import {Component, Input} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';
import {MenuLabel} from '../../_models/menu-label';

@Component({
    selector: 'bb-display-label-modal',
    templateUrl: './display-label-modal.component.html',
    styleUrls: ['./display-label-modal.component.css']
})
export class DisplayLabelModalComponent extends BaseModalComponent {
    @Input() label: MenuLabel;

    constructor(modalController: ModalController) {
        super(modalController);
    }
}
