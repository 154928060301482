<modal-header title="Set Password"></modal-header>

<ion-content>
    <ion-grid>
        <form [formGroup]="loginFormGroup" (ngSubmit)="submit()">
            <bb-login-form (formReady)="loginFormInitialized($event)"></bb-login-form>
        </form>
    </ion-grid>
</ion-content>

<modal-footer submitLabel="Create User" [disableSubmit]="loginFormGroup.invalid" (submitClicked)="submit()"></modal-footer>
