import {Inject, Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {ModalController} from '@ionic/angular';
import {LibConfig, LibConfigService} from '../lib.config';
import {BehaviorSubject, Observable} from 'rxjs';
import {Deposit} from '../_models/deposit';

@Injectable({
    providedIn: 'root'
})
export class DepositService extends BaseService<Deposit> {
    destination = 'deposits';

    public searchCriteria: Observable<any>;
    private searchCriteriaSubject: BehaviorSubject<any>;

    constructor(
        http: HttpClient,
        private modalController: ModalController,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);

        this.searchCriteriaSubject = new BehaviorSubject<any>({filter: ''});
        this.searchCriteria = this.searchCriteriaSubject.asObservable();
    }

    findAllByOrganization(organizationId: any, status) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}${!!status ? '?status=' + status : ''}`);
    }

    findByTab(tabId: number) {
        return this.http.get(`${this.baseUrl()}/tab/${tabId}`);
    }

    findByPaymentCharge(paymentChargeId: number) {
        return this.http.get(`${this.baseUrl()}/payment-charge/${paymentChargeId}`);
    }

    findByCashAudit(cashAudit: number) {
        return this.http.get(`${this.baseUrl()}/cash-audit/${cashAudit}`);
    }

    refund(ids: string, drawerId: number) {
        return this.http.put(`${this.baseUrl()}/refund/${ids}${!!drawerId ? '?drawerId=' + drawerId : ''}`, {});
    }

    findSummaryByOrganization(organizationId: any, status) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/summary${!!status ? '?status=' + status : ''}`);
    }

    setSearchCriteria(value: any) {
        this.searchCriteriaSubject.next(value);
    }

    getSearchCriteria(): any {
        return this.searchCriteriaSubject.getValue();
    }
}
