<ion-footer>
    <ion-footer *ngIf="!linear" class="ion-no-border">
        <ion-button [disabled]="!showPrevious()" data-cy="footer-previous-button" class="stepper-button" (click)="previous(stepper)" color="dark">
            <ion-icon slot="icon-only" color="light" name="arrow-back-outline"></ion-icon>
        </ion-button>
        <ion-button [disabled]="!showNext()" data-cy="footer-next-button" class="stepper-button" (click)="next(stepper)" color="dark">
            <ion-icon slot="icon-only" color="light" name="arrow-forward-outline"></ion-icon>
        </ion-button>
        <ion-button color="success" data-cy="footer-submit-button"
                    class="stepper-button" (click)="submitButtonClicked()" [disabled]="disableSubmit">
            {{submitText}}
        </ion-button>
        <ion-button data-cy="footer-cancel-button" color="dark" class="stepper-button" (click)="close()">Cancel</ion-button>
    </ion-footer>

    <ion-footer *ngIf="linear" class="ion-no-border">
        <ion-button *ngIf="showNext()" data-cy="footer-next-button" [disabled]="disableNext()"
                    color="dark" class="footer-button" (click)="next(stepper)">
            Next
        </ion-button>
        <ion-button *ngIf="!showNext() && !disableSubmit" data-cy="footer-submit-button"
                    color="dark" class="footer-button" (click)="submitButtonClicked()">
            {{submitText}}
        </ion-button>
    </ion-footer>
</ion-footer>
