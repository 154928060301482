<modal-header title="Edit Address"></modal-header>

<ion-content>
    <form [formGroup]="editAddressForm" (ngSubmit)="onSubmit()">
        <ion-grid>
            <bb-address-form (formReady)="formInitialized($event)" [address]="address"></bb-address-form>
        </ion-grid>
    </form>
</ion-content>

<modal-footer [disableSubmit]="editAddressForm.invalid" (submitClicked)="onSubmit()"></modal-footer>
