import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MarketingPush} from '../../_models/marketing-push';

@Component({
    selector: 'bb-marketing-push',
    templateUrl: './marketing-push.component.html',
    styleUrls: ['./marketing-push.component.css']
})
export class MarketingPushComponent implements OnInit {
    @Input() marketingPush: MarketingPush;
    @Input() hideUser = false;
    @Output() imageLoadComplete: EventEmitter<any> = new EventEmitter<any>();
    imageLoaded = true;

    ngOnInit(): void {
        this.imageLoaded = !this.marketingPush.image;
        this.imageLoadComplete.emit();
    }

    hideLoader() {
        this.imageLoaded = true;
    }

    navigate() {
        if (!!this.marketingPush.navUrl) {
            window.open(this.marketingPush.navUrl, '_system', 'location=yes');
        }
    }
}
