import {Address} from './address';
import {BaseFormModel} from './base-form-model';
import {OrganizationRating} from './organization-rating';
import {MerchantAccount} from './merchant-account';
import {PickupLocation} from './pickup-location';
import {OrganizationRewardProgram} from './organization-reward-program';
import {Venue} from './venue';
import {CustomerArea} from './customer-area';
import {OrganizationPreference} from './organization-preference';
import {OrganizationRole} from './organization-role';
import {Person} from './person';
import {CompDefinition} from './comp-definition';
import {TabNoteDefinition} from './tab-note-definition';
import {OrganizationEvent} from './organization-event';
import {MembershipProgram} from './membership-program';
import {MembershipTokenGroup} from './membership-token-group';
import {CashDrawer} from './cash-information/cash-drawer';

export class Organization extends BaseFormModel<Organization> {
    id: number;
    venueId: number;
    venue: Venue;
    name: string;
    description: string;
    phone: string;
    website: string;
    facebook: string;
    instagram: string;
    twitter: string;
    receiptTag: string;
    logoAddress: string;
    tipDefinitions: string;
    address: Address;
    autoTransferDrawer: CashDrawer;
    autoFinalAmount: number;
    customerAreas: CustomerArea[];
    merchantAccount: MerchantAccount;
    pickupLocations: PickupLocation[];
    compDefinitions: CompDefinition[];
    tabNoteDefinitions: TabNoteDefinition[];
    membershipPrograms: MembershipProgram[];
    tokenGroups: MembershipTokenGroup[];
    events: OrganizationEvent[];
    roles: OrganizationRole[];
    rewardProgram: OrganizationRewardProgram;
    organizationPreference: OrganizationPreference;
    status: string;
    rating: OrganizationRating;
    activeEvent: OrganizationEvent;
    latitude: number;
    longitude: number;
    distance: number;
    allowOrderNotes: boolean;
    allowTabs: boolean;
    allowTips: boolean;
    allowTaxExempt: boolean;
    allowInvoices: boolean;
    allowMarketingEmailRequest: boolean;
    requireCardAtClose: boolean;
    platform: boolean;
    menuId: number;
    preAuthAmount: number;
    timeZone: string;
    testMode: boolean;
    achFirstName: string;
    achLastName: string;
    achMaskedAccountNumber: string;
    achRoutingNumber: string;
    achAccountType: string;
    allowQuickCloseAllTabs: boolean;
    autoDeliver: boolean;
    quickbooksEnabled: boolean;
    preventDrawerCopy: boolean;
    tipOutType: string;
    autoPrintClaimTab: boolean;
    hideServiceOptions: boolean;

    owner: Person;

    public constructor(jsonInput: any) {
        super(jsonInput);
        Object.assign(this, jsonInput);
    }

    init(): void {
        const temp = [];
        if (!!this.tokenGroups) {
            this.tokenGroups.forEach(r => {
                temp.push(new MembershipTokenGroup(r));
            });
            this.tokenGroups = temp;
        }
    }
}
