import {AlertController, NavController} from '@ionic/angular';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SubscriberComponent} from './subscriber.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Organization} from '../_models/organization';
import {PickupLocation} from '../_models/pickup-location';
import {Menu} from '../_models/menu';
import {Order} from '../_models/order';
import {PickupLocationMenu} from '../_models/pickup-location-menu';
import {PickupLocationService} from '../_services/pickup-location.service';
import {ToastService} from '../_services/toast.service';
import {PickupLocationMenuService} from '../_services/pickup-location-menu.service';
import {OrderService} from '../_services/order.service';
import {OrderItemService} from '../_services/order-item.service';
import {MenuService} from '../_services/menu.service';
import {UserService} from '../_services/user.service';
import {OrganizationService} from '../_services/organization.service';
import {MenuCategoryItem} from '../_models/menu-category-item';
import {OrderItem} from '../_models/order-item';
import clone from 'clone';
import {TabService} from '../_services/tab.service';
import {Tab} from '../_models/tab';
import {LoadingService} from '../_services/loading.service';
import {TabStatuses} from '../_constants/tab-statuses';
import {OrderItemSummary} from '../_models/order-item-summary';
import {cloneItem, compareItems} from '../_utils/order-utils';
import {OrganizationTerminalService} from '../_services/organization-terminal.service';
import {v4 as uuidv4} from 'uuid';
import {addCurrency} from '../_utils/currency-math';
import {MembershipService} from '../_services/membership.service';
import {Membership} from '../_models/membership';
import {TabNoteDefinition} from '../_models/tab-note-definition';
import {TabNote} from '../_models/tab-note';

@Component({
    template: ''
})
export abstract class BaseMenuPageComponent extends SubscriberComponent implements OnInit {
    organization: Organization;
    pickupLocation: PickupLocation;
    menu: Menu;
    order: Order;
    tab: Tab;
    pickupLocationMenu: PickupLocationMenu;
    orderItemIndex = 1;
    orderItemCreditIndex = 1;
    activeMembership: Membership;
    availableTokens = 0;
    applyNotes: TabNoteDefinition[];

    abstract tabChanged(tab: Tab);

    abstract pickupLocationChanged(pickupLocation: PickupLocation);

    abstract organizationChanged();

    protected constructor(
        protected membershipService: MembershipService,
        protected pickupLocationService: PickupLocationService,
        protected toastService: ToastService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected pickupLocationMenuService: PickupLocationMenuService,
        protected orderService: OrderService,
        protected orderItemService: OrderItemService,
        protected loadingService: LoadingService,
        protected menuService: MenuService,
        protected tabService: TabService,
        protected organizationTerminalService: OrganizationTerminalService,
        protected userService: UserService,
        protected organizationService: OrganizationService,
        protected route: ActivatedRoute,
        protected alertController: AlertController,
        protected router: Router,
        protected navController: NavController
    ) {
        super();
    }

    ngOnInit(): void {
        this.organization = this.organizationService.currentValue;

        this.subscribe(this.pickupLocationService.current
            .subscribe(p => this.pickupLocationChanged(p)));

        this.subscribe(this.organizationService.current.subscribe(o => {
            this.organization = !!o ? new Organization(o) : null;
            this.organizationChanged();
        }));

        this.subscribe(this.pickupLocationMenuService.current.subscribe(p => {
            this.pickupLocationMenu = p;
            this.menu = !!p ? p.menu : null;
            this.changeDetectorRef.detectChanges();
        }));

        this.subscribe(this.orderService.current.subscribe(o => {
            this.order = !!o ? new Order(o) : null;
            if (!this.order) {
                this.orderItemIndex = 1;
                this.orderItemCreditIndex = 1;
            } else {
                this.orderItemIndex = this.order.items.reduce((max, item) => max = (max > item.addIndex) ? max : item.addIndex + 1, 0);
                this.orderItemCreditIndex = this.order.maxCreditIndex();
                this.order.deposits = this.order.items.reduce((total, item: OrderItem) => total = addCurrency(total, item.calcDeposits()), 0);
            }

            this.availableTokens = (!!this.activeMembership ? this.activeMembership.availableTokens : 0)
                - (!!this.order ? this.order.tokenPayments() : 0);
        }));

        this.subscribe(this.tabService.current.subscribe(t => {
            this.tabChanged(t);
            this.applyMembershipProgram();
        }));

        this.subscribe(this.membershipService.current.subscribe(m => {
            this.activeMembership = !!m ? new Membership(m) : null;
            this.availableTokens = (!!this.activeMembership ? this.activeMembership.availableTokens : 0)
                - (!!this.order ? this.order.tokenPayments() : 0);

            if (!!this.tab && !this.tab.member && !!this.activeMembership) {
                this.tab.member = this.activeMembership;
                this.tab.membershipProgram = !!this.activeMembership ? this.activeMembership.activeMembershipProgram : null;
                this.tabService.setCurrent(this.tab);
            }

            this.applyMembershipProgram();
        }));
    }

    ionViewWillEnter() {
        const openTab = this.tabService.getCurrentOpenTabs().find(t => t.orgId === this.organization.id);
        this.tabService.setCurrent(!!openTab ? new Tab(openTab) : null);
    }

    applyMembershipProgram() {
        this.orderService.applyMembershipTokens(this.order, this.activeMembership, this.availableTokens);
        this.orderService.setCurrent(this.order);
    }

    async openItem(item: MenuCategoryItem) {
        await this.router.navigate([`/menu-item/${this.pickupLocation.id}/${item.menuItem.id}`],
            {queryParams: {newItem: true}});
    }

    removeOrderItems(items: OrderItem[]) {
        if (!this.order || !this.order.items) {
            return;
        }

        const order = clone<Order>(this.order);
        order.items = order.items.filter(i => !items.some(n => n.addIndex === i.addIndex));

        this.applyComps(order);
    }

    updateItem(item: OrderItem) {
        const order = clone<Order>(this.order);
        const index = order.items.findIndex((i: OrderItem) => i.key === item.key);
        if (index > -1) {
            order.items[index] = item;
        }

        this.applyComps(order);
    }

    addOrderItems(items: OrderItem[]) {
        if (!this.order) {
            this.order = this.orderService.initialize(this.menu, this.pickupLocation);
        }

        const order = clone<Order>(this.order);
        items.forEach(i => {
            if (!i.key) {
                i.key = uuidv4();
            }

            if (!!i.id) {
                const index = order.items.findIndex((item: OrderItem) => item.key === i.key);
                if (index > -1) {
                    order.items[index] = i;
                } else {
                    i.addIndex = this.orderItemIndex++;
                    order.items.push(new OrderItem(i));
                }
            } else {
                i.addIndex = !!i.addIndex ? i.addIndex : this.orderItemIndex++;
                order.items.push(new OrderItem(i));
            }
        });

        this.applyComps(order);
    }

    async addToOrder(item: MenuCategoryItem) {
        if (!this.order) {
            this.order = this.orderService.initialize(this.menu, this.pickupLocation);
        }
        let orderItem: OrderItem;
        const order = new Order({...this.order});
        const index = order.items.reverse().findIndex(i => i.menuItem.id === item.menuItem.id);
        if (index > -1) {
            orderItem = cloneItem(order.items[index]);
            orderItem.id = null;
        } else {
            orderItem = this.orderItemService.initializeOrderItem(item.menuItem);
        }

        orderItem.key = uuidv4();
        orderItem.addIndex = this.orderItemIndex++;

        order.items.reverse();
        order.items.push(orderItem);

        this.applyComps(order);
    }

    async createPending(retry = true) {
        this.order.terminalOrder = this.organizationTerminalService.isTerminal;

        if (!!this.tab && this.tab.status === TabStatuses.OPEN) {
            this.order.parentId = this.tab.id;
        }

        try {
            const t: Tab = await this.orderService.createPending(this.order).toPromise() as Tab;
            this.tab = new Tab(t);
            this.tabService.setEvent(this.tab);
            this.tabService.setCurrent(this.tab);
            this.orderService.setCurrentPending(t, this.order);
        } catch (error) {
            if (error.status === 424 && !!error.error && !!error.error.message) {
                const errorDetails = JSON.parse(error.error.message);
                if (!!errorDetails.unavailableItemIds) {
                    const removing = this.order.items.filter(i => errorDetails.unavailableItemIds.includes(i.menuItem.id));
                    this.order.items = this.order.items.filter(i => !errorDetails.unavailableItemIds.includes(i.menuItem.id));
                    let errMessage = 'Some items are no longer available and are removed from your order:<br><ul>';
                    removing.forEach(i => errMessage += `<li>${i.menuItem.name}</li>`);
                    errMessage += '</ul>';
                    await this.toastService.message(errMessage);

                    this.orderService.setCurrent(this.order);

                    if (this.order.items.length === 0) {
                        this.clearCart();
                    } else if (retry) {
                        await this.createPending(false);
                    }

                    this.pickupLocationMenuService.findByPickupLocationId(this.pickupLocation.id).subscribe(m => {
                        this.pickupLocationMenuService.setCurrent(new PickupLocationMenu(m));
                    });
                }
            }
            throw error;
        }
        return Promise.resolve(this.tab);
    }

    async removeFromOrder(item: MenuCategoryItem) {
        if (!!this.order) {
            const order = clone<Order>(this.order);
            const index = order.items.reverse().findIndex(i => i.menuItem.id === item.menuItem.id);
            order.items.splice(index, 1);
            order.items.reverse();

            this.orderService.setCurrent(order.items.length > 0 ? new Order(order) : null);
        }
    }

    clearCart() {
        this.orderService.setCurrent(null);
        if (!!this.tab && this.tab.status === TabStatuses.PENDING) {
            this.tabService.setCurrent(null);
        }
    }

    addToItemSummary(itemSummary: OrderItemSummary) {
        const item = cloneItem(itemSummary.items[0]);
        item.key = uuidv4();

        const tokenGroup = !!this.organization && !!this.organization.tokenGroups
            ? this.organization.tokenGroups.find(t => t.id === item.membershipTokenGroupId)
            : null;

        const availability = !!this.activeMembership && !!tokenGroup ?
            this.activeMembership.availableTokenGroups.find(t => t.id === tokenGroup.id) : null;

        if (!this.activeMembership
            || !this.availableTokens
            || itemSummary.items[0].tokenPayment > this.availableTokens
            || !availability
            || (!!availability.remaining && availability.remaining <= availability.currentUsed)
        ) {
            item.tokenPayment = null;
            item.membershipTokenGroupId = null;
            item.id = null;
            item.addIndex = this.order.items.reduce((max, i) => !!i.addIndex && i.addIndex > max ? i.addIndex : max, 0) + 1;
        }

        const order = clone<Order>(this.order);
        item.addIndex = this.orderItemIndex++;
        order.items.push(item);

        this.applyComps(order);
    }

    removeFromItemSummary(itemSummary: OrderItemSummary) {
        const item = itemSummary.items.pop();
        const order = clone<Order>(this.order);
        order.items = order.items.filter(i => i.key !== item.key);

        this.applyComps(order);
    }

    removeItemSummary(itemSummary: OrderItemSummary) {
        const item = itemSummary.items.pop();

        const order = clone<Order>(this.order);
        order.items = order.items.filter(i => !compareItems(i, item));
        this.applyComps(order);
    }

    applyComps(order: Order) {
        if (!!order) {
            let tab = !!this.tab ? this.tab : null;
            if (!!this.applyNotes && this.applyNotes.length > 0) {
                const notes = [];
                this.applyNotes.forEach(definition => notes.push(new TabNote({definition})));
                tab = new Tab({notes});
            }

            this.orderService.applyComps(order, tab, this.pickupLocation.activeEvent, this.activeMembership);
            this.orderService.setCurrent(order.items.length > 0 ? new Order(order) : null);
        }
    }
}
