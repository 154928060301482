import {Component, Input} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';
import {Organization} from '../../_models/organization';
import {OrganizationMarketingEmail} from '../../_models/organization-marketing-email';
import {OrganizationMarketingEmailService} from '../../_services/organization-marketing-email.service';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'bb-email-opt-in',
    templateUrl: './email-opt-in.component.html',
    styleUrls: ['./email-opt-in.component.scss'],
})
export class EmailOptInComponent extends BaseModalComponent {
    @Input() organization: Organization;
    @Input() email: string;
    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private organizationMarketingEmailService: OrganizationMarketingEmailService
    ) {
        super(modalController);
    }

    onSubmit() {
        this.organizationMarketingEmailService.create(new OrganizationMarketingEmail({
            email: this.email,
            parentId: this.organization.id
        })).subscribe(() => {
        });

        this.close();
    }
}
