import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';
import {OrganizationService} from './organization.service';
import {BaseObservableService} from './base-obeservable-service';
import {Organization} from '../_models/organization';
import {OrganizationTerminal} from '../_models/organization-terminal';
import {Constants} from '../_constants/constants';
import {LoadingController} from '@ionic/angular';
import {LoadingService} from './loading.service';
import {Tab} from '../_models/tab';
import {Membership} from '../_models/membership';

@Injectable({
    providedIn: 'root'
})
export class OrganizationTerminalService extends BaseObservableService<OrganizationTerminal> {
    destination = 'organization-terminals';

    currentOrganization: Organization;
    isTerminal = false;
    canceling = false;

    constructor(
        http: HttpClient,
        private loadingController: LoadingController,
        private loadingService: LoadingService,
        private organizationService: OrganizationService,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);

        const val = JSON.parse(localStorage.getItem('TERMINAL'));
        if (!!val) {
            this.setCurrent(val);
        }

        this.organizationService.current.subscribe(o => {
            if ((!!o && !!this.currentOrganization && o.id !== this.currentOrganization.id)
                || (!!this.currentOrganization && !!o && this.currentOrganization.testMode !== o.testMode)) {
                this.setCurrent(null);
            }
            this.currentOrganization = o;
        });
    }

    findByOrganizationId(organizationId) {
        return this.http.get(this.baseUrl() + `/organization/${organizationId}`);
    }

    tokenize(organizationId: number, terminalId: string, silent: boolean, referenceId?: string) {
        let headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '404,406,408,410,413,423,424');
        if (silent) {
            headers = headers.set(Constants.SILENT, 'true');
        }
        headers = headers.set('timeout', '30000');
        return this.http.post(`${this.baseUrl()}/${terminalId}/organization/${organizationId}${!!referenceId
            ? '?referenceId=' + referenceId
            : ''}`, {}, {headers});
    }

    cancel(organizationId: number, terminalId: string, referenceId: string) {
        this.canceling = true;
        let headers = new HttpHeaders().set(Constants.SILENT, 'true');
        headers = headers.set(Constants.IGNORE_ERROR_CODES, '409');
        return this.http.post(`${this.baseUrl()}/${terminalId}/organization/${organizationId}/${referenceId}/cancel`, {}, {headers});
    }

    payMembership(terminalId: string, membership: Membership, referenceId: string) {
        const headers = new HttpHeaders().set('timeout', '30000');
        return this.http.post(`${this.baseUrl()}/${terminalId}/membership?referenceId=${referenceId}`, membership, {headers});
    }

    payTab(terminalId: string, tabId: number, amount: number, tip: number, gratuityEligibleAmount: number, referenceId?: string) {
        let headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406,408,410,413,423,424');
        headers = headers.set('timeout', '30000');
        return this.http.post(`${this.baseUrl()}/${terminalId}/tab/${tabId}?amount=${amount}&gratuityEligibleAmount=${gratuityEligibleAmount}&tip=${!!tip
                ? tip
                : 0}${!!referenceId
                ? '&referenceId=' + referenceId
                : ''}`,
            {}, {headers});
    }

    payOfflineTab(terminalId: string, tab: Tab, amount: number, tip: number, gratuityEligibleAmount: number, referenceId?: string) {
        let headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406,408,410,413,423,424');
        headers = headers.set('timeout', '30000');
        return this.http.post(`${this.baseUrl()}/${terminalId}/tab?amount=${amount}&gratuityEligibleAmount=${gratuityEligibleAmount}&tip=${!!tip
                ? tip
                : 0}${!!referenceId
                ? '&referenceId=' + referenceId
                : ''}`,
            tab, {headers});
    }

    setCurrent(val) {
        if (!!val) {
            localStorage.setItem('TERMINAL', JSON.stringify(val));
        } else {
            localStorage.removeItem('TERMINAL');
        }
        super.setCurrent(val);
    }

    async loading(terminalId: string, organizationId: number, restrictTap: boolean, referenceId: string) {
        const options: any = {
            spinner: 'crescent',
            cssClass: 'bb-loading-spinner',
            message: restrictTap ? 'Insert or slide card.' : 'Tap, insert, or slide card.',
            backdropDismiss: true
        };

        this.canceling = false;
        const loading = await this.loadingController.create(options);
        loading.onDidDismiss().then((dataReturned) => {
            if (!dataReturned || !dataReturned.data) {
                this.loadingService.present('Canceling.');
                this.cancel(organizationId, terminalId, referenceId)
                    .subscribe(() => {
                        this.loadingService.dismiss();
                    }, (error) => {
                        if (error.status === 409) {
                            this.loadingService.present('Cannot cancel an in flight transaction.');
                        }
                    });
            }
        });
        await loading.present();
        return loading;
    }
}
