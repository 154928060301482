import {IdCache} from '../_models/id-cache';

const DURATION = 1.08e+7; // 3 hours
export const DEVICE_ID = 'DEVICE_ID';

export class StoredObject {
    dateInMilli: number;
    value: any;

    constructor(init?: Partial<StoredObject>) {
        Object.assign(this, init);
    }
}

export function saveTimeBased(key: string, obj: any) {
    if (!!obj) {
        localStorage.setItem(key, JSON.stringify(new StoredObject({dateInMilli: Date.now(), value: obj})));
    } else {
        localStorage.removeItem(key);
    }
}

export function getTimeBased(key) {
    const val = localStorage.getItem(key);
    if (!!val) {
        try {
            const storedObject = new StoredObject(JSON.parse(val));
            localStorage.removeItem(key);
            if (Date.now() - storedObject.dateInMilli > DURATION) {
                return null;
            } else {
                saveTimeBased(key, storedObject.value);
                return storedObject.value;
            }
        } catch (error) {
            console.log(error);
            localStorage.removeItem(key);
            return null;
        }
    }
    return null;
}

export function getTabIdCache(organizationId: number): IdCache[] {
    const tabIdString = localStorage.getItem(`TAB_ID_CACHE_${organizationId}`);
    return !!tabIdString ? JSON.parse(tabIdString) : [];
}

export function setTabIdCache(organizationId: number, tabIds: IdCache[]) {
    if (!!organizationId) {
        localStorage.setItem(`TAB_ID_CACHE_${organizationId}`, JSON.stringify(tabIds));
    }
}
