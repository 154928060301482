import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BaseStepperFormComponent} from '../base-stepper-form.component';
import {ModalController} from '@ionic/angular';
import {ToastService} from '../../_services/toast.service';
import {FormGroup} from '@angular/forms';
import {AuthenticationService} from '../../_services/authentication.service';
import {User} from '../../_models/user';
import {CreditCardFormComponent} from '../_forms/credit-card-form/credit-card-form.component';

@Component({
    selector: 'bb-register-user',
    templateUrl: './register-user.component.html',
    styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent extends BaseStepperFormComponent implements OnInit {
    @Input() email: string;
    @ViewChild(CreditCardFormComponent) cardFormComponent: CreditCardFormComponent;
    includeCreditCard = true;

    personFormGroup: FormGroup = new FormGroup({});
    loginFormGroup: FormGroup = new FormGroup({});

    constructor(
        modalController: ModalController,
        private authenticationService: AuthenticationService,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    ngOnInit() {
        if (this.email != null) {
            this.includeCreditCard = false;
        }
    }

    personFormInitialized(form: FormGroup) {
        this.personFormGroup = form;
    }

    loginFormInitialized(form: FormGroup) {
        this.loginFormGroup = form;
    }

    valid() {
        return this.personFormGroup.valid
            && this.loginFormGroup.valid;
    }

    async onSubmit() {
        if (!this.valid()) {
            return;
        }

        const formGroup = new FormGroup({});
        formGroup.addControl('person', this.personFormGroup);
        formGroup.addControl('login', this.loginFormGroup);
        formGroup.addControl('email', this.personFormGroup.controls.email);

        try {
            const user: User = new User(formGroup.value);
            await this.authenticationService.create(user, true);
        } catch (err) {
            await this.toastService.error('The credit card information is invalid.');
        }
    }
}
