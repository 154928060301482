import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';
import {PickupLocationMenu} from '../_models/pickup-location-menu';
import {Menu} from '../_models/menu';
import {MenuItem} from '../_models/menu-item';
import {PickupLocationMenuItemPrice} from '../_models/pickup-location-menu-item-price';
import {BaseObservableService} from './base-obeservable-service';
import {MenuService} from './menu.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PickupLocationMenuService extends BaseObservableService<PickupLocationMenu> {
    destination = 'pickup-location-menus';

    public showImages: Observable<boolean>;
    private showImagesSubject: BehaviorSubject<boolean>;

    constructor(
        http: HttpClient,
        private menuService: MenuService,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);

        const showImages = localStorage.getItem('MENU_IMAGES');
        this.showImagesSubject = new BehaviorSubject<boolean>(!showImages || showImages !== 'false');
        this.showImages = this.showImagesSubject.asObservable();

        this.menuService.current.subscribe(m => {
            const pickupLocationMenu = this.currentValue;
            if (!!pickupLocationMenu && !!m && pickupLocationMenu.menu.id === m.id) {
                pickupLocationMenu.menu = new Menu(m);
                this.setCurrent(pickupLocationMenu);
            }
        });
    }

    refresh() {
        if (!!this.currentValue && !!this.currentValue.id) {
            this.getSilent(this.currentValue.id).subscribe((o: PickupLocationMenu) => this.setCurrent(new PickupLocationMenu(o)));
        }
    }

    saveAllowInHouse(pickupLocationMenu: PickupLocationMenu) {
        return this.http.put(this.baseUrl() + `/${pickupLocationMenu.id}/allow-in-house/${pickupLocationMenu.allowInHouse}`, {});
    }

    saveAllowToGo(pickupLocationMenu: PickupLocationMenu) {
        return this.http.put(this.baseUrl() + `/${pickupLocationMenu.id}/allow-to-go/${pickupLocationMenu.allowTogo}`, {});
    }

    findByPickupLocationId(pickupLocationId: number) {
        return this.http.get(this.baseUrl() + `/pickup-location/${pickupLocationId}`);
    }

    availabilityUpdate(pickupLocationMenuItemPrice: PickupLocationMenuItemPrice) {
        let pickupLocationMenu = this.currentValue;
        if (!!pickupLocationMenu) {
            if (!pickupLocationMenu.menuItemPrices) {
                pickupLocationMenu.menuItemPrices = [];
                pickupLocationMenu.menuItemPrices.push(pickupLocationMenuItemPrice);
            } else {
                const index = pickupLocationMenu.menuItemPrices.findIndex(mi => mi.id === pickupLocationMenuItemPrice.id);
                if (index > -1) {
                    pickupLocationMenu.menuItemPrices[index] = pickupLocationMenuItemPrice;
                } else {
                    pickupLocationMenu.menuItemPrices.push(pickupLocationMenuItemPrice);
                }
            }
            pickupLocationMenu = this.initialize(pickupLocationMenu);
            this.setCurrent(pickupLocationMenu);
        }
    }

    initialize(p: PickupLocationMenu) {
        const pickupLocationMenu = new PickupLocationMenu(p);
        if (!!pickupLocationMenu && !!pickupLocationMenu.menuItemPrices && pickupLocationMenu.menuItemPrices.length > 0) {
            pickupLocationMenu.menu.menuItems.forEach(i => {
                this.applyPickupLocationItem(i, pickupLocationMenu.menuItemPrices);
            });

            pickupLocationMenu.menu.menuCategories.forEach(cat => {
                cat.menuItems.forEach(catItem => {
                    this.applyPickupLocationItem(catItem.menuItem, pickupLocationMenu.menuItemPrices);
                });

                cat.menuItemGroups.forEach(itemGroup => {
                    itemGroup.menuItemGroup.menuItems.forEach(groupItem => {
                        this.applyPickupLocationItem(groupItem.menuItem, pickupLocationMenu.menuItemPrices);
                    });
                });
            });
        }
        return pickupLocationMenu;
    }

    applyPickupLocationItem(item: MenuItem, pickupLocationPrices: PickupLocationMenuItemPrice[]) {
        item.setPickupLocationMenuItemPrices(pickupLocationPrices);

        if (!!item.selections && item.selections.length > 0) {
            item.selections.forEach(sel => {
                if (!!sel.menuSelection.options) {
                    sel.menuSelection.options.forEach(selItem => {
                        this.applyPickupLocationItem(selItem.menuItem, pickupLocationPrices);
                    });
                }
            });
        }
    }

    setShowImages(showImages: boolean) {
        this.showImagesSubject.next(showImages);
        localStorage.setItem('MENU_IMAGES', Boolean(showImages).toString());
    }

    public get showImagesCurrent(): boolean {
        return this.showImagesSubject.value;
    }
}
