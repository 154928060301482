import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SubscriberComponent} from './subscriber.component';
import {Organization} from '../_models/organization';
import {DisplayOrderComponent} from './display-order/display-order.component';
import {OrderItemSummary} from '../_models/order-item-summary';
import {OrderItem} from '../_models/order-item';
import {DisplayMenuItemModalComponent} from './display-menu-item-modal/display-menu-item-modal.component';
import {MenuItem} from '../_models/menu-item';
import {Order} from '../_models/order';
import {Tab} from '../_models/tab';
import {LoadingService} from '../_services/loading.service';
import {MenuItemService} from '../_services/menu-item.service';
import {OrderService} from '../_services/order.service';
import {ModalController} from '@ionic/angular';
import clone from 'clone';
import {cloneItem} from '../_utils/order-utils';
import {OrderItemService} from '../_services/order-item.service';
import {PickupLocation} from '../_models/pickup-location';
import {DeviceService} from '../_services/device.service';
import {v4 as uuidv4} from 'uuid';
import {PickupLocationMenuService} from '../_services/pickup-location-menu.service';
import {PickupLocationMenu} from '../_models/pickup-location-menu';
import {Membership} from '../_models/membership';
import {MembershipService} from '../_services/membership.service';

@Component({
    template: ''
})
export abstract class BaseConfirmationComponent extends SubscriberComponent implements OnInit {

    @Input() organization: Organization;
    @Input() pickupLocation: PickupLocation;
    @Input() editable = false;

    @ViewChild(DisplayOrderComponent) displayOrder: DisplayOrderComponent;

    @Output() orderEmpty: EventEmitter<any> = new EventEmitter();

    order: Order;
    deviceId: string;
    useCached = false;
    pickupLocationMenu: PickupLocationMenu;

    activeMembership: Membership;
    availableTokens = 0;

    abstract tab: Tab;

    abstract refresh(tab: Tab, orderId?: number);

    abstract removeItemSave(order: Order);

    abstract addItemSave(order: Order);

    protected constructor(
        protected membershipService: MembershipService,
        protected pickupLocationMenuService: PickupLocationMenuService,
        protected deviceService: DeviceService,
        protected menuItemService: MenuItemService,
        protected orderService: OrderService,
        protected orderItemService: OrderItemService,
        protected loadingService: LoadingService,
        protected modalController: ModalController
    ) {
        super();
    }

    ngOnInit(): void {
        this.deviceId = this.deviceService.currentValue;
        this.activeMembership = this.membershipService.currentValue;
        this.order = this.orderService.currentValue;

        this.availableTokens = (!!this.activeMembership ? this.activeMembership.availableTokens : 0)
            - (!!this.order ? this.order.tokenPayments() : 0);

        this.subscribe(this.pickupLocationMenuService.current.subscribe(m => this.pickupLocationMenu = !!m
            ? new PickupLocationMenu(m)
            : null));

        this.subscribe(this.orderService.current.subscribe(o => {
            this.order = !!o ? new Order(o) : null;

            this.availableTokens = (!!this.activeMembership ? this.activeMembership.availableTokens : 0)
                - (!!this.order ? this.order.tokenPayments() : 0);
        }));
    }

    async addItem(itemSummary: OrderItemSummary) {
        if (this.editable && !!this.order) {
            const item = cloneItem(itemSummary.items[0]);
            item.key = uuidv4();

            if (!!item.tokenPayment) {
                const tokenGroup = !!this.organization && !!this.organization.tokenGroups
                    ? this.organization.tokenGroups.find(t => t.id === item.membershipTokenGroupId)
                    : null;

                const availability = !!this.activeMembership && !!tokenGroup ?
                    this.activeMembership.availableTokenGroups.find(t => t.id === tokenGroup.id) : null;

                if (!this.activeMembership
                    || !this.availableTokens
                    || itemSummary.items[0].tokenPayment > this.availableTokens
                    || !availability
                    || (!!availability.remaining && availability.remaining > availability.currentUsed)
                ) {
                    item.tokenPayment = null;
                    item.membershipTokenGroupId = null;
                    item.id = null;
                    item.addIndex = this.order.items.reduce((max, i) => !!i.addIndex && i.addIndex > max ? i.addIndex : max, 0) + 1;
                }
            }

            const order = new Order(clone<Order>(this.order));
            order.items.push(item);

            this.applyComps(order);
            this.addItemSave(order);
        }
    }

    removeItem(itemSummary: OrderItemSummary) {
        if (this.editable && !!this.order) {
            const item = itemSummary.items.pop();
            this.removeItems([item]);
        }
    }

    removeItemSummary(itemSummary: OrderItemSummary) {
        if (this.editable && !!this.order) {
            this.removeItems(itemSummary.items);
        }
    }

    removeItems(items: OrderItem[]) {
        if (this.editable && !!this.order) {
            const order = new Order(this.order);
            order.items = order.items.filter(item => !items.some(i => (!!i.id && !!item.id && i.id === item.id) || i.key === item.key));
            this.applyComps(order);
            this.removeItemSave(order);
            if (order.items.length === 0) {
                this.orderEmpty.emit();
            }
        }
    }

    async openEditOrderItemSummary(orderItemSummary: OrderItemSummary) {
        if (this.editable && !!this.order) {
            const orderItem = new OrderItem(orderItemSummary.items[0]);
            orderItem.count = orderItemSummary.items.length;
            let menuItem = null;
            if (!!orderItem.menuItem) {
                if (!this.useCached) {
                    this.loadingService.present();
                    menuItem = new MenuItem(await this.menuItemService.get(orderItem.menuItem.id).toPromise());
                    this.loadingService.dismiss();
                } else if (!!this.pickupLocationMenu) {
                    menuItem = this.pickupLocationMenu.menu.menuItems.find(mi => mi.id === orderItem.menuItem.id);
                }
            }
            const modal = await this.modalController.create({
                component: DisplayMenuItemModalComponent,
                componentProps: {
                    menuItem,
                    orderItem,
                    rewardProgram: this.organization.rewardProgram
                },
                cssClass: 'menu-item-modal'
            });
            await modal.present();

            modal.onDidDismiss().then((dataReturned) => {
                if (!!dataReturned && !!dataReturned.data) {
                    const order = clone<Order>(this.order);
                    const returnedItems: OrderItem[] = dataReturned.data;
                    const itemIds = orderItemSummary.items.reduce((acc, o) => acc.concat(o.id), []);
                    order.items = order.items.filter(i => !itemIds.includes(i.id)).concat(returnedItems);

                    this.applyComps(order);

                    if (!this.useCached) {
                        this.orderService.createPending(order).subscribe((t: Tab) => {
                            this.refresh(t, order.id);
                        }, error => {
                            this.orderService.handlePendingError(error);
                            this.loadingService.dismiss();
                        });
                    } else {
                        this.addItemSave(order);
                    }
                }
            });
        }
    }

    applyComps(order: Order) {
        this.orderService.applyComps(order, this.tab, this.pickupLocation.activeEvent, this.activeMembership);
    }
}
