import {IonInput, ModalController} from '@ionic/angular';
import {Component, HostListener, OnDestroy, ViewChild} from '@angular/core';
import {SubscriberComponent} from './subscriber.component';

@Component({
    template: ''
})
export abstract class BaseModalComponent extends SubscriberComponent implements OnDestroy {
    preventBack = false;
    firstInputTimeout;
    firstFocus;
    disableBackDismiss = false;

    @ViewChild('firstInput') set first(input: IonInput) {
        if (!!input) {
            this.waitForFirstInput(input);
        }
    }

    protected constructor(public modalController: ModalController) {
        super();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (!!this.firstInputTimeout) {
            clearTimeout(this.firstInputTimeout);
        }
    }

    async close(data?) {
        return await this.modalController.dismiss(data);
    }

    @HostListener('window:popstate', ['$event'])
    async backClose(event) {
        if (!this.disableBackDismiss) {
            return await this.modalController.dismiss();
        }
    }

    async waitForFirstInput(input: IonInput) {
        const el = await input.getInputElement() as HTMLInputElement;

        el.addEventListener('focus', (event) => {
            this.firstFocus = true;
            clearTimeout(this.firstInputTimeout);
        });
        await this.checkFocus(input);
    }

    async checkFocus(input: IonInput) {
        if (!this.firstFocus) {
            await input.setFocus();
            this.firstInputTimeout = setTimeout(() => this.checkFocus(input), 200);
        }
    }
}
