<ion-row [formGroup]="formGroup">
    <ion-col size-xs="12" size-md="6">
        <ion-item>
            <ion-label position="floating">First Name</ion-label>
            <ion-input id="firstName" data-cy="first-name-input" name="firstName" type="text" formControlName="firstName" #firstInput
                       required></ion-input>
        </ion-item>
    </ion-col>
    <ion-col size-xs="12" size-md="6">
        <ion-item>
            <ion-label position="floating">Last Name</ion-label>
            <ion-input id="lastName" name="lastName" type="text" formControlName="lastName" required></ion-input>
        </ion-item>
    </ion-col>
    <ng-container *ngIf="!nameOnly">
        <ion-col size-xs="12" size-md="6" *ngIf="!self">
            <ion-item>
                <ion-label position="floating">Email</ion-label>
                <ion-input id="email" data-cy="person-email-input" name="email" formControlName="email"
                           (ionChange)="emailChange.emit()"
                           (ionBlur)="correctEmail()"></ion-input>
            </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-md="6">
            <ion-item>
                <ion-label position="floating">Mobile</ion-label>
                <ion-input id="mobile" data-cy="person-mobile-input" name="mobile" type="tel" bbPhoneMask inputmode="numeric" maxlength="14"
                           minlength="14"
                           autocomplete="off"
                           formControlName="mobile"></ion-input>
            </ion-item>
        </ion-col>

        <ion-col size-xs="12" *ngIf="!self">
            <ion-item *ngIf="!!organization && organization.allowMarketingEmailRequest">
                <ion-label>Email upcoming events and specials for {{ organization.name }}</ion-label>
                <ion-checkbox slot="start" formControlName="optIn"></ion-checkbox>
            </ion-item>
        </ion-col>
    </ng-container>
</ion-row>
