<modal-header title="Sort"></modal-header>

<ion-content>
    <ion-list>
        <ion-reorder-group (ionItemReorder)="reorder($event)" disabled="false">
            <ion-item *ngFor="let item of items">
                <ion-label>
                    {{ item.name }}
                </ion-label>
                <ion-reorder slot="end"></ion-reorder>
            </ion-item>
        </ion-reorder-group>
    </ion-list>
</ion-content>

<modal-footer (submitClicked)="onSubmit()"></modal-footer>
