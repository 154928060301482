import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {formatDate} from '@angular/common';

@Component({
    selector: 'bb-date-input',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.scss'],
})
export class DateInputComponent implements OnInit {
    @Input() label: string;
    @Input() date: string;
    @Input() id: string;
    @Input() min: string;
    @Input() max: string;
    @Input() format = 'MM/dd/YYYY hh:mm aa';
    @Input() hideIcon = false;
    @Input() presentation = 'date-time';
    @Output() dateChange = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    formatDate(val: string) {
        if (!!val) {
            return formatDate(val, this.format, 'en-us', null);
        } else {
            return '';
        }
    }

    fireDateChange(event) {
        const value = event.detail.value;
        if (value !== this.date) {
            this.dateChange.emit(event.detail.value);
        }
    }
}
