<modal-header title="Select Type"></modal-header>
<ion-content>
    <ion-row>
        <ion-col>
            <ion-card class="pointer" (click)="select('beer')">
                <ion-card-content class="ion-text-center">
                    <ion-icon name="beer-outline"></ion-icon>
                    <p>Beer</p>
                </ion-card-content>
            </ion-card>
        </ion-col>
        <ion-col>
            <ion-card class="pointer" (click)="select('other')">
                <ion-card-content class="ion-text-center">
                    <ion-icon name="pricetag-outline"></ion-icon>
                    <p>Anything Else</p>
                </ion-card-content>
            </ion-card>
        </ion-col>
    </ion-row>
</ion-content>

<modal-footer [hideSubmit]="true"></modal-footer>
