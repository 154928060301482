import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {MenuItem} from '../_models/menu-item';

@Injectable({
    providedIn: 'root'
})
export class MenuItemPriceSummaryService extends BaseService<MenuItem> {
    destination = 'menu-item-price-summaries';

    get(menuId: number) {
        return this.http.get(this.baseUrl() + '/' + menuId);
    }
}
