import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Availabilities} from '../../_constants/availabilities';

@Component({
    selector: 'bb-order-type',
    templateUrl: './order-type.component.html',
    styleUrls: ['./order-type.component.scss']
})
export class OrderTypeComponent {
    @Input() orderType: string;
    @Input() iconOnly = false;
    @Output() select = new EventEmitter<any>();

    availabilities = Availabilities;

    segmentChanged(value) {
        this.select.emit({value});
    }
}
