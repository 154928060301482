import {BaseFormModel} from './base-form-model';
import {CardSummary} from './card-summary';
import {PaymentChargeRefund} from './payment-charge-refund';
import {addCurrency} from '../_utils/currency-math';

export class PaymentCharge extends BaseFormModel<PaymentCharge> {
    id: number;
    terminalPayment: boolean;
    success: boolean;
    voided: boolean;
    message: string;
    refunded: number;
    principal: number;
    tip: number;
    surcharge: number;
    surchargeIncludeTip: boolean;
    total: number;
    currency: string;
    status: string;
    createDate: Date;
    updateDate: Date;
    registerId: string;
    registerNickname: string;
    authCode: string;
    cardSummary: CardSummary;
    refunds: PaymentChargeRefund[];
    refundable: boolean;
    voidable: boolean;

    tenderType: string;
    applicationLabel: string;
    issuerApplicationData: string;
    cvmMethod: string;

    totalPrincipalRefunds() {
        return !this.refunds ? 0 : this.refunds.reduce((total, refund) => addCurrency(total, refund.principal), 0);
    }

    totalTipRefunds() {
        return !this.refunds ? 0 : this.refunds.reduce((total, refund) => addCurrency(total, refund.tip), 0);
    }

    init(): void {
        const temp = [];
        if (!!this.refunds) {
            this.refunds.forEach(r => {
                temp.push(new PaymentChargeRefund(r));
            });
            this.refunds = temp;
        }

        this.cardSummary = !!this.cardSummary ? new CardSummary(this.cardSummary) : null;
    }
}
