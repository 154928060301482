import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CashDrawerService} from '../_services/cash-drawer.service';
import {UserService} from '../_services/user.service';
import {User} from '../_models/user';
import {FeatureKeys} from '../_constants/feature-keys';

@Injectable()
export class SafesResolver implements Resolve<any> {
    constructor(
        private userService: UserService,
        private cashDrawerService: CashDrawerService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        let user = this.userService.currentValue;
        if (!!user) {
            user = new User(user);
            if (!user.authorizedTo(FeatureKeys.SAFE_ADMIN, parseInt(route.paramMap.get('orgId'), 10))) {
                return Promise.resolve([]);
            }
        }
        return this.cashDrawerService.safes(route.paramMap.get('orgId'));
    }
}
