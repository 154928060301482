import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Organization} from '../../_models/organization';
import {ModalController} from '@ionic/angular';
import {OrganizationService} from '../../_services/organization.service';
import {ToastService} from '../../_services/toast.service';
import {BaseStepperFormComponent} from '../base-stepper-form.component';
import {TipOutTypes} from '../../_constants/tip-out-types';
import {CashDrawer} from '../../_models/cash-information/cash-drawer';

@Component({
    selector: 'bb-edit-organization-modal',
    templateUrl: './edit-organization-modal.component.html',
    styleUrls: ['./edit-organization-modal.component.scss']
})
export class EditOrganizationModalComponent extends BaseStepperFormComponent implements OnInit {
    @Input() organization: Organization;
    @Input() safes: CashDrawer[];
    editOrganizationForm: FormGroup = new FormGroup({});
    formGroup: FormGroup;

    tipOutTypes = TipOutTypes;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private organizationService: OrganizationService,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        const reg = '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
        const tips = !!this.organization.tipDefinitions ? this.organization.tipDefinitions.split(',') : [];
        const autoTransferDrawer = !!this.organization.autoTransferDrawer
            ? this.safes.find(s => s.id === this.organization.autoTransferDrawer.id)
            : null;
        this.formGroup = this.formBuilder.group({
            description: [this.organization.description, Validators.required],
            receiptTag: this.organization.receiptTag,
            facebook: [this.organization.facebook, Validators.pattern(reg)],
            instagram: [this.organization.instagram, Validators.pattern(reg)],
            twitter: [this.organization.twitter, Validators.pattern(reg)],
            allowTabs: this.organization.allowTabs,
            allowTips: this.organization.allowTips,
            allowOrderNotes: this.organization.allowOrderNotes,
            allowTaxExempt: this.organization.allowTaxExempt,
            allowInvoices: this.organization.allowInvoices,
            allowMarketingEmailRequest: this.organization.allowMarketingEmailRequest,
            autoPrintClaimTab: this.organization.autoPrintClaimTab,
            hideServiceOptions: this.organization.hideServiceOptions,
            requireCardAtClose: this.organization.requireCardAtClose,
            allowQuickCloseAllTabs: this.organization.allowQuickCloseAllTabs,
            autoDeliver: this.organization.autoDeliver,
            preventDrawerCopy: this.organization.preventDrawerCopy,
            tipOutType: this.organization.tipOutType,
            autoFinalAmount: this.organization.autoFinalAmount,
            autoTransferDrawer,
            tip1: [tips.length > 0 ? tips[0] : null, [Validators.min(0), Validators.max(100)]],
            tip2: [tips.length > 1 ? tips[1] : null, [Validators.min(0), Validators.max(100)]],
            tip3: [tips.length > 2 ? tips[2] : null, [Validators.min(0), Validators.max(100)]],
            tip4: [tips.length > 3 ? tips[3] : null, [Validators.min(0), Validators.max(100)]]
        });
    }

    onSubmit() {
        const tipDefinitions = [];
        if (!!this.formGroup.controls.tip1.value || this.formGroup.controls.tip1.value === 0) {
            tipDefinitions.push(Number.parseInt(this.formGroup.controls.tip1.value, 10));
        }
        if (!!this.formGroup.controls.tip2.value || this.formGroup.controls.tip2.value === 0) {
            tipDefinitions.push(Number.parseInt(this.formGroup.controls.tip2.value, 10));
        }
        if (!!this.formGroup.controls.tip3.value || this.formGroup.controls.tip3.value === 0) {
            tipDefinitions.push(Number.parseInt(this.formGroup.controls.tip3.value, 10));
        }
        if (!!this.formGroup.controls.tip4.value || this.formGroup.controls.tip4.value === 0) {
            tipDefinitions.push(Number.parseInt(this.formGroup.controls.tip4.value, 10));
        }

        const org = new Organization({...this.editOrganizationForm.value, ...this.formGroup.value});
        org.tipDefinitions = tipDefinitions.sort((a, b) => {
            return a - b;
        }).join(',');

        this.organizationService.update(org).subscribe(
            async (organization: Organization) => {
                await this.toastService.success('Information updated.');
                await this.close(organization);
            }
        );
    }

    formInitialized(form: FormGroup) {
        this.editOrganizationForm = form;
    }
}
