import {BaseFormModel} from './base-form-model';
import {Sortable} from './sortable';
import {MenuLabel} from './menu-label';

export class MenuItemLabel extends BaseFormModel<MenuItemLabel> implements Sortable {
    id: number;
    name: string;
    sortOrder: number;
    label: MenuLabel;

    getName() {
        return this.name;
    }
}
