import {Component, Input} from '@angular/core';
import {AddressService} from '../../_services/address.service';
import {FormGroup} from '@angular/forms';
import {Address} from '../../_models/address';
import {BaseModalComponent} from '../base-modal.component';
import {AlertController, ModalController} from '@ionic/angular';
import {ToastService} from '../../_services/toast.service';
import {LoadingService} from '../../_services/loading.service';

@Component({
    selector: 'bb-edit-address-modal',
    templateUrl: './edit-address-modal.component.html',
    styleUrls: ['./edit-address-modal.component.css']
})
export class EditAddressModalComponent extends BaseModalComponent {
    editAddressForm: FormGroup = new FormGroup({});
    @Input() address: Address;
    @Input() endpoint: string;
    @Input() parentId: number;

    constructor(
        modalController: ModalController,
        private loadingService: LoadingService,
        private addressService: AddressService,
        private alertController: AlertController,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    async onSubmit() {
        const address = new Address(this.editAddressForm.value);

        this.loadingService.present();
        this.addressService.getGeocoder().geocode({
            address: this.addressService.fullAddress(address)
        }, async (results, status) => {
            // @ts-ignore
            if (status === google.maps.GeocoderStatus.OK && results[0].geometry.location_type === 'ROOFTOP') {
                address.latitude = results[0].geometry.location.lat();
                address.longitude = results[0].geometry.location.lng();
                this.addressService.update(address, this.endpoint, this.parentId).subscribe(
                    async (a: Address) => {
                        await this.toastService.success('Address updated.');
                        this.loadingService.dismiss();
                        await this.close(a);
                    }
                );
            } else {
                this.loadingService.dismiss();
                const alert = await this.alertController.create({
                    cssClass: 'brewbill-alert',
                    header: 'Confirm',
                    message: 'The address was not found. Would you like to proceed anyway?',
                    buttons: [
                        {
                            text: 'No',
                            role: 'cancel',
                            cssClass: 'secondary',
                        }, {
                            text: 'Yes',
                            handler: () => {
                                address.latitude = null;
                                address.longitude = null;
                                this.addressService.update(address, this.endpoint, this.parentId).subscribe(
                                    async (a: Address) => {
                                        await this.toastService.success('Address updated.');
                                        await this.close(a);
                                    }
                                );
                            }
                        }
                    ]
                });

                await alert.present();
            }
        });
    }

    formInitialized(form: FormGroup) {
        this.editAddressForm = form;
    }
}
