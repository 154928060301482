import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {CashSessionNote} from '../_models/cash-information/cash-session-note';

@Injectable({
    providedIn: 'root'
})
export class CashSessionNoteService extends BaseService<CashSessionNote> {
    destination = 'cash-session-notes';
}
