import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItemPrice} from '../../_models/menu-item-price';
import {BaseMenuItemInventoryComponent} from '../base-menu-item-inventory.component';
import {ModalController} from '@ionic/angular';
import {MenuItemInventoryService} from '../../_services/menu-item-inventory.service';
import {OrganizationTerminalService} from '../../_services/organization-terminal.service';
import {MenuItem} from '../../_models/menu-item';
import {MenuItemInventory} from '../../_models/menu-item-inventory';

@Component({
    selector: 'bb-mobile-menu-price-button',
    templateUrl: './mobile-menu-price-button.component.html',
    styleUrls: ['./mobile-menu-price-button.component.scss'],
})
export class MobileMenuPriceButtonComponent extends BaseMenuItemInventoryComponent implements OnInit {
    @Input() menuItem: MenuItem;
    @Input() price: MenuItemPrice;
    @Input() showInventory = false;
    @Output() longPress = new EventEmitter();
    @Output() add = new EventEmitter();
    menuItemInventory: MenuItemInventory;

    constructor(
        modalController: ModalController,
        menuItemInventoryService: MenuItemInventoryService,
        organizationTerminalService: OrganizationTerminalService
    ) {
        super(modalController, menuItemInventoryService, organizationTerminalService);
    }

    ngOnInit() {
        this.subscribe(this.menuItemInventoryService.pickupLocationInventory.subscribe(inventories => {
            this.menuItemInventory = !!inventories && inventories.length > 0 ?
                inventories.find(i => !!i.menuItemPrice && this.price.id === i.menuItemPrice.id) :
                null;
        }));
    }
}
