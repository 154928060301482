<modal-header title="Rating Response"></modal-header>

<ion-content>
    <ion-item lines="none">
        <ion-label>
            <h2 class="tab-header">{{rating.date | date}}</h2>
        </ion-label>
        <bb-rating [rating]="rating.rating" size="medium"></bb-rating>
    </ion-item>
    <ion-list>
        <ion-item lines="none" *ngIf="!!rating.comments">
            <ion-label>
                <h3>You Said</h3>
                <p>{{ rating.comments }}</p>
            </ion-label>
        </ion-item>

        <ion-item lines="none">
            <ion-label>
                <h3>Response</h3>
                <p>{{ !!rating.response ? rating.response : 'None' }}</p>
            </ion-label>
        </ion-item>

        <ion-item *ngIf="!!rating.userOrganizationCredit" lines="none">
            <ion-label>
                <h3>Response Credit</h3>
                <p>{{ rating.userOrganizationCredit.amount | currency }}</p>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>

<modal-footer [hideSubmit]="true"></modal-footer>
