import {BaseFormModel} from './base-form-model';
import {TabNoteDefinition} from './tab-note-definition';
import {Person} from './person';

export class TabNote extends BaseFormModel<TabNote> {
    id: number;
    override: string;
    createdBy: Person;
    createDate: Date;
    definition: TabNoteDefinition;
}
