import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserService} from '../_services/user.service';

@Injectable()
export class NotificationsResolver implements Resolve<any> {
    constructor(private userService: UserService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.userService.getPushOptIns();
    }
}
