import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {MenuSelection} from '../_models/menu-selection';
import {HttpHeaders} from '@angular/common/http';
import {Constants} from '../_constants/constants';

@Injectable({
    providedIn: 'root'
})
export class MenuSelectionService extends BaseService<MenuSelection> {
    destination = 'menu-selections';

    create(selection: MenuSelection) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409,424');
        return this.http.post(this.baseUrl(), selection, {headers});
    }

    update(selection: MenuSelection) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409,424');
        return this.http.put(this.baseUrl(), selection, {headers});
    }
}
