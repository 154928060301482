<ion-row [formGroup]="formGroup">
    <ion-col size-xs="12" *ngIf="user != null">
        <ion-item>
            <ion-label position="floating">
                Temporary Password
            </ion-label>
            <ion-input type="password" name="oldPassword" formControlName="oldPassword"></ion-input>
        </ion-item>
    </ion-col>

    <ion-col size-xs="12" size-md="6" *ngIf="!emailOnly">
        <ion-item>
            <ion-label position="floating">
                {{ (user != null) ? 'New Password' : 'Password' }}
            </ion-label>
            <ion-input data-cy="login-password-input" type="password" formControlName="password"></ion-input>
        </ion-item>
    </ion-col>

    <ion-col size-xs="12" size-md="6" *ngIf="!emailOnly">
        <ion-item>
            <ion-label position="floating">Confirm Password</ion-label>
            <ion-input type="password" name="confirmPassword" formControlName="confirmPassword"></ion-input>
        </ion-item>
    </ion-col>
</ion-row>
<bb-password-requirements *ngIf="!emailOnly"
                          [password]="formGroup.controls.password.value"
                          [confirmPassword]="formGroup.controls.confirmPassword.value">
</bb-password-requirements>
