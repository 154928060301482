import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isEmpty} from 'lodash';

// custom validator to check that either both fields are missing or both are required
export function creditType(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
        const amount = formGroup.controls['amount'];
        const type = formGroup.controls['type'];

        if (!!amount.value && amount.value > 0 && isEmpty(type.value)) {
            type.setErrors({required: true});
        } else {
            type.setErrors(null);
        }
        return;
    };
}
