import {Inject, Injectable} from '@angular/core';
import {MenuItemInventory} from '../_models/menu-item-inventory';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Constants} from '../_constants/constants';
import {BaseObservableService} from './base-obeservable-service';
import {LibConfig, LibConfigService} from '../lib.config';
import {BehaviorSubject, Observable} from 'rxjs';
import {PickupLocationService} from './pickup-location.service';
import {PickupLocation} from '../_models/pickup-location';
import {OrganizationService} from './organization.service';
import {Organization} from '../_models/organization';
import {User} from '../_models/user';
import {UserService} from './user.service';
import {FeatureKeys} from '../_constants/feature-keys';

@Injectable({
    providedIn: 'root'
})
export class MenuItemInventoryService extends BaseObservableService<MenuItemInventory> {
    destination = 'menu-item-inventory';

    public pickupLocationInventory: Observable<MenuItemInventory[]>;
    private pickupLocationInventorySubject: BehaviorSubject<MenuItemInventory[]>;

    public backOfHouseInventory: Observable<MenuItemInventory[]>;
    private backOfHouseInventorySubject: BehaviorSubject<MenuItemInventory[]>;

    pickupLocation: PickupLocation;
    organization: Organization;
    currentUser: User;

    constructor(
        http: HttpClient,
        private pickupLocationService: PickupLocationService,
        private organizationService: OrganizationService,
        private userService: UserService,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);
        this.pickupLocationInventorySubject = new BehaviorSubject<MenuItemInventory[]>(null);
        this.pickupLocationInventory = this.pickupLocationInventorySubject.asObservable();

        this.backOfHouseInventorySubject = new BehaviorSubject<MenuItemInventory[]>(null);
        this.backOfHouseInventory = this.backOfHouseInventorySubject.asObservable();

        this.pickupLocationService.current.subscribe(p => {
            if (!!p && (!this.pickupLocation || p.id !== this.pickupLocation.id)) {
                this.pickupLocation = new PickupLocation(p);
                this.findByPickupLocationId(this.pickupLocation.id)
                    .subscribe((i: MenuItemInventory[]) => this.pickupLocationInventorySubject.next(i));
            } else if (!p) {
                this.pickupLocation = null;
                this.pickupLocationInventorySubject.next([]);
            }
        });

        if (this.config.terminal) {
            this.userService.current.subscribe(u => this.currentUser = !!u ? new User(u) : null);

            this.organizationService.current.subscribe(o => {
                if (!!o && (!this.organization || o.id !== this.organization.id)) {
                    this.organization = new Organization(o);
                    if (!!this.currentUser && this.currentUser.authorizedTo([
                        FeatureKeys.INVENTORY_ADMIN, FeatureKeys.TERMINAL
                    ], this.organization.id)) {
                        this.findByOrganizationId(this.organization.id)
                            .subscribe((i: MenuItemInventory[]) => this.backOfHouseInventorySubject.next(i));
                    } else {
                        this.backOfHouseInventorySubject.next([]);
                    }
                } else if (!o) {
                    this.organization = null;
                    this.backOfHouseInventorySubject.next([]);
                }
            });
        }
    }

    create(menuItemInventory: MenuItemInventory) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409');
        return this.http.post(this.baseUrl(), menuItemInventory, {headers});
    }

    findByOrganizationId(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}`);
    }

    findByPickupLocationId(pickupLocationId: number) {
        return this.http.get(`${this.baseUrl()}/pickup-location/${pickupLocationId}`);
    }

    findByMenuItemId(menuItemId: number | string, menuItemPriceId?: number) {
        return this.http.get(`${this.baseUrl()}/menu-item/${menuItemId}` + (!!menuItemPriceId ? `/${menuItemPriceId}` : ''));
    }

    setEvent(obj: MenuItemInventory) {
        if (!!obj && !!obj.pickupLocation && !!this.pickupLocationInventory && !!this.pickupLocation
            && this.pickupLocation.id === obj.pickupLocation.id) {
            const current = this.pickupLocationInventorySubject.value;
            const index = current.findIndex(i => i.id === obj.id);
            if (obj.deleted) {
                current.splice(index, 1);
            } else if (index > -1) {
                current[index] = obj;
            } else {
                current.push(obj);
            }
            this.pickupLocationInventorySubject.next(current);
        }

        if (!!obj && !obj.pickupLocation && !!this.backOfHouseInventory && !!this.organization
            && this.organization.id === obj.orgId) {
            const current = this.backOfHouseInventorySubject.value;
            const index = current.findIndex(i => i.id === obj.id);
            if (obj.deleted) {
                current.splice(index, 1);
            } else if (index > -1) {
                current[index] = obj;
            } else {
                current.push(obj);
            }
            this.backOfHouseInventorySubject.next(current);
        }
        super.setEvent(obj);
    }

    getPickupLocationInventory() {
        return this.pickupLocationInventorySubject.value;
    }
}
