import {BaseFormModel} from '../base-form-model';

export class Column extends BaseFormModel<Column> {
    id: number;
    key: string;
    keyValue: string;
    accessKey: string;
    format: string;
    rowLabel: boolean;
    includeParent: boolean;
    groupingColumn: boolean;
    label: string;
    aggregation: string;
    summaryAggregation: string;
    sortOrder: number;
}
