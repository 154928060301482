import {Injectable} from '@angular/core';
import {Geolocation, Position} from '@capacitor/geolocation';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GeoLocationService {
    position: Observable<Position>;
    private positionSubject: BehaviorSubject<Position>;

    constructor() {
        this.positionSubject = new BehaviorSubject<any>(null);
        this.position = this.positionSubject.asObservable();
    }

    getPosition() {
        Geolocation.getCurrentPosition().then(p => this.setPosition(p));
    }

    setPosition(position) {
        this.positionSubject.next(position);
    }
}
