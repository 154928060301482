import {Component, Input} from '@angular/core';
import {MenuLabel} from '../../_models/menu-label';

@Component({
    selector: 'bb-menu-label',
    templateUrl: './menu-label.component.html',
    styleUrls: ['./menu-label.component.css']
})
export class MenuLabelComponent {
    @Input() menuLabel: MenuLabel;
}
