<ion-button class="full-footer-button all-options-button"
            [class.low-inventory]="warnInventoryPrice(price)"
            [color]="price.defaultPrice ? 'success' : 'dark'"
            bbLongPress
            (longPress)="longPress.emit()"
            (click)="add.emit()">
    <div *ngIf="price.name !== 'Default'">
        <span class="price-title">{{price.name}}</span>
        <p class="price-amount">{{price.price | currency}}</p>
    </div>
    <div *ngIf="price.name === 'Default'">
        <span>{{price.price | currency}}</span>
    </div>
    <ion-badge *ngIf="showInventory && !!menuItemInventory"
               color="light" slot="end" class="inventory-button-badge"
               (click)="adjustInventory($event, menuItemInventory)">
        <ion-text>{{menuItemInventory.currentCount}}</ion-text>
    </ion-badge>
</ion-button>
