import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderItem} from '../../_models/order-item';
import {Organization} from '../../_models/organization';
import {MenuCategoryItem} from '../../_models/menu-category-item';
import {Menu} from '../../_models/menu';
import {MenuCategory} from '../../_models/menu-category';
import {MenuItem} from '../../_models/menu-item';
import {OrderItemService} from '../../_services/order-item.service';

@Component({
    selector: 'bb-menu-simulator',
    templateUrl: './menu-simulator.component.html',
    styleUrls: ['./menu-simulator.component.css'],
})
export class MenuSimulatorComponent implements OnInit {
    @Input() menu: Menu;
    @Input() organization: Organization;

    @Output() editClicked = new EventEmitter<any>();
    @Output() itemClicked = new EventEmitter<any>();
    @Output() categoryClicked = new EventEmitter<MenuCategory>();
    @Output() addCategoryClicked = new EventEmitter<any>();
    @Output() editCategoryClicked = new EventEmitter<MenuCategory>();
    @Output() removeCategoryClicked = new EventEmitter<MenuCategory>();
    @Output() sortCategoriesClicked = new EventEmitter<any>();
    @Output() uploadItemImageClicked = new EventEmitter<MenuItem>();

    navStack = [];
    currentItem: any;
    orderItem: OrderItem;

    constructor(private orderItemService: OrderItemService) {
    }

    ngOnInit(): void {
        this.refreshNav();
    }

    refreshNav() {
        if (this.navStack.length > 0) {
            this.navStack.forEach(i => {
                i.item = this.refreshItem(i);
            });
        }

        if (!!this.currentItem) {
            this.currentItem.item = this.refreshItem(this.currentItem);
        }
    }

    refreshItem(item) {
        let val = item.item;
        let itemArray;
        switch (item.type) {
        case 'category':
            itemArray = this.menu.menuCategories;
            break;
        case 'item':
            itemArray = this.menu.menuItems;
            break;
        case 'selection':
            itemArray = this.menu.menuSelections;
            break;
        }

        if (!!itemArray) {
            const i = itemArray.find(c => item.related(c));
            if (!!i) {
                val = {...i};
            }
        }

        return val;
    }

    openItem(item: MenuCategoryItem) {
        this.orderItem = this.orderItemService.initializeOrderItem(item.menuItem);
        this.nav('item', item.menuItem);
    }

    openSelection(selection) {
        this.nav('selection', selection);
    }

    openCategory(category) {
        this.nav('category', category);
    }

    headerTitle() {
        return !!this.currentItem ? this.currentItem.item.name : this.organization.name;
    }

    nav(type: string, item: any) {
        if (!!this.currentItem) {
            this.navStack.push(item);
        }
        this.currentItem = {type, item};
    }

    back() {
        if (this.navStack.length > 0) {
            this.currentItem = this.navStack.pop();
        } else {
            this.currentItem = null;
        }
    }
}
