export const environment = {
    production: true,
    testMode: false,
    version: '2.0.1.3',
    apiUrl: 'https://rabbit-tab-api-stage.herokuapp.com',
    mobileUrl: 'https://staging.mobile.brewbill.com',
    firebase: {
        apiKey: 'AIzaSyBZpLAnFLNd6OJ5AZ6XnuwDQYyWwBgGsSM',
        authDomain: 'brewbill-df0d3.firebaseapp.com',
        projectId: 'brewbill-df0d3',
        storageBucket: 'brewbill-df0d3.appspot.com',
        messagingSenderId: '961671913075',
        appId: '1:961671913075:web:5e894b65cc05b2a81fd406',
        measurementId: 'G-WHW07XG0NC',
        vapidKey: 'BJl7r1qNUUfBNvOSu6UT1Ujc59tkXapIVkkxsXziacpD0s-B3swKnQZC67I982i4e1DXuGL2lFpcqHAv3bv47D4'
    }
};
