import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {BaseService} from './base-service';
import {Constants} from '../_constants/constants';
import {CashSessionEmployee} from '../_models/cash-information/cash-session-employee';

@Injectable({
    providedIn: 'root'
})
export class CashSessionEmployeeService extends BaseService<CashSessionEmployee> {
    destination = 'cash-session-employees';

    create(cashSessionEmployee: CashSessionEmployee) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409,412');
        return this.http.post(this.baseUrl(), cashSessionEmployee, {headers});
    }

    update(cashSessionEmployee: CashSessionEmployee) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '412');
        return this.http.put(this.baseUrl(), cashSessionEmployee, {headers});
    }

    bulk(cashSessionId: number, employees: CashSessionEmployee[]) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '412');
        return this.http.put(`${this.baseUrl()}/cash-session/${cashSessionId}/bulk`, employees, {headers});
    }

    findByCashSessionAndPerson(cashSessionId: number, personId: number) {
        const headers = new HttpHeaders().set(Constants.SILENT, 'true');
        return this.http.get(`${this.baseUrl()}/cash-session/${cashSessionId}/person/${personId}`, {headers});
    }
}
