import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {CompDefinition} from '../../_models/comp-definition';
import {FormBuilder, Validators} from '@angular/forms';
import {divideCurrency, subtractCurrency} from '../../_utils/currency-math';
import {OrderItem} from '../../_models/order-item';
import {OrderItemCredit} from '../../_models/order-item-credit';
import {Tab} from '../../_models/tab';
import {BaseCompComponent} from '../base-comp.component';

@Component({
    selector: 'bb-create-order-item-credit',
    templateUrl: './create-order-item-credit.component.html',
    styleUrls: ['./create-order-item-credit.component.scss'],
})
export class CreateOrderItemCreditComponent extends BaseCompComponent implements OnInit {

    @Input() tab: Tab;
    @Input() orderItem: OrderItem;
    @Input() compDefinition: CompDefinition;

    constructor(
        private formBuilder: FormBuilder,
        alertController: AlertController,
        modalController: ModalController
    ) {
        super(alertController, modalController);
    }

    ngOnInit(): void {
        this.full = subtractCurrency(this.orderItem.calcTotal(), this.orderItem.calcCredits());
        this.half = divideCurrency(this.full, 2);
        this.max = !!this.compDefinition && !!this.compDefinition.max && this.compDefinition.max < this.full
            ? this.compDefinition.max
            : this.full;

        this.creditForm = this.formBuilder.group({
            amount: [
                null, [
                    Validators.required, Validators.max(this.max), Validators.min(0)
                ]
            ],
            parentId: this.orderItem.id,
            comment: '',
            compDefinition: this.compDefinition
        });
    }

    onSubmit() {
        this.close(new OrderItemCredit(this.creditForm.value));
    }
}
