import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';
import {TimecardService} from '../../_services/timecard.service';
import {Organization} from '../../_models/organization';
import {Timecard} from '../../_models/timecard';
import {UserService} from '../../_services/user.service';
import {LoadingService} from '../../_services/loading.service';
import {forkJoin} from 'rxjs';
import {OrganizationService} from '../../_services/organization.service';
import {User} from '../../_models/user';
import {Person} from '../../_models/person';
import {Tab} from '../../_models/tab';
import {ToastService} from '../../_services/toast.service';
import {TabService} from '../../_services/tab.service';

@Component({
    selector: 'bb-select-server',
    templateUrl: './select-server.component.html',
    styleUrls: ['./select-server.component.scss'],
})
export class SelectServerComponent extends BaseModalComponent implements OnInit {
    @Input() serverId: number;
    @Input() tabId: number;
    activeTimecards: Timecard[];
    allUsers: User[];
    organization: Organization;
    selected: Person;
    options: Person[];
    source: string;

    constructor(
        private tabService: TabService,
        private timecardService: TimecardService,
        private userService: UserService,
        private loadingService: LoadingService,
        private organizationService: OrganizationService,
        private toastService: ToastService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.organization = this.organizationService.currentValue;
        this.load();
    }

    load() {
        this.loadingService.present();
        forkJoin([
            this.timecardService.active(this.organization.id),
            this.userService.getOrgUsers(this.organization.id)
        ]).subscribe(([timecards, users]) => {
            this.activeTimecards = timecards as Timecard[];
            this.allUsers = users as User[];

            if (!!this.activeTimecards && this.activeTimecards.length > 0
                && (!this.serverId || this.activeTimecards.find(s => s.person.id === this.serverId))) {
                this.source = 'timecards';
                this.optionsFromTimecards();
            } else {
                this.source = 'users';
                this.optionsFromUsers();
            }
            this.loadingService.dismiss();
        });
    }

    optionsFromTimecards() {
        this.options = this.activeTimecards.reduce((acc, i) => {
            acc.push(i.person);
            return acc;
        }, []);
        const found = this.options.find(o => o.id === this.serverId);
        this.selected = !this.selected || !!found ? found : this.selected;
    }

    optionsFromUsers() {
        this.options = this.allUsers.reduce((acc, i) => {
            acc.push(i.person);
            return acc;
        }, []);
        const found = this.options.find(o => o.id === this.serverId);
        this.selected = !this.selected || !!found ? found : this.selected;
    }

    selectServer(person: Person) {
        this.loadingService.present();
        this.selected = person;
        this.tabService.updateServer(this.tabId, this.selected).subscribe(tab => {
            this.loadingService.dismiss();
            this.toastService.success('The server has been updated.');
            this.tabService.setEvent(new Tab(tab));
            this.close(tab);
        }, error => {
            this.loadingService.dismiss();
            if (error.status === 417) {
                this.toastService.error('The server is not valid for this tab.');
            }
        });
    }
}
