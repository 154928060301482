<div class="menu-item-container" [class.low-inventory]="!menuItemPrice && !insufficientInventory() && warnInventory()">
    <ion-grid class="menu-item-price-grid">
        <ion-row>
            <ion-col>
                <h3 class="all-options-title">
                    <span>{{item.menuItem.name}}</span>
                    <ion-icon *ngIf="insufficientInventory()" color="danger" name="alert-circle-outline" class="low-inventory-alert"
                              (click)="adjustInventory($event, exactInventory)"></ion-icon>

                    <bb-keg-status *ngIf="isKegInventory() && !insufficientInventory()"
                                   [menuItemInventory]="inventories[0]"
                                   [radius]="10"
                                   (click)="kickKeg($event)"
                                   class="keg-percent" slot="start"></bb-keg-status>

                    <ion-badge *ngIf="showInventory && !isKegInventory() && !insufficientInventory() && !!exactInventory" color="dark"
                               class="inventory-badge"
                               (click)="adjustInventory($event, exactInventory)">
                        <ion-text>{{exactInventory.currentCount}}</ion-text>
                    </ion-badge>
                </h3>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size-xs="12" class="all-options-price-container ion-no-padding">
                <ion-grid [style.min-width]="(item.menuItem.availablePrices.length * 220) + 'px'">
                    <ion-row>
                        <ion-col *ngFor="let price of item.menuItem.availablePrices; trackBy: getId"
                                 class="price-button-container">
                            <bb-mobile-menu-price-button [menuItem]="menuItem"
                                                         [price]="price"
                                                         [showInventory]="showInventory && !exactInventory"
                                                         (longPress)="addWithPrice(item.menuItem, price, true)"
                                                         (add)="addWithPrice(item.menuItem, price)">
                            </bb-mobile-menu-price-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
