import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {MenuItemService} from '../_services/menu-item.service';

@Injectable()
export class MenuItemRatingResolver implements Resolve<any> {
    constructor(private menuItemService: MenuItemService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.menuItemService.getRating(route.paramMap.get('id'));
    }
}
