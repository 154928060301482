import moment, {MomentInput} from 'moment';
import {Duration} from 'moment/moment';

export function formattedTimeDifference(start: MomentInput, end: MomentInput) {
    return formatDuration(moment.duration(moment(start).diff(moment(end))));
}

export function formattedMilli(milli: number) {
    return formatDuration(moment.duration(milli));
}

export function formatDuration(duration: Duration) {
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes().toString(10).padStart(2, '0');
    const seconds = duration.seconds().toString(10).padStart(2, '0');
    if (days > 1) {
        return `${days} days`;
    } else if (hours > 0) {
        return `${hours}:${minutes}:${seconds}`;
    } else {
        return `${minutes}:${seconds}`;
    }
}

export function formattedDate(date: Date) {
    return moment(date).format('MM/DD/YYYY h:mm:ss A');
}

export function formatToHoursAndMinutes(duration: Duration) {
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes().toString(10).padStart(2, '0');

    return `${hours}:${minutes}`;
}

export function formatToHoursPartial(duration: Duration) {
    let hours = Math.floor(duration.asHours());
    let minutes = Math.round((duration.minutes() / 60) * 10);
    if (minutes === 10) {
        hours++;
        minutes = 0;
    }

    return `${hours}.${minutes}`;
}

const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export function formatMonth(m: number) {
    return (!!m || m === 0) && m <= MONTHS.length ? MONTHS[m - 1] : '';
}

const DAYS_OF_WEEK = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

export function formatDayOfWeek(d: number) {
    return (!!d || d === 0) && d <= DAYS_OF_WEEK.length ? DAYS_OF_WEEK[d] : '';
}

export function offset(date: string, part: string, count: number) {
    const dateSetter = moment(date);

    switch (part) {
    case 'HOUR':
        dateSetter.subtract(count, 'hours');
        break;
    case 'DAY':
        dateSetter.subtract(count, 'days');
        break;
    case 'WEEK':
        dateSetter.subtract(count, 'weeks');
        break;
    case 'MONTH':
        dateSetter.subtract(count, 'months');
        break;
    case 'YEAR':
        dateSetter.subtract(count, 'years');
        break;
    }

    return dateSetter.format();
}

export function formatHour(h: number) {
    return (!!h || h === 0) ? `${h}:00` : '';
}
