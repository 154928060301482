import {Component, Input} from '@angular/core';
import {formatToHoursAndMinutes} from '../../_utils/date-utils';
import {CurrencyPipe, DatePipe, PercentPipe} from '@angular/common';
import {UnderscoreConversionPipe} from '../_pipes/underscore-conversion.pipe';
import {BaseWidgetComponent} from './base-widget.component';
import {Timecard} from '../../_models/timecard';
import {Organization} from '../../_models/organization';
import {TimecardService} from '../../_services/timecard.service';
import {OrganizationService} from '../../_services/organization.service';
import {LoadingService} from '../../_services/loading.service';
import {ModalController} from '@ionic/angular';
import {TimecardComponent} from '../timecard/timecard.component';
import moment from 'moment';

@Component({
    template: ''
})
export abstract class BaseTimecardWidgetComponent extends BaseWidgetComponent {
    timecards: Timecard[];
    @Input() organization: Organization;

    constructor(
        protected timecardService: TimecardService,
        protected organizationService: OrganizationService,
        protected loadingService: LoadingService,
        protected modalController: ModalController,
        currencyPipe: CurrencyPipe,
        percentPipe: PercentPipe,
        datePipe: DatePipe,
        underscoreConversionPipe: UnderscoreConversionPipe
    ) {
        super(currencyPipe, percentPipe, datePipe, underscoreConversionPipe);
    }

    async editTimecard(timecard: Timecard) {
        this.loadingService.present();

        const modal = await this.modalController.create({
            component: TimecardComponent,
            componentProps: {
                timecard
            }
        });

        modal.onDidDismiss().then(dataReturned => {
            if (dataReturned) {
                this.refresh();
            }
        });

        await modal.present();
        this.loadingService.dismiss();
    }

    formatTotalTime(timecard: Timecard) {
        if (!!timecard.shiftDurationSecs) {
            return formatToHoursAndMinutes(moment.duration(timecard.shiftDurationSecs * 1000));
        } else {
            return formatToHoursAndMinutes(moment.duration(+new Date() - +new Date(timecard.clockInTime)));
        }
    }
}
