import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TabService} from '../../_services/tab.service';
import {LoadingService} from '../../_services/loading.service';
import {User} from '../../_models/user';
import {Organization} from '../../_models/organization';
import {Tab} from '../../_models/tab';
import {UIChart} from 'primeng/chart';
import {Constants} from '../../_constants/constants';
import {SubscriberComponent} from '../subscriber.component';
import {UserService} from '../../_services/user.service';
import {UserOrganizationCreditService} from '../../_services/user-organization-credit.service';
import {ModalController} from '@ionic/angular';
import {EditOrganizationCreditComponent} from '../edit-organization-credit/edit-organization-credit.component';
import clone from 'clone';
import {UserOrganizationCreditSummary} from '../../_models/user-organization-credit-summary';
import {UserOrganizationCredit} from '../../_models/user-organization-credit';
import {TabStatuses} from '../../_constants/tab-statuses';
import {PaymentStatuses} from '../../_constants/payment-statuses';

@Component({
    selector: 'bb-user-tab-details',
    templateUrl: './user-tab-details.component.html',
    styleUrls: ['./user-tab-details.component.css']
})
export class UserTabDetailsComponent extends SubscriberComponent implements OnInit, OnChanges {
    @Input() personId: number;
    @Input() organization: Organization;
    @Output() closeClicked: EventEmitter<any> = new EventEmitter();
    @Output() tabDetailsClicked: EventEmitter<Tab> = new EventEmitter();
    @ViewChild(UIChart) chart: UIChart;

    user: User;
    tabs: Tab[];
    summaryChart;
    credits: UserOrganizationCreditSummary[];
    currentUser: User;
    tabStatuses = TabStatuses;
    paymentStatuses = PaymentStatuses;

    constructor(
        private userService: UserService,
        private tabService: TabService,
        private userOrganizationCreditService: UserOrganizationCreditService,
        private loadingService: LoadingService,
        private modalController: ModalController
    ) {
        super();
    }

    async ngOnInit() {
        this.subscribe(this.userService.current.subscribe((user: User) => {
            this.currentUser = !!user ? new User(user) : null;
        }));

        this.subscribe(this.tabService.event.subscribe((tab: Tab) => {
            if (!!tab && !!this.user && tab.user.id === this.user.id) {
                const curTab = this.tabs.filter((t: Tab) => t.id === tab.id);

                if (curTab.length === 1) {
                    this.tabs.splice(this.tabs.indexOf(curTab[0]), 1, new Tab(tab));
                } else {
                    this.tabs.unshift(new Tab(tab));
                }
                this.loadChart();
            }
        }));
        await this.load();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (!changes.user || !!changes.user.previousValue) {
            if (!changes.user || changes.user.previousValue.id !== changes.user.currentValue.id) {
                await this.load();
            }
        }
    }

    async openAddUserCreditModal(credit?: UserOrganizationCreditSummary) {
        const userOrganizationCredit = !!credit ? clone<UserOrganizationCreditSummary[]>(credit) : new UserOrganizationCredit();
        userOrganizationCredit.user = this.user;
        if (!userOrganizationCredit.parentId) {
            userOrganizationCredit.parentId = this.organization.id;
        }

        const modal = await this.modalController.create({
            component: EditOrganizationCreditComponent,
            componentProps: {
                userOrganizationCredit
            }
        });

        await modal.present();
        await modal.onDidDismiss().then((dataReturned) => {
            if (!!dataReturned && !!dataReturned.data) {
                this.credits.unshift(dataReturned.data);
            }
        });
    }

    async load() {
        this.loadingService.present();

        const u = await this.userService.getByPersonId(this.personId).toPromise();
        this.user = new User(u);
        if (this.currentUser.isOrgAdmin(this.organization.id)) {
            await this.userOrganizationCreditService.findByOrgIdAndPersonId(this.organization.id, this.personId)
                .subscribe((credits: UserOrganizationCreditSummary[]) => {
                    this.credits = credits;
                });
        }

        await this.tabService.organizationHistory(this.user.person.id, this.organization.id).subscribe((tabs: Tab[]) => {
            this.tabs = [];
            tabs.forEach(t => this.tabs.push(new Tab(t)));
            this.loadChart();
        });
        this.loadingService.dismiss();
    }

    loadChart() {
        const summary = {
            rf_other: 0
        };
        this.tabs.forEach(tab => {
            let taxedTotal = 0;
            tab.taxes.forEach(tax => {
                if (!summary[tax.name]) {
                    summary[tax.name] = tax.taxedTotal;
                } else {
                    summary[tax.name] += tax.taxedTotal;
                }
                taxedTotal += tax.taxedTotal;
            });

            summary.rf_other += (tab.subtotal - taxedTotal);
        });
        const sumChart = {
            labels: [],
            datasets: [
                {
                    ...Constants.CHART_CONFIG,
                    data: []
                }
            ]
        };
        Object.keys(summary).forEach(k => {
            if (summary[k] > 0) {
                sumChart.labels.push((k !== 'rf_other' ? k : 'Other'));
                sumChart.datasets[0].data.push(summary[k]);
            }
        });
        this.summaryChart = sumChart;
    }

    close() {
        this.closeClicked.emit(null);
    }

    async tabDetails(tab: Tab) {
        this.tabDetailsClicked.emit(tab);
    }

    getTotal() {
        return !!this.chart && !!this.chart.chart && this.chart.chart.getVisibleDatasetCount() > 0
            ? this.chart.chart.getDatasetMeta(0).total
            : 0;
    }
}
