<modal-header title="Search"></modal-header>
<ion-content>
    <ion-item>
        <ion-icon name="search-outline" slot="start"></ion-icon>
        <ion-input data-cy="search-input" placeholder="Search" [(ngModel)]="searchString" (ionChange)="search()"></ion-input>
    </ion-item>
    <ion-list data-cy="search-results">
        <ion-item *ngFor="let pickupLocation of pickupLocations" (click)="openMenu(pickupLocation)" class="pointer">
            <img *ngIf="pickupLocation.orgLogo" [src]="pickupLocation.orgLogo" slot="start" alt="">
            <ion-label>
                {{pickupLocation.organizationName}}
                <p>{{pickupLocation.name}}</p>
            </ion-label>
            <ion-note *ngIf="pickupLocation.distance" slot="end">{{pickupLocation.distance | number: '1.0-1'}}m</ion-note>
        </ion-item>
    </ion-list>
</ion-content>
<modal-footer [hideSubmit]="true"></modal-footer>
