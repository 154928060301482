<modal-header [title]="title"></modal-header>

<ion-content [formGroup]="formGroup">
    <ion-row *ngIf="!!image" class="ion-justify-content-center content">
        <ion-col size-xs="12" class="ion-justify-content-center content">
            <img [src]="image" class="org-logo" alt="">
        </ion-col>
    </ion-row>
    <ion-list class="no-padding no-margin">
        <ion-item lines="none">
            <div class="star-container">
                <bb-rating [rating]="formGroup.controls.rating.value" (select)="rate($event)"></bb-rating>
            </div>
        </ion-item>

        <ion-item lines="none" *ngIf="!!message">
            <p>{{message}}</p>
        </ion-item>
    </ion-list>
    <ion-row>
        <ion-col>
            <ion-item lines="none" *ngIf="!message">
                <ion-label position="stacked">Comments</ion-label>
                <ion-textarea formControlName="comments" maxlength="2000" rows="10"></ion-textarea>
            </ion-item>
        </ion-col>
    </ion-row>
</ion-content>

<modal-footer [disableSubmit]="formGroup.invalid" (submitClicked)="submit()"></modal-footer>
