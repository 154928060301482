import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CustomerAreaPositionService} from '../_services/customer-area-position.service';

@Injectable()
export class CustomerAreaPositionResolver implements Resolve<any> {
    constructor(private customerAreaPositionService: CustomerAreaPositionService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.customerAreaPositionService.get(route.paramMap.get('customerAreaPositionId'));
    }
}
