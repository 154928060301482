import {BaseFormModel} from './base-form-model';
import {MenuItemPrice} from './menu-item-price';

export class KickedKegSales extends BaseFormModel<KickedKegSales> {
    id: number;
    orgId: number;
    menuItemPrice: MenuItemPrice;
    sortOrder: number;
    count: number;
    ouncesSold: number;
    gross: number;
    net: number;
    comps: number;
}
