import {Address} from './address';
import {BaseFormModel} from './base-form-model';
import {Organization} from './organization';
import {User} from './user';

export class Venue extends BaseFormModel<Venue> {
    id: number;
    name: string;
    subdomain: string;
    phone: string;
    address: Address;
    organizations: Organization[];
    north: number;
    south: number;
    east: number;
    west: number;
    owner: User;
}
