import {BaseFormModel} from './base-form-model';
import {MenuSelectionItem} from './menu-selection-item';
import {OrderItem} from './order-item';
import {addCurrency, multiplyCurrency} from '../_utils/currency-math';
import {convertPercentage} from '../_utils/tax-utils';

export class OrderItemSelectionItem extends BaseFormModel<OrderItemSelectionItem> {
    id: number;
    additionalPrice: number;
    selectionCountPrice: number;
    menuSelectionItem: MenuSelectionItem;
    orderItem: OrderItem;
    parentAmount: number;

    public constructor(jsonInput: any) {
        super(jsonInput);
        Object.assign(this, jsonInput);
        this.orderItem = new OrderItem(this.orderItem);
    }

    total(parentAmount: number) {
        let percentageOfParent = null;

        if (!!this.orderItem.selectedPrice) {
            percentageOfParent = this.orderItem.selectedPrice.percentageOfParent ? this.orderItem.selectedPrice.price : null;
        } else {
            percentageOfParent = this.orderItem.menuItem.percentageOfParent();
        }

        if (this.orderItem.menuItem.isVariable()) {
            return this.orderItem.priceOverride;
        } else if (!!percentageOfParent) {
            return multiplyCurrency(parentAmount, convertPercentage(percentageOfParent));
        } else {
            return addCurrency((!!this.orderItem && !!this.orderItem.optionalToppings
                    ? this.orderItem.addedToppingsFilter().reduce((tot, topping) => addCurrency(tot, topping.menuItemTopping.price), 0) : 0),
                (!!this.menuSelectionItem && !!this.menuSelectionItem.additionalPrice ? this.menuSelectionItem.additionalPrice : 0));
        }
    }

    depositTotal() {
        return this.orderItem.menuItem.deposit();
    }
}
