<ion-grid>
    <ion-row>
        <ion-col *ngIf="!multiple && !hideNoSelection" [sizeXs]="sizeXs" [sizeSm]="sizeSm" [sizeMd]="sizeMd" [sizeLg]="sizeLg" [sizeXl]="sizeXl">
            <ion-button class="selection-option" [color]="noneSelected() ? 'primary' : 'light'" (click)="optionSelected.emit(null)">
                {{ noSelectionText }}
            </ion-button>
        </ion-col>
        <ion-col *ngFor="let option of options; trackBy: getId"
                 [sizeXs]="sizeXs" [sizeSm]="sizeSm" [sizeMd]="sizeMd" [sizeLg]="sizeLg" [sizeXl]="sizeXl">
            <ion-button class="selection-option" [color]="isSelected(option) ? 'primary' : 'light'" (click)="optionSelected.emit(option)">
                <ng-template [ngTemplateOutlet]="displayTemplate" [ngTemplateOutletContext]="{$implicit: option}"></ng-template>
            </ion-button>
        </ion-col>
    </ion-row>
</ion-grid>
