import {Component, ContentChild, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {reorder} from '../../_utils/sortable-utils';

@Component({
    selector: 'bb-filter-list',
    templateUrl: './filter-list.component.html',
    styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements OnChanges {
    @Input() id: string;
    @Input() list: any[];
    @Input() selected: any[];
    @Input() filterBy: string[];
    @Input() placeholder = 'Existing Item';
    @Input() customSelect = false;
    @Input() sortable = false;
    @Output() addItem = new EventEmitter<any>();
    @Output() removeItem = new EventEmitter<any>();
    @Output() itemSelected = new EventEmitter<any>();
    @Output() sorted = new EventEmitter<any>();

    preventBlurEvent = false;

    @ContentChild('displayTemplate') displayTemplate: TemplateRef<any>;
    @ContentChild('searchListTemplate') searchListTemplate: TemplateRef<any>;

    focused = false;
    searchString = '';
    filteredItems: any[] = [];

    constructor() {
    }

    ngOnChanges(change: SimpleChanges) {
        if (change.selected && !!this.searchString) {
            this.searchString = '';
        }
    }

    preventBlur() {
        this.preventBlurEvent = true;
    }

    focus() {
        this.focused = true;
        this.filterItems();
    }

    blurred() {
        if (!this.preventBlurEvent) {
            this.focused = false;
        }
    }

    selectItem(item: any) {
        if (this.customSelect) {
            this.itemSelected.emit(item);
        } else {
            if (this.selected.find(obj => obj.id === item.id) == null) {
                this.selected.push(item);
            }
        }
        this.clearSearch();
    }

    clearSearch() {
        this.preventBlurEvent = false;
        this.focused = false;
        this.searchString = '';
    }

    remove(item: any) {
        this.selected.splice(this.selected.indexOf(item), 1);
        this.removeItem.emit(item);
    }

    openAddItem() {
        this.addItem.emit('open');
    }

    reorder(event) {
        reorder(this.selected, event);
    }

    filterItems() {
        if (!!this.searchString) {
            const query = this.searchString.toLowerCase();

            this.filteredItems = this.list.filter((item: any) => {
                let found = false;

                this.filterBy.forEach(s => {
                    if (item[s].toLowerCase().indexOf(query) > -1) {
                        found = true;
                        return;
                    }
                });
                return found;
            });
        } else {
            this.filteredItems = [...this.list];
        }
    }
}
