import {Inject, Injectable} from '@angular/core';
import {BaseObservableService} from './base-obeservable-service';
import {Membership} from '../_models/membership';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Constants} from '../_constants/constants';
import {BehaviorSubject, Observable} from 'rxjs';
import {PickupLocation} from '../_models/pickup-location';
import {Organization} from '../_models/organization';
import {LibConfig, LibConfigService} from '../lib.config';
import {OrganizationTerminalService} from './organization-terminal.service';
import {User} from '../_models/user';
import {UserService} from './user.service';
import {OrganizationService} from './organization.service';

@Injectable({
    providedIn: 'root'
})
export class MembershipService extends BaseObservableService<Membership> {
    destination = 'memberships';

    public memberships: Observable<Membership[]>;
    private membershipsSubject: BehaviorSubject<Membership[]>;

    pickupLocation: PickupLocation;
    organization: Organization;
    currentUser: User;
    terminalMode = false;

    constructor(
        http: HttpClient,
        @Inject(LibConfigService) config: LibConfig,
        private organizationTerminalService: OrganizationTerminalService,
        private organizationService: OrganizationService,
        private userService: UserService
    ) {
        super(http, config);
        this.membershipsSubject = new BehaviorSubject<Membership[]>([]);
        this.memberships = this.membershipsSubject.asObservable();
        this.terminalMode = this.organizationTerminalService.isTerminal;

        this.organizationService.current.subscribe(o => this.organization = !!o ? new Organization(o) : null);

        this.userService.current.subscribe(u => {
            this.currentUser = !!u ? new User(u) : null;
            if (!this.currentUser) {
                if (!!this.currentValue && !!this.currentValue.id) {
                    this.setCurrent(null);
                }
                this.setMemberships([]);
            }
        });
    }

    create(membership: Membership) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409');
        return this.http.post(this.baseUrl(), membership, {headers});
    }

    removeCardOnFile(id: number) {
        return this.http.put(`${this.baseUrl()}/${id}/remove-card-on-file`, {});
    }

    updateAutoRenewal(id: number, value: boolean) {
        return this.http.put(`${this.baseUrl()}/${id}/auto-renewal/${value}`, {});
    }

    findByTerminalCard(organizationId: number, terminalId: string, referenceId: string) {
        const headers = new HttpHeaders().set('timeout', '30000');
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/terminal/${terminalId}/${referenceId}`,
            {headers});
    }

    addPersonInfo(member: Membership) {
        return this.http.put(`${this.baseUrl()}/person`, member);
    }

    claim(member: Membership) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409,417');
        return this.http.put(`${this.baseUrl()}/${member.id}/claim`, member, {headers});
    }

    changeCardOnFile(id: number, terminalId: string, referenceId: string) {
        let headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406,410,423,424');
        headers = headers.set('timeout', '30000');
        return this.http.put(`${this.baseUrl()}/${id}/terminal/${terminalId}/change-card-on-file/${referenceId}`, {}, {headers});
    }

    findByUser() {
        const headers = new HttpHeaders().set(Constants.SILENT, 'true');
        return this.http.get(this.baseUrl(), {headers});
    }

    findSummariesByUser() {
        return this.http.get(`${this.baseUrl()}/summary`);
    }

    findAllByOrganizationId(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/all`);
    }

    findActiveByOrganizationId(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/active`);
    }

    findAllByMembershipProgramId(membershipProgramId: number) {
        return this.http.get(`${this.baseUrl()}/membership-program/${membershipProgramId}`);
    }

    findActiveByMembershipProgramId(membershipProgramId: number) {
        return this.http.get(`${this.baseUrl()}/membership-program/${membershipProgramId}/active`);
    }

    emailReceipt(renewalId: number) {
        return this.http.get(`${this.baseUrl()}/renewal/${renewalId}/receipt`);
    }

    getSummarySilent(id: number | string) {
        const headers = new HttpHeaders().set(Constants.SILENT, 'true');
        return this.http.get(`${this.baseUrl()}/${id}/summary`, {headers});
    }

    getVisitSummary(id: number | string) {
        const headers = new HttpHeaders().set(Constants.SILENT, 'true');
        return this.http.get(`${this.baseUrl()}/${id}/visit-summary`, {headers});
    }

    loadMembership(id: number) {
        this.getSilent(id).subscribe(m => {
            this.setCurrent(new Membership(m));
        }, error => {
            console.log(error);
        });
    }

    setMembership(m: Membership) {
        let memberships = this.membershipsValue;
        if (!memberships) {
            memberships = [];
        }

        const existingIndex = memberships.findIndex(i => i.id === m.id);
        const membership = new Membership(m);
        if (membership.status === 'ACTIVE') {
            if (existingIndex > -1) {
                memberships[existingIndex] = membership;
            } else {
                memberships.push(membership);
            }
        } else if (existingIndex > -1) {
            memberships.splice(existingIndex, 1);
        }

        if ((!!this.currentValue && this.currentValue.id === membership.id)
            || (!this.terminalMode
                && !!membership.person
                && !this.currentValue
                && !!this.organization
                && !!this.currentUser
                && this.organization.id === membership.orgId
                && this.currentUser.person.id === membership.person.id
            )) {
            this.setCurrent(membership.status === 'ACTIVE' ? membership : null);
        }

        this.setMemberships(memberships);
    }

    setMemberships(memberships: Membership[]) {
        this.membershipsSubject.next(memberships);
    }

    public get membershipsValue(): Membership[] {
        return this.membershipsSubject.value;
    }
}
