import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseModalComponent} from '../base-modal.component';
import {CustomerArea} from '../../_models/customer-area';
import {CustomerAreaPosition} from '../../_models/customer-area-position';

@Component({
    selector: 'bb-change-customer-position',
    templateUrl: './change-customer-position.component.html',
    styleUrls: ['./change-customer-position.component.scss'],
})
export class ChangeCustomerPositionComponent extends BaseModalComponent implements OnInit {
    @Input() customerAreas: CustomerArea[];
    @Input() position: CustomerAreaPosition;
    sanitizedCustomerAreas = [];
    currentArea: CustomerArea;
    currentPosition: CustomerAreaPosition;
    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.customerAreas.forEach(c => {
            this.sanitizedCustomerAreas.push(new CustomerArea(c));
        });

        if (!!this.position) {
            this.currentArea = this.sanitizedCustomerAreas.find(c => c.id === this.position.areaId);
            this.currentPosition = this.currentArea.positions.find(c => c.id === this.position.id);
        } else if (this.sanitizedCustomerAreas.length === 1) {
            this.currentArea = this.sanitizedCustomerAreas[0];
        }

        this.formGroup = this.formBuilder.group({
            area: [this.currentArea, Validators.required],
            position: [this.currentPosition, Validators.required]
        });
    }

    onSubmit(position) {
        this.close(position);
    }
}
