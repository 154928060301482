<ion-row [formGroup]="formGroup">
    <ion-col size-xs="12" size-md="6" size-lg="6">
        <ion-item>
            <ion-label position="floating">Name</ion-label>
            <ion-input type="text" formControlName="name" #firstInput></ion-input>
        </ion-item>
    </ion-col>
    <ion-col size-xs="12" size-md="6" size-lg="6">
        <ion-item>
            <ion-label position="floating">Phone</ion-label>
            <ion-input type="tel" formControlName="phone" bbPhoneMask inputmode="numeric" maxlength="14" minlength="14">
            </ion-input>
        </ion-item>
    </ion-col>
    <ion-col size-lg="6" size-md="12">
        <ion-item>
            <ion-label position="floating">Website</ion-label>
            <ion-input type="text" formControlName="website"></ion-input>
        </ion-item>
    </ion-col>
</ion-row>
