import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {TabReportEmail} from '../_models/report/tab-report-email';

@Injectable({
    providedIn: 'root'
})
export class TabReportEmailService extends BaseService<TabReportEmail> {
    destination = 'tab-report-emails';
}
