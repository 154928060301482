import {Injectable} from '@angular/core';
import {BaseObservableService} from './base-obeservable-service';
import {KickedKeg} from '../_models/kicked-keg';

@Injectable({
    providedIn: 'root'
})
export class KickedKegService extends BaseObservableService<KickedKeg> {
    destination = 'kicked-keg';

    findByMenuItemId(menuItemId: number | string) {
        return this.http.get(`${this.baseUrl()}/menu-item/${menuItemId}`);
    }

    findSummaryByMenuItemId(menuItemId: number | string) {
        return this.http.get(`${this.baseUrl()}/menu-item/${menuItemId}/summary`);
    }
}
