import {BaseFormModel} from './base-form-model';
import {OrganizationRole} from './organization-role';
import {MenuItemPriceSummary} from './menu-item-price-summary';
import {MembershipProgram} from './membership-program';
import {TabNoteDefinition} from './tab-note-definition';

export class CompDefinition extends BaseFormModel<CompDefinition> {
    id: number;
    orgId: number;
    name: string;
    description: string;
    type: string;
    availability: string;
    color: string;
    contrastColor: string;
    amount: number;
    max: number;
    autoApply: boolean;
    requiredCount: number;
    rewardCount: number;
    priority: string;
    limitDurationPart: string;
    limitDurationCount: number;
    limitCount: number;
    roles: OrganizationRole[];
    items: MenuItemPriceSummary[];
    conditionItems: MenuItemPriceSummary[];
    membershipProgram: MembershipProgram;
    selected: boolean;
    requiredTabNote: TabNoteDefinition;
    sortOrder: number;
}
