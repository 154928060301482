import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PickupLocation} from '../../_models/pickup-location';
import {MapMarker} from '@angular/google-maps';

@Component({
    selector: 'bb-pickup-location-map-marker',
    templateUrl: './pickup-location-map-marker.component.html',
    styleUrls: ['./pickup-location-map-marker.component.css'],
})
export class PickupLocationMapMarkerComponent implements OnInit {
    @Input() marker: any;
    @Input() pickupLocation: PickupLocation;
    @Output() markerClick: EventEmitter<any> = new EventEmitter();

    @ViewChild(MapMarker) mapMarker: MapMarker;

    constructor() {
    }

    ngOnInit() {
    }

    animatePosition(latitude, longitude) {
        const curPosition = this.mapMarker.marker.getPosition();

        const deltaLat = (latitude - curPosition.lat()) / 100;
        const deltaLng = (longitude - curPosition.lng()) / 100;

        const delay = 10 * .5;
        for (let i = 0; i < 100; i++) {
            ((ind) => {
                setTimeout(
                    () => {
                        let lat = this.mapMarker.marker.getPosition().lat();
                        let lng = this.mapMarker.marker.getPosition().lng();
                        lat += deltaLat;
                        lng += deltaLng;
                        this.setPosition(lat, lng);
                    }, delay * ind
                );
            })(i);
        }
    }

    setPosition(latitude, longitude) {
        this.pickupLocation.latitude = latitude;
        this.pickupLocation.longitude = longitude;
        this.mapMarker.marker.setPosition(new google.maps.LatLng(this.pickupLocation.latitude, this.pickupLocation.longitude));
    }
}
