import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {BaseModalComponent} from '../base-modal.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoadingService} from '../../_services/loading.service';
import {UserOrganizationCredit} from '../../_models/user-organization-credit';
import {UserOrganizationCreditService} from '../../_services/user-organization-credit.service';

@Component({
    selector: 'bb-edit-organization-credit',
    templateUrl: './edit-organization-credit.component.html',
    styleUrls: ['./edit-organization-credit.component.css']
})
export class EditOrganizationCreditComponent extends BaseModalComponent implements OnInit {
    @Input() userOrganizationCredit: UserOrganizationCredit;
    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private userOrganizationCreditService: UserOrganizationCreditService,
        private loadingService: LoadingService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            amount: [
                (!!this.userOrganizationCredit && this.userOrganizationCredit.amount > 0)
                    ? this.userOrganizationCredit.amount : '',
                [Validators.min(0), Validators.required]
            ],
            type: [
                (!!this.userOrganizationCredit && !!this.userOrganizationCredit.type)
                    ? this.userOrganizationCredit.type : null,
                Validators.required
            ]
        });
    }

    async submit() {
        this.loadingService.present();
        this.userOrganizationCredit.amount = this.formGroup.controls.amount.value;
        this.userOrganizationCredit.type = this.formGroup.controls.type.value;

        if (!!this.userOrganizationCredit.id) {
            this.userOrganizationCreditService.update(this.userOrganizationCredit).subscribe(c => {
                this.loadingService.dismiss();
                this.close(c);
            });
        } else {
            this.userOrganizationCreditService.create(this.userOrganizationCredit).subscribe(c => {
                this.loadingService.dismiss();
                this.close(c);
            });
        }
    }
}
