import {BaseFormModel} from './base-form-model';

export class TabSearchResult extends BaseFormModel<TabSearchResult> {
    id: number;
    tabNumber: string;
    name: string;
    serverName: string;
    email: string;
    subtotal: number;
    invoiced: number;
    tip: number;
    status: string;
    startTime: string;
    paymentsTotal: number;
    refundsTotal: number;
    creditsTotal: number;
    paymentStatus: string;
    deviceId: string;
}
