import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseForm} from '../base-form';
import {Organization} from '../../../_models/organization';

@Component({
    selector: 'bb-organization-form',
    templateUrl: './organization-form.component.html',
    styleUrls: ['./organization-form.component.scss'],
})
export class OrganizationFormComponent extends BaseForm {
    @Input() organization: Organization;

    constructor(private formBuilder: FormBuilder) {
        super();
        this.organization = this.organization == null ? new Organization({}) : this.organization;
    }

    setupFormGroup(): FormGroup {
        const reg = '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
        return this.formBuilder.group({
            id: this.organization.id,
            allowTips: true,
            allowTabs: true,
            name: [this.organization.name, Validators.required],
            phone: [this.organization.phone, Validators.required],
            website: [this.organization.website, Validators.pattern(reg)]
        });
    }
}
