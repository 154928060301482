import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {QuickbooksDefinition} from '../_models/quickbooks/quickbooks-definition';

@Injectable({
    providedIn: 'root'
})
export class QuickbooksDefinitionService extends BaseService<QuickbooksDefinition> {
    destination = 'quickbooks-definitions';

    findByOrganizationId(organizationId: number) {
        return this.http.get(this.baseUrl() + `/organization/${organizationId}`);
    }

    updateStatus(organizationId: number, active: boolean) {
        return this.http.put(this.baseUrl() + `/organization/${organizationId}/active/${active}`, {});
    }
}
