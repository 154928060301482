import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {OrderService} from '../../_services/order.service';
import {Order} from '../../_models/order';
import {IonFab} from '@ionic/angular';
import {SubscriberComponent} from '../subscriber.component';
import {OrderStatuses} from '../../_constants/order-statuses';
import {Availabilities} from '../../_constants/availabilities';
import {PickupLocationService} from '../../_services/pickup-location.service';
import {PickupLocation} from '../../_models/pickup-location';
import {OrderModalService} from '../../_services/order-modal.service';

@Component({
    selector: 'bb-active-orders',
    templateUrl: './active-orders.component.html',
    styleUrls: ['./active-orders.component.scss']
})
export class ActiveOrdersComponent extends SubscriberComponent implements OnInit {
    @ViewChild('ordersFab') ionFab: IonFab;

    orders: Order[] = null;
    order: Order;
    pickupLocation: PickupLocation;

    orderStatuses = OrderStatuses;
    availabilities = Availabilities;

    constructor(
        private pickupLocationService: PickupLocationService,
        private orderService: OrderService,
        private orderModalService: OrderModalService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribe(this.pickupLocationService.current.subscribe(p => {
            this.pickupLocation = !!p ? new PickupLocation(p) : null;
        }));

        this.subscribe(this.orderService.current.subscribe(o => {
            this.order = !!o ? new Order(o) : null;
        }));

        this.subscribe(this.orderService.activeOrders.subscribe(async act => {
            if (act && act.length > 0) {
                const incoming = [];
                act.forEach(i => {
                    incoming.push(new Order(i));
                });

                this.orders = incoming.sort((a: Order, b: Order) => a.statusPriority() - b.statusPriority());

                if (!!this.ionFab && this.ionFab.activated) {
                    await this.ionFab.close();
                }
            } else {
                this.orders = null;
            }

            this.changeDetectorRef.detectChanges();
        }));
    }

    async click(order: Order) {
        await this.orderModalService.viewOrder(order);
    }

    async viewOrder() {
        await this.orderService.confirmOrder(this.order);
    }

    async checkSingleOrder() {
        if (!!this.orders && this.orders.length === 1) {
            await this.click(this.orders[0]);
        }
    }
}
