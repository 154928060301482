import {Component, Input} from '@angular/core';
import {TabReportService} from '../../../_services/tab-report.service';
import {CurrencyPipe, DatePipe, PercentPipe} from '@angular/common';
import {offset} from '../../../_utils/date-utils';
import {BaseWidgetComponent} from '../base-widget.component';
import {UnderscoreConversionPipe} from '../../_pipes/underscore-conversion.pipe';

@Component({
    selector: 'bb-single-item-widget',
    templateUrl: './single-item-widget.component.html',
    styleUrls: ['./single-item-widget.component.scss'],
})
export class SingleItemWidgetComponent extends BaseWidgetComponent {
    @Input() comparisonOffsetPart: string;
    @Input() comparisonOffsetCount: number;
    value: string;
    change: number;

    constructor(
        private tabReportService: TabReportService,
        currencyPipe: CurrencyPipe,
        percentPipe: PercentPipe,
        datePipe: DatePipe,
        underscoreConversionPipe: UnderscoreConversionPipe
    ) {
        super(currencyPipe, percentPipe, datePipe, underscoreConversionPipe);
    }

    refresh() {
        this.loading = true;
        this.dataLoading.emit();

        let comparisonStartDate = null;
        let comparisonEndDate = null;
        if (!!this.comparisonOffsetPart && !!this.comparisonOffsetCount) {
            comparisonStartDate = offset(this.startDate, this.comparisonOffsetPart, this.comparisonOffsetCount);
            comparisonEndDate = offset(this.endDate, this.comparisonOffsetPart, this.comparisonOffsetCount);
        }

        this.tabReportService.single(this.tabReport.id, this.startDate, this.endDate,
            comparisonStartDate, comparisonEndDate, this.selectedCategories, this.selectedPickupLocations, true)
            .subscribe((result: any) => {
                const trr = result.value;
                const column = trr.columns[0];
                if (trr.data.length > 0) {
                    this.value = this.getRowValue(trr.data[0], column);
                } else {
                    this.value = '0';
                }

                if (!this.value) {
                    this.value = '0';
                }
                this.change = result.change;

                this.loading = false;
                this.error = false;
                this.dataReady.emit();
            }, () => {
                this.value = null;
                this.change = null;
                this.loading = false;
                this.error = true;
            });
    }

    changeDirection() {
        if (this.change > 0) {
            return 'arrow-up-outline';
        } else if (this.change < 0) {
            return 'arrow-down-outline';
        } else {
            return '';
        }
    }
}
