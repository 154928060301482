import {BaseFormModel} from './base-form-model';

export class DailyPaymentSummary extends BaseFormModel<DailyPaymentSummary> {
    paymentsDate: Date;
    creditsTotal: number;
    tabChargesTips: number;
    tabChargesTotal: number;
    tabChargeSurcharges: number;
    tabChargeRefundsTips: number;
    tabChargeRefundsTotal: number;
    giftCardPurchaseChargesTotal: number;
    giftCardPurchaseChargeSurcharges: number;
    giftCardPurchaseChargeRefundsTotal: number;
    chargesTotal: number;
    tabCashTips: number;
    tabCashTotal: number;
    giftCardPurchaseCashTotal: number;
    cashTotal: number;
    giftCardPaymentsTips: number;
    giftCardPaymentsTotal: number;
    tipsTotal: number;
    paymentsTotal: number;
}
