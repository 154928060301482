import {BaseFormModel} from './base-form-model';
import {MenuItemGroupItem} from './menu-item-group-item';

export class MenuItemGroup extends BaseFormModel<MenuItemGroup> {
    id: number;
    name: string;
    menuItems: MenuItemGroupItem[];
    imageAddress: string;

    init(): void {
        const temp = [];
        if (!!this.menuItems) {
            this.menuItems.forEach(i => temp.push(new MenuItemGroupItem(i)));
            this.menuItems = temp;
        }
    }
}
