import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {LibConfig, LibConfigService} from '../lib.config';
import {Constants} from '../_constants/constants';

@Injectable()
export abstract class BaseService<T> {
    abstract destination: string;

    constructor(
        public http: HttpClient,
        @Inject(LibConfigService) public config: LibConfig
    ) {
    }

    baseUrl() {
        return this.config.apiUrl + '/' + this.destination;
    }

    create(obj: T, paramMap?) {
        return this.http.post(this.baseUrl() + this.addParameters(paramMap), obj);
    }

    list(paramMap?) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '422');
        return this.http.get(this.baseUrl() + this.addParameters(paramMap), {headers});
    }

    get(id: any, paramMap?) {
        return this.http.get(this.config.apiUrl + '/' + this.destination + '/' + id + this.addParameters(paramMap));
    }

    getSilent(id: any, paramMap?) {
        const headers = new HttpHeaders().set(Constants.SILENT, 'true');
        return this.http.get(this.config.apiUrl + '/' + this.destination + '/' + id + this.addParameters(paramMap), {headers});
    }

    update(obj: T, paramMap?) {
        return this.http.put(this.baseUrl() + this.addParameters(paramMap), obj);
    }

    delete(id: number, paramMap?) {
        return this.http.delete(this.baseUrl() + '/' + id + this.addParameters(paramMap));
    }

    addParameters(paramMap: any) {
        if (!paramMap || Object.keys(paramMap).length === 0) {
            return '';
        }

        let paramString = null;
        Object.keys(paramMap).forEach(key => {
            if (!paramString) {
                paramString = `?${key}=${paramMap[key]}`;
            } else {
                paramString += `&${key}=${paramMap[key]}`;
            }
        });
        return paramString;
    }
}
