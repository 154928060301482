import {Injectable} from '@angular/core';
import {BaseObservableService} from './base-obeservable-service';
import {OrganizationEvent} from '../_models/organization-event';

@Injectable({
    providedIn: 'root'
})
export class OrganizationEventService extends BaseObservableService<OrganizationEvent> {
    destination = 'organization-events';
}
