import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';
import {BehaviorSubject, Observable} from 'rxjs';
import moment from 'moment/moment';

@Injectable({
    providedIn: 'root'
})
export class SystemReportService {
    destination = 'system-reports';

    public dailyPaymentSummariesSearchCriteria: Observable<any>;
    private dailyPaymentSummariesSearchCriteriaSubject: BehaviorSubject<any>;

    constructor(
        public http: HttpClient,
        @Inject(LibConfigService) public config: LibConfig
    ) {
        const dateSetter = moment();
        dateSetter.set({hour: 0, minute: 0, second: 0, millisecond: 0});
        dateSetter.add(1, 'days');

        const endDate = dateSetter.format();
        dateSetter.subtract(8, 'days');
        const startDate = dateSetter.format();

        this.dailyPaymentSummariesSearchCriteriaSubject = new BehaviorSubject<any>({cacheTime: new Date(), startDate, endDate});
        this.dailyPaymentSummariesSearchCriteria = this.dailyPaymentSummariesSearchCriteriaSubject.asObservable();
    }

    baseUrl() {
        return this.config.apiUrl + '/' + this.destination;
    }

    getDailyPaymentSummaries(organizationId: number, startTime: string, endTime: string) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/daily-payment-summaries?startTime=${startTime.replace('+', '%2b')}&endTime=${endTime.replace('+', '%2b')}`);
    }

    getDailyPaymentSummariesTotals(organizationId: number, startTime: string, endTime: string) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/daily-payment-summaries-totals?startTime=${startTime.replace('+', '%2b')}&endTime=${endTime.replace('+', '%2b')}`);
    }

    getDailyPaymentSummariesCsv(organizationId: number, startTime: string, endTime: string) {
        const httpOptions = {
            responseType: 'blob' as 'json',
        };
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/daily-payment-summaries/csv?startTime=${startTime.replace('+', '%2b')}&endTime=${endTime.replace('+', '%2b')}`, httpOptions);
    }

    getDailyPaymentSummariesSearchCriteria(): any {
        const val = this.dailyPaymentSummariesSearchCriteriaSubject.getValue();
        const current = new Date();
        const difference = current.getTime() - val.cacheTime.getTime();
        const hoursMilli = 1000 * 60 * 60; // 1 hour
        if (Math.abs(difference) < hoursMilli) {
            val.cacheTime = new Date();
            this.dailyPaymentSummariesSearchCriteriaSubject.next(val);
            return val;
        } else {
            const dateSetter = moment();
            dateSetter.set({hour: 0, minute: 0, second: 0, millisecond: 0});
            dateSetter.add(1, 'days');

            const endDate = dateSetter.format();
            dateSetter.subtract(8, 'days');
            const startDate = dateSetter.format();
            const newVal = {cacheTime: new Date(), startDate, endDate};
            this.dailyPaymentSummariesSearchCriteriaSubject.next(newVal);
            return newVal;
        }
    }
}
