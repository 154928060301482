import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {OrganizationService} from '../_services/organization.service';

@Injectable()
export class OrganizationResolver implements Resolve<any> {
    constructor(private organizationService: OrganizationService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.organizationService.get(route.paramMap.get('orgId'));
    }
}
