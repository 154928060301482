import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {ToastService} from '../../_services/toast.service';
import {LoadingService} from '../../_services/loading.service';
import {User} from '../../_models/user';
import {BaseModalComponent} from '../base-modal.component';
import {UserService} from '../../_services/user.service';
import {OrganizationUserService} from '../../_services/organization-user.service';
import {OrganizationUser} from '../../_models/organization-user';
import {Organization} from '../../_models/organization';
import {debounce} from 'lodash';
import {FeatureKeys} from '../../_constants/feature-keys';

@Component({
    selector: 'bb-invite-user-modal',
    templateUrl: './invite-user-modal.component.html',
    styleUrls: ['./invite-user-modal.component.scss']
})
export class InviteUserModalComponent extends BaseModalComponent implements OnInit {
    @Input() organization: Organization;
    formGroup: FormGroup;
    user: User;
    featureKeys = FeatureKeys;

    constructor(
        private formBuilder: FormBuilder,
        private organizationUserService: OrganizationUserService,
        private userService: UserService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        modalController: ModalController
    ) {
        super(modalController);
        this.emailChange = debounce(this.emailChange, 250);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            parentId: this.organization.id,
            roles: [],
            admin: false,
            owner: false,
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            mobile: [
                '', [
                    Validators.minLength(14),
                    Validators.maxLength(14)
                ]
            ],
            email: [
                '', Validators.compose([
                    Validators.required,
                    Validators.email,
                    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
                ])
            ]
        });
    }

    emailChange() {
        if (this.formGroup.controls.email.valid) {
            this.userService.findByEmail(this.formGroup.controls.email.value).subscribe(async (u: User) => {
                this.user = !!u ? new User(u) : null;
                if (!!this.user) {
                    this.formGroup.controls.firstName.setValue(this.user.person.firstName);
                    this.formGroup.controls.lastName.setValue(this.user.person.lastName);
                    this.formGroup.controls.mobile.setValue(this.user.person.mobile);
                }
            });
        } else {
            this.user = null;
        }
    }

    async onSubmit() {
        this.loadingService.present();
        const organizationUser = new OrganizationUser(this.formGroup.value);
        this.organizationUserService.create(organizationUser).subscribe((u: OrganizationUser) => {
            this.toastService.success(`${organizationUser.firstName} ${organizationUser.lastName} has been granted access.`);
            this.close('success');
            this.loadingService.dismiss();
        }, error => {
            if (error.status === 409) {
                this.toastService.error('The user already exists for this organization.');
            }
        });
    }
}
