import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';
import {Tab} from '../../_models/tab';
import {TabService} from '../../_services/tab.service';
import {CardSummary} from '../../_models/card-summary';
import {LoadingService} from '../../_services/loading.service';
import {addCurrency, multiplyCurrency} from '../../_utils/currency-math';
import {OrganizationService} from '../../_services/organization.service';
import {Organization} from '../../_models/organization';

@Component({
    selector: 'bb-select-existing-card',
    templateUrl: './select-existing-card.component.html',
    styleUrls: ['./select-existing-card.component.scss'],
})
export class SelectExistingCardComponent extends BaseModalComponent implements OnInit {
    @Input() tab: Tab;
    principal: number;
    tip: number;
    surcharge: number;
    selectedCardSummary: CardSummary;
    organization: Organization;

    cardSummaries: CardSummary[];

    constructor(
        private tabService: TabService,
        private loadingService: LoadingService,
        private organizationService: OrganizationService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.organization = this.organizationService.currentValue;

        this.cardSummaries = this.tab.paymentCharges.reduce((accumulator: CardSummary[], paymentCharge) => {
            if (!!paymentCharge.cardSummary && paymentCharge.cardSummary.tokenized) {
                const existing = accumulator.find(c => c.id === paymentCharge.cardSummary.id);

                if (!existing) {
                    accumulator.push(paymentCharge.cardSummary);
                }
            }
            return accumulator;
        }, []);
        this.selectedCardSummary = this.cardSummaries[0];
        this.principal = this.tab.paymentInformation.remaining;
        this.subscribe(this.tabService.event.subscribe(t => {
            if (!!t && !!this.tab && t.id === this.tab.id) {
                this.principal = t.paymentInformation.remaining;
                if (this.principal <= 0) {
                    this.close();
                }
            }
        }));

        this.subscribe(this.organizationService.current.subscribe(o => this.organization = !!o ? new Organization(o) : null));
    }

    total() {
        const total = addCurrency(this.principal, this.tip);
        if (!!this.organization && !!this.organization.organizationPreference && this.organization.organizationPreference.surcharge > 0) {
            this.surcharge = multiplyCurrency(this.organization.organizationPreference.surchargeIncludeTip ? total : this.principal,
                (this.organization.organizationPreference.surcharge / 100));
        } else {
            this.surcharge = 0;
        }
        return addCurrency(total, this.surcharge);
    }

    onSubmit() {
        this.loadingService.present();

        this.tabService.paymentCharge(this.tab, this.selectedCardSummary, this.principal, this.tip)
            .subscribe(t => {
                this.loadingService.dismiss();
                this.close(true);
            });
    }
}
