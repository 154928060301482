import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Person} from '../../../_models/person';
import {BaseForm} from '../base-form';
import {Organization} from '../../../_models/organization';

@Component({
    selector: 'bb-person-form',
    templateUrl: './person-form.component.html',
    styleUrls: ['./person-form.component.scss']
})
export class PersonFormComponent extends BaseForm {
    @Input() person: Person;
    @Input() organization: Organization;
    @Input() self: false;
    @Input() nameOnly: false;
    @Input() requireEmail = false;
    @Input() requireMobile = false;
    @Output() validMobile = new EventEmitter();
    @Output() emailChange = new EventEmitter();

    constructor(private formBuilder: FormBuilder) {
        super();
    }

    setupFormGroup(): FormGroup {
        let formGroup;
        this.person = !this.person ? new Person() : this.person;
        if (!this.nameOnly) {
            formGroup = this.formBuilder.group({
                id: this.person.id,
                email: [
                    this.person.email, this.requireEmail ? [
                        Validators.required,
                        Validators.email,
                        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
                    ] : [
                        Validators.email,
                        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
                    ]
                ],
                mobile: [
                    this.person.mobile, this.requireMobile ? [
                        Validators.required,
                        Validators.minLength(14),
                        Validators.maxLength(14)
                    ] : [
                        Validators.minLength(14),
                        Validators.maxLength(14)
                    ]
                ],
                firstName: [
                    (this.person.firstName === 'Single' && this.person.lastName === 'Pay')
                    || (this.person.firstName === 'Invoice' && this.person.lastName === 'Customer') ? '' : this.person.firstName,
                    Validators.required
                ],
                lastName: [
                    (this.person.firstName === 'Single' && this.person.lastName === 'Pay')
                    || (this.person.firstName === 'Invoice' && this.person.lastName === 'Customer') ? '' : this.person.lastName,
                    Validators.required
                ],
                optIn: false
            });

            formGroup.controls.mobile.statusChanges.subscribe(s => {
                if (s === 'VALID') {
                    this.validMobile.emit();
                }
            });
        } else {
            formGroup = this.formBuilder.group({
                id: this.person.id,
                firstName: [this.person.firstName, Validators.required],
                lastName: [this.person.lastName, Validators.required]
            });
        }

        return formGroup;
    }

    correctEmail() {
        const email = this.formGroup.controls.email.value;
        if (!!email && email !== email.toLowerCase()) {
            this.formGroup.controls.email.setValue(email.toLowerCase());
            this.formGroup.get('email').updateValueAndValidity();
        }
    }
}
