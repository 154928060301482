import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'bb-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss'],
})
export class CounterComponent {
    @Input() value: number;
    @Input() displayAdd = false;
    @Input() scale = false;

    @Output() add: EventEmitter<any> = new EventEmitter();
    @Output() remove: EventEmitter<any> = new EventEmitter();

    addClicked(event) {
        event.stopPropagation();
        this.add.emit(event);
    }

    async removeClicked(event) {
        event.stopPropagation();
        this.remove.emit(event);
    }
}
