import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {TabService} from '../_services/tab.service';

@Injectable()
export class TabHistoryResolver implements Resolve<any> {
    constructor(private tabService: TabService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.tabService.history();
    }
}
