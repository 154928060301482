<modal-header title="Change Password"></modal-header>

<ion-content>
    <ion-grid>
        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
            <bb-login-form (formReady)="formInitialized($event)" [user]="currentUser"></bb-login-form>
        </form>
    </ion-grid>
</ion-content>

<modal-footer [disableSubmit]="changePasswordForm.invalid" (submitClicked)="onSubmit()"></modal-footer>
