import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastService} from '../../_services/toast.service';
import {ModalController} from '@ionic/angular';
import {BaseModalComponent} from '../base-modal.component';
import {LoadingService} from '../../_services/loading.service';
import {UserService} from '../../_services/user.service';

@Component({
    selector: 'bb-forgot-password-modal',
    templateUrl: './forgot-password-modal.component.html',
    styleUrls: ['./forgot-password-modal.component.css']
})
export class ForgotPasswordModalComponent extends BaseModalComponent {
    forgotPasswordForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private toastService: ToastService,
        modalController: ModalController,
        private userService: UserService
    ) {
        super(modalController);
        this.forgotPasswordForm = this.formBuilder.group({
            email: [
                '', [
                    Validators.required, Validators.email,
                    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
                ]
            ]
        });
    }

    async onSubmit() {
        this.loadingService.present();
        this.userService.forgotPassword(this.forgotPasswordForm.controls.email.value).subscribe(
            async () => {
                await this.toastService.success('The request has been sent. Please check your email to continue.');
                await this.close();
                this.loadingService.dismiss();
            }
        );
    }
}
