<modal-header title="Tips Summary"></modal-header>
<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col>
                <bb-date-input [(date)]="startDate"
                               id="tipStartDate"
                               label="Start Time"
                               [max]="today"
                               (dateChange)="changeStartDate()">
                </bb-date-input>
            </ion-col>
            <ion-col>
                <bb-date-input [(date)]="endDate"
                               id="tipEndDate"
                               label="End Time"
                               [max]="today"
                               [min]="startDate"
                               (dateChange)="loadTabs()">
                </bb-date-input>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!!errorMessage">
            <ion-col>
                <ion-item lines="none">
                    <ion-icon slot="start"
                              name="close-circle-outline"
                              color="danger"></ion-icon>
                    <ion-label>{{errorMessage}}</ion-label>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!!serverOptions && serverOptions.length > 0">
            <ion-col>
                <ion-item lines="none">
                    <ion-label position="floating">Server</ion-label>
                    <ion-select [(ngModel)]="selectedServer" interface="popover" (ionChange)="loadTabs()">
                        <ion-select-option value="">Any</ion-select-option>
                        <ion-select-option *ngFor="let person of serverOptions" [value]="person">{{person.name}}</ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!errorMessage">
            <ion-col>
                <h1>Total: {{ tabTotal | currency }}</h1>
            </ion-col>
            <ion-col>
                <h1>Tips: {{ tipTotal | currency }}</h1>
            </ion-col>

            <ion-col *ngIf="tabTotal > 0">
                <h1>{{ ((tipTotal / tabTotal) * 100) | number: '1.2-2' }}%</h1>
            </ion-col>
        </ion-row>
        <ion-row class="tab-list">
            <ion-col>
                <ion-list *ngIf="!!tabs && tabs.length">
                    <ion-item *ngFor="let tab of tabs">
                        <ion-note slot="start">{{tab.subtotal | currency}}</ion-note>
                        <ion-label>
                            <h2>{{tab.serverName}}</h2>
                            <p>{{tab.name}}</p>
                            <p>{{tab.email}}</p>
                            <p>{{tab.startTime | date:'M/d/yy h:mm a'}}</p>
                        </ion-label>
                        <span class="pill-success">
                            {{tab.tip | currency}}
                        </span>
                    </ion-item>
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<modal-footer [hideSubmit]="true"></modal-footer>
