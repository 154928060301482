<div class="menu-item-container">
    <ion-fab *ngIf="showReward()" class="item-notification" vertical="top" horizontal="end"
             slot="fixed">
        <ion-fab-button (click)="showRewardMessage($event, item.menuItem)">
            <ion-icon name="ribbon-outline"></ion-icon>
        </ion-fab-button>
    </ion-fab>

    <ion-fab *ngIf="showQualified()" class="item-notification" vertical="top" horizontal="end"
             slot="fixed">
        <ion-fab-button color="dark" (click)="showQualifiedMessage($event, item.menuItem)">
            <ion-icon name="ribbon-outline"></ion-icon>
        </ion-fab-button>
    </ion-fab>

    <bb-menu-item-image [menuItem]="item.menuItem"
                        [showInventory]="showInventory"></bb-menu-item-image>

    <div class="menu-item-info-container">
        <div class="menu-item-name"><span *ngIf="!!item.menuItem.imageAddress && showImages">{{item.menuItem.name}}</span></div>
        <div *ngIf="!!item.menuItem.defaultPrice || !!this.minToken" [class.menu-item-price-alone]="!item.menuItem.imageAddress"
             class="menu-item-price">
            <span *ngIf="!!item.menuItem.defaultPrice && !this.minToken">{{ item.menuItem.defaultPrice.price | currency }}</span>
            <div class="token-information-container" *ngIf="!!this.minToken">
                <span class="token-count" *ngIf="this.minToken != this.maxToken">{{ this.minToken }} - {{ this.maxToken }}</span>
                <span class="token-count" *ngIf="this.minToken === this.maxToken">{{ this.minToken }}</span>
                <ion-icon src="assets/icon/token.svg"></ion-icon>
            </div>
        </div>
    </div>

    <div *ngIf="!terminalMode && exactInventory && !!exactInventory.mobileCountdownStart && exactInventory.currentCount <= exactInventory.mobileCountdownStart"
         class="mobile-countdown">
        {{exactInventory.currentCount}} remaining
    </div>
</div>
