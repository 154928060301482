import {BaseFormModel} from './base-form-model';

export class MembershipProgram extends BaseFormModel<MembershipProgram> {
    id: number;
    name: string;
    description: string;
    status: string;
    amount: number;
    durationPart: string;
    durationCount: number;
    autoRenewal: boolean;
    lifetime: boolean;
    tokens: number;
    tokensExpire: boolean;
    sortOrder: number;
    feeStructure: string;
}
