import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MenuItemInventory} from '../../_models/menu-item-inventory';

@Component({
    selector: 'bb-keg-status',
    templateUrl: './keg-status.component.html',
    styleUrls: ['./keg-status.component.scss'],
})
export class KegStatusComponent implements OnChanges {
    @Input() menuItemInventory: MenuItemInventory;
    @Input() radius: number;
    @Input() backgroundColor = 'transparent';
    expectedLoss = 0;
    percent = 0;
    color = '#2dd36f';

    ngOnChanges(changes: SimpleChanges): void {
        if (!!this.menuItemInventory) {
            this.percent = this.getKegPercent();
            this.color = this.getColor();
        }
    }

    getKegPercent() {
        const expectedLoss = !!this.menuItemInventory.expectedPercentageLost ?
            Math.floor(this.menuItemInventory.containerQuantity * (this.menuItemInventory.expectedPercentageLost / 100)) : 0;
        return this.menuItemInventory.containerQuantity > 0 && (this.menuItemInventory.currentCount - expectedLoss) > 0
            ? ((this.menuItemInventory.currentCount - expectedLoss) / (this.menuItemInventory.containerQuantity - expectedLoss)) * 100 : 0;
    }

    getColor() {
        if (this.percent > 50) {
            return '#2dd36f';
        } else if (this.percent > 25) {
            return '#ffc409';
        } else {
            return '#ff0000';
        }
    }
}
