import {Inject, Injectable} from '@angular/core';
import {Address} from '../_models/address';
import {LibConfig, LibConfigService} from '../lib.config';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    geocoder: any;

    constructor(
        @Inject(LibConfigService) private config: LibConfig,
        private http: HttpClient
    ) {
    }

    update(address: Address, endpoint: string, parentId: number) {
        return this.http.put(`${this.config.apiUrl}/${endpoint}/${parentId}/address`, address);
    }

    fullAddress(address: Address) {
        let fullAddress: string = address.addr1 || '';
        if (address.addr2) {
            fullAddress += ' ' + address.addr2;
        }
        if (address.addr3) {
            fullAddress += ' ' + address.addr3;
        }
        if (address.city) {
            fullAddress += ' ' + address.city;
        }
        if (address.state) {
            fullAddress += ' ' + address.state;
        }
        if (address.postalCode) {
            fullAddress += ' ' + address.postalCode;
        }

        return fullAddress;
    }

    getGeocoder() {
        if (!this.geocoder) {
            // @ts-ignore
            this.geocoder = new google.maps.Geocoder();
        }
        return this.geocoder;
    }
}
