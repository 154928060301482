import {BaseFormModel} from './base-form-model';
import {MenuItem} from './menu-item';
import {Sortable} from './sortable';

export class MenuMarqueeItem extends BaseFormModel<MenuMarqueeItem> implements Sortable {
    id: number;
    menuId: number;
    message: string;
    sortOrder: number;
    name: string;
    menuItem: MenuItem;

    init(): void {
        this.menuItem = new MenuItem(this.menuItem);
    }
}
