import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {OrganizationRewardProgramCardSummary} from '../../_models/organization-reward-program-card-summary';
import {CurrencyPipe} from '@angular/common';

@Component({
    selector: 'bb-reward-modal',
    templateUrl: './reward-modal.component.html',
    styleUrls: ['./reward-modal.component.css']
})
export class RewardModalComponent implements OnInit {
    @Input() title: string;
    @Input() image: string;
    @Input() cards: OrganizationRewardProgramCardSummary[];

    cardIndex = 0;
    currentCard: OrganizationRewardProgramCardSummary;

    punches = [];
    currentPunch = 0;
    rewardSlide = 0;

    constructor(
        private modalController: ModalController,
        private currencyPipe: CurrencyPipe
    ) {
    }

    ngOnInit(): void {
        this.animate();
    }

    animate() {
        this.punches = null;
        this.currentPunch = 0;
        this.rewardSlide = 0;

        const card = this.cards[this.cardIndex++];
        if (card.type === 'PER_ITEM') {
            this.animatePunch(card);
        } else {
            this.animateProgress(card);
        }
    }

    async closeMessage() {
        if (this.cardIndex < this.cards.length) {
            this.rewardSlide = 0;
            this.animate();
        } else {
            await this.close();
        }
    }

    animatePunch(card: OrganizationRewardProgramCardSummary) {
        this.currentCard = card;
        this.punches = new Array(card.goal);
        this.punches.fill(false);

        const createPunches = setInterval(() => {
            this.addPunch();
            if (this.currentPunch === card.used || this.currentPunch === this.punches.length) {
                clearInterval(createPunches);
            }
        }, 750);
    }

    animateProgress(card: OrganizationRewardProgramCardSummary) {
        const intRewardGoal = card.goal * 100;
        const intMoneyUsed = card.used * 100;
        this.currentCard = card;

        const ticks = Math.floor((intMoneyUsed - this.rewardSlide) / 10);

        const timePerTick = Math.floor(3000 / ticks);

        const slide = setInterval(() => {
            this.rewardSlide += 10;
            if (this.rewardSlide >= intMoneyUsed || this.rewardSlide >= intRewardGoal) {
                clearInterval(slide);
            }
        }, timePerTick);
    }

    addPunch() {
        if (this.currentPunch < this.punches.length) {
            this.punches[this.currentPunch++] = true;
        }
    }

    rewardMessage() {
        if (!!this.currentCard && this.currentCard.rewardType === 'ITEM') {
            return `You have earned ${this.currentCard.reward} free ${this.currentCard.rewardName}!`;
        } else if (!!this.currentCard && this.currentCard.rewardType === 'DOLLAR') {
            return `You have earned ${this.currencyPipe.transform(this.currentCard.reward)} off your next order!`;
        } else {
            return '';
        }
    }

    async close() {
        await this.modalController.dismiss();
    }
}
