import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'bb-rating-summary',
    templateUrl: './rating-summary.component.html',
    styleUrls: ['./rating-summary.component.css']
})
export class RatingSummaryComponent implements OnInit {
    @Input() rating: number;

    constructor() {
    }

    ngOnInit(): void {
    }

}
