import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItemSelection} from '../../_models/menu-item-selection';
import {OrderItem} from '../../_models/order-item';
import {MenuSelectionItem} from '../../_models/menu-selection-item';
import {OrderItemSelection} from '../../_models/order-item-selection';
import {OrderItemSelectionItem} from '../../_models/order-item-selection-item';
import {OrderItemService} from '../../_services/order-item.service';
import {AlertController} from '@ionic/angular';
import {MenuItemService} from '../../_services/menu-item.service';
import clone from 'clone';
import {MenuSelectionService} from '../../_services/menu-selection.service';
import {Availabilities} from '../../_constants/availabilities';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MenuItemInventory} from '../../_models/menu-item-inventory';
import {SubscriberComponent} from '../subscriber.component';

@Component({
    selector: 'bb-menu-item-selection',
    templateUrl: './menu-item-selection.component.html',
    styleUrls: ['./menu-item-selection.component.scss']
})
export class MenuItemSelectionComponent extends SubscriberComponent implements OnInit {
    @Input() menuItemSelection: MenuItemSelection;
    @Input() selectionIndex: number;
    @Input() parentOrderItem: OrderItem;
    @Input() count: number;
    @Input() terminalMode = false;

    @Output() itemSelected: EventEmitter<any> = new EventEmitter();
    @Output() editSelected: EventEmitter<any> = new EventEmitter();
    @Output() adjustInventory: EventEmitter<MenuItemInventory> = new EventEmitter();

    options: MenuSelectionItem[];
    orderItemSelection: OrderItemSelection;
    selections: OrderItemSelectionItem[];

    formGroup: FormGroup;

    constructor(
        private orderItemService: OrderItemService,
        private menuItemService: MenuItemService,
        private menuSelectionService: MenuSelectionService,
        private alertController: AlertController,
        private formBuilder: FormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        const tmpOptions = clone<MenuSelectionItem>(this.menuItemSelection.menuSelection.options);
        const opts = [];
        tmpOptions.forEach(o => {
            const opt = clone<MenuSelectionItem>(o);
            this.updateOptionCache(opt);
            opts.push(opt);
        });
        this.options = opts;

        this.menuItemSelection.menuSelection.options = this.menuItemSelection.menuSelection.options.filter(opt => {
            return opt.menuItem.availability !== Availabilities.NONE;
        });

        this.orderItemSelection = this.parentOrderItem.selections.filter(sel =>
            this.menuItemSelection.id === sel.menuItemSelection.id
        )[0];

        this.formGroup = this.formBuilder.group({
            amount: ['', Validators.required]
        });

        this.options.forEach(o => {
            if (o.isVariable()) {
                const found = this.orderItemSelection.selectedItems.find(i => i.menuSelectionItem.id === o.id);
                this.formGroup.addControl(`amount_${o.id}`, new FormControl(!!found
                    ? found.orderItem.priceOverride
                    : '', Validators.required));
            }
        });
    }

    updateOptionCache(opt: MenuSelectionItem) {
        opt.selectionCount = this.parentOrderItem.selections[this.selectionIndex].selectedItems
            .filter(i => i.menuSelectionItem.id === opt.id).length;
        opt.selected = opt.selectionCount > 0;
    }

    async checkboxClicked(event, option: MenuSelectionItem) {
        if (option.selected) {
            event.stopPropagation();
            this.removeAll(option);
        }
    }

    itemsForSelection(option: MenuSelectionItem) {
        return this.parentOrderItem.selections[this.selectionIndex].selectedItems.filter(item => item.menuSelectionItem.id === option.id);
    }

    removeAll(option: MenuSelectionItem) {
        const removeItems = this.parentOrderItem.selections[this.selectionIndex].selectedItems
            .filter(o => o.menuSelectionItem.id === option.id);

        removeItems.forEach(i => {
            this.parentOrderItem.selections[this.selectionIndex].selectedItems
                .splice(this.parentOrderItem.selections[this.selectionIndex].selectedItems.indexOf(i), 1);
        });

        setTimeout(() => {
            this.updateOptionCache(option);
        });
    }

    async info(event, menuSelectionItem: MenuSelectionItem) {
        event.stopPropagation();
        await this.itemDetails(menuSelectionItem);
    }

    async itemDetails(menuSelectionItem: MenuSelectionItem) {
        this.itemSelected.emit(menuSelectionItem);
    }

    async selectItem(menuSelectionItem: MenuSelectionItem) {
        const index = this.options.findIndex(o => o.id === menuSelectionItem.id);

        if (this.menuItemSelection.menuSelection.maxCount === 1 && this.orderItemSelection.selectedItems.length === 1) {
            const selIndex = this.options.findIndex(o => o.id === this.orderItemSelection.selectedItems[0].menuSelectionItem.id);
            this.orderItemSelection.selectedItems.splice(0, 1);
            this.updateOptionCache(this.options[selIndex]);
        }

        if (this.menuItemSelection.menuSelection.maxCount != null
            && this.menuItemSelection.menuSelection.maxCount <= this.orderItemSelection.selectedItems.length) {
            await this.maxCountAlert(menuSelectionItem);
        } else if (!menuSelectionItem.menuItem.selectionRequired) {
            const orderItem = this.orderItemService.initializeOrderItem(menuSelectionItem.menuItem);
            // there should be no selected price for a menu item selection
            if (!!orderItem.selectedPrice && !orderItem.selectedPrice.deposit
                && !orderItem.selectedPrice.percentageOfParent && !orderItem.selectedPrice.variable) {
                orderItem.selectedPrice = null;
            }
            const orderItemSelectionItem = new OrderItemSelectionItem({
                orderItem,
                menuSelectionItem
            });
            orderItemSelectionItem.menuSelectionItem.menuItem = menuSelectionItem.menuItem;
            this.orderItemSelection.selectedItems.push(orderItemSelectionItem);
            this.updateOptionCache(this.options[index]);
        } else {
            await this.itemDetails(menuSelectionItem);
        }

        this.updateTotal(menuSelectionItem);
    }

    async clear() {
        this.parentOrderItem.selections[this.selectionIndex].selectedItems.length = 0;
        this.options.forEach(o => {
            o.selected = false;
            o.selectionCount = 0;
        });
    }

    async itemClick(option: MenuSelectionItem) {
        if (!!this.menuItemSelection.menuSelection.maxCount
            && this.menuItemSelection.menuSelection.maxCount > 1
            && this.menuItemSelection.menuSelection.maxCount <= this.orderItemSelection.selectedItems.length) {
            if (option.selectionCount > 0) {
                this.removeAll(option);
            } else {
                await this.maxCountAlert(option);
            }
        } else {
            await this.selectItem(option);
        }
    }

    updateTotal(option: MenuSelectionItem) {
        if (!!option.isVariable()) {
            const found = this.orderItemSelection.selectedItems.find(i => i.menuSelectionItem.id === option.id);
            if (!!found) {
                found.orderItem.priceOverride = Number.parseFloat(this.formGroup.controls[`amount_${option.id}`].value);
            }
        }
    }

    async editItem(orderItem: OrderItem) {
        this.editSelected.emit(orderItem);
    }

    async maxCountAlert(menuSelectionItem: MenuSelectionItem) {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Max Selections',
            message: 'You have added the maximum number of items for this selection. '
                + 'Please remove an item before adding anything new.',
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel',
                    cssClass: 'secondary',
                }
            ]
        });

        await alert.present();
        await alert.onDidDismiss().then(() => {
            this.updateOptionCache(this.options[this.options.findIndex(o => o.id === menuSelectionItem.id)]);
        });
    }
}
