import {Inject, Injectable} from '@angular/core';
import {BaseObservableService} from './base-obeservable-service';
import {CustomerAreaPosition} from '../_models/customer-area-position';
import {HttpClient} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';
import {getTimeBased} from '../_utils/local-storage-utils';

@Injectable({
    providedIn: 'root'
})
export class CustomerAreaPositionService extends BaseObservableService<CustomerAreaPosition> {
    destination = 'customer-area-positions';

    constructor(
        http: HttpClient,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);
        this.saveToLocalStorage = true;
        const val = getTimeBased(this.destination);
        if (!!val) {
            this.setCurrent(new CustomerAreaPosition(val));
        }
    }
}
