import {Component, Input} from '@angular/core';
import {Tab} from '../../_models/tab';
import {TabStatuses} from '../../_constants/tab-statuses';

@Component({
    selector: 'bb-tab-summary-item',
    templateUrl: './tab-summary-item.component.html',
    styleUrls: ['./tab-summary-item.component.css']
})
export class TabSummaryItemComponent {
    @Input() tab: Tab;
    @Input() active: boolean;
    tabStatuses = TabStatuses;

    constructor() {
    }
}
