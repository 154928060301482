import {Directive, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {debounce} from 'lodash';

@Directive({
    selector: '[bbCardSwipe]'
})
export class CardSwipeMaskDirective implements OnInit {
    stripChars = new RegExp('[^0-9|.]', 'g');

    constructor(
        public el: ElementRef,
        public renderer: Renderer2
    ) {
    }

    ngOnInit() {
        this.onInput = debounce(this.onInput, 100);
        this.format(this.el.nativeElement.value); // format any initial values
    }

    @HostListener('input', ['$event.target.value']) onInput(e: string) {
        this.format(e);
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        event.preventDefault();
        this.format(event.clipboardData.getData('text/plain'));
    }

    format(val: string) {
        let formatted = val;
        if (val.indexOf('%B6010567') > -1 && val.indexOf('^LOO/CMS^') > -1) {
            const marker = val.indexOf('%B6010567') + '%B6010567'.length;
            formatted = '6217'
                + val.substring(marker, marker + 8)
                + val.substring(val.length - 5, val.length - 1);
            console.log(formatted, val);
        } else if (!!val && val.indexOf(';') > -1 && val.indexOf('=') > 0 && val.indexOf('?') > 0) {
            formatted = String(val).substring(val.indexOf(';') + 1, val.indexOf('='));
        } else if (!!val && val.indexOf(';') > -1 && val.indexOf('?') > 0) {
            formatted = String(val).substring(val.indexOf(';') + 1, val.indexOf('?'));
        }
        this.renderer.setProperty(this.el.nativeElement, 'value', formatted);
    }
}
