import {Component, Input, OnInit} from '@angular/core';
import {BaseWidgetComponent} from '../base-widget.component';
import {TabReportResult} from '../../../_models/report/tab-report-result';
import {Constants} from '../../../_constants/constants';
import {TabReportService} from '../../../_services/tab-report.service';
import {CurrencyPipe, DatePipe, PercentPipe} from '@angular/common';
import moment from 'moment';
import {UnderscoreConversionPipe} from '../../_pipes/underscore-conversion.pipe';

@Component({
    selector: 'bb-bar-chart-widget',
    templateUrl: './bar-chart-widget.component.html',
    styleUrls: ['./bar-chart-widget.component.scss'],
})
export class BarChartWidgetComponent extends BaseWidgetComponent implements OnInit {
    @Input() chartType = 'bar';
    chartOptions;
    chart;

    constructor(
        private tabReportService: TabReportService,
        currencyPipe: CurrencyPipe,
        percentPipe: PercentPipe,
        datePipe: DatePipe,
        underscoreConversionPipe: UnderscoreConversionPipe
    ) {
        super(currencyPipe, percentPipe, datePipe, underscoreConversionPipe);
    }

    ngOnInit() {
    }

    refresh() {
        this.loading = true;
        this.dataLoading.emit();
        this.tabReportService.run(this.tabReport.id, 0, 1000, this.startDate, this.endDate,
            null, this.selectedCategories, this.selectedPickupLocations, true)
            .subscribe((trr: TabReportResult) => {
                this.configureBarChart(trr);
                this.loading = false;
                this.dataReady.emit();
            });
    }

    configureBarChart(trr: TabReportResult) {
        const labels = [];
        let datasets = [];

        const labelColumn = trr.columns[0];
        const valueColumn = trr.columns[1];
        const groupingColumn = trr.columns.length > 2 ? trr.columns[2] : null;

        this.chartOptions = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            scales: {
                x: {
                    ticks: {
                        color: '#383a3e'
                    }
                },
                y: {
                    ticks: {
                        color: '#383a3e',
                        callback: (label) => {
                            return this.formatValue(label, valueColumn.format);
                        }
                    }
                }
            }
        };

        let dataset;
        let datasetMap;
        let groupingLabel;

        trr.data.forEach(row => {
            let label = this.getRowValue(row, labelColumn);
            if (labelColumn.includeParent) {
                const yearKey = 'YEAR';
                const year = row.data[yearKey];

                if (labelColumn.accessKey === 'MONTH') {
                    if (Number.isInteger(label)) {
                        label = moment().year(year).month(label - 1);
                    }
                } else if (labelColumn.accessKey === 'WEEK') {
                    label = moment().year(year).week(label + 1).startOf('week').add(1, 'days').format('MM/DD/YY');
                } else if (labelColumn.accessKey === 'DAY') {
                    label = moment().year(year).dayOfYear(label).format('MM/DD/YY');
                }
            }

            if (labels.indexOf(label) === -1) {
                labels.push(label);
            }

            if (!!groupingColumn) {
                groupingLabel = this.getRowValue(row, groupingColumn);
                if (!!groupingLabel) {
                    if (!datasetMap) {
                        datasetMap = {};
                    }
                    dataset = datasetMap[groupingLabel];

                    if (!dataset) {
                        dataset = {
                            type: this.chartType,
                            label: groupingLabel,
                            backgroundColor: Constants.CHART_CONFIG.backgroundColor[Object.keys(datasetMap).length],
                            data: []
                        };

                        if (this.chartType === 'line') {
                            dataset.tension = 0.4;
                            dataset.borderColor = dataset.backgroundColor;
                        }
                        datasetMap[groupingLabel] = dataset;
                    }
                }
            } else if (!dataset) {
                dataset = {
                    type: this.chartType,
                    label: labelColumn.label,
                    backgroundColor: Constants.CHART_CONFIG.backgroundColor[datasets.length],
                    data: []
                };
                if (this.chartType === 'line') {
                    dataset.tension = 0.4;
                    dataset.borderColor = dataset.backgroundColor;
                }
                datasets.push(dataset);
            }

            if (!!dataset) {
                dataset.data.push(this.getNumeric(row.data[valueColumn.accessKey]));
            }
        });

        if (!!datasetMap) {
            datasets = Object.values(datasetMap);
        }

        if (datasets.length > 0) {
            this.chart = {labels, datasets};
        } else {
            this.chart = null;
        }
    }
}
