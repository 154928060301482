<modal-header title="Rating Response"></modal-header>

<ion-content [formGroup]="formGroup">
    <ion-list class="no-padding no-margin">
        <ion-item lines="none">
            <ion-label>
                <h2>Tab: {{tabTotal | currency}}</h2>
            </ion-label>
            <bb-rating [rating]="rating.rating" size="medium" slot="end"></bb-rating>
        </ion-item>
    </ion-list>
    <ion-row>
        <ion-col>
            <ion-item lines="none" *ngIf="!!rating.comments">
                <ion-label>
                    <h3>Comments</h3>
                    <p>{{ rating.comments }}</p>
                </ion-label>
            </ion-item>
        </ion-col>
    </ion-row>

    <ion-row>
        <ion-col>
            <ion-item lines="none">
                <ion-label position="stacked">Response</ion-label>
                <ion-textarea formControlName="response" maxlength="2000" rows="5"></ion-textarea>
            </ion-item>
        </ion-col>
    </ion-row>

    <ng-container *ngIf="!rating.userOrganizationCredit">
        <ion-row>
            <ion-col size-xs="12">
                <ion-item color="success">
                    <ion-label>
                        <h2>Credit For Next Visit (optional)</h2>
                    </ion-label>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size-xs="12" size-md="6">
                <ion-item>
                    <ion-label position="floating">Reason</ion-label>
                    <ion-select formControlName="type" interface="popover">
                        <ion-select-option value="UNSATISFACTORY">{{'UNSATISFACTORY' | creditType}}</ion-select-option>
                        <ion-select-option value="INCORRECT_ITEM">{{'INCORRECT_ITEM' | creditType}}</ion-select-option>
                        <ion-select-option value="POOR_SERVICE">{{'POOR_SERVICE' | creditType}}</ion-select-option>
                        <ion-select-option value="LATE">{{'LATE' | creditType}}</ion-select-option>
                        <ion-select-option value="NEVER_RECEIVED">{{'NEVER_RECEIVED' | creditType}}</ion-select-option>
                        <ion-select-option value="DUPLICATE">{{'DUPLICATE' | creditType}}</ion-select-option>
                        <ion-select-option value="FRAUDULENT">{{'FRAUDULENT' | creditType}}</ion-select-option>
                        <ion-select-option value="REWARD">{{'REWARD' | creditType}}</ion-select-option>
                        <ion-select-option value="OTHER">{{'OTHER' | creditType}}</ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
            <ion-col size-xs="12" size-md="6">
                <ion-item lines="none">
                    <ion-label position="floating">Amount</ion-label>
                    <ion-input type="number" formControlName="amount" bbCurrencyMask></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
    </ng-container>

    <ng-container *ngIf="!!rating.userOrganizationCredit">
        <ion-row>
            <ion-col size-xs="12">
                <ion-item lines="none">
                    <ion-label>
                        <h2>Credit For Next Visit: {{rating.userOrganizationCredit.amount | currency}}</h2>
                    </ion-label>
                </ion-item>
            </ion-col>
        </ion-row>
    </ng-container>
</ion-content>

<modal-footer [disableSubmit]="formGroup.invalid" (submitClicked)="submit()"></modal-footer>
