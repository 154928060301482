<ion-card>
    <ion-card-header>
        <ion-buttons class="actions ion-text-end">
            <ion-button color="dark" (click)="refresh()">
                <ion-icon name="refresh-outline"></ion-icon>
            </ion-button>
            <ion-button color="dark" (click)="editClicked($event)">
                <ion-icon name="create-outline"></ion-icon>
            </ion-button>
            <ion-button color="dark" (click)="removeClicked($event)">
                <ion-icon name="trash-outline"></ion-icon>
            </ion-button>
        </ion-buttons>

        <ion-card-title>
            {{widget.title}}
        </ion-card-title>
    </ion-card-header>
    <ion-card-content class="ion-text-center">
        <ion-segment *ngIf="widget.tabs.length > 1" [(ngModel)]="currentTab" mode="ios" class="tab-header" (ionChange)="updateStartDate()">
            <ion-segment-button *ngFor="let tab of widget.tabs; trackBy: getId"
                                [value]="tab">
                {{tab.title}}
            </ion-segment-button>
        </ion-segment>

        <ng-container *ngIf="!!currentTab">
            <bb-report-table *ngIf="currentTab.type === 'TABLE'"
                             [tabReport]="currentTab.report"
                             [pageSize]="currentTab.maxRows"
                             [startDate]="calculatedStartDate"
                             [endDate]="calculatedEndDate"
                             [selectedCategories]="selectedCategories"
                             [selectedPickupLocations]="selectedPickupLocations"
                             (dataReady)="dataReady.emit()"
                             #widgetComponent>
            </bb-report-table>

            <bb-single-item-widget *ngIf="currentTab.type === 'SINGLE_VALUE'"
                                   [tabReport]="currentTab.report"
                                   [startDate]="calculatedStartDate"
                                   [endDate]="calculatedEndDate"
                                   [comparisonOffsetPart]="currentTab.comparisonOffsetPart"
                                   [comparisonOffsetCount]="currentTab.comparisonOffsetCount"
                                   [selectedCategories]="selectedCategories"
                                   [selectedPickupLocations]="selectedPickupLocations"
                                   (dataReady)="dataReady.emit()"
                                   #widgetComponent>
            </bb-single-item-widget>

            <bb-pie-chart-widget *ngIf="currentTab.type === 'PIE_CHART'"
                                 [tabReport]="currentTab.report"
                                 [startDate]="calculatedStartDate"
                                 [endDate]="calculatedEndDate"
                                 [selectedCategories]="selectedCategories"
                                 [selectedPickupLocations]="selectedPickupLocations"
                                 (dataReady)="dataReady.emit()"
                                 #widgetComponent>
            </bb-pie-chart-widget>

            <bb-bar-chart-widget *ngIf="currentTab.type === 'BAR_CHART' || currentTab.type === 'LINE_CHART'"
                                 [chartType]="currentTab.type === 'LINE_CHART' ? 'line' : 'bar'"
                                 [tabReport]="currentTab.report"
                                 [startDate]="calculatedStartDate"
                                 [endDate]="calculatedEndDate"
                                 [selectedCategories]="selectedCategories"
                                 [selectedPickupLocations]="selectedPickupLocations"
                                 (dataReady)="dataReady.emit()"
                                 #widgetComponent>
            </bb-bar-chart-widget>

            <bb-summary-list-widget *ngIf="currentTab.type === 'SUMMARY_LIST'"
                                    [tabReport]="currentTab.report"
                                    [startDate]="calculatedStartDate"
                                    [endDate]="calculatedEndDate"
                                    [selectedCategories]="selectedCategories"
                                    [selectedPickupLocations]="selectedPickupLocations"
                                    (dataReady)="dataReady.emit()"
                                    #widgetComponent>
            </bb-summary-list-widget>

            <bb-list-widget *ngIf="currentTab.type === 'LIST'"
                            [tabReport]="currentTab.report"
                            [pageSize]="currentTab.maxRows"
                            [startDate]="calculatedStartDate"
                            [endDate]="calculatedEndDate"
                            [selectedCategories]="selectedCategories"
                            [selectedPickupLocations]="selectedPickupLocations"
                            (dataReady)="dataReady.emit()"
                            #widgetComponent>
            </bb-list-widget>

            <bb-employee-hours-widget *ngIf="currentTab.type === 'EMPLOYEE_HOURS'"
                                      [widget]="widget"
                                      [startDate]="calculatedStartDate"
                                      [endDate]="calculatedEndDate"
                                      [selectedPickupLocations]="selectedPickupLocations"
                                      [organization]="organization"
                                      (dataReady)="dataReady.emit()"
                                      #widgetComponent>
            </bb-employee-hours-widget>

            <bb-active-timecards-widget *ngIf="currentTab.type === 'ACTIVE_TIMECARDS'"
                                        [widget]="widget"
                                        [selectedPickupLocations]="selectedPickupLocations"
                                        [organization]="organization"
                                        (dataReady)="dataReady.emit()"
                                        #widgetComponent>
            </bb-active-timecards-widget>
        </ng-container>
    </ion-card-content>
</ion-card>
