import {BaseFormModel} from './base-form-model';
import {PaymentCharge} from './payment-charge';
import {Person} from './person';

export class PaymentChargeRefund extends BaseFormModel<PaymentChargeRefund> {
    id: number;
    createdBy: Person;
    paymentCharge: PaymentCharge;
    success: boolean;
    pending: boolean;
    total: number;
    tip: number;
    principal: number;
    createDate: Date;
    updateDate: Date;
    type: string;
    comment: string;
    creditTab: boolean;
    creditType: string;
    terminalId: string;
}
