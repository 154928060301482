import {Component, OnInit} from '@angular/core';
import {CurrencyPipe, DatePipe, PercentPipe} from '@angular/common';
import {UnderscoreConversionPipe} from '../../_pipes/underscore-conversion.pipe';
import {TimecardService} from '../../../_services/timecard.service';
import {Timecard} from '../../../_models/timecard';
import {OrganizationService} from '../../../_services/organization.service';
import {LoadingService} from '../../../_services/loading.service';
import {ModalController} from '@ionic/angular';
import {BaseTimecardWidgetComponent} from '../base-timecard-widget.component';

@Component({
    selector: 'bb-employee-hours-widget',
    templateUrl: './employee-hours-widget.component.html',
    styleUrls: ['./employee-hours-widget.component.scss'],
})
export class EmployeeHoursWidgetComponent extends BaseTimecardWidgetComponent implements OnInit {

    constructor(
        timecardService: TimecardService,
        organizationService: OrganizationService,
        loadingService: LoadingService,
        modalController: ModalController,
        currencyPipe: CurrencyPipe,
        percentPipe: PercentPipe,
        datePipe: DatePipe,
        underscoreConversionPipe: UnderscoreConversionPipe
    ) {
        super(timecardService,
            organizationService,
            loadingService,
            modalController,
            currencyPipe,
            percentPipe,
            datePipe,
            underscoreConversionPipe);
    }

    ngOnInit(): void {
        this.timecardService.event.subscribe(t => this.refresh());
    }

    refresh() {
        if (!!this.organization) {
            const pickupLocations = this.selectedPickupLocations.length === 0
            || this.selectedPickupLocations.length === this.organization.pickupLocations.length
                ? null : this.selectedPickupLocations;

            this.timecardService.getDateRangeTimecardsSearch(this.widget.parentId,
                pickupLocations,
                null,
                this.startDate,
                this.endDate,
                null,
                null
            ).subscribe((t: Timecard[]) => {
                this.timecards = t;
                this.dataReady.emit();
            });
        }
    }
}
