import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dateAgo',
    pure: false
})
export class DateAgoPipe implements PipeTransform {

    transform(value: any, summary?: boolean): unknown {
        if (value) {
            const differenceInSeconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (differenceInSeconds < 60) {
                return 'Now';
            }

            const timeIntervals = {
                year: {
                    short: 'y',
                    interval: 31536000
                },
                month: {
                    short: 'mo',
                    interval: 2592000
                },
                week: {
                    short: 'w',
                    interval: 604800
                },
                day: {
                    short: 'd',
                    interval: 86400
                },
                hour: {
                    short: 'h',
                    interval: 3600
                },
                minute: {
                    short: 'm',
                    interval: 60
                }
            };
            let counter;

            for (const i in timeIntervals) {
                counter = Math.floor(differenceInSeconds / timeIntervals[i].interval);
                if (counter > 0) {
                    if (!summary) {
                        if (counter === 1) {
                            // singular (1 day ago)
                            return counter + ' ' + i + ' ago';
                        } else {
                            // plural (2 days ago)
                            return counter + ' ' + i + 's ago';
                        }
                    } else {
                        return counter + timeIntervals[i].short;
                    }
                }
            }
        }
        return value;
    }

}
