<ion-list>
    <ion-item lines="none">
        <ion-icon slot="start"
                  [name]="passwordLength() ? 'checkmark-circle-outline' : 'close-circle-outline'"
                  [color]="passwordLength() ? 'success' : 'danger'"></ion-icon>
        <ion-label>8 Or More Characters</ion-label>
    </ion-item>
    <ion-item lines="none">
        <ion-icon slot="start"
                  [name]="passwordUpper() ? 'checkmark-circle-outline' : 'close-circle-outline'"
                  [color]="passwordUpper() ? 'success' : 'danger'"></ion-icon>
        <ion-label>One Uppercase and One LowerCase Character</ion-label>
    </ion-item>
    <ion-item lines="none">
        <ion-icon slot="start"
                  [name]="passwordSpecial() ? 'checkmark-circle-outline' : 'close-circle-outline'"
                  [color]="passwordSpecial() ? 'success' : 'danger'"></ion-icon>
        <ion-label>One Number Or Special Character</ion-label>
    </ion-item>
    <ion-item lines="none">
        <ion-icon slot="start"
                  [name]="match() ? 'checkmark-circle-outline' : 'close-circle-outline'"
                  [color]="match() ? 'success' : 'danger'"></ion-icon>
        <ion-label>Passwords Match</ion-label>
    </ion-item>
</ion-list>
