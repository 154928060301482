import {Inject, Injectable} from '@angular/core';
import {OrganizationRating} from '../_models/organization-rating';
import {UserOrganizationCredit} from '../_models/user-organization-credit';
import {Organization} from '../_models/organization';
import {BaseObservableService} from './base-obeservable-service';
import {BehaviorSubject, Observable} from 'rxjs';
import {OrganizationSummary} from '../_models/organization-summary';
import {LibConfig, LibConfigService} from '../lib.config';
import {ToastService} from './toast.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class OrganizationRatingService extends BaseObservableService<OrganizationRating> {
    destination = 'organization-ratings';

    public organizationRatingSummary: Observable<OrganizationSummary>;
    private organizationRatingSummarySubject: BehaviorSubject<OrganizationSummary>;

    constructor(
        @Inject(LibConfigService) config: LibConfig,
        private toastService: ToastService,
        http: HttpClient
    ) {
        super(http, config);

        this.organizationRatingSummarySubject = new BehaviorSubject<OrganizationSummary>(null);
        this.organizationRatingSummary = this.organizationRatingSummarySubject.asObservable();
    }

    summary(orgId: number) {
        return this.http.get(this.baseUrl() + `/organization/${orgId}/summary`);
    }

    find(orgId: number, page: number, size: number, ratings: string[], readFilter: string, responseFilter: string) {
        return this.http.get(this.baseUrl() + `/${orgId}?page=${page}&size=${size}&ratings=${this.convertRatings(ratings)}&readFilter=${readFilter}&responseFilter=${responseFilter}`);
    }

    findResponsesByUser(page: number, size: number) {
        return this.http.get(this.baseUrl() + `/user/response?page=${page}&size=${size}`);
    }

    getOrganizationSummary(orgId: number, page: number, size: number, ratings: string[], readFilter: string, responseFilter: string) {
        return this.http.get(this.baseUrl() + `/chart-summary/${orgId}?page=${page}&size=${size}&ratings=${this.convertRatings(ratings)}&readFilter=${readFilter}&responseFilter=${responseFilter}`);
    }

    monthlyAverage(orgId: number, page: number, size: number, ratings: string[], readFilter: string, responseFilter: string) {
        return this.http.get(this.baseUrl() + `/monthly-average/${orgId}?page=${page}&size=${size}&ratings=${this.convertRatings(ratings)}&readFilter=${readFilter}&responseFilter=${responseFilter}`);
    }

    toggleOrganizationRead(id: number) {
        return this.http.put(this.baseUrl() + `/toggle-organization-read/${id}`, {});
    }

    toggleUserRead(id: number) {
        return this.http.put(this.baseUrl() + `/toggle-user-read/${id}`, {});
    }

    response(rating: OrganizationRating) {
        return this.http.put(this.baseUrl() + '/response', rating);
    }

    markAllReadOrganization(orgId: number) {
        return this.http.put(this.baseUrl() + `/mark-all-organization-read/${orgId}`, {});
    }

    convert(item): OrganizationRating {
        const rating = new OrganizationRating(item);
        if (!!item.creditAmount) {
            rating.userOrganizationCredit = new UserOrganizationCredit();
            rating.userOrganizationCredit.amount = item.creditAmount;
        }
        if (!!item.organizationName) {
            rating.organization = new Organization({});
            rating.organizationName = item.organizationName;
        }
        return rating;
    }

    convertRatings(ratings): any {
        const r = [];
        if (ratings.indexOf('one') > -1) {
            r.push(1);
        }
        if (ratings.indexOf('two') > -1) {
            r.push(2);
        }
        if (ratings.indexOf('three') > -1) {
            r.push(3);
        }
        if (ratings.indexOf('four') > -1) {
            r.push(4);
        }
        if (ratings.indexOf('five') > -1) {
            r.push(5);
        }
        return r;
    }

    setOrganizationRatingSummary(obj: OrganizationSummary) {
        this.organizationRatingSummarySubject.next(obj);
    }
}
