<modal-header [title]="label.name"></modal-header>
<ion-content>
    <ion-item>
        <ion-text>
            {{label.description}}
        </ion-text>
    </ion-item>
</ion-content>

<modal-footer [hideSubmit]="true"></modal-footer>
