<section class="timeline-wrapper">
    <div class="middle-line"></div>

    <div class="box box-top start">
        <div class="start-end">
            <p>Start</p>
        </div>
    </div>

    <div *ngFor="let e of events" class="box box-top" [style.left]="'calc(' + e.position + '% - 25px)'">
        <p class="status">{{e.status | orderStatus}}</p>
        <div class="date" [style.background-color]="getBackgroundColor(e)" [style.color]="getColor(e)" [style.border]="getBorder(e)">
            <p class="time">{{e.time}}</p>
        </div>
    </div>

    <div class="box box-top end">
        <div class="start-end">
            <p>{{endTime()}}</p>
        </div>
    </div>
</section>
<section *ngIf="waits.length > 0" class="waits-wrapper">
    <div *ngFor="let w of waits" class="wait-line" [style.left]="w.position + '%'" [style.width]="w.width + '%'">
        <div class="wait-line-start"></div>
        <div class="wait-line-time">{{w.time}}</div>
        <div class="wait-line-end"></div>
    </div>
</section>
