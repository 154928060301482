export function roundCurrency(val) {
    return +(Math.round(+((!!val ? val : 0) + 'e+2')) + 'e-2');
}

export function addCurrency(a, b) {
    return dollars(cents(a) + cents(b));
}

export function subtractCurrency(a, b) {
    return dollars(cents(a) - cents(b));
}

export function multiplyCurrency(a, b) {
    return dollars(cents(a) * b);
}

export function divideCurrency(a, b) {
    return dollars(cents(a) / b);
}

export function cents(val) {
    return (!!val ? val : 0) * 100;
}

export function dollars(val) {
    return roundCurrency((!!val ? val : 0) / 100);
}
