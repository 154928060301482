import {BaseFormModel} from './base-form-model';

export class MenuItemPriceSummary extends BaseFormModel<MenuItemPriceSummary> {
    itemId: number;
    priceId: number;
    itemName: string;
    priceName: string;
    price: number;

    qualified: boolean;
    reward: boolean;
    condition: boolean;
}
