import {Component, OnInit} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';
import {OrderService} from '../../_services/order.service';
import {PickupLocationService} from '../../_services/pickup-location.service';

@Component({
    selector: 'bb-set-marker',
    templateUrl: './set-marker.component.html',
    styleUrls: ['./set-marker.component.scss']
})
export class SetMarkerComponent extends BaseModalComponent implements OnInit {
    markers = [];
    active = [];

    constructor(
        private orderService: OrderService,
        private pickupLocationService: PickupLocationService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.subscribe(this.pickupLocationService.current.subscribe(p => {
            const markerStrings = p.availableMarkers.split(',');
            const tmp = [];
            markerStrings.forEach(s => tmp.push(parseInt(s, 10)));
            tmp.sort((a, b) => a - b);
            this.markers = tmp;
        }));

        this.subscribe(this.orderService.activeOrders.subscribe(o => {
            this.active = [...new Set(o.map(order => order.orderMarker))];
        }));
    }

    async submit(marker) {
        await this.close(marker);
    }
}
