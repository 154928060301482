import {ModuleWithProviders, NgModule} from '@angular/core';
import {SwiperModule} from 'swiper/angular';
import {
    CommonModule,
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    HashLocationStrategy,
    LocationStrategy,
    NgOptimizedImage,
    PercentPipe
} from '@angular/common';
import {RatingComponent} from './_components/rating/rating.component';
import {MaterialModule} from './material.module';
import {IonicModule} from '@ionic/angular';
import {OrderStatusPipe} from './_components/_pipes/order-status.pipe';
import {OrderStatusColorPipe} from './_components/_pipes/order-status-color.pipe';
import {DateAgoPipe} from './_components/_pipes/date-ago.pipe';
import {TabStatusPipe} from './_components/_pipes/tab-status.pipe';
import {LeftPadPipe} from './_components/_pipes/left-pad.pipe';
import {CreditTypePipe} from './_components/_pipes/credit-type.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {ChartModule} from 'primeng/chart';
import {MenuHeaderComponent} from './_components/menu-header/menu-header.component';
import {ModalHeaderComponent} from './_components/modal-header/modal-header.component';
import {PersonFormComponent} from './_components/_forms/person-form/person-form.component';
import {PersonComponent} from './_components/person/person.component';
import {ModalFooterComponent} from './_components/modal-footer/modal-footer.component';
import {LoginFormComponent} from './_components/_forms/login-form/login-form.component';
import {AddressFormComponent} from './_components/_forms/address-form/address-form.component';
import {OrganizationFormComponent} from './_components/_forms/organization-form/organization-form.component';
import {AddressComponent} from './_components/address/address.component';
import {OrganizationComponent} from './_components/organization/organization.component';
import {UserComponent} from './_components/user/user.component';
import {UsersComponent} from './_components/users/users.component';
import {MenuLabelComponent} from './_components/menu-label/menu-label.component';
import {RegisterUserComponent} from './_components/register-user/register-user.component';
import {SortModalComponent} from './_components/sort-modal/sort-modal.component';
import {PhoneMaskDirective} from './_directives/phone-mask.directive';
import {NumberMaskDirective} from './_directives/number-mask.directive';
import {CurrencyMaskDirective} from './_directives/currency-mask.directive';
import {FilterListComponent} from './_components/filter-list/filter-list.component';
import {ModalStepperFooterComponent} from './_components/modal-stepper-footer/modal-stepper-footer.component';
import {DisplayLabelModalComponent} from './_components/display-label-modal/display-label-modal.component';
import {DisplayOrderModalComponent} from './_components/display-order-modal/display-order-modal.component';
import {
    DisplayOrderItemSelectionItemComponent
} from './_components/display-order-item-selection-item/display-order-item-selection-item.component';
import {DisplayOrderItemComponent} from './_components/display-order-item/display-order-item.component';
import {PickupLocationComponent} from './_components/pickup-location/pickup-location.component';
import {TipComponent} from './_components/tip/tip.component';
import {RatingPopoverComponent} from './_components/rating-popover/rating-popover.component';
import {RewardModalComponent} from './_components/reward-modal/reward-modal.component';
import {OrderTypeComponent} from './_components/order-type/order-type.component';
import {OrderTypeSelectionComponent} from './_components/order-type-selection/order-type-selection.component';
import {ActiveOrdersComponent} from './_components/active-orders/active-orders.component';
import {PasswordRequirementsComponent} from './_components/password-requirements/password-requirements.component';
import {DigitOnlyDirective} from './_directives/digit-only.directive';
import {SetMarkerComponent} from './_components/set-marker/set-marker.component';
import {TabSummaryItemComponent} from './_components/tab-summary-item/tab-summary-item.component';
import {PaymentMethodListComponent} from './_components/payment-methods/payment-method-list.component';
import {UserSummaryItemComponent} from './_components/user-summary-item/user-summary-item.component';
import {UserTabDetailsComponent} from './_components/user-tab-details/user-tab-details.component';
import {SearchOrganizationsComponent} from './_components/search-organizations/search-organizations.component';
import {DisplayOrganizationModalComponent} from './_components/display-organization-modal/display-organization-modal.component';
import {TipSummaryComponent} from './_components/tip-summary/tip-summary.component';
import {RatingSummaryComponent} from './_components/rating-summary/rating-summary.component';
import {OrganizationRatingsComponent} from './_components/organization-ratings/organization-ratings.component';
import {EditRatingsComponent} from './_components/edit-ratings/edit-ratings.component';
import {EditOrganizationCreditComponent} from './_components/edit-organization-credit/edit-organization-credit.component';
import {DisplayRatingComponent} from './_components/display-rating/display-rating.component';
import {PaymentChargeRefundComponent} from './_components/payment-charge-refund/payment-charge-refund.component';
import {PaymentStatusComponent} from './_components/payment-status/payment-status.component';
import {EditAddressModalComponent} from './_components/edit-address-modal/edit-address-modal.component';
import {EditOrganizationModalComponent} from './_components/edit-organization-modal/edit-organization-modal.component';
import {EditPersonModalComponent} from './_components/edit-person-modal/edit-person-modal.component';
import {ConnectBankAccountModalComponent} from './_components/connect-bank-account/connect-bank-account-modal.component';
import {ForgotPasswordModalComponent} from './_components/forgot-password-modal/forgot-password-modal.component';
import {UploadImageModalComponent} from './_components/upload-image-modal/upload-image-modal.component';
import {InviteUserModalComponent} from './_components/invite-user-modal/invite-user-modal.component';
import {ChangePasswordModalComponent} from './_components/change-password-modal/change-password-modal.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './_interceptors/jwt.interceptor';
import {ErrorInterceptor} from './_interceptors/error.interceptor';
import {BrewBillRouteReuseStrategy} from './_route-reuse/brewbill-route-reuse-strategy';
import {LibConfigService} from './lib.config';
import {PrivacyPolicyComponent} from './_components/privacy-policy/privacy-policy.component';
import {CreditCardFormComponent} from './_components/_forms/credit-card-form/credit-card-form.component';
import {EditCreditCardComponent} from './_components/edit-credit-card/edit-credit-card.component';
import {DisplayRatingModalComponent} from './_components/display-rating-modal/display-rating-modal.component';
import {MenuItemSelectionComponent} from './_components/menu-item-selection/menu-item-selection.component';
import {CreatePushModalComponent} from './_components/create-push-modal/create-push-modal.component';
import {MarketingPushComponent} from './_components/marketing-push/marketing-push.component';
import {MarketingPushModalComponent} from './_components/marketing-push-modal/marketing-push-modal.component';
import {MobileMenuComponent} from './_components/mobile-menu/mobile-menu.component';
import {ChannelSubscriberComponent} from './_components/channel-subscriber.component';
import {SelectMenuItemTypeComponent} from './_components/select-menu-item-type/select-menu-item-type.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import {EditVenueModalComponent} from './_components/edit-venue-modal/edit-venue-modal.component';
import {VenueFormComponent} from './_components/_forms/venue-form/venue-form.component';
import {VenueMapComponent} from './_components/venue-map/venue-map.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {DateInputComponent} from './_components/date-input/date-input.component';
import {MenuSimulatorComponent} from './_components/menu-simulator/menu-simulator.component';
import {CounterComponent} from './_components/counter/counter.component';
import {DisplayOrderComponent} from './_components/display-order/display-order.component';
import {DisplayMenuItemModalComponent} from './_components/display-menu-item-modal/display-menu-item-modal.component';
import {ConfirmTabComponent} from './_components/confirm-tab/confirm-tab.component';
import {MenuItemImageComponent} from './_components/menu-item-image/menu-item-image.component';
import {ScrollingMarqueeComponent} from './_components/scrolling-marquee/scrolling-marquee.component';
import {RemovableItemComponent} from './_components/removable-item/removable-item.component';
import {CreditCardSummaryComponent} from './_components/credit-card-summary/credit-card-summary.component';
import {TimelineModule} from 'primeng/timeline';
import {VisitComponent} from './_components/visit/visit.component';
import {OrderTimelineComponent} from './_components/order-timeline/order-timeline.component';
import {CardLoadingComponent} from './_components/card-loading/card-loading.component';
import {OrderItemSummaryComponent} from './_components/order-item-summary/order-item-summary.component';
import {CompactOrderItemComponent} from './_components/compact-order-item/compact-order-item.component';
import {ManageOrderCardComponent} from './_components/manage-order-card/manage-order-card.component';
import {BaseStepperFormComponent} from './_components/base-stepper-form.component';
import {MasonryGridComponent} from './_components/masonry-grid/masonry-grid.component';
import {MasonryGridItemComponent} from './_components/masonry-grid-item/masonry-grid-item.component';
import {PickupLocationMapMarkerComponent} from './_components/pickup-location-map-marker/pickup-location-map-marker.component';
import {CreateTabCreditComponent} from './_components/create-tab-credit/create-tab-credit.component';
import {CashAuditEventPipe} from './_components/_pipes/cash-audit-event.pipe';
import {DateInputFormComponent} from './_components/date-input-form/date-input-form.component';
import {CreateLoginComponent} from './_components/create-login/create-login.component';
import {DashboardWidgetComponent} from './_components/_widgets/dashboard-widget/dashboard-widget.component';
import {ReportTableComponent} from './_components/_widgets/report-table/report-table.component';
import {UnderscoreConversionPipe} from './_components/_pipes/underscore-conversion.pipe';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {SingleItemWidgetComponent} from './_components/_widgets/single-item-widget/single-item-widget.component';
import {ClockInOutComponent} from './_components/clock-in-out/clock-in-out.component';
import {PieChartWidgetComponent} from './_components/_widgets/pie-chart-widget/pie-chart-widget.component';
import {BarChartWidgetComponent} from './_components/_widgets/bar-chart-widget/bar-chart-widget.component';
import {SummaryListWidgetComponent} from './_components/_widgets/summary-list-widget/summary-list-widget.component';
import {ListWidgetComponent} from './_components/_widgets/list-widget/list-widget.component';
import {ExpirationPipe} from './_components/_pipes/expiration.pipe';
import {SeparatorComponent} from './_components/separator/separator.component';
import {LoginModalComponent} from './_components/login-modal/login-modal.component';
import {ConfirmOrderComponent} from './_components/confirm-order/confirm-order.component';
import {SelectCardReaderComponent} from './_components/select-card-reader/select-card-reader.component';
import {CustomDollarAmountComponent} from './_components/custom-dollar-amount/custom-dollar-amount.component';
import {OrganizationUserComponent} from './_components/organization-user/organization-user.component';
import {SelectExistingCardComponent} from './_components/select-existing-card/select-existing-card.component';
import {ChangeCustomerPositionComponent} from './_components/change-customer-position/change-customer-position.component';
import {SelectServerComponent} from './_components/select-server/select-server.component';
import {SelectButtonsComponent} from './_components/select-buttons/select-buttons.component';
import {EmployeeHoursWidgetComponent} from './_components/_widgets/employee-hours-widget/employee-hours-widget.component';
import {TimecardComponent} from './_components/timecard/timecard.component';
import {ActiveTimecardsWidgetComponent} from './_components/_widgets/active-timecards-widget/active-timecards-widget.component';
import {NegativeCurrencyPipe} from './_components/_pipes/negative-currency.pipe';
import {LabelledItemComponent} from './_components/labelled-item/labelled-item.component';
import {EditTaxExemptionComponent} from './_components/edit-tax-exemption/edit-tax-exemption.component';
import {TypeaheadSelectComponent} from './_components/typeahead-select/typeahead-select.component';
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions/ngx';
import {SecuredComponent} from './_components/secured/secured.component';
import {SecuredDirective} from './_directives/secured.directive';
import {PinLoginComponent} from './_components/pin-login/pin-login.component';
import {LongPressDirective} from './_directives/long-press.directive';
import {EmailOptInComponent} from './_components/email-opt-in/email-opt-in.component';
import {MoveOrderComponent} from './_components/move-order/move-order.component';
import {ColorPickerComponent} from './_components/color-picker/color-picker.component';
import {ColorSketchModule} from 'ngx-color/sketch';
import {CreateOrderItemCreditComponent} from './_components/create-order-item-credit/create-order-item-credit.component';
import {EditTerminalComponent} from './_components/edit-terminal/edit-terminal.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MobileMenuItemComponent} from './_components/mobile-menu-item/mobile-menu-item.component';
import {MobileMenuAllPricesComponent} from './_components/mobile-menu-all-prices/mobile-menu-all-prices.component';
import {KegStatusComponent} from './_components/keg-status/keg-status.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {KickKegComponent} from './_components/kick-keg/kick-keg.component';
import {MenuItemInventoryActionComponent} from './_components/menu-item-inventory-action/menu-item-inventory-action.component';
import {MobileMenuPriceButtonComponent} from './_components/mobile-menu-price-button/mobile-menu-price-button.component';
import {CurrencyMaskPrefixDirective} from './_directives/currency-mask-prefix.directive';
import {CardSwipeMaskDirective} from './_directives/card-swipe-mask.directive';
import {StarPRNT} from '@awesome-cordova-plugins/star-prnt/ngx';
import {MenuItemGroupSelectComponent} from './_components/menu-item-group-select/menu-item-group-select.component';
import {SelectMembershipProgramComponent} from './_components/select-membership-program/select-membership-program.component';
import {TokensComponent} from './_components/tokens/tokens.component';
import {LoadGiftCardComponent} from './_components/load-gift-card/load-gift-card.component';
import {AssignCashDrawerComponent} from './_components/assign-cash-drawer/assign-cash-drawer.component';

@NgModule({
    imports: [
        ChartModule,
        CommonModule,
        FormsModule,
        GoogleMapsModule,
        ImageCropperModule,
        InfiniteScrollModule,
        IonicModule,
        MaterialModule,
        ReactiveFormsModule,
        RouterModule,
        SwiperModule,
        TimelineModule,
        NgOptimizedImage,
        ColorSketchModule,
        ClipboardModule,
        NgCircleProgressModule.forRoot({
            animationDuration: 300
        })
    ],
    declarations: [
        ActiveOrdersComponent,
        ActiveTimecardsWidgetComponent,
        AddressComponent,
        AddressFormComponent,
        AssignCashDrawerComponent,
        BarChartWidgetComponent,
        BaseStepperFormComponent,
        CardLoadingComponent,
        CardSwipeMaskDirective,
        CashAuditEventPipe,
        ChangeCustomerPositionComponent,
        ChangePasswordModalComponent,
        ChannelSubscriberComponent,
        ClockInOutComponent,
        ColorPickerComponent,
        CompactOrderItemComponent,
        ConfirmOrderComponent,
        ConfirmTabComponent,
        ConnectBankAccountModalComponent,
        CounterComponent,
        CreateLoginComponent,
        CreatePushModalComponent,
        CreateOrderItemCreditComponent,
        CreateTabCreditComponent,
        CreditCardFormComponent,
        CreditCardSummaryComponent,
        CreditTypePipe,
        CurrencyMaskDirective,
        CurrencyMaskPrefixDirective,
        CustomDollarAmountComponent,
        DashboardWidgetComponent,
        DateAgoPipe,
        DateInputComponent,
        DateInputFormComponent,
        DigitOnlyDirective,
        DisplayLabelModalComponent,
        DisplayMenuItemModalComponent,
        DisplayOrderComponent,
        DisplayOrderItemComponent,
        DisplayOrderItemSelectionItemComponent,
        DisplayOrderModalComponent,
        DisplayOrganizationModalComponent,
        DisplayRatingComponent,
        DisplayRatingModalComponent,
        EditAddressModalComponent,
        EditCreditCardComponent,
        EditOrganizationCreditComponent,
        EditOrganizationModalComponent,
        EditPersonModalComponent,
        EditRatingsComponent,
        EditTaxExemptionComponent,
        EditTerminalComponent,
        EditVenueModalComponent,
        EmailOptInComponent,
        EmployeeHoursWidgetComponent,
        ExpirationPipe,
        FilterListComponent,
        ForgotPasswordModalComponent,
        InviteUserModalComponent,
        LabelledItemComponent,
        LeftPadPipe,
        ListWidgetComponent,
        LoadGiftCardComponent,
        LoginFormComponent,
        LoginModalComponent,
        LongPressDirective,
        KegStatusComponent,
        KickKegComponent,
        ManageOrderCardComponent,
        MarketingPushComponent,
        MarketingPushModalComponent,
        MasonryGridComponent,
        MasonryGridItemComponent,
        MenuHeaderComponent,
        MenuItemSelectionComponent,
        MenuItemGroupSelectComponent,
        MenuItemImageComponent,
        MenuItemInventoryActionComponent,
        MenuLabelComponent,
        MenuSimulatorComponent,
        MobileMenuComponent,
        MobileMenuAllPricesComponent,
        MobileMenuItemComponent,
        MobileMenuPriceButtonComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalStepperFooterComponent,
        MoveOrderComponent,
        NegativeCurrencyPipe,
        NumberMaskDirective,
        OrderStatusColorPipe,
        OrderStatusPipe,
        OrderItemSummaryComponent,
        OrderTimelineComponent,
        OrderTypeComponent,
        OrderTypeSelectionComponent,
        OrganizationComponent,
        OrganizationFormComponent,
        OrganizationRatingsComponent,
        PasswordRequirementsComponent,
        PaymentChargeRefundComponent,
        PaymentMethodListComponent,
        PaymentStatusComponent,
        PersonComponent,
        PersonFormComponent,
        PhoneMaskDirective,
        PickupLocationComponent,
        PickupLocationMapMarkerComponent,
        PieChartWidgetComponent,
        PinLoginComponent,
        PrivacyPolicyComponent,
        RatingComponent,
        RatingPopoverComponent,
        RatingSummaryComponent,
        RegisterUserComponent,
        RemovableItemComponent,
        ReportTableComponent,
        RewardModalComponent,
        ScrollingMarqueeComponent,
        SearchOrganizationsComponent,
        SecuredComponent,
        SecuredDirective,
        SelectButtonsComponent,
        SelectCardReaderComponent,
        SelectMembershipProgramComponent,
        SelectMenuItemTypeComponent,
        SelectExistingCardComponent,
        SelectServerComponent,
        SeparatorComponent,
        SetMarkerComponent,
        SingleItemWidgetComponent,
        SortModalComponent,
        SummaryListWidgetComponent,
        TabStatusPipe,
        TabSummaryItemComponent,
        TimecardComponent,
        TipComponent,
        TipSummaryComponent,
        TokensComponent,
        TypeaheadSelectComponent,
        UnderscoreConversionPipe,
        UploadImageModalComponent,
        UserComponent,
        OrganizationUserComponent,
        UserSummaryItemComponent,
        UserTabDetailsComponent,
        UsersComponent,
        VenueFormComponent,
        VenueMapComponent,
        VisitComponent
    ],
    exports: [
        ActiveOrdersComponent,
        ActiveTimecardsWidgetComponent,
        AddressComponent,
        AddressFormComponent,
        AssignCashDrawerComponent,
        BarChartWidgetComponent,
        BaseStepperFormComponent,
        CardLoadingComponent,
        CardSwipeMaskDirective,
        CashAuditEventPipe,
        ColorPickerComponent,
        ChangeCustomerPositionComponent,
        ChangePasswordModalComponent,
        ChannelSubscriberComponent,
        ClockInOutComponent,
        CompactOrderItemComponent,
        ConfirmOrderComponent,
        ConfirmTabComponent,
        ConnectBankAccountModalComponent,
        CounterComponent,
        CreateLoginComponent,
        CreateOrderItemCreditComponent,
        CreateTabCreditComponent,
        CreditCardFormComponent,
        CreditCardSummaryComponent,
        CreditTypePipe,
        CurrencyMaskDirective,
        CurrencyMaskPrefixDirective,
        CustomDollarAmountComponent,
        DashboardWidgetComponent,
        DateAgoPipe,
        DateInputComponent,
        DateInputFormComponent,
        DigitOnlyDirective,
        DisplayMenuItemModalComponent,
        DisplayOrderComponent,
        DisplayOrderItemComponent,
        DisplayRatingModalComponent,
        EditAddressModalComponent,
        EditCreditCardComponent,
        EditOrganizationCreditComponent,
        EditOrganizationModalComponent,
        EditPersonModalComponent,
        EditTaxExemptionComponent,
        EditTerminalComponent,
        EditVenueModalComponent,
        EmailOptInComponent,
        EmployeeHoursWidgetComponent,
        FilterListComponent,
        ForgotPasswordModalComponent,
        InviteUserModalComponent,
        LabelledItemComponent,
        LeftPadPipe,
        ListWidgetComponent,
        LoadGiftCardComponent,
        LoginFormComponent,
        LoginModalComponent,
        LongPressDirective,
        KegStatusComponent,
        KickKegComponent,
        ManageOrderCardComponent,
        MarketingPushComponent,
        MarketingPushModalComponent,
        MasonryGridComponent,
        MasonryGridItemComponent,
        MenuHeaderComponent,
        MenuItemImageComponent,
        MenuItemInventoryActionComponent,
        MenuItemGroupSelectComponent,
        MenuItemSelectionComponent,
        MenuLabelComponent,
        MenuSimulatorComponent,
        MobileMenuComponent,
        MobileMenuAllPricesComponent,
        MobileMenuItemComponent,
        MobileMenuPriceButtonComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalStepperFooterComponent,
        MoveOrderComponent,
        NegativeCurrencyPipe,
        NumberMaskDirective,
        OrderStatusPipe,
        OrderStatusColorPipe,
        OrderItemSummaryComponent,
        OrderTimelineComponent,
        OrderTypeComponent,
        OrderTypeSelectionComponent,
        OrganizationComponent,
        OrganizationFormComponent,
        OrganizationRatingsComponent,
        PaymentMethodListComponent,
        PaymentStatusComponent,
        PersonComponent,
        PersonFormComponent,
        PhoneMaskDirective,
        PickupLocationComponent,
        PickupLocationMapMarkerComponent,
        PieChartWidgetComponent,
        PinLoginComponent,
        PrivacyPolicyComponent,
        RatingComponent,
        RatingSummaryComponent,
        RegisterUserComponent,
        RemovableItemComponent,
        ReportTableComponent,
        ScrollingMarqueeComponent,
        SearchOrganizationsComponent,
        SecuredComponent,
        SecuredDirective,
        SelectButtonsComponent,
        SelectCardReaderComponent,
        SelectMembershipProgramComponent,
        SelectMenuItemTypeComponent,
        SelectExistingCardComponent,
        SelectServerComponent,
        SeparatorComponent,
        SingleItemWidgetComponent,
        SortModalComponent,
        SummaryListWidgetComponent,
        TabSummaryItemComponent,
        TimecardComponent,
        TipComponent,
        TipSummaryComponent,
        TokensComponent,
        TypeaheadSelectComponent,
        UnderscoreConversionPipe,
        UploadImageModalComponent,
        UserComponent,
        OrganizationUserComponent,
        UserSummaryItemComponent,
        UserTabDetailsComponent,
        UsersComponent,
        VenueFormComponent,
        VenueMapComponent,
        VisitComponent
    ],
    providers: [
        {provide: RouteReuseStrategy, useClass: BrewBillRouteReuseStrategy},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        AndroidPermissions,
        BluetoothSerial,
        CashAuditEventPipe,
        CreditTypePipe,
        CurrencyPipe,
        DateAgoPipe,
        DatePipe,
        DecimalPipe,
        ExpirationPipe,
        LeftPadPipe,
        NegativeCurrencyPipe,
        OrderStatusColorPipe,
        OrderStatusPipe,
        PercentPipe,
        TabStatusPipe,
        UnderscoreConversionPipe,
        StarPRNT
    ]
})
export class BrewBillLibModule {
    static forRoot(config): ModuleWithProviders<BrewBillLibModule> {
        return {
            ngModule: BrewBillLibModule,
            providers: [
                {
                    provide: LibConfigService,
                    useValue: config
                }
            ]
        };
    }
}
