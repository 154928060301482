import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-create-login',
    templateUrl: './create-login.component.html',
    styleUrls: ['./create-login.component.css'],
})
export class CreateLoginComponent extends BaseModalComponent implements OnInit {
    loginFormGroup: FormGroup = new FormGroup({});

    constructor(modalController: ModalController) {
        super(modalController);
    }

    ngOnInit() {
    }

    loginFormInitialized(form: FormGroup) {
        this.loginFormGroup = form;
    }

    async submit() {
        await this.close(this.loginFormGroup.controls.password.value);
    }
}
