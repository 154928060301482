import {Inject, Injectable} from '@angular/core';
import {LibConfig, LibConfigService} from '../lib.config';
import {CardSummary} from '../_models/card-summary';
import {HttpClient} from '@angular/common/http';
import {ModalController} from '@ionic/angular';
import {BaseService} from './base-service';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class CardSummaryService extends BaseService<CardSummary> {

    destination = 'card-summaries';

    constructor(
        http: HttpClient,
        private modalController: ModalController,
        private userService: UserService,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);
    }

    makeDefault(cardSummary: CardSummary) {
        return this.http.put(`${this.baseUrl()}/${cardSummary.id}/default`, {});
    }

    deleteCard(cardSummaryId: number) {
        return this.http.delete(`${this.baseUrl()}/${cardSummaryId}`);
    }

    findByOrganizationId(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}`);
    }

    findByOrganizationIdAndLastFour(organizationId: number, lastFour: string) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/${lastFour}`);
    }
}
