export function formatDistance(miles: number) {
    if (!!miles || miles === 0) {
        if (miles > .25) {
            return ` (${Math.round(miles * 10) / 10}mi)`;
        } else {
            return ` (${Math.round(miles * 5280)}ft)`;
        }
    } else {
        return '';
    }
}
