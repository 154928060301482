import {BaseFormModel} from './base-form-model';
import {Person} from './person';
import {CashAudit} from './cash-information/cash-audit';
import {PaymentCharge} from './payment-charge';

export class Deposit extends BaseFormModel<Deposit> {
    id: number;
    orgId: number;
    person: Person;
    createdBy: Person;
    createDate: Date;
    refundedBy: Person;
    refundDate: Date;
    status: string;
    cashAudit: CashAudit;
    paymentCharge: PaymentCharge;
    deviceId: string;
    label: string;
    amount: number;
    imported: boolean;

    selected: boolean;
}
