import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CustomerAreaService} from '../_services/customer-area.service';

@Injectable()
export class CustomerAreaResolver implements Resolve<any> {
    constructor(private customerAreaService: CustomerAreaService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.customerAreaService.get(route.paramMap.get('customerAreaId'));
    }
}
