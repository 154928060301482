import {CardSummary} from '../_models/card-summary';
import {PaymentCharge} from '../_models/payment-charge';
import {CashAudit} from '../_models/cash-information/cash-audit';
import {GiftCardAction} from '../_models/gift-card-action';

export function convertCardToClass(card: CardSummary) {
    return card.cardType.toLowerCase().replace(' ', '-');
}

export function sortCards(cards) {
    return cards.sort(
        (a: CardSummary, b: CardSummary) => {
            return (a.defaultSource === b.defaultSource) ? 0 : a.defaultSource ? -1 : 1;
        }
    );
}

export function sortPayments(charges: PaymentCharge[], cashPayments: CashAudit[], giftCardPayments: GiftCardAction[]) {
    const payments = [...charges, ...cashPayments, ...giftCardPayments];
    payments.sort(
        (a: CashAudit | PaymentCharge | GiftCardAction, b: CashAudit | PaymentCharge | GiftCardAction) => {
            return new Date(a.createDate).getTime() - new Date(b.createDate).getTime();
        });
    return payments;
}

export function isCashPayment(payment: CashAudit | PaymentCharge | GiftCardAction): payment is CashAudit {
    return (payment as CashAudit).action !== undefined;
}

export function isGiftCardPayment(payment: CashAudit | PaymentCharge | GiftCardAction): payment is CashAudit {
    return (payment as GiftCardAction).balance !== undefined;
}

export function isCreditCardPayment(payment: CashAudit | PaymentCharge | GiftCardAction): payment is CashAudit {
    return (payment as PaymentCharge).refundable !== undefined;
}
