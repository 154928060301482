<ion-grid *ngIf="!!item" [class.child]="level > 1" [class.remove-padding]="removePadding"
          [id]="!!itemSummary ? 'item_summary_' + itemSummary.key : 'item_' + item.id">
    <ion-row *ngIf="level === 1 && !hideHeader && !details">
        <ion-col *ngIf="level === 1 && !hideHeader" size-xs="4">
            <bb-menu-item-image *ngIf="!!item && count > 0"
                                [nameOverride]="!item.giftCard && !item.membershipRenewal ? 'Misc' : (!item.membershipRenewal ? 'Gift Card' : (!item.membershipRenewal.membershipProgram ? 'Membership' : item.membershipRenewal.membershipProgram.name))"
                                [menuItem]="item.menuItem"
                                [orderDisplay]="true"
                                [orderType]="item.orderType"
                                [showImageOverride]="showImageOverride"
                                (imageLoaded)="imageLoaded.emit()"></bb-menu-item-image>
            <div *ngIf="!!count && count > 1 && !editable" class="count-badge">x{{ count }}</div>
            <div *ngIf="editable && (!miscItem || item.membershipRenewal)" class="order-item-counter">
                <bb-counter [value]="!!item.membershipRenewal ? item.membershipRenewal.quantity : count"
                            [scale]="true"
                            [displayAdd]="editable"
                            (add)="addClicked()"
                            (remove)="removeClicked()">
                </bb-counter>
            </div>
        </ion-col>
        <ion-col size-xs="8" class="item-info-container" [class.remove-padding]="removePadding">
            <ion-grid>
                <ng-container>
                    <ion-row>
                        <ion-col *ngIf="!!item.membershipRenewal" class="item-name">
                            <ng-container *ngIf="!!item.membershipRenewal.membershipName; else noPerson">
                                <span>{{ item.membershipRenewal.membershipName }}</span>
                                <p class="sub-header">{{ item.membershipRenewal.getDescription() | underscoreConversion }}</p>
                            </ng-container>
                            <ng-template #noPerson>
                                <span>{{ item.membershipRenewal.getDescription()  | underscoreConversion }}</span>
                            </ng-template>
                        </ion-col>
                        <ion-col *ngIf="!item.membershipRenewal" class="item-name">
                            {{
                                !miscItem
                                    ? item.menuItem.name
                                    : (!item.giftCard ? item.notes : item.giftCard.cardNumber)
                            }} {{
                                !!item.selectedPrice && !!item.selectedPrice.name && item.selectedPrice.name.toLowerCase() !== 'default'
                                    ? '(' + item.selectedPrice.name + ') '
                                    : ''
                            }}
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col *ngIf="!item.selectedPrice"
                                 class="ion-text-end item-price"
                                 [class.remove-padding]="removePadding"
                                 [class.canceled-price]="orderStatus === orderStatuses.CANCELED">
                            <span *ngIf="!item.membershipFeeIncluded">{{
                                !!item.priceOverride
                                    ? item.priceOverride
                                    : item.total | currency
                                }}
                            </span>
                            <span *ngIf="item.membershipFeeIncluded">{{
                                    addCurrency(item.total, item.membershipFees) | currency
                                }}
                            </span>
                            <div class="remove-container"><a *ngIf="editable" class="remove-misc" (click)="removeMisc($event)">Remove</a></div>
                        </ion-col>
                        <ng-container *ngIf="!!item.selectedPrice">
                            <ion-col *ngIf="!!item.tokenPayment; else nonTokenPrice"
                                     class="ion-text-end item-price"
                                     [class.remove-padding]="removePadding"
                                     [class.canceled-price]="orderStatus === orderStatuses.CANCELED">
                                <div class="token-price-container">
                                    <bb-tokens [count]="adjustedTokenPrice(item.tokenPayment)"
                                               (click)="$event.stopPropagation(); removeTokens.emit(item)"></bb-tokens>
                                </div>
                            </ion-col>
                            <ng-template #nonTokenPrice>
                                <ion-col class="ion-text-end item-price"
                                         [class.remove-padding]="removePadding"
                                         [class.canceled-price]="orderStatus === orderStatuses.CANCELED">{{
                                        adjustedPrice(item.selectedPrice.variable
                                            ? item.priceOverride
                                            : item.selectedPrice.price) | currency
                                    }} <span *ngIf="item.selectedPrice.deposit" class="deposit-label">Deposit</span>
                                </ion-col>
                            </ng-template>
                        </ng-container>

                        <ion-col *ngIf="additionalPrice"
                                 class="ion-text-end item-price"
                                 [class.remove-padding]="removePadding"
                                 [class.canceled-price]="orderStatus === orderStatuses.CANCELED">{{ adjustedPrice(additionalPrice) | currency }}
                        </ion-col>
                    </ion-row>
                </ng-container>
            </ion-grid>
        </ion-col>
    </ion-row>

    <ion-row *ngIf="level === 1 && details">
        <ion-col *ngIf="!miscItem">
            {{ item.menuItem.name }} {{
                !!item.selectedPrice && !!item.selectedPrice.name && item.selectedPrice.name.toLowerCase() !== 'default'
                    ? '(' + item.selectedPrice.name + ') '
                    : ''
            }}
        </ion-col>

        <ion-col *ngIf="miscItem">
            <span>{{
                    !item.giftCard && !item.membershipRenewal ? 'Misc' : (!item.membershipRenewal
                        ? 'Gift Card'
                        : (!item.membershipRenewal.membershipProgram ? 'Membership' : (!!item.membershipRenewal.membershipName
                            ? item.membershipRenewal.membershipName + ': '
                            : '') + item.membershipRenewal.membershipProgram.name))
                }}</span>
            <p *ngIf="!!item.giftCard" class="sub-header">{{ item.giftCard.cardNumber }}</p>
            <p *ngIf="!!item.membershipRenewal" class="sub-header">{{ item.membershipRenewal.getDescription() | underscoreConversion }}</p>
            <p *ngIf="!item.membershipRenewal && !item.giftCard && item.notes" class="sub-header">{{ item.notes }}</p>
        </ion-col>

        <ng-container *ngIf="!item.tokenPayment">
            <ion-col *ngIf="!!item.priceOverride" class="ion-text-end"
                     [class.canceled-price]="orderStatus === orderStatuses.CANCELED">{{ adjustedPrice(item.priceOverride) | currency }}
            </ion-col>
            <ion-col *ngIf="!item.priceOverride && !!item.selectedPrice" class="ion-text-end"
                     [class.canceled-price]="orderStatus === orderStatuses.CANCELED">{{ adjustedPrice(item.selectedPrice.price) | currency }}
            </ion-col>
            <ion-col *ngIf="!item.menuItem && !item.priceOverride && !item.selectedPrice && !!item.total" class="ion-text-end"
                     [class.canceled-price]="orderStatus === orderStatuses.CANCELED">{{ adjustedPrice(item.total) | currency }}
            </ion-col>
            <ion-col *ngIf="additionalPrice" class="ion-text-end"
                     [class.canceled-price]="orderStatus === orderStatuses.CANCELED">{{ adjustedPrice(additionalPrice) | currency }}
            </ion-col>
        </ng-container>

        <ng-container *ngIf="!!item.tokenPayment">
            <ion-col class="ion-text-end">
                <bb-tokens [count]="adjustedPrice(item.tokenPayment)"
                           [canceled]="orderStatus === orderStatuses.CANCELED"
                           (click)="$event.stopPropagation(); removeTokens.emit(item)"></bb-tokens>
            </ion-col>
        </ng-container>
    </ion-row>

    <ng-container *ngIf="item.removedToppingsFilter().length > 0">
        <ion-row>
            <ion-col [style]="parentRow()">
                Remove:
            </ion-col>
        </ion-row>
        <ion-row *ngFor="let topping of item.removedToppingsFilter()">
            <ion-col [style]="childRow()">
                {{ topping.menuItemTopping.topping.name }}
            </ion-col>
        </ion-row>
    </ng-container>

    <ng-container *ngIf="item.addedToppingsFilter().length > 0">
        <ion-row>
            <ion-col [style]="parentRow()">Add:</ion-col>
        </ion-row>
        <ion-row *ngFor="let topping of item.addedToppingsFilter()">
            <ion-col [style]="childRow()">
                {{ topping.menuItemTopping.topping.name }}
            </ion-col>
            <ion-col *ngIf="topping.menuItemTopping.price && topping.menuItemTopping.price != 0"
                     class="ion-text-end"
                     [class.remove-padding]="removePadding"
                     [class.canceled-price]="orderStatus === orderStatuses.CANCELED">{{ adjustedPrice(topping.menuItemTopping.price) | currency }}
            </ion-col>
        </ion-row>
    </ng-container>

    <ng-container *ngFor="let menuSelection of item.selections">
        <ion-row>
            <ion-col [style]="parentRow()">
                <ion-text color="secondary">
                    {{
                        menuSelection.menuItemSelection.menuSelection.name + (menuSelection.countTotal() != 0
                            ? (' (x' + menuSelection.selectedItems.length + ')')
                            : '')
                    }}
                </ion-text>
            </ion-col>
            <ion-col class="ion-text-end" *ngIf="menuSelection.countTotal() != 0" [class.remove-padding]="removePadding">
                <ion-text color="secondary">{{ adjustedPrice(menuSelection.countTotal()) | currency }}</ion-text>
            </ion-col>
        </ion-row>
        <bb-display-order-item-selection-item *ngFor="let selection of menuSelection.selectedItems; trackBy: getId"
                                              [parent]="item"
                                              [count]="count"
                                              [selection]="selection"
                                              [level]="level"
                                              [orderStatus]="orderStatus">
        </bb-display-order-item-selection-item>
        <ion-row *ngIf="menuSelection.selectedItems.length === 0">
            <ion-col [style]="childRow()">
                None
            </ion-col>
        </ion-row>
    </ng-container>

    <ng-container *ngIf="!!item.notes && !miscItem">
        <ion-row>
            <ion-col [style]="parentRow()">
                <ion-text color="tertiary">
                    Instructions:
                </ion-text>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col [style]="parentRow()">
                {{ item.notes }}
            </ion-col>
        </ion-row>
    </ng-container>

    <ion-row
            *ngIf="isTerminal && !!item.selectedPrice && orderStatus !== orderStatuses.CANCELED && editable && !!filteredComps && filteredComps.length > 0 && item.calcTotal() > item.calcCredits()">
        <ion-col size="12" class="ion-text-end">
            <a (click)="addCreditClicked($event)">Add Comp</a>
        </ion-col>
    </ion-row>
    <ion-row *ngFor="let credit of item.credits; trackBy: getId">
        <ion-col>
            <div class="comp-container">
                <div class="comp-label ion-text-end">
                    <span *ngIf="!!credit.compDefinition">{{ credit.compDefinition.name }} <span
                            *ngIf="credit.compDefinition.type === compDefinitionTypes.PERCENT">({{ credit.compDefinition.amount }}%)</span></span>
                    <p>({{ adjustedPrice(credit.amount) | currency }})</p>
                </div>
                <div *ngIf="isTerminal && editable" class="comp-actions">
                    <ion-toolbar>
                        <ion-buttons>
                            <ion-button color="dark"
                                        [disabled]="!currentUser.authorizedTo(featureKeys.CREDIT_APPLY, organization.id) && (!credit.compDefinition || !currentUser.hasRole(credit.compDefinition.roles, organization.id))"
                                        (click)="removeCreditClicked($event, credit)">
                                <ion-icon name="trash-outline" slot="icon-only"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-toolbar>
                </div>
            </div>
        </ion-col>
    </ion-row>
</ion-grid>
