import {Injectable} from '@angular/core';
import {DEVICE_ID} from '../_utils/local-storage-utils';
import {Device} from '@capacitor/device';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    public deviceId: Observable<string>;
    private deviceIdSubject: BehaviorSubject<string>;

    constructor() {
        this.deviceIdSubject = new BehaviorSubject<string>(null);
        this.deviceId = this.deviceIdSubject.asObservable();

        const deviceId = localStorage.getItem(DEVICE_ID);
        if (!deviceId) {
            Device.getId().then(d => {
                this.deviceIdSubject.next(d.identifier);
                localStorage.setItem(DEVICE_ID, d.identifier);
            });
        } else {
            this.deviceIdSubject.next(deviceId);
        }
    }

    async getDeviceId(attempt = 0) {
        const deviceId = this.deviceIdSubject.value;
        if (!!deviceId) {
            return Promise.resolve(deviceId);
        } else if (attempt > 50) {
            return Promise.resolve(null);
        } else {
            return new Promise(resolve => {
                setTimeout(async () => {
                    resolve(this.getDeviceId(attempt + 1));
                }, 100);
            });
        }
    }

    public get currentValue(): string {
        return this.deviceIdSubject.value;
    }
}
