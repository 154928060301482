import {BaseFormModel} from './base-form-model';
import {CompDefinition} from './comp-definition';

export class OrganizationEvent extends BaseFormModel<OrganizationEvent> {
    id: number;
    orgId: number;
    name: string;
    description: string;
    type: string;
    availability: string;
    color: string;
    contrastColor: string;
    compDefinitions: CompDefinition[];
}
