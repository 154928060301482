import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CardSummaryService} from '../_services/card-summary.service';

@Injectable()
export class PaymentMethodsResolver implements Resolve<any> {
    constructor(private cardSummaryService: CardSummaryService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        // return this.cardSummaryService.paymentMethods();
    }
}
