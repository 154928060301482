import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {KickedKegService} from '../_services/kicked-keg.service';

@Injectable()
export class MenuItemKickedKegsSummaryResolver implements Resolve<any> {
    constructor(private kickedKegService: KickedKegService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.kickedKegService.findSummaryByMenuItemId(route.paramMap.get('menuItemId'));
    }
}
