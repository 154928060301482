import {Injectable} from '@angular/core';
import {Order} from '../_models/order';
import {OrderStatuses} from '../_constants/order-statuses';
import {BaseOfflineService} from './base-offline.service';
import {v4 as uuidv4} from 'uuid';
import {PickupLocationService} from './pickup-location.service';
import {OrganizationService} from './organization.service';
import {OrderService} from './order.service';

@Injectable({
    providedIn: 'root'
})
export class OfflineOrderService extends BaseOfflineService {
    deviceId: string;

    constructor(
        private orderService: OrderService,
        pickupLocationService: PickupLocationService,
        organizationService: OrganizationService
    ) {
        super(pickupLocationService, organizationService);
    }

    initialize(order: Order, deviceId: string): Order {
        order.pickupLocation = this.pickupLocation;
        order.date = new Date();
        order.deviceId = deviceId;
        order.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        order.status = OrderStatuses.PENDING;
        order.key = uuidv4();
        return order;
    }
}
