<modal-header title="Card Details"></modal-header>
<ion-content>
    <ion-grid class="name-container" *ngIf="requireName" [formGroup]="formGroup">
        <ion-row>
            <ion-col size-xs="12" size-md="6">
                <ion-item>
                    <ion-label position="floating">First Name</ion-label>
                    <ion-input id="firstName" data-cy="first-name-input" name="firstName" type="text" formControlName="firstName"
                               required #firstInput></ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-xs="12" size-md="6">
                <ion-item>
                    <ion-label position="floating">Last Name</ion-label>
                    <ion-input id="lastName" name="lastName" type="text" formControlName="lastName"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-item>
                    <ion-label position="floating">Street Address</ion-label>
                    <ion-input id="addr1" data-cy="addr1-input" name="addr1" type="text" formControlName="addr1"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-item>
                    <ion-label position="floating">Postal Code</ion-label>
                    <ion-input id="postalCode" data-cy="postal-code-input" name="postalCode" type="text" formControlName="postalCode"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
    <bb-credit-card-form [organization]="organization"
                         [externalValid]="formGroup.valid"
                         [hideCancel]="true"
                         (token)="submit($event)"></bb-credit-card-form>
</ion-content>
