import {BaseFormModel} from './base-form-model';
import {Organization} from './organization';
import {MenuItemPriceSummary} from './menu-item-price-summary';

export class OrganizationRewardProgram extends BaseFormModel<OrganizationRewardProgram> {
    id: number;
    organization: Organization;
    type: string;
    goal: number;
    rewardType: string;
    reward: number;
    rewardName: string;

    qualifiedItems: MenuItemPriceSummary[];
    rewardItems: MenuItemPriceSummary[];
}
