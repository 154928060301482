import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseForm} from '../base-form';
import {Venue} from '../../../_models/venue';

@Component({
    selector: 'bb-venue-form',
    templateUrl: './venue-form.component.html',
    styleUrls: ['./venue-form.component.scss'],
})
export class VenueFormComponent extends BaseForm {
    @Input() venue: Venue;

    constructor(private formBuilder: FormBuilder) {
        super();
        this.venue = this.venue == null ? new Venue() : this.venue;
    }

    setupFormGroup(): FormGroup {
        return this.formBuilder.group({
            id: this.venue.id,
            name: [this.venue.name, Validators.required],
            subdomain: [this.venue.subdomain, Validators.required],
            phone: [this.venue.phone, Validators.required]
        });
    }
}
