import {Injectable} from '@angular/core';
import {Sortable} from '../_models/sortable';
import {BaseObservableService} from './base-obeservable-service';

@Injectable({
    providedIn: 'root'
})
export abstract class SortableService<T extends Sortable> extends BaseObservableService<T> {
    sort(items: Sortable[]) {
        return this.http.put(this.baseUrl() + '/sort', items);
    }
}
