import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {createAnimation} from '@ionic/angular';
import {Animation} from '@ionic/core/dist/types/utils/animation/animation-interface';
import {MenuMarqueeItem} from '../../_models/menu-marquee-item';
import {MenuItem} from '../../_models/menu-item';

@Component({
    selector: 'bb-scrolling-marquee',
    templateUrl: './scrolling-marquee.component.html',
    styleUrls: ['./scrolling-marquee.component.scss'],
})
export class ScrollingMarqueeComponent {
    @Input() duration: number;
    @Input() items: MenuMarqueeItem[];

    currentIndex = 0;

    @Output() menuItemClicked: EventEmitter<any> = new EventEmitter();

    marquee: ElementRef;
    animation: Animation;
    paused = false;

    @ViewChild('container') container: ElementRef;

    constructor() {
    }

    @ViewChild('marquee') set content(content: ElementRef) {
        if (content) {
            this.marquee = content;
            this.scroll();
        }
    }

    scroll() {
        if (!this.duration) {
            // this.duration = 8000 * this.items.length;
            this.duration = 8000;
        }
        this.animation = createAnimation()
            .beforeRemoveClass('clear')
            .addElement(this.marquee.nativeElement)
            .duration(this.duration)
            .iterations(1)
            .fromTo('transform', 'translateX(100%)', 'translateX(-100%)')
            .afterAddClass('clear');
        this.animation.play().then(() => {
            if (this.currentIndex === this.items.length - 1) {
                this.currentIndex = 0;
            } else {
                this.currentIndex++;
            }
            this.scroll();
        });
    }

    itemClicked(menuItem: MenuItem) {
        this.menuItemClicked.emit(menuItem);
    }
}
