import {Component, Input, OnInit} from '@angular/core';
import {OrganizationRating} from '../../_models/organization-rating';
import {OrganizationRatingService} from '../../_services/organization-rating.service';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-display-rating-modal',
    templateUrl: './display-rating-modal.component.html',
    styleUrls: ['./display-rating-modal.component.css']
})
export class DisplayRatingModalComponent extends BaseModalComponent implements OnInit {
    @Input() rating: OrganizationRating;

    constructor(
        private organizationRatingService: OrganizationRatingService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.organizationRatingService.toggleUserRead(this.rating.id).subscribe((r: OrganizationRating) => {
            this.rating.responseReadDate = r.responseReadDate;
        });
    }
}
