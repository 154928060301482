import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {MembershipTokenGroup} from '../_models/membership-token-group';

@Injectable({
    providedIn: 'root'
})
export class MembershipTokenGroupService extends BaseService<MembershipTokenGroup> {
    destination = 'membership-token-groups';

}
