import {BaseFormModel} from './base-form-model';

export class TabNoteDefinition extends BaseFormModel<TabNoteDefinition> {
    id: number;
    orgId: number;
    name: string;
    description: string;
    type: string;
    availability: string;
    color: string;
    contrastColor: string;
}
