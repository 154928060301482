import {Inject, Injectable} from '@angular/core';
import {ToastService} from './toast.service';
import {LoadingService} from './loading.service';
import {AlignmentPosition, CutPaperAction, Emulation, RasterObj, StarPRNT} from '@awesome-cordova-plugins/star-prnt/ngx';
import {BehaviorSubject, Observable} from 'rxjs';
import {CashSession} from '../_models/cash-information/cash-session';
import {CashSessionSummary} from '../_models/cash-information/cash-session-summary';
import {Organization} from '../_models/organization';
import {Timecard} from '../_models/timecard';
import {Order} from '../_models/order';
import {formattedDate, formatToHoursAndMinutes} from '../_utils/date-utils';
import {buildLineItem, lineSeparator} from '../_utils/receipt-utils';
import {OrderItem} from '../_models/order-item';
import {CurrencyPipe, DatePipe, formatDate} from '@angular/common';
import {Availabilities} from '../_constants/availabilities';
import {Tab} from '../_models/tab';
import {LibConfig, LibConfigService} from '../lib.config';
import moment from 'moment/moment';

@Injectable({
    providedIn: 'root'
})
export class StarPrinterService {
    port: string;
    modelName: string;
    columns;

    public connected: Observable<boolean>;
    private connectedSubject: BehaviorSubject<boolean>;

    constructor(
        @Inject(LibConfigService) private config: LibConfig,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private starPRNT: StarPRNT
    ) {
        this.connectedSubject = new BehaviorSubject<boolean>(!!this.port);
        this.connected = this.connectedSubject.asObservable();

        const col = localStorage.getItem('PRINTER_COLUMNS');
        this.columns = !!col ? parseInt(col, 10) : 38;
    }

    async discover() {
        try {
            const res = await this.starPRNT.portDiscovery('All');
            if (!!res && res.length > 0) {
                this.port = res[0].portName;
                this.modelName = res[0].modelName;
                if (!this.connected) {
                    this.starPRNT.connect(this.port, Emulation.StarPRNT, false).subscribe(c => {
                        console.log(`Connected on ${this.port}`);
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }
        this.connectedSubject.next(!!this.port);
    }

    async openDrawer() {
        if (!!this.port) {
            await this.starPRNT.openCashDrawer(this.port, Emulation.StarPRNT);
        }
    }

    async print(data) {
        if (!!this.port) {
            const rasterObj: RasterObj = {
                text: data,
                paperWidth: 576,
                fontSize: 25,
                cutReceipt: true,
                openCashDrawer: false
            };

            try {
                console.log(data);
                const commandsArray = [];
                commandsArray.push({appendAlignment: AlignmentPosition.Center});
                commandsArray.push({appendLogo: 1});
                commandsArray.push({appendAlignment: AlignmentPosition.Left});
                commandsArray.push({append: data});

                this.send(commandsArray, true);

                // await this.starPRNT.printRasterReceipt(this.port, Emulation.StarPRNT, rasterObj);
            } catch (error) {
                await this.toastService.error(error);
            }
        } else {
            await this.toastService.error('No printer found.');
        }
    }

    printClaimTab(tab: Tab) {
        try {
            const commandsArray = [];
            commandsArray.push({appendAlignment: AlignmentPosition.Center});
            commandsArray.push({appendLogo: 1});
            commandsArray.push({appendMultiple: `\nThank you ${tab.personName}!\n\n`, width: 2, height: 2});
            commandsArray.push({append: `Scan the QR code to manage your tab from your phone.\n\n`});
            commandsArray.push({
                appendQrCode: `${this.config.mobileUrl}/#/claim-tab/${tab.id}/${tab.claimCode}`,
                QrCodeModel: 'No2',
                QrCodeLevel: 'M',
                cell: 8,
                alignment: 'Center'
            });

            this.send(commandsArray);
        } catch (error) {
            console.log(error);
            this.loadingService.dismiss();
            this.toastService.error('Could not print summary');
        }
    }

    printCashSession(cashSession: CashSession, summary: CashSessionSummary, organization: Organization) {
        this.loadingService.present('Printing.');
        try {
            const commandsArray = [];
            commandsArray.push({append: organization.name + '\n'});

            commandsArray.push({appendAlignment: AlignmentPosition.Right});
            commandsArray.push({append: `${formattedDate(cashSession.pendedTime)}\n`});

            this.addSeparator(commandsArray);

            commandsArray.push({appendAlignment: AlignmentPosition.Left});
            commandsArray.push({append: '\nSales\n'});
            this.addSeparator(commandsArray);

            commandsArray.push({appendAlignment: AlignmentPosition.Left});
            commandsArray.push({
                append: buildLineItem('Cash:',
                    this.currencyPipe.transform(summary.cashSalesNet), 0, this.columns)
            });
            commandsArray.push({
                append: buildLineItem('Charge:',
                    this.currencyPipe.transform(summary.chargePrincipal), 0, this.columns)
            });
            commandsArray.push({
                append: buildLineItem('Gift Card:',
                    this.currencyPipe.transform(summary.giftCardPrincipal), 0, this.columns)
            });
            commandsArray.push({
                append: buildLineItem('Comps:',
                    this.currencyPipe.transform(summary.creditAmount), 0, this.columns)
            });
            commandsArray.push({
                append: buildLineItem('Total:',
                    this.currencyPipe.transform(summary.total), 0, this.columns)
            });
            this.addSeparator(commandsArray);

            commandsArray.push({appendAlignment: AlignmentPosition.Left});
            commandsArray.push({append: '\nTips\n'});
            this.addSeparator(commandsArray);

            commandsArray.push({appendAlignment: AlignmentPosition.Left});
            commandsArray.push({
                append: buildLineItem('Cash:',
                    this.currencyPipe.transform(summary.cashTipIn), 0, this.columns)
            });
            commandsArray.push({
                append: buildLineItem('Charge:',
                    this.currencyPipe.transform(summary.chargeTip), 0, this.columns)
            });
            commandsArray.push({
                append: buildLineItem('Gift Card:',
                    this.currencyPipe.transform(summary.giftCardTip), 0, this.columns)
            });
            commandsArray.push({
                append: buildLineItem('Adjustments:',
                    this.currencyPipe.transform(summary.tipAdjustments), 0, this.columns)
            });
            commandsArray.push({
                append: buildLineItem('Total:',
                    this.currencyPipe.transform(summary.tipTotal), 0, this.columns)
            });
            this.addSeparator(commandsArray);

            if (!!cashSession.cashDrawers && cashSession.cashDrawers.length > 0) {
                cashSession.cashDrawers.forEach(d => {
                    commandsArray.push({append: `\n${d.name}\n`});
                    this.addSeparator(commandsArray);

                    commandsArray.push({
                        append: buildLineItem('Final Count:',
                            this.currencyPipe.transform(d.endAmount), 0, this.columns)
                    });

                    if (d.ones) {
                        commandsArray.push({
                            append: buildLineItem('Ones:',
                                d.ones.toString(10), 0, this.columns)
                        });
                    }
                    if (d.fives) {
                        commandsArray.push({
                            append: buildLineItem('Fives:',
                                d.fives.toString(10), 0, this.columns)
                        });
                    }
                    if (d.tens) {
                        commandsArray.push({
                            append: buildLineItem('Tens:',
                                d.tens.toString(10), 0, this.columns)
                        });
                    }
                    if (d.twenties) {
                        commandsArray.push({
                            append: buildLineItem('Twenties:',
                                d.twenties.toString(10), 0, this.columns)
                        });
                    }
                    if (d.fifties) {
                        commandsArray.push({
                            append: buildLineItem('Fifties:',
                                d.fifties.toString(10), 0, this.columns)
                        });
                    }
                    if (d.hundreds) {
                        commandsArray.push({
                            append: buildLineItem('Hundreds:',
                                d.hundreds.toString(10), 0, this.columns)
                        });
                    }
                    if (d.pennies) {
                        commandsArray.push({
                            append: buildLineItem('Pennies:',
                                d.pennies.toString(10), 0, this.columns)
                        });
                    }
                    if (d.nickels) {
                        commandsArray.push({
                            append: buildLineItem('Nickels:',
                                d.nickels.toString(10), 0, this.columns)
                        });
                    }
                    if (d.dimes) {
                        commandsArray.push({
                            append: buildLineItem('Dimes:',
                                d.dimes.toString(10), 0, this.columns)
                        });
                    }
                    if (d.quarters) {
                        commandsArray.push({
                            append: buildLineItem('Quarters:',
                                d.quarters.toString(10), 0, this.columns)
                        });
                    }
                    this.addSeparator(commandsArray);
                });
            }

            commandsArray.push({appendAlignment: AlignmentPosition.Left});
            if (!!cashSession.notes) {
                cashSession.notes.forEach(note => {
                    commandsArray.push({
                        append: buildLineItem(note.createdBy.name,
                            this.datePipe.transform(note.createDate), 0, this.columns)
                    });
                    commandsArray.push({
                        append: note.note + '\n'
                    });
                    this.addSeparator(commandsArray);
                });
            }

            this.send(commandsArray);
        } catch (error) {
            console.log(error);
            this.loadingService.dismiss();
            this.toastService.error('Could not print summary');
        }
        this.loadingService.dismiss();
    }

    printTimeCard(timecard: Timecard, organization: Organization) {
        this.loadingService.present('Printing.');
        try {
            const commandsArray = [];
            commandsArray.push({appendAlignment: AlignmentPosition.Left});
            commandsArray.push({append: organization.name + '\n'});
            commandsArray.push({append: timecard.person.name + '\n'});
            this.addSeparator(commandsArray);
            commandsArray.push({appendAlignment: AlignmentPosition.Left});
            commandsArray.push({
                append: buildLineItem('Clock In:',
                    formatDate(timecard.clockInTime, 'M/d/yy h:mm a', 'en-US'), 0, this.columns)
            });

            if (!!timecard.clockOutTime) {
                commandsArray.push({
                    append: buildLineItem('Clock Out:',
                        formatDate(timecard.clockOutTime, 'M/d/yy h:mm a', 'en-US'), 0, this.columns)
                });

                commandsArray.push({
                    append: buildLineItem('Duration:',
                        formatToHoursAndMinutes(
                            moment.duration(+new Date(timecard.clockOutTime) - +new Date(timecard.clockInTime))), 0, this.columns)
                });

                if (!!timecard.cashTips) {
                    commandsArray.push({
                        append: buildLineItem('Cash Tips:',
                            this.currencyPipe.transform(timecard.cashTips), 0, this.columns)
                    });
                }

                if (!!timecard.payrollTips) {
                    commandsArray.push({
                        append: buildLineItem('Payroll Tips:',
                            this.currencyPipe.transform(timecard.payrollTips), 0, this.columns)
                    });
                }
            }

            this.send(commandsArray);
        } catch (error) {
            console.log(error);
            this.loadingService.dismiss();
            this.toastService.error('Could not print summary');
        }
        this.loadingService.dismiss();
    }

    printOrder(order: Order, items: OrderItem[], silent = false) {
        if (!silent) {
            this.loadingService.present('Printing order.');
        }
        try {
            const commandsArray = [];
            commandsArray.push({appendAlignment: AlignmentPosition.Center});

            if (!!order.orderMarker) {
                commandsArray.push({appendMultiple: (order.orderMarker + '\n\n'), width: 5, height: 5});
            }

            commandsArray.push({append: order.pickupLocation.name + '\n\n'});
            this.addSeparator(commandsArray);

            commandsArray.push({appendAlignment: AlignmentPosition.Left});

            items.forEach(item => {
                if (item.orderType === Availabilities.TOGO) {
                    commandsArray.push({appendMultiple: 'TOGO\n', width: 2, height: 2});
                }
                let msg = !!item.menuItem ? item.menuItem.name : 'Misc';
                if (!!item.selectedPrice && item.selectedPrice.name !== 'Default') {
                    msg += ` (${item.selectedPrice.name})`;
                }
                commandsArray.push({appendMultiple: msg + '\n', width: 2, height: 2});

                this.addItemDetails(commandsArray, item);

                commandsArray.push({append: '\n'});
            });

            this.addSeparator(commandsArray);

            commandsArray.push({appendAlignment: AlignmentPosition.Right});
            commandsArray.push({append: formattedDate(order.date) + '\n'});

            this.send(commandsArray, silent);
        } catch (error) {
            console.log(error);
            if (!silent) {
                this.loadingService.dismiss();
                this.toastService.error('Could not print order');
            }
        }
        if (!silent) {
            this.loadingService.dismiss();
        }
    }

    send(commandsArray: any[], silent = false) {
        commandsArray.push({appendCutPaper: CutPaperAction.FullCutWithFeed});

        this.starPRNT.print(this.port, Emulation.StarPRNT, commandsArray)
            .then((result) => {
                console.log(result);
                if (!silent) {
                    this.loadingService.dismiss();
                }
            })
            .catch(error => {
                console.log(error);
                this.toastService.error(error);
                this.loadingService.dismiss();
            });
    }

    addItemDetails(commandsArray: any[], item: OrderItem, additionalPadding = '') {
        if (item.removedToppingsFilter().length > 0) {
            commandsArray.push({append: `${additionalPadding}  Remove: ${item.compactRemovedToppings()}\n`});
        }
        if (item.addedToppingsFilter().length > 0) {
            commandsArray.push({append: `${additionalPadding}  Add: ${item.compactAddedToppings()}\n`});
        }
        if (!!item.selections && item.selections.length > 0) {
            item.selections.forEach(selection => {
                commandsArray.push({append: `${additionalPadding}  ${selection.menuItemSelection.menuSelection.name}\n`});
                selection.selectedItems.forEach(si => {
                    commandsArray
                        .push({append: `${additionalPadding}    ${!!si.orderItem.menuItem ? si.orderItem.menuItem.name : 'Misc'}\n`});
                    this.addItemDetails(commandsArray, si.orderItem, additionalPadding + '    ');
                });
            });
        }
    }

    addSeparator(commandsArray: any[]) {
        commandsArray.push({appendAlignment: this.starPRNT.AlignmentPosition.Center});
        commandsArray.push({append: lineSeparator(this.columns)});
    }

    setColumns(col: string) {
        localStorage.setItem('PRINTER_COLUMNS', col);
        this.columns = Number.parseInt(col, 10);
    }
}
