<ion-fab *ngIf="orders"
         [class.second-button]="!!pickupLocation && pickupLocation.status === 'ACTIVE' && order != null && order.items != null && order.items.length > 0"
         horizontal="end" vertical="center" slot="fixed" edge #ordersFab>
    <ion-fab-button [color]="!!orders && orders[0].status | orderStatusColor:true" (click)="checkSingleOrder()">
        <span class="order-label">{{orders[0].status === orderStatuses.READY ? 'Ready!' : ('Active Order' + (orders.length > 1 ? 's' : ''))}}</span>
    </ion-fab-button>
    <ion-fab-list *ngIf="orders.length > 1">
        <ion-fab-button *ngFor="let order of orders; trackBy: getId;" [color]="order.status | orderStatusColor" (click)="click(order)">
            <div class="order-info">
                {{order.status | orderStatus}}
                <span class="status-change-time">{{order.statusChangeDate | dateAgo}}</span>
            </div>
        </ion-fab-button>
    </ion-fab-list>
</ion-fab>

<ion-fab *ngIf="!!pickupLocation && pickupLocation.status === 'ACTIVE' && order != null && order.items != null && order.items.length > 0"
         vertical="center" horizontal="end" edge>
    <ion-fab-button color="success" (click)="viewOrder()">
        <ion-icon name="cart-outline"></ion-icon>
        <span>{{order.items.length}}</span>
    </ion-fab-button>
</ion-fab>
