import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {MenuTaxLabel} from '../_models/menu-tax-label';

@Injectable({
    providedIn: 'root'
})
export class MenuTaxLabelService extends BaseService<MenuTaxLabel> {
    destination = 'menu-tax-labels';
}
