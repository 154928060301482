import {MenuItem} from './menu-item';
import {SpecialtyTag} from './specialty-tag';

export class BeerMenuItem extends MenuItem {
    abv: number;
    ibu: number;
    srm: number;
    style: SpecialtyTag;

    static [Symbol.hasInstance](obj) {
        return typeof obj.abv !== 'undefined';
    }
}
