import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {BaseModalComponent} from '../base-modal.component';
import {OrganizationRating} from '../../_models/organization-rating';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrganizationRatingService} from '../../_services/organization-rating.service';
import {LoadingService} from '../../_services/loading.service';
import {creditType} from '../../_validators/credit-type-required.validator';
import {UserOrganizationCredit} from '../../_models/user-organization-credit';

@Component({
    selector: 'bb-edit-ratings',
    templateUrl: './edit-ratings.component.html',
    styleUrls: ['./edit-ratings.component.css']
})
export class EditRatingsComponent extends BaseModalComponent implements OnInit {
    @Input() rating: OrganizationRating;
    @Input() tabTotal: number;
    disableResponse = false;
    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private organizationRatingService: OrganizationRatingService,
        private loadingService: LoadingService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.disableResponse = !!this.rating.response && this.rating.response.length > 0;

        const fg = this.formBuilder.group({
            response: [this.rating.response, [Validators.required, Validators.maxLength(2000)]],
            amount: [
                (!!this.rating.userOrganizationCredit && this.rating.userOrganizationCredit.amount > 0)
                    ? this.rating.userOrganizationCredit.amount : '', [Validators.min(0)]
            ],
            type: (!!this.rating.userOrganizationCredit && !!this.rating.userOrganizationCredit.type)
                ? this.rating.userOrganizationCredit.type : null
        });

        fg.setValidators(creditType());
        this.formGroup = fg;
    }

    async submit() {
        this.loadingService.present();
        const rating = new OrganizationRating({...this.rating});
        rating.response = this.formGroup.controls.response.value;

        if (!!this.formGroup.controls.amount.value && this.formGroup.controls.amount.value > 0) {
            rating.userOrganizationCredit = new UserOrganizationCredit();
            rating.userOrganizationCredit.amount = this.formGroup.controls.amount.value;
            rating.userOrganizationCredit.type = this.formGroup.controls.type.value;
        }

        this.organizationRatingService.response(rating).subscribe(r => {
            this.loadingService.dismiss();
            this.close(r);
        });
    }
}
