import {BaseFormModel} from '../base-form-model';
import {Person} from '../person';
import {Tab} from '../tab';

export class CashAudit extends BaseFormModel<CashAudit> {
    id: number;
    createdBy: Person;
    createDate: Date;
    tabId: number;
    giftCardId: number;
    amount: number;
    received: number;
    tip: number;
    total: number;
    deposit: number;
    change: number;
    action: string;
    event: string;
    comment: string;
    transferName: string;
    applyToCashSessionId: number;

    applyToTab: Tab;
    expected: number;
    delta: number;
    discrepancy: number;
    tippedPerson: Person;
    corrects: CashAudit;

    correctsAll: boolean;
    fullPayment: boolean;
}
