export class BaseFormModel<T> {
    parentId: number;

    public constructor(init?: Partial<T>) {
        Object.assign(this, init);
        this.init();
    }

    public init(): void {
    }
}
