import {Injectable} from '@angular/core';
import {BaseObservableService} from './base-obeservable-service';
import {TabNote} from '../_models/tab-note';

@Injectable({
    providedIn: 'root'
})
export class TabNoteService extends BaseObservableService<TabNote> {
    destination = 'tab-notes';
}
