import {Injectable} from '@angular/core';
import {Order} from '../_models/order';
import {ModalController} from '@ionic/angular';
import {DisplayOrderModalComponent} from '../_components/display-order-modal/display-order-modal.component';
import {OrganizationService} from './organization.service';

@Injectable({
    providedIn: 'root'
})
export class OrderModalService {
    viewingOrder: Order;
    orderModal: any;

    constructor(
        private modalController: ModalController,
        private organizationService: OrganizationService
    ) {
    }

    async viewOrder(order) {
        if (!!this.orderModal && this.viewingOrder.id === order.id) {
            return;
        }

        if (this.orderModal && this.viewingOrder.id !== order.id) {
            await this.orderModal.dismiss();
            this.orderModal = null;
            this.viewingOrder = null;
        }

        let organization = null;
        if (!!order.status) {
            organization = await this.organizationService.get(order.orgId).toPromise();
        }

        this.orderModal = await this.modalController.create({
            component: DisplayOrderModalComponent,
            componentProps: {
                order: new Order(order),
                organization
            }
        });

        this.viewingOrder = order;
        await this.orderModal.present();
        await this.orderModal.onDidDismiss().then(() => {
            this.orderModal = null;
            this.viewingOrder = null;
        });
    }
}
