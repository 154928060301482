<modal-header title="Edit Customer Area Position"></modal-header>

<ion-content>
    <ion-grid>
        <ion-row *ngIf="!!sanitizedCustomerAreas && sanitizedCustomerAreas.length > 1">
            <ion-col size-xs="12">
                <ion-item>
                    <ion-label position="floating">Area</ion-label>
                    <ion-select [(ngModel)]="currentArea" interface="popover">
                        <ion-select-option *ngFor="let a of sanitizedCustomerAreas" [value]="a">{{a.name}}</ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!!currentArea">
            <ion-col size-xs="12">
                <bb-select-buttons [options]="currentArea.positions"
                                   [selected]="currentPosition"
                                   (optionSelected)="onSubmit($event)">
                    <ng-template let-option #displayTemplate>
                        {{ option.formattedValue() }}
                    </ng-template>
                </bb-select-buttons>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
