<ion-row [formGroup]="formGroup">
    <ion-col size-xs="12">
        <ion-item>
            <ion-label position="floating">Address</ion-label>
            <ion-input type="text" name="addr1" formControlName="addr1" #firstInput></ion-input>
        </ion-item>
    </ion-col>
    <ion-col *ngIf="!singleLine" size-xs="12">
        <ion-item>
            <ion-label position="floating">Address</ion-label>
            <ion-input type="text" name="addr2" formControlName="addr2"></ion-input>
        </ion-item>
    </ion-col>
    <ion-col *ngIf="!singleLine" size-xs="12">
        <ion-item>
            <ion-label position="floating">Address</ion-label>
            <ion-input type="text" name="addr3" formControlName="addr3"></ion-input>
        </ion-item>
    </ion-col>
    <ion-col size-xs="12" size-md="6">
        <ion-item>
            <ion-label position="floating">City</ion-label>
            <ion-input type="text" name="city" formControlName="city"></ion-input>
        </ion-item>
    </ion-col>
    <ion-col size-xs="12" size-md="6">
        <ion-item>
            <ion-label position="floating">State</ion-label>
            <ion-select name="state" formControlName="state2" interface="popover" (ionChange)="updateState($event)">
                <ion-select-option value="AL">Alabama</ion-select-option>
                <ion-select-option value="AK">Alaska</ion-select-option>
                <ion-select-option value="AZ">Arizona</ion-select-option>
                <ion-select-option value="AR">Arkansas</ion-select-option>
                <ion-select-option value="CA">California</ion-select-option>
                <ion-select-option value="CO">Colorado</ion-select-option>
                <ion-select-option value="CT">Connecticut</ion-select-option>
                <ion-select-option value="DE">Delaware</ion-select-option>
                <ion-select-option value="DC">District Of Columbia</ion-select-option>
                <ion-select-option value="FL">Florida</ion-select-option>
                <ion-select-option value="GA">Georgia</ion-select-option>
                <ion-select-option value="HI">Hawaii</ion-select-option>
                <ion-select-option value="ID">Idaho</ion-select-option>
                <ion-select-option value="IL">Illinois</ion-select-option>
                <ion-select-option value="IN">Indiana</ion-select-option>
                <ion-select-option value="IA">Iowa</ion-select-option>
                <ion-select-option value="KS">Kansas</ion-select-option>
                <ion-select-option value="KY">Kentucky</ion-select-option>
                <ion-select-option value="LA">Louisiana</ion-select-option>
                <ion-select-option value="ME">Maine</ion-select-option>
                <ion-select-option value="MD">Maryland</ion-select-option>
                <ion-select-option value="MA">Massachusetts</ion-select-option>
                <ion-select-option value="MI">Michigan</ion-select-option>
                <ion-select-option value="MN">Minnesota</ion-select-option>
                <ion-select-option value="MS">Mississippi</ion-select-option>
                <ion-select-option value="MO">Missouri</ion-select-option>
                <ion-select-option value="MT">Montana</ion-select-option>
                <ion-select-option value="NE">Nebraska</ion-select-option>
                <ion-select-option value="NV">Nevada</ion-select-option>
                <ion-select-option value="NH">New Hampshire</ion-select-option>
                <ion-select-option value="NJ">New Jersey</ion-select-option>
                <ion-select-option value="NM">New Mexico</ion-select-option>
                <ion-select-option value="NY">New York</ion-select-option>
                <ion-select-option value="NC">North Carolina</ion-select-option>
                <ion-select-option value="ND">North Dakota</ion-select-option>
                <ion-select-option value="OH">Ohio</ion-select-option>
                <ion-select-option value="OK">Oklahoma</ion-select-option>
                <ion-select-option value="OR">Oregon</ion-select-option>
                <ion-select-option value="PA">Pennsylvania</ion-select-option>
                <ion-select-option value="RI">Rhode Island</ion-select-option>
                <ion-select-option value="SC">South Carolina</ion-select-option>
                <ion-select-option value="SD">South Dakota</ion-select-option>
                <ion-select-option value="TN">Tennessee</ion-select-option>
                <ion-select-option value="TX">Texas</ion-select-option>
                <ion-select-option value="UT">Utah</ion-select-option>
                <ion-select-option value="VT">Vermont</ion-select-option>
                <ion-select-option value="VA">Virginia</ion-select-option>
                <ion-select-option value="WA">Washington</ion-select-option>
                <ion-select-option value="WV">West Virginia</ion-select-option>
                <ion-select-option value="WI">Wisconsin</ion-select-option>
                <ion-select-option value="WY">Wyoming</ion-select-option>
            </ion-select>

            <select name="state" [disabled]="true" [hidden]="true" formControlName="state" (change)="updateState2($event)">
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
            </select>
        </ion-item>
    </ion-col>
    <ion-col size-xs="12" size-md="6">
        <ion-item>
            <ion-label position="floating">Zip Code</ion-label>
            <ion-input type="text" name="postalCode" formControlName="postalCode" inputmode="numeric" bbNumberMask maxlength="5"
                       minlength="5"
                       autocomplete="off"
                       #postalCode></ion-input>
        </ion-item>
    </ion-col>
</ion-row>
