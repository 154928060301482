import {BaseFormModel} from './base-form-model';
import {Person} from './person';
import {KickedKegSales} from './kicked-keg-sales';

export class KickedKeg extends BaseFormModel<KickedKeg> {
    id: number;
    orgId: number;
    startedBy: Person;
    startTime: Date;
    kickedBy: Person;
    endTime: Date;
    loss: number;
    count: number;
    ouncesSold: number;
    gross: number;
    net: number;
    comps: number;
    productionCost: number;
    estimatedFillGross: number;
    fillOunces: number;
    fillQuantity: number;
    estimatedTotalNet: number;
    sales: KickedKegSales[];
}
