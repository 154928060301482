import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {MenuSelectionItemService} from '../_services/menu-selection-item.service';

@Injectable()
export class MenuSelectionItemResolver implements Resolve<any> {
    constructor(private menuSelectionItemService: MenuSelectionItemService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.menuSelectionItemService.get(route.paramMap.get('id'));
    }
}
