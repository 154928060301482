import {BaseFormModel} from './base-form-model';
import {User} from './user';
import {CompDefinition} from './comp-definition';

export class TabCredit extends BaseFormModel<TabCredit> {
    id: number;
    createdBy: User;
    date: Date;
    amount: number;
    comment: string;
    type: string;
    deleted: boolean;
    compDefinition: CompDefinition;

    addIndex: number;
}
