import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthenticationService} from '../_services/authentication.service';
import {ToastService} from '../_services/toast.service';

import {Constants} from '../_constants/constants';
import {LoadingService} from '../_services/loading.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private toastService: ToastService,
        private loadingService: LoadingService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            let skipError = false;
            const silent = !!request.headers.get(Constants.SILENT);
            const skipCodes = request.headers.get(Constants.IGNORE_ERROR_CODES);

            if (skipCodes != null && skipCodes.length > 0) {
                const codes: string[] = skipCodes.split(',');
                if (codes.includes(err.status.toString())) {
                    skipError = true;
                }
            }

            this.loadingService.dismiss();

            if (!silent || skipError) {
                if (err.status === 403) {
                    this.toastService.error(Constants.NOT_AUTHORIZED_ERROR);
                    return throwError(err);
                } else if (!skipError) {
                    if (err.status === 401) {
                        // auto logout if 401 response returned from api
                        this.authenticationService.logout();
                        location.reload();
                    } else if (!!err.error && !!err.error.message && err.error.message.indexOf('Read timed out') > -1) {
                        this.toastService.error(Constants.TIME_OUT_ERROR);
                    } else {
                        this.loadingService.dismiss();
                        this.toastService.error(Constants.SERVER_ERROR);
                    }
                    const error = err.error.message || err.statusText;
                    return throwError(error);
                } else {
                    return throwError(err);
                }
            }
        }));
    }
}
