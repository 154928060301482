<circle-progress slot="start"
                 class="keg-percent"
                 [backgroundColor]="backgroundColor"
                 [percent]="percent"
                 [radius]="radius"
                 [outerStrokeWidth]="3"
                 [outerStrokeColor]="color"
                 [animation]="true"
                 [showTitle]="false"
                 [showUnits]="false"
                 [startFromZero]="false"
                 [showInnerStroke]="false"
                 [showSubtitle]="false"
                 [renderOnClick]="false"
                 [animationDuration]="100"
></circle-progress>
