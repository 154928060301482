import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {OrganizationMarketingEmail} from '../_models/organization-marketing-email';

@Injectable({
    providedIn: 'root'
})
export class OrganizationMarketingEmailService extends BaseService<OrganizationMarketingEmail> {
    destination = 'organization-marketing-emails';
}
