import {BaseFormModel} from './base-form-model';
import {MenuItemTopping} from './menu-item-topping';

export class OrderItemTopping extends BaseFormModel<OrderItemTopping> {
    id: number;
    included: boolean;
    name: string;
    menuItemTopping: MenuItemTopping;

    init(): void {
        this.menuItemTopping = new MenuItemTopping(this.menuItemTopping);
    }
}
