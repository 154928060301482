import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {
    ActiveOrderGuard,
    AvailableCreditsResolver,
    CustomerAreaPositionResolver,
    LoggedInUserResolver,
    MembershipResolver,
    MenuItemRatingResolver,
    MenuItemResolver,
    MenuItemSelectionResolver,
    MenuSelectionItemResolver,
    NotificationsResolver,
    OrganizationMembershipProgramsResolver,
    OrganizationResolver,
    OrganizationRewardCardsResolver,
    PaymentMethodsResolver,
    PickupLocationMenuResolver,
    PickupLocationResolver,
    RatingResponsesResolver,
    RoleGuard,
    TabHistoryResolver,
    TabResolver,
    UserMembershipSummariesResolver,
    VenueResolver
} from 'brewbill-lib';

const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {
        path: 'login',
        loadChildren: () => import('./_pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'account',
        loadChildren: () => import('./_pages/account/account.module').then(m => m.AccountPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./_pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./_pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'menu/:pickupLocationId',
        loadChildren: () => import('./_pages/menu/menu.module').then(m => m.MenuPageModule),
        canDeactivate: [ActiveOrderGuard],
        canActivate: [RoleGuard]
    },
    {
        path: 'menu/:pickupLocationId/:customerAreaPositionId',
        loadChildren: () => import('./_pages/menu/menu.module').then(m => m.MenuPageModule),
        canActivate: [RoleGuard],
        canDeactivate: [ActiveOrderGuard],
        resolve: {
            customerAreaPosition: CustomerAreaPositionResolver
        }
    },
    {
        path: 'tab-history',
        loadChildren: () => import('./_pages/tab-history/tab-history.module').then(m => m.TabHistoryPageModule),
        canActivate: [RoleGuard],
        resolve: {
            tabs: TabHistoryResolver
        },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'payment-methods/:userId',
        loadChildren: () => import('./_pages/payment-methods/payment-methods.module').then(m => m.PaymentMethodsPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'display-user-credits',
        loadChildren: () => import('./_pages/display-user-credits/display-user-credits.module').then(m => m.DisplayUserCreditsPageModule),
        canActivate: [RoleGuard],
        resolve: {
            credits: AvailableCreditsResolver
        }
    },
    {
        path: 'rating-responses',
        loadChildren: () => import('./_pages/rating-responses/rating-responses.module').then(m => m.RatingResponsesPageModule),
        canActivate: [RoleGuard],
        resolve: {
            ratings: RatingResponsesResolver
        }
    },
    {
        path: 'confirm-email',
        loadChildren: () => import('./_pages/confirm-email/confirm-email.module').then(m => m.ConfirmEmailPageModule)
    },
    {
        path: 'notifications',
        loadChildren: () => import('./_pages/notifications/notifications.module').then(m => m.NotificationsPageModule),
        canActivate: [RoleGuard],
        resolve: {
            notifications: NotificationsResolver
        }
    },
    {
        path: 'marketing/:orgId',
        loadChildren: () => import('./_pages/marketing/marketing.module').then(m => m.MarketingPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'rewards',
        loadChildren: () => import('./_pages/rewards/rewards.module').then(m => m.RewardsPageModule),
        canActivate: [RoleGuard],
        resolve: {
            rewards: OrganizationRewardCardsResolver
        }
    },
    {
        path: 'venue/:venueId',
        loadChildren: () => import('./_pages/venue/venue.module').then(m => m.VenuePageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'confirm-tab/:pickupLocationId/:tabId',
        loadChildren: () => import('./_pages/confirm-tab/confirm-tab.module').then(m => m.ConfirmTabPageModule),
        canDeactivate: [ActiveOrderGuard],
        canActivate: [RoleGuard]
    },
    {
        path: 'confirm-order/:pickupLocationId',
        loadChildren: () => import('./_pages/confirm-order/confirm-order.module').then(m => m.ConfirmOrderPageModule),
        canDeactivate: [ActiveOrderGuard],
        canActivate: [RoleGuard]
    },
    {
        path: 'select-payment/:pickupLocationId/:tabId',
        loadChildren: () => import('./_pages/select-payment/select-payment.module').then(m => m.SelectPaymentPageModule),
        canDeactivate: [ActiveOrderGuard],
        canActivate: [RoleGuard]
    },
    {
        path: 'membership-program/select-payment/:pickupLocationId/:membershipProgramId',
        loadChildren: () => import('./_pages/select-payment/select-payment.module').then(m => m.SelectPaymentPageModule),
        canDeactivate: [ActiveOrderGuard],
        canActivate: [RoleGuard]
    },
    {
        path: 'thank-you/:pickupLocationId/:tabId',
        loadChildren: () => import('./_pages/thank-you/thank-you.module').then(m => m.ThankYouPageModule),
        canActivate: [RoleGuard],
        resolve: {
            tab: TabResolver,
            pickupLocation: PickupLocationResolver
        }
    },
    {
        path: 'tab/:tabId',
        loadChildren: () => import('./_pages/display-tab/display-tab.module').then(m => m.DisplayTabPageModule)
    },
    {
        path: 'claim-tab/:tabId/:claimCode',
        loadChildren: () => import('./_pages/claim-tab/claim-tab.module').then(m => m.ClaimTabPageModule)
    },
    {
        path: 'claim-tab/:tabId',
        loadChildren: () => import('./_pages/claim-tab/claim-tab.module').then(m => m.ClaimTabPageModule)
    },
    {
        path: 'pickup-location-search',
        loadChildren: () => import('./_pages/pickup-location-search/pickup-location-search.module')
            .then(m => m.PickupLocationSearchPageModule)
    },
    {
        path: 'membership-signup/:orgId/:pickupLocationId',
        loadChildren: () => import('./_pages/membership-signup/membership-signup.module').then(m => m.MembershipSignupPageModule),
        canActivate: [RoleGuard],
        resolve: {
            membershipPrograms: OrganizationMembershipProgramsResolver
        }
    },
    {
        path: ':orgId/claim-membership/:memberId',
        loadChildren: () => import('./_pages/claim-membership/claim-membership.module').then(m => m.ClaimMembershipPageModule),
        canActivate: [RoleGuard],
        resolve: {
            member: MembershipResolver
        }
    },
    {
        path: 'memberships',
        loadChildren: () => import('./_pages/memberships/memberships.module').then(m => m.MembershipsPageModule),
        canActivate: [RoleGuard],
        resolve: {
            membershipSummaries: UserMembershipSummariesResolver
        }
    },
    {
        path: ':orgId/membership/:memberId',
        loadChildren: () => import('./_pages/member/member.module').then(m => m.MemberPageModule),
        canActivate: [RoleGuard],
        resolve: {
            member: MembershipResolver
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy'})
    ],
    exports: [RouterModule],
    providers: [
        AvailableCreditsResolver,
        CustomerAreaPositionResolver,
        LoggedInUserResolver,
        MembershipResolver,
        MenuItemRatingResolver,
        MenuItemResolver,
        MenuItemSelectionResolver,
        MenuSelectionItemResolver,
        NotificationsResolver,
        OrganizationResolver,
        OrganizationMembershipProgramsResolver,
        OrganizationRewardCardsResolver,
        PaymentMethodsResolver,
        PickupLocationMenuResolver,
        PickupLocationResolver,
        RatingResponsesResolver,
        TabHistoryResolver,
        TabResolver,
        UserMembershipSummariesResolver,
        VenueResolver
    ]
})
export class AppRoutingModule {
}
