import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot} from '@angular/router';
import {OrderService} from '../_services/order.service';
import {BaseMenuPageComponent} from '../_components/base-menu-page.component';
import {OrderStatuses} from '../_constants/order-statuses';
import {AlertController} from '@ionic/angular';
import {LoadingService} from '../_services/loading.service';

@Injectable({providedIn: 'root'})
export class ActiveOrderGuard implements CanDeactivate<any> {
    activeOrderRoutes = ['confirm-order', 'confirm-tab', 'select-payment', 'menu', 'thank-you'];

    constructor(
        private router: Router,
        private alertController: AlertController,
        private orderService: OrderService,
        private loadingService: LoadingService
    ) {
    }

    canDeactivate(
        component: BaseMenuPageComponent,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Promise<boolean> | boolean {
        const order = this.orderService.currentValue;
        let path = nextState.url.startsWith('/') ? nextState.url.substring(1) : nextState.url;
        const index = path.indexOf('/');
        if (index > 0) {
            path = path.substring(0, index);
        }

        if (!!order && order.status === OrderStatuses.PENDING && !this.activeOrderRoutes.includes(path)) {
            return this.confirmDeactivate();
        } else {
            return true;
        }
    }

    private async confirmDeactivate(): Promise<boolean> {
        this.loadingService.dismiss();
        return new Promise((resolve) => {
            this.alertController
                .create({
                    cssClass: 'brewbill-alert',
                    header: 'Pending Order',
                    message: `You have a pending order that has not been submitted. Would you still like to leave this page?`,
                    buttons: [
                        {
                            text: 'No',
                            handler: () => resolve(false)
                        },
                        {
                            text: 'Yes',
                            handler: () => resolve(true)
                        }
                    ]
                })
                .then(alert => {
                    this.loadingService.dismiss();
                    alert.present();
                });
        });
    }

}
