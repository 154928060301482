import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

import {TOKEN_KEY} from '../_services/authentication.service';
import {User} from '../_models/user';
import {UserService} from '../_services/user.service';
import {DEVICE_ID} from '../_utils/local-storage-utils';
import {OrganizationService} from '../_services/organization.service';
import {timeout} from 'rxjs/operators';
import {LibConfig, LibConfigService} from '../lib.config';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    currentUser: User;
    appVersion: string;

    constructor(
        @Inject(LibConfigService) private config: LibConfig,
        private userService: UserService,
        private organizationService: OrganizationService
    ) {
        this.userService.current.subscribe(u => this.currentUser = u);
        this.appVersion = config.appVersion;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let token = localStorage.getItem(TOKEN_KEY);

        if (token === null && this.currentUser != null) {
            token = this.currentUser.token;
        }

        if (token === null) {
            token = this.organizationService.getOrganizationToken();
        }

        if (token != null) {
            if (token.startsWith('Bearer')) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `${token}`
                    }
                });
            }
        }

        const deviceId = localStorage.getItem(DEVICE_ID);
        if (!!deviceId) {
            request = request.clone({
                setHeaders: {
                    DEVICE_ID: deviceId
                }
            });
        }

        const origin = window.location.origin;
        if (!!origin) {
            request = request.clone({
                setHeaders: {
                    ORIGIN_SERVER: origin
                }
            });
        }

        if (!!this.appVersion) {
            request = request.clone({
                setHeaders: {
                    APP_VERSION: this.appVersion
                }
            });
        }

        const timeoutValue = request.headers.get('timeout');
        if (!!timeoutValue) {
            return next.handle(request).pipe(timeout(Number(timeoutValue)));
        } else {
            return next.handle(request);
        }
    }
}
