import {Inject, Injectable} from '@angular/core';
import {LibConfig, LibConfigService} from '../lib.config';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class QuickbooksService {
    destination = 'quickbooks';

    constructor(
        @Inject(LibConfigService) private config: LibConfig,
        private http: HttpClient
    ) {
    }

    baseUrl() {
        return this.config.apiUrl + '/' + this.destination;
    }

    getConnectionAuthUrl(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/connection-auth-url`, {responseType: 'text'});
    }

    revoke(organizationId: number) {
        return this.http.post(`${this.baseUrl()}/organization/${organizationId}/revoke-connection`, {});
    }

    connect(code: string, realmId: string, state: string) {
        return this.http.get(`${this.baseUrl()}/oauth2redirect?code=${code}&realmId=${realmId}&state=${state}`);
    }

    accounts(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/accounts`);
    }

    customers(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/customers`);
    }

    categories(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/categories`);
    }

    items(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/items`);
    }

    createDailySalesReceipt(organizationId: number, date: string) {
        return this.http.post(`${this.baseUrl()}/organization/${organizationId}/create-daily-sales-receipt?date=${date.replace('+', '%2b')}`, {});
    }

    createDailySalesReceiptJob(organizationId: number) {
        return this.http.post(`${this.baseUrl()}/organization/${organizationId}/create-daily-sales-receipt-job`, {});
    }
}
