import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseForm} from '../base-form';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {match} from '../../../_validators/match.validator';
import {User} from '../../../_models/user';

@Component({
    selector: 'bb-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent extends BaseForm implements OnInit {
    @Input() user: User;
    @Input() emailOnly = false;
    @Input() email: string;

    @Output() validPassword = new EventEmitter();

    constructor(
        private formBuilder: FormBuilder
    ) {
        super();
    }

    setupFormGroup(): FormGroup {
        if (this.user != null) {
            this.email = this.user.login.email;
        }
        const fg: FormGroup = this.formBuilder.group({});

        if (!this.emailOnly) {
            if (this.user != null) {
                fg.addControl('oldPassword', new FormControl('', Validators.required));
            }
            fg.addControl('password', new FormControl('', [
                Validators.required,
                Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]|[$@$!%*?&]).{8,32}')
            ]));
            fg.addControl('confirmPassword', new FormControl('', Validators.required));
            fg.setValidators(match('password', 'confirmPassword'));
        }

        fg.controls.confirmPassword.statusChanges.subscribe(s => {
            if (this.formGroup.valid && this.formGroup.controls.password.value === this.formGroup.controls.confirmPassword.value) {
                this.validPassword.emit();
            }
        });

        return fg;
    }
}
