<modal-header title="Login"></modal-header>

<ion-content>
    <ion-grid>
        <ion-row class="ion-justify-content-center">

            <ion-col class="ion-align-self-center" size-xs="12">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="padding">
                        <ion-item>
                            <ion-label position="floating">Email</ion-label>
                            <ion-input type="text" formControlName="email"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-label position="floating">Password</ion-label>
                            <ion-input type="password" formControlName="password"></ion-input>
                        </ion-item>
                    </div>

                    <div class="padding">
                        <ion-grid>
                            <ion-col size-xs="12" class="link-col"><a (click)="openForgotPassword()">Forgot Password</a></ion-col>
                        </ion-grid>
                    </div>
                </form>
            </ion-col>
        </ion-row>
    </ion-grid>

</ion-content>

<modal-footer [disableSubmit]="loginForm.invalid" submitLabel="Login" (submitClicked)="onSubmit()"></modal-footer>
