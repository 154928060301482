import {AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Constants} from '../../_constants/constants';
import {UIChart} from 'primeng/chart';
import {Organization} from '../../_models/organization';
import {OrganizationRating} from '../../_models/organization-rating';
import {OrganizationRatingService} from '../../_services/organization-rating.service';
import {DatePipe} from '@angular/common';
import {LoadingService} from '../../_services/loading.service';

@Component({
    selector: 'bb-organization-ratings',
    templateUrl: './organization-ratings.component.html',
    styleUrls: ['./organization-ratings.component.css']
})
export class OrganizationRatingsComponent implements OnInit, AfterViewChecked {
    @Input() organization: Organization;
    @Output() closeClicked: EventEmitter<any> = new EventEmitter();
    @Output() ratingSelected: EventEmitter<any> = new EventEmitter();
    @ViewChild('donutChart') chart: UIChart;
    readFilter = 'all';
    responseFilter = 'all';
    ratingsLoading = false;

    ratings: OrganizationRating[];
    total: number;
    totalPages: number;
    currentPage = 0;

    selectedRatings = ['one', 'two', 'three', 'four', 'five'];

    summaryChart;
    summaryChartOptions;

    monthlyChart;
    monthlyChartOptions;

    constructor(
        private organizationRatingService: OrganizationRatingService,
        private datePipe: DatePipe,
        private loadingService: LoadingService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    async ngOnInit() {
        await this.loadPageData();
    }

    async ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
    }

    async loadPageData(resetPage = false) {
        await this.loadSummaryChart(resetPage);
        await this.loadMonthlyChart(resetPage);
        await this.loadRatings(resetPage);
    }

    async loadRatings(resetPage = false) {
        if (resetPage) {
            this.currentPage = 0;
        }

        this.ratingsLoading = true;
        await this.organizationRatingService.find(this.organization.id, this.currentPage,
            20, this.selectedRatings, this.readFilter, this.responseFilter).subscribe((page: any) => {
            const converted = [];
            page.content.forEach(item => {
                const rating = this.organizationRatingService.convert(item);

                if (this.currentPage === 0) {
                    converted.push(rating);
                } else {
                    this.ratings.push(rating);
                }
            });

            if (this.currentPage === 0) {
                this.ratings = converted;
            }

            this.total = page.totalElements;
            this.totalPages = page.totalPages;
            this.ratingsLoading = false;
        });
    }

    async loadSummaryChart(resetPage = false) {
        if (resetPage) {
            this.currentPage = 0;
        }

        this.organizationRatingService.getOrganizationSummary(this.organization.id, this.currentPage,
            20, this.selectedRatings, this.readFilter, this.responseFilter).subscribe((page: any) => {

            let labels = [];
            page.content.forEach(items => {
                labels = this.sortNumberLabels(items);
            });

            const sumChart = {
                labels,
                datasets: [
                    {
                        ...Constants.CHART_CONFIG,
                        backgroundColor: [
                            '#0cd1e8',
                            '#7044ff',
                            '#ffce00',
                            '#E91E63',
                            '#10dc60'
                        ],
                        data: []
                    }
                ]
            };

            page.content.forEach(items => {
                Object.keys(items).forEach(k => {
                    if (k !== 'total' && items[k] > 0) {
                        sumChart.datasets[0].data.push(items[k]);
                    }
                });
            });

            this.summaryChart = sumChart;
        });
    }

    nextLabel(date: Date) {
        date.setMonth(date.getMonth() + 1);
        return this.datePipe.transform(date, 'MMM');
    }

    async loadMonthlyChart(resetPage = false) {
        if (resetPage) {
            this.currentPage = 0;
        }

        this.monthlyChartOptions = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ]
            }
        };

        this.organizationRatingService.monthlyAverage(this.organization.id, this.currentPage,
            20, this.selectedRatings, this.readFilter, this.responseFilter).subscribe((page: any) => {

            const labels = [];
            const date = new Date();
            date.setDate(1);
            date.setMonth(date.getMonth() - 12);

            for (let i = 0; i < 12; i++) {
                labels.push(this.nextLabel(date));
            }

            const monthly = {
                labels,
                datasets: [
                    {
                        label: 'Average',
                        backgroundColor: '#10dc60',
                        data: []
                    }
                ]
            };

            const results = page.content;

            labels.forEach(label => {
                const month = results.find(m => m.label === label);
                monthly.datasets[0].data.push(!!month ? month.value : 0);
            });

            this.monthlyChart = monthly;
        });
    }

    sortNumberLabels(results) {
        let filteredResults = Object.keys(results)
            .filter((k) => results[k] > 0)
            .reduce((a, k) => ({...a, [k]: results[k]}), {});

        const weights = {
            one: 1,
            two: 2,
            three: 3,
            four: 4,
            five: 5
        };
        return Object.keys(filteredResults).sort((a, b) => weights[a] - weights[b]);
    }

    getTotal() {
        return !!this.chart
        && !!this.chart.chart
        && this.chart.chart.getVisibleDatasetCount() > 0
            ? this.chart.chart.getDatasetMeta(0).total : 0;
    }

    async clicked(event) {
        let changed = false;
        let index;
        this.chart.chart.legend.legendItems.forEach(item => {
            index = this.selectedRatings.indexOf(item.text);
            if (item.hidden && index > -1) {
                this.selectedRatings.splice(index, 1);
                changed = true;
            } else if (!item.hidden && index === -1) {
                this.selectedRatings.push(item.text);
                changed = true;
            }
        });

        if (changed) {
            await this.loadRatings(true);
        }
    }

    close() {
        this.closeClicked.emit(null);
    }

    ratingClicked(rating) {
        this.ratingSelected.emit(rating);
    }

    async markAllRead() {
        this.loadingService.present();
        this.organizationRatingService.markAllReadOrganization(this.organization.id).subscribe(async () => {
            await this.loadRatings();
            this.loadingService.dismiss();
        });
    }

    async doInfinite() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            await this.loadRatings();
        }
    }
}
