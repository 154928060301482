<bb-card-loading *ngIf="loading"></bb-card-loading>
<h3 *ngIf="error">
    <ion-text color="danger">An error occurred. Please contact support.</ion-text>
</h3>
<div *ngIf="!loading && !error" class="report-container" [style.min-width]="minWidth + 'px'">
    <ion-item class="header-row">
        <ion-grid class="row-data">
            <ion-row *ngIf="includeCount && !!tabReportResult">
                <ion-col class="ion-text-end count-container">
                    <span class="count-label">
                        Count: {{tabReportResult.totalElements}}
                    </span>`
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col *ngFor="let column of reportColumns; trackBy: getKey"
                         class="column-header"
                         [class.ion-text-end]="isNumberFormat(column.format)"
                         (click)="changeSort(column)">
                    <span class="column-header-text">{{column.label}}</span>
                    <ion-icon *ngIf="!!sort && sort.column.accessKey === column.accessKey"
                              [name]="!!sort && sort.sortDirection === 'ASC' ? 'arrow-up' : 'arrow-down'"
                              class="column-header-sort"></ion-icon>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-item>
    <ion-list *ngIf="!!tabReportResult"
              class="report-contents"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="onScroll()"
              [scrollWindow]="false">
        <ion-item *ngFor="let row of tabReportResult.data; trackBy: getId"
                  [class.pointer]="(tabReportResult.rowKey === 'TAB_ID' || tabReportResult.rowKey === 'ORDER_ID' || tabReportResult.rowKey === 'ORDER_ITEM_ID') && row.id > 0"
                  (click)="viewTab(row)">
            <ion-label *ngIf="!!rowLabelColumns && rowLabelColumns.length > 0" position="stacked">
                <ng-container *ngFor="let labelColumn of rowLabelColumns; let last = last;">
                    <span class="row-label">
                        {{ getRowValue(row, labelColumn) }}
                    </span>
                    <ion-icon *ngIf="!last" name="chevron-forward-sharp" class="row-label-divider"></ion-icon>
                </ng-container>
            </ion-label>
            <ion-grid *ngIf="!!reportColumns" class="row-data">
                <ion-row>
                    <ion-col *ngFor="let column of reportColumns; let index = index; trackBy: getKey"
                             [class.ion-text-end]="isNumberFormat(column.format)">
                        <span *ngIf="column.format !== columnFormats.BOOLEAN" class="pointer"
                              [cdkCopyToClipboard]="getRowValue(row, column)"
                              (cdkCopyToClipboardCopied)="onClipboardCopy($event, getRowValue(row, column))">{{ getRowValue(row, column) }}</span>
                        <ion-icon *ngIf="column.format === columnFormats.BOOLEAN && row.data[column.accessKey] === true"
                                  color="success"
                                  name="checkmark-outline">
                        </ion-icon>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>

        <ion-item *ngIf="!!tabReportResult.summaryRow" class="summary-row">
            <ion-grid class="row-data">
                <ion-row>
                    <ion-col *ngFor="let column of reportColumns; let index = index; trackBy: getKey"
                             [class.ion-text-end]="isNumberFormat(column.format)">
                        <span *ngIf="!!column.summaryAggregation" class="summary-label">{{getAggregateSummaryLabel(column)}}</span>
                        <span *ngIf="!!column.summaryAggregation" class="summary-value pointer"
                              [cdkCopyToClipboard]="getRowValue(tabReportResult.summaryRow, column)"
                              (cdkCopyToClipboardCopied)="onClipboardCopy($event, getRowValue(tabReportResult.summaryRow, column))">{{getRowValue(tabReportResult.summaryRow, column)}}</span>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>
    </ion-list>
</div>
