import {BaseFormModel} from './base-form-model';

export class KickedKegsSummary extends BaseFormModel<KickedKegsSummary> {
    menuItemId: number;
    orgId: number;
    averageLoss: number;
    totalLoss: number;
    averageCount: number;
    totalCount: number;
    averageOuncesSold: number;
    totalOuncesSold: number;
    averageGross: number;
    totalGross: number;
    averageComps: number;
    totalComps: number;
    averageNet: number;
    totalNet: number;
    averageEstimatedFillGross: number;
    totalEstimatedFillGross: number;
    averageFillOunces: number;
    totalFillOunces: number;
    averageFillQuantity: number;
    totalFillQuantity: number;
    averageEstimatedTotalNet: number;
    totalEstimatedTotalNet: number;
    averageOuncesPerSale: number;
    averageDuration: number;
}
