import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {VenueService} from '../_services/venue.service';

@Injectable()
export class VenueResolver implements Resolve<any> {
    constructor(private venueService: VenueService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.venueService.get(route.paramMap.get('venueId'));
    }
}
