import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {TabService} from '../_services/tab.service';
import {OrderService} from '../_services/order.service';
import {OrderItemService} from '../_services/order-item.service';

@Injectable()
export class TabResolver implements Resolve<any> {
    constructor(
        private tabService: TabService,
        private orderService: OrderService,
        private orderItemService: OrderItemService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        let tabId = null;
        if (!!route.queryParams && !!route.queryParams.tabId) {
            tabId = Number.parseInt(route.queryParams.tabId, 10);
        } else if (route.paramMap.get('tabId')) {
            tabId = Number.parseInt(route.paramMap.get('tabId'), 10);
        }

        if (!!tabId && !isNaN(tabId)) {
            const tab = this.tabService.fromCache(tabId);
            if (!!tab) {
                tab.fromCache = true;
                return Promise.resolve(tab);
            }
            return this.tabService.get(tabId);
        } else if (!!route.paramMap.get('orderId')) {
            return this.orderService.getTab(Number.parseInt(route.paramMap.get('orderId'), 10));
        } else if (!!route.paramMap.get('orderItemId')) {
            return this.orderItemService.getTab(Number.parseInt(route.paramMap.get('orderItemId'), 10));
        } else {
            return null;
        }
    }
}
