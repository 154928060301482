<modal-header title=""></modal-header>
<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col class="ion-padding-start">
                <h3>Would you like to receive emails about future events and specials from {{organization.name}}?</h3>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer class="ion-no-border">
    <div class="paired-button-container">
        <ion-button data-cy="no-button" color="dark" class="paired-button" (click)="close()">
            No Thanks
        </ion-button>
        <ion-button data-cy="yes-button" color="success" class="paired-button" (click)="onSubmit()">
            Yes
        </ion-button>
    </div>
</ion-footer>
