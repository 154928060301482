<ion-row>
    <ion-col size-xs="4" class="ion-float-left ion-padding">
        <ion-icon *ngIf="!pickupLocation.imageAddress" name="cloud-upload-outline" class="image-placeholder"
                  (click)="openUploadDirectionImageModal()"></ion-icon>
        <img *ngIf="!!pickupLocation.imageAddress" [src]="pickupLocation.imageAddress"
             alt=""
             [class.upload-image]="editable" (click)="openUploadDirectionImageModal()">
    </ion-col>
    <ion-col size-xs="8" class="ion-float-left ion-padding">
        <ion-grid>
            <ion-row>
                <ion-col>
                    <span class="ion-text-wrap">
                        <strong>{{ pickupLocation.name }}</strong>
                    </span>
                    <span *ngIf="pickupLocation.phone" class="phone">{{pickupLocation.phone}}</span>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col class="ion-wrap">
                    {{ pickupLocation.direction }}
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-col>
</ion-row>
