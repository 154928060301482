import {Component, OnInit} from '@angular/core';
import {SubscriberComponent} from './subscriber.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

@Component({
    template: ''
})
export abstract class BaseParentRouteComponent extends SubscriberComponent implements OnInit {
    displayTab: string;

    abstract navigate();

    protected constructor(
        public router: Router,
        public route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribe(this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                if (!!this.route.snapshot.firstChild
                    && this.route.snapshot.firstChild.url.length > 0
                    && this.displayTab !== this.route.snapshot.firstChild.url[0].path) {
                    this.displayTab = this.route.snapshot.firstChild.url[0].path;
                }
            }
        }));
    }

    checkRoute() {
        if (!!this.route.snapshot.firstChild && this.route.snapshot.firstChild.url.length > 0) {
            this.displayTab = this.route.snapshot.firstChild.url[0].path;
        }
    }

    async changeTab() {
        this.navigate();
    }
}
