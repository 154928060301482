export class Account {
    name: string;
    subAccount: string;
    description: string;
    fullyQualifiedName: string;
    accountAlias: string;
    txnLocationType: string;
    active: boolean;
    classification: string;
    accountType: string;
    accountSubType: string;
    acctNum: string;
    acctNumExtn: string;
    bankNum: string;
    openingBalanceDate: string;
    currentBalance: number;
    currentBalanceWithSubAccounts: number;
    taxAccount: boolean;
    onlineBankingEnabled: boolean;
    fiName: string;
}
