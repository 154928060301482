<ion-header mode="md">
    <ion-toolbar data-cy="header-toolbar" color="dark">
        <ion-buttons slot="start" *ngIf="!editMode">
            <ion-back-button data-cy="header-back-button"
                             *ngIf="!hideBack"
                             [defaultHref]="defaultHome"
                             slot="start"
                             (click)="backClicked($event)">
            </ion-back-button>
            <ion-menu-button data-cy="header-menu-button" *ngIf="!hideButtons && !hideMenu"></ion-menu-button>
            <div *ngIf="!!notificationSummary && (notificationSummary.unread > 0 || notificationSummary.unusedCredits > 0 || notificationSummary.unseenNotifications > 0)"
                 class="notification pointer"
                 (click)="openMenu()">
            </div>
        </ion-buttons>

        <ion-buttons slot="start" *ngIf="editMode && !hideBack">
            <ion-button (click)="editModeBack()">
                <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
            </ion-button>
        </ion-buttons>

        <ng-container #titleContent>
            <ng-content></ng-content>
        </ng-container>

        <ion-title>
            <ng-container *ngIf="!!title">{{ title }}</ng-container>
        </ion-title>

        <ion-buttons *ngIf="!hideBack && editMode" slot="end">
            <ion-button (click)="editClicked.emit()">
                <ion-icon slot="icon-only" name="create-outline"></ion-icon>
            </ion-button>
        </ion-buttons>

        <ion-buttons *ngIf="manager && !!user && !!organization" class="ion-hide-md-down" slot="end">
            <bb-clock-in-out></bb-clock-in-out>
            <ng-container *ngIf="!!activePickupLocationId">
                <ion-button *bbSecured="featureKeys.TERMINAL" data-cy="terminal-button" color="primary"
                            (click)="navigateToTerminal()">Terminal
                </ion-button>
            </ng-container>
            <ion-button *bbSecured="featureKeys.OPERATIONS_FEATURE_KEYS" data-cy="operations-button" color="primary"
                        (click)="operationsNavigation()">Operations
            </ion-button>
            <ion-button *bbSecured="featureKeys.CONFIGURATION_FEATURE_KEYS" data-cy="configure-button" color="primary"
                        routerLink="/organization/{{organization.id}}">Configuration
            </ion-button>
            <ion-button *bbSecured="featureKeys.REPORT_FEATURE_KEYS" data-cy="reports-button" color="primary"
                        routerLink="/reports/{{organization.id}}">Reports
            </ion-button>
            <ion-button data-cy="lock-button" color="primary" class="header-button" (click)="lock()" type="button">
                <ion-icon name="lock-closed-outline" slot="icon-only"></ion-icon>
            </ion-button>
            <ion-button data-cy="help-button" color="primary" (click)="openHelpDocs()">
                <ion-icon name="help-circle-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>

        <ion-buttons *ngIf="manager && !!user && !!organization" class="ion-hide-md-up" slot="end">
            <bb-clock-in-out></bb-clock-in-out>
            <ion-button color="primary" (click)="presentActionSheet()">Actions</ion-button>
            <ion-button color="primary" class="header-button" (click)="lock()" type="button">
                <ion-icon name="lock-closed-outline" slot="icon-only"></ion-icon>
            </ion-button>
            <ion-button color="primary" (click)="openHelpDocs()">
                <ion-icon name="help-circle-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ng-content select="[breadCrumbs]"></ng-content>

    <ion-accordion-group *ngIf="!manager && !hideTabs && !!openTabs && openTabs.length > 1">
        <ion-accordion value="openTabs">
            <ion-item data-cy="items-header" slot="header" color="secondary">
                <ion-label>Open Tabs ({{openTabs.length}})</ion-label>
            </ion-item>
            <ion-list slot="content">
                <ion-item class="pointer" *ngFor="let tab of openTabs" routerLink="/tab/{{tab.id}}">
                    <ion-label>
                        {{tab.organizationName}}
                        <p>{{tab.startTime | dateAgo}}</p>
                    </ion-label>
                    <h1 slot="end">{{tab.paymentInformation.remaining | currency}}</h1>
                </ion-item>
            </ion-list>
        </ion-accordion>
    </ion-accordion-group>

    <bb-active-orders *ngIf="includeOrder"></bb-active-orders>

    <ng-content select="[tabNavigation]"></ng-content>

    <ng-content select="[subHeader]"></ng-content>
</ion-header>

<ion-item class="pointer" color="primary" *ngIf="!manager && !!openTabs && openTabs.length === 1" routerLink="/tab/{{openTabs[0].id}}">
    <ion-label>
        {{openTabs[0].organizationName}}
        <p>{{openTabs[0].startTime | dateAgo}}</p>
    </ion-label>
    <h1 slot="end">{{openTabs[0].paymentInformation.remaining | currency}}</h1>
</ion-item>
