import {BaseFormModel} from './base-form-model';
import {Person} from './person';
import {CashAudit} from './cash-information/cash-audit';
import {CardSummary} from './card-summary';
import {PaymentCharge} from './payment-charge';

export class GiftCardAction extends BaseFormModel<GiftCardAction> {
    id: number;
    orgId: number;
    createdBy: Person;
    createDate: Date;
    deletedBy: Person;
    deleteDate: Date;
    cardLastFour: string;
    balance: number;
    deviceId: string;
    amount: number;
    tip: number;
    total: number;
    event: string;
    tabNumber: string;
    tabId: number;
    credited: boolean;
    refunded: boolean;
    cashAudit: CashAudit;
    paymentCharge: PaymentCharge;
    cardSummary: CardSummary;
    terminalId: string;
    referenceId: string;
}
