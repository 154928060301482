import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {BaseModalComponent} from '../base-modal.component';

@Component({
    selector: 'bb-select-menu-item-type',
    templateUrl: './select-menu-item-type.component.html',
    styleUrls: ['./select-menu-item-type.component.css'],
})
export class SelectMenuItemTypeComponent extends BaseModalComponent {

    constructor(modalController: ModalController) {
        super(modalController);
    }

    async select(type: string) {
        await this.close(type);
    }
}
