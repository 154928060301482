import {Component, OnInit} from '@angular/core';
import {User} from '../../_models/user';
import {Organization} from '../../_models/organization';
import {AlertController} from '@ionic/angular';
import {OrganizationService} from '../../_services/organization.service';
import {UserService} from '../../_services/user.service';
import {SubscriberComponent} from '../subscriber.component';
import {PickupLocation} from '../../_models/pickup-location';
import {PickupLocationService} from '../../_services/pickup-location.service';
import {Timecard} from '../../_models/timecard';
import {TimecardService} from '../../_services/timecard.service';
import {PrinterService} from '../../_services/printer.service';
import {addCurrency} from '../../_utils/currency-math';

@Component({
    selector: 'bb-clock-in-out',
    templateUrl: './clock-in-out.component.html',
    styleUrls: ['./clock-in-out.component.scss'],
})
export class ClockInOutComponent extends SubscriberComponent implements OnInit {
    user: User;
    organization: Organization;
    pickupLocation: PickupLocation;

    constructor(
        private userService: UserService,
        private printerService: PrinterService,
        private organizationService: OrganizationService,
        private pickupLocationService: PickupLocationService,
        private alertController: AlertController,
        private timecardService: TimecardService,
    ) {
        super();
    }

    ngOnInit() {
        this.subscribe(this.organizationService.current.subscribe(o => this.organization = !!o ? new Organization(o) : null));
        this.subscribe(this.userService.current.subscribe(u => this.user = u));
        this.subscribe(this.pickupLocationService.current.subscribe(p => this.pickupLocation = !!p ? new PickupLocation(p) : null));
    }

    async clockIn() {
        await this.timecardService.confirmClockIn(this.user, this.pickupLocation, this.organization);
    }

    async clockOut(timecardId: number) {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Clock Out',
            message: `Are you sure you want to clock out ${this.user.person.name}?`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary'
                }, {
                    text: 'Yes',
                    handler: async () => {
                        this.timecardService.clockOut(timecardId).subscribe((t: Timecard) => {
                            this.user.timecard = null;
                            if (this.printerService.isConnected()) {
                                this.organizationService.getEmployeeTipReport(this.organization.id, t.clockInTime, t.clockOutTime, t.person.id)
                                    .subscribe((employeeTipSummaries: any[]) => {
                                        const tc = new Timecard(t);
                                        tc.cashTips = 0;
                                        tc.payrollTips = 0;
                                        if (!!employeeTipSummaries) {
                                            employeeTipSummaries.forEach(ts => {
                                                tc.cashTips = addCurrency(tc.cashTips, ts.cashTips);
                                                tc.payrollTips = addCurrency(tc.payrollTips, ts.payrollTips);
                                            });
                                        }
                                        this.printerService.printTimeCard(tc, this.organization);
                                    });
                            }
                        });
                    }
                }
            ]
        });

        await alert.present();
    }
}
