import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserOrganizationCreditService} from '../_services/user-organization-credit.service';
import {UserService} from '../_services/user.service';

@Injectable()
export class AvailableCreditsResolver implements Resolve<any> {
    constructor(
        private userOrganizationCreditService: UserOrganizationCreditService,
        private userService: UserService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        if (!!this.userService.currentValue) {
            return this.userOrganizationCreditService.unused(route.paramMap.get('orgId'));
        }
    }
}
