<ion-row>
    <ion-col size-xs="12">
        <ion-item lines="none">
            <span class="ion-text-wrap">
                <span>{{ address.addr1 }}<br></span>
                <span *ngIf="address.addr2 != null && address.addr2.length > 0">{{ address.addr2 }}<br></span>
                <span *ngIf="address.addr3 != null && address.addr3.length > 0">{{ address.addr3 }}<br></span>
                <span>{{ address.city }}, {{ address.state }} {{ address.postalCode}}</span>
            </span>
        </ion-item>
    </ion-col>
</ion-row>