import {Pipe, PipeTransform} from '@angular/core';
import {OrderStatuses} from '../../_constants/order-statuses';

@Pipe({
    name: 'orderStatusColor'
})
export class OrderStatusColorPipe implements PipeTransform {
    transform(value: string, header?: boolean): string {
        let color = null;
        switch (value) {
        case OrderStatuses.RECEIVED:
            color = 'warning';
            break;
        case OrderStatuses.PREPARING:
            color = 'tertiary';
            break;
        case OrderStatuses.READY:
            color = 'primary';
            break;
        case OrderStatuses.DELIVERED:
            color = header ? '' : 'light';
            break;
        case OrderStatuses.CANCELED:
            color = header ? 'danger' : 'light';
            break;
        case OrderStatuses.PENDING:
            color = 'dark';
            break;
        default:
            color = 'primary';
        }
        return color;
    }
}
