import {BaseFormModel} from './base-form-model';

export class MembershipTokenPurse extends BaseFormModel<MembershipTokenPurse> {
    id: number;
    orgId: number;
    count: number;
    initialCount: number;
    createDate: Date;
    expirationDate: Date;
}
