<modal-header title="Create Marketing Push"></modal-header>

<ion-content>
    <ion-card>
        <ion-card-content>
            <form [formGroup]="createPushForm" (ngSubmit)="onSubmit()">
                <ion-grid>
                    <ion-row>
                        <ion-col class="ion-text-center">
                            <ion-icon *ngIf="!imageUrl" name="cloud-upload-outline" class="image-placeholder"
                                      (click)="openUploadImageModal()"></ion-icon>
                            <img *ngIf="!!imageUrl"
                                 [src]="imageUrl"
                                 class="push-image">
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-item>
                                <ion-label position="floating">External URL</ion-label>
                                <ion-input type="text" formControlName="navUrl" maxlength="500"></ion-input>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-item>
                                <ion-label position="stacked">Message</ion-label>
                                <ion-textarea formControlName="message" placeholder="Message" maxlength="1000">
                                </ion-textarea>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </form>
        </ion-card-content>
    </ion-card>
</ion-content>

<modal-footer [disableSubmit]="createPushForm.invalid" (submitClicked)="onSubmit()"></modal-footer>
