import {Injectable} from '@angular/core';
import {CashDrawer} from '../_models/cash-information/cash-drawer';
import {HttpHeaders} from '@angular/common/http';
import {Constants} from '../_constants/constants';
import {BaseObservableService} from './base-obeservable-service';

@Injectable({
    providedIn: 'root'
})
export class CashDrawerService extends BaseObservableService<CashDrawer> {
    destination = 'cash-drawers';

    create(cashDrawer: CashDrawer) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '412');
        return this.http.post(this.baseUrl(), cashDrawer, {headers});
    }

    complete(cashDrawer: CashDrawer) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '412');
        return this.http.put(`${this.baseUrl()}/${cashDrawer.id}/complete?endAmount=${cashDrawer.endAmount}`, cashDrawer, {headers});
    }

    summary(id: any) {
        return this.http.get(`${this.baseUrl()}/${id}/summary`);
    }

    summaries(cashSessionId: any) {
        return this.http.get(`${this.baseUrl()}/cash-session/${cashSessionId}`);
    }

    safes(organizationId: any) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}`);
    }

    export(cashDrawerId: any) {
        const httpOptions = {
            responseType: 'blob' as 'json',
        };
        return this.http.get(`${this.baseUrl()}/${cashDrawerId}/export`, httpOptions);
    }

    setCurrent(obj: CashDrawer) {
        if (!obj) {
            localStorage.removeItem('CASH_DRAWER_ID');
        } else {
            localStorage.setItem('CASH_DRAWER_ID', obj.id.toString());
        }
        this.currentSubject.next(obj);
    }
}
