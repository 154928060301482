<modal-header title="User Roles"></modal-header>

<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col size-xs="12">
                <ion-list>
                    <ng-container [formGroup]="formGroup">
                        <ion-item *bbSecured="[featureKeys.ORG_ADMIN, featureKeys.ORG_OWNER]">
                            <ion-label>
                                Owner
                            </ion-label>
                            <ion-checkbox color="primary" slot="start" formControlName="owner"></ion-checkbox>
                        </ion-item>
                        <ion-item *bbSecured="[featureKeys.ORG_ADMIN, featureKeys.ORG_OWNER]">
                            <ion-label>
                                Admin
                            </ion-label>
                            <ion-checkbox color="primary" slot="start" formControlName="admin"></ion-checkbox>
                        </ion-item>
                    </ng-container>
                    <ion-item *ngFor="let role of roles">
                        <ion-label>
                            {{ role.role.name }}
                            <p *ngIf="!!role.role.description">{{role.role.description}}</p>
                        </ion-label>
                        <ion-checkbox color="primary" slot="start" [(ngModel)]="role.selected"></ion-checkbox>
                    </ion-item>
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

<modal-footer [disableSubmit]="formGroup.invalid" (submitClicked)="onSubmit()"></modal-footer>
