import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {MenuItemInventoryService} from '../_services/menu-item-inventory.service';

@Injectable()
export class MenuItemInventoryResolver implements Resolve<any> {
    constructor(private menuItemInventoryService: MenuItemInventoryService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.menuItemInventoryService.findByMenuItemId(route.paramMap.get('menuItemId'));
    }
}
