import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseModalComponent} from '../base-modal.component';
import {MenuItemInventory} from '../../_models/menu-item-inventory';
import {LoadingService} from '../../_services/loading.service';
import {MenuItemInventoryActionService} from '../../_services/menu-item-inventory-action.service';
import {MenuItemInventoryService} from '../../_services/menu-item-inventory.service';
import {ToastService} from '../../_services/toast.service';
import {MenuItemInventoryAction} from '../../_models/menu-item-inventory-action';

@Component({
    selector: 'bb-menu-item-inventory-action',
    templateUrl: './menu-item-inventory-action.component.html',
    styleUrls: ['./menu-item-inventory-action.component.scss'],
})
export class MenuItemInventoryActionComponent extends BaseModalComponent implements OnInit {
    @Input() menuItemInventory: MenuItemInventory;
    @Input() backOfHouseInventory: MenuItemInventory;
    formGroup: FormGroup;
    closing = false;

    constructor(
        modalController: ModalController,
        private loadingService: LoadingService,
        private menuItemInventoryActionService: MenuItemInventoryActionService,
        private menuItemInventoryService: MenuItemInventoryService,
        private toastService: ToastService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            parentId: this.menuItemInventory.id,
            action: ['', Validators.required],
            amount: ['', Validators.required],
            transferBackOfHouse: false,
            comment: ''
        });

        this.subscribe(this.menuItemInventoryService.event.subscribe(i => {
            if (!!i && !i.deleted && i.id === this.menuItemInventory.id) {
                this.menuItemInventory = new MenuItemInventory(i);
            }
        }));
    }

    calculatedCount() {
        const action = this.formGroup.controls.action.value;
        if (action === 'COUNT') {
            return this.formGroup.controls.amount.value;
        } else if (action === 'ADD') {
            return this.menuItemInventory.currentCount + Number.parseInt(this.formGroup.controls.amount.value, 10);
        } else {
            return this.menuItemInventory.currentCount - Number.parseInt(this.formGroup.controls.amount.value, 10);
        }
    }

    calculatedBackOfHouseCount() {
        const action = this.formGroup.controls.action.value;
        if (action === 'ADD') {
            return this.backOfHouseInventory.currentCount - Number.parseInt(this.formGroup.controls.amount.value, 10);
        } else if (action === 'REMOVE') {
            return this.backOfHouseInventory.currentCount + Number.parseInt(this.formGroup.controls.amount.value, 10);
        } else {
            return 0;
        }
    }

    transfer() {
        this.loadingService.present();
        const action = new MenuItemInventoryAction(this.formGroup.value);

    }

    addAction() {
        let menuItemInventoryAction = new MenuItemInventoryAction(this.formGroup.value);
        this.loadingService.present();
        if (this.formGroup.controls.transferBackOfHouse.value) {
            let fromId = this.backOfHouseInventory.id;
            if (menuItemInventoryAction.action === 'REMOVE') {
                fromId = this.menuItemInventory.id;
                menuItemInventoryAction = new MenuItemInventoryAction({
                    parentId: this.backOfHouseInventory.id,
                    amount: menuItemInventoryAction.amount
                });
                menuItemInventoryAction.parentId = this.backOfHouseInventory.id;
            } else {
                menuItemInventoryAction = new MenuItemInventoryAction({
                    parentId: this.menuItemInventory.id,
                    amount: menuItemInventoryAction.amount,
                    pickupLocation: this.menuItemInventory.pickupLocation
                });
            }
            this.menuItemInventoryActionService.transfer(fromId, menuItemInventoryAction).subscribe(c => {
                this.closing = true;
                this.loadingService.dismiss();
                this.close(c);
            });
        } else {
            this.menuItemInventoryActionService.create(menuItemInventoryAction).subscribe(c => {
                this.closing = true;
                this.loadingService.dismiss();
                this.close(c);
            });
        }
    }
}
