<ion-segment mode="ios"
             [(ngModel)]="orderType">
    <ion-segment-button value="ALL" (click)="segmentChanged(availabilities.ALL)">
        <ion-label *ngIf="!iconOnly" position="stacked">Both</ion-label>
        <ion-icon name="checkmark-circle-outline"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="IN_HOUSE" (click)="segmentChanged(availabilities.IN_HOUSE)">
        <ion-label *ngIf="!iconOnly">In House Only</ion-label>
        <ion-icon name="home-outline"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="TOGO" (click)="segmentChanged(availabilities.TOGO)">
        <ion-label *ngIf="!iconOnly">To Go Only</ion-label>
        <ion-icon name="car-outline"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="NONE" (click)="segmentChanged(availabilities.NONE)">
        <ion-label *ngIf="!iconOnly">Unavailable</ion-label>
        <ion-icon name="ban-outline"></ion-icon>
    </ion-segment-button>
</ion-segment>
