import {Person} from './person';
import {Login} from './login';
import {BaseFormModel} from './base-form-model';
import {OrganizationRating} from './organization-rating';
import {CardSummary} from './card-summary';
import {Timecard} from './timecard';
import {OrganizationUser} from './organization-user';
import {OrganizationRole} from './organization-role';

export class User extends BaseFormModel<User> {
    id: number;
    email: string;
    passwordReset: boolean;
    person: Person;
    login: Login;
    token?: string;
    organizations: OrganizationUser[];
    invitedUserOrganizations: OrganizationUser[];
    organizationRatings: OrganizationRating[];
    lastLogin: Date;
    timecard?: Timecard;

    initialized = false;
    selectedCard: CardSummary;
    modalLogin: boolean;
    siteAdmin: boolean;

    init(): void {
        this.organizations = this.sanitizeUserOrganizations(this.organizations);
        this.invitedUserOrganizations = this.sanitizeUserOrganizations(this.invitedUserOrganizations);
    }

    sanitizeUserOrganizations(userOrganizations) {
        const tmp = [];
        if (userOrganizations && userOrganizations.length > 0) {
            userOrganizations.forEach(uo => tmp.push(new OrganizationUser(uo)));
        }
        return tmp;
    }

    hasRole(role: OrganizationRole[], orgId: number) {
        if (this.siteAdmin || this.isOrgAdmin(orgId)) {
            return true;
        }
        const uo = this.organizations.find(o => o.orgId === orgId);
        return role.some(r => uo.roles.some(ur => ur.id === r.id));
    }

    authorizedTo(feature: string | string[], orgId: number) {
        if (this.siteAdmin) {
            return true;
        }

        if (this.organizations != null && this.organizations.length > 0) {
            if (!feature || typeof feature === 'string') {
                const uo = this.organizations.find(o => o.orgId === orgId);
                if (!!uo) {
                    return uo.authorizedTo(feature as string);
                }
            } else if (Array.isArray(feature) && feature.length > 0) {
                for (const f of feature) {
                    const auth = this.authorizedTo(f, orgId);
                    if (auth) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    isOrgAdmin(organizationId: number) {
        if (this.siteAdmin) {
            return true;
        }
        if (!!this.organizations) {
            const organizationUser = this.organizations.find(o => o.orgId = organizationId);
            return !!organizationUser && organizationUser.admin;
        }
        return false;
    }

    organizationRolesString(organizationId: number) {
        let roles = [];
        if (!!this.organizations) {
            const organizationUser = this.organizations.find(o => o.orgId === organizationId);
            roles = !!organizationUser ? organizationUser.roles.map(r => r.name) : [];

            if (organizationUser.owner) {
                roles.push('Owner');
            }

            if (organizationUser.admin) {
                roles.push('Admin');
            }
        }
        roles.sort();

        return roles.length > 0 ? roles.join(', ') : '';
    }
}
