import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormGroup} from '@angular/forms';
import {ToastService} from '../../_services/toast.service';
import {User} from '../../_models/user';
import {BaseModalComponent} from '../base-modal.component';
import {UserService} from '../../_services/user.service';

@Component({
    selector: 'bb-change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.css']
})
export class ChangePasswordModalComponent extends BaseModalComponent implements OnInit {
    changePasswordForm: FormGroup;
    strength: number;
    currentUser: User;

    constructor(
        modalController: ModalController,
        private userService: UserService,
        private toastService: ToastService
    ) {
        super(modalController);
        this.changePasswordForm = new FormGroup({});
    }

    ngOnInit() {
        this.subscribe(this.userService.current.subscribe(x => this.currentUser = x));
    }

    onSubmit() {
        this.userService.changePassword(this.currentUser.login.email,
            this.changePasswordForm.controls.oldPassword.value,
            this.changePasswordForm.controls.password.value).subscribe(
            async () => {
                await this.toastService.success('Your password has been changed.');
                await this.close();
            },
            async () => {
                await this.toastService.error('Invalid password');
            }
        );
    }

    formInitialized(form: FormGroup) {
        this.changePasswordForm = form;
    }
}
