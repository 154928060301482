import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {CardSummary} from '../../_models/card-summary';
import {CardSummaryService} from '../../_services/card-summary.service';
import {ToastService} from '../../_services/toast.service';
import {AlertController} from '@ionic/angular';
import {User} from '../../_models/user';
import {LoadingService} from '../../_services/loading.service';
import {RemovableItemComponent} from '../removable-item/removable-item.component';
import {SubscriberComponent} from '../subscriber.component';
import {sortCards} from '../../_utils/payment-utils';
import {UserService} from '../../_services/user.service';

@Component({
    selector: 'bb-payment-method-list',
    templateUrl: './payment-method-list.component.html',
    styleUrls: ['./payment-method-list.component.css']
})
export class PaymentMethodListComponent extends SubscriberComponent implements OnInit {
    @Input() cards: CardSummary[];
    @Input() user: User;

    @ViewChildren(RemovableItemComponent) removableItems: QueryList<RemovableItemComponent>;

    constructor(
        private cardSummaryService: CardSummaryService,
        private userService: UserService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private alertController: AlertController,
    ) {
        super();
    }

    ngOnInit(): void {
        if (!!this.cards && this.cards.length > 0) {
            sortCards(this.cards);
        }
    }

    refreshCards() {
        // this.cardSummaryService.paymentMethods()
        //     .subscribe(results => {
                // this.user.person.cardSummaries = sortCards(results as CardSummary[]);
                // this.userService.setCurrent(this.user);
                // this.cards = this.user.person.cardSummaries;
            // });
    }

    async setAsDefault(card: CardSummary) {
        if (!card.defaultSource) {
            const alert = await this.alertController.create({
                cssClass: 'brewbill-alert',
                header: 'Confirm Default Card',
                message: 'Are you sure you want to set this card as the default payment method?',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel'
                    }, {
                        text: 'Yes',
                        handler: () => {
                            this.processDefault(card);
                        }
                    }
                ]
            });

            await alert.present();
        }
    }

    async processDefault(card: CardSummary) {
        try {
            await this.cardSummaryService.makeDefault(card).subscribe(() => {
                    this.toastService.success('Default source updated.');
                    this.refreshCards();
                }
            );
        } catch (err) {
            await this.toastService.error('A server error occurred.');
        }
    }

    async remove(cardId: number, index) {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Card Delete',
            message: 'Are you sure you want to delete this card from your account?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    handler: () => {
                        this.removableItems.get(index).reverse();
                    }
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.processRemoval(cardId, index);
                    }
                }
            ]
        });

        await alert.present();
    }

    async processRemoval(cardId: number, index) {
        this.loadingService.present();
        await this.cardSummaryService.deleteCard(cardId).subscribe(
            async () => {
                this.loadingService.dismiss();
                await this.toastService.success('Card deleted.');
                await this.removableItems.get(index).animate();
                this.refreshCards();
            }, error => {
                this.loadingService.dismiss();
                if (error.status === 424) {
                    this.toastService.error('An open tab exists. At least one payment method must be on file until it is closed.');
                } else {
                    this.toastService.error('A server error occurred.');
                }

                this.removableItems.get(index).reverse();
            }
        );
    }
}
