import {PickupLocationMenu} from './pickup-location-menu';
import {BaseFormModel} from './base-form-model';
import {MenuItemPrice} from './menu-item-price';

export class PickupLocationMenuItemPrice extends BaseFormModel<PickupLocationMenuItemPrice> {
    id: number;
    pickupLocationMenu: PickupLocationMenu;
    menuItemPrice: MenuItemPrice;
    allowInHouse: boolean;
    allowTogo: boolean;
    terminalOnly: boolean;
    venueId: number;
    orgId: number;
}
