import {Component, Input} from '@angular/core';
import {PaymentStatuses} from '../../_constants/payment-statuses';

@Component({
    selector: 'bb-payment-status',
    templateUrl: './payment-status.component.html',
    styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent {
    @Input() paymentStatus: string;

    constructor() {
    }

    getLabel() {
        switch (this.paymentStatus) {
        case PaymentStatuses.PAID:
            return 'Paid';
        case PaymentStatuses.OVER_PAID:
            return 'Overpaid';
        case PaymentStatuses.PARTIAL_PAID:
            return 'Partial Paid';
        case PaymentStatuses.UNPAID:
            return 'Unpaid';
        case PaymentStatuses.CANCELED:
            return 'Canceled';
        case PaymentStatuses.PRE_AUTH:
            return 'Card On File';
        default:
            return '';
        }
    }

    getCssClass() {
        switch (this.paymentStatus) {
        case PaymentStatuses.PAID:
            return 'pill-success';
        case PaymentStatuses.PRE_AUTH:
            return 'pill-secondary';
        case PaymentStatuses.OVER_PAID:
            return 'pill-warning';
        case PaymentStatuses.PARTIAL_PAID:
        case PaymentStatuses.UNPAID:
            return 'pill-danger';
        default:
            return '';
        }
    }
}
