import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    toast: any;
    queue = [];

    constructor(public toastController: ToastController) {
    }

    async message(message: string, color = 'tertiary') {
        await this.show({
            duration: 10000,
            message,
            color
        });
    }

    async success(message: string) {
        await this.show({
            duration: 5000,
            message,
            color: 'success'
        });
    }

    async error(message: string) {
        await this.show({
            duration: 10000,
            message,
            color: 'danger'
        });
    }

    async show(details: any) {
        if (!!this.toast) {
            this.queue.push(details);
        } else {
            this.toast = await this.toastController.create(
                {
                    position: 'top',
                    duration: details.duration,
                    buttons: [
                        {
                            side: 'end',
                            icon: 'close'
                        }
                    ],
                    message: details.message,
                    color: details.color
                });

            await this.toast.present();
            this.toast.onDidDismiss().then(() => {
                this.toast = null;
                if (this.queue.length > 0) {
                    this.show(this.queue.pop());
                }
            });
        }
    }

    async hide() {
        if (!!this.toast) {
            await this.toastController.dismiss();
            this.toast = null;
        }
    }
}
