import {Inject, Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import * as Ably from 'ably';
import {LibConfig, LibConfigService} from '../lib.config';
import {PickupLocation} from '../_models/pickup-location';
import {PickupLocationService} from './pickup-location.service';
import {OrganizationService} from './organization.service';
import {PickupLocationMenuService} from './pickup-location-menu.service';
import {Organization} from '../_models/organization';
import {Availabilities} from '../_constants/availabilities';
import {OrderService} from './order.service';
import {PickupLocationMenu} from '../_models/pickup-location-menu';
import {MenuService} from './menu.service';
import {TabService} from './tab.service';
import {MenuItemInventoryService} from './menu-item-inventory.service';
import {MenuItemInventory} from '../_models/menu-item-inventory';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseEventService {
    public ably: Ably.Realtime;

    organization: Organization;
    pickupLocation: PickupLocation;
    pickupLocationMenu: PickupLocationMenu;

    abstract readonly pickupLocationChannelPrefix: string;
    abstract readonly organizationChannelPrefix: string;

    pickupLocationChannel: string;
    organizationChannel: string;
    stagingMode = false;

    userChannel: string;

    abstract subscribeToOrganizationChannels(channel);

    abstract refreshApp();

    protected constructor(
        protected pickupLocationService: PickupLocationService,
        protected pickupLocationMenuService: PickupLocationMenuService,
        protected menuService: MenuService,
        protected menuItemInventoryService: MenuItemInventoryService,
        protected orderService: OrderService,
        protected tabService: TabService,
        protected organizationService: OrganizationService,
        protected platform: Platform,
        @Inject(LibConfigService) public config: LibConfig
    ) {
        this.pickupLocationService.current.subscribe((p: PickupLocation) => {
            this.pickupLocation = p;
            this.subscribeToPickupLocation();
        });

        this.organizationService.current.subscribe(o => {
            this.organization = !!o ? new Organization(o) : null;
            this.subscribeToOrganization();
        });

        this.stagingMode = this.config.testMode;
    }

    connectAbly(token) {
        console.log('connecting ably');
        this.close();
        this.ably = new Ably.Realtime({
            authUrl: `${this.config.apiUrl}/sse/auth`,
            authParams: !!token ? {token} : {},
        });
    }

    getChannel(channelKey, token) {
        if (!this.ably) {
            this.connectAbly(token);
        }
        return this.ably.channels.get(channelKey);
    }

    close() {
        if (!!this.ably) {
            if (!!this.organizationChannel) {
                this.releaseChannel(this.organizationChannel, null);
                this.organizationChannel = null;
            }

            if (!!this.pickupLocationChannel) {
                this.releaseChannel(this.pickupLocationChannel, null);
                this.pickupLocationChannel = null;
            }
            this.ably.close();
            this.ably = null;
        }
    }

    releaseChannel(channelKey, token) {
        console.log('releasing ' + channelKey);
        if (!this.ably) {
            this.connectAbly(token);
        }
        this.ably.channels.get(channelKey).detach(() => {
            this.ably.channels.release(channelKey);
        });
    }

    subscribeToOrganization() {
        const nextChannel = !!this.organization ? `${this.organizationChannelPrefix}_${this.organization.id}` : null;

        if (!!this.organizationChannel && this.organizationChannel !== nextChannel) {
            this.releaseChannel(this.organizationChannel, null);
        }

        if (!!nextChannel
            && nextChannel !== this.organizationChannel
            && !!this.ably) {

            this.organizationChannel = nextChannel;

            const channel = this.ably.channels.get(this.organizationChannel);
            this.subscribeToOrganizationChannels(channel);
        }

        if (!nextChannel) {
            this.organizationChannel = null;
        }
    }

    subscribeToPickupLocation() {
        const nextChannel = !!this.pickupLocation ? `${this.pickupLocationChannelPrefix}_${this.pickupLocation.id}` : null;

        if (!!this.pickupLocationChannel && this.pickupLocationChannel !== nextChannel) {
            this.releaseChannel(this.pickupLocationChannel, null);
        }

        if (!!nextChannel
            && nextChannel !== this.pickupLocationChannel
            && !!this.ably) {

            this.pickupLocationChannel = nextChannel;

            const channel = this.ably.channels.get(this.pickupLocationChannel);

            channel.subscribe('MENU_AVAILABILITY_UPDATE', async (message) => {
                const item = JSON.parse(message.data);
                if (!!item.id) {
                    this.pickupLocationMenuService.availabilityUpdate(item);
                } else {
                    this.pickupLocationMenu.allowInHouse = item.allowInHouse;
                    this.pickupLocationMenu.allowTogo = item.allowTogo;
                    this.pickupLocationMenuService.setCurrent(this.pickupLocationMenu);
                    const availability = this.orderService.getOrderType();

                    if (!this.pickupLocationMenu.allowInHouse
                        && this.pickupLocationMenu.allowTogo
                        && availability === Availabilities.IN_HOUSE) {
                        this.orderService.nextType(Availabilities.TOGO);
                    } else if (!this.pickupLocationMenu.allowTogo
                        && this.pickupLocationMenu.allowInHouse
                        && availability === Availabilities.TOGO) {
                        this.orderService.nextType(Availabilities.IN_HOUSE);
                    }
                }
            });

            channel.subscribe('PICKUP_LOCATION_UPDATE', async (message) => {
                this.pickupLocationService.get(message.data).subscribe(async pl => {
                    await this.pickupLocationService.setCurrent(new PickupLocation(pl));
                });
            });

            channel.subscribe('PICKUP_LOCATION_GEO_UPDATE', async (message) => {
                this.pickupLocation.address.latitude = message.data.latitude;
                this.pickupLocation.address.longitude = message.data.longitude;
                await this.pickupLocationService.setCurrent(this.pickupLocation);
            });
        }

        if (!nextChannel) {
            this.pickupLocationChannel = null;
        }
    }

    inventoryEventHandler(id: number) {
        this.menuItemInventoryService.getSilent(id).subscribe(i => {
            if (!!i) {
                const inventory = new MenuItemInventory(i);
                this.menuItemInventoryService.setEvent(inventory);
            } else {
                this.menuItemInventoryService.setEvent(new MenuItemInventory({id, deleted: true}));
            }
        });
    }

    refresh() {
        if (!this.stagingMode) {
            this.organizationService.refresh();
            this.pickupLocationService.refresh();
            this.pickupLocationMenuService.refresh();
            this.orderService.refresh();
            this.menuService.refresh();
            this.tabService.refresh();

            this.refreshApp();
        }
    }

    connectionState() {
        return !!this.ably && !!this.ably.connection ? this.ably.connection.state : null;
    }
}
