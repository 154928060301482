<modal-header title="Select Cash Drawer"></modal-header>
<ion-content>
    <ng-container *ngIf="!!activeDrawers && activeDrawers.length > 0">
        <ion-grid>
            <ion-row>
                <ion-col>
                    <h3>Active Drawers</h3>
                </ion-col>
            </ion-row>
        </ion-grid>
        <bb-select-buttons [options]="activeDrawers"
                           [selected]="selected"
                           [hideNoSelection]="true"
                           (optionSelected)="selectDrawer($event)">
            <ng-template let-option #displayTemplate>
                {{ option.name }}
            </ng-template>
        </bb-select-buttons>
        <ion-grid>
            <ion-row>
                <ion-col>
                    <h3>Completed Drawers from {{ cashSession.startTime | date }}</h3>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
    <bb-select-buttons [options]="drawers"
                       [selected]="selected"
                       (optionSelected)="selectDrawer($event)">
        <ng-template let-option #displayTemplate>
            {{ option.name }}
        </ng-template>
    </bb-select-buttons>
</ion-content>
