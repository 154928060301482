<ion-item [id]="id" (click)="popover.present()">
    <ion-label position="floating">{{label}}</ion-label>
    <ion-input [value]="field" (keydown)="$event.preventDefault()"></ion-input>

    <ion-popover [trigger]="id" show-backdrop="true" css-class="color-select-popover" #popover>
        <ng-template>
            <color-sketch [(color)]="field"
                          [presetColors]="presetColors"
                          [disableAlpha]="true"
                          [width]="300"
                          (colorChange)="fireColorChange($event)"></color-sketch>
        </ng-template>
    </ion-popover>
</ion-item>
