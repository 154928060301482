<modal-header title="{{label}}"></modal-header>
<ion-content>
    <ion-grid *ngIf="!!max">
        <ion-row *ngIf="!!splitMax">
            <ion-col>
                <ion-segment mode="ios"
                             [(ngModel)]="selectedSplit"
                             (ionChange)="calculateSplit()">
                    <ion-segment-button [value]="2">
                        <ion-label>2</ion-label>
                    </ion-segment-button>
                    <ion-segment-button [value]="3">
                        <ion-label>3</ion-label>
                    </ion-segment-button>
                    <ion-segment-button [value]="4">
                        <ion-label>4</ion-label>
                    </ion-segment-button>
                    <ion-segment-button [value]="5">
                        <ion-label>5</ion-label>
                    </ion-segment-button>
                    <ion-segment-button [value]="6">
                        <ion-label>6</ion-label>
                    </ion-segment-button>
                    <ion-segment-button [value]="7">
                        <ion-label>7</ion-label>
                    </ion-segment-button>
                    <ion-segment-button [value]="8">
                        <ion-label>8</ion-label>
                    </ion-segment-button>
                </ion-segment>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size-xs="6" class="max-container">
                <ion-item>
                    <bb-labelled-item label="Max" value="{{max | currency}}"></bb-labelled-item>
                    <ion-icon class="arrow" name="arrow-forward-outline" slot="end" (click)="setToMax()"></ion-icon>
                </ion-item>
            </ion-col>

            <ion-col size-xs="6">
                <ion-item>
                    <ion-label class="floating-label" position="stacked">
                        {{label}}
                    </ion-label>
                    <ion-input [(ngModel)]="formatted"
                               bbCurrencyMaskPrefix
                               inputmode="numeric"
                               min="0"
                               class="tip-input-small"
                               #manualAmount>
                    </ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
    <ion-item *ngIf="!max">
        <ion-input [(ngModel)]="formatted"
                   bbCurrencyMaskPrefix
                   inputmode="numeric"
                   min="0"
                   class="tip-input"
                   #manualAmount>
        </ion-input>
    </ion-item>
</ion-content>
<modal-footer [disableSubmit]="!!max && this.amount > max" (submitClicked)="formatAndClose()" submitLabel="Confirm"></modal-footer>
