<bb-card-loading *ngIf="loading || !tabReportResult"></bb-card-loading>
<ng-container *ngIf="!loading && !!tabReportResult">
    <ion-list>
        <ion-item *ngFor="let column of tabReportResult.columns">
            <ion-label>
                {{column.label}}
            </ion-label>
            {{!!tabReportResult.data && tabReportResult.data.length === 1 ? getRowValue(tabReportResult.data[0], column) : 0}}
        </ion-item>
    </ion-list>
</ng-container>
