import {BaseFormModel} from './base-form-model';
import {OrderItem} from './order-item';
import {Menu} from './menu';
import {formatDate} from '@angular/common';
import {User} from './user';
import {Person} from './person';
import {PickupLocation} from './pickup-location';
import {CardSummary} from './card-summary';
import {OrderStatusAudit} from './order-status-audit';
import {Visit} from './visit';
import {OrderStatuses} from '../_constants/order-statuses';
import {addCurrency} from '../_utils/currency-math';
import {CustomerAreaPosition} from './customer-area-position';
import {Tab} from './tab';
import {v4 as uuidv4} from 'uuid';

export class Order extends BaseFormModel<Order> {
    id: number;
    status: string;
    total: number;
    deposits: number;
    items: OrderItem[] = [];
    date: Date;
    menu: Menu;
    pickupLocation: PickupLocation;
    orgId: number;
    venueId: number;
    orderMarker: number;
    statusChangeDate: Date;
    submittedBy: Person;
    modifiedBy: User;
    deviceId: string;
    statusAudits: OrderStatusAudit[];
    terminalOrder: boolean;
    readyTime: number;
    deliveryTime: number;
    tabPersonName: string;
    tabPersonEmail: string;
    invoiceCreated: boolean;
    server: Person;
    tabKey: string;
    key: string;

    visit: Visit;

    waiting = false;

    additionalModifiedItems: OrderItem[];

    // TODO: remove these
    person: Person;
    tip: number;
    email: string;
    cardSummary: CardSummary;
    customerAreaPosition: CustomerAreaPosition;
    timeZone: string;
    refundFailureMessages: string[];
    tabPaymentStatus: string;
    preload: boolean;

    public constructor(jsonInput: any) {
        super(jsonInput);
        Object.assign(this, jsonInput);
        const items = [];
        if (this.items) {
            this.items.forEach(o => {
                if (!o.key) {
                    o.key = uuidv4();
                }
                items.push(new OrderItem(o));
            });
        }
        this.items = items;
        this.customerAreaPosition = !!this.customerAreaPosition ? new CustomerAreaPosition(this.customerAreaPosition) : null;
    }

    formattedDate() {
        return formatDate(this.date, 'M/d/yy h:mm a', 'en-US');
    }

    statusPriority() {
        let priority = 100;
        switch (this.status) {
        case OrderStatuses.RECEIVED:
            priority = 20;
            break;
        case OrderStatuses.PREPARING:
            priority = 10;
            break;
        case OrderStatuses.READY:
            priority = 0;
            break;
        case OrderStatuses.DELIVERED:
            priority = 30;
            break;
        }
        return priority;
    }

    isActive(): boolean {
        return this.status !== OrderStatuses.DELIVERED
            && this.status !== OrderStatuses.CANCELED
            && this.status !== OrderStatuses.VACATED
            && this.status !== OrderStatuses.DELETED
            && this.status !== OrderStatuses.PENDING;
    }

    tokenPayments() {
        return this.items.reduce((total, item) => {
            return total + (!!item.tokenPayment ? item.tokenPayment : 0);
        }, 0);
    }

    calculateTotal() {
        this.total = this.items.reduce((total, item) => {
            return addCurrency(total, item.calcTotal());
        }, 0);
        return this.total;
    }

    calculateDeposits() {
        this.deposits = this.items.reduce((deposits, item) => {
            return addCurrency(deposits, item.calcDeposits());
        }, 0);
        return this.deposits;
    }

    init(): void {
        this.menu = null;
        const temp = [];
        if (!!this.items) {
            this.items.forEach(oi => {
                temp.push(new OrderItem(oi));
            });
            this.items = temp;
        }
    }

    getRefundFailureMessage() {
        let msg: string;
        if (this.refundFailureMessages.length === 1) {
            switch (this.refundFailureMessages[0]) {
            case 'EXISTING_SYNCED':
                msg = 'Existing refund synced, no action required.';
                break;
            case 'TERMINAL_REQUIRED':
                msg = 'Payment requires terminal refund. Click on refund in the payments section below.';
                break;
            case 'CASH_DUE':
                msg = 'Refund cash payments manually.';
                break;
            case 'DEPOSIT_EXISTS':
                msg = 'Refund deposits manually.';
                break;
            case 'MIXED_PAYMENTS':
                msg = 'Refund mixed payment methods manually.';
                break;
            }
        } else {
            msg = 'A refund could not be issued due to:';
            msg += '<ul>';
            this.refundFailureMessages.forEach(m => {
                msg += '<li>';
                switch (m) {
                case 'EXISTING_SYNCED':
                    msg += 'Existing refund synced, no action required.';
                    break;
                case 'TERMINAL_REQUIRED':
                    msg += 'Terminal required for refund.';
                    break;
                case 'CASH_DUE':
                    msg += 'Cash refund due.';
                    break;
                case 'DEPOSIT_EXISTS':
                    msg = 'Refund deposits manually.';
                    break;
                case 'MIXED_PAYMENTS':
                    msg += 'Refund mixed payment methods manually.';
                    break;
                }
                msg += '</li>';
            });
            msg += '</ul>';
        }
        return msg;
    }

    maxCreditIndex(): number {
        return this.items
            .reduce((max, item) => max = (max > item.maxCreditIndex()) ? max : item.maxCreditIndex() + 1, 0);
    }

    groupTaxedAmounts(order: Order, tab: Tab) {
        if (!!order && !!order.items) {
            order.items.forEach(item => {
                item.groupTaxedAmounts(tab);
            });
        }
    }
}
