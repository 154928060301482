import {BaseFormModel} from './base-form-model';
import {User} from './user';
import {Organization} from './organization';
import {Tab} from './tab';
import {UserOrganizationCredit} from './user-organization-credit';

export class OrganizationRating extends BaseFormModel<OrganizationRating> {
    id: number;
    rating: number;
    user: User;
    organization: Organization;
    organizationName: string;
    tab: Tab;
    date: Date;
    comments: string;
    response: string;
    readDate: Date;
    responseReadDate: Date;
    userOrganizationCredit: UserOrganizationCredit;
}
