<ion-row>
    <ion-col
            [style]="'border-left: 1px solid #fff; margin-left: ' + ((level + 1) * 15) + 'px; max-width: calc((calc(9 / var(--ion-grid-columns, 12)) * 100%) - ' + ((this.level + 1) * 15) + ')'">{{ selection.orderItem.menuItem.name }}</ion-col>
    <ion-col *ngIf="!!compact && !!selection.orderItem.total" class="ion-text-end">{{ adjustedPrice(selection.orderItem.total) | currency }}</ion-col>
    <ion-col *ngIf="!compact && !selection.orderItem.priceOverride && !!selection.additionalPrice"
             class="ion-text-end">{{ adjustedPrice(selection.additionalPrice) | currency }}</ion-col>
    <ion-col
            *ngIf="!compact && !selection.orderItem.total && !!selection.orderItem.selectedPrice && selection.orderItem.selectedPrice.percentageOfParent && !!parent"
            class="ion-text-end">{{ adjustedPrice(selection.total(!!parent.priceOverride ? parent.priceOverride : (!!parent.selectedPrice
        ? parent.selectedPrice.price
        : parent.total))) | currency }}</ion-col>
    <ion-col
            *ngIf="!compact && !selection.orderItem.total && !!selection.orderItem.selectedPrice && selection.orderItem.selectedPrice.variable && !!selection.orderItem.priceOverride"
            class="ion-text-end">{{ adjustedPrice(selection.orderItem.priceOverride) | currency }}</ion-col>

    <ion-col *ngIf="!compact && !selection.additionalPrice && selection.menuSelectionItem.additionalPrice > 0"
             class="ion-text-end"
             [class.remove-padding]="removePadding"
             [class.canceled-price]="orderStatus === 'CANCELED'">{{ adjustedPrice(selection.menuSelectionItem.additionalPrice) | currency }}</ion-col>
    <ion-col *ngIf="!compact && selection.depositTotal() > 0"
             class="ion-text-end"
             [class.remove-padding]="removePadding"
             [class.canceled-price]="orderStatus === 'CANCELED'">{{ adjustedPrice(selection.depositTotal()) | currency }}<span class="deposit-label">Deposit</span>
    </ion-col>
    <ion-col *ngIf="!compact && selection.orderItem.deposits > 0"
             class="ion-text-end"
             [class.remove-padding]="removePadding"
             [class.canceled-price]="orderStatus === 'CANCELED'">{{ adjustedPrice(selection.orderItem.deposits) | currency }}<span
            class="deposit-label">Deposit</span></ion-col>
</ion-row>
<ion-row *ngIf="hasDetails()">
    <ion-col class="child">
        <bb-compact-order-item *ngIf="compact" [item]="selection.orderItem" [level]="level + 1"></bb-compact-order-item>
        <bb-display-order-item *ngIf="!compact" [item]="selection.orderItem" [level]="level + 1" [count]="count"
                               [removePadding]="removePadding"></bb-display-order-item>
    </ion-col>
</ion-row>

