import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AlertController, ModalController} from '@ionic/angular';
import {divideCurrency, subtractCurrency} from '../../_utils/currency-math';
import {Tab} from '../../_models/tab';
import {TabCredit} from '../../_models/tab-credit';
import {CompDefinition} from '../../_models/comp-definition';
import {BaseCompComponent} from '../base-comp.component';

@Component({
    selector: 'bb-tab-credit-modal',
    templateUrl: './create-tab-credit.component.html',
    styleUrls: ['./create-tab-credit.component.scss']
})
export class CreateTabCreditComponent extends BaseCompComponent implements OnInit {
    @Input() tab: Tab;
    @Input() compDefinition: CompDefinition;

    itemsTotal: number;

    constructor(
        private formBuilder: FormBuilder,
        alertController: AlertController,
        modalController: ModalController
    ) {
        super(alertController, modalController);
    }

    ngOnInit(): void {
        this.full = subtractCurrency(this.tab.subtotal, this.tab.paymentInformation.credits);
        this.half = divideCurrency(this.full, 2);
        this.max = !!this.compDefinition && !!this.compDefinition.max && this.compDefinition.max < this.full
            ? this.compDefinition.max
            : this.full;

        this.creditForm = this.formBuilder.group({
            amount: [
                null, [
                    Validators.required, Validators.max(
                        !!this.compDefinition && !!this.compDefinition.max && this.compDefinition.max < this.full
                            ? this.compDefinition.max
                            : this.full), Validators.min(0)
                ]
            ],
            type: ['REWARD', Validators.required],
            comment: '',
            parentId: this.tab.id,
            compDefinition: this.compDefinition
        });
    }

    async onSubmit() {
        await this.close(new TabCredit(this.creditForm.value));
    }
}
