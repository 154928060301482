import {Person} from './person';
import {BaseFormModel} from './base-form-model';

export class CardSummary extends BaseFormModel<CardSummary> {
    id: number;
    person: Person;
    contact: Person;
    externalCardId: string;
    nickname: string;
    cardType: string;
    lastFour: string;
    expiration: string;
    defaultSource: boolean;
    wallet: string;
    personName: string;
    tokenized: boolean;
    entryType: string;

    tempToken: string;
}
