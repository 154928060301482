import {Component} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {base64ToFile, Dimensions, ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
    selector: 'bb-upload-image-modal',
    templateUrl: './upload-image-modal.component.html',
    styleUrls: ['./upload-image-modal.component.scss']
})
export class UploadImageModalComponent extends BaseModalComponent {
    formGroup: FormGroup;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    showCropper = false;

    constructor(
        private formBuilder: FormBuilder,
        modalController: ModalController
    ) {
        super(modalController);
        this.formGroup = formBuilder.group({
            uploadFile: [null]
        });
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        this.showCropper = true;
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        console.log('Cropper ready', sourceImageDimensions);
    }

    loadImageFailed() {
        console.log('Load failed');
    }

    uploadFile(event) {
        this.formGroup.get('uploadFile').updateValueAndValidity();
        this.imageChangedEvent = event;
    }

    async onSubmit() {
        if (this.formGroup.valid) {
            await this.close(base64ToFile(this.croppedImage));
        }
    }
}
