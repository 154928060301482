import {BaseFormModel} from './base-form-model';
import {Sortable} from './sortable';
import {MenuItemGroup} from './menu-item-group';

export class MenuCategoryItemGroup extends BaseFormModel<MenuCategoryItemGroup> implements Sortable {
    id: number;
    menuItemGroup: MenuItemGroup;
    sortOrder: number;

    getName() {
        return !!this.menuItemGroup ? this.menuItemGroup.name : null;
    }

    init(): void {
        this.menuItemGroup = new MenuItemGroup(this.menuItemGroup);
    }
}
