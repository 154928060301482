import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

@Component({
    template: ''
})
export abstract class SubscriberComponent implements OnDestroy {
    public subscriptions = [];

    protected constructor() {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    subscribe(subscription: Subscription) {
        this.subscriptions.push(subscription);
    }

    getId(index: number, obj: any) {
        return obj.id;
    }
}
