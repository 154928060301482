import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {MembershipTokenAuditService} from '../_services/membership-token-audit.service';

@Injectable()
export class MembershipTokenAuditsResolver implements Resolve<any> {
    constructor(private membershipTokenAuditService: MembershipTokenAuditService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.membershipTokenAuditService.findByMembershipTokenPurseId(route.paramMap.get('membershipTokenPurseId'));
    }
}
