<ion-content>
    <ion-grid class="pin page active-orders"
              [class.active-orders]="receivedWaiting">
        <ion-row>
            <ion-col class="ion-text-end">
                <ion-button color="dark" (click)="logoutOrganization()">
                    <ion-icon name="log-out-outline" slot="icon-only"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="ion-text-center pin-code-placeholder">
                {{formattedPinCode()}}
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="ion-text-center">
                <ion-grid class="keyboard-container">
                    <ion-row>
                        <ion-col class="ion-text-center">
                            <ion-button color="dark" class="pin-button" (click)="typePin('1')">
                                <span>1</span>
                            </ion-button>
                        </ion-col>
                        <ion-col class="ion-text-center">
                            <ion-button color="dark" class="pin-button" (click)="typePin('2')">
                                <span>2</span>
                            </ion-button>
                        </ion-col>
                        <ion-col class="ion-text-center">
                            <ion-button color="dark" class="pin-button" (click)="typePin('3')">
                                <span>3</span>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="ion-text-center">
                            <ion-button color="dark" class="pin-button" (click)="typePin('4')">
                                <span>4</span>
                            </ion-button>
                        </ion-col>
                        <ion-col class="ion-text-center">
                            <ion-button color="dark" class="pin-button" (click)="typePin('5')">
                                <span>5</span>
                            </ion-button>
                        </ion-col>
                        <ion-col class="ion-text-center">
                            <ion-button color="dark" class="pin-button" (click)="typePin('6')">
                                <span>6</span>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="ion-text-center">
                            <ion-button color="dark" class="pin-button" (click)="typePin('7')">
                                <span>7</span>
                            </ion-button>
                        </ion-col>
                        <ion-col class="ion-text-center">
                            <ion-button color="dark" class="pin-button" (click)="typePin('8')">
                                <span>8</span>
                            </ion-button>
                        </ion-col>
                        <ion-col class="ion-text-center">
                            <ion-button color="dark" class="pin-button" (click)="typePin('9')">
                                <span>9</span>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="ion-text-center">
                            <ion-button color="dark" class="pin-button" (click)="pinCode = ''">
                                <ion-icon name="close-circle-outline" class="pin-button-icon"></ion-icon>
                            </ion-button>
                        </ion-col>
                        <ion-col class="ion-text-center">
                            <ion-button color="dark" class="pin-button" (click)="typePin('0')">
                                <span>0</span>
                            </ion-button>
                        </ion-col>
                        <ion-col class="ion-text-center">
                            <ion-button color="dark" class="pin-button" (click)="backspace()">
                                <ion-icon name="backspace-outline" class="pin-button-icon" slot="icon-only"></ion-icon>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
