import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {OrganizationRewardCardService} from '../_services/organization-reward-card.service';

@Injectable()
export class OrganizationRewardCardsResolver implements Resolve<any> {
    constructor(private organizationRewardCardService: OrganizationRewardCardService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.organizationRewardCardService.list();
    }
}
