<modal-header title="{{menuItemInventory.menuItem.name}} Action"
              [subHeader]="!!menuItemInventory.menuItemPrice ? menuItemInventory.menuItemPrice.name : ''"></modal-header>
<ion-content [formGroup]="formGroup">
    <ion-grid>
        <ng-container>
            <ion-row *ngIf="!closing">
                <ion-col class="ion-text-center">
                    <span class="count" color="dark">{{menuItemInventory.currentCount}}</span>
                    <ng-container *ngIf="formGroup.valid">
                        <ion-icon name="arrow-forward-outline" class="count-icon"></ion-icon>
                        <span class="count" color="dark">{{calculatedCount()}}</span>
                    </ng-container>
                </ion-col>

                <ion-col *ngIf="formGroup.controls.transferBackOfHouse.value" class="ion-text-center">
                    <span class="count" color="dark">{{backOfHouseInventory.currentCount}}</span>
                    <ng-container *ngIf="formGroup.valid">
                        <ion-icon name="arrow-forward-outline" class="count-icon"></ion-icon>
                        <span class="count" color="dark">{{calculatedBackOfHouseCount()}}</span>
                    </ng-container>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size-xs="12">
                    <ion-segment *ngIf="!!formGroup"
                                 formControlName="action"
                                 mode="ios">
                        <ion-segment-button value="ADD" class="action-selection">
                            <ion-label>Add</ion-label>
                        </ion-segment-button>
                        <ion-segment-button value="REMOVE" class="action-selection">
                            <ion-label>Remove</ion-label>
                        </ion-segment-button>
                        <ion-segment-button value="COUNT" class="action-selection">
                            <ion-label>Count</ion-label>
                        </ion-segment-button>
                    </ion-segment>
                </ion-col>

                <ion-col *ngIf="!!backOfHouseInventory && (formGroup.controls.action.value === 'ADD' || formGroup.controls.action.value === 'REMOVE')"
                         size-xs="12">
                    <ion-item>
                        <ion-label>{{formGroup.controls.action.value === 'ADD' ? 'From' : 'To'}} Back of House</ion-label>
                        <ion-checkbox formControlName="transferBackOfHouse" slot="start"></ion-checkbox>
                    </ion-item>
                </ion-col>

                <ion-col size-xs="12">
                    <ion-item>
                        <ion-label position="floating">Count</ion-label>
                        <ion-input bbCurrencyMask inputmode="decimal" min="0" name="amount" formControlName="amount" #firstInput></ion-input>
                    </ion-item>
                </ion-col>

                <ion-col size-xs="12">
                    <ion-item>
                        <ion-label position="floating">Comment</ion-label>
                        <ion-textarea rows="5" formControlName="comment"></ion-textarea>
                    </ion-item>
                </ion-col>
            </ion-row>
        </ng-container>
    </ion-grid>
</ion-content>
<modal-footer [disableSubmit]="formGroup.invalid" submitLabel="Apply" (submitClicked)="addAction()"></modal-footer>
