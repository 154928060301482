import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {BaseService} from './base-service';
import {Constants} from '../_constants/constants';
import {CashSessionTipAdjustment} from '../_models/cash-information/cash-session-tip-adjustment';

@Injectable({
    providedIn: 'root'
})
export class CashSessionTipAdjustmentService extends BaseService<CashSessionTipAdjustment> {
    destination = 'cash-session-tip-adjustments';

    create(cashSessionTipAdjustment: CashSessionTipAdjustment) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409');
        return this.http.post(this.baseUrl(), cashSessionTipAdjustment, {headers});
    }
}
