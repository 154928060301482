import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    isLoading = false;
    loading = [];

    constructor(private loadingController: LoadingController) {
    }

    present(message = null) {
        this.isLoading = true;
        const options: any = {
            spinner: 'crescent',
            cssClass: 'bb-loading-spinner'
        };
        if (!!message) {
            options.message = message;
        }
        this.loadingController.create(options).then(a => {
            a.present().then(() => {
                if (!this.isLoading) {
                    a.dismiss().then(() => {
                    });
                } else {
                    this.loading.push(a);
                }
            });
        });
    }

    dismiss() {
        this.isLoading = false;
        if (this.loading.length > 0) {
            this.loading.forEach(l => l.dismiss());
        }
    }
}
