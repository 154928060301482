<ion-list>
    <ion-item *ngIf="!cards || cards.length == 0">
        <ion-text>Payment method not found.</ion-text>
    </ion-item>
    <bb-removable-item *ngFor="let card of cards; trackBy: getId; let cardIndex = index;"
                       [disableSliding]="true"
                       (removeItem)="remove(card.id, cardIndex)">
        <ion-item class="pointer ion-text-center" lines="none">
            <bb-credit-card-summary class="payment-item" [card]="card"
                                    buttonText="Set Default"
                                    [hideButton]="card.defaultSource"
                                    [selected]="card.defaultSource"
                                    (action)="setAsDefault(card)">
            </bb-credit-card-summary>
        </ion-item>
    </bb-removable-item>
</ion-list>
