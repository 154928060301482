import {BaseFormModel} from './base-form-model';
import {Sortable} from './sortable';
import {PickupLocationMenuItemPrice} from './pickup-location-menu-item-price';
import {Availabilities} from '../_constants/availabilities';
import {MenuItemInventory} from './menu-item-inventory';

export class MenuItemPrice extends BaseFormModel<MenuItemPrice> implements Sortable {
    id: number;
    ancestorId: number;
    name: string;
    sku: string;
    price: number;
    volume: number;
    sortOrder: number;
    defaultPrice: boolean;
    availability: string;
    terminalOnly: boolean;
    variable: boolean;
    percentageOfParent: boolean;
    tipPool: boolean;
    deposit: boolean;

    qualifiedItem = false;
    rewardItem = false;
    compDefinitionIds: number[];
    tokenGroupIds: number[];

    menuItemInventory: MenuItemInventory;
    pickupLocationMenuItemPrice: PickupLocationMenuItemPrice;

    tokenPrice: number;
    membershipTokenGroupId: number;
    countLimit: number;

    getName() {
        return this.name;
    }

    allowMobileOrdering() {
        if (this.availability === Availabilities.NONE || this.terminalOnly) {
            return false;
        } else if (!!this.pickupLocationMenuItemPrice) {
            return !this.pickupLocationMenuItemPrice.terminalOnly;
        } else {
            return true;
        }
    }

    allowInHouse() {
        if (this.availability === Availabilities.NONE || this.availability === Availabilities.TOGO) {
            return false;
        } else if (!!this.pickupLocationMenuItemPrice) {
            return this.pickupLocationMenuItemPrice.allowInHouse;
        } else {
            return true;
        }
    }

    allowToGo() {
        if (this.availability === Availabilities.NONE || this.availability === Availabilities.IN_HOUSE) {
            return false;
        } else if (!!this.pickupLocationMenuItemPrice) {
            return this.pickupLocationMenuItemPrice.allowTogo;
        } else {
            return true;
        }
    }
}
