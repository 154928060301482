<ion-grid class="filter-list-wrapper">
    <ion-row class="filter-list-header">
        <ion-col>
            <ion-item>
                <ion-searchbar [id]="id + '_searchbar'"
                               [placeholder]="placeholder"
                               showCancelButton="focus"
                               [(ngModel)]="searchString"
                               (ionChange)="filterItems()"
                               (ionFocus)="focus()"
                               (ionBlur)="blurred()"
                               (ionCancel)="searchString = ''" #searchBar>
                </ion-searchbar>
                <ion-button [id]="id + '_addNew'" slot="end" color="success" size="default" (click)="openAddItem()">
                    Create
                </ion-button>
            </ion-item>
        </ion-col>
    </ion-row>
    <ion-row class="filter-list-body" (mousedown)="preventBlur()">
        <ion-col>
            <ion-list [id]="id + '_searchResults'" *ngIf="!!searchString || focused">
                <ion-item class="selectable-item" *ngFor="let item of filteredItems" (click)="selectItem(item)">
                    <div class="display">
                        <ng-template [ngTemplateOutlet]="searchListTemplate" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
                    </div>
                </ion-item>
            </ion-list>

            <ion-list [id]="id + '_selected'" *ngIf="!searchString && !focused">
                <ion-item *ngIf="selected.length < 1">None selected</ion-item>
                <ion-reorder-group (ionItemReorder)="reorder($event)" disabled="false">
                    <ion-item *ngFor="let item of selected">
                        <div class="display">
                            <ng-template [ngTemplateOutlet]="displayTemplate" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
                        </div>
                        <div class="actions">
                            <ion-icon name="trash-outline" (click)="remove(item)"></ion-icon>
                        </div>
                        <ion-reorder *ngIf="sortable && selected.length > 1" slot="end"></ion-reorder>
                    </ion-item>
                </ion-reorder-group>
            </ion-list>
        </ion-col>
    </ion-row>
</ion-grid>
