import {MenuLabel} from './menu-label';
import {MenuCategory} from './menu-category';
import {MenuTopping} from './menu-topping';
import {MenuItem} from './menu-item';
import {MenuSelection} from './menu-selection';
import {MenuTaxLabel} from './menu-tax-label';
import {BaseFormModel} from './base-form-model';
import {MenuMarqueeItem} from './menu-marquee-item';
import {MenuItemGroup} from './menu-item-group';

export class Menu extends BaseFormModel<Menu> {

    id: number;
    orgId: number;
    menuCategories: MenuCategory[];
    menuLabels: MenuLabel[];
    menuTaxLabels: MenuTaxLabel[];
    menuItems: MenuItem[];
    menuItemGroups: MenuItemGroup[];
    menuToppings: MenuTopping[];
    menuSelections: MenuSelection[];
    menuMarqueeItems: MenuMarqueeItem[];

    init(): void {
        let temp = [];
        if (!!this.menuItems) {
            this.menuItems.forEach(mi => {
                temp.push(new MenuItem(mi));
            });
            this.menuItems = temp;
        }

        temp = [];
        if (!!this.menuCategories) {
            this.menuCategories.forEach(mc => {
                temp.push(new MenuCategory(mc));
            });
            this.menuCategories = temp;
        }

        temp = [];
        if (!!this.menuMarqueeItems) {
            this.menuMarqueeItems.forEach(mc => {
                temp.push(new MenuMarqueeItem(mc));
            });
            this.menuMarqueeItems = temp;
        }
    }
}
