import {BaseFormModel} from './base-form-model';
import {Organization} from './organization';
import {Address} from './address';
import {OrganizationEvent} from './organization-event';

export class PickupLocation extends BaseFormModel<PickupLocation> {
    id: number;
    organization: Organization;
    name: string;
    phone: string;
    direction: string;
    imageAddress: string;
    availableMarkers: string;
    address: Address;
    status: string;
    mobile: boolean;
    printAllOrders: boolean;
    activeEvent: OrganizationEvent;

    orgId: number;
    organizationName: string;
    orgLogo: string;
    distance: number;
    latitude: number;
    longitude: number;
}
