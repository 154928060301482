export class Constants {
    public static SILENT = 'SILENT';
    public static IGNORE_ERROR_CODES = 'ignoreErrorCodes';
    public static SERVER_ERROR = 'A server error occurred.';
    public static TIME_OUT_ERROR = 'Request timed out.';
    public static NOT_AUTHORIZED_ERROR = 'Not authorized.';
    public static STAX_API_URL = 'https://staxjs.staxpayments.com/stax.js?nocache=2';
    public static GLOBAL_PAY_API_URL = 'https://js.paygateway.com/secure_payment/v1/globalpayments.js';
    public static LIVEAGENT_API_URL = 'https://brewbill.ladesk.com/scripts/track.js';
    public static LIVEAGENT_KB_URL = 'https://brewbill.ladesk.com/';
    public static CHART_CONFIG = {
        backgroundColor: [
            '#fddb31',
            '#B87333',
            '#52e5ff',
            '#ff0000',
            '#9e3dff',
            '#f1b6cd',
            '#7c0505',
            '#ec7e33',
            '#b8e645',
            '#080373',
            '#e733de',
            '#f4f1c1',
            '#a4f5bd',
            '#44958c',
            '#956027',
            '#f5f5f5',
            '#030303'
        ],
        borderWidth: .5
    };

    public static AGE_VERIFIED = 'ageVerified';

    public static REUSE_ROUTES = ['manual-order', 'tabs', 'history', 'open'];
}
