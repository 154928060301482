import {Inject, Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {OrderItem} from '../_models/order-item';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';
import {MenuItem} from '../_models/menu-item';
import {MenuItemPrice} from '../_models/menu-item-price';
import {OrderItemSelection} from '../_models/order-item-selection';
import {OrderItemSelectionItem} from '../_models/order-item-selection-item';
import {Availabilities} from '../_constants/availabilities';
import {OrderItemTopping} from '../_models/order-item-topping';

@Injectable({
    providedIn: 'root'
})
export class OrderItemService extends BaseService<OrderItem> {
    destination = 'order-items';

    private orderItems: Observable<OrderItem[]>;
    private orderItemsSubject: BehaviorSubject<OrderItem[]>;

    constructor(
        http: HttpClient,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);
        this.orderItemsSubject = new BehaviorSubject<Array<OrderItem>>(new Array<OrderItem>());
        this.orderItems = this.orderItemsSubject.asObservable();
    }

    getTab(orderItemId) {
        return this.http.get(`${this.baseUrl()}/${orderItemId}/tab`);
    }

    initializeOrderItem(menuItem: MenuItem, updateItem?: OrderItem) {
        const orderItem = new OrderItem(!!updateItem ? updateItem : {});
        orderItem.menuItem = menuItem;

        if (!!menuItem) {
            orderItem.includedToppings = menuItem.toppings ?
                menuItem.toppings.filter((topping) => (topping.included && topping.topping.available))
                    .map(mit => {
                        const orderItemTopping = new OrderItemTopping();
                        orderItemTopping.menuItemTopping = mit;
                        return orderItemTopping;
                    }) : [];

            orderItem.includedToppings.forEach((topping) => {
                const found = !!updateItem && !!updateItem.includedToppings
                    ? updateItem.includedToppings.find(t => t.menuItemTopping.id === topping.menuItemTopping.id) : null;
                topping.included = !found || found.included;
            });

            orderItem.optionalToppings = menuItem.toppings ?
                menuItem.toppings.filter((topping) => (!topping.included && topping.topping.available))
                    .map(mit => {
                        const orderItemTopping = new OrderItemTopping();
                        orderItemTopping.menuItemTopping = mit;
                        return orderItemTopping;
                    }) : [];

            orderItem.optionalToppings.forEach((topping) => {
                const found = !!updateItem && !!updateItem.optionalToppings
                    ? updateItem.optionalToppings.find(t => t.menuItemTopping.id === topping.menuItemTopping.id) : null;
                topping.included = found && found.included;
            });

            if (!updateItem || !updateItem.selectedPrice) {
                const defaultPrice = menuItem.prices && menuItem.prices.filter(price => price.defaultPrice);
                orderItem.selectedPrice = (defaultPrice && defaultPrice.length > 0)
                    ? defaultPrice[0] : (menuItem.prices ? menuItem.prices[0] : new MenuItemPrice());
            } else {
                orderItem.selectedPrice = menuItem.prices.find(p => p.id === updateItem.selectedPrice.id);

                if (orderItem.selectedPrice.variable) {
                    orderItem.selectedPrice.price = orderItem.priceOverride;
                }
            }

            orderItem.selections = [];
            if (menuItem.selections) {
                menuItem.selections.forEach(menuItemSelection => {
                    if (!!updateItem) {
                        const found = updateItem.selections.find(s => s.menuItemSelection.id === menuItemSelection.id);
                        if (!!found) {
                            const selection = new OrderItemSelection(found);
                            selection.selectedItems.forEach(i => {
                                const menuSelectionItem = menuItemSelection.menuSelection.options
                                    .find(opt => opt.menuItem.id === i.orderItem.menuItem.id);
                                i.orderItem = this.initializeOrderItem(menuSelectionItem.menuItem, i.orderItem);
                            });
                            orderItem.selections.push(selection);
                        }
                    } else {
                        const selection = new OrderItemSelection({});
                        selection.menuItemSelection = menuItemSelection;

                        selection.selectedItems = [];
                        menuItemSelection.menuSelection.options.filter(opt => {
                            return opt.defaultSelection && opt.menuItem.availability !== Availabilities.NONE;
                        }).forEach(menuSelectionItem => {
                            const item = new OrderItemSelectionItem({});
                            item.menuSelectionItem = menuSelectionItem;
                            item.orderItem = this.initializeOrderItem(menuSelectionItem.menuItem);
                            selection.selectedItems.push(item);
                        });
                        orderItem.selections.push(selection);
                    }
                });
            }
        }
        orderItem.credits = [];
        return orderItem;
    }

    public count(): number {
        return this.orderItemsSubject.getValue().length;
    }

    public getCurrent(): OrderItem {
        const items: OrderItem[] = this.orderItemsSubject.getValue();
        return (items.length > 0) ? items[items.length - 1] : null;
    }

    public removeCurrent() {
        const items: OrderItem[] = this.orderItemsSubject.getValue();
        if (items && items.length > 0) {
            items.pop();
        }
        this.orderItemsSubject.next(items);
    }

    public add(orderItem: OrderItem) {
        const current = this.getCurrent();
        if (current == null || orderItem.menuItem.id !== current.menuItem.id) {
            this.orderItemsSubject.getValue().push(orderItem);
        }
    }

    public clear() {
        this.orderItemsSubject.next(new Array<OrderItem>());
    }
}
