<modal-header title="Edit Details"></modal-header>

<ion-content>
    <mat-horizontal-stepper labelPosition="bottom" #stepper>
        <mat-step>
            <ng-template matStepLabel>Information</ng-template>
            <form [formGroup]="editOrganizationForm">
                <bb-organization-form (formReady)="formInitialized($event)" [organization]="organization"></bb-organization-form>
            </form>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Socials</ng-template>
            <ion-grid [formGroup]="formGroup">
                <ion-row>
                    <ion-col size-lg="6" size-md="12">
                        <ion-item>
                            <ion-label position="floating">Facebook</ion-label>
                            <ion-input type="text" formControlName="facebook"></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col size-lg="6" size-md="12">
                        <ion-item>
                            <ion-label position="floating">Instagram</ion-label>
                            <ion-input type="text" formControlName="instagram"></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col size-lg="6" size-md="12">
                        <ion-item>
                            <ion-label position="floating">Twitter</ion-label>
                            <ion-input type="text" formControlName="twitter"></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Messaging</ng-template>
            <ion-grid [formGroup]="formGroup">
                <ion-col size-lg="6" size-xs="12">
                    <ion-item>
                        <ion-label position="floating">Description</ion-label>
                        <ion-textarea rows="5" formControlName="description"></ion-textarea>
                    </ion-item>
                </ion-col>
                <ion-col size-lg="6" size-xs="12">
                    <ion-item>
                        <ion-label position="floating">Receipt Message</ion-label>
                        <ion-textarea rows="5" formControlName="receiptTag"></ion-textarea>
                    </ion-item>
                </ion-col>
            </ion-grid>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Configuration</ng-template>
            <ion-grid [formGroup]="formGroup">
                <ion-row>
                    <ion-col size-xs="12" size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Allow Order Notes</ion-label>
                            <ion-toggle class="ion-toggle-small" formControlName="allowOrderNotes"></ion-toggle>
                        </ion-item>
                    </ion-col>
                    <ion-col size-xs="12" size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Allow Tax Exemption</ion-label>
                            <ion-toggle class="ion-toggle-small" formControlName="allowTaxExempt"></ion-toggle>
                        </ion-item>
                    </ion-col>
                    <ion-col size-xs="12" size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Allow Invoicing</ion-label>
                            <ion-toggle class="ion-toggle-small" formControlName="allowInvoices"></ion-toggle>
                        </ion-item>
                    </ion-col>
                    <ion-col size-xs="12" size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Allow Marketing Email Request</ion-label>
                            <ion-toggle class="ion-toggle-small" formControlName="allowMarketingEmailRequest"></ion-toggle>
                        </ion-item>
                    </ion-col>
                    <ion-col size-xs="12" size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Require Card When Closing Tab</ion-label>
                            <ion-toggle class="ion-toggle-small" formControlName="requireCardAtClose"></ion-toggle>
                        </ion-item>
                    </ion-col>
                    <ion-col size-xs="12" size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Allow Quick Close All Tabs</ion-label>
                            <ion-toggle class="ion-toggle-small" formControlName="allowQuickCloseAllTabs"></ion-toggle>
                        </ion-item>
                    </ion-col>
                    <ion-col size-xs="12" size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Auto Mark Orders Delivered</ion-label>
                            <ion-toggle class="ion-toggle-small" formControlName="autoDeliver"></ion-toggle>
                        </ion-item>
                    </ion-col>
                    <ion-col size-xs="12" size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Auto Print Claim Tab</ion-label>
                            <ion-toggle class="ion-toggle-small" formControlName="autoPrintClaimTab"></ion-toggle>
                        </ion-item>
                    </ion-col>
                    <ion-col size-xs="12" size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Hide Service Options</ion-label>
                            <ion-toggle class="ion-toggle-small" formControlName="hideServiceOptions"></ion-toggle>
                        </ion-item>
                    </ion-col>
                    <ion-col size-xs="12" size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Prevent Drawer Copy</ion-label>
                            <ion-toggle class="ion-toggle-small" formControlName="preventDrawerCopy"></ion-toggle>
                        </ion-item>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Tipping</ng-template>
            <ion-grid [formGroup]="formGroup">
                <ion-row>
                    <ion-col size-xs="12" size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Allow Tips</ion-label>
                            <ion-toggle class="ion-toggle-small" formControlName="allowTips"></ion-toggle>
                        </ion-item>
                    </ion-col>
                    <ion-col size-xs="12" size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Tip Out Type</ion-label>
                            <ion-select formControlName="tipOutType" interface="popover">
                                <ion-select-option value="{{tipOutTypes.POOLED}}">Pooled</ion-select-option>
                                <ion-select-option value="{{tipOutTypes.PROGRESSIVE}}">Progressive</ion-select-option>
                                <ion-select-option value="{{tipOutTypes.PAY_PERIOD_POOLED}}">Pooled By Pay Period</ion-select-option>
                            </ion-select>
                        </ion-item>
                    </ion-col>

                    <ion-col *ngIf="formGroup.controls.tipOutType.value === tipOutTypes.PAY_PERIOD_POOLED && !!safes && safes.length > 0" size-xs="12"
                             size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="stacked">Auto Transfer Safe</ion-label>
                            <ion-select formControlName="autoTransferDrawer" interface="popover">
                                <ion-select-option value="">None</ion-select-option>
                                <ion-select-option *ngFor="let safe of safes" [value]="safe">{{ safe.name }}</ion-select-option>
                            </ion-select>
                        </ion-item>
                    </ion-col>

                    <ion-col *ngIf="formGroup.controls.tipOutType.value === tipOutTypes.PAY_PERIOD_POOLED && !!safes && safes.length > 0" size-xs="12"
                             size-md="6" size-lg="6">
                        <ion-item>
                            <ion-label position="floating">Auto Transfer Final Drawer</ion-label>
                            <ion-input type="text" name="autoFinalAmount" formControlName="autoFinalAmount" bbNumberMask
                                       inputmode="numeric"></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-item>
                            <ion-label position="stacked">Tip 1</ion-label>
                            <ion-input type="text" name="tip1" formControlName="tip1" bbNumberMask inputmode="numeric"></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col>
                        <ion-item>
                            <ion-label position="stacked">Tip 2</ion-label>
                            <ion-input type="text" name="tip2" formControlName="tip2" bbNumberMask inputmode="numeric"></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col>
                        <ion-item>
                            <ion-label position="stacked">Tip 3</ion-label>
                            <ion-input type="text" name="tip3" formControlName="tip3" bbNumberMask inputmode="numeric"></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col>
                        <ion-item>
                            <ion-label position="stacked">Tip 4</ion-label>
                            <ion-input type="text" name="tip4" formControlName="tip4" bbNumberMask inputmode="numeric"></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </mat-step>
    </mat-horizontal-stepper>
</ion-content>

<modal-footer [disableSubmit]="editOrganizationForm.invalid" (submitClicked)="onSubmit()"></modal-footer>
