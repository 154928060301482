<ion-grid>
    <ion-row>
        <ion-col *ngFor="let program of membershipPrograms; trackBy: getId" size-xs="12" size-md="6" size-lg="4">
            <ion-card>
                <ion-card-header>
                    <div class="program-header-container">
                        <div class="program-header">
                            <h1 class="program-title">{{ program.name }}</h1>
                            <h1 class="program-cost">{{ program.amount | currency }}</h1>
                        </div>
                        <p class="sub-header" *ngIf="!!program.tokens">
                            <bb-tokens [count]="program.tokens"></bb-tokens>
                        </p>
                    </div>
                </ion-card-header>
                <ion-card-content>
                    <ion-grid>
                        <ion-row>
                            <ion-col class="description">
                                {{ program.description }}
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-button class="full-footer-button"
                                            color="dark"
                                            (click)="selectedProgram.emit(program)">
                                    Join
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-card-content>
            </ion-card>
        </ion-col>
    </ion-row>
</ion-grid>
