import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseModalComponent} from '../base-modal.component';

@Component({
    selector: 'bb-popover-rating',
    templateUrl: './rating-popover.component.html',
    styleUrls: ['./rating-popover.component.css']
})
export class RatingPopoverComponent extends BaseModalComponent implements OnInit {
    @Input() title: string;
    @Input() image: string;
    @Input() message: string;
    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            rating: [null, Validators.required],
            comments: ['', Validators.maxLength(2000)]
        });
    }

    rate(rate) {
        this.formGroup.controls.rating.setValue(rate);
    }

    async submit() {
        await this.close(this.formGroup.value);
    }

    async cancel() {
        await this.close();
    }
}
