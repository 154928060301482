import {Pipe, PipeTransform} from '@angular/core';
import {CashAuditEvents} from '../../_constants/cash-audit-events';

@Pipe({
    name: 'cashAuditEvent'
})
export class CashAuditEventPipe implements PipeTransform {
    transform(value: string, lowercase: boolean = false): string {
        let outString = '';
        switch (value) {
        case CashAuditEvents.BANK_DEPOSIT:
            outString = 'Bank Deposit';
            break;
        case CashAuditEvents.PAYMENT:
            outString = 'Payment';
            break;
        case CashAuditEvents.ADD:
            outString = 'Add';
            break;
        case CashAuditEvents.GIFT_CARD_PURCHASE:
            outString = 'Gift Card Purchase';
            break;
        case CashAuditEvents.PAYOUT:
            outString = 'Payout';
            break;
        case CashAuditEvents.REFUND:
            outString = 'Refund';
            break;
        case CashAuditEvents.MISC:
            outString = 'Misc';
            break;
        case CashAuditEvents.TIP_OUT:
            outString = 'Tip Out';
            break;
        case CashAuditEvents.TRANSFER:
            outString = 'Transfer';
            break;
        case CashAuditEvents.TIP_ADJUSTMENT:
            outString = 'Tip Adjustment';
            break;
        default:
            outString = '';
            break;
        }
        return lowercase ? outString.toLowerCase() : outString;
    }
}
