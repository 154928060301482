import {BaseFormModel} from '../base-form-model';

export class CashDrawerSummary extends BaseFormModel<CashDrawerSummary> {
    id: number;
    name: string;
    pickupLocationName: string;
    cashSessionId: number;
    organizationId: number;
    safeAccess: string;

    startTime: Date;
    startedByName: Date;
    endTime: Date;
    endedByName: Date;

    startAmount: number;
    endAmount: number;
    netTransfers: number;
    inActionCount: number;
    inPrincipal: number;
    inTip: number;
    actionCount: number;
    principal: number;
    tip: number;
    expectedTotal: number;
    delta: number;
    status: string;
    trackCashTips: boolean;
}
