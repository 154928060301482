import {BaseFormModel} from '../base-form-model';
import {Column} from './column';
import {RowResult} from './row-result';
import {Sort} from './sort';

export class TabReportResult extends BaseFormModel<TabReportResult> {
    title: string;
    sort: Sort[];
    columns: Column[];
    data: RowResult[];
    rowKey: string;
    totalElements: number;
    totalPages: number;
    summaryRow: RowResult;
}
