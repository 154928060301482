export class OrganizationRewardProgramCardSummary {
    id: number;
    organizationId: number;
    organizationName: string;
    organizationLogoAddress: string;
    remaining: number;
    used: number;
    tabUsed: number;
    createDate: Date;
    goal: number;
    type: string;
    reward: number;
    rewardType: string;
    rewardName: string;
    status: string;
}
