import {BaseFormModel} from './base-form-model';

export class TimeSegmentReportSummary extends BaseFormModel<TimeSegmentReportSummary> {
    name: string;
    count: number;
    creditTotal: number;
    total: number;
    timeSegment: number;
    year: number;
}
