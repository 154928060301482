<modal-header title="Upload Image"></modal-header>

<ion-content>
    <ion-grid>
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
            <ion-row [formGroup]="formGroup">
                <ion-col size-xs="12">
                    <ion-item>
                        <ion-label position="stacked">Image</ion-label>
                        <ion-input type="file" formControlName="uploadFile" accept="image/*" (change)="uploadFile($event)"></ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <image-cropper
                            [imageChangedEvent]="imageChangedEvent"
                            [maintainAspectRatio]="true"
                            [aspectRatio]="1"
                            [onlyScaleDown]="true"
                            [alignImage]="'center'"
                            [resizeToHeight]="400"
                            [style.display]="showCropper ? null : 'none'"
                            [style.max-height]="'410px'"
                            format="png"
                            (imageCropped)="imageCropped($event)"
                            (imageLoaded)="imageLoaded()"
                            (cropperReady)="cropperReady($event)"
                            (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                </ion-col>
            </ion-row>
        </form>
    </ion-grid>
</ion-content>

<modal-footer [disableSubmit]="formGroup.invalid" (submitClicked)="onSubmit()"></modal-footer>
