import {Injectable} from '@angular/core';
import {BaseObservableService} from './base-obeservable-service';
import {MembershipTokenAudit} from '../_models/membership-token-audit';

@Injectable({
    providedIn: 'root'
})
export class MembershipTokenAuditService extends BaseObservableService<MembershipTokenAudit> {
    destination = 'membership-token-audits';

    findByMembershipTokenPurseId(tokenPurseId: number | string) {
        return this.http.get(this.baseUrl() + `/membership-token-purse/${tokenPurseId}`);
    }
}
