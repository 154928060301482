<modal-header title="Forgot Password"></modal-header>

<ion-content>
    <ion-grid>
        <ion-row class="justify-content-center">
            <ion-col class="align-self-center" size-xs="12">
                <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
                    <div class="padding">
                        <ion-item>
                            <ion-label position="floating">Email</ion-label>
                            <ion-input type="email" formControlName="email"></ion-input>
                        </ion-item>
                    </div>
                </form>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

<modal-footer (submitClicked)="onSubmit()" [disableSubmit]="forgotPasswordForm.invalid" submitLabel="Send Password"></modal-footer>
