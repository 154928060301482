import {BaseFormModel} from './base-form-model';
import {Person} from './person';
import {MenuItemPriceSummary} from './menu-item-price-summary';

export class MembershipTokenGroup extends BaseFormModel<MembershipTokenGroup> {
    id: number;
    orgId: number;
    name: string;
    description: string;
    tokenCount: number;
    items: MenuItemPriceSummary[];
    limitDurationPart: string;
    limitDurationCount: number;
    limitCount: number;
    createdBy: Person;
    createDate: Date;
}
