<modal-header title="Add Comp"></modal-header>

<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col size-xs="6">
                <h1>Remaining: {{ full | currency }}</h1>
            </ion-col>

            <ion-col size-xs="6" *ngIf="!!compDefinition && compDefinition.max > 0 && compDefinition.max < full">
                <h1>Max Comp: {{ compDefinition.max | currency }}</h1>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!compDefinition || !compDefinition.max || compDefinition.max > half">
            <ion-col size-xs="12">
                <ion-segment mode="ios"
                             [(ngModel)]="selectedCredit"
                             (ionChange)="changedSelected()">
                    <ion-segment-button [value]="50">
                        <ion-label>50%</ion-label>
                    </ion-segment-button>
                    <ion-segment-button [value]="100" *ngIf="!compDefinition || !compDefinition.max || compDefinition.max > full">
                        <ion-label>100%</ion-label>
                    </ion-segment-button>
                    <ion-segment-button [value]="-1" (click)="setPercent()">
                        <ion-label *ngIf="!!percent">{{ percent }}%</ion-label>
                        <ion-label *ngIf="!percent">Custom</ion-label>
                    </ion-segment-button>
                </ion-segment>
            </ion-col>
        </ion-row>
    </ion-grid>
    <ion-grid [formGroup]="creditForm" (ngSubmit)="onSubmit()">
        <ion-row>
            <ion-col size-xs="12" size-md="6">
                <ion-item>
                    <ion-label position="floating">Reason</ion-label>
                    <ion-select formControlName="type" interface="popover">
                        <ion-select-option value="UNSATISFACTORY">{{ 'UNSATISFACTORY' | creditType }}</ion-select-option>
                        <ion-select-option value="INCORRECT_ITEM">{{ 'INCORRECT_ITEM' | creditType }}</ion-select-option>
                        <ion-select-option value="POOR_SERVICE">{{ 'POOR_SERVICE' | creditType }}</ion-select-option>
                        <ion-select-option value="LATE">{{ 'LATE' | creditType }}</ion-select-option>
                        <ion-select-option value="NEVER_RECEIVED">{{ 'NEVER_RECEIVED' | creditType }}</ion-select-option>
                        <ion-select-option value="DUPLICATE">{{ 'DUPLICATE' | creditType }}</ion-select-option>
                        <ion-select-option value="FRAUDULENT">{{ 'FRAUDULENT' | creditType }}</ion-select-option>
                        <ion-select-option value="REWARD">{{ 'REWARD' | creditType }}</ion-select-option>
                        <ion-select-option value="OTHER">{{ 'OTHER' | creditType }}</ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
            <ion-col size-xs="12" size-md="6">
                <ion-item>
                    <ion-label position="floating">Amount</ion-label>
                    <ion-input inputmode="numeric" formControlName="amount" bbCurrencyMask
                               (keyup)="manualEntry()"
                               [max]="itemsTotal"
                               [min]="0" #firstInput></ion-input>
                </ion-item>
            </ion-col>
            <ion-col size-xs="12">
                <ion-item>
                    <ion-label position="floating">Comment</ion-label>
                    <ion-textarea rows="5" formControlName="comment"></ion-textarea>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

<modal-footer (submitClicked)="onSubmit()" [disableSubmit]="creditForm.invalid"></modal-footer>
