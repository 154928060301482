import {BaseFormModel} from './base-form-model';
import {Person} from './person';
import {PickupLocation} from './pickup-location';

export class MenuItemInventoryAction extends BaseFormModel<MenuItemInventoryAction> {
    id: number;
    action: string;
    createdBy: Person;
    createDate: Date;
    comment: string;
    amount: number;

    pickupLocation: PickupLocation;
}
