import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DisplayOrderItemComponent} from '../display-order-item/display-order-item.component';
import {OrderItem} from '../../_models/order-item';

@Component({
    selector: 'bb-compact-order-item',
    templateUrl: './compact-order-item.component.html',
    styleUrls: ['./compact-order-item.component.scss'],
})
export class CompactOrderItemComponent extends DisplayOrderItemComponent implements OnChanges {
    @Input() items: OrderItem[];
    @Input() details = false;
    @Output() expandChanged: EventEmitter<any> = new EventEmitter<any>();
    expanded = false;
    allSelected = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.selectedItemIds && !!changes.selectedItemIds.currentValue) {
            this.allSelected = this.checkSelection();
        }
    }

    toggleExpanded() {
        this.expanded = !this.expanded;
        this.expandChanged.emit();

        if (!this.expanded && !this.allSelected) {
            this.itemsSelected.emit([]);
        }
    }

    checkSelection() {
        return !!this.selectedItemIds
            && this.items
            && this.selectedItemIds.length > 0
            && this.items.every(i => this.selectedItemIds.includes(i.id));
    }
}
