<bb-display-order-item [itemSummary]="itemSummary"
                       [level]="1"
                       [details]="details"
                       [count]="itemSummary.items.length"
                       [editable]="editable"
                       [orderStatus]="orderStatus"
                       [availableOrderComps]="availableOrderComps"
                       [showImageOverride]="showImageOverride"
                       (addCredit)="addCredit.emit($event)"
                       (removeCredit)="removeCredit.emit($event)"
                       (removeTokens)="removeTokens.emit($event)"
                       (updateItem)="updateItem.emit($event)"
                       (add)="add.emit($event)"
                       (remove)="remove.emit($event)"
                       (click)="itemsSelected.emit(itemSummary.items)"
                       (imageLoaded)="imageLoaded.emit()">
</bb-display-order-item>
