import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DashboardWidget} from '../../../_models/dashboard-widget';
import {SubscriberComponent} from '../../subscriber.component';
import {DashboardWidgetTab} from '../../../_models/dashboard-widget-tab';
import {offset} from '../../../_utils/date-utils';
import moment from 'moment/moment';
import {BaseWidgetComponent} from '../base-widget.component';
import {Organization} from '../../../_models/organization';

@Component({
    selector: 'bb-dashboard-widget',
    templateUrl: './dashboard-widget.component.html',
    styleUrls: ['./dashboard-widget.component.scss'],
})
export class DashboardWidgetComponent extends SubscriberComponent implements OnChanges {
    @Input() widget: DashboardWidget;
    @Input() startDate: string;
    @Input() endDate: string;
    @Input() selectedCategories: number[];
    @Input() selectedPickupLocations: number[];
    @Input() organization: Organization;

    @Output() remove = new EventEmitter();
    @Output() edit = new EventEmitter();
    @Output() dataReady = new EventEmitter();

    @ViewChild('widgetComponent') widgetComponent: BaseWidgetComponent;

    currentTab: DashboardWidgetTab;
    loading = true;
    calculatedEndDate: string;
    calculatedStartDate: string;

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.currentTab) {
            this.currentTab = this.widget.tabs[0];
        }

        if (!!changes.endDate) {
            this.updateStartDate();
        }
    }

    updateStartDate() {
        if (!!this.endDate && !!this.currentTab && !!this.currentTab.durationPart && !!this.currentTab.durationCount) {
            const dateSetter = moment(this.endDate);
            switch (this.currentTab.durationPart) {
            case 'WEEK':
                this.calculatedEndDate = dateSetter.startOf('week').add(1, 'day').add(1, 'week').format();
                break;
            case 'MONTH':
                this.calculatedEndDate = dateSetter.startOf('month').add(1, 'month').format();
                break;
            case 'YEAR':
                this.calculatedEndDate = dateSetter.startOf('year').add(1, 'year').format();
                break;
            default:
                this.calculatedEndDate = dateSetter.format();
            }
            this.calculatedStartDate = offset(this.calculatedEndDate, this.currentTab.durationPart, this.currentTab.durationCount);
        }
    }

    editClicked(event) {
        event.stopPropagation();
        this.edit.emit();
    }

    removeClicked(event) {
        event.stopPropagation();
        this.remove.emit();
    }

    loaded() {
        this.loading = false;
        this.dataReady.emit();
    }

    refresh() {
        if (!!this.widgetComponent) {
            this.widgetComponent.refresh();
        }
    }
}
