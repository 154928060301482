import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Venue} from '../../_models/venue';
import {BaseMapComponent} from '../base-map.component';
import {UserService} from '../../_services/user.service';
import {BaseEventService} from '../../_services/base-event.service';
import {GeoLocationService} from '../../_services/geo-location.service';
import {PickupLocationService} from '../../_services/pickup-location.service';

@Component({
    selector: 'bb-venue-map',
    templateUrl: './venue-map.component.html',
    styleUrls: ['./venue-map.component.css'],
})
export class VenueMapComponent extends BaseMapComponent implements OnInit, OnChanges {
    @Input() venue: Venue;
    @Output() mapClick: EventEmitter<any> = new EventEmitter();
    @Output() markerClick: EventEmitter<any> = new EventEmitter();

    constructor(
        protected eventService: BaseEventService,
        protected userService: UserService,
        protected geolocationService: GeoLocationService,
        protected pickupLocationService: PickupLocationService
    ) {
        super(eventService, userService, pickupLocationService, geolocationService);
    }

    ngOnInit() {
        this.options.restriction = {
            latLngBounds: {
                north: this.venue.north,
                south: this.venue.south,
                east: this.venue.east,
                west: this.venue.west
            },
            strictBounds: false,
        };

        this.options.tilt = 0;
        this.options.zoomControl = false;
        this.options.rotateControl = false;

        const pickupLocations = this.venue.organizations.reduce((flat, o) => {
            o.pickupLocations.forEach(p => p.organization = o);
            return flat.concat(o.pickupLocations);
        }, []);
        pickupLocations.forEach(l => this.markers.push(this.pickupLocationService.buildMarker(this.map, l)));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes.venue.previousValue) {
            const pickupLocations = this.venue.organizations.reduce((flat, o) => flat.concat(o.pickupLocations), []);

            // delete markers that no longer exist
            this.markers = this.markers.filter(m => pickupLocations.some(p => {
                return p.id === m.pickupLocation.id && p.address.latitude === m.position.lat && p.address.longitude === m.position.lng;
            }));

            pickupLocations.forEach(p => {
                const marker = this.markers.find(m => m.pickupLocation.id === p.id);

                if (!marker) {
                    this.markers.push(this.pickupLocationService.buildMarker(this.map, p));
                }
            });
        }
    }
}
