import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {BaseForm} from '../base-form';
import {Address} from '../../../_models/address';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IonInput} from '@ionic/angular';

@Component({
    selector: 'bb-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent extends BaseForm {
    @Input() address: Address;
    @Input() singleLine = false;
    @Output() validPostalCode = new EventEmitter();

    @ViewChild('postalCode') postalCode: IonInput;

    constructor(private formBuilder: FormBuilder) {
        super();
    }

    setupFormGroup(): FormGroup {
        this.address = !this.address ? new Address() : this.address;
        const formGroup = this.formBuilder.group({
            id: this.address.id,
            addr1: [this.address.addr1, Validators.required],
            addr2: this.address.addr2,
            addr3: this.address.addr3,
            city: [this.address.city, Validators.required],
            state: [this.address.state, Validators.required],
            state2: [this.address.state, Validators.required],
            postalCode: [this.address.postalCode, [Validators.required, Validators.maxLength(5)]]
        });

        formGroup.controls.postalCode.statusChanges.subscribe(s => {
            if (s === 'VALID') {
                this.validPostalCode.emit();
            }
        });

        return formGroup;
    }

    updateState2(event) {
        const state = event.target.value;
        if (this.formGroup.controls.state2.value !== state) {
            this.formGroup.controls.state2.setValue(state);
        }
    }

    updateState(event) {
        const state = event.detail.value;
        if (this.formGroup.controls.state.value !== state) {
            this.formGroup.controls.state.setValue(state);
        }
    }
}
