import {Injectable} from '@angular/core';
import {BaseService} from './base-service';

@Injectable({
    providedIn: 'root'
})
export class SpecialtyTagService extends BaseService<string> {
    destination = 'specialty-tags';

    getShell(type: string) {
        return this.http.get(`${this.baseUrl()}/${type}/shell/`);
    }
}
