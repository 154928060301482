import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CashDrawerService} from '../_services/cash-drawer.service';

@Injectable()
export class CashDrawerResolver implements Resolve<any> {
    constructor(private cashDrawerService: CashDrawerService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.cashDrawerService.get(route.paramMap.get('cashDrawerId'));
    }
}
