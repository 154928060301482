import {BaseFormModel} from './base-form-model';
import {MenuLabel} from './menu-label';

export class MenuTopping extends BaseFormModel<MenuTopping> {
    id: number;
    name: string;
    description: string;
    available: boolean;
    labels: MenuLabel[];
}
