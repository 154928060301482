import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'creditType'
})
export class CreditTypePipe implements PipeTransform {

    transform(value: string, lowercase: boolean = false): string {
        let typeString = '';
        switch (value) {
        case 'UNSATISFACTORY':
            typeString = 'Unsatisfactory Item(s)';
            break;
        case 'POOR_SERVICE':
            typeString = 'Poor Service';
            break;
        case 'LATE':
            typeString = 'Late Item(s)';
            break;
        case 'INCORRECT_ITEM':
            typeString = 'Incorrect Order';
            break;
        case 'NEVER_RECEIVED':
            typeString = 'Never Received';
            break;
        case 'DUPLICATE':
            typeString = 'Duplicate';
            break;
        case 'FRAUDULENT':
            typeString = 'Fraudulent';
            break;
        case 'REWARD':
            typeString = 'Reward';
            break;
        case 'REQUESTED_BY_CUSTOMER':
            typeString = 'Requested By Customer';
            break;
        default:
            typeString = 'Other';
            break;
        }

        return lowercase ? typeString.toLowerCase() : typeString;
    }
}
