import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IonInput} from '@ionic/angular';
import {Tab} from '../../_models/tab';

@Component({
    selector: 'bb-edit-tax-exemption',
    templateUrl: './edit-tax-exemption.component.html',
    styleUrls: ['./edit-tax-exemption.component.scss'],
})
export class EditTaxExemptionComponent implements OnInit {

    @Input() tab: Tab;
    @Input() requireSave = false;
    @Output() close = new EventEmitter();

    taxExemptionId: string;

    @ViewChild('taxExemption') set content(input: IonInput) {
        if (!!input) {
            setTimeout(async () => {
                await input.setFocus();
            }, 50);
        }
    }

    ngOnInit() {
        this.taxExemptionId = !!this.tab.taxExemptionId ? this.tab.taxExemptionId : '';
    }

    saveTaxExemptionId() {
        this.close.emit(!!this.taxExemptionId ? this.taxExemptionId : null);
    }

    handleBlur() {
        this.saveTaxExemptionId();
    }

    async handleKeyUp(e) {
        if (e.keyCode === 13) {
            this.saveTaxExemptionId();
        }
    }
}
