import {OrderItem} from '../_models/order-item';
import {Tab} from '../_models/tab';
import {Organization} from '../_models/organization';
import {formattedDate} from './date-utils';
import {OrderItemSummary} from '../_models/order-item-summary';
import {multiplyCurrency} from './currency-math';
import {OrderItemSelectionItem} from '../_models/order-item-selection-item';
import {compareItems} from './order-utils';
import {Address} from '../_models/address';
import {formatItemName, formatMaxLength, isOrderItem, lineSeparator} from './receipt-utils';

const NEW_LINE = '\n';
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

export function replaceInvalidCharacters(s) {
    return s.replaceAll('&', 'and');
}

export function taggedCenteredText(s: string, length: number) {
    if (!!s) {
        return `<c>${replaceInvalidCharacters(s)}</c>`;
    } else {
        return '';
    }
}

export function taggedBuildReceiptHeader(organization: Organization, tab: Tab, length: number) {
    let output = '';
    if (!!organization) {
        output += taggedCenteredText(organization.name, length) + NEW_LINE;

        let address: Address;
        if (!!tab.orders) {
            const order = tab.orders.find(o => !!o.pickupLocation && !!o.pickupLocation.address);
            if (!!order) {
                address = order.pickupLocation.address;
            }
        }

        if (!address && !!organization.address) {
            address = organization.address;
        }

        if (!!address) {
            output += taggedPrintAddress(address, length);
        }
    }

    if (!!tab) {
        output += `${NEW_LINE}ID: ${tab.tabNumber}${NEW_LINE}`;
        if (!!tab.server && !!tab.server.firstName) {
            output += `<l>Server: ${tab.server.firstName} ${tab.server.lastName.substring(0, 1)}</l>${NEW_LINE}`;
        }
        output += `<l>${formattedDate(tab.startTime)}</l>${NEW_LINE}`;
    }

    output += lineSeparator(length);
    return output;
}

export function taggedBlankLine() {
    return `<l></l>${NEW_LINE}`;
}

export function taggedPrintAddress(address: Address, length: number) {
    let output = '';
    if (!!address.addr1) {
        output += taggedCenteredText(address.addr1, length) + NEW_LINE;
    }
    if (!!address.addr2) {
        output += taggedCenteredText(address.addr2, length) + NEW_LINE;
    }
    if (!!address.addr3) {
        output += taggedCenteredText(address.addr3, length) + NEW_LINE;
    }
    output += taggedCenteredText(`${address.city}, ${address.state} ${address.postalCode}`
            , length)
        + taggedBlankLine() + taggedBlankLine();
    return output;
}

export function taggedBuildReceiptOrderItemSummary(itemSummary: OrderItemSummary, length: number) {
    if (!!itemSummary && !!itemSummary.items && itemSummary.items.length > 0) {
        return taggedBuildReceiptOrderItem(itemSummary.items[0], 0, length, itemSummary.items.length);
    } else {
        return '';
    }
}

export function taggedBuildReceiptOrderItem(input: OrderItem | OrderItemSelectionItem, currentPad: number, length: number, count = 1) {
    const item = isOrderItem(input) ? input : input.orderItem;
    let total = 0;

    if (isOrderItem(input)) {
        total = item.selectedPrice.price;
    } else {
        total = input.menuSelectionItem.additionalPrice;
    }
    // title: string, amount: number, padCount: number, length: number, prefix = '', prefixMaxLength = 0;
    let output = taggedBuildCurrencyLineItem(formatItemName(item), multiplyCurrency(count, total), currentPad, length,
        (isOrderItem(input) ? count.toString(10) : ''), 4);

    const addedToppings = item.addedToppingsFilter();
    const subPad = isOrderItem(input) ? currentPad : currentPad + 2;
    if (!!addedToppings && addedToppings.length > 0) {
        output += taggedBuildLineItem('Add:', null, subPad, length, '', 4);
        for (const topping of addedToppings) {
            output += taggedBuildCurrencyLineItem(topping.name,
                (topping.menuItemTopping.price > 0) ? multiplyCurrency(count, topping.menuItemTopping.price) : null,
                subPad + 2, length, '', 4);
        }
    }

    const removedToppings = item.removedToppingsFilter();
    if (!!removedToppings && removedToppings.length > 0) {
        output += taggedBuildLineItem('Remove:', null, subPad, length, '', 4);
        for (const topping of removedToppings) {
            output += taggedBuildLineItem(topping.name, null, subPad + 2, length, '', 4);
        }
    }

    if (!!item.selections && item.selections.length > 0) {
        for (const selection of item.selections) {
            const selectionPrice = selection.countTotal();
            const selectionCount = selectionPrice > 0
                ? ' (x' + selection.selectedItems.length + ')'
                : '';
            output += taggedBuildCurrencyLineItem(selection.menuItemSelection.menuSelection.name + selectionCount, selectionPrice,
                subPad, length, '', 4);

            for (const selectedItem of selection.selectedItems) {
                output += taggedBuildReceiptOrderItem(selectedItem, subPad + 2, length, count);
            }
        }
    }

    return output;
}

export function taggedBuildReceiptDueSummary(tab: Tab, length: number) {
    let output = '';
    const small = length < 25;
    const totalPad = 18;
    const textPad = length - totalPad;
    if (!!tab && !!tab.paymentInformation) {
        if (!!tab.subtotal) {
            if (small) {
                output += taggedBuildCurrencyLineItem('Subtotal', tab.subtotal, 0, length);
            } else {
                output += 'Subtotal'.padStart(textPad, ' ') + currencyFormatter.format(tab.subtotal).padStart(totalPad, ' ') + NEW_LINE;
            }
        }
        if (!!tab.taxes && tab.taxes.length > 0) {
            for (const tax of tab.taxes) {
                if (small) {
                    output += taggedBuildCurrencyLineItem(`${tax.name} (${tax.taxRate}%)`, tax.tax, 0, length);
                } else {
                    output += formatMaxLength(`${tax.name} (${tax.taxRate}%)`, textPad).padStart(textPad, ' ')
                        + currencyFormatter.format(tax.tax).padStart(totalPad, ' ') + NEW_LINE;
                }
            }
        }
        if (tab.totalFee > 0) {
            if (small) {
                output += taggedBuildCurrencyLineItem('Mobile Order Fee', tab.totalFee, 0, length);
            } else {
                output += 'Mobile Order Fee'.padStart(textPad, ' ') + currencyFormatter.format(tab.totalFee).padStart(totalPad, ' ')
                    + NEW_LINE;
            }
        }

        if (tab.paymentInformation.credits > 0) {
            if (small) {
                output += taggedBuildLineItem('Credits', ('(' + currencyFormatter.format(tab.paymentInformation.credits) + ')'), 0, length);
            } else {
                output += 'Credits'.padStart(textPad, ' ') + ('(' + currencyFormatter.format(tab.paymentInformation.credits) + ')')
                    .padStart(totalPad, ' ') + NEW_LINE;
            }
        }

        output += lineSeparator(length);
        if (small) {
            output += taggedBuildCurrencyLineItem('Total', tab.paymentInformation.invoiced, 0, length);
        } else {
            output += 'Total'.padStart(textPad, ' ') +
                currencyFormatter.format(tab.paymentInformation.invoiced).padStart(totalPad, ' ') + NEW_LINE;
        }

        if (tab.paymentInformation.tips > 0) {
            if (small) {
                output += taggedBuildCurrencyLineItem('Tip', tab.paymentInformation.tips, 0, length);
            } else {
                output += 'Tip'.padStart(textPad, ' ')
                    + currencyFormatter.format(tab.paymentInformation.tips).padStart(totalPad, ' ') + NEW_LINE;
            }
        }
    }

    output += lineSeparator(length);

    return output;
}

export function taggedBuildReceiptPaymentSummary(tab: Tab, length: number) {
    let output = '';
    const small = length < 25;
    const totalPad = 18;
    const textPad = length - totalPad;
    if (!!tab && !!tab.paymentInformation) {
        if (tab.paymentInformation.cashPaymentsTotal > 0) {
            output += taggedBuildCurrencyLineItem('Cash Payments:', tab.paymentInformation.appliedCashTotal, 0, length);
            output += lineSeparator(length);
        }

        if (!!tab.paymentCharges && tab.paymentCharges.length > 0) {
            for (const charge of tab.paymentCharges) {
                output += formattedDate(charge.createDate) + NEW_LINE;
                output += taggedBuildCurrencyLineItem(charge.cardSummary.cardType.padEnd(11, ' ') + charge.cardSummary.lastFour.padEnd(5, ' '),
                    charge.total, 0, length);
                if (!!charge.authCode) {
                    output += `<l>Auth Code:</l><r>${charge.authCode}</r>${NEW_LINE}`;
                }
                if (charge.voided) {
                    output += `<r>VOIDED</r>${NEW_LINE}`;
                }

                if (!!charge.refunds && charge.refunds.length > 0) {
                    output += lineSeparator(length);
                    for (const refund of charge.refunds) {
                        output += `<l>    ${formattedDate(refund.createDate)}</l>${NEW_LINE}`;
                        output += `<l>    Refund ${charge.cardSummary.lastFour}</l><r>(${currencyFormatter.format(refund.total)})</r>`;
                    }
                }
                output += lineSeparator(length);
            }
        }

        if (tab.paymentInformation.appliedPayments > 0) {
            if (small) {
                output += taggedBuildCurrencyLineItem('Payments Total', tab.paymentInformation.appliedPayments, 0, length);
            } else {
                output += 'Payments Total'.padStart(textPad, ' ')
                    + currencyFormatter.format(tab.paymentInformation.appliedPayments).padStart(totalPad, ' ') + NEW_LINE;
            }
        }

        if (small) {
            output += taggedBuildLineItem('Due', currencyFormatter.format(tab.paymentInformation.remaining), 0, length);
        } else {
            output += 'Due'.padStart(textPad, ' ')
                + currencyFormatter.format(tab.paymentInformation.remaining).padStart(totalPad, ' ') + NEW_LINE;
        }
    }

    output += lineSeparator(length);
    return output;
}

export function taggedBuildReceipt(organization: Organization, tab: Tab, length: number) {
    let output = '';
    if (!!organization && !!tab) {
        output += taggedBuildReceiptHeader(organization, tab, length);
        const items = tab.orders.reduce((accumulator: OrderItem[], cur) => accumulator.concat(cur.items), []);

        const itemSummaries = items.reduce((accumulator: OrderItemSummary[], cur) => {
            const casted = new OrderItem(cur);
            casted.init();
            const item = accumulator.find(e => compareItems(e.items[0], casted));

            if (item) {
                item.items.push(cur);
                item.total += cur.total;
            } else {
                accumulator.push({key: casted.key, items: [casted], total: cur.total});
            }
            return accumulator;
        }, []);

        itemSummaries.forEach(s => output += taggedBuildReceiptOrderItemSummary(s, length));

        output += lineSeparator(length);

        output += taggedBuildReceiptDueSummary(tab, length);

        output += taggedBuildReceiptPaymentSummary(tab, length);

        if (!!organization.receiptTag) {
            output += taggedBuildTag(organization.receiptTag, length);
        }
    }
    return output;
}

export function taggedBuildCurrencyLineItem(title: string, amount: number, padCount: number,
                                            length: number, prefix = '', prefixPadding = 0
) {
    return taggedBuildLineItem(title, !!amount ? currencyFormatter.format(amount) : '', padCount, length, prefix, prefixPadding);
}

export function taggedBuildTag(receiptTag: string, length: number) {
    const lines = receiptTag.split('\n');
    let output = NEW_LINE;
    lines.forEach(l => output += taggedCenteredText(l, length) + NEW_LINE);
    return output;
}

export function taggedBuildLineItem(title: string, suffix: string, padCount: number, length: number, prefix = '', prefixPadding = 0) {
    const formattedSuffix = !!suffix ? '  ' + suffix : '';

    const padding = ''.padStart(padCount, ' ');
    let output = `<l>${padding}${title}</l>`;

    output += `<r>${formattedSuffix}</r>${NEW_LINE}`;
    return output;
}
