import {BaseFormModel} from './base-form-model';

export class MenuTaxLabel extends BaseFormModel<MenuTaxLabel> {
    id: number;
    menuId: number;
    name: string;
    description: string;
    taxRate: number;
    taxIncludedCash: boolean;
    taxIncludedCharge: boolean;
    taxIncludedGiftCard: boolean;
    fee: boolean;
}
