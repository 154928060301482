import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {OrganizationService} from '../_services/organization.service';

@Injectable()
export class AllOrganizationsResolver implements Resolve<any> {
    constructor(private organizationService: OrganizationService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.organizationService.list();
    }
}
