import {User} from '../_models/user';
import {MenuController, NavController, Platform} from '@ionic/angular';
import {AuthenticationService} from '../_services/authentication.service';
import {Event, GuardsCheckStart, NavigationCancel, NavigationEnd, NavigationError, Router} from '@angular/router';
import {SubscriberComponent} from './subscriber.component';
import {Component, Inject, Renderer2} from '@angular/core';
import {SplashScreen} from '@capacitor/splash-screen';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Capacitor} from '@capacitor/core';
import {UserService} from '../_services/user.service';
import {LoadingService} from '../_services/loading.service';
import {ScriptService} from '../_services/script.service';
import {DOCUMENT} from '@angular/common';
import {Constants} from '../_constants/constants';
import {GlobalPayService} from '../_services/global-pay.service';
import {DeviceService} from '../_services/device.service';

@Component({
    template: ''
})
export abstract class BaseAppComponent extends SubscriberComponent {
    currentUser: User;
    ready = false;
    initializedFor: number;

    abstract init(user: User);

    protected constructor(
        @Inject(DOCUMENT) protected document: Document,
        protected platform: Platform,
        protected authenticationService: AuthenticationService,
        protected userService: UserService,
        protected router: Router,
        protected navController: NavController,
        protected loadingService: LoadingService,
        protected deviceService: DeviceService,
        protected menu: MenuController,
        protected globalPayService: GlobalPayService,
        protected renderer: Renderer2,
        protected scriptService: ScriptService
    ) {
        super();
        this.initializeApp().then(() => {
        });
    }

    async initializeApp() {
        const scriptElement = this.scriptService.loadJsScript(this.renderer, Constants.GLOBAL_PAY_API_URL, this.document.body);
        scriptElement.onload = () => {
            this.globalPayService.initialize();
        };

        scriptElement.onerror = () => {
            console.log('Could not load the Global Pay Script.');
        };

        const deviceId = await this.deviceService.getDeviceId();

        if (Capacitor.isPluginAvailable('StatusBar') && this.platform.is('android')) {
            await StatusBar.setBackgroundColor({color: '#ffffff'});
            await StatusBar.setStyle({style: Style.Light});
        }

        const user = await this.authenticationService.checkToken();

        this.currentUser = !!user ? new User(user) : null;

        await this.init(this.currentUser);

        this.platform.ready().then(async () => {
            this.router.events.subscribe(async (event: Event) => {
                switch (true) {
                case event instanceof GuardsCheckStart: {
                    event = event as GuardsCheckStart;
                    const path = !!event && !!event.url && event.url.indexOf('/') > -1 ?
                        event.url.substring(event.url.lastIndexOf('/') + 1) : null;
                    if (!Constants.REUSE_ROUTES.includes(path)) {
                        this.present();
                    }
                    break;
                }
                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    await this.dismiss();
                    break;
                }
                default: {
                    break;
                }
                }
            });

            this.userService.current.subscribe(async (u: User) => {
                this.currentUser = !!u ? new User(u) : null;
                if (!!u && this.initializedFor !== u.id) {
                    this.init(new User(u));
                    this.initializedFor = u.id;
                } else if (!this.currentUser) {
                    this.initializedFor = null;
                }
            });
        });

        // Chart.defaults.global.legend.labels.fontColor = '#383a3e';

        setTimeout(async () => {
            await SplashScreen.hide();
        }, 3000);
    }

    present() {
        this.loadingService.present();
    }

    async dismiss() {
        this.loadingService.dismiss();
        await this.menu.close();
    }
}
