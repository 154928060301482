import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CashSessionService} from '../_services/cash-session.service';

@Injectable()
export class ActiveCashSessionResolver implements Resolve<any> {
    constructor(private cashSessionService: CashSessionService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.cashSessionService.findOpen(parseInt(route.paramMap.get('orgId'), 10));
    }
}
