import {BaseFormModel} from './base-form-model';
import {Person} from './person';
import {MembershipProgram} from './membership-program';
import {multiplyCurrency} from '../_utils/currency-math';
import {MembershipTokenPurse} from './membership-token-purse';

export class MembershipRenewal extends BaseFormModel<MembershipRenewal> {
    id: number;
    orgId: number;
    membershipProgram: MembershipProgram;
    purse: MembershipTokenPurse;
    amount: number;
    createdBy: Person;
    autoRenewal: boolean;
    createDate: Date;
    startDate: Date;
    endDate: Date;
    quantity: number;
    future: boolean;
    expired: boolean;

    requirePersonUpdate: boolean;
    membershipId: number;
    membershipName: string;

    init(): void {
        this.membershipProgram = !!this.membershipProgram ? new MembershipProgram(this.membershipProgram) : null;
    }

    getDescription() {
        const q = multiplyCurrency(this.quantity, this.membershipProgram.durationCount);
        return `${q} ${this.membershipProgram.durationPart}${(q > 1 ? 's' : '')}`;
    }

    get months() {
        if (!!this.membershipProgram && !!this.quantity) {
            switch (this.membershipProgram.durationPart) {
            case 'MONTH':
                return multiplyCurrency(this.quantity, this.membershipProgram.durationCount);
            case 'YEAR':
                return multiplyCurrency(this.quantity, multiplyCurrency(this.membershipProgram.durationCount, 12));
            }
        } else {
            return 0;
        }
    }
}
