import {Inject, Injectable} from '@angular/core';
import {MenuCategory} from '../_models/menu-category';
import {SortableService} from './sortable-service';
import {HttpClient} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';

@Injectable({
    providedIn: 'root'
})
export class MenuCategoryService extends SortableService<MenuCategory> {
    destination = 'menu-categories';

    constructor(
        public http: HttpClient,
        @Inject(LibConfigService) public config: LibConfig
    ) {
        super(http, config);
    }

    findByOrgId(orgId) {
        return this.http.get(this.baseUrl() + `/organization/${orgId}`);
    }
}
