import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderItemSummary} from '../../_models/order-item-summary';
import {DisplayOrderItemComponent} from '../display-order-item/display-order-item.component';
import {MembershipRenewal} from '../../_models/membership-renewal';

@Component({
  selector: 'bb-order-item-summary',
  templateUrl: './order-item-summary.component.html',
  styleUrls: ['./order-item-summary.component.scss'],
})
export class OrderItemSummaryComponent extends DisplayOrderItemComponent implements OnInit {
  @Input() itemSummary: OrderItemSummary;
  @Output() imageLoaded = new EventEmitter<any>();

    membershipRenewal: MembershipRenewal;

    ngOnInit() {
        super.ngOnInit();
        this.membershipRenewal = this.itemSummary.items[0].membershipRenewal;
    }
}
