<ion-row>
    <ion-col size-xs="12">
        <ion-item lines="none">
            <span class="ion-text-wrap">
                <strong>{{ person.name }}<br></strong>
                <span *ngIf="person.email.length > 0">{{ person.email }}<br></span>
            </span>
        </ion-item>
    </ion-col>
</ion-row>
