import {Address} from './address';
import {BaseFormModel} from './base-form-model';

export class Person extends BaseFormModel<Person> {
    id: number;
    email: string;
    mobile: string;
    password: string;
    firstName: string;
    lastName: string;
    name: string;
    address: Address;
    optIn: boolean;
}
