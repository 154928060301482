import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../_services/user.service';
import {OrganizationService} from '../../_services/organization.service';
import {User} from '../../_models/user';
import {Organization} from '../../_models/organization';
import {SubscriberComponent} from '../subscriber.component';

@Component({
    selector: 'bb-secured',
    templateUrl: './secured.component.html',
    styleUrls: ['./secured.component.scss'],
})
export class SecuredComponent extends SubscriberComponent implements OnInit {
    @Input() feature: string | string[];

    user: User;
    organization: Organization;
    authorized = false;

    constructor(
        private userService: UserService,
        private organizationService: OrganizationService
    ) {
        super();
    }

    ngOnInit() {
        this.subscribe(this.userService.current.subscribe(u => {
            this.user = !!u ? new User(u) : null;
            this.checkAuth();
        }));

        this.subscribe(this.organizationService.current.subscribe(o => {
            this.organization = !!o ? new Organization(o) : null;
            this.checkAuth();
        }));
    }

    checkAuth() {
        this.authorized = !!this.user && !!this.organization && this.user.authorizedTo(this.feature, this.organization.id);
    }
}
