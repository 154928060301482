import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CardSummary} from '../../_models/card-summary';

@Component({
    selector: 'bb-credit-card-summary',
    templateUrl: './credit-card-summary.component.html',
    styleUrls: ['./credit-card-summary.component.scss'],
})
export class CreditCardSummaryComponent {
    @Input() card: CardSummary;
    @Input() selected = false;
    @Input() buttonText = 'Pay Now';
    @Input() hideButton = false;
    @Input() buttonColor = 'success';

    @Output() action: EventEmitter<any> = new EventEmitter();

    clickAction(event) {
        event.stopPropagation();
        this.action.emit();
    }
}
