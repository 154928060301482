export class ColumnFormats {
    public static DATE = 'DATE';
    public static DATE_TIME = 'DATE_TIME';
    public static CURRENCY = 'CURRENCY';
    public static STRING = 'STRING';
    public static UNDERSCORE = 'UNDERSCORE';
    public static NUMBER = 'NUMBER';
    public static PERCENT = 'PERCENT';
    public static HOUR = 'HOUR';
    public static MONTH = 'MONTH';
    public static YEAR = 'YEAR';
    public static TIME = 'TIME';
    public static DAY_OF_WEEK = 'DAY_OF_WEEK';
    public static DURATION = 'DURATION';
    public static BOOLEAN = 'BOOLEAN';
    public static ID = 'ID';
}

export function isNumberFormat(format: string) {
    return format === ColumnFormats.CURRENCY
        || format === ColumnFormats.PERCENT
        || format === ColumnFormats.NUMBER
        || format === ColumnFormats.YEAR
        || format === ColumnFormats.HOUR
        || format === ColumnFormats.ID;
}
