import {User} from './user';
import {Organization} from './organization';

export class PushOptIn {
    id: number;
    organization: Organization;
    user: User;
    optIn: boolean;
    lastViewed: Date;

    // summary values
    organizationId: number;
    organizationName: string;
    unseen: number;
}
