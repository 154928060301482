import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MenuItem} from '../../_models/menu-item';
import {BaseModalComponent} from '../base-modal.component';
import {AlertController, IonContent, IonInput, ModalController} from '@ionic/angular';
import {OrderItem} from '../../_models/order-item';
import {OrderItemService} from '../../_services/order-item.service';
import {MenuItemSelection} from '../../_models/menu-item-selection';
import {MenuSelectionPrice} from '../../_models/menu-selection-price';
import {MenuSelectionItem} from '../../_models/menu-selection-item';
import {OrderItemSelectionItem} from '../../_models/order-item-selection-item';
import {MenuSelection} from '../../_models/menu-selection';
import {PickupLocationService} from '../../_services/pickup-location.service';
import {PickupLocation} from '../../_models/pickup-location';
import {MenuItemPrice} from '../../_models/menu-item-price';
import {LoadingService} from '../../_services/loading.service';
import {OrderService} from '../../_services/order.service';
import {Availabilities} from '../../_constants/availabilities';
import {OrganizationRewardProgram} from '../../_models/organization-reward-program';
import {UserOrganizationCreditSummary} from '../../_models/user-organization-credit-summary';
import {addCurrency, multiplyCurrency} from '../../_utils/currency-math';
import {PickupLocationMenuService} from '../../_services/pickup-location-menu.service';
import {ToastService} from '../../_services/toast.service';
import {PickupLocationMenu} from '../../_models/pickup-location-menu';
import {OrganizationService} from '../../_services/organization.service';
import {Organization} from '../../_models/organization';
import {cloneItem} from '../../_utils/order-utils';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import clone from 'clone';
import {OrganizationTerminalService} from '../../_services/organization-terminal.service';
import {MenuItemInventoryService} from '../../_services/menu-item-inventory.service';
import {MenuItemInventory} from '../../_models/menu-item-inventory';
import {MenuItemInventoryActionComponent} from '../menu-item-inventory-action/menu-item-inventory-action.component';
import {MenuCategory} from '../../_models/menu-category';
import {MembershipService} from '../../_services/membership.service';
import {Membership} from '../../_models/membership';
import {PaymentStatuses} from '../../_constants/payment-statuses';
import {Order} from '../../_models/order';
import {Tab} from '../../_models/tab';
import {TabService} from '../../_services/tab.service';

@Component({
    selector: 'bb-display-menu-item-modal',
    templateUrl: './display-menu-item-modal.component.html',
    styleUrls: ['./display-menu-item-modal.component.scss'],
})
export class DisplayMenuItemModalComponent extends BaseModalComponent implements OnInit, OnChanges {
    @Input() initialPrice: MenuItemPrice;
    @Input() menuItem: MenuItem;
    @Input() orderItem: OrderItem;
    @Input() parentOrderItem: OrderItem;
    @Input() parentMenuSelectionItem: MenuSelectionItem;
    @Input() parentSelection: MenuSelection;
    @Input() selectedItemIndex: number;
    @Input() rating: number;
    @Input() ratingChanged = false;
    @Input() rewardProgram: OrganizationRewardProgram;
    @Input() activeCredit: UserOrganizationCreditSummary;
    @Input() showInventory = false;

    @ViewChild(IonContent) ionContent: IonContent;

    @ViewChild('amount') set content(input: IonInput) {
        if (!!input) {
            setTimeout(async () => {
                await input.setFocus();
            }, 50);
        }
    }

    @Output() itemSelected: EventEmitter<any> = new EventEmitter();
    @Output() changeRating: EventEmitter<any> = new EventEmitter();
    @Output() selectionClicked: EventEmitter<any> = new EventEmitter();
    @Output() uploadImage: EventEmitter<any> = new EventEmitter();

    terminalMode = false;
    editMode = false;
    defaultPrice: number;
    selectionPrices: MenuSelectionPrice[];
    organization: Organization;
    pickupLocation: PickupLocation;
    pickupLocationMenu: PickupLocationMenu;
    order: Order;
    tab: Tab;

    menuCategory: MenuCategory;
    inventories: MenuItemInventory[];
    backOfHouseInventories: MenuItemInventory[];
    prices: MenuItemPrice[];
    selections: MenuItemSelection[];
    initialized = false;
    hideInHouse = false;
    hideTogo = false;
    miscItem = true;
    formGroup: FormGroup;

    activeMembership: Membership;
    availableTokens = 0;

    paymentStatuses = PaymentStatuses;

    constructor(
        private tabService: TabService,
        private orderItemService: OrderItemService,
        private alertController: AlertController,
        private pickupLocationService: PickupLocationService,
        private organizationService: OrganizationService,
        private pickupLocationMenuService: PickupLocationMenuService,
        private orderService: OrderService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private organizationTerminalService: OrganizationTerminalService,
        private menuItemInventoryService: MenuItemInventoryService,
        private membershipService: MembershipService,
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.terminalMode = this.organizationTerminalService.isTerminal;
        this.editMode = !!this.orderItem;
        this.orderItem = this.orderItemService.initializeOrderItem(this.menuItem, this.orderItem);
        this.activeMembership = !!this.membershipService.currentValue ? new Membership(this.membershipService.currentValue) : null;
        this.order = !!this.orderService.currentValue ? new Order(this.orderService.currentValue) : null;
        this.tab = !!this.tabService.currentValue ? new Tab(this.tabService.currentValue) : null;

        this.availableTokens = (!!this.activeMembership ? this.activeMembership.availableTokens : 0)
            - (!!this.order ? this.order.tokenPayments() : 0);

        let amount = this.orderItem.total;
        if (!!this.orderItem.selectedPrice && this.orderItem.selectedPrice.variable && !!this.orderItem.priceOverride) {
            amount = this.orderItem.priceOverride;
        } else if (!!this.orderItem.selectedPrice) {
            amount = this.orderItem.selectedPrice.price;
        }

        this.formGroup = this.formBuilder.group({
            amount: [amount, [Validators.required, Validators.min(0)]],
            reason: [this.orderItem.notes, [Validators.required, Validators.maxLength(2000)]]
        });

        this.subscribe(this.tabService.current.subscribe(t => this.tab = !!t ? new Tab(t) : null));
        this.subscribe(this.organizationService.current.subscribe(o => this.organization = !!o ? new Organization(o) : null));

        this.subscribe(this.pickupLocationService.current.subscribe(p => {
            this.pickupLocation = !!p ? new PickupLocation(p) : null;
        }));

        this.subscribe(this.pickupLocationMenuService.current.subscribe(async p => {
            this.pickupLocationMenu = !!p ? new PickupLocationMenu(p) : null;
            if (this.initialized) {
                const item = p.menu.menuItems.find(i => this.menuItem.related(i));
                if (!!item) {
                    this.menuItem = item;
                    await this.init();
                }
            }
        }));

        this.subscribe(this.menuItemInventoryService.pickupLocationInventory.subscribe(async p => {
            if (!!this.menuItem && !!this.menuItem.prices) {
                this.inventories = !!p ? p.filter(i => i.menuItem.id === this.menuItem.id && !!i.menuItemPrice) : [];
                this.inventories.forEach(n => {
                    const price = !!this.prices && this.prices.length > 0 ?
                        this.prices.find(mip => mip.id === n.menuItemPrice.id) :
                        this.menuItem.prices.find(mip => mip.id === n.menuItemPrice.id);
                    if (!!price) {
                        price.menuItemInventory = n;
                    }
                });
            }

            if (!!this.menuItem && !!this.menuItem.selections && this.menuItem.selections.length > 0) {
                this.menuItem.selections.forEach(selection => {
                    selection.menuSelection.options.forEach(o => {
                        if (!!o.menuItemPrice) {
                            o.menuItemPrice.menuItemInventory = p.find(i => !!i.menuItemPrice && i.menuItemPrice.id === o.menuItemPrice.id);
                        }
                    });
                });
            }
        }));

        this.subscribe(this.menuItemInventoryService.backOfHouseInventory.subscribe(inventory => {
            if (!!this.menuItem && !!this.menuItem.prices) {
                this.backOfHouseInventories = !!inventory && !!this.menuItem
                    ? inventory.filter(i => i.menuItem.id === this.menuItem.id)
                    : [];
            }
        }));

        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes.menuItem.previousValue) {
            this.init();
        }
    }

    async init() {
        this.applyComps();

        if (this.terminalMode && !this.menuItem) {
            this.miscItem = true;
        } else if (!this.terminalMode || !!this.menuItem.prices) {
            if (!this.terminalMode && !this.menuItem.allowMobileOrdering()) {
                await this.toastService.message(`${this.menuItem.name} is no longer available.`);
                await this.close();
            } else if (!!this.orderItem) {
                this.hideInHouse = !this.pickupLocationMenu.allowInHouse || !this.menuItem.allowInHouse();
                this.hideTogo = !this.pickupLocationMenu.allowTogo || !this.menuItem.allowToGo();
                if (this.hideInHouse && this.hideTogo) {
                    await this.toastService.message(`${this.menuItem.name} is no longer available.`);
                    await this.close();
                } else if (!this.hideInHouse && !this.hideTogo) {
                    let type = this.orderService.getOrderType();
                    if (!type) {
                        type = Availabilities.IN_HOUSE;
                    }
                    this.orderItem.orderType = !!this.orderItem.orderType ? this.orderItem.orderType : type;
                } else {
                    this.orderItem.orderType = !this.hideInHouse ? Availabilities.IN_HOUSE : Availabilities.TOGO;
                }
            }

            if (!!this.initialPrice) {
                const allowInHouse = this.initialPrice.allowInHouse();
                const allowToGo = this.initialPrice.allowToGo();
                if ((allowInHouse && !allowToGo) || (!allowInHouse && allowToGo)) {
                    this.orderItem.orderType = allowInHouse ? Availabilities.IN_HOUSE : Availabilities.TOGO;
                }
                this.initialPrice = null;
            }

            if (!!this.orderItem && !!this.orderItem.orderType) {
                if ((this.orderItem.orderType === Availabilities.IN_HOUSE && this.menuItem.allowInHouse())
                    || (this.orderItem.orderType === Availabilities.TOGO && this.menuItem.allowToGo())) {
                    const prices = clone<MenuItemPrice[]>(this.menuItem.prices);
                    this.prices = prices.filter(price => {
                        return (this.terminalMode || price.allowMobileOrdering()) &&
                            (this.orderItem.orderType === Availabilities.IN_HOUSE
                                ? price.allowInHouse()
                                : price.allowToGo());
                    });

                    if (this.prices.length === 0) {
                        await this.toastService.message(`${this.menuItem.name} is no longer available.`);
                        await this.close();
                    } else {
                        if (this.prices.length === 1) {
                            this.orderItem.selectedPrice = this.prices[0];
                        } else if (!this.orderItem.selectedPrice) {
                            const defaultPrices = this.prices.filter(price => price.defaultPrice);
                            this.orderItem.selectedPrice = (defaultPrices && defaultPrices.length > 0) ? defaultPrices[0] : this.prices[0];
                        } else if (!!this.initialPrice) {
                            this.orderItem.selectedPrice = this.prices.find(p => p.id === this.initialPrice.id);
                        } else {
                            this.orderItem.selectedPrice = this.prices.find(p => p.id === this.orderItem.selectedPrice.id);
                        }

                        if (!this.orderItem.selectedPrice && !!this.prices && this.prices.length > 0) {
                            this.orderItem.selectedPrice = this.prices[0];
                        }
                        const uniquePrices = [...new Set(this.prices.map(p => p.price))];
                        if (uniquePrices.length === 1) {
                            this.defaultPrice = uniquePrices[0];
                        }

                        if (!!this.menuItem.selections) {
                            const selections = clone<MenuItemSelection[]>(this.menuItem.selections);
                            selections.forEach(sel => {
                                sel.menuSelection.options = sel.menuSelection.options.filter(
                                    item => (this.orderItem.orderType === Availabilities.IN_HOUSE && item.menuItem.allowInHouse())
                                        || (this.orderItem.orderType === Availabilities.TOGO && item.menuItem.allowToGo())
                                );
                            });

                            this.selections = selections.filter(sel => sel.menuSelection.options.length > 0);
                        }

                        if (this.orderItem.selectedPrice.variable && !!this.orderItem.priceOverride) {
                            this.orderItem.selectedPrice.price = this.orderItem.priceOverride;
                        }

                        this.changeDetectorRef.detectChanges();
                    }
                    this.initialized = true;
                } else {
                    await this.toastService.message(`${this.menuItem.name} is no longer available.`);
                    await this.close();
                }
            }

            this.miscItem = false;
        }

        if (!this.miscItem && !!this.pickupLocationMenu
            && !!this.pickupLocationMenu.menu && !!this.pickupLocationMenu.menu.menuCategories && !!this.menuItem) {
            this.menuCategory = this.pickupLocationMenu.menu.menuCategories
                .find(c => !!c.menuItems && c.menuItems.some(i => i.menuItem.id === this.menuItem.id));
        }

        this.checkTokenOptions();

        this.updateTotal();
    }

    checkTokenOptions() {
        if (!!this.activeMembership
            && !!this.availableTokens
            && !!this.activeMembership.activeMembershipProgram
            && !!this.organization.tokenGroups) {

            this.prices.forEach(p => {
                const tokenGroup = this.organization.tokenGroups
                    .find(g => g.items.some(i => i.priceId === p.id));

                const tokenAvailability = !!tokenGroup && !!this.activeMembership.availableTokenGroups
                    ? this.activeMembership.availableTokenGroups.find(t => t.id === tokenGroup.id)
                    : null;

                if (!!tokenAvailability && !!tokenAvailability.remaining) {
                    p.countLimit = tokenAvailability.remaining - tokenAvailability.currentUsed;
                } else {
                    p.countLimit = null;
                }

                p.tokenPrice = !!tokenAvailability
                && (!tokenAvailability.remaining || tokenAvailability.remaining > tokenAvailability.currentUsed)
                && !!tokenGroup && this.availableTokens >= tokenGroup.tokenCount
                    ? tokenGroup.tokenCount
                    : null;

                p.membershipTokenGroupId = !!tokenAvailability
                && (!tokenAvailability.remaining || tokenAvailability.remaining > tokenAvailability.currentUsed)
                && !!tokenGroup && this.availableTokens >= tokenGroup.tokenCount
                    ? tokenGroup.id
                    : null;
            });
        }
    }

    showReward(price: MenuItemPrice) {
        if (!!this.activeCredit && this.activeCredit.rewardType === 'ITEM'
            && !!this.rewardProgram && !!this.rewardProgram.rewardItems && this.rewardProgram.rewardItems) {
            return this.rewardProgram.rewardItems.some(q => price.id === q.priceId);
        }
        return false;
    }

    showQualified(price: MenuItemPrice) {
        if (!this.activeCredit && !!this.rewardProgram && !!this.rewardProgram.qualifiedItems && this.rewardProgram.qualifiedItems) {
            return this.rewardProgram.qualifiedItems.some(q => price.id === q.priceId);
        }
        return false;
    }

    add() {
        if (!this.orderItem.selectedPrice
            || !this.orderItem.selectedPrice.tokenPrice
            || !this.activeMembership
            || ((this.orderTokensTotal() + this.orderItem.selectedPrice.tokenPrice) < this.availableTokens
                && ((this.orderItem.selectedPrice.countLimit > 0 && this.orderItem.selectedPrice.countLimit > this.orderItem.count)
                    || this.orderItem.selectedPrice.countLimit === null)
            )
        ) {
            this.orderItem.count++;
            this.applyComps();
        }
    }

    remove() {
        if (this.orderItem.count > 1) {
            this.orderItem.count--;
            this.applyComps();
        }
    }

    displaySelectionPricingInfo(selection: MenuItemSelection) {
        this.selectionPrices = selection.menuSelection.prices;
    }

    async confirmSubmit() {
        if (!this.miscItem) {

            let missingSelections = this.menuItem.selections.filter((selection, index) => {
                return selection.menuSelection.minCount > 0
                    && this.orderItem.selections[index].selectedItems.length < selection.menuSelection.minCount;
            });

            let required = false;
            if (missingSelections.some(m => m.menuSelection.required)) {
                missingSelections = missingSelections.filter(m => m.menuSelection.required);
                required = true;
            }

            if (!!missingSelections && missingSelections.length > 0) {
                let message = 'Not all selections are complete.';

                if (missingSelections.length === 1) {
                    message = `The ${required
                        ? 'required'
                        : 'expected'} number of items for ${missingSelections[0].menuSelection.name} were not selected.`;
                } else {
                    const last = missingSelections.pop();
                    const selectionText = missingSelections.map(s => s.menuSelection.name).join(', ') + ` and ${last.menuSelection.name}`;

                    message = `The ${required ? 'required' : 'expected'} number of items for ${selectionText} were not selected.`;
                    required = missingSelections.some(m => m.menuSelection.required);
                }

                if (!required) {
                    message += ' Would you like to proceed anyway?';
                }

                const alert = await this.alertController.create({
                    cssClass: 'brewbill-alert',
                    header: required ? 'Required' : 'Confirm',
                    message,
                    buttons: required ? [
                            {
                                text: 'Modify',
                                role: 'cancel',
                                cssClass: 'secondary',
                            }
                        ] :
                        [
                            {
                                text: 'No',
                                role: 'cancel',
                                cssClass: 'secondary',
                            }, {
                            text: 'Yes',
                            handler: () => {
                                this.submit();
                            }
                        }
                        ]
                });

                await alert.present();
            } else {
                await this.submit();
            }
        } else {
            this.submit();
        }
    }

    updateTotal() {
        if (!!this.orderItem.selectedPrice && this.orderItem.selectedPrice.variable) {
            this.orderItem.priceOverride = this.formGroup.controls.amount.value;
        }
    }

    orderTotal() {
        return multiplyCurrency(this.orderItem.calcTotal(), this.orderItem.count);
    }

    orderTokensTotal() {
        return this.orderItem.selectedPrice.tokenPrice * this.orderItem.count;
    }

    orderDeposits() {
        return multiplyCurrency(this.orderItem.calcDeposits(), this.orderItem.count);
    }

    paymentTotal() {
        return addCurrency(this.orderDeposits(), this.orderTotal());
    }

    async submit() {
        if (this.miscItem) {
            this.orderItem.total = Number.parseFloat(this.formGroup.controls.amount.value);
            this.orderItem.notes = this.formGroup.controls.reason.value;
            this.orderItem.menuItem = null;
            this.orderItem.selectedPrice = null;
            this.close([this.orderItem]);
        } else {
            if (!this.parentOrderItem) {
                if (this.orderItem.selectedPrice.variable) {
                    this.orderItem.priceOverride = Number.parseFloat(this.formGroup.controls.amount.value);
                }
                const items = [];
                let count = this.orderItem.count;

                this.orderService.setToppings(this.orderItem);

                while (count > 0) {
                    const item = cloneItem(this.orderItem);
                    item.count = 1;
                    if (!!this.orderItem.selectedPrice.tokenPrice && !item.tokenPaymentRefunded) {
                        item.tokenPayment = this.orderItem.selectedPrice.tokenPrice;
                        item.membershipTokenGroupId = this.orderItem.selectedPrice.membershipTokenGroupId;
                    }
                    items.push(item);
                    count--;
                }

                await this.close(items);
            } else if (!this.editMode) {
                const orderItemSelection = this.parentOrderItem.selections.find(sel =>
                    this.parentSelection.id === sel.menuItemSelection.id
                );

                const orderItemSelectionItem = new OrderItemSelectionItem({
                    orderItem: this.orderItem,
                    menuSelectionItem: this.parentMenuSelectionItem
                });

                orderItemSelectionItem.menuSelectionItem.menuItem = this.orderItem.menuItem;
                orderItemSelection.selectedItems.push(orderItemSelectionItem);

                if (!this.parentMenuSelectionItem.selected) {
                    this.parentMenuSelectionItem.selected = true;
                }
                this.parentMenuSelectionItem.selectionCount++;
                await this.close(this.orderItem);
            } else {
                const orderItemSelection = this.parentOrderItem.selections.find(sel =>
                    this.parentSelection.id === sel.menuItemSelection.id
                );

                orderItemSelection.selectedItems[this.selectedItemIndex].orderItem = this.orderItem;
                await this.close(this.orderItem);
            }
        }
    }

    async openItemModal(menuSelectionItem: MenuSelectionItem, selection: MenuItemSelection) {
        if (menuSelectionItem.selected && menuSelectionItem.selectionCount === 0) {
            menuSelectionItem.selected = false;
        }

        const modal = await this.modalController.create({
            component: DisplayMenuItemModalComponent,
            componentProps: {
                menuItem: menuSelectionItem.menuItem,
                parentOrderItem: this.orderItem,
                parentMenuSelectionItem: menuSelectionItem,
                parentSelection: selection,
                terminalMode: this.terminalMode
            },
            cssClass: 'menu-item-modal',
            canDismiss: true,
            presentingElement: document.querySelector(`#header-${this.menuItem.id}`)
        });
        await modal.present();
    }

    async editItemModal(orderItem: OrderItem, selection: MenuItemSelection, selectionIndex: number) {
        const selectedItemIndex = this.orderItem.selections[selectionIndex].selectedItems
            .findIndex(orderItemSelectionItem => orderItem === orderItemSelectionItem.orderItem);
        const modal = await this.modalController.create({
            component: DisplayMenuItemModalComponent,
            componentProps: {
                menuItem: orderItem.menuItem,
                orderItem,
                parentOrderItem: this.orderItem,
                terminalMode: this.terminalMode,
                parentSelection: selection,
                selectedItemIndex
            },
            cssClass: 'menu-item-modal',
            canDismiss: true,
            presentingElement: document.querySelector(`#header-${this.menuItem.id}`)
        });
        await modal.present();
    }

    async orderTypeChange(type) {
        this.orderItem.orderType = type;
        await this.init();
    }

    async adjustInventory(event, menuItemInventory: MenuItemInventory) {
        if (!!event) {
            event.stopPropagation();
        }
        const modal = await this.modalController.create({
            component: MenuItemInventoryActionComponent,
            componentProps: {
                menuItemInventory,
                backOfHouseInventory: !!this.backOfHouseInventories
                    ? this.backOfHouseInventories.find(i => i.menuItem.id === menuItemInventory.menuItem.id
                        && ((!i.menuItemPrice && !menuItemInventory.menuItemPrice)
                            || (!!i.menuItemPrice && !!menuItemInventory.menuItemPrice && i.menuItemPrice.id === menuItemInventory.menuItemPrice.id)))
                    : null
            },
            cssClass: 'inventory-modal'
        });

        modal.onDidDismiss().then(dataReturned => {
            if (!!dataReturned && !!dataReturned.data) {
                this.menuItemInventoryService.setEvent(dataReturned.data);
            }
        });

        await modal.present();
    }

    applyComps() {
        if (!!this.order) {
            this.orderService.applyComps(this.order, this.tab, this.pickupLocation.activeEvent, this.activeMembership);
        }
    }
}
