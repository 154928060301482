import {Inject, Injectable} from '@angular/core';
import {MenuController, ModalController, NavController, Platform} from '@ionic/angular';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {User} from '../_models/user';
import {ToastService} from './toast.service';

import {Constants} from '../_constants/constants';
import {LibConfig, LibConfigService} from '../lib.config';
import {LoadingService} from './loading.service';
import {TabService} from './tab.service';
import {OrderService} from './order.service';
import {ChangePasswordModalComponent} from '../_components/change-password-modal/change-password-modal.component';
import {BaseEventService} from './base-event.service';
import {Router} from '@angular/router';
import {UserService} from './user.service';
import {DEVICE_ID} from '../_utils/local-storage-utils';
import {BehaviorSubject, Observable} from 'rxjs';
import {PickupLocationMenuService} from './pickup-location-menu.service';
import {MenuService} from './menu.service';
import {FeatureKeys} from '../_constants/feature-keys';
import {Organization} from '../_models/organization';
import {OrganizationService} from './organization.service';
import {DeviceService} from './device.service';
import {MembershipService} from './membership.service';

export const TOKEN_KEY = 'auth-token';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    public defaultHome = '/pickup-location-search';

    public returnUrl: Observable<string>;
    private returnUrlSubject: BehaviorSubject<string>;

    constructor(
        @Inject(LibConfigService) private config: LibConfig,
        private platform: Platform,
        private http: HttpClient,
        private userService: UserService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private tabService: TabService,
        private orderService: OrderService,
        private organizationService: OrganizationService,
        private menuService: MenuService,
        private pickupLocationMenuService: PickupLocationMenuService,
        private modalController: ModalController,
        private eventService: BaseEventService,
        private deviceService: DeviceService,
        private router: Router,
        private menuController: MenuController,
        private navController: NavController,
        private membershipService: MembershipService
    ) {
        this.returnUrlSubject = new BehaviorSubject<string>(null);
        this.returnUrl = this.returnUrlSubject.asObservable();
    }

    async checkToken() {
        if (!!localStorage.getItem(TOKEN_KEY)) {
            return await this.loginFromToken();
        }
        return null;
    }

    clearServices() {
        this.orderService.setCurrent(null);
        this.orderService.setActiveOrders(null);
        this.orderService.nextType(null);
        this.tabService.setCurrent(null);
        this.tabService.setOpenTabs(null);
        this.menuService.setCurrent(null);
        this.membershipService.setCurrent(null);
        this.membershipService.setMemberships([]);
        this.pickupLocationMenuService.setCurrent(null);
    }

    async logout() {
        await this.menuController.close();
        this.eventService.close();
        await this.clearStorage();
        this.userService.setCurrent(null);
        this.clearServices();
        await this.userService.saveDevice(null);
        this.defaultHome = null;
        if (!!this.userService.currentValue) {
            await this.toastService.success('You have been logged out. Good bye!');
        }
        await this.router.navigate(['/login'], {replaceUrl: true});
    }

    async clearStorage() {
        const organization = this.organizationService.currentValue;
        const deviceId = await this.deviceService.getDeviceId();
        const terminal = localStorage.getItem('TERMINAL');
        const organizationToken = localStorage.getItem('ORGANIZATION_TOKEN');
        const audioOption = localStorage.getItem('AUDIO');
        const cashDrawer = localStorage.getItem('CASH_DRAWER_ID');
        const printerIp = localStorage.getItem('PRINTER_IP');
        const printerColumns = localStorage.getItem('PRINTER_COLUMNS');
        const tabCache = !!organization ? localStorage.getItem(`TAB_ID_CACHE_${organization.id}`) : null;
        const menuImages = localStorage.getItem('MENU_IMAGES');
        const menuInventory = localStorage.getItem('MENU_INVENTORY');
        const menuSortAlpha = localStorage.getItem('MENU_SORT_ALPHA');
        const tipOutType = localStorage.getItem('TIP_OUT_TYPE');

        localStorage.clear();
        localStorage.setItem(DEVICE_ID, deviceId);
        if (!!terminal) {
            localStorage.setItem('TERMINAL', terminal);
        }
        if (!!organizationToken) {
            localStorage.setItem('ORGANIZATION_TOKEN', organizationToken);
        }
        if (!!audioOption) {
            localStorage.setItem('AUDIO', audioOption);
        }
        if (!!cashDrawer) {
            localStorage.setItem('CASH_DRAWER_ID', cashDrawer);
        }
        if (!!printerIp) {
            localStorage.setItem('PRINTER_IP', printerIp);
        }
        if (!!printerColumns) {
            localStorage.setItem('PRINTER_COLUMNS', printerColumns);
        }
        if (!!tabCache) {
            localStorage.setItem(`TAB_ID_CACHE_${organization.id}`, tabCache);
        }
        if (!!menuImages) {
            localStorage.setItem('MENU_IMAGES', menuImages);
        }
        if (!!menuInventory) {
            localStorage.setItem('MENU_INVENTORY', menuInventory);
        }
        if (!!menuSortAlpha) {
            localStorage.setItem('MENU_SORT_ALPHA', menuSortAlpha);
        }
        if (!!tipOutType) {
            localStorage.setItem('TIP_OUT_TYPE', tipOutType);
        }
    }

    async create(newUser: User, modal = false) {
        this.loadingService.present();
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409,422');

        return this.http.post(`${this.config.apiUrl}/users/register`, newUser, {headers}).subscribe(async () => {
                if (modal) {
                    await this.modalController.dismiss();
                }
                await this.login(newUser.person.email, newUser.login.password);
                this.loadingService.dismiss();
            },
            async (error) => {
                if (error.status === 409) {
                    await this.toastService.error('The email or mobile already exists.');
                } else if (error.status === 422) {
                    await this.toastService.error('Unable to send email.');
                }
            });
    }

    async setupUser(user: User, pinLogin = false) {
        user.lastLogin = new Date(user.lastLogin);
        this.userService.setCurrent(new User(user));
        localStorage.setItem(TOKEN_KEY, user.token);

        if (user.login.confirmedEmail && !pinLogin) {
            await this.toastService.success(`Welcome ${user.person.name}!`);
        }

        await this.userService.saveDevice(null);

        if (user.passwordReset && !pinLogin) {
            await this.openChangePassword();
        }
    }

    async openChangePassword() {
        const modal = await this.modalController.create({
            component: ChangePasswordModalComponent
        });
        await modal.present();
    }

    async loginFromToken() {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '401,403');

        try {
            const user = await this.http.get(`${this.config.apiUrl}/users`, {headers}).toPromise() as User;
            if (user == null) {
                await this.toastService.error('The login has expired.');
            } else {
                await this.setupUser(user, true);
            }
            return new User(user);
        } catch (error) {
            console.log(error);
            await this.logout();
        }
    }

    async login(email: string, password: string, modalLogin = false) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '401');

        try {
            const user = await this.http.post(`${this.config.apiUrl}/users/login`, {email, password}, {headers})
                .toPromise() as User;
            if (user == null) {
                await this.toastService.error('The email or password is invalid.');
            } else {
                user.modalLogin = modalLogin;
                await this.setupUser(user);
            }
        } catch (error) {
            if (error.status === 401) {
                await this.toastService.error('The email or password is invalid.');
            }
        }
    }

    async loginNavigation(user: User, organization: Organization, hash = null) {
        this.defaultHome = this.getReturnUrl();
        this.setReturnUrl(null);

        const idString = localStorage.getItem('ACTIVE_PICKUP_LOCATION');
        let pickupLocationId = !!idString ? Number.parseInt(idString, 10) : null;
        if (!!organization && !!organization.pickupLocations && organization.pickupLocations.length > 0) {
            if (!pickupLocationId) {
                pickupLocationId = organization.pickupLocations[0].id;
            } else {
                if (!organization.pickupLocations.some(s => s.id === pickupLocationId)) {
                    pickupLocationId = null;
                }
            }
        }

        if (!this.defaultHome) {
            if (user.siteAdmin && !organization) {
                this.defaultHome = '/home';
            } else if (user.authorizedTo(FeatureKeys.DASHBOARD_FEATURE_KEYS, organization.id)) {
                this.defaultHome = `/${organization.id}/dashboard`;
            } else if (user.authorizedTo(FeatureKeys.OPERATIONS_FEATURE_KEYS, organization.id)) {
                this.defaultHome = this.operationsNavigation(user, organization);
            } else if (user.authorizedTo(FeatureKeys.REPORT_FEATURE_KEYS, organization.id)) {
                this.defaultHome = `/reports/${organization.id}`;
            } else if (!!pickupLocationId && user.authorizedTo(FeatureKeys.TERMINAL, organization.id)) {
                this.defaultHome = `/manager/${pickupLocationId}`;
            } else if (user.authorizedTo(FeatureKeys.CONFIGURATION_FEATURE_KEYS, organization.id)) {
                this.defaultHome = `/organization/${organization.id}`;
            } else {
                this.defaultHome = `/organization/blank/${organization.id}`;
            }
        }

        if (!hash || hash === '#/login' || hash === '#/') {
            await this.navController.navigateRoot(this.defaultHome);
        } else {
            await this.navController.navigateRoot(hash.substring(1));
        }
    }

    operationsNavigation(user: User, organization: Organization) {
        if (user.authorizedTo(FeatureKeys.CASH_SESSION_VIEW_KEYS, organization.id)) {
            return `/operations/${organization.id}/cash-sessions`;
        } else if (user.authorizedTo(FeatureKeys.TIMECARD_FEATURE_KEYS, organization.id)) {
            return `/operations/${organization.id}/timecards`;
        } else if (user.authorizedTo(FeatureKeys.GIFT_CARD_FEATURE_KEYS, organization.id)) {
            return `/operations/${organization.id}/gift-cards`;
        } else if (user.authorizedTo(FeatureKeys.DEPOSITS_ADMIN, organization.id)) {
            return `/operations/${organization.id}/deposits`;
        } else {
            return `/operations/${organization.id}`;
        }
    }

    setReturnUrl(url: string) {
        this.returnUrlSubject.next(url);
    }

    getReturnUrl() {
        return this.returnUrlSubject.value;
    }
}
