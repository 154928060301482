import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderService} from '../../_services/order.service';
import {Order} from '../../_models/order';
import {AuthenticationService} from '../../_services/authentication.service';
import {Router} from '@angular/router';
import {SubscriberComponent} from '../subscriber.component';
import {ActionSheetController, MenuController, ModalController, NavController} from '@ionic/angular';
import {UserNotificationSummaryService} from '../../_services/user-notification-summary.service';
import {UserNotificationSummary} from '../../_models/user-notification-summary';
import {Tab} from '../../_models/tab';
import {TabService} from '../../_services/tab.service';
import {PickupLocationService} from '../../_services/pickup-location.service';
import {PickupLocation} from '../../_models/pickup-location';
import {UserService} from '../../_services/user.service';
import {User} from '../../_models/user';
import {Organization} from '../../_models/organization';
import {OrganizationService} from '../../_services/organization.service';
import {Constants} from '../../_constants/constants';
import {ToastService} from '../../_services/toast.service';
import {FeatureKeys} from '../../_constants/feature-keys';
import {PinLoginComponent} from '../pin-login/pin-login.component';
import {LoadingService} from '../../_services/loading.service';
import {MembershipService} from '../../_services/membership.service';

@Component({
    selector: 'bb-menu-header',
    templateUrl: './menu-header.component.html',
    styleUrls: ['./menu-header.component.scss'],
})
export class MenuHeaderComponent extends SubscriberComponent implements OnInit {
    @Input() title: string;
    @Input() hideTabs: boolean;
    @Input() hideBack: boolean;
    @Input() hideMenu: boolean;
    @Input() hideButtons: boolean;
    @Input() includeOrder: boolean;
    @Input() includeCart: boolean;
    @Input() editMode = false;
    @Input() manager = false;
    @Input() removeUser = false;
    @Input() includeMembershipInfo = false;

    @Output() editModeBackClicked: EventEmitter<any> = new EventEmitter();
    @Output() editClicked: EventEmitter<any> = new EventEmitter();

    tab: Tab;
    openTabs: Tab[];
    organization: Organization;
    user: User;
    pickupLocation: PickupLocation;
    order: Order;
    defaultHome: string;
    notificationSummary: UserNotificationSummary;
    activePickupLocationId: number;

    featureKeys = FeatureKeys;

    constructor(
        private orderService: OrderService,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private userNotificationSummaryService: UserNotificationSummaryService,
        private organizationService: OrganizationService,
        private tabService: TabService,
        private membershipService: MembershipService,
        private pickupLocationService: PickupLocationService,
        private actionSheetController: ActionSheetController,
        private toastService: ToastService,
        private menu: MenuController,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private modalController: ModalController,
        private navController: NavController,
        private loadingService: LoadingService
    ) {
        super();
    }

    ngOnInit() {
        this.subscribe(this.orderService.current.subscribe(o => this.order = o));
        this.subscribe(this.userNotificationSummaryService.current.subscribe(s => this.notificationSummary = s));
        this.subscribe(this.tabService.current.subscribe(t => this.tab = !!t ? new Tab(t) : null));
        this.subscribe(this.tabService.openTabs.subscribe(ot => this.openTabs = ot));

        this.subscribe(this.userService.current.subscribe(u => {
            this.user = !!u ? new User(u) : null;
            if (!this.user) {
                this.notificationSummary = null;
            }
        }));

        this.subscribe(this.organizationService.current.subscribe(o => {
            this.organization = !!o ? new Organization(o) : null;
            if (!!this.organization) {
                const idString = localStorage.getItem('ACTIVE_PICKUP_LOCATION');
                let id = !!idString ? Number.parseInt(idString, 10) : null;
                if (!!id && !this.organization.pickupLocations.some(s => s.id === id)) {
                    this.activePickupLocationId = null;
                    localStorage.removeItem('ACTIVE_PICKUP_LOCATION');
                    id = null;
                }

                if (!id) {
                    if (!this.activePickupLocationId && this.organization.pickupLocations.length === 1) {
                        this.activePickupLocationId = this.organization.pickupLocations[0].id;
                    }
                } else {
                    if (this.organization.pickupLocations.some(s => s.id === id)) {
                        this.activePickupLocationId = id;
                    }
                }
            } else {
                this.activePickupLocationId = null;
            }
        }));

        this.subscribe(this.pickupLocationService.current.subscribe(p => {
            this.pickupLocation = !!p ? new PickupLocation(p) : null;
            if (!!this.pickupLocation) {
                this.activePickupLocationId = this.pickupLocation.id;
            }
            this.changeDetectorRef.detectChanges();
        }));

        this.defaultHome = this.authenticationService.defaultHome;
        this.hideBack = this.hideBack || this.router.url.indexOf(this.defaultHome) > -1;
    }

    async openMenu() {
        await this.menu.open();
    }

    editModeBack() {
        this.editModeBackClicked.emit();
    }

    backClicked(event) {
        event.stopPropagation();
        if (this.removeUser) {
            this.userService.setCurrent(null);
        }
        window.history.back();
    }

    async navigateToTerminal() {
        this.tabService.setCurrent(null);
        this.orderService.setCurrent(null);
        this.membershipService.setCurrent(null);
        this.loadingService.present();
        await this.router.navigateByUrl(`/manager/${this.activePickupLocationId}`);
    }

    operationsNavigation() {
        this.navController.navigateForward(this.authenticationService.operationsNavigation(this.user, this.organization));
    }

    async presentActionSheet() {
        const buttons = [];

        if (!!this.activePickupLocationId && this.user.authorizedTo(FeatureKeys.TERMINAL, this.organization.id)) {
            buttons.push({
                text: 'Terminal',
                handler: () => {
                    this.navigateToTerminal();
                }
            });
        }

        if (this.user.authorizedTo(FeatureKeys.OPERATIONS_FEATURE_KEYS, this.organization.id)) {
            buttons.push({
                text: 'Operations',
                handler: () => {
                    this.router.navigateByUrl(`/operations/${this.organization.id}`);
                }
            });
        }

        if (this.user.authorizedTo(FeatureKeys.CONFIGURATION_FEATURE_KEYS, this.organization.id)) {
            buttons.push({
                text: 'Configuration',
                handler: () => {
                    this.router.navigateByUrl(`/organization/${this.organization.id}`);
                }
            });
        }

        if (this.user.authorizedTo(FeatureKeys.REPORT_FEATURE_KEYS, this.organization.id)) {
            buttons.push({
                text: 'Reports',
                handler: () => {
                    this.router.navigateByUrl(`/reports/${this.organization.id}`);
                }
            });
        }

        const actionSheet = await this.actionSheetController.create({
            header: 'Actions',
            cssClass: 'bb-action-sheet',
            buttons
        });
        await actionSheet.present();
    }

    openHelpDocs() {
        window.open(Constants.LIVEAGENT_KB_URL, '_system', 'location=yes');
    }

    async logout() {
        await this.authenticationService.logout();
    }

    async lock() {
        this.userService.setCurrent(null);
        await this.authenticationService.clearStorage();

        const modal = await this.modalController.create({
            component: PinLoginComponent,
            backdropDismiss: false,
            keyboardClose: false,
            cssClass: 'full-screen-modal'
        });
        await modal.present();

        await this.navController.navigateRoot('/login');
    }
}
