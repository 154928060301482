import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Order} from '../_models/order';
import {Organization} from '../_models/organization';
import {CashSessionSummary} from '../_models/cash-information/cash-session-summary';
import {CashSession} from '../_models/cash-information/cash-session';
import {Timecard} from '../_models/timecard';
import {EpsonPrinterService} from './epson-printer.service';
import {StarPrinterService} from './star-printer.service';
import {Platform} from '@ionic/angular';
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions/ngx';
import {Tab} from '../_models/tab';

@Injectable({
    providedIn: 'root'
})
export class PrinterService {
    type: string;
    public connected: Observable<boolean>;
    private connectedSubject: BehaviorSubject<boolean>;

    constructor(
        private epsonPrinterService: EpsonPrinterService,
        private starPrinterService: StarPrinterService,
        private platform: Platform,
        private androidPermissions: AndroidPermissions
    ) {
        this.connectedSubject = new BehaviorSubject<boolean>(false);
        this.connected = this.connectedSubject.asObservable();

        this.epsonPrinterService.connected.subscribe(c => {
            if (c) {
                this.type = 'EPSON';
                this.connectedSubject.next(true);
            } else if (this.type === 'EPSON' && !c) {
                this.type = null;
                this.connectedSubject.next(false);
            }
        });

        this.starPrinterService.connected.subscribe(c => {
            if (c) {
                this.type = 'STAR';
                this.connectedSubject.next(true);
            } else if (this.type === 'STAR' && !c) {
                this.type = null;
                this.connectedSubject.next(false);
            }
        });
    }

    async connectViaBlueTooth() {
        if (this.platform.is('android')) {
            try {
                await this.requestPermission(this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT);
                await this.requestPermission(this.androidPermissions.PERMISSION.BLUETOOTH_SCAN);

                console.log('DISCOVERING STAR');
                await this.starPrinterService.discover();
                if (!!this.starPrinterService.port) {
                    this.type = 'STAR';
                }
            } catch (error) {

            }
        }
    }

    async requestPermission(permission: string) {
        console.log('REQUESTING PERMISSION', permission);
        const result = await this.androidPermissions.checkPermission(permission);
        if (!!result && !result.hasPermission) {
            console.log('REQUESTING PERMISSION', permission);
            const res = await this.androidPermissions.requestPermission(permission);
            console.log(res);
        }
    }

    openDrawer() {
        if (this.type === 'EPSON') {
            this.epsonPrinterService.openDrawer();
        } else if (this.type === 'STAR') {
            this.starPrinterService.openDrawer();
        }
    }

    printClaimTab(tab: Tab) {
        if (this.type === 'EPSON') {
            this.epsonPrinterService.printClaimTab(tab);
        } else if (this.type === 'STAR') {
            this.starPrinterService.printClaimTab(tab);
        }
    }

    print(msg) {
        if (this.type === 'EPSON') {
            this.epsonPrinterService.print(msg);
        } else if (this.type === 'STAR') {
            this.starPrinterService.print(msg);
        }
    }

    printCashSession(cashSession: CashSession, summary: CashSessionSummary, organization: Organization) {
        if (this.type === 'EPSON') {
            this.epsonPrinterService.printCashSession(cashSession, summary, organization);
        } else if (this.type === 'STAR') {
            this.starPrinterService.printCashSession(cashSession, summary, organization);
        }
    }

    printTimeCard(timeCard: Timecard, organization: Organization) {
        if (this.type === 'EPSON') {
            this.epsonPrinterService.printTimeCard(timeCard, organization);
        } else if (this.type === 'STAR') {
            this.starPrinterService.printTimeCard(timeCard, organization);
        }
    }

    printOrder(order: Order, silent = false) {
        const items = this.sortOrderItems(order);
        if (this.type === 'EPSON') {
            this.epsonPrinterService.printOrder(order, items, silent);
        } else if (this.type === 'STAR') {
            this.starPrinterService.printOrder(order, items, silent);
        }
    }

    sortOrderItems(order: Order) {
        let items = [...order.items];
        items = items.sort((a, b) => {
            let aVal = a.orderType;
            let bVal = b.orderType;
            if (aVal < bVal) {
                return -1;
            }
            if (aVal > bVal) {
                return 1;
            }

            aVal = `${!!a.selectedPrice ? a.selectedPrice.name : ''}`;
            bVal = `${!!b.selectedPrice ? b.selectedPrice.name : ''}`;

            if (aVal < bVal) {
                return -1;
            }
            if (aVal > bVal) {
                return 1;
            }
            return a.addIndex - b.addIndex;
        });
        return items;
    }

    isConnected() {
        return !!this.type;
    }

    get columns() {
        if (this.type === 'EPSON') {
            return this.epsonPrinterService.columns;
        } else if (this.type === 'STAR') {
            return this.starPrinterService.columns;
        } else {
            return 38;
        }
    }
}
