import {Directive, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';

@Directive({
    selector: '[bbDigitOnlyMask]'
})
export class DigitOnlyDirective implements OnInit {

    stripChars = new RegExp('[^0-9]', 'g');

    constructor(
        public el: ElementRef,
        public renderer: Renderer2
    ) {
    }

    ngOnInit() {
        this.format(this.el.nativeElement.value); // format any initial values
    }

    @HostListener('input', ['$event.target.value']) onInput(e: string) {
        this.format(e);
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        event.preventDefault();
        this.format(event.clipboardData.getData('text/plain'));
    }

    format(val: string) {
        this.renderer.setProperty(this.el.nativeElement, 'value', String(val).replace(this.stripChars, ''));
    }
}
