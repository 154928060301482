import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';
import {Tab} from '../_models/tab';
import {Constants} from '../_constants/constants';
import {BaseObservableService} from './base-obeservable-service';
import {getTabIdCache, getTimeBased, saveTimeBased, setTabIdCache} from '../_utils/local-storage-utils';
import moment from 'moment';
import {CardSummary} from '../_models/card-summary';
import {Person} from '../_models/person';
import {LoadingService} from './loading.service';
import {ToastService} from './toast.service';
import {IdCache} from '../_models/id-cache';
import {TabStatuses} from '../_constants/tab-statuses';
import {OrderItemCredit} from '../_models/order-item-credit';
import {TabCredit} from '../_models/tab-credit';
import {TabSearchResult} from '../_models/tab-search-result';
import {PaymentStatuses} from '../_constants/payment-statuses';
import {OrderItem} from '../_models/order-item';
import {Membership} from '../_models/membership';

@Injectable({
    providedIn: 'root'
})
export class TabService extends BaseObservableService<Tab> {
    destination = 'tabs';
    tabCache: Tab[] = [];

    public openTabs: Observable<Tab[]>;
    private openTabsSubject: BehaviorSubject<Tab[]>;

    public deletedTab: Observable<number>;
    private deletedTabSubject: BehaviorSubject<number>;

    public historySearchCriteria: Observable<any>;
    private historySearchCriteriaSubject: BehaviorSubject<any>;

    public openTabsSearchCriteria: Observable<string>;
    private openTabsSearchCriteriaSubject: BehaviorSubject<string>;

    public invoiceToken: Observable<string>;
    private invoiceTokenSubject: BehaviorSubject<string>;

    constructor(
        http: HttpClient,
        private loadingService: LoadingService,
        private toastService: ToastService,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);
        this.saveToLocalStorage = true;
        const val = getTimeBased(this.destination);

        if (!!val) {
            val.preload = true;
            this.setCurrent(new Tab(val));
        }

        const invoiceToken = getTimeBased('INVOICE_TOKEN');

        this.deletedTabSubject = new BehaviorSubject<number>(null);
        this.deletedTab = this.deletedTabSubject.asObservable();

        this.openTabsSubject = new BehaviorSubject<Tab[]>(null);
        this.openTabs = this.openTabsSubject.asObservable();

        const dateSetter = moment();
        dateSetter.set({hour: 0, minute: 0, second: 0, millisecond: 0});
        const startDate = dateSetter.format();
        dateSetter.add(1, 'days');
        const endDate = dateSetter.format();

        this.openTabsSearchCriteriaSubject = new BehaviorSubject<any>({searchString: '', tabNumber: ''});
        this.openTabsSearchCriteria = this.openTabsSearchCriteriaSubject.asObservable();

        this.historySearchCriteriaSubject = new BehaviorSubject<any>({
            cacheTime: new Date(),
            startDate,
            endDate,
            searchString: '',
            tabNumber: ''
        });
        this.historySearchCriteria = this.historySearchCriteriaSubject.asObservable();

        this.invoiceTokenSubject = new BehaviorSubject<string>(invoiceToken);
        this.invoiceToken = this.invoiceTokenSubject.asObservable();
    }

    refresh() {
        if (!!this.currentValue && !!this.currentValue.id) {
            this.getSilent(this.currentValue.id).subscribe((t: Tab) => {
                const tab = new Tab(t);
                this.setCurrent(tab);
                this.setEvent(tab);
            });
        }
    }

    history() {
        return this.http.get(`${this.baseUrl()}/user`);
    }

    findByCardSummary(id: number) {
        return this.http.get(`${this.baseUrl()}/card-summary/${id}`);
    }

    findByRfmiq(organizationId: number, rfmiq: string) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/rfmiq/${rfmiq}`);
    }

    findByTerminalCard(organizationId: number, terminalId: string, referenceId: string, status: string) {
        const headers = new HttpHeaders().set('timeout', '30000');
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/terminal/${terminalId}/${referenceId}?status=${status}`,
            {headers});
    }

    findByLastFour(organizationId: number, lastFour: string) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/card-summary/last-four/${lastFour}`);
    }

    organizationHistory(personId: number, organizationId: number) {
        return this.http.get(`${this.baseUrl()}/user/${personId}/${organizationId}`);
    }

    findByIdIn(organizationId: number, ids: number[]) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}?ids=${ids.join(',')}`);
    }

    getServerDateRange(organizationId: number, serverId: number, page: number, size: number, startDate: string, endDate?: string) {
        return this.http.get(`${this.baseUrl()}/servers/${serverId}/${organizationId}?startDate=${startDate.replace('+', '%2b')}&page=${page}&size=${size}`
            + (!!endDate ? '&endDate=' + endDate.replace('+', '%2b') : ''));
    }

    emailReceipt(id: number, email?: string) {
        const invoiceToken = this.getInvoiceToken();

        let queryParams = '';
        if (!!invoiceToken) {
            queryParams = `?invoiceToken=${invoiceToken}`;
        }
        if (!!email) {
            queryParams = `${!!queryParams ? queryParams + '&' : '?'}email=${email}`;
        }
        return this.http.get(`${this.baseUrl()}/${id}/receipt${queryParams}`);
    }

    applyTransaction(tab: Tab, transactionId: string, tip: number) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '424,417');
        return this.http.put(`${this.baseUrl()}/${tab.id}/transaction/${transactionId}/apply?tip=${!!tip ? tip : 0}`, {}, {headers});
    }

    payWithGiftCard(tab: Tab, giftCardId: any, amount: number, tip: number) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '402,409,424');
        if (!!tab.id) {
            return this.http.post(`${this.baseUrl()}/${tab.id}/gift-card/${giftCardId}?amount=${amount}&tip=${!!tip ? tip : 0}`,
                {}, {headers});
        } else {
            return this.http.post(`${this.baseUrl()}/gift-card/${giftCardId}?amount=${amount}&tip=${!!tip ? tip : 0}`,
                tab, {headers});
        }
    }

    refundGiftCardAction(tab: Tab, giftCardPaymentId: number, cardNumber?: string) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409,424');
        return this.http.post(`${this.baseUrl()}/${tab.id}/gift-card-payment/${giftCardPaymentId}/refund${!!cardNumber
            ? '?cardNumber=' + cardNumber
            : ''}`, {}, {headers});
    }

    remove() {
        this.currentSubject.next(null);
    }

    getCurrentOpenTabs(): Tab[] {
        return this.openTabsSubject.getValue();
    }

    setHistorySearchCriteria(value: any) {
        this.historySearchCriteriaSubject.next(value);
    }

    getHistorySearchCriteria(): any {
        const val = this.historySearchCriteriaSubject.getValue();
        const current = new Date();
        const difference = current.getTime() - val.cacheTime.getTime();
        const hoursMilli = 1000 * 60 * 10; // 10 minutes
        if (Math.abs(difference) < hoursMilli) {
            val.cacheTime = new Date();
            this.historySearchCriteriaSubject.next(val);
            return val;
        } else {
            const dateSetter = moment();
            dateSetter.set({hour: 0, minute: 0, second: 0, millisecond: 0});
            dateSetter.add(1, 'days');

            const endDate = dateSetter.format();
            dateSetter.subtract(8, 'days');
            const startDate = dateSetter.format();
            const newVal = {
                cacheTime: new Date(),
                startDate,
                endDate,
                searchString: '',
                tabNumber: ''
            };
            this.historySearchCriteriaSubject.next(newVal);
            return newVal;
        }
    }

    addToIdCache(organizationId: number, tabId: number) {
        const cached = getTabIdCache(organizationId).filter(t => t.id !== tabId);
        const idCache = new IdCache();
        idCache.id = tabId;
        idCache.lastAction = new Date();
        cached.unshift(idCache);
        if (cached.length > 50) {
            cached.length = 50;
        }

        setTabIdCache(organizationId, cached);
    }

    addToCache(tab: Tab) {
        if (!!tab) {
            const index = this.tabCache.findIndex(t => t.id === tab.id || t.key === tab.key);

            if (index > -1) {
                this.tabCache.splice(index, 1);
            }
            this.tabCache.unshift(tab);

            if (this.tabCache.length > 50) {
                this.tabCache.length = 50;
            }
        }
    }

    removeFromCache(id: number) {
        this.tabCache = this.tabCache.filter(t => t.id !== id);
    }

    fromCache(id: number | string) {
        const tab = this.tabCache.find(t => t.id === id || t.key === id);
        if (!!tab) {
            this.addToCache(tab);
        }
        return tab;
    }

    setOpenTabSearchCriteria(value: any) {
        this.openTabsSearchCriteriaSubject.next(value);
    }

    getOpenTabSearchCriteria(): any {
        return this.openTabsSearchCriteriaSubject.getValue();
    }

    addOpenTab(tab: Tab) {
        const active = this.getCurrentOpenTabs();
        const index = active.findIndex(t => t.id === tab.id);
        if (index > -1) {
            active[index] = tab;
        } else {
            active.push(tab);
        }

        this.setOpenTabs(active);
    }

    setOpenTabs(tabs: Tab[]) {
        const sanitizedTabs = [];

        if (!!tabs) {
            tabs.forEach(t => {
                const tab = new Tab(t);
                sanitizedTabs.push(tab);
                this.addToCache(tab);
            });
        }

        const sorted = sanitizedTabs.sort((tabA: Tab, tabB: Tab) => {
            if (!!tabA.personName && !!tabB.personName && tabA.personName !== tabB.personName) {
                const a = !!tabA.personName ? tabA.personName.split(' ') : [''];
                const b = !!tabB.personName ? tabB.personName.split(' ') : [''];
                return (a[a.length - 1].toLowerCase() > b[b.length - 1].toLowerCase()) ? 1 : -1;
            } else if (!!tabA.startTime && !!tabB.startTime) {
                return new Date(tabB.startTime).getTime() - new Date(tabA.startTime).getTime();
            } else {
                return 0;
            }
        });

        this.openTabsSubject.next(sorted);
    }

    setDeletedTab(id: number) {
        this.deletedTabSubject.next(id);
    }

    setInvoiceToken(token: string) {
        saveTimeBased('INVOICE_TOKEN', token);
        this.invoiceTokenSubject.next(token);
    }

    getInvoiceToken() {
        return this.invoiceTokenSubject.value;
    }

    loadDeviceOpen(deviceId: string) {
        return this.http.get(`${this.baseUrl()}/current/${deviceId}`);
    }

    loadCurrent() {
        return this.http.get(`${this.baseUrl()}/current`);
    }

    closeTab(tab: Tab, applyFee: boolean, silent = false) {
        let headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406,424');
        if (silent) {
            headers = headers.set(Constants.SILENT, 'true');
        }
        let params = '';
        const invoiceToken = this.invoiceTokenSubject.value;

        params = `?applyFee=${applyFee}`;

        if (!!tab.pendingTip) {
            params += `&tip=${tab.pendingTip}`;
        }

        if (!!invoiceToken) {
            params += `&invoiceToken=${invoiceToken}`;
        }

        if (!!tab.terminalId) {
            params += `&terminalId=${tab.terminalId}`;
            headers = headers.set('timeout', '30000');
        }

        if (!!tab.id) {
            return this.http.put(
                `${this.baseUrl()}/${tab.id}/close${params}`,
                !!tab.cardSummary ? tab.cardSummary : null,
                {headers}
            );
        } else {
            return this.http.put(
                `${this.baseUrl()}/close${params}`,
                tab,
                {headers}
            );
        }
    }

    paymentCharge(tab: Tab, cardSummary: CardSummary, amount: number, tip: number) {
        return this.http.post(
            `${this.baseUrl()}/${tab.id}/payment-charge?amount=${amount}&tip=${tip}&terminal=${tab.fromTerminal}`,
            cardSummary
        );
    }

    createBlank(pickupLocationId: number, tab: Tab) {
        return this.http.post(
            `${this.baseUrl()}/pickupLocation/${pickupLocationId}`, tab
        );
    }

    changeCardOnFile(tab: Tab, cardSummary: CardSummary) {
        let headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406,410,423,424');
        headers = headers.set('timeout', '30000');
        return this.http.put(`${this.baseUrl()}/${tab.id}/change-card-on-file/${cardSummary.id}`, {}, {headers});
    }

    startInvoice(tab: Tab) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406');
        return this.http.put(`${this.baseUrl()}/start/invoice`, tab, {headers});
    }

    emailInvoice(tab: Tab, email: string) {
        return this.http.put(`${this.baseUrl()}/${tab.id}/invoice/email?email=${email}`, {});
    }

    addItemCredit(id: number, orderItemCredit: OrderItemCredit) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409');
        return this.http.post(`${this.baseUrl()}/${id}/order-item-credit`, orderItemCredit, {headers});
    }

    updateTokens(id: number, orderItem: OrderItem, count: number) {
        return this.http.post(`${this.baseUrl()}/${id}/order-item/token${!!count ? '?count=' + count : ''}`, orderItem);
    }

    deleteItemCredit(id: number) {
        return this.http.delete(`${this.baseUrl()}/order-item-credit/${id}`);
    }

    addTabCredit(id: number, tabCredit: TabCredit) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409');
        return this.http.post(`${this.baseUrl()}/${id}/tab-credit`, tabCredit, {headers});
    }

    deleteTabCredit(id: number) {
        return this.http.delete(`${this.baseUrl()}/tab-credit/${id}`);
    }

    startTab(tab: Tab, cardSummary: CardSummary) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '402,406,410,415,417,423,424,451');
        return this.http.put(`${this.baseUrl()}/${tab.id}/start`, cardSummary, {headers});
    }

    startTabFromTerminal(tab: Tab, terminalId: string, referenceId: string) {
        let headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '405,406,408,410,417,423,424');
        headers = headers.set('timeout', '30000');
        return this.http.put(`${this.baseUrl()}/terminal/${terminalId}/start/${referenceId}`, tab, {headers});
    }

    startForMember(tab: Tab, memberId: number) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '402,406,410,415,417,423,424,451');
        return this.http.put(`${this.baseUrl()}/membership/${memberId}/start`, tab, {headers});
    }

    pendingTip(tab: Tab) {
        const invoiceToken = this.invoiceTokenSubject.value;
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '424');
        return this.http.put(
            `${this.baseUrl()}/${tab.id}/pending-tip${!!invoiceToken ? '?invoiceToken=' + invoiceToken : ''}`, tab, {headers}
        );
    }

    nameOverride(tab: Tab) {
        return this.http.put(
            `${this.baseUrl()}/${tab.id}/name-override`, tab
        );
    }

    taxExemptionId(tab: Tab) {
        return this.http.put(
            `${this.baseUrl()}/${tab.id}/tax-exemption-id`, tab
        );
    }

    textClaim(id: number) {
        return this.http.put(`${this.baseUrl()}/${id}/text-claim`, {});
    }

    generateClaim(id: number) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '417');
        return this.http.put(`${this.baseUrl()}/${id}/generate-claim`, {}, {headers});
    }

    claim(id: number, claimCode: string, customerAreaPositionId: number) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '417');
        return this.http.put(`${this.baseUrl()}/${id}/claim/${claimCode}`
            + (!!customerAreaPositionId ? `?customerAreaPositionId=${customerAreaPositionId}` : ''), {}, {headers});
    }

    get(id: number | string) {
        const invoiceToken = this.getInvoiceToken();
        if (typeof id === 'string' && id.includes('-')) {
            return this.http.get(`${this.baseUrl()}/key/${id}`);
        } else {
            return this.http.get(`${this.baseUrl()}/${id}${!!invoiceToken ? '?invoiceToken=' + invoiceToken : ''}`);
        }
    }

    claimByUser(id: number) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '417');
        return this.http.put(`${this.baseUrl()}/${id}/claim-by-user`, {}, {headers});
    }

    updatePerson(id: number, person: Person) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '417');
        return this.http.put(`${this.baseUrl()}/${id}/person`, person, {headers});
    }

    rewardCards(tabId: number) {
        return this.http.get(`${this.baseUrl()}/${tabId}/reward-cards`);
    }

    updateMember(id: number, member: Membership) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '417');
        return this.http.put(`${this.baseUrl()}/${id}/member`, member, {headers});
    }

    updateServer(id: number, server: Person) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '417');
        return this.http.put(`${this.baseUrl()}/${id}/server`, server, {headers});
    }

    updateCustomerPosition(tabId: number, positionId: number) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '417,424');
        return this.http.put(`${this.baseUrl()}/${tabId}/customer-area-position${!!positionId
            ? '?positionId=' + positionId
            : ''}`, {}, {headers});
    }

    async startTabErrorHandler(error: any) {
        if (error.status === 402) {
            await this.toastService.error('Prepaid cards are not supported for tabs.');
        } else if (error.status === 406 && !!error.error.message) {
            await this.toastService.error(error.error.message);
        } else if (error.status === 410) {
            await this.toastService.error('Payment Canceled');
        } else if (error.status === 415) {
            await this.toastService.error('Wallet payments are not supported for tabs.');
        } else if (error.status === 417) {
            await this.toastService.error('This tab is no longer pending and cannot accept a pre-authorization.');
        } else if (error.status === 424) {
            await this.toastService
                .error('A pre-authorization cannot be applied to a tab that has already accepted payments.');
        } else if (error.status === 451) {
            await this.toastService
                .error('A tab cannot be started from a mobile device in this establishment.');
        }
        await this.loadingService.dismiss();
    }

    setEvent(obj: Tab, force = false) {
        if (!!obj) {
            const found = this.fromCache(!!obj.id ? obj.id : obj.key);
            if (!found || force || !found.version || found.version < obj.version) {
                const current = this.openTabsSubject.value;
                const index = current.findIndex(i => i.id === obj.id);
                if (obj.status !== TabStatuses.OPEN && index > -1) {
                    current.splice(index, 1);
                } else if (obj.status === TabStatuses.OPEN && index > -1) {
                    current[index] = obj;
                } else if (obj.status === TabStatuses.OPEN) {
                    current.push(obj);
                }
                this.setOpenTabs(current);
                this.addToCache(obj);
                super.setEvent(obj);
            }
        }
    }

    convertToTabSearchResult(tab: Tab) {
        if (!!tab) {
            const searchResult = new TabSearchResult();
            searchResult.id = tab.id;
            searchResult.tabNumber = tab.tabNumber;
            searchResult.name = tab.personName;
            searchResult.serverName = !!tab.server ? tab.server.name : null;
            searchResult.email = !!tab.person ? tab.person.email : null;
            searchResult.subtotal = tab.subtotal;
            searchResult.invoiced = !!tab.invoiced ? tab.invoiced : 0;
            searchResult.tip = !!tab.paymentInformation ? tab.paymentInformation.tips : null;
            searchResult.status = tab.status;
            searchResult.startTime = moment(tab.startTime).format();
            searchResult.paymentsTotal = !!tab.paymentInformation ? tab.paymentInformation.payments : 0;
            searchResult.refundsTotal = !!tab.paymentInformation ? tab.paymentInformation.refunds : 0;
            searchResult.creditsTotal = !!tab.paymentInformation ? tab.paymentInformation.credits : 0;
            searchResult.paymentStatus = !!tab.paymentInformation ? tab.paymentInformation.paymentStatus : PaymentStatuses.UNPAID;
            searchResult.deviceId = tab.deviceId;
            return searchResult;
        }
        return null;
    }
}
