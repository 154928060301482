import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {UserOrganizationCredit} from '../_models/user-organization-credit';

@Injectable({
    providedIn: 'root'
})
export class UserOrganizationCreditService extends BaseService<UserOrganizationCredit> {
    destination = 'user-organization-credits';

    unused(orgId = null) {
        return this.http.get(`${this.baseUrl()}/current-user/unused${!!orgId ? '?orgId=' + orgId : ''}`);
    }

    findByOrgIdAndPersonId(orgId: number, personId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${orgId}/user/${personId}`);
    }
}
