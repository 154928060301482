import {BaseFormModel} from './base-form-model';

export class OrganizationPreference extends BaseFormModel<OrganizationPreference> {
    id: number;
    parentId: number;
    percent: number;
    organizationPercent: number;
    minFee: number;
    maxFee: number;
    preAuthAmount: number;
    orgId: number;
    currentTabNumber: number;
    currentOrderNumber: number;
    tabNumberLength: number;
    orderNumberLength: number;
    surcharge: number;
    surchargeIncludeTip: boolean;
    timeZone: string;
    testMode: boolean;
    allowMobileTabStart: boolean;
}
