import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {OrderItemCredit} from '../_models/order-item-credit';
import {HttpHeaders} from '@angular/common/http';
import {Constants} from '../_constants/constants';

@Injectable({
    providedIn: 'root'
})
export class OrderItemCreditService extends BaseService<OrderItemCredit> {
    destination = 'order-item-credits';

    bulk(credits: OrderItemCredit[]) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409');
        return this.http.put(this.baseUrl() + '/bulk', credits, {headers});
    }

    findByOrderItemId(orderItemId: number) {
        return this.http.get(this.baseUrl() + `/item/${orderItemId}`);
    }
}
