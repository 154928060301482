import {Component, Input} from '@angular/core';

@Component({
    selector: 'bb-labelled-item',
    templateUrl: './labelled-item.component.html',
    styleUrls: ['./labelled-item.component.scss'],
})
export class LabelledItemComponent {
    @Input() label: string;
    @Input() value: string;
    @Input() color = 'dark';
}
