<ion-card class="pointer">
    <ion-card-header>
        <ion-card-title>
            {{rating.date | date}}
            <span><bb-rating [rating]="rating.rating" size="medium"></bb-rating></span>
        </ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-label>Comments</ion-label>
                    <p>{{ !!rating.comments ? rating.comments : 'No comments' }}</p>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col>
                    <ion-label>Response</ion-label>
                    <p>{{ !!rating.response ? rating.response : 'None' }}</p>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="!!rating.userOrganizationCredit">
                <ion-col>
                    <ion-label>Response Credit</ion-label>
                    <p>{{ rating.userOrganizationCredit.amount | currency }}</p>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col class="ion-text-end">
                    <ion-icon *ngIf="!read()" name="mail-unread-outline" color="success" (click)="toggleRead($event)"></ion-icon>
                    <ion-icon *ngIf="read()" name="mail-outline" (click)="toggleRead($event)"></ion-icon>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
</ion-card>
