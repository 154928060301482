import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Order} from '../../_models/order';
import {OrderStatuses} from '../../_constants/order-statuses';
import {User} from '../../_models/user';
import {SubscriberComponent} from '../subscriber.component';
import {AlertController, ModalController} from '@ionic/angular';
import {OrderService} from '../../_services/order.service';
import {TabService} from '../../_services/tab.service';
import {UserService} from '../../_services/user.service';
import {LoadingService} from '../../_services/loading.service';
import {OrderStatusPipe} from '../_pipes/order-status.pipe';
import {ToastService} from '../../_services/toast.service';
import {SetMarkerComponent} from '../set-marker/set-marker.component';
import {OrderItem} from '../../_models/order-item';
import {PickupLocation} from '../../_models/pickup-location';
import {PickupLocationService} from '../../_services/pickup-location.service';
import {ChangeCustomerPositionComponent} from '../change-customer-position/change-customer-position.component';
import {CustomerAreaService} from '../../_services/customer-area.service';
import {CustomerArea} from '../../_models/customer-area';
import {CustomerAreaPosition} from '../../_models/customer-area-position';
import {PaymentChargeRefundService} from '../../_services/payment-charge-refund.service';
import {Tab} from '../../_models/tab';
import {SelectServerComponent} from '../select-server/select-server.component';
import {PaymentStatuses} from '../../_constants/payment-statuses';
import {FeatureKeys} from '../../_constants/feature-keys';
import {MoveOrderComponent} from '../move-order/move-order.component';
import {CompDefinition} from '../../_models/comp-definition';
import {OrganizationTerminalService} from '../../_services/organization-terminal.service';
import {MenuItemInventory} from '../../_models/menu-item-inventory';
import {MenuItemInventoryService} from '../../_services/menu-item-inventory.service';
import {PrinterService} from '../../_services/printer.service';

@Component({
    selector: 'bb-manage-order-card',
    templateUrl: './manage-order-card.component.html',
    styleUrls: ['./manage-order-card.component.scss']
})

export class ManageOrderCardComponent extends SubscriberComponent implements OnInit, OnChanges, OnDestroy {
    @Input() order: Order;
    @Input() active = false;
    @Input() details = false;
    @Input() editable = false;
    @Input() includeServerOptions = false;
    @Input() includeMoveOption = false;
    @Input() includeCustomerAreaPosition = false;
    @Input() includePaymentStatus = false;
    @Input() availableOrderComps: CompDefinition[];
    @Output() changed: EventEmitter<any> = new EventEmitter();
    @Output() layout: EventEmitter<any> = new EventEmitter();
    @Output() headerClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() viewDetails: EventEmitter<any> = new EventEmitter<any>();
    @Output() addCredit = new EventEmitter<OrderItem>();
    @Output() removeCredit = new EventEmitter<any>();
    @Output() removeTokens = new EventEmitter<OrderItem>();

    resizeObserver: ResizeObserver;
    statusGrid: any;

    @ViewChild('statusGrid', {read: ElementRef}) set content(element) {
        this.statusGrid = element.nativeElement;
        if (!this.resizeObserver) {
            this.resizeObserver = new ResizeObserver(entries => {
                entries.forEach(async () => {
                    if (!!this.statusGrid && this.statusGrid.offsetWidth > 0) {
                        this.fullText = this.statusGrid.offsetWidth > 410;
                        this.orderService.cardFullText = this.fullText;
                    }
                });
            });

            this.resizeObserver.observe(this.statusGrid);
        }
    }

    fullText: boolean = null;
    orderStatuses = OrderStatuses;
    paymentStatuses = PaymentStatuses;
    currentStatus: string;
    selectedItemIds = [];
    currentUser: User;
    pickupLocation: PickupLocation;
    terminalId: string;
    inventories: MenuItemInventory[] = [];
    canPrint = false;

    featureKeys = FeatureKeys;

    constructor(
        private alertController: AlertController,
        private modalController: ModalController,
        private orderService: OrderService,
        private customerAreaService: CustomerAreaService,
        private pickupLocationService: PickupLocationService,
        private tabService: TabService,
        private userService: UserService,
        private loadingService: LoadingService,
        private orderStatusPipe: OrderStatusPipe,
        private paymentChargeRefundService: PaymentChargeRefundService,
        private organizationTerminalService: OrganizationTerminalService,
        private menuItemInventoryService: MenuItemInventoryService,
        private printerService: PrinterService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.currentStatus = this.order.status;
        this.fullText = this.orderService.cardFullText;
        this.canPrint = this.printerService.isConnected();

        this.subscribe(this.printerService.connected.subscribe(c => this.canPrint = c));

        this.subscribe(this.organizationTerminalService.current.subscribe(r => this.terminalId = !!r ? r.terminalId : null));

        this.subscribe(this.pickupLocationService.current.subscribe(p => {
            this.pickupLocation = !!p ? new PickupLocation(p) : null;
        }));

        this.subscribe(this.userService.current.subscribe(u => {
            this.currentUser = !!u ? new User(u) : null;
        }));

        this.subscribe(this.orderService.event.subscribe(o => {
            if (!!o && o.id === this.order.id) {
                const order = new Order(o);
                order.init();
                this.order = order;
                this.currentStatus = this.order.status;
                this.changeDetectorRef.detectChanges();
                this.changed.emit();
            }
        }));

        this.subscribe(this.tabService.event.subscribe(t => {
            if (!!t && t.id === this.order.parentId) {
                this.order.customerAreaPosition = t.customerAreaPosition;
            }
        }));

        this.subscribe(this.menuItemInventoryService.pickupLocationInventory.subscribe(inventory => {
            this.inventories = inventory;
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes.order && !!changes.order.previousValue) {
            if (changes.order.previousValue.id !== changes.order.currentValue.id) {
                this.selectedItemIds = [];
            }
            this.currentStatus = changes.order.currentValue.status;
        }
    }

    ngOnDestroy(): void {
        if (!!this.resizeObserver) {
            this.resizeObserver.unobserve(this.statusGrid);
            this.resizeObserver.disconnect();
        }
        super.ngOnDestroy();
    }

    async orderReady() {
        const modal = await this.modalController.create({
            component: SetMarkerComponent
        });

        await modal.present();

        return modal.onDidDismiss();
    }

    async confirmStatusChange(newVal, deliveredChecked = false) {
        if (newVal !== this.order.status) {
            if (this.selectedItemIds.length > 0 && this.selectedItemIds.length < this.order.items.length) {
                const alert = await this.alertController.create({
                    cssClass: 'brewbill-alert',
                    header: 'Confirm Partial Order',
                    message: (newVal === OrderStatuses.CANCELED ? 'Cancelling' : 'Changing the status')
                        + ' will move the selected items into their own ' + (newVal === OrderStatuses.CANCELED ? 'canceled  ' : '')
                        + 'order. This cannot be undone.',
                    buttons: [
                        {
                            text: 'No',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                this.resetStatus();
                            }
                        }, {
                            text: 'Yes',
                            handler: async () => {
                                if (newVal === OrderStatuses.READY && this.pickupLocation.availableMarkers.length > 0) {
                                    this.orderReady().then((dataReturned) => {
                                        if (dataReturned !== null && dataReturned.data != null) {
                                            this.separate(newVal, dataReturned.data === 'none' ? null : dataReturned.data);
                                        } else {
                                            this.resetStatus();
                                        }
                                    });
                                } else {
                                    await this.separate(newVal);
                                }
                            }
                        }
                    ]
                });

                await alert.present();
            } else if (newVal === OrderStatuses.CANCELED) {
                const alert = await this.alertController.create({
                    cssClass: 'brewbill-alert',
                    header: 'Confirm Order Cancellation',
                    message: 'Are you sure you want to cancel this order? This cannot be undone.',
                    buttons: [
                        {
                            text: 'No',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                this.resetStatus();
                            }
                        }, {
                            text: 'Yes',
                            handler: async () => {
                                await this.orderStatusChange(newVal);
                            }
                        }
                    ]
                });

                await alert.present();
            } else if (this.order.status === OrderStatuses.DELIVERED && !deliveredChecked) {
                const alert = await this.alertController.create({
                    cssClass: 'brewbill-alert',
                    header: 'Confirm Status Change',
                    message: 'This order has been delivered. Are you sure you want to change this status?',
                    buttons: [
                        {
                            text: 'No',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                this.resetStatus();
                            }
                        }, {
                            text: 'Yes',
                            handler: async () => {
                                await this.confirmStatusChange(newVal, true);
                            }
                        }
                    ]
                });

                await alert.present();
            } else if (newVal === OrderStatuses.READY && this.pickupLocation.availableMarkers.length > 0) {
                this.orderReady().then((dataReturned) => {
                    if (dataReturned !== null && dataReturned.data != null) {
                        this.orderStatusChange(newVal, dataReturned.data === 'none' ? null : dataReturned.data);
                    } else {
                        this.resetStatus();
                    }
                });
            } else {
                await this.orderStatusChange(newVal);
            }
        }
    }

    async separate(newVal, marker?: number) {
        let restockItems = false;
        if (!!this.inventories) {
            const orderItems = this.order.items.filter(oi => this.selectedItemIds.some(id => id === oi.id));
            if (!!orderItems) {
                const restockCheck = this.inventories.find(i => orderItems.some(oi => !!oi.menuItem && i.menuItem.id === oi.menuItem.id));
                if (restockCheck) {
                    restockItems = await this.restock();
                }
            }
        }

        this.loadingService.present();
        this.orderService.separate(this.order.id, newVal, this.selectedItemIds.join(','), this.terminalId, restockItems, marker)
            .subscribe(async (o: Order) => {
                if (o.status !== OrderStatuses.CANCELED) {
                    await this.toastService.success(`The selected items have been moved to order ${o.id} with a status of ${this.orderStatusPipe.transform(o.status, true)}.`);
                } else if (!!o.refundFailureMessages && o.refundFailureMessages.length > 0) {
                    await this.toastService.message(new Order(o).getRefundFailureMessage());
                    this.viewDetailsClicked(null);
                }
                this.order.items = this.order.items.filter((item) => !o.items.some(i => i.id === item.id));
                this.resetStatus();
                this.selectedItemIds = [];
                this.loadingService.dismiss();
            }, error => {
                this.resetStatus();
                this.paymentChargeRefundService.errorHandler(error);
            });
    }

    async restock(): Promise<boolean> {
        let resolveFunction: (confirm: boolean) => void;
        const promise = new Promise<boolean>(resolve => {
            resolveFunction = resolve;
        });
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Restock',
            message: `Restock any inventoried items from this cancellation?`,
            buttons: [
                {
                    text: 'No',
                    handler: () => resolveFunction(false)
                },
                {
                    text: 'Yes',
                    handler: () => resolveFunction(true)
                }
            ]
        });
        await alert.present();
        return promise;
    }

    resetStatus() {
        this.currentStatus = this.order.status;
    }

    async orderStatusChange(newVal: string, marker?: number) {
        if (!!this.order.id) {
            let restockItems = false;
            if (!!this.inventories && (this.inventories.length > 0) && newVal === 'CANCELED') {
                if (!!this.order.items) {
                    const restockCheck = this.inventories.find(i => this.order.items.some(oi => !!oi.menuItem && i.menuItem.id === oi.menuItem.id));
                    if (restockCheck) {
                        restockItems = await this.restock();
                    }
                }
            }

            this.loadingService.present();
            this.orderService.updateStatus(this.order.id, newVal, this.terminalId, restockItems, marker).subscribe(async (o) => {
                this.order = new Order(o);
                if (!!this.order.refundFailureMessages && this.order.refundFailureMessages.length > 0) {
                    await this.toastService.message(this.order.getRefundFailureMessage());
                    this.viewDetailsClicked(null);
                }
                this.orderService.setEvent(this.order);
                this.loadingService.dismiss();
                this.tabService.addToIdCache(this.order.orgId, this.order.parentId);
            }, (error) => {
                this.resetStatus();
                this.paymentChargeRefundService.errorHandler(error);
            });
        }
    }

    async move() {
        const modal = await this.modalController.create({
            component: MoveOrderComponent,
            componentProps: {
                order: this.order,
                orderItemIds: this.selectedItemIds
            },
            cssClass: 'menu-modal'
        });

        await modal.present();
    }

    async assignServer() {
        const modal = await this.modalController.create({
            component: SelectServerComponent,
            componentProps: {
                tabId: this.order.parentId,
                serverId: !!this.order.server ? this.order.server.id : null
            },
            cssClass: 'menu-modal'
        });

        await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.loadingService.present();
                this.tabService.updateCustomerPosition(this.order.parentId, !!dataReturned.data ? dataReturned.data.id : null)
                    .subscribe((o: Order) => {
                        this.order.customerAreaPosition = !!o.customerAreaPosition
                            ? new CustomerAreaPosition(o.customerAreaPosition)
                            : null;
                        this.loadingService.dismiss();
                    }, error => {
                        if (error.status === 424) {
                            this.toastService.error('The selected position is invalid.');
                        } else if (error.status === 417) {
                            this.toastService.error('The tab is in an invalid status.');
                        }
                        this.loadingService.dismiss();
                    });

            }
        });
        await modal.present();
    }

    async claimServer() {
        if (!!this.order.id) {
            this.loadingService.present();
            this.tabService.updateServer(this.order.parentId, this.currentUser.person).subscribe((t: Tab) => {
                this.tabService.setEvent(new Tab(t));
                this.order.server = t.server;
                this.loadingService.dismiss();
            }, (error) => {
                this.loadingService.dismiss();
                if (error.status === 417) {
                    this.toastService.error('The server is not valid for this tab.');
                }
            });
        }
    }

    toggleSelect(orderItems: OrderItem[]) {
        let t = [...this.selectedItemIds];

        if (orderItems.length === 1) {
            const item = orderItems[0];
            const index = t.indexOf(item.id);
            if (index > -1) {
                t.splice(index, 1);
            } else {
                t.push(item.id);
            }
        } else {
            t = (this.selectedItemIds.length > 0) ? [] : [...orderItems.map(i => i.id)];
        }

        this.selectedItemIds = t;
    }

    header(event) {
        event.stopPropagation();
        this.headerClicked.emit();
    }

    viewDetailsClicked(event) {
        if (!!event) {
            event.stopPropagation();
        }
        this.viewDetails.emit();
    }

    async changeCustomerPosition(event) {
        event.stopPropagation();
        this.loadingService.present();
        this.customerAreaService.findByPickupLocationId(this.pickupLocation.id).subscribe(async (ca: CustomerArea[]) => {
            const customerAreas = !!ca ? ca.map(a => new CustomerArea(a)) : null;
            this.loadingService.dismiss();
            if (!!customerAreas && customerAreas.length > 0) {
                const modal = await this.modalController.create({
                    component: ChangeCustomerPositionComponent,
                    componentProps: {
                        customerAreas,
                        position: this.order.customerAreaPosition
                    }
                });

                await modal.present();

                await modal.onDidDismiss().then((dataReturned) => {
                    if (dataReturned !== null && dataReturned.data != null) {
                        this.loadingService.present();
                        this.tabService.updateCustomerPosition(this.order.parentId, !!dataReturned.data ? dataReturned.data.id : null)
                            .subscribe((o: Order) => {
                                this.order.customerAreaPosition = !!o.customerAreaPosition
                                    ? new CustomerAreaPosition(o.customerAreaPosition)
                                    : null;
                                this.loadingService.dismiss();
                            }, error => {
                                if (error.status === 424) {
                                    this.toastService.error('The selected position is invalid.');
                                } else if (error.status === 417) {
                                    this.toastService.error('The tab is in an invalid status.');
                                }
                                this.loadingService.dismiss();
                            });

                    }
                });
            } else {
                await this.toastService.error(`No customer areas are defined for ${this.pickupLocation.name}`);
            }
        });
    }

    print(event) {
        event.stopPropagation();
        this.printerService.printOrder(this.order);
    }

    authorizedToCancel() {
        return !!this.currentUser && this.currentUser.authorizedTo(FeatureKeys.ORDER_CANCEL, this.order.orgId);
    }
}
