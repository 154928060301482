import {Inject, Injectable} from '@angular/core';
import {LibConfig, LibConfigService} from '../lib.config';
import {HttpClient} from '@angular/common/http';
import {UserNotificationSummary} from '../_models/user-notification-summary';
import {BaseObservableService} from './base-obeservable-service';

@Injectable({
    providedIn: 'root'
})
export class UserNotificationSummaryService extends BaseObservableService<UserNotificationSummary> {
    destination = 'user-notification-summaries';

    constructor(
        @Inject(LibConfigService) config: LibConfig,
        http: HttpClient
    ) {
        super(http, config);
    }
}
