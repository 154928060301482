import {BaseFormModel} from './base-form-model';
import {TabReport} from './report/tab-report';

export class DashboardWidgetTab extends BaseFormModel<DashboardWidgetTab> {
    id: number;
    title: string;
    type: string;
    report: TabReport;
    maxRows: number;
    durationPart: string;
    durationCount: number;
    comparisonOffsetPart: string;
    comparisonOffsetCount: number;
    sortOrder: number;
}
