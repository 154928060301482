import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {BaseModalComponent} from '../base-modal.component';
import {Order} from '../../_models/order';
import {UserService} from '../../_services/user.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {OrganizationService} from '../../_services/organization.service';
import {ToastService} from '../../_services/toast.service';
import {LoadingService} from '../../_services/loading.service';
import {OrderService} from '../../_services/order.service';
import {OrderStatuses} from '../../_constants/order-statuses';
import {User} from '../../_models/user';
import {FeatureKeys} from '../../_constants/feature-keys';
import {PickupLocationService} from '../../_services/pickup-location.service';
import {Organization} from '../../_models/organization';

@Component({
    selector: 'bb-pin-login',
    templateUrl: './pin-login.component.html',
    styleUrls: ['./pin-login.component.scss'],
})
export class PinLoginComponent extends BaseModalComponent implements OnInit {
    @Input() terminal = false;
    lastOrder: Order;
    receivedWaiting = false;
    pinCode = '';

    constructor(
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private organizationService: OrganizationService,
        private pickupLocationService: PickupLocationService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private orderService: OrderService,
        private navController: NavController,
        private alertController: AlertController,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.preventBack = true;
        this.authenticationService.logout().then(() => {
            this.organizationService.getFromToken().subscribe(o => {
                if (!!o) {
                    this.organizationService.setCurrent(new Organization(o));
                }
            });
        });

        this.subscribe(this.orderService.current.subscribe(async t => {
            this.lastOrder = t;
        }));

        this.subscribe(this.orderService.activeOrders.subscribe(a => {
            this.receivedWaiting = !!a && a.some(o => o.status === OrderStatuses.RECEIVED);
        }));
    }

    typePin(value: string) {
        if (this.pinCode.length < 4) {
            this.pinCode += value;
            if (this.pinCode.length === 4) {
                this.login();
            }
        }
    }

    login() {
        this.userService.fromPinCode(this.pinCode).subscribe(async (u: User) => {
                await this.navigate(u);
            },
            async () => {
                await this.toastService.error('Invalid pin code.');
                this.pinCode = '';
            });
    }

    async navigate(u: User) {
        if (!!u) {
            const user = new User(u);
            user.modalLogin = true;
            const pickupLocation = this.pickupLocationService.currentValue;
            if (this.terminal && !!pickupLocation && user.authorizedTo(FeatureKeys.TERMINAL, pickupLocation.orgId)) {
                this.authenticationService.setReturnUrl(`/manager/${pickupLocation.id}`);
            }
            await this.authenticationService.setupUser(user, true);
            this.loadingService.dismiss();
            await this.authenticationService.loginNavigation(user, this.organizationService.currentValue);
            await this.close();
        }
    }

    formattedPinCode() {
        let val = '';
        for (let i = 0; i < this.pinCode.length; i++) {
            val += val.length === 0 ? '*' : ' *';
        }
        return val;
    }

    backspace() {
        if (this.pinCode.length > 0) {
            this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1);
        }
    }

    async logoutOrganization() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Logout',
            message: 'Logging out the organization will prevent notifications for incoming orders and tabs. Do you wish to continue?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.navController.navigateBack('/login').then(async () => {
                            await this.close();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }
}
