import {ChangeDetectorRef, Component} from '@angular/core';
import {BaseEventService} from '../_services/base-event.service';
import {ChannelSubscriberComponent} from './channel-subscriber.component';
import {ToastService} from '../_services/toast.service';
import {Organization} from '../_models/organization';
import {Menu} from '../_models/menu';
import {PickupLocationMenu} from '../_models/pickup-location-menu';
import {PickupLocation} from '../_models/pickup-location';
import {PickupLocationService} from '../_services/pickup-location.service';

@Component({
    template: ''
})
export abstract class MenuSubscriberComponent extends ChannelSubscriberComponent {
    public organization: Organization;
    public pickupLocation: PickupLocation;
    public menu: Menu;
    public pickupLocationMenu: PickupLocationMenu;

    protected constructor(
        baseEventService: BaseEventService,
        protected toastService: ToastService,
        protected pickupLocationService: PickupLocationService,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
        super(baseEventService);
    }

    ionViewWillLeave() {
        this.ngOnDestroy();
    }
}
