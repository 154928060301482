import {BaseFormModel} from './base-form-model';

export class MenuLabel extends BaseFormModel<MenuLabel> {
    id: number;
    menuId: number;
    name: string;
    description: string;
    shortLabel: string;
    color: string;
    display: boolean;
}
