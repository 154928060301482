import {BaseFormModel} from '../base-form-model';
import {OrganizationUser} from '../organization-user';

export class TabReportEmail extends BaseFormModel<TabReportEmail> {
    id: number;
    filter: string;
    cronMarker: string;
    attachmentType: string;
    recipients: OrganizationUser[];

    getDisplay() {
        let msg = 'Email report ';
        const filterArray = !!this.filter ? this.filter.substring(1, this.filter.length - 1).split('|') : [];
        switch (this.cronMarker) {
        case 'DAY':
            msg += 'nightly.';
            break;
        case 'WEEK':
            msg += 'weekly on';
            let first = true;
            filterArray.forEach(d => {
                if (!first) {
                    msg += ',';
                }
                first = false;

                switch (d) {
                case '1':
                    msg += ' Monday';
                    break;
                case '2':
                    msg += ' Tuesday';
                    break;
                case '3':
                    msg += ' Wednesday';
                    break;
                case '4':
                    msg += ' Thursday';
                    break;
                case '5':
                    msg += ' Friday';
                    break;
                case '6':
                    msg += ' Saturday';
                    break;
                case '7':
                    msg += ' Sunday';
                    break;
                }
            });
            msg += '.';
            break;
        case 'MONTH':
            msg += `monthly on day${filterArray.length > 1 ? 's' : ''} `;
            let firstDay = true;
            filterArray.forEach(d => {
                if (!firstDay) {
                    msg += ', ';
                }
                firstDay = false;
                msg += d;
            });
        }
        return msg;
    }
}
