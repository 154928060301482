import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {OrganizationRole} from '../_models/organization-role';

@Injectable({
    providedIn: 'root'
})
export class OrganizationRoleService extends BaseService<OrganizationRole> {
    destination = 'organization-roles';
}
