import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {CustomerArea} from '../_models/customer-area';

@Injectable({
    providedIn: 'root'
})
export class CustomerAreaService extends BaseService<CustomerArea> {
    destination = 'customer-areas';

    findByPickupLocationId(pickupLocationId: number) {
        return this.http.get(`${this.baseUrl()}/pickup-locations/${pickupLocationId}`);
    }
}
