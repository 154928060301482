import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';
import {MarketingPush} from '../../_models/marketing-push';

@Component({
    selector: 'bb-marketing-push-modal',
    templateUrl: './marketing-push-modal.component.html',
    styleUrls: ['./marketing-push-modal.component.css'],
})
export class MarketingPushModalComponent extends BaseModalComponent implements OnInit {
    @Input() marketingPush: MarketingPush;

    constructor(modalController: ModalController) {
        super(modalController);
    }

    ngOnInit() {
    }
}
