import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';
import {Order} from '../_models/order';
import {Menu} from '../_models/menu';
import {NavController} from '@ionic/angular';
import {Constants} from '../_constants/constants';
import {ToastService} from './toast.service';
import {OrderItem} from '../_models/order-item';
import {PickupLocation} from '../_models/pickup-location';
import {PickupLocationMenuService} from './pickup-location-menu.service';
import {BaseObservableService} from './base-obeservable-service';
import {Tab} from '../_models/tab';
import {LoadingService} from './loading.service';
import {TabService} from './tab.service';
import {getTimeBased} from '../_utils/local-storage-utils';
import {Availabilities} from '../_constants/availabilities';
import {MenuItemPrice} from '../_models/menu-item-price';
import {OrderStatuses} from '../_constants/order-statuses';
import {CustomerAreaPositionService} from './customer-area-position.service';
import {CustomerAreaPosition} from '../_models/customer-area-position';
import {PickupLocationMenu} from '../_models/pickup-location-menu';
import {DeviceService} from './device.service';
import {OrganizationService} from './organization.service';
import {Organization} from '../_models/organization';
import {CompDefinition} from '../_models/comp-definition';
import {OrganizationEvent} from '../_models/organization-event';
import {OrderItemCredit} from '../_models/order-item-credit';
import {CompDefinitionTypes} from '../_constants/comp-definition-types';
import {divideCurrency, multiplyCurrency} from '../_utils/currency-math';
import {Membership} from '../_models/membership';
import {TabNote} from '../_models/tab-note';

@Injectable({
    providedIn: 'root'
})
export class OrderService extends BaseObservableService<Order> {
    destination = 'orders';
    orderModal: any;
    viewingOrder: Order;
    customerAreaPosition: CustomerAreaPosition;
    lockOrder = false;
    deviceId: string;
    cardFullText = true;
    organization: Organization;
    autoComps: CompDefinition[];

    public orderType: Observable<string>;
    private orderTypeSubject: BehaviorSubject<string>;

    public activeOrders: Observable<Order[]>;
    private activeOrdersSubject: BehaviorSubject<Order[]>;

    constructor(
        http: HttpClient,
        private customerAreaPositionService: CustomerAreaPositionService,
        private toastService: ToastService,
        private deviceService: DeviceService,
        private pickupLocationMenuService: PickupLocationMenuService,
        private organizationService: OrganizationService,
        private loadingService: LoadingService,
        private tabService: TabService,
        private navController: NavController,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);
        this.saveToLocalStorage = true;
        const val = getTimeBased(this.destination);
        if (!!val) {
            val.preload = true;
            this.setCurrent(new Order(val));
        }

        this.orderTypeSubject = new BehaviorSubject<string>(Availabilities.IN_HOUSE);
        this.orderType = this.orderTypeSubject.asObservable();

        this.activeOrdersSubject = new BehaviorSubject<Order[]>([]);
        this.activeOrders = this.activeOrdersSubject.asObservable();

        this.deviceService.getDeviceId().then(d => this.deviceId = d);

        this.organizationService.current.subscribe(o => {
            this.organization = !!o ? new Organization(o) : null;
            this.autoComps = !!this.organization ? this.organization.compDefinitions.filter(c => c.autoApply) : [];
        });

        this.customerAreaPositionService.current.subscribe(c => this.customerAreaPosition = !!c ? new CustomerAreaPosition(c) : null);

        this.pickupLocationMenuService.current.subscribe(async pickupLocationMenu => {
            if (!!pickupLocationMenu) {
                const currentOrder = this.currentValue;
                const currentTab = this.tabService.currentValue;
                if (!this.lockOrder && !!currentOrder && currentOrder.status === OrderStatuses.PENDING
                    && pickupLocationMenu.parentId === currentOrder.pickupLocation.id
                    && !!currentOrder.items && currentOrder.items.length > 0) {
                    const sent = [];
                    currentOrder.items = currentOrder.items.filter(i => {
                        const menuItem = pickupLocationMenu.menu.menuItems.find(mi => !!i.menuItem && i.menuItem.related(mi));
                        i.selectedPrice.pickupLocationMenuItemPrice = !!pickupLocationMenu.menuItemPrices
                            ? pickupLocationMenu.menuItemPrices.find(p => p.menuItemPrice.id === i.selectedPrice.id) : null;

                        const price = new MenuItemPrice(i.selectedPrice);

                        const retVal = !menuItem
                            || (i.orderType === Availabilities.IN_HOUSE
                                && pickupLocationMenu.allowInHouse
                                && menuItem.allowInHouse()
                                && price.allowInHouse()
                                && (currentOrder.terminalOrder || menuItem.allowMobileOrdering()))
                            || (i.orderType === Availabilities.TOGO
                                && pickupLocationMenu.allowTogo
                                && menuItem.allowToGo()
                                && price.allowToGo()
                                && (currentOrder.terminalOrder || menuItem.allowMobileOrdering()));

                        if (!retVal && !sent.includes(i.menuItem.name)) {
                            this.toastService.message(`${i.menuItem.name} is no longer available and it has been removed from your current order.`);
                            sent.push(i.menuItem.name);
                        }
                        return retVal;
                    });

                    if (sent.length > 0 && currentOrder.items.length > 0) {
                        this.createPending(currentOrder).subscribe((t: Tab) => {
                            const i = !!t ? t.orders.find(u => u.id === currentOrder.id) : null;
                            this.setCurrent(!!i ? new Order(i) : null);
                        });
                    }

                    if (!!currentTab && !!currentTab.orders && currentTab.orders.length > 0) {
                        const index = currentTab.orders.findIndex(o => o.id === currentOrder.id);
                        currentTab.orders[index] = currentOrder;
                        this.tabService.setCurrent(currentTab);
                    }

                    if (currentOrder.items.length > 0) {
                        this.setCurrent(currentOrder);
                    } else {
                        this.setCurrent(null);
                    }
                }
            }
        });
    }

    refresh() {
        if (!!this.currentValue && !!this.currentValue.id) {
            this.getSilent(this.currentValue.id).subscribe((o: Order) => this.setCurrent(new Order(o)));
        }
    }

    activateOrder(order: Order) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '422');
        return this.http.post(`${this.baseUrl()}/activate`, order, {headers});
    }

    createPending(order: Order) {
        const o = new Order(order);
        o.menu = null;
        o.customerAreaPosition = this.customerAreaPosition;
        o.items.forEach(i => this.setToppings(i));

        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '417,423,424');
        return this.http.post(`${this.baseUrl()}/pending`, o, {headers});
    }

    setToppings(orderItem: OrderItem) {
        orderItem.toppings = [...orderItem.addedToppingsFilter(), ...orderItem.removedToppingsFilter()];
        orderItem.selections.forEach(s => {
            s.selectedItems.forEach(si => this.setToppings(si.orderItem));
        });
    }

    initialize(menu: Menu, pickupLocation: PickupLocation) {
        const order = new Order({});
        order.menu = menu;
        order.pickupLocation = pickupLocation;
        order.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return order;
    }

    updateStatus(id: number, status: string, terminalId: string, restock: boolean, marker?: number) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '410,422,423,428');
        let url = `${this.baseUrl()}/${id}/status/${status}?restock=${restock}`;
        if (!!terminalId) {
            url += `&terminalId=${terminalId}`;
        }
        if (!!marker) {
            url += `&marker=${marker}`;
        }
        return this.http.put(url, {}, {headers});
    }

    move(id: number, tabId: number, orderItemIds: number[] = null) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409');
        return this.http.put(`${this.baseUrl()}/${id}/move${!!tabId ? ('/' + tabId) : ''}` + (!!orderItemIds && orderItemIds.length
            ? `?selectedOrderItemIds=${orderItemIds.join(',')}`
            : ''), {}, {headers});
    }

    separate(id: number, status: string, items: string, terminalId: string, restock: boolean, marker?: number) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '410,422,423,428');
        let url = `${this.baseUrl()}/${id}/separate/${status}/${items}?restock=${restock}`;
        if (!!terminalId) {
            url += `&terminalId=${terminalId}`;
        }
        if (!!marker) {
            url += `&marker=${marker}`;
        }
        return this.http.put(url, {}, {headers});
    }

    updateOrderType(id: number, type: string) {
        return this.http.put(`${this.baseUrl()}/${id}/order-type/${type}`, {});
    }

    loadCurrent() {
        return this.http.get(`${this.baseUrl()}/current`);
    }

    loadDeviceCurrent(deviceId: string) {
        return this.http.get(`${this.baseUrl()}/current/${deviceId}`);
    }

    missed(date: Date) {
        return this.http.get(`${this.baseUrl()}/missed?last=${date.toISOString()}`);
    }

    getTab(orderId) {
        return this.http.get(`${this.baseUrl()}/${orderId}/tab`);
    }

    public getCurrentActiveOrders(): Order[] {
        const active = this.activeOrdersSubject.value;
        return !!active ? active : [];
    }

    addActiveOrder(order: Order) {
        const active = this.getCurrentActiveOrders();
        const index = active.findIndex(o => o.id === order.id);
        if (index > -1) {
            active[index] = order;
        } else {
            active.push(order);
        }
        this.setActiveOrders(active);
    }

    getOrderType() {
        return this.orderTypeSubject.getValue();
    }

    nextType(type: string) {
        this.orderTypeSubject.next(type);
    }

    setActiveOrders(orders: Order[]) {
        this.activeOrdersSubject.next(orders);
    }

    async confirmOrder(order: Order) {
        this.loadingService.present();

        // need to init to populate the toppings
        order.items.forEach(i => {
            i.init();
        });

        this.createPending(order).subscribe(async (t: Tab) => {
            const tab = new Tab(t);
            this.tabService.setCurrent(tab);
            this.tabService.setEvent(tab);
            this.setCurrent(tab.orders.find(o => o.id === order.id || (!order.id && o.deviceId === this.deviceId)));
            this.loadingService.dismiss();
            await this.navController.navigateForward(`/confirm-order/${order.pickupLocation.id}`);
        }, async error => {
            if (error.status === 424 && !!error.error && !!error.error.message) {
                const errorDetails = JSON.parse(error.error.message);
                if (!!errorDetails.unavailableItemIds) {
                    const removing = order.items.filter(i => errorDetails.unavailableItemIds.includes(i.menuItem.id));
                    order.items = order.items.filter(i => !errorDetails.unavailableItemIds.includes(i.menuItem.id));
                    let errMessage = 'Some items are no longer available and are removed from your order:<br><ul>';
                    removing.forEach(i => errMessage += `<li>${i.menuItem.name}</li>`);
                    errMessage += '</ul>';
                    await this.toastService.message(errMessage);

                    if (order.items.length > 0) {
                        this.setCurrent(order);
                    } else {
                        this.setCurrent(null);
                        this.tabService.setCurrent(null);
                    }

                    this.pickupLocationMenuService.findByPickupLocationId(order.pickupLocation.id).subscribe(m => {
                        this.pickupLocationMenuService.setCurrent(new PickupLocationMenu(m));
                    });
                }
            } else if (error.status === 417) {
                await this.toastService.error('This location is not currently taking orders.');
            } else {
                // clean out local storage
                this.tabService.setCurrent(null);
                this.setCurrent(null);
                await this.toastService.error('A server error occurred.');
            }
            this.loadingService.dismiss();
        });
    }

    handlePendingError(error) {
        if (error.status === 424 && !!error.error && !!error.error.message) {
            try {
                const errorDetails = JSON.parse(error.error.message);
                const pickupLocationMenu = this.pickupLocationMenuService.currentValue;
                if (!!errorDetails.unavailableItemIds && !!pickupLocationMenu) {
                    this.pickupLocationMenuService.findByPickupLocationId(pickupLocationMenu.parentId).subscribe(m => {
                        this.pickupLocationMenuService.setCurrent(new PickupLocationMenu(m));
                    });
                }
            } catch (err) {
                console.log('Could not parse errors');
            }
        }
    }

    setCurrentPending(tab: Tab, order: Order) {
        if (!!tab && !!tab.orders) {
            if (order.id) {
                this.setCurrent(new Order(tab.orders.find(o => o.id === order.id)));
            } else {
                this.setCurrent(new Order(tab.orders.find(o => o.deviceId === this.deviceId && o.status === OrderStatuses.PENDING)));
            }
        }
    }

    applyMembershipTokens(order: Order, activeMembership: Membership, availableTokens: number) {
        if (!!order
            && !!order.items
            && order.items.length > 0
            && !!this.organization.tokenGroups
            && this.organization.tokenGroups.length > 0) {
            if (!activeMembership) {
                order.items.forEach(i => {
                    i.tokenPayment = null;
                    i.membershipTokenGroupId = null;
                });
            } else if (!!availableTokens) {
                for (const orderItem of order.items) {
                    if (!orderItem.credits || orderItem.credits.length === 0) {
                        const tokenGroup = this.organization.tokenGroups
                            .find(g => g.items.some(i =>
                                !!orderItem.selectedPrice && i.priceId === orderItem.selectedPrice.id));

                        const availability = !!activeMembership && !!tokenGroup ?
                            activeMembership.availableTokenGroups.find(t => t.id === tokenGroup.id) : null;

                        if (!!tokenGroup
                            && !orderItem.tokenPaymentRefunded
                            && availableTokens >= tokenGroup.tokenCount
                            && !!availability
                            && (!availability.remaining || availability.remaining > availability.currentUsed)) {
                            orderItem.tokenPayment = tokenGroup.tokenCount;
                            orderItem.membershipTokenGroupId = tokenGroup.id;
                            availableTokens -= orderItem.tokenPayment;
                            availability.currentUsed++;
                        }
                    } else {
                        orderItem.tokenPayment = null;
                        orderItem.membershipTokenGroupId = null;
                    }
                }
            }
        }
    }

    applyComps(order: Order, tab: Tab, activeEvent: OrganizationEvent, activeMembership: Membership) {
        if (!!order) {
            const orders = !!tab
                ? tab.orders.filter(o => o.status !== OrderStatuses.CANCELED && o.status !== OrderStatuses.PENDING)
                : [];
            orders.push(order);
            this.applyEventComps(orders, !!tab ? tab.notes : null, activeEvent);
            this.applyMembershipComps(orders, activeMembership);
            this.updateTokenAvailability(order, activeMembership);
            order.additionalModifiedItems = !!tab && !!tab.orders
                ? tab.orders.reduce((acc, o) => acc.concat(o.items.filter(i => !!i.id && i.creditsModified)), [])
                : [];
        }
    }

    updateTokenAvailability(order: Order, activeMembership: Membership) {
        if (!!activeMembership && !!activeMembership.availableTokenGroups && !!order && !!order.items) {
            activeMembership.availableTokenGroups.forEach(t => t.currentUsed = 0);

            order.items.forEach(i => {
                if (!!i.membershipTokenGroupId) {
                    const availability = activeMembership.availableTokenGroups.find(t => t.id === i.membershipTokenGroupId);
                    if (!!availability && !!availability.remaining) {
                        availability.currentUsed++;
                    }
                }
            });
        }
    }

    applyEventComps(orders: Order[], tabNotes: TabNote[], event: OrganizationEvent) {
        if (!!event && !!event.compDefinitions && event.compDefinitions.length > 0) {
            const comps = event.compDefinitions.filter(c =>
                c.availability === 'ORDER_ITEM'
                && c.autoApply
                && (!c.requiredTabNote || (!!tabNotes && tabNotes.some(n => n.definition.id === c.requiredTabNote.id)))
            );
            const allItems = orders.reduce((acc, order) =>
                acc.concat(order.items.filter(i => !!i.menuItem && !!i.selectedPrice && !i.preventAutoCredit)), []);
            comps.forEach(c => {
                const compItems = allItems.filter(i => c.items.some(s => i.menuItem.id === s.itemId
                    && i.selectedPrice.id === s.priceId
                    && !i.credits.some((ic: OrderItemCredit) => ic.compDefinition.id !== c.id)));
                let sorted = [...compItems];
                if (c.priority === 'GREATEST') {
                    sorted = sorted.sort((a, b) => (b.selectedPrice.price - a.selectedPrice.price === 0)
                        ? a.addIndex - b.addIndex : b.selectedPrice.price - a.selectedPrice.price);
                } else if (c.priority === 'LEAST') {
                    sorted = sorted.sort((a, b) => (b.selectedPrice.price - a.selectedPrice.price === 0)
                        ? a.addIndex - b.addIndex : a.selectedPrice.price - b.selectedPrice.price);
                } else {
                    sorted = sorted.sort((a, b) => a.addIndex - b.addIndex);
                }

                allItems.forEach(orderItem => {
                    orderItem.tempCreditCount = orderItem.credits.length;
                    orderItem.credits = orderItem.credits.filter(oic => oic.compDefinition.id !== c.id || !oic.autoApplied);
                });

                const manualComped = allItems.filter(i => i.credits.some(oic => !oic.autoApplied && oic.compDefinition.id === c.id));
                const limit = (!!c.limitCount && c.limitCount <= compItems.length ? c.limitCount : compItems.length) - manualComped.length;

                for (let i = 0; i < limit; i++) {
                    this.createComp(sorted[i], c);
                }

                allItems.forEach(orderItem => orderItem.creditsModified = orderItem.credits.length !== orderItem.tempCreditCount);
            });
        }
    }

    applyMembershipComps(orders: Order[], activeMembership: Membership) {
        if (!!activeMembership && !!activeMembership.availableComps && activeMembership.availableComps.length > 0) {
            const comps = this.autoComps.filter(c => c.availability === 'ORDER_ITEM'
                && activeMembership.availableComps.some(ac => ac.id === c.id))
                .sort((a, b) => {
                    if (a.limitDurationPart === b.limitDurationPart) {
                        return b.limitDurationCount - a.limitDurationCount;
                    } else {
                        return this.durationRank(a.limitDurationPart) - this.durationRank(b.limitDurationPart);
                    }
                });

            const allItems = orders.reduce((acc, order) =>
                acc.concat(order.items.filter(i => !!i.menuItem && !!i.selectedPrice && !i.preventAutoCredit)), []);

            comps.forEach(c => {
                const compItems = allItems.filter(i => c.items.some(s => i.menuItem.id === s.itemId
                    && i.selectedPrice.id === s.priceId
                    && !i.credits.some((ic: OrderItemCredit) => ic.compDefinition.id !== c.id)));
                let sorted = [...compItems];
                if (c.priority === 'GREATEST') {
                    sorted = sorted.sort((a, b) => (b.selectedPrice.price - a.selectedPrice.price === 0)
                        ? a.addIndex - b.addIndex : b.selectedPrice.price - a.selectedPrice.price);
                } else if (c.priority === 'LEAST') {
                    sorted = sorted.sort((a, b) => (b.selectedPrice.price - a.selectedPrice.price === 0)
                        ? a.addIndex - b.addIndex : a.selectedPrice.price - b.selectedPrice.price);
                } else {
                    sorted = sorted.sort((a, b) => a.addIndex - b.addIndex);
                }

                allItems.forEach(orderItem => {
                    orderItem.tempCreditCount = orderItem.credits.length;
                    orderItem.credits = orderItem.credits.filter(oic => oic.compDefinition.id !== c.id || !oic.autoApplied);
                });

                const manualComped = allItems.filter(i => i.credits.some(oic => !oic.autoApplied && oic.compDefinition.id === c.id));
                const limit = (!!c.limitCount && c.limitCount <= compItems.length ? c.limitCount : compItems.length) - manualComped.length;

                for (let i = 0; i < limit; i++) {
                    this.createComp(sorted[i], c);
                }

                allItems.forEach(orderItem => orderItem.creditsModified = orderItem.credits.length !== orderItem.tempCreditCount);
            });
        }
    }

    createComp(orderItem: OrderItem, compDefinition: CompDefinition) {
        const credit = new OrderItemCredit({compDefinition});
        credit.addIndex = !!orderItem.credits ? orderItem.credits.reduce((max, c) => max <= c.addIndex ? c.addIndex + 1 : max, 1) : 1;
        credit.autoApplied = true;

        if (compDefinition.type === CompDefinitionTypes.DEFINED) {
            credit.amount = compDefinition.amount;
        } else if (compDefinition.type === CompDefinitionTypes.PERCENT) {
            credit.amount = multiplyCurrency(orderItem.calcTotal(), divideCurrency(compDefinition.amount, 100));
        }

        if (credit.amount > 0) {
            if (!orderItem.credits) {
                orderItem.credits = [];
            }
            orderItem.tokenPayment = null;
            orderItem.membershipTokenGroupId = null;
            orderItem.credits.push(credit);
        }
    }

    durationRank(limitDurationPart: string): number {
        switch (limitDurationPart) {
        case 'EVER':
            return 1;
        case 'YEAR':
            return 2;
        case 'MONTH':
            return 3;
        case 'WEEK':
            return 4;
        case 'DAY':
            return 5;
        case 'TAB':
            return 6;
        }
    }
}
