import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import moment from 'moment';
import {BaseModalComponent} from '../base-modal.component';
import {Timecard} from '../../_models/timecard';
import {LoadingService} from '../../_services/loading.service';
import {ToastService} from '../../_services/toast.service';
import {TimecardService} from '../../_services/timecard.service';
import {formatToHoursAndMinutes} from '../../_utils/date-utils';
import {OrganizationService} from '../../_services/organization.service';
import {Organization} from '../../_models/organization';
import {UserService} from '../../_services/user.service';
import {User} from '../../_models/user';

@Component({
    selector: 'bb-timecard',
    templateUrl: './timecard.component.html',
    styleUrls: ['./timecard.component.scss'],
})
export class TimecardComponent extends BaseModalComponent implements OnInit {
    @Input() timecard: Timecard;
    organization: Organization;
    allUsers: User[];
    today = moment().format();

    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private organizationService: OrganizationService,
        private loadingService: LoadingService,
        private alertController: AlertController,
        private toastService: ToastService,
        private timecardService: TimecardService,
        private userService: UserService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.organization = this.organizationService.currentValue;

        this.formGroup = this.formBuilder.group({
            id: !!this.timecard ? this.timecard.id : null,
            pickupLocation: !!this.timecard && !!this.timecard.pickupLocation && !!this.organization && !!this.organization.pickupLocations
                ?
                this.organization.pickupLocations.find(p => p.id === this.timecard.pickupLocation.id)
                : null,
            clockInTime: [!!this.timecard ? this.timecard.clockInTime : null, Validators.required],
            clockOutTime: !!this.timecard ? this.timecard.clockOutTime : '',
            tipped: !!this.timecard ? this.timecard.tipped : false,
            person: [!!this.timecard ? this.timecard.person : null, Validators.required],
            parentId: this.organization.id
        });

        if (!this.timecard) {
            this.loadingService.present();
            this.userService.getOrgUsers(this.organization.id).subscribe(users => {
                this.allUsers = users as User[];
                this.loadingService.dismiss();
            });
        }
    }

    clockOut() {
        this.loadingService.present();
        if (this.formGroup.valid) {
            const timecard = new Timecard(this.formGroup.value);
            timecard.clockOutTime = moment().format();
            if (!timecard.pickupLocation) {
                // need this to replace empty string
                timecard.pickupLocation = null;
            }
            this.timecardService.update(timecard).subscribe(async (t: Timecard) => {
                this.formGroup.controls.clockOutTime.setValue(t.clockOutTime);
                this.timecard = new Timecard(t);
                await this.toastService.success(`The timecard has been updated.`);
                this.loadingService.dismiss();
            });
        }
    }

    updateTimecard() {
        this.loadingService.present();
        if (this.formGroup.valid) {
            const timecard = new Timecard(this.formGroup.value);
            this.loadingService.present();
            if (!!this.timecard) {
                if (!timecard.pickupLocation) {
                    // need this to replace empty string
                    timecard.pickupLocation = null;
                }
                this.timecardService.update(timecard).subscribe(async (t: Timecard) => {
                    await this.toastService.success(`The timecard has been updated.`);
                    this.loadingService.dismiss();
                    await this.close(t);
                });
            } else {
                this.timecardService.create(timecard).subscribe(async (t: Timecard) => {
                    await this.toastService.success(`The timecard has been created.`);
                    this.loadingService.dismiss();
                    await this.close(t);
                });
            }
        }
    }

    setValue(field, val) {
        this.formGroup.get(field).setValue(val);
    }

    formatTotalTime() {
        if (!!this.formGroup.controls.clockOutTime.value) {
            return formatToHoursAndMinutes(
                moment.duration(moment(this.formGroup.controls.clockOutTime.value)
                    .diff(moment(this.formGroup.controls.clockInTime.value))));
        } else {
            return formatToHoursAndMinutes(
                moment.duration(moment()
                    .diff(moment(this.formGroup.controls.clockInTime.value))));
        }
    }
}
