import {OrderItem} from '../_models/order-item';
import {OrderItemSelection} from '../_models/order-item-selection';
import clone from 'clone';
import {OrderItemTopping} from '../_models/order-item-topping';
import {OrderItemCredit} from '../_models/order-item-credit';

export function compareItems(a: OrderItem, b: OrderItem) {
    if (!a.menuItem && !b.menuItem && a.key === b.key) {
        return true;
    }

    if (!a.menuItem
        || !b.menuItem
        || a.menuItem.id !== b.menuItem.id
        || a.orderType !== b.orderType
        || ((!!a.tokenPayment ? a.tokenPayment : 0) !== (!!b.tokenPayment ? b.tokenPayment : 0))
        || (!!a.notes && !!b.notes && a.notes !== b.notes)
        || (!a.selectedPrice && !!b.selectedPrice)
        || (!!a.selectedPrice && !b.selectedPrice)
        || (!!a.selectedPrice && !!b.selectedPrice && a.selectedPrice.id !== b.selectedPrice.id)) {
        return false;
    }

    if (!compareToppings(a.toppings, b.toppings)) {
        return false;
    }

    if (!compareComps(a.credits, b.credits)) {
        return false;
    }

    return compareSelections(a.selections, b.selections);
}

export function compareSelections(a: OrderItemSelection[], b: OrderItemSelection[]) {
    if ((!a || a.length === 0) && (!b || b.length === 0)) {
        return true;
    }

    let check = (!a && !b)
        || (!!a && !!b && a.length === b.length);

    if (check) {
        check = a.every(sel1 => !!b.find(sel2 => sel1.menuItemSelection.id === sel2.menuItemSelection.id
            && sel1.selectedItems.length === sel2.selectedItems.length
            && sel1.selectedItems.every(item1 => !!sel2.selectedItems.find(item2 => compareItems(item1.orderItem, item2.orderItem)))));
    }

    return check;
}

export function compareToppings(a: OrderItemTopping[], b: OrderItemTopping[]) {
    if ((!a || a.length === 0) && (!b || b.length === 0)) {
        return true;
    }

    let check = (!a && !b)
        || (!!a && !!b && a.length === b.length);

    if (check && a.length > 0) {
        check = a.every(top1 => !!b.find(top2 => !!top1.menuItemTopping.topping
            && !!top2.menuItemTopping.topping
            && top1.menuItemTopping.topping.id === top2.menuItemTopping.topping.id
            && top1.included === top2.included));
    }

    return check;
}

export function compareComps(a: OrderItemCredit[], b: OrderItemCredit[]) {
    if ((!a || a.length === 0) && (!b || b.length === 0)) {
        return true;
    }

    let check = (!a && !b)
        || (!!a && !!b && a.length === b.length);

    if (check && a.length > 0) {
        check = a.every(credit1 => !!b.find(credit2 =>
            !!credit1.compDefinition
            && !!credit2.compDefinition
            && credit1.compDefinition.id === credit2.compDefinition.id
            && credit1.amount === credit2.amount));
    }

    return check;
}

export function cloneItem(item: OrderItem): OrderItem {
    const cloned = new OrderItem(clone<OrderItem>(item));
    cloned.id = null;
    if (!!cloned.toppings) {
        cloned.toppings.forEach(t => {
            t.id = null;
        });
    }

    if (!!cloned.selections) {
        cloned.selections.forEach(s => {
            s.id = null;
            if (!!s.selectedItems) {
                s.selectedItems.forEach(si => {
                    si.id = null;
                    si.orderItem = cloneItem(si.orderItem);
                });
            }
        });
    }

    if (!!cloned.credits) {
        cloned.credits = [];
        cloned.creditsTotal = null;
    }

    return cloned;
}
