import {Inject, Injectable} from '@angular/core';
import {GiftCard} from '../_models/gift-card';
import {BaseService} from './base-service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';
import {BehaviorSubject, Observable} from 'rxjs';
import {Constants} from '../_constants/constants';
import {GiftCardAction} from '../_models/gift-card-action';

@Injectable({
    providedIn: 'root'
})
export class GiftCardService extends BaseService<GiftCard> {
    destination = 'gift-cards';

    public searchCriteria: Observable<any>;
    private searchCriteriaSubject: BehaviorSubject<any>;

    public loadingGiftCard: Observable<GiftCard>;
    private loadingGiftCardSubject: BehaviorSubject<GiftCard>;

    constructor(
        http: HttpClient,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);

        this.searchCriteriaSubject = new BehaviorSubject<any>({filter: ''});
        this.searchCriteria = this.searchCriteriaSubject.asObservable();

        this.loadingGiftCardSubject = new BehaviorSubject<any>(null);
        this.loadingGiftCard = this.loadingGiftCardSubject.asObservable();
    }

    create(giftCard: GiftCard) {
        let headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406,410,409');
        headers = headers.set('timeout', '30000');
        return this.http.post(this.baseUrl(), giftCard, {headers});
    }

    complete(giftCard: GiftCard) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409');
        return this.http.put(`${this.baseUrl()}/${giftCard.id}/complete`, giftCard, {headers});
    }

    action(action: GiftCardAction) {
        let headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406,410,424');
        headers = headers.set('timeout', '30000');
        return this.http.put(`${this.baseUrl()}/${action.parentId}/action`, action, {headers});
    }

    refund(action: GiftCardAction, terminal = null, cashDrawer = null) {
        let headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406,410,424');
        let params = !!terminal || !!cashDrawer ? '?' : '';
        if (!!terminal) {
            params += `terminalId=${terminal.terminalId}`;
        }
        if (!!cashDrawer) {
            params += `${!!terminal ? '&' : ''}drawerId=${cashDrawer.id}`;
        }
        return this.http.put(`${this.baseUrl()}/action/${action.id}/refund${params}`, action, {headers});
    }

    emailReceipt(actionId: number, email: string) {
        return this.http.get(`${this.baseUrl()}/action/${actionId}/receipt?email=${email}`);
    }

    findByCardNumber(organizationId: number, cardNumber: string) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/${cardNumber}`);
    }

    last(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/last`);
    }

    findActiveByOrganization(organizationId: any) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/active`);
    }

    findCompletedByOrganization(organizationId: any) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/complete`);
    }

    findAllByOrganization(organizationId: any) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}`);
    }

    findSummaryByOrganization(organizationId: any, status) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/summary${!!status ? '?status=' + status : ''}`);
    }

    setSearchCriteria(value: any) {
        this.searchCriteriaSubject.next(value);
    }

    getSearchCriteria(): any {
        return this.searchCriteriaSubject.getValue();
    }

    setLoadingGiftCard(giftCard: GiftCard) {
        this.loadingGiftCardSubject.next(giftCard);
    }

    get loadingGiftCardValue(): any {
        return this.loadingGiftCardSubject.getValue();
    }
}
