import {BaseModalComponent} from './base-modal.component';
import {ModalController} from '@ionic/angular';
import {MatStepper} from '@angular/material/stepper';
import {Component, ViewChild} from '@angular/core';

@Component({
    template: ''
})
export class BaseStepperFormComponent extends BaseModalComponent {
    @ViewChild(MatStepper) stepper: MatStepper;

    constructor(modalController: ModalController) {
        super(modalController);
    }
}
