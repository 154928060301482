<div class="iphone-x">
  <bb-menu-header id="menuPageHeader"
                  [title]="headerTitle()"
                  [editMode]="true"
                  [hideBack]="!currentItem"
                  (editClicked)="editClicked.emit(currentItem)"
                  (editModeBackClicked)="back()"></bb-menu-header>

  <ion-content class="ion-justify-content-center content">
    <bb-mobile-menu *ngIf="!currentItem"
                    [editMode]="true"
                    (addCategory)="addCategoryClicked.emit()"
                    (editCategory)="editCategoryClicked.emit($event)"
                    (removeCategory)="removeCategoryClicked.emit($event)"
                    (sortCategories)="sortCategoriesClicked.emit()">
    </bb-mobile-menu>
  </ion-content>
</div>
