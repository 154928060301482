import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {AuthenticationService} from '../../_services/authentication.service';
import {BaseModalComponent} from '../base-modal.component';
import {ForgotPasswordModalComponent} from '../forgot-password-modal/forgot-password-modal.component';
import {UserService} from '../../_services/user.service';
import {User} from '../../_models/user';

@Component({
    selector: 'bb-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent extends BaseModalComponent implements OnInit {
    @Input() email: string;
    @Input() returnUrl: string;
    loginForm: FormGroup;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private userService: UserService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.subscribe(this.userService.current.subscribe((u: User) => {
            if (!!u) {
                this.close(u);
            }
        }));

        this.loginForm = this.formBuilder.group(
            {
                email: [this.email, Validators.required],
                password: ['', Validators.required]
            }
        );
    }

    async onSubmit() {
        if (this.loginForm.invalid) {
            return;
        }

        if (!!this.returnUrl) {
            this.authenticationService.setReturnUrl(this.returnUrl);
        }
        await this.authenticationService.login(this.loginForm.controls.email.value, this.loginForm.controls.password.value, true);
    }

    async openForgotPassword() {
        const modal = await this.modalController.create({
            component: ForgotPasswordModalComponent
        });
        await modal.present();
    }
}
