export class OrganizationRatingSearchResult {
    id: number;
    userId: number;
    name: string;
    email: string;
    comments: string;
    response: string;
    rating: number;
    date: Date;
    readDate: Date;
    responseReadDate: Date;
    tabId: number;
    tabTotal: number;
    tabTip: number;
    creditAmount: number;
}
