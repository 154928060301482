import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {MatStepper} from '@angular/material/stepper';

@Component({
    selector: 'bb-modal-stepper-footer',
    templateUrl: './modal-stepper-footer.component.html',
    styleUrls: ['./modal-stepper-footer.component.css']
})
export class ModalStepperFooterComponent implements OnInit {
    @Input() stepper: MatStepper;
    @Input() disableSubmit: boolean;
    @Input() linear = false;
    @Input() tab: number;
    @Input() submitText = 'Submit';
    @Output() submitClicked: EventEmitter<any> = new EventEmitter();

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {
        if (!!this.tab) {
            this.stepper.selectedIndex = this.tab;
        }
    }

    submitButtonClicked() {
        this.submitClicked.emit(null);
    }

    async close() {
        await this.modalController.dismiss();
    }

    showPrevious() {
        return this.stepper._getFocusIndex() > 0;
    }

    showNext() {
        return this.stepper._steps.length - this.stepper._getFocusIndex() > 1;
    }

    disableNext() {
        const stepControl = this.stepper._steps.toArray()[this.stepper._getFocusIndex()].stepControl;
        return !!stepControl && !stepControl.valid;
    }

    previous(stepper: MatStepper) {
        stepper.previous();
    }

    next(stepper: MatStepper) {
        stepper.next();
    }
}
