import {BaseFormModel} from './base-form-model';
import {MenuSelectionItem} from './menu-selection-item';
import {MenuSelectionPrice} from './menu-selection-price';

export class MenuSelection extends BaseFormModel<MenuSelection> {
    id: number;
    name: string;
    description: string;
    minCount: number;
    maxCount: number;
    required: boolean;
    options: MenuSelectionItem[];
    prices: MenuSelectionPrice[];

    init(): void {
        let temp = [];
        if (!!this.options) {
            this.options.forEach(o => temp.push(new MenuSelectionItem(o)));
            this.options = temp;
        }

        if (!!this.prices) {
            temp = [];
            this.prices.forEach(o => temp.push(new MenuSelectionPrice(o)));
            this.prices = temp;
        }
    }

    get selectionMessage() {
        let message = this.required ? 'Required' : 'Optional';
        if (this.minCount > 0 && this.maxCount > this.minCount) {
            message += `, select ${this.minCount} to ${this.maxCount} options.`;
        } else if (this.minCount > 0 && this.maxCount === this.minCount) {
            message += `, select ${this.minCount} option${this.minCount > 1 ? 's' : ''}.`;
        } else if (this.minCount > 0 && this.maxCount == null) {
            message += `, select at least ${this.minCount} options.`;
        } else if ((this.minCount == null || this.minCount === 0) && this.maxCount > 0) {
            message += `, select up to ${this.maxCount} options.`;
        }
        return message;
    }
}
