<modal-header [title]="action === 'ADD' ? 'Load Gift Card' : 'Correction'"></modal-header>
<ion-content>
    <ion-grid [formGroup]="formGroup">
        <ion-row *ngIf="!existing">
            <ion-col size-xs="12">
                <ion-item>
                    <ion-label position="floating">Card Number</ion-label>
                    <ion-button *ngIf="!!prefix && formGroup.controls.cardNumber.value.length <= 6" color="success" slot="start">
                        {{ prefix }}
                    </ion-button>
                    <ion-input formControlName="cardNumber"
                               bbCardSwipe
                               class="ion-text-left top-search"
                               autocomplete="off"
                               [placeholder]="!!prefix ? 'Last 6 or swipe card' : 'Manual entry or swipe card'"
                               (ionChange)="search()"
                               #firstInput>
                    </ion-input>
                    <ion-icon name="close-outline" slot="end" (click)="clear()"></ion-icon>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!!giftCard && !!giftCard.id">
            <ion-col>
                <bb-labelled-item label="Status"
                                  value="{{giftCard.status | underscoreConversion}}"></bb-labelled-item>
            </ion-col>
            <ion-col>
                <ion-item lines="none">
                    <bb-labelled-item label="Balance"
                                      value="{{giftCard.balance | currency | negativeCurrency}}"
                                      [color]="giftCard.balance <= 0 ? 'danger' : 'dark'"></bb-labelled-item>

                    <ion-icon class="arrow" name="arrow-forward-outline" slot="end"></ion-icon>
                </ion-item>
            </ion-col>
            <ion-col>
                <bb-labelled-item label="Loaded Balance"
                                  value="{{loadedBalance() | currency | negativeCurrency}}"
                                  [color]="loadedBalance() <= 0 ? 'danger' : 'dark'"></bb-labelled-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size-xs="12">
                <ion-item>
                    <ion-label position="floating">Amount</ion-label>
                    <ion-input type="number" formControlName="initialAmount" bbCurrencyMask></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-grid class="footer-button-grid">
        <ion-row>
            <ion-col size-xs="6">
                <ion-button color="dark" class="full-footer-button" (click)="close()">
                    Cancel
                </ion-button>
            </ion-col>

            <ion-col size-xs="6">
                <ion-button color="dark" class="full-footer-button" [disabled]="formGroup.invalid" (click)="addToOrder()">
                    {{ !!giftCard ? 'Update' : 'Add To Order' }}
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>
