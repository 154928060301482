import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Tab} from '../../_models/tab';
import {addCurrency, multiplyCurrency, subtractCurrency} from '../../_utils/currency-math';
import {OrderService} from '../../_services/order.service';
import {TabService} from '../../_services/tab.service';
import {Order} from '../../_models/order';
import {DisplayOrderComponent} from '../display-order/display-order.component';
import {TabStatuses} from '../../_constants/tab-statuses';
import {LoadingService} from '../../_services/loading.service';
import {OrderStatuses} from '../../_constants/order-statuses';
import {BaseConfirmationComponent} from '../base-confirmation.component';
import {MenuItemService} from '../../_services/menu-item.service';
import {ModalController} from '@ionic/angular';
import {GiftCardAction} from '../../_models/gift-card-action';
import {Tax} from '../../_models/tax';
import {TabCredit} from '../../_models/tab-credit';
import {User} from '../../_models/user';
import {FeatureKeys} from '../../_constants/feature-keys';
import {UserService} from '../../_services/user.service';
import {CompDefinitionTypes} from '../../_constants/comp-definition-types';
import {CompDefinition} from '../../_models/comp-definition';
import {OrderItem} from '../../_models/order-item';
import {OrderItemSelectionItem} from '../../_models/order-item-selection-item';
import {CustomDollarAmountComponent} from '../custom-dollar-amount/custom-dollar-amount.component';
import {GiftCard} from '../../_models/gift-card';
import {TipComponent} from '../tip/tip.component';
import {OrderItemService} from '../../_services/order-item.service';
import {DeviceService} from '../../_services/device.service';
import {clone} from 'chart.js/helpers';
import {PickupLocationMenuService} from '../../_services/pickup-location-menu.service';
import {MembershipService} from '../../_services/membership.service';

@Component({
    selector: 'bb-confirm-tab',
    templateUrl: './confirm-tab.component.html',
    styleUrls: ['./confirm-tab.component.scss'],
})
export class ConfirmTabComponent extends BaseConfirmationComponent implements OnInit, OnChanges {
    @Input() tip: number;
    @Input() displayTipSelection = true;
    @Input() maxTip: number;
    @Input() paymentMethod: string;
    @Input() managerApp = false;
    @Input() applyingGiftCard: GiftCard;
    @Input() availableOrderComps: CompDefinition[];

    @ViewChild(DisplayOrderComponent) displayOrder: DisplayOrderComponent;
    @ViewChild(TipComponent) tipComponent: TipComponent;

    @Output() refreshTab: EventEmitter<any> = new EventEmitter();
    @Output() orderEmpty: EventEmitter<any> = new EventEmitter();
    @Output() tipChange = new EventEmitter<number>();
    @Output() dueChange = new EventEmitter<number>();
    @Output() deleteGiftCardPayment: EventEmitter<GiftCardAction> = new EventEmitter();
    @Output() deleteCredit: EventEmitter<TabCredit> = new EventEmitter();
    @Output() addCredit = new EventEmitter<OrderItem>();
    @Output() removeCredit = new EventEmitter<any>();
    @Output() amountChanged = new EventEmitter<number>();
    @Output() surchargeChanged = new EventEmitter<number>();
    @Output() removeTokens = new EventEmitter<OrderItem>();

    due = 0;
    tabStatuses = TabStatuses;
    taxes: Tax[];
    editTaxExemption = false;
    tab: Tab;
    user: User;
    tipPoolItems = [];
    tipPoolSelectionItems = [];
    amount: number;
    surcharge: number;
    tipAgainstAmount: number;

    featureKeys = FeatureKeys;
    compDefinitionTypes = CompDefinitionTypes;
    subtractCurrency = subtractCurrency;

    constructor(
        membershipService: MembershipService,
        pickupLocationMenuService: PickupLocationMenuService,
        deviceService: DeviceService,
        orderService: OrderService,
        menuItemService: MenuItemService,
        orderItemService: OrderItemService,
        loadingService: LoadingService,
        modalController: ModalController,
        private tabService: TabService,
        private userService: UserService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super(membershipService,
            pickupLocationMenuService,
            deviceService,
            menuItemService,
            orderService,
            orderItemService,
            loadingService,
            modalController);
    }

    ngOnInit() {
        this.useCached = true;

        super.ngOnInit();
        this.subscribe(this.userService.current.subscribe(u => this.user = !!u ? new User(u) : null));

        this.subscribe(this.tabService.current.subscribe(t => {
            if (!!t &&
                (!this.tab
                    || (this.tab.id === t.id && (t.status !== TabStatuses.CLOSED || this.tab.status === TabStatuses.CLOSED)))) {
                this.tab = new Tab(t);
                if (!this.tab.id && !!this.activeMembership) {
                    this.tab.person = this.activeMembership.person;
                }

                this.amount = this.tab.paymentInformation.remaining;
                this.tipAgainstAmount = this.tab.paymentInformation.subtotal;

                this.adjustIncludedTaxes();
                this.gatherTipPoolInformation();

                if (!t.orders || t.orders.length === 0) {
                    this.orderEmpty.emit();
                } else {
                    if (this.tab.status === TabStatuses.PENDING) {
                        this.order = this.tab.orders.find(o => o.status === OrderStatuses.PENDING);
                    } else {
                        this.order = new Order({status: OrderStatuses.DELIVERED});
                        this.order.items = this.tab.orders
                            .filter(o => (this.tab.status === TabStatuses.PENDING || o.status !== OrderStatuses.PENDING)
                                && o.status !== OrderStatuses.CANCELED)
                            .reduce((items, o) => {
                                return items.concat(o.items);
                            }, []);
                    }

                    if (!this.order || !this.order.items || this.order.items.length === 0) {
                        this.orderEmpty.emit();
                    }

                    this.changeDetectorRef.detectChanges();
                }
            } else if (!t && !!this.tab) {
                this.tab = null;
                this.orderEmpty.emit();
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!this.tab) {
            this.adjustIncludedTaxes();
        }
        if (!!changes.tab && !changes.tab.isFirstChange()) {
            this.order = this.tab.orders.find(o => o.status === OrderStatuses.PENDING && o.deviceId === this.deviceId);
            this.orderService.setCurrent(this.order);
        }
        this.gatherTipPoolInformation();
    }

    removeItemSave(order: Order) {
        const tab = clone<Tab>(this.tab);
        const index = tab.orders.findIndex(o => o.id === order.id
            || (order.status === OrderStatuses.PENDING && o.status === OrderStatuses.PENDING && order.deviceId === o.deviceId));
        tab.orders[index] = order;
        this.refreshTab.emit(tab);
    }

    addItemSave(order: Order) {
        const tab = clone<Tab>(this.tab);
        const index = tab.orders.findIndex(o => o.id === order.id
            || (order.status === OrderStatuses.PENDING && o.status === OrderStatuses.PENDING && order.deviceId === o.deviceId));
        tab.orders[index] = order;
        this.refreshTab.emit(tab);
    }

    refresh(t: Tab) {
        this.refreshTab.emit(t);
    }

    gatherTipPoolInformation() {
        const tipPoolItems = [];
        const tipPoolSelectionItems = [];
        if (!!this.tab && !!this.tab.orders) {
            this.tab.orders.forEach(o => {
                if (!!o.items) {
                    o.items.forEach(i => {
                        if (!!i.selectedPrice && i.selectedPrice.tipPool) {
                            tipPoolItems.push(new OrderItem(i));
                        }

                        if (!!i.selections) {
                            i.selections.forEach(s => {
                                if (!!s.selectedItems) {
                                    s.selectedItems.forEach(si => {
                                        if (!!si.orderItem && !!si.orderItem.selectedPrice && si.orderItem.selectedPrice.tipPool) {
                                            const orderItemSelectionItem = new OrderItemSelectionItem(si);
                                            const found = tipPoolSelectionItems
                                                .find(t => t.orderItem.menuItem.id === orderItemSelectionItem.orderItem.menuItem.id);
                                            if (!!found) {
                                                found.orderItem.total = addCurrency(found.orderItem.total, orderItemSelectionItem.orderItem.total);
                                            } else {
                                                tipPoolSelectionItems.push(orderItemSelectionItem);
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }
        this.tipPoolItems = tipPoolItems;
        this.tipPoolSelectionItems = tipPoolSelectionItems;
    }

    adjustIncludedTaxes() {
        if (!!this.tab && !this.tab.taxExemptionId) {

            const paymentType = !this.tab.paymentInformation.paymentType
            || this.tab.paymentInformation.paymentType === this.paymentMethod
            || this.tab.paymentInformation.paymentType === 'NONE'
                ? this.paymentMethod
                : 'MIX';

            let d = this.tab.paymentInformation.remaining;

            if (!!this.tab.taxes) {
                const taxes = [];
                this.tab.orders.forEach(o => {
                    o.items.forEach(oi => {
                        if (!!oi.menuItem && !!oi.taxes && oi.taxes.length > 0) {
                            oi.taxes.filter(t => !t.taxIncluded).forEach(tax => {
                                if (!oi.menuItem.taxOverride
                                    && ((paymentType === 'CASH' && tax.taxIncludedCash)
                                        || (paymentType === 'CHARGE' && tax.taxIncludedCharge)
                                        || (paymentType === 'GIFT_CARD' && tax.taxIncludedGiftCard))) {
                                    d = subtractCurrency(d, tax.tax);
                                } else {
                                    const found = taxes.find(tabTax => tabTax.name === tax.name
                                        && tabTax.taxRate === tax.taxRate
                                        && tabTax.taxIncluded === tax.taxIncluded);
                                    if (!found) {
                                        taxes.push(new Tax(tax));
                                    } else {
                                        found.tax = addCurrency(tax.tax, found.tax);
                                    }
                                }
                            });
                        }
                    });
                });
                this.taxes = taxes;
            }
            this.due = d;

            if (!this.amount || this.tab.paymentInformation.remaining >= d) {
                this.amount = d;
                this.amountChanged.emit(this.amount);
            } else {
                this.due = this.amount;
            }

            this.dueChange.emit(this.due);
        }
    }

    openEditTaxExemption() {
        this.editTaxExemption = true;
    }

    closeEditTaxExemption(taxExemptionId) {
        const tab = new Tab(this.tab);
        tab.taxExemptionId = !!taxExemptionId ? taxExemptionId : null;
        this.refreshTab.emit(tab);
        this.editTaxExemption = false;
    }

    updateGridLayout() {
        this.displayOrder.updateGridLayout();
    }

    tipChanged(tip: number) {
        this.tip = tip;
        this.tipChange.emit(tip);
    }

    total() {
        const total = addCurrency(this.tip, this.amount);
        if (((!!this.tab.cardOnFile && !this.paymentMethod) || this.paymentMethod === 'CHARGE') && !!this.organization
            && !!this.organization.organizationPreference && this.organization.organizationPreference.surcharge > 0) {
            this.surcharge = multiplyCurrency(this.organization.organizationPreference.surchargeIncludeTip ? total : this.amount,
                (this.organization.organizationPreference.surcharge / 100));
        } else {
            this.surcharge = 0;
        }

        return addCurrency(this.surcharge, total);
    }

    totalDue() {
        return addCurrency(this.total(), this.tab.paymentInformation.depositsRemaining);
    }

    giftCardRemaining(payment: GiftCardAction) {
        return payment.event === 'PENDING' ? payment.balance : addCurrency(payment.balance, payment.total);
    }

    async split() {
        this.tip = null;
        this.tipComponent.selectedTip = null;
        const modal = await this.modalController.create({
            component: CustomDollarAmountComponent,
            componentProps: {
                amount: '',
                max: this.tab.paymentInformation.remaining,
                label: 'Split',
                splitMax: this.tab.invoiced
            },
            breakpoints: [0.0, 1],
            initialBreakpoint: 1,
            cssClass: 'quick-input-modal'
        });
        await modal.present();

        modal.onDidDismiss().then(async (returnData) => {
            if (!!returnData && (!!returnData.data || returnData.data === 0)) {
                this.amount = Number.parseFloat(returnData.data);
                this.tipAgainstAmount = this.amount;
                this.amountChanged.emit(this.amount);
            }
        });
    }

    authorizedTo(feature: string | string[]) {
        return !!this.user && this.user.authorizedTo(feature, this.organization.id);
    }
}
