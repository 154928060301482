<div class="image-container" #imageContainer>
    <div class="menu-item-image-backdrop"
         [class.low-inventory]="!orderDisplay && terminalMode && !insufficientInventory() && warnInventory()"
         style="background: linear-gradient(180deg, {{color}} 25.52%, rgba(255, 250, 235, 0) 94.79%);">
        &nbsp;
    </div>
    <ion-badge *ngIf="orderType === availabilities.TOGO"
               [style.width]="((togoFontSize * .67) + togoFontSize) + 'px'"
               color="warning"
               class="togo-indicator">
        <ion-icon name="car-outline" [style.font-size]="togoFontSize + 'px'"></ion-icon>
    </ion-badge>

    <ng-container *ngIf="terminalMode && !orderDisplay">
        <ion-badge *ngIf="terminalMode && insufficientInventory()"
                   color="danger"
                   class="low-inventory-indicator"
                   (click)="adjustInventory($event, exactInventory)">
            <ion-icon name="alert-circle-outline" [style.font-size]="((togoFontSize / 2) + 10) + 'px'"></ion-icon>
        </ion-badge>

        <div *ngIf="isKegInventory() && !insufficientInventory() && togoFontSize > 0"
                   class="low-inventory-indicator" (click)="kickKeg($event)">
            <bb-keg-status [menuItemInventory]="inventories[0]"
                           [radius]="togoFontSize / 3"
                           backgroundColor="#000000"
                           (click)="adjustInventory($event, exactInventory)"></bb-keg-status>
        </div>

        <ion-badge *ngIf="showInventory && !isKegInventory() && !insufficientInventory() && !!exactInventory"
                   color="dark"
                   class="low-inventory-indicator"
                   (click)="adjustInventory($event, exactInventory)">
            <ion-text [style.font-size]="(togoFontSize / 2) + 'px'"
                      [style.min-width]="((togoFontSize / 2) + 10) + 'px'">{{exactInventory.currentCount}}</ion-text>
        </ion-badge>
    </ng-container>

    <img *ngIf="((!!menuItem && !!menuItem.imageAddress) || !!imageAddressOverride) && !hideImage"
         draggable="false"
         [src]="imageSrc"
         class="menu-item-image" alt=""
         (load)="imageLoaded.emit()">
    <div [hidden]="((!!menuItem && !!menuItem.imageAddress) || !!imageAddressOverride) && !hideImage" class="missing-image"
         [style.font-size]="fontSize + 'px'">
        <div class="item-name" style="color: {{contrastColor}};">{{ !!menuItem ? menuItem.name : nameOverride }}</div>
    </div>
    <div *ngIf="!!menuItem && !!menuItem.labels && menuItem.labels.length > 0" class="image-labels">
        <div *ngFor="let label of menuItem.labels" class="label-container">
            <bb-menu-label *ngIf="label.display" [menuLabel]="label.label"></bb-menu-label>
        </div>
    </div>
</div>
