import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ToastService} from '../_services/toast.service';
import {Constants} from '../_constants/constants';
import {User} from '../_models/user';
import {OrganizationService} from '../_services/organization.service';
import {VenueService} from '../_services/venue.service';
import {PickupLocationService} from '../_services/pickup-location.service';
import {UserService} from '../_services/user.service';
import {PickupLocationMenuService} from '../_services/pickup-location-menu.service';
import {AuthenticationService} from '../_services/authentication.service';
import {MenuService} from '../_services/menu.service';
import {TabService} from '../_services/tab.service';
import {OrderService} from '../_services/order.service';
import {MembershipProgramService} from '../_services/membership-program.service';
import {MembershipProgram} from '../_models/membership-program';

@Injectable({providedIn: 'root'})
export class RoleGuard implements CanActivate {
    constructor(
        private router: Router,
        private menuService: MenuService,
        private userService: UserService,
        private venueService: VenueService,
        private membershipProgramService: MembershipProgramService,
        private orderService: OrderService,
        private organizationService: OrganizationService,
        private pickupLocationService: PickupLocationService,
        private pickupLocationMenuService: PickupLocationMenuService,
        private tabService: TabService,
        private toastService: ToastService,
        private authenticationService: AuthenticationService
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let invoiceToken = !!route.queryParams ? route.queryParams.invoiceToken : null;
        if (!!invoiceToken) {
            this.tabService.setInvoiceToken(invoiceToken);
        }

        let currentUser = !!this.userService.currentValue ? new User(this.userService.currentValue) : null;

        if (!currentUser) {
            currentUser = await this.authenticationService.checkToken();
        }

        if (!!currentUser && !currentUser.login.confirmedEmail) {
            if (!this.authenticationService.getReturnUrl()) {
                this.authenticationService.setReturnUrl(state.url);
            }
            await this.router.navigate(['/confirm-email']);
        }

        const feature = route.data.feature;
        let venueId = !!route.params.venueId ? parseInt(route.params.venueId, 10) : null;
        let orgId = !!route.params.orgId ? parseInt(route.params.orgId, 10) : null;
        let pickupLocationId = !!route.params.pickupLocationId ? parseInt(route.params.pickupLocationId, 10) : null;
        const membershipProgramId = !!route.params.membershipProgramId ? parseInt(route.params.membershipProgramId, 10) : null;
        const tabId = !!route.params.tabId && !isNaN(route.params.tabId) ? parseInt(route.params.tabId, 10) : route.params.tabId;
        const tabRoute = !!tabId;

        // get current org and venue from services, if not hydrated, hydrate them
        let currentOrganization: any = this.organizationService.currentValue;
        let currentMenu: any = this.menuService.currentValue;
        let currentVenue: any = this.venueService.currentValue;
        let currentPickupLocation: any = this.pickupLocationService.currentValue;
        let currentPickupLocationMenu: any = this.pickupLocationMenuService.currentValue;
        let currentTab: any = this.tabService.currentValue;
        const currentOrder = this.orderService.currentValue;

        if (!pickupLocationId && !currentPickupLocation && !!currentOrder && !!currentOrder.pickupLocation) {
            pickupLocationId = currentOrder.pickupLocation.id;
        }

        if (!!pickupLocationId && (!currentPickupLocation || currentPickupLocation.id !== pickupLocationId)) {
            currentPickupLocation = await this.pickupLocationService.get(pickupLocationId).toPromise();
            await this.pickupLocationService.setCurrent(currentPickupLocation);
            orgId = !orgId && !!currentPickupLocation ? currentPickupLocation.orgId : orgId;
        }

        if (!!membershipProgramId) {
            const currentMembershipProgram = this.membershipProgramService.currentValue;
            if (!currentMembershipProgram || currentMembershipProgram.id !== membershipProgramId) {
                this.membershipProgramService.setCurrent(await this.membershipProgramService.get(membershipProgramId).toPromise() as MembershipProgram);
            }
        }

        if (!!tabId && (!currentTab || (currentTab.id !== tabId && currentTab.key !== tabId))) {
            currentTab = this.tabService.fromCache(tabId);
            if (!currentTab && !isNaN(tabId)) {
                currentTab = await this.tabService.get(tabId).toPromise();
                if (!!currentTab) {
                    this.tabService.addToCache(currentTab);
                }
            }

            if (!!currentTab && (tabRoute || !currentPickupLocation || currentTab.orgId === currentPickupLocation.orgId)) {
                this.tabService.setCurrent(currentTab);
            } else {
                this.tabService.setCurrent(null);
            }
        }

        if (tabRoute && !orgId && !!currentTab && !!currentTab.orgId) {
            orgId = currentTab.orgId;
        }

        if (tabRoute && !!currentTab && !currentPickupLocation) {
            if (currentTab.orders.length > 0) {
                const order = currentTab.orders[currentTab.orders.length - 1];
                currentPickupLocation = await this.pickupLocationService
                    .get(!!order.pickupLocationId ? order.pickupLocationId : order.pickupLocation.id).toPromise();
                await this.pickupLocationService.setCurrent(currentPickupLocation);
            }
        }

        if (!!pickupLocationId && (!currentPickupLocationMenu || currentPickupLocationMenu.parentId !== pickupLocationId)) {
            currentPickupLocationMenu = await this.pickupLocationMenuService.findByPickupLocationId(pickupLocationId).toPromise();
            currentPickupLocationMenu = this.pickupLocationMenuService.initialize(currentPickupLocationMenu);
            await this.pickupLocationMenuService.setCurrent(currentPickupLocationMenu);
        }

        if (!orgId && !!currentPickupLocation && !!currentPickupLocation.orgId) {
            orgId = currentPickupLocation.orgId;
        }

        if (!!orgId && (!currentOrganization || currentOrganization.id !== orgId)) {
            currentOrganization = await this.organizationService.get(orgId).toPromise();
            if (!!currentPickupLocation && !!currentOrganization) {
                this.authenticationService.defaultHome = `/menu/${currentPickupLocation.id}`;
            }
            await this.organizationService.setCurrent(currentOrganization);
        }

        if (!venueId && !!currentOrganization) {
            venueId = currentOrganization.venueId;
            if (!venueId) {
                this.venueService.setCurrent(null);
            }
        }

        if (!!venueId && (!currentVenue || currentVenue.id !== venueId)) {
            currentVenue = await this.venueService.get(venueId).toPromise();
            await this.venueService.setCurrent(currentVenue);
        }

        if (!!currentOrganization && (!currentMenu || currentMenu.orgId !== currentOrganization.id)) {
            currentMenu = await this.menuService.findByOrganizationId(currentOrganization.id).toPromise();
            await this.menuService.setCurrent(currentMenu);
        }

        if (feature == null) {
            return true;
        }
        let auth = false;

        if (!!currentUser) {
            auth = currentUser.authorizedTo(feature,
                !!currentOrganization ? currentOrganization.id : null);
        } else {
            await this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        }

        if (!auth) {
            await this.toastService.error(Constants.NOT_AUTHORIZED_ERROR);
        }
        return auth;
    }
}
