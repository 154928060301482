import {Component, Input} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';
import {Organization} from '../../_models/organization';
import {RatingPopoverComponent} from '../rating-popover/rating-popover.component';
import {OrganizationRatingService} from '../../_services/organization-rating.service';
import {ToastService} from '../../_services/toast.service';
import {OrganizationRating} from '../../_models/organization-rating';

@Component({
    selector: 'bb-display-organization-modal',
    templateUrl: './display-organization-modal.component.html',
    styleUrls: ['./display-organization-modal.component.css']
})
export class DisplayOrganizationModalComponent extends BaseModalComponent {
    @Input() organization: Organization;

    constructor(
        modalController: ModalController,
        private toastService: ToastService,
        private organizationRatingService: OrganizationRatingService
    ) {
        super(modalController);
    }

    async openMenu() {
        await this.modalController.dismiss(this.organization, '', 'displayOrganization');
    }

    async rate() {
        const modal = await this.modalController.create({
            component: RatingPopoverComponent,
            componentProps: {
                title: this.organization.name,
                image: this.organization.logoAddress
            }
        });

        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (!!dataReturned && !!dataReturned.data) {
                const rating = new OrganizationRating(dataReturned.data);
                rating.organization = this.organization;
                this.organizationRatingService.create(rating).subscribe(() => {
                    this.toastService.success(`Thank you for rating ${this.organization.name}!`);
                });
            }
        });
    }
}
