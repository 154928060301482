import {Component, Input, OnInit} from '@angular/core';
import {SubscriberComponent} from './subscriber.component';
import {MenuItemInventory} from '../_models/menu-item-inventory';
import {MenuItemInventoryService} from '../_services/menu-item-inventory.service';
import {OrganizationTerminalService} from '../_services/organization-terminal.service';
import {MenuItem} from '../_models/menu-item';
import {MenuItemPrice} from '../_models/menu-item-price';
import {ModalController} from '@ionic/angular';
import {KickKegComponent} from './kick-keg/kick-keg.component';
import {MenuItemInventoryActionComponent} from './menu-item-inventory-action/menu-item-inventory-action.component';

@Component({
    template: ''
})
export class BaseMenuItemInventoryComponent extends SubscriberComponent implements OnInit {
    @Input() menuItem: MenuItem;
    @Input() menuItemPrice: MenuItemPrice;
    inventories: MenuItemInventory[];
    backOfHouseInventories: MenuItemInventory[];
    exactInventory: MenuItemInventory;
    terminalMode = false;

    constructor(
        private modalController: ModalController,
        protected menuItemInventoryService: MenuItemInventoryService,
        protected organizationTerminalService: OrganizationTerminalService
    ) {
        super();
    }

    ngOnInit(): void {
        this.terminalMode = this.organizationTerminalService.isTerminal;

        if (!!this.menuItem && !this.menuItemPrice && !!this.menuItem.prices && this.menuItem.prices.length === 1) {
            this.menuItemPrice = this.menuItem.prices[0];
        }

        this.subscribe(this.menuItemInventoryService.pickupLocationInventory.subscribe(inventory => {
            this.inventories = !!inventory && !!this.menuItem ? inventory.filter(i => i.menuItem.id === this.menuItem.id) : [];

            if (!!this.menuItemPrice) {
                this.exactInventory = this.inventories.find(i => !!i.menuItemPrice && i.menuItemPrice.id === this.menuItemPrice.id);
            } else if (this.inventories.length === 1 && !!this.inventories[0].containerQuantity) {
                this.exactInventory = this.inventories[0];
            } else {
                this.exactInventory = this.inventories.find(i => i.menuItem.id === this.menuItem.id && !i.menuItemPrice);
            }
        }));

        this.subscribe(this.menuItemInventoryService.backOfHouseInventory.subscribe(inventories => {
            this.backOfHouseInventories = !!inventories && !!this.menuItem
                ? inventories.filter(i => !!i.menuItem && i.menuItem.id === this.menuItem.id
                    && (
                        (!this.menuItemPrice && !i.menuItemPrice)
                        || (!!this.menuItemPrice && !!i.menuItemPrice && this.menuItemPrice.id === i.menuItemPrice.id)
                    )
                ) : [];
        }));
    }

    warnInventoryPrice(price: MenuItemPrice) {
        return !!this.inventories && this.inventories.length > 0
            && !!this.inventories.some(i => !!i.menuItemPrice && i.menuItemPrice.id === price.id &&
                (!!i.alertCount || i.alertCount === 0) && i.alertCount >= i.currentCount && i.currentCount >= 0);
    }

    warnInventory() {
        return !!this.inventories && this.inventories.length > 0
            && !!this.inventories.some(i =>
                (!!i.alertCount || i.alertCount === 0) && i.alertCount >= i.currentCount && i.currentCount >= 0);
    }

    insufficientInventory() {
        const defaultPrice = (!!this.menuItem && !!this.menuItem.prices) ? this.menuItem.prices.find(p => p.defaultPrice) : null;
        return !!this.exactInventory
            && ((this.exactInventory.menuItemPrice && this.exactInventory.currentCount < this.exactInventory.byPriceCount)
                || !this.exactInventory.active
                || (!!this.exactInventory.expectedPercentageLost && !!this.exactInventory.containerQuantity && this.exactInventory.currentCount < Math.floor(this.exactInventory.containerQuantity * (this.exactInventory.expectedPercentageLost / 100)))
                || (!!defaultPrice && this.exactInventory.priceAdjustments.some(a => defaultPrice.id === a.menuItemPrice.id && this.exactInventory.currentCount < a.count))
            );
    }

    isKegInventory() {
        return !!this.exactInventory && this.exactInventory.containerQuantity > 0;
    }

    async adjustInventory(event, menuItemInventory: MenuItemInventory) {
        if (this.isKegInventory()) {
            await this.kickKeg(event);
        } else {
            event.stopPropagation();
            const modal = await this.modalController.create({
                component: MenuItemInventoryActionComponent,
                componentProps: {
                    menuItemInventory,
                    backOfHouseInventory: !!this.backOfHouseInventories
                        ? this.backOfHouseInventories.find(i => i.menuItem.id === menuItemInventory.menuItem.id
                            && ((!i.menuItemPrice && !menuItemInventory.menuItemPrice)
                                || (!!i.menuItemPrice && !!menuItemInventory.menuItemPrice && i.menuItemPrice.id === menuItemInventory.menuItemPrice.id)))
                        : null
                }
            });

            modal.onDidDismiss().then(dataReturned => {
                if (!!dataReturned && !!dataReturned.data) {
                    this.menuItemInventoryService.setEvent(dataReturned.data);
                }
            });

            await modal.present();
        }
    }

    async kickKeg(event) {
        if (!!event) {
            event.stopPropagation();
        }

        if (this.isKegInventory()) {
            const backOfHouseInventory = !!this.backOfHouseInventories && this.backOfHouseInventories.length === 1
                ? this.backOfHouseInventories[0]
                : null;
            const modal = await this.modalController.create({
                component: KickKegComponent,
                componentProps: {
                    menuItemInventory: this.inventories[0],
                    backOfHouseInventory
                }
            });
            await modal.present();
        }
    }
}
