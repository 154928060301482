import {BaseFormModel} from '../base-form-model';
import {Person} from '../person';
import {CashAudit} from './cash-audit';

export class CashSessionTipAdjustment extends BaseFormModel<CashSessionTipAdjustment> {
    id: number;
    orgId: number;
    parentId: number;
    createdBy: Person;
    amount: number;
    comment: string;
    drawerId: number;
    cashAudit: CashAudit;
}
