import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DateFilterService {
    startDate: Observable<string>;
    private startDateSubject: BehaviorSubject<string>;

    endDate: Observable<string>;
    private endDateSubject: BehaviorSubject<string>;

    constructor() {
        this.startDateSubject = new BehaviorSubject<any>(null);
        this.startDate = this.startDateSubject.asObservable();
        this.endDateSubject = new BehaviorSubject<any>(null);
        this.endDate = this.endDateSubject.asObservable();
    }

    getStartDate() {
        return this.startDateSubject.value;
    }

    setStartDate(start) {
        this.startDateSubject.next(start);
    }

    getEndDate() {
        return this.endDateSubject.value;
    }

    setEndDate(end) {
        this.endDateSubject.next(end);
    }

    reset() {
        this.endDateSubject.next(null);
        this.startDateSubject.next(null);
    }
}
