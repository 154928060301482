import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {Organization} from '../../_models/organization';
import {Menu} from '../../_models/menu';
import {MenuCategory} from '../../_models/menu-category';
import {OrderService} from '../../_services/order.service';
import {SubscriberComponent} from '../subscriber.component';
import {MenuCategoryItem} from '../../_models/menu-category-item';
import {OrganizationService} from '../../_services/organization.service';
import {PickupLocationMenuService} from '../../_services/pickup-location-menu.service';
import {PickupLocationMenu} from '../../_models/pickup-location-menu';
import {Order} from '../../_models/order';
import {ModalController} from '@ionic/angular';
import {DisplayMenuItemModalComponent} from '../display-menu-item-modal/display-menu-item-modal.component';
import {MenuItem} from '../../_models/menu-item';
import {TabService} from '../../_services/tab.service';
import {PickupLocationService} from '../../_services/pickup-location.service';
import {PickupLocation} from '../../_models/pickup-location';
import {Availabilities} from '../../_constants/availabilities';
import {OrderItemSummary} from '../../_models/order-item-summary';
import {OrganizationRewardProgram} from '../../_models/organization-reward-program';
import {UserOrganizationCreditSummary} from '../../_models/user-organization-credit-summary';
import {MenuMarqueeItem} from '../../_models/menu-marquee-item';
import {Tab} from '../../_models/tab';
import {TabStatuses} from '../../_constants/tab-statuses';
import {OrderItem} from '../../_models/order-item';
import {MenuItemService} from '../../_services/menu-item.service';
import {OrderItemService} from '../../_services/order-item.service';
import {CashSession} from '../../_models/cash-information/cash-session';
import {CashSessionService} from '../../_services/cash-session.service';
import {CompDefinition} from '../../_models/comp-definition';
import {MenuItemPrice} from '../../_models/menu-item-price';
import {OrganizationTerminalService} from '../../_services/organization-terminal.service';
import clone from 'clone';
import {MenuItemInventoryService} from '../../_services/menu-item-inventory.service';
import {MenuItemInventory} from '../../_models/menu-item-inventory';
import {MenuCategoryItemGroup} from '../../_models/menu-category-item-group';
import {MenuItemGroupSelectComponent} from '../menu-item-group-select/menu-item-group-select.component';
import {MembershipService} from '../../_services/membership.service';
import {Membership} from '../../_models/membership';
import {TabNoteDefinition} from '../../_models/tab-note-definition';
import {LoadGiftCardComponent} from '../load-gift-card/load-gift-card.component';
import {GiftCard} from '../../_models/gift-card';
import {GiftCardService} from '../../_services/gift-card.service';
import {MembershipProgramFeeStructure} from '../../_constants/membership-program-fee-structure';
import {subtractCurrency} from '../../_utils/currency-math';

@Component({
    selector: 'bb-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent extends SubscriberComponent implements OnInit, OnDestroy {
    @Input() editMode = false;
    @Input() tab: Tab;
    @Input() applyNotes: TabNoteDefinition[];
    @Input() order: Order;
    @Input() showTabInfo = false;
    @Input() activeCredit: UserOrganizationCreditSummary;
    @Input() availableTabComps: CompDefinition[];
    @Input() availableOrderComps: CompDefinition[];
    @Output() removeTokens = new EventEmitter<OrderItem>();
    @Output() removeOrderItems: EventEmitter<any> = new EventEmitter();
    @Output() addOrderItems: EventEmitter<any> = new EventEmitter();
    @Output() addCategory: EventEmitter<any> = new EventEmitter();
    @Output() editCategory: EventEmitter<any> = new EventEmitter();
    @Output() removeCategory: EventEmitter<any> = new EventEmitter();
    @Output() sortCategories: EventEmitter<any> = new EventEmitter();
    @Output() payNow: EventEmitter<any> = new EventEmitter();
    @Output() clearCart: EventEmitter<any> = new EventEmitter();
    @Output() updateItem: EventEmitter<OrderItem> = new EventEmitter<OrderItem>();
    @Output() addToSummary: EventEmitter<OrderItemSummary> = new EventEmitter<OrderItemSummary>();
    @Output() removeFromSummary: EventEmitter<OrderItemSummary> = new EventEmitter<OrderItemSummary>();
    @Output() removeItemSummary: EventEmitter<OrderItemSummary> = new EventEmitter<OrderItemSummary>();
    @Output() notesChanged: EventEmitter<TabNoteDefinition[]> = new EventEmitter();
    @Output() selectMember: EventEmitter<any> = new EventEmitter();
    @Output() removeMember: EventEmitter<any> = new EventEmitter();
    @Output() renewMembership: EventEmitter<OrderItem> = new EventEmitter<OrderItem>();
    @Output() addCredit: EventEmitter<OrderItem> = new EventEmitter<OrderItem>();
    @Output() removeCredit = new EventEmitter<any>();

    @ViewChild('orderContainer') orderContainer: ElementRef;
    @ViewChildren('menuCategoryContainer') categoryContainers: QueryList<any>;
    @ViewChildren('categorySegmentButton') categorySegmentButtons: QueryList<any>;

    noteDefinitions: TabNoteDefinition[];
    rewardProgram: OrganizationRewardProgram;
    scrollAreaElement: any;
    categoryTableOfContentsElement: any;
    marginOffset = 0;
    organization: Organization;
    pickupLocation: PickupLocation;
    pickupLocationMenu: PickupLocationMenu;
    menu: Menu;
    type: string;
    menuCategories: MenuCategory[];
    featuredItems: MenuCategoryItem[];
    // order: Order;
    showLeftScroll = false;
    showRightScroll = false;
    currentCategory: MenuCategory;
    scrollLock = false;
    catScrollLock = false;
    orderAdded = false;
    menuMarqueeItems: MenuMarqueeItem[];
    terminalMode = false;
    cashSession: CashSession;
    showImages = true;
    showInventory = true;
    sortAlpha = false;
    inventories: MenuItemInventory[] = [];
    activeMembership: Membership;
    availableTokens = 0;

    resizeObserver: ResizeObserver;
    tabStatuses = TabStatuses;

    constructor(
        private cashSessionService: CashSessionService,
        private giftCardService: GiftCardService,
        private orderService: OrderService,
        private menuItemService: MenuItemService,
        private orderItemService: OrderItemService,
        private tabService: TabService,
        private membershipService: MembershipService,
        private organizationTerminalService: OrganizationTerminalService,
        private pickupLocationMenuService: PickupLocationMenuService,
        private organizationService: OrganizationService,
        private pickupLocationService: PickupLocationService,
        private menuItemInventoryService: MenuItemInventoryService,
        private modalController: ModalController,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    @ViewChild('categoryTableOfContents') set categoryTableOfContents(element) {
        this.categoryTableOfContentsElement = element;
    }

    @ViewChild('scrollArea') set scrollArea(element) {
        this.scrollAreaElement = element.nativeElement;
        if (!this.resizeObserver) {
            this.resizeObserver = new ResizeObserver(entries => {
                entries.forEach(async () => {
                    await this.calculatePadding();
                });
            });

            this.resizeObserver.observe(this.scrollAreaElement);
        }
    }

    ngOnInit() {
        this.terminalMode = this.organizationTerminalService.isTerminal;
        this.inventories = this.menuItemInventoryService.getPickupLocationInventory();
        this.organization = this.organizationService.currentValue;
        this.rewardProgram = this.organization.rewardProgram;
        this.pickupLocation = this.pickupLocationService.currentValue ? new PickupLocation(this.pickupLocationService.currentValue) : null;
        this.cashSession = !!this.cashSessionService.currentValue ? new CashSession(this.cashSessionService.currentValue) : null;
        this.showImages = !this.terminalMode || this.pickupLocationMenuService.showImagesCurrent;

        const loadingGiftCard = this.giftCardService.loadingGiftCardValue;

        if (this.terminalMode) {
            const showInventory = localStorage.getItem('MENU_INVENTORY');
            this.showInventory = !!showInventory && showInventory !== 'false';

            const sortAlpha = localStorage.getItem('MENU_SORT_ALPHA');
            this.sortAlpha = !!sortAlpha && sortAlpha !== 'false';
        }

        if (this.terminalMode) {
            this.subscribe(this.pickupLocationMenuService.showImages.subscribe(s => this.showImages = s));
        }

        this.subscribe(this.organizationService.current.subscribe(async o => {
            this.organization = new Organization(o);
            this.rewardProgram = this.organization.rewardProgram;
        }));

        this.subscribe(this.pickupLocationService.current.subscribe(p => {
            this.pickupLocation = !!p ? new PickupLocation(p) : null;
        }));

        this.subscribe(this.cashSessionService.current.subscribe(cs => {
            this.cashSession = !!cs ? new CashSession(cs) : null;
        }));

        this.subscribe(this.pickupLocationMenuService.current.subscribe(async p => {
            if (!!p) {
                this.pickupLocationMenu = this.pickupLocationMenuService.initialize(p);
                this.menu = new Menu(this.pickupLocationMenu.menu);

                await this.filterType();

                if (!!this.order && this.order.pickupLocation.id !== this.pickupLocationMenu.parentId) {
                    this.orderService.setCurrent(null);
                    this.tabService.setCurrent(null);
                }
            }
        }));

        this.subscribe(this.orderService.current.subscribe(o => {
            if (!!o) {
                if (!!this.pickupLocationMenu && o.pickupLocation.id !== this.pickupLocationMenu.parentId) {
                    this.orderService.setCurrent(null);
                    this.tabService.setCurrent(null);
                } else {
                    this.order = new Order(o);
                    this.orderAdded = true;
                }
            } else {
                this.order = null;
            }

            this.availableTokens = (!!this.activeMembership ? this.activeMembership.availableTokens : 0)
                - (!!this.order ? this.order.tokenPayments() : 0);
        }));

        this.subscribe(this.menuItemInventoryService.pickupLocationInventory.subscribe(inventory => {
            this.inventories = inventory;
            this.filterType();
        }));

        this.subscribe(this.membershipService.current.subscribe(m => {
            this.activeMembership = !!m ? new Membership(m) : null;

            this.availableTokens = (!!this.activeMembership ? this.activeMembership.availableTokens : 0)
                - (!!this.order ? this.order.tokenPayments() : 0);
        }));

        this.calculatePadding();

        if (!!loadingGiftCard) {
            this.loadGiftCard(null, loadingGiftCard);
            this.giftCardService.setLoadingGiftCard(null);
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.resizeObserver.unobserve(this.scrollAreaElement);
    }

    saveViewImages() {
        this.pickupLocationMenuService.setShowImages(this.showImages);
    }

    saveShowInventory() {
        localStorage.setItem('MENU_INVENTORY', Boolean(this.showInventory).toString());
    }

    toggleSortAlphabetical() {
        localStorage.setItem('MENU_SORT_ALPHA', Boolean(this.sortAlpha).toString());
        this.filterType();
    }

    async calculatePadding() {
        await this.changeDetectorRef.detectChanges();

        if (!!this.scrollAreaElement && !!this.categoryTableOfContentsElement && !!this.categoryContainers
            && this.categoryContainers.length > 0 && !!this.menuCategories && this.menuCategories.length > 1) {
            const parentHeight = this.scrollAreaElement.offsetHeight;
            this.categoryContainers.forEach(c => c.el.style.marginBottom = '0');

            const last = this.categoryContainers.last.el;
            if (!!last && !this.terminalMode) {
                const delta = parentHeight - last.offsetHeight;

                if (delta > 0) {
                    this.marginOffset = delta > 75 ? delta : 75 - delta;
                } else {
                    this.marginOffset = 75;
                }

                last.style.marginBottom = `${this.marginOffset}px`;
            }
        }
    }

    checkMode(menuItem: MenuItem) {
        const defaultPrice = menuItem.prices.find(p => p.defaultPrice);
        if (this.terminalMode
            && !menuItem.selectionRequired
            && !!defaultPrice
            && (
                (this.pickupLocationMenu.allowInHouse
                    && menuItem.allowInHouse()
                    && defaultPrice.allowInHouse()) ||
                (this.pickupLocationMenu.allowTogo
                    && menuItem.allowToGo()
                    && defaultPrice.allowToGo())
            )) {

            const orderItem = this.orderItemService.initializeOrderItem(menuItem, null);
            if (!!this.activeMembership
                && !!this.availableTokens
                && !!this.activeMembership.activeMembershipProgram
                && !!this.organization.tokenGroups) {

                const tokenGroup = this.organization.tokenGroups
                    .find(g => g.items.some(i => i.priceId === orderItem.selectedPrice.id));

                const availability = !!this.activeMembership && !!tokenGroup ?
                    this.activeMembership.availableTokenGroups.find(t => t.id === tokenGroup.id) : null;

                if (!!tokenGroup
                    && !orderItem.tokenPaymentRefunded
                    && this.availableTokens >= tokenGroup.tokenCount
                    && !!availability
                    && (!availability.remaining || availability.remaining > availability.currentUsed)) {
                    orderItem.tokenPayment = tokenGroup.tokenCount;
                    orderItem.membershipTokenGroupId = tokenGroup.id;
                } else {
                    orderItem.tokenPayment = null;
                }
            }

            if (orderItem.selectedPrice.availability === Availabilities.ALL
                || orderItem.selectedPrice.availability === Availabilities.IN_HOUSE) {
                orderItem.orderType = Availabilities.IN_HOUSE;
            } else {
                orderItem.orderType = Availabilities.TOGO;
            }
            this.addOrderItems.emit([orderItem]);
        } else {
            this.openItemModal(menuItem);
        }
    }

    addWithPrice(menuItem: MenuItem, price: MenuItemPrice, forceModal = false) {
        if (!menuItem.selectionRequired
            && (
                (this.pickupLocationMenu.allowInHouse
                    && menuItem.allowInHouse()
                    && price.allowInHouse()) ||
                (this.pickupLocationMenu.allowTogo
                    && menuItem.allowToGo()
                    && price.allowToGo())
            )) {
            const orderItem = this.orderItemService.initializeOrderItem(menuItem, null);
            orderItem.selectedPrice = price;
            if (orderItem.selectedPrice.availability === Availabilities.ALL
                || orderItem.selectedPrice.availability === Availabilities.IN_HOUSE) {
                orderItem.orderType = Availabilities.IN_HOUSE;
            } else {
                orderItem.orderType = Availabilities.TOGO;
            }
            if (forceModal) {
                this.openItemModal(menuItem, price);
            } else {
                this.addOrderItems.emit([orderItem]);
            }
        } else {
            this.openItemModal(menuItem, price);
        }
    }

    async openItemModal(menuItem: MenuItem, initialPrice = null) {
        const modal = await this.modalController.create({
            component: DisplayMenuItemModalComponent,
            componentProps: {
                menuItem,
                initialPrice,
                rewardProgram: this.rewardProgram,
                activeCredit: this.activeCredit,
                terminalMode: this.terminalMode,
                showInventory: this.showInventory
            },
            cssClass: 'menu-item-modal'
        });
        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (!!dataReturned && !!dataReturned.data) {
                this.addOrderItems.emit(dataReturned.data);
            }
        });
    }

    async openEditOrderItemSummary(orderItemSummary: OrderItemSummary) {
        const orderItem = new OrderItem(orderItemSummary.items[0]);
        if (!!orderItem.giftCard) {
            this.loadGiftCard(orderItem);
        } else if (!!orderItem.membershipRenewal) {
            this.renewMembership.emit(orderItem);
        } else {
            orderItem.count = orderItemSummary.items.length;
            let menuItem = null;
            if (!!orderItem.menuItem) {
                menuItem = new MenuItem(await this.menuItemService.get(orderItem.menuItem.id).toPromise());
            }

            const modal = await this.modalController.create({
                component: DisplayMenuItemModalComponent,
                componentProps: {
                    menuItem,
                    orderItem,
                    rewardProgram: this.rewardProgram,
                    activeCredit: this.activeCredit,
                    terminalMode: this.terminalMode
                },
                cssClass: 'menu-item-modal'
            });
            await modal.present();

            modal.onDidDismiss().then((dataReturned) => {
                if (!!dataReturned && !!dataReturned.data) {
                    const returnedItems: OrderItem[] = dataReturned.data;
                    this.removeOrderItems.emit(orderItemSummary.items);
                    this.addOrderItems.emit(returnedItems);
                }
            });
        }
    }

    orderCount(item: MenuCategoryItem) {
        if (!!this.order) {
            return this.order.items.filter(i => i.menuItem.id === item.menuItem.id).length;
        }
        return 0;
    }

    async filterType() {
        if (this.checkMenuType() && !!this.pickupLocationMenu && !!this.menu) {
            this.menuCategories = clone<MenuCategory[]>(this.menu.menuCategories.filter(c => !c.featured));

            this.menuCategories.forEach(category => {
                category.menuItems = category.menuItems.filter(
                    item => {
                        const inventories = !this.terminalMode && !!this.inventories && !!item.menuItem
                            ? this.inventories.filter(i => i.menuItem.id === item.menuItem.id)
                            : [];

                        return (this.terminalMode || item.menuItem.allowMobileOrdering())
                            && (
                                ((!this.pickupLocationMenu || this.pickupLocationMenu.allowInHouse)
                                    && item.menuItem.allowInHouse()
                                )
                                || ((!this.pickupLocationMenu || this.pickupLocationMenu.allowTogo)
                                    && item.menuItem.allowToGo()
                                )
                            )
                            && (this.terminalMode || !inventories || inventories.length === 0
                                || inventories.some(i => i.currentCount > 0 && i.active
                                    && (!i.expectedPercentageLost
                                        || (i.currentCount > Math.floor(i.containerQuantity * (i.expectedPercentageLost / 100))))
                                )
                            );
                    }
                );

                category.menuItemGroups = category.menuItemGroups.filter(
                    itemGroup => {
                        itemGroup.menuItemGroup.menuItems = itemGroup.menuItemGroup.menuItems.filter(item => {
                            const inventories = !this.terminalMode && !!this.inventories && !!item.menuItem
                                ? this.inventories.filter(i => i.menuItem.id === item.menuItem.id)
                                : [];

                            return (this.terminalMode || item.menuItem.allowMobileOrdering())
                                && (
                                    ((!this.pickupLocationMenu || this.pickupLocationMenu.allowInHouse)
                                        && item.menuItem.allowInHouse()
                                    )
                                    || ((!this.pickupLocationMenu || this.pickupLocationMenu.allowTogo)
                                        && item.menuItem.allowToGo()
                                    )
                                )
                                && (this.terminalMode || !inventories || inventories.length === 0
                                    || inventories.some(i => i.currentCount > 0 && i.active
                                        && (!i.expectedPercentageLost
                                            || (i.currentCount > Math.floor(i.containerQuantity * (i.expectedPercentageLost / 100))))
                                    )
                                );
                        });

                        return itemGroup.menuItemGroup.menuItems.length > 0;
                    }
                );

                category.options = [...category.menuItems, ...category.menuItemGroups].sort((a: any, b: any) => a.sortOrder - b.sortOrder);

                if (this.sortAlpha) {
                    category.options = category.options.sort((a, b) => {
                        const aName = this.isMenuCategoryItemGroup(a) ? a.menuItemGroup.name.toLowerCase() : a.menuItem.name.toLowerCase();
                        const bName = this.isMenuCategoryItemGroup(b) ? b.menuItemGroup.name.toLowerCase() : b.menuItem.name.toLowerCase();
                        return aName.localeCompare(bName);
                    });
                }
            });

            this.menuCategories = this.menuCategories.filter(c => c.menuItems.length > 0);

            if (!this.editMode) {
                this.menuCategories = this.menuCategories.filter(category => category.menuItems.length > 0);
            }

            if (this.menuCategories.length > 0) {
                this.currentCategory = this.menuCategories[0];

                this.menuCategories.forEach(category => {
                    category.menuItems.forEach(mci => {
                        const prices = clone<MenuItemPrice[]>(mci.menuItem.prices);
                        mci.menuItem.availablePrices = prices.filter(price => {
                            return (this.terminalMode || mci.menuItem.allowMobileOrdering())
                                && ((this.pickupLocationMenu.allowInHouse && price.allowInHouse())
                                    || (this.pickupLocationMenu.allowTogo && price.allowToGo()));
                        });
                    });
                });
            }

            const cat = this.menu.menuCategories.find(category => category.featured);
            if (!!cat) {
                this.featuredItems = cat.menuItems.filter(item => !!this.pickupLocationMenu
                    && !!item.menuItem.imageAddress
                    && item.menuItem.imageAddress.length > 0
                    && (this.terminalMode || item.menuItem.allowMobileOrdering())
                    && ((this.pickupLocationMenu.allowInHouse && item.menuItem.allowInHouse())
                        || (this.pickupLocationMenu.allowTogo && item.menuItem.allowToGo())
                    ));
            }

            if (!!this.menu.menuMarqueeItems) {
                this.menuMarqueeItems = this.menu.menuMarqueeItems.filter(item => {
                    return !!this.pickupLocationMenu
                        && (this.terminalMode || item.menuItem.allowMobileOrdering())
                        && ((this.pickupLocationMenu.allowInHouse && item.menuItem.allowInHouse())
                            || (this.pickupLocationMenu.allowTogo && item.menuItem.allowToGo())
                        );
                });
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    editCategoryClicked(event, cat: MenuCategory) {
        event.stopPropagation();
        this.editCategory.emit(cat);
    }

    startBlankTab() {
        this.payNow.emit('START_BLANK_TAB');
    }

    payNowClicked(event, paymentType: string = null) {
        event.stopPropagation();
        this.payNow.emit(paymentType);
    }

    removeCategoryClicked(event, cat: MenuCategory) {
        event.stopPropagation();
        this.removeCategory.emit(cat);
    }

    scrollCategoryTableOfContents() {
        const target = this.categoryTableOfContentsElement.el;
        this.showLeftScroll = target.scrollLeft !== 0;
        this.showRightScroll = target.scrollWidth - target.clientWidth !== target.scrollLeft;
    }

    async scrollToCat(cat: MenuCategory) {
        if (!this.catScrollLock && !!this.categoryTableOfContentsElement) {
            this.scrollLock = true;
            const tocBottom = this.categoryTableOfContentsElement.el.getBoundingClientRect().bottom;
            const foundCat = this.categoryContainers.find(b => b.el.id === cat.id.toString(10));
            const objTop = foundCat.el.getBoundingClientRect().top;
            await this.scrollAreaElement.scrollBy({
                left: 0,
                top: objTop - tocBottom,
                behavior: 'instant'
            });
            this.scrollLock = false;
        }
    }

    setCatScrollLock(lock: boolean) {
        this.catScrollLock = lock;
    }

    parentScroll() {
        if (this.menuCategories.length > 1) {
            if (!this.scrollLock) {
                const tocBottom = this.categoryTableOfContentsElement.el.getBoundingClientRect().bottom;
                const found: any = this.categoryContainers.find((catContainer: any) => {
                    const bounds = catContainer.el.getBoundingClientRect();
                    return bounds.top - tocBottom - 10 + bounds.height >= 0;
                });

                if (!!found && (!this.currentCategory || found.el.id !== this.currentCategory.id)) {
                    this.currentCategory = this.menuCategories.find(cat => cat.id.toString(10) === found.el.id);
                    if (this.categoryTableOfContentsElement.el.scrollWidth > this.categoryTableOfContentsElement.el.clientWidth) {
                        const buttonBounds = this.categorySegmentButtons
                            .find(b => b.value === this.currentCategory).el.getBoundingClientRect();

                        const tocBounds = this.categoryTableOfContentsElement.el.getBoundingClientRect();
                        if (buttonBounds.left < 0) {
                            this.categoryTableOfContentsElement.el.scrollBy({
                                left: buttonBounds.left,
                                top: 0,
                                behavior: 'smooth'
                            });
                        } else if (buttonBounds.right > tocBounds.right) {
                            this.categoryTableOfContentsElement.el.scrollBy({
                                left: buttonBounds.right - tocBounds.right,
                                top: 0,
                                behavior: 'smooth'
                            });
                        }
                    }
                }
            }
        }
    }

    checkMenuType() {
        if (!!this.pickupLocationMenu) {
            if (!this.pickupLocationMenu.allowInHouse
                && this.pickupLocationMenu.allowTogo
                && this.type === Availabilities.IN_HOUSE) {
                this.orderService.nextType(Availabilities.TOGO);
                return false;
            } else if (!this.pickupLocationMenu.allowTogo
                && this.pickupLocationMenu.allowInHouse
                && this.type === Availabilities.TOGO) {
                this.orderService.nextType(Availabilities.IN_HOUSE);
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    scrollToSummary(itemSummary: OrderItemSummary) {
        if (!!this.orderContainer && !!itemSummary) {
            setTimeout(() => {
                const element = document.getElementById(`item_summary_${itemSummary.key}`);
                if (!!element) {
                    element.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
                }
            }, 150);
        }
    }

    async openItemGroup(event, itemGroup: MenuCategoryItemGroup) {
        event.stopPropagation();

        const modal = await this.modalController.create({
            component: MenuItemGroupSelectComponent,
            componentProps: {
                itemGroup: itemGroup.menuItemGroup,
                rewardProgram: this.rewardProgram,
                activeCredit: this.activeCredit,
                terminalMode: this.terminalMode,
                showInventory: this.showInventory,
                editMode: this.editMode,
                pickupLocationMenu: this.pickupLocationMenu
            },
            cssClass: 'menu-item-modal'
        });
        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (!!dataReturned && !!dataReturned.data) {
                this.addOrderItems.emit(dataReturned.data);
            }
        });
    }

    isMenuCategoryItemGroup(item: any) {
        return !!item && !!item.menuItemGroup;
    }

    selectNoteDefinitions() {
        this.notesChanged.emit(this.noteDefinitions);
    }

    async loadGiftCard(orderItem: OrderItem, giftCard = null) {
        if (!giftCard) {
            giftCard = !!orderItem && !!orderItem.giftCard ? orderItem.giftCard : null;
        }
        const modal = await this.modalController.create({
            component: LoadGiftCardComponent,
            componentProps: {
                giftCard,
                organization: this.organization,
                amount: !!orderItem && !!orderItem.giftCard ? orderItem.giftCard.initialAmount : null,
                fromOrder: true,
                action: 'ADD'
            }
        });

        modal.onDidDismiss().then((dataReturned) => {
            if (!!dataReturned && !!dataReturned.data) {
                let orderItemIndex = !!orderItem ? orderItem.addIndex : null;
                if (!orderItemIndex) {
                    orderItemIndex = (!!this.order && !!this.order.items && this.order.items.length > 0) ?
                        this.order.items.reduce((max, item) => max = (max > item.addIndex) ? max : item.addIndex + 1, 0)
                        : 1;
                }
                const updated = this.orderItemService.initializeOrderItem(null, null);
                updated.giftCard = new GiftCard(dataReturned.data);
                updated.total = updated.giftCard.initialAmount;
                updated.menuItem = null;
                updated.selectedPrice = null;
                updated.addIndex = orderItemIndex;

                updated.membershipHostFee = updated.membershipRenewal.months;
                updated.membershipOrganizationFee = updated.membershipRenewal.membershipProgram.feeStructure === MembershipProgramFeeStructure.DUAL
                    ? updated.membershipHostFee : 0;

                if (updated.membershipRenewal.membershipProgram.feeStructure === MembershipProgramFeeStructure.ABSORBED) {
                    // for now just apply $1/month make this configurable in the future
                    updated.total = subtractCurrency(updated.total, updated.membershipHostFee);
                    updated.membershipFeeIncluded = true;
                }

                if (!!orderItem) {
                    this.removeOrderItems.emit([orderItem]);
                }
                this.addOrderItems.emit([updated]);
            }
        });

        await modal.present();
    }
}
