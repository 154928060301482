import {BaseFormModel} from './base-form-model';
import {OrganizationRole} from './organization-role';
import {FeatureKeys} from '../_constants/feature-keys';

export class OrganizationUser extends BaseFormModel<OrganizationUser> {
    id: number;
    roles: OrganizationRole[];
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    pinCode: string;
    orgId: number;
    admin: boolean;
    owner: boolean;

    authorizedTo(feature: string) {
        if (this.owner) {
            return true;
        } else if (!!feature && feature === FeatureKeys.ORG_OWNER) {
            return false;
        }

        if (this.admin) {
            return true;
        } else if (!!feature && feature === FeatureKeys.ORG_ADMIN) {
            return false;
        }

        if (!!this.roles && this.roles.length > 0) {
            if (!feature) {
                return !!this.roles && this.roles.length > 0;
            }

            if (!!this.roles && this.roles.length > 0) {
                return this.roles.some(r => r.features.some(f => f.key === feature));
            }
        }
        return false;
    }
}
