import {Injectable} from '@angular/core';
import {MenuCategoryItem} from '../_models/menu-category-item';
import {SortableService} from './sortable-service';

@Injectable({
    providedIn: 'root'
})
export class MenuCategoryItemService extends SortableService<MenuCategoryItem> {
    destination = 'menu-category-items';

    findPopular(orgId: number, type: string) {
        return this.http.get(this.baseUrl() + `/${orgId}/popular/${type}`);
    }

    findContentBasedRecommendation(orgId: number, type: string) {
        return this.http.get(this.baseUrl() + `/${orgId}/cbr/${type}`);
    }
}
