import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ToastService} from './toast.service';
import {LoadingService} from './loading.service';
import {Order} from '../_models/order';
import {OrderItem} from '../_models/order-item';
import {formattedDate, formatToHoursAndMinutes} from '../_utils/date-utils';
import {Organization} from '../_models/organization';
import {CashSessionSummary} from '../_models/cash-information/cash-session-summary';
import {CashSession} from '../_models/cash-information/cash-session';
import {CurrencyPipe, DatePipe, formatDate} from '@angular/common';
import {buildLineItem, lineSeparator} from '../_utils/receipt-utils';
import {Timecard} from '../_models/timecard';
import {Availabilities} from '../_constants/availabilities';
import {Tab} from '../_models/tab';
import {LibConfig, LibConfigService} from '../lib.config';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class EpsonPrinterService {
    ePosDevice: any;
    ipAddress = null;
    columns = 35;

    public connected: Observable<boolean>;
    private connectedSubject: BehaviorSubject<boolean>;

    constructor(
        @Inject(LibConfigService) private config: LibConfig,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe
    ) {
        // @ts-ignore
        this.ePosDevice = new window.epson.ePOSDevice();

        this.ipAddress = localStorage.getItem('PRINTER_IP');
        const col = localStorage.getItem('PRINTER_COLUMNS');
        this.columns = !!col ? parseInt(col, 10) : 35;

        this.connectedSubject = new BehaviorSubject<boolean>(!!this.ipAddress);
        this.connected = this.connectedSubject.asObservable();

        if (!!this.ipAddress) {
            this.connect(this.ipAddress, true, this.columns);
        }
    }

    async connect(ipAddress: string, silent: boolean, columns) {
        if (!silent) {
            this.loadingService.present('Connecting printer.');
        }

        try {
            this.ePosDevice.connect(ipAddress, 8008, (data) => {
                if (data === 'OK') {
                    this.connectedSubject.next(true);
                    if (!silent) {
                        this.loadingService.dismiss();
                        this.toastService.success(`Connected to ${ipAddress}`);
                        this.ipAddress = ipAddress;
                        this.columns = columns;
                        localStorage.setItem('PRINTER_IP', ipAddress);
                        localStorage.setItem('PRINTER_COLUMNS', columns.toString());
                        this.connectedSubject.next(true);
                    }
                } else {
                    this.connectedSubject.next(false);
                    if (!silent) {
                        this.loadingService.dismiss();
                        this.toastService.error(`Could not connect to printer at ${ipAddress}.`);
                    }
                }
            }, error => {
                this.connectedSubject.next(false);
                console.log(error);
                if (!silent) {
                    this.loadingService.dismiss();
                    this.toastService.error(`Could not connect to printer at ${ipAddress}.`);
                }
            });
        } catch (error) {
            this.loadingService.dismiss();
            this.toastService.error(`Could not connect to printer at ${ipAddress}.`);
        }
    }

    disconnect() {
        this.connectedSubject.next(false);
        this.ePosDevice.disconnect();
        this.ipAddress = null;
        localStorage.removeItem('PRINTER_IP');
    }

    openDrawer() {
        this.ePosDevice.connect(this.ipAddress, 8008, (data) => {
            if (data === 'OK') {
                this.ePosDevice.createDevice(
                    'local_printer',
                    this.ePosDevice.DEVICE_TYPE_PRINTER,
                    {crypto: true, buffer: false},
                    (printerDevice, retcode) => {
                        if (retcode === 'OK' || retcode === 'SSL_CONNECT_OK') {
                            try {
                                printerDevice.addPulse();
                                printerDevice.onreceive = (res) => {
                                    console.log(res);
                                };
                                printerDevice.onerror = (err) => {
                                    console.log(err);
                                };
                                printerDevice.send(this.randomId());
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    }
                );
            }
        }, error => {
            console.log(error);
        });
    }

    addImage(printerDevice: any) {
        try {
            const canvas = document.getElementById('canvas') as HTMLCanvasElement;
            if (!!canvas && canvas.getContext) {
                const context = canvas.getContext('2d');
                const color = 'COLOR_1';
                const mode = 'MODE_MONO';
                const brightness = '1.0';
                const halftone = 'HALFTONE_DITHER';
                printerDevice.brightness = brightness;
                printerDevice.halftone = printerDevice[halftone];
                printerDevice.addImage(context, 0, 0, canvas.width, canvas.height, printerDevice[color], printerDevice[mode]);
            }
        } catch (e) {
            console.log(e.message, false);
        }
    }

    print(msg) {
        this.loadingService.present('Printing.');
        console.log(msg);
        this.ePosDevice.connect(this.ipAddress, 8008, (data) => {
            if (data === 'OK') {
                this.ePosDevice.createDevice(
                    'local_printer',
                    this.ePosDevice.DEVICE_TYPE_PRINTER,
                    {crypto: true, buffer: true},
                    (printerDevice, retcode) => {
                        if (retcode === 'OK' || retcode === 'SSL_CONNECT_OK') {
                            try {
                                printerDevice.addTextAlign(printerDevice.ALIGN_CENTER);
                                printerDevice.addLogo(32, 32);
                                printerDevice.addTextAlign(printerDevice.ALIGN_LEFT);
                                printerDevice.addText(msg);
                                this.send(printerDevice);
                            } catch (error) {
                                console.log(error);
                                this.loadingService.dismiss();
                                this.toastService.error('Could not print order');
                            }
                            this.loadingService.dismiss();
                        } else {
                            this.toastService.error(`Could not connect to printer at ${this.ipAddress}.`);
                        }
                        this.loadingService.dismiss();
                    }
                );
            } else {
                this.toastService.error(`Could not connect to printer at ${this.ipAddress}.`);
                this.loadingService.dismiss();
            }
        }, error => {
            console.log(error);
            this.loadingService.dismiss();
        });
    }

    printClaimTab(tab: Tab) {
        this.ePosDevice.connect(this.ipAddress, 8008, (data) => {
            if (data === 'OK') {
                this.ePosDevice.createDevice(
                    'local_printer',
                    this.ePosDevice.DEVICE_TYPE_PRINTER,
                    {crypto: true, buffer: false},
                    (printerDevice, retcode) => {
                        if (retcode === 'OK' || retcode === 'SSL_CONNECT_OK') {
                            try {
                                if (!!printerDevice) {
                                    printerDevice.addTextAlign(printerDevice.ALIGN_CENTER);
                                    printerDevice.addLogo(32, 32);

                                    printerDevice.addTextSize(2, 1);
                                    printerDevice.addText(`\nThank you ${tab.personName}!\n\n`);
                                    printerDevice.addTextSize(1, 1);
                                    printerDevice.addText(`Scan the QR code to manage your tab from your phone.\n\n`);

                                    printerDevice.addSymbol(`${this.config.mobileUrl}/#/claim-tab/${tab.id}/${tab.claimCode}`, printerDevice.SYMBOL_QRCODE_MODEL_2, printerDevice.LEVEL_M, 8, 8, 0, 0, true);

                                    this.send(printerDevice);
                                }
                            } catch (error) {
                                console.log(error);
                                this.toastService.error('Could not print claim');
                            }
                        } else {
                            this.toastService.error(`Could not connect to printer at ${this.ipAddress}.`);
                        }
                    }
                );
            } else {
                this.toastService.error(`Could not connect to printer at ${this.ipAddress}.`);
                this.loadingService.dismiss();
            }
        }, error => {
            console.log(error);
        });
    }

    printCashSession(cashSession: CashSession, summary: CashSessionSummary, organization: Organization) {
        this.loadingService.present('Printing.');
        this.ePosDevice.connect(this.ipAddress, 8008, (data) => {
            if (data === 'OK') {
                this.ePosDevice.createDevice(
                    'local_printer',
                    this.ePosDevice.DEVICE_TYPE_PRINTER,
                    {crypto: true, buffer: false},
                    (printerDevice, retcode) => {
                        if (retcode === 'OK' || retcode === 'SSL_CONNECT_OK') {
                            try {
                                printerDevice.addTextSmooth(true);
                                printerDevice.addTextAlign(printerDevice.ALIGN_CENTER);
                                printerDevice.addText(organization.name + '\n\n');
                                printerDevice.addTextAlign(printerDevice.ALIGN_RIGHT);
                                printerDevice.addText(`${formattedDate(cashSession.pendedTime)}\n`);
                                this.addSeparator(printerDevice);
                                printerDevice.addTextAlign(printerDevice.ALIGN_LEFT);

                                printerDevice.addText('\nSales\n');
                                this.addSeparator(printerDevice);
                                printerDevice.addText(buildLineItem('Cash:',
                                    this.currencyPipe.transform(summary.cashSalesNet), 0, this.columns));
                                printerDevice.addText(buildLineItem('Charge:',
                                    this.currencyPipe.transform(summary.chargePrincipal), 0, this.columns));
                                printerDevice.addText(buildLineItem('Gift Card:',
                                    this.currencyPipe.transform(summary.giftCardPrincipal), 0, this.columns));
                                printerDevice.addText(buildLineItem('Comps:',
                                    this.currencyPipe.transform(summary.creditAmount), 0, this.columns));
                                printerDevice.addText(buildLineItem('Total:',
                                    this.currencyPipe.transform(summary.total), 0, this.columns));
                                this.addSeparator(printerDevice);

                                printerDevice.addText('\nTips\n');
                                this.addSeparator(printerDevice);
                                printerDevice.addText(buildLineItem('Cash:',
                                    this.currencyPipe.transform(summary.cashTipIn), 0, this.columns));
                                printerDevice.addText(buildLineItem('Charge:',
                                    this.currencyPipe.transform(summary.chargeTip), 0, this.columns));
                                printerDevice.addText(buildLineItem('Gift Card:',
                                    this.currencyPipe.transform(summary.giftCardTip), 0, this.columns));
                                printerDevice.addText(buildLineItem('Adjustments:',
                                    this.currencyPipe.transform(summary.tipAdjustments), 0, this.columns));
                                printerDevice.addText(buildLineItem('Total:',
                                    this.currencyPipe.transform(summary.tipTotal), 0, this.columns));
                                this.addSeparator(printerDevice);

                                if (!!cashSession.cashDrawers && cashSession.cashDrawers.length > 0) {
                                    cashSession.cashDrawers.forEach(d => {
                                        printerDevice.addText(`\n${d.name}\n`);
                                        this.addSeparator(printerDevice);
                                        printerDevice.addText(buildLineItem('Final Count:', this.currencyPipe.transform(d.endAmount),
                                            0, this.columns));
                                        if (d.ones) {
                                            printerDevice.addText(buildLineItem('Ones:', d.ones.toString(10), 0, this.columns));
                                        }
                                        if (d.fives) {
                                            printerDevice.addText(buildLineItem('Fives:', d.fives.toString(10), 0, this.columns));
                                        }
                                        if (d.tens) {
                                            printerDevice.addText(buildLineItem('Tens:', d.tens.toString(10), 0, this.columns));
                                        }
                                        if (d.twenties) {
                                            printerDevice.addText(buildLineItem('Twenties:', d.twenties.toString(10), 0, this.columns));
                                        }
                                        if (d.fifties) {
                                            printerDevice.addText(buildLineItem('Fifties:', d.fifties.toString(10), 0, this.columns));
                                        }
                                        if (d.hundreds) {
                                            printerDevice.addText(buildLineItem('Hundreds:', d.hundreds.toString(10), 0, this.columns));
                                        }
                                        if (d.pennies) {
                                            printerDevice.addText(buildLineItem('Pennies:', d.pennies.toString(10), 0, this.columns));
                                        }
                                        if (d.nickels) {
                                            printerDevice.addText(buildLineItem('Nickels:', d.nickels.toString(10), 0, this.columns));
                                        }
                                        if (d.dimes) {
                                            printerDevice.addText(buildLineItem('Dimes:', d.dimes.toString(10), 0, this.columns));
                                        }
                                        if (d.quarters) {
                                            printerDevice.addText(buildLineItem('Quarters:', d.quarters.toString(10), 0, this.columns));
                                        }
                                        this.addSeparator(printerDevice);
                                    });
                                }

                                if (!!cashSession.notes) {
                                    cashSession.notes.forEach(note => {
                                        printerDevice.addText(buildLineItem(note.createdBy.name,
                                            this.datePipe.transform(note.createDate), 0, this.columns));
                                        printerDevice.addText(note.note + '\n', 0, this.columns);
                                        this.addSeparator(printerDevice);
                                    });
                                }
                                this.send(printerDevice);
                            } catch (error) {
                                console.log(error);
                                this.loadingService.dismiss();
                                this.toastService.error('Could not print summary');
                            }
                            this.loadingService.dismiss();
                        } else {
                            this.toastService.error(`Could not connect to printer at ${this.ipAddress}.`);
                        }
                        this.loadingService.dismiss();
                    }
                );
            } else {
                this.toastService.error(`Could not connect to printer at ${this.ipAddress}.`);
                this.loadingService.dismiss();
            }
        }, error => {
            console.log(error);
            this.loadingService.dismiss();
        });
    }

    printTimeCard(timecard: Timecard, organization: Organization) {
        this.loadingService.present('Printing.');
        this.ePosDevice.connect(this.ipAddress, 8008, (data) => {
            if (data === 'OK') {
                this.ePosDevice.createDevice(
                    'local_printer',
                    this.ePosDevice.DEVICE_TYPE_PRINTER,
                    {crypto: true, buffer: false},
                    (printerDevice, retcode) => {
                        if (retcode === 'OK' || retcode === 'SSL_CONNECT_OK') {
                            try {
                                printerDevice.addTextSmooth(true);
                                printerDevice.addTextAlign(printerDevice.ALIGN_LEFT);
                                printerDevice.addText(organization.name + '\n');
                                printerDevice.addText(timecard.person.name + '\n');
                                printerDevice.addTextAlign(printerDevice.ALIGN_LEFT);
                                this.addSeparator(printerDevice);
                                printerDevice.addText(buildLineItem('Clock In:',
                                    formatDate(timecard.clockInTime, 'M/d/yy h:mm a', 'en-US'), 0, this.columns));

                                if (!!timecard.clockOutTime) {
                                    printerDevice.addText(buildLineItem('Clock Out:',
                                        formatDate(timecard.clockOutTime, 'M/d/yy h:mm a', 'en-US'), 0, this.columns));

                                    printerDevice.addText(buildLineItem('Duration:',
                                        formatToHoursAndMinutes(
                                            moment.duration(+new Date(timecard.clockOutTime) - +new Date(timecard.clockInTime))),
                                        0, this.columns));

                                    if (!!timecard.cashTips) {
                                        printerDevice.addText(buildLineItem('Cash Tips:',
                                            this.currencyPipe.transform(timecard.cashTips), 0, this.columns));
                                    }

                                    if (!!timecard.payrollTips) {
                                        printerDevice.addText(buildLineItem('Payroll Tips:',
                                            this.currencyPipe.transform(timecard.payrollTips), 0, this.columns));
                                    }
                                }

                                this.send(printerDevice);
                            } catch (error) {
                                console.log(error);
                                this.loadingService.dismiss();
                                this.toastService.error('Could not print summary');
                            }
                            this.loadingService.dismiss();
                        } else {
                            this.toastService.error(`Could not connect to printer at ${this.ipAddress}.`);
                        }
                        this.loadingService.dismiss();
                    }
                );
            } else {
                this.toastService.error(`Could not connect to printer at ${this.ipAddress}.`);
                this.loadingService.dismiss();
            }
        }, error => {
            console.log(error);
            this.loadingService.dismiss();
        });
    }

    printOrder(order: Order, items: OrderItem[], silent = false) {
        if (!silent) {
            this.loadingService.present('Printing order.');
        }
        this.ePosDevice.connect(this.ipAddress, 8008, (data) => {
            if (data === 'OK') {
                this.ePosDevice.createDevice(
                    'local_printer',
                    this.ePosDevice.DEVICE_TYPE_PRINTER,
                    {crypto: true, buffer: false},
                    (printerDevice, retcode) => {
                        if (retcode === 'OK' || retcode === 'SSL_CONNECT_OK') {
                            try {
                                if (!!printerDevice) {
                                    printerDevice.addTextSmooth(true);
                                    printerDevice.addTextAlign(printerDevice.ALIGN_CENTER);

                                    if (!!order.orderMarker) {
                                        printerDevice.addTextSize(5, 6);
                                        printerDevice.addText(order.orderMarker + '\n\n');
                                        printerDevice.addTextSize(1, 1);
                                    }
                                    printerDevice.addText(order.pickupLocation.name + '\n\n');

                                    this.addSeparator(printerDevice);

                                    printerDevice.addTextAlign(printerDevice.ALIGN_LEFT);
                                    items.forEach(item => {
                                        printerDevice.addTextSize(1, 2);
                                        if (item.orderType === Availabilities.TOGO) {
                                            printerDevice.addText('TOGO\n');
                                        }
                                        let msg = !!item.menuItem ? item.menuItem.name : 'Misc';
                                        if (!!item.selectedPrice && item.selectedPrice.name !== 'Default') {
                                            msg += ` (${item.selectedPrice.name})`;
                                        }
                                        printerDevice.addText(msg + '\n');

                                        this.addItemDetails(printerDevice, item);

                                        printerDevice.addText('\n');
                                    });

                                    this.addSeparator(printerDevice);

                                    printerDevice.addTextAlign(printerDevice.ALIGN_RIGHT);
                                    printerDevice.addText(formattedDate(order.date) + '\n');

                                    this.send(printerDevice);
                                } else {
                                    if (!silent) {
                                        this.loadingService.dismiss();
                                        this.toastService.error('No printer is connected.');
                                    }
                                }
                            } catch (error) {
                                console.log(error);
                                if (!silent) {
                                    this.loadingService.dismiss();
                                    this.toastService.error('Could not print order');
                                }
                            }
                            if (!silent) {
                                this.loadingService.dismiss();
                            }
                        } else {
                            if (!silent) {
                                this.toastService.error(`Could not connect to printer at ${this.ipAddress}.`);
                            }
                        }
                        if (!silent) {
                            this.loadingService.dismiss();
                        }
                    }
                );
            } else {
                if (!silent) {
                    this.toastService.error(`Could not connect to printer at ${this.ipAddress}.`);
                    this.loadingService.dismiss();
                }
            }
        }, error => {
            console.log(error);
            if (!silent) {
                this.loadingService.dismiss();
            }
        });
    }

    addItemDetails(printerDevice: any, item: OrderItem, additionalPadding = '') {
        printerDevice.addTextSize(1, 1);
        if (item.removedToppingsFilter().length > 0) {
            printerDevice.addText(`${additionalPadding}  Remove: ${item.compactRemovedToppings()}\n`);
        }
        if (item.addedToppingsFilter().length > 0) {
            printerDevice.addText(`${additionalPadding}  Add: ${item.compactAddedToppings()}\n`);
        }
        if (!!item.selections && item.selections.length > 0) {
            item.selections.forEach(selection => {
                printerDevice.addText(`${additionalPadding}  ${selection.menuItemSelection.menuSelection.name}\n`);
                selection.selectedItems.forEach(si => {
                    printerDevice.addText(`${additionalPadding}    ${!!si.orderItem.menuItem ? si.orderItem.menuItem.name : 'Misc'}\n`);
                    this.addItemDetails(printerDevice, si.orderItem, additionalPadding + '    ');
                });
            });
        }
    }

    addSeparator(printerDevice) {
        printerDevice.addText(lineSeparator(this.columns));
    }

    send(printerDevice) {
        printerDevice.addCut();
        printerDevice.onreceive = (res) => {
            console.log(res);
            this.loadingService.dismiss();
        };
        printerDevice.onerror = (err) => {
            console.log(err);
            this.toastService.error(err.status);
            this.loadingService.dismiss();
        };
        printerDevice.send(this.randomId());
    }

    randomId() {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 6) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    isConnected() {
        return this.connectedSubject.value;
    }
}
