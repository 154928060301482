import {Component, Input} from '@angular/core';
import {TabReportResult} from '../../../_models/report/tab-report-result';
import {Column} from '../../../_models/report/column';
import {Sort} from '../../../_models/report/sort';
import {TabReportService} from '../../../_services/tab-report.service';
import {CurrencyPipe, DatePipe, PercentPipe} from '@angular/common';
import {BaseWidgetComponent} from '../base-widget.component';
import {ColumnFormats, isNumberFormat} from '../../../_constants/column-formats';
import {NavController} from '@ionic/angular';
import {OrganizationService} from '../../../_services/organization.service';
import {UnderscoreConversionPipe} from '../../_pipes/underscore-conversion.pipe';
import {ToastService} from '../../../_services/toast.service';

@Component({
    selector: 'bb-report-table',
    templateUrl: './report-table.component.html',
    styleUrls: ['./report-table.component.scss'],
})
export class ReportTableComponent extends BaseWidgetComponent {
    @Input() pageSize = 100;
    @Input() includeCount = false;
    currentPage = 0;

    tabReportResult: TabReportResult;
    rowLabelColumns: Column[];
    reportColumns: Column[];
    sort: Sort;
    minWidth: number;

    isNumberFormat = isNumberFormat;
    columnFormats = ColumnFormats;

    constructor(
        private tabReportService: TabReportService,
        private navController: NavController,
        private organizationService: OrganizationService,
        private toastService: ToastService,
        currencyPipe: CurrencyPipe,
        percentPipe: PercentPipe,
        datePipe: DatePipe,
        underscoreConversionPipe: UnderscoreConversionPipe
    ) {
        super(currencyPipe, percentPipe, datePipe, underscoreConversionPipe);
    }

    changeSort(column: Column) {
        if (!!this.sort && column.accessKey === this.sort.column.accessKey) {
            this.sort.sortDirection = this.sort.sortDirection === 'ASC' ? 'DESC' : 'ASC';
        } else {
            this.sort = new Sort({
                column,
                sortDirection: 'ASC'
            });
        }
        this.refresh();
    }

    onScroll() {
        if (this.tabReportResult.totalPages > this.currentPage) {
            this.tabReportService.run(this.tabReport.id, this.currentPage + 1, this.pageSize, this.startDate, this.endDate,
                this.sort, this.selectedCategories, this.selectedPickupLocations)
                .subscribe((trr: TabReportResult) => {
                    this.tabReportResult.data = this.tabReportResult.data.concat(trr.data);
                    this.currentPage++;
                });
        }
    }

    refresh() {
        this.currentPage = 0;

        this.dataLoading.emit();

        this.tabReportService.run(this.tabReport.id, this.currentPage, this.pageSize, this.startDate, this.endDate,
            this.sort, this.selectedCategories, this.selectedPickupLocations, true)
            .subscribe((trr: TabReportResult) => {
                this.tabReportResult = trr;
                this.rowLabelColumns = this.tabReportResult.columns.filter(c => c.rowLabel);
                this.reportColumns = this.tabReportResult.columns.filter(c => !c.rowLabel);
                this.minWidth = this.reportColumns.length * 150;

                if (!!this.tabReportResult.sort && this.tabReportResult.sort.length > 0) {
                    this.sort = this.tabReportResult.sort[0];
                }

                this.loading = false;
                this.error = false;
                this.dataReady.emit();
            }, error => {
                this.loading = false;
                this.error = true;
            });
    }

    viewTab(row) {
        if (row.id > 0) {
            if (this.tabReportResult.rowKey === 'TAB_ID') {
                this.navController.navigateForward(`/report/${this.tabReport.orgId}/${this.tabReport.id}/tab/${row.id}`);
            } else if (this.tabReportResult.rowKey === 'ORDER_ID') {
                this.navController.navigateForward(`/report/${this.tabReport.orgId}/${this.tabReport.id}/order/${row.id}/tab`);
            } else if (this.tabReportResult.rowKey === 'ORDER_ITEM_ID') {
                this.navController.navigateForward(`/report/${this.tabReport.orgId}/${this.tabReport.id}/order-item/${row.id}/tab`);
            }
        }
    }

    getKey(index: number, obj: any) {
        return obj.key;
    }

    onClipboardCopy(success: boolean, copied: string) {
        if (success) {
            this.toastService.success(`Copied ${copied}`);
        }
    }
}
