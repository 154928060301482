import {BaseFormModel} from './base-form-model';

export class Address extends BaseFormModel<Address> {
    id: number;
    addr1: string;
    addr2: string;
    addr3: string;
    city: string;
    postalCode: string;
    state: string;
    latitude: number;
    longitude: number;
}
