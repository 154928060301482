import {BaseFormModel} from './base-form-model';
import {MenuItemPrice} from './menu-item-price';
import {MenuItemTopping} from './menu-item-topping';
import {MenuItemSelection} from './menu-item-selection';
import {MenuTaxLabel} from './menu-tax-label';
import {PickupLocationMenuItemPrice} from './pickup-location-menu-item-price';
import {Availabilities} from '../_constants/availabilities';
import {MenuItemLabel} from './menu-item-label';

export class MenuItem extends BaseFormModel<MenuItem> {
    id: number;
    orgId: number;
    name: string;
    sku: string;
    flavorText: string;
    description: string;
    priceLabel: string;
    labels: MenuItemLabel[];
    taxLabels: MenuTaxLabel[];
    prices: MenuItemPrice[];
    toppings: MenuItemTopping[];
    selections: MenuItemSelection[];
    availability: string;
    imageAddress: string;
    featuredColor: string;
    selectionRequired: boolean;
    verifyAge: boolean;
    originId: number;
    defaultPrice: MenuItemPrice;
    terminalOnly: boolean;
    taxOverride: string;

    pickupLocationMenuItemPrices: PickupLocationMenuItemPrice[];
    pickupLocationMenuAvailability: string;
    pickupLocationMenuAvailabilityStatus: string;
    pickupLocationMenuInHouseAvailability: string;
    pickupLocationMenuTogoAvailability: string;
    pickupLocationMenuMobileOrdering: string;
    menuCategoryIds: number[];
    menuSelectionIds: number[];
    availablePrices: MenuItemPrice[];
    allPrices: boolean;

    init(): void {
        let temp = [];
        if (!!this.prices) {
            this.prices.forEach(p => temp.push(new MenuItemPrice(p)));
            this.prices = temp;
        }

        temp = [];
        if (!!this.selections) {
            this.selections.forEach(s => temp.push(new MenuItemSelection(s)));
            this.selections = temp;
        }

        temp = [];
        if (!!this.toppings) {
            this.toppings.forEach(s => temp.push(new MenuItemTopping(s)));
            this.toppings = temp;
        }
    }

    isVariable() {
        return !!this.prices && this.prices.some(p => p.variable);
    }

    percentageOfParent() {
        if (!!this.prices) {
            const price = this.prices.find(p => p.defaultPrice && p.percentageOfParent);
            if (price) {
                return price.price;
            }
        }
        return null;
    }

    deposit() {
        if (!!this.prices) {
            const price = this.prices.find(p => p.defaultPrice && p.deposit);
            if (price) {
                return price.price;
            }
        }
        return null;
    }

    setPickupLocationMenuItemPrices(pickupLocationMenuItemPrices: PickupLocationMenuItemPrice[]) {
        this.pickupLocationMenuItemPrices = [];
        this.prices.forEach(p => {
            const found = !!pickupLocationMenuItemPrices
                ? pickupLocationMenuItemPrices.find(m => m.menuItemPrice.id === p.id) : null;
            if (!!found) {
                p.pickupLocationMenuItemPrice = found;
                this.pickupLocationMenuItemPrices.push(found);
            }
        });
    }

    allowMobileOrdering() {
        return this.availability !== Availabilities.NONE && !this.terminalOnly && this.prices.some(p => p.allowMobileOrdering());
    }

    allowInHouse() {
        if (this.availability === Availabilities.NONE ||
            this.availability === Availabilities.TOGO ||
            (!!this.prices && !this.prices.some(p => p.allowInHouse())) ||
            !this.validateRequiredSelections(Availabilities.IN_HOUSE)) {
            return false;
        } else if (!!this.pickupLocationMenuItemPrices && this.pickupLocationMenuItemPrices.some(p => p.allowInHouse)) {
            return true;
        } else {
            return this.availability === Availabilities.ALL || this.availability === Availabilities.IN_HOUSE;
        }
    }

    allowToGo() {
        if (this.availability === Availabilities.NONE ||
            this.availability === Availabilities.IN_HOUSE ||
            (!!this.prices && !this.prices.some(p => p.allowToGo())) ||
            !this.validateRequiredSelections(Availabilities.TOGO)) {
            return false;
        } else if (!!this.pickupLocationMenuItemPrices && this.pickupLocationMenuItemPrices.some(p => p.allowTogo)) {
            return true;
        } else {
            return this.availability === Availabilities.ALL || this.availability === Availabilities.TOGO;
        }
    }

    validateRequiredSelections(orderType) {
        if (!!this.selections) {
            const selections = this.selections.filter(s => s.menuSelection.required);
            selections.forEach(sel => {
                sel.menuSelection.options = sel.menuSelection.options.filter(
                    item => (orderType === Availabilities.IN_HOUSE && item.menuItem.allowInHouse())
                        || (orderType === Availabilities.TOGO && item.menuItem.allowToGo())
                );
            });

            return !selections || selections.length === 0 || !selections.some(sel => sel.menuSelection.options.length === 0);
        }
        return true;
    }

    related(menuItem: MenuItem): boolean {
        return !!menuItem && (
            this.id === menuItem.id
            || (!!this.originId && (this.originId === menuItem.id || this.originId === menuItem.originId))
            || (!!menuItem.originId && (menuItem.originId === this.id || menuItem.originId === this.originId))
        );
    }
}
