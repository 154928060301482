<modal-header title="Timecard"></modal-header>
<ion-content>
    <ion-grid [formGroup]="formGroup">
        <ion-row>
            <ion-col *ngIf="!timecard" size-xs="12">
                <ion-item lines="none">
                    <ion-label position="floating">Employee</ion-label>
                    <ion-select formControlName="person" interface="popover">
                        <ion-select-option value="">None</ion-select-option>
                        <ion-select-option *ngFor="let u of allUsers"
                                           [value]="u.person">{{u.person.name}}</ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
            <ion-col size-xs="12" size-md="6">
                <ion-item lines="none">
                    <ion-label position="floating">Pickup Location</ion-label>
                    <ion-select formControlName="pickupLocation" interface="popover">
                        <ion-select-option value="">None</ion-select-option>
                        <ion-select-option *ngFor="let pl of organization.pickupLocations"
                                           [value]="pl">{{pl.name}}</ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
            <ion-col size-xs="12" size-md="6">
                <ion-item lines="none">
                    <ion-label position="stacked">Tipped</ion-label>
                    <ion-toggle class="ion-toggle-small" color="success" formControlName="tipped"></ion-toggle>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size-xs="12" size-md="6">
                <bb-date-input-form id="clockInTime"
                                    label="Clock In Time"
                                    formControlName="clockInTime"
                                    [max]="!!formGroup.controls.clockOutTime.value ? formGroup.controls.clockOutTime.value : today"
                                    (dateChange)="setValue('clockInTime', $event)">
                </bb-date-input-form>
            </ion-col>
            <ion-col size-xs="12" size-md="6" *ngIf="!timecard || !!timecard.clockOutTime">
                <bb-date-input-form id="clockOutTime"
                                    label="Clock Out Time"
                                    formControlName="clockOutTime"
                                    [min]="formGroup.controls.clockInTime.value"
                                    (dateChange)="setValue('clockOutTime', $event)">
                </bb-date-input-form>
            </ion-col>

            <ion-col size-xs="12" size-md="6" *ngIf="!!timecard && !timecard.clockOutTime" class="filter-action-button-container">
                <ion-button color="dark" (click)="clockOut()">
                    Clock Out
                </ion-button>
            </ion-col>
            <ion-col *ngIf="formGroup.valid" size-xs="12" class="ion-text-center total-time-row">
                {{formatTotalTime()}}
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<modal-footer (submitClicked)="updateTimecard()" [disableSubmit]="formGroup.invalid"></modal-footer>
