<google-map height="100%"
            width="100%"
            [options]="options"
            (mapClick)="mapClick.emit($event)">
    <map-marker *ngFor="let marker of markers"
                [position]="marker.position"
                [label]="marker.label"
                [title]="marker.title"
                [options]="marker.options"
                (mapClick)="markerClick.emit(marker.pickupLocation)">
    </map-marker>
</google-map>
