export class CashAuditEvents {
    public static ADD = 'ADD';
    public static BANK_DEPOSIT = 'BANK_DEPOSIT';
    public static CORRECTION = 'CORRECTION';
    public static MISC = 'MISC';
    public static PAYMENT = 'PAYMENT';
    public static GIFT_CARD_PURCHASE = 'GIFT_CARD_PURCHASE';
    public static PAYOUT = 'PAYOUT';
    public static REFUND = 'REFUND';
    public static TIP_OUT = 'TIP_OUT';
    public static TRANSFER = 'TRANSFER';
    public static TIP_ADJUSTMENT = 'TIP_ADJUSTMENT';
}
