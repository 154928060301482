import {Inject, Injectable} from '@angular/core';
import {LibConfig, LibConfigService} from '../lib.config';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Card} from '../_models/global-pay/card';
import {Organization} from '../_models/organization';
import {OrganizationService} from './organization.service';

declare let GlobalPayments: any;

@Injectable({
    providedIn: 'root'
})
export class GlobalPayService {
    destination = 'global-pay';
    globalPay: any;
    organization: Organization;

    public ready: Observable<boolean>;
    private readySubject: BehaviorSubject<boolean>;

    constructor(
        public http: HttpClient,
        @Inject(LibConfigService) public config: LibConfig,
        private organizationService: OrganizationService
    ) {
        this.readySubject = new BehaviorSubject<boolean>(false);
        this.ready = this.readySubject.asObservable();

        this.organizationService.current.subscribe(o => {
            if (!!o) {
                if (!this.organization || (o.testMode !== this.organization.testMode || o.id !== this.organization.id)) {
                    this.organization = new Organization(o);
                    if (!!this.globalPay) {
                        this.configure();
                    }
                }
            }
        });
    }

    baseUrl() {
        return this.config.apiUrl + '/' + this.destination;
    }

    tokenize(organizationId: number, card: Card) {
        return this.http.post(`${this.baseUrl()}/organization/${organizationId}/tokenize`, card);
    }

    setFocus(target: string) {
        this.globalPay.internal.postMessage.post({
            id: target,
            type: 'set-focus'
        }, target);
    }

    getToken(target: string) {
        this.globalPay.internal.postMessage.post({
            id: target,
            type: 'accumulate-data'
        }, target);
    }

    initialize() {
        console.log('loading global pay');
        this.globalPay = GlobalPayments;

        if (!!this.organization) {
            this.configure();
        }
    }

    configure() {
        this.globalPay.configure({
            'X-GP-Api-Key': !!this.organization && this.organization.testMode
                ? 'jUayraWCUzsDyR6Dr7tDyYGjRYrGZMH9'
                : 'eBk3ncR05Q7rm8PkZhJqVDSUcXNu61Hx',
            'X-GP-Environment': !!this.organization && this.organization.testMode ? 'test' : 'prod',
            enableAutocomplete: true
        });

        this.readySubject.next(true);
    }

    initializeForm() {
        const imageURL = 'https://hps.github.io/token/gp-1.0.2/images';
        return this.globalPay.ui.form({
            fields: {
                'card-number': {
                    target: '#gp-card-number',
                    placeholder: '•••• •••• •••• ••••'
                },
                'card-expiration': {
                    target: '#gp-card-expiration',
                    placeholder: 'MM / YYYY'
                },
                'card-cvv': {
                    target: '#gp-card-cvv',
                    placeholder: 'CVV'
                },
                submit: {
                    target: '#gp-submit',
                    text: 'Submit'
                }
            },
            styles: {
                button: {
                    height: '55px',
                    width: '100%'
                },
                input: {
                    'font-family': 'Tahoma,sans-serif',
                    padding: '5px',
                    'font-size': '17px',
                    'background-color': 'transparent',
                    border: 'none',
                    outline: 0
                },
                'input:focus': {
                    outline: 'none'
                },
                '.card-number.card-type-visa': {
                    background: `url(${imageURL}/logo-visa@2x.png) no-repeat right`,
                    'background-position-y': '-5px',
                    'background-size': '70px 75px'
                },
                '.card-number.card-type-mastercard': {
                    background: `url(${imageURL}/logo-mastercard@2x.png) no-repeat right`,
                    'background-position-y': '-1px',
                    'background-size': '60px 64px'
                },
                '.card-number.card-type-discover': {
                    background: `url(${imageURL}/logo-discover@2x.png) no-repeat right`,
                    'background-position-y': '-11px',
                    'background-size': '90px 96px'
                },
                '.card-number.card-type-jcb': {
                    background: `url(${imageURL}/logo-jcb@2x.png) no-repeat right`,
                    'background-position-y': '-6px',
                    'background-size': '48px 82px'
                },
                '.card-number.card-type-amex': {
                    background: `url(${imageURL}/logo-amex@2x.png) no-repeat right`,
                    'background-position-y': '-12px',
                    'background-size': '55px 98px'
                },
                'input.invalid.card-type-visa': {
                    'background-position-y': '-42px'
                },
                'input.invalid.card-type-mastercard': {
                    'background-position-y': '-33px'
                },
                'input.invalid.card-type-discover': {
                    'background-position-y': '-59px'
                },
                'input.invalid.card-type-amex': {
                    'background-position-y': '-60px'
                },
                'input.invalid.card-type-jcb': {
                    'background-position-y': '-46px'
                }
            }
        });
    }
}
