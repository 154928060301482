import {Component, OnInit, ViewChild} from '@angular/core';
import {ChannelSubscriberComponent} from './channel-subscriber.component';
import {BaseEventService} from '../_services/base-event.service';
import {GoogleMap} from '@angular/google-maps';
import {UserService} from '../_services/user.service';
import {Geolocation} from '@capacitor/geolocation';
import {User} from '../_models/user';
import {GeoLocationService} from '../_services/geo-location.service';
import {PickupLocation} from '../_models/pickup-location';
import {PickupLocationService} from '../_services/pickup-location.service';

@Component({
    template: ''
})
export abstract class BaseMapComponent extends ChannelSubscriberComponent implements OnInit {
    @ViewChild(GoogleMap, {static: false}) map: GoogleMap;
    currentUser: User;

    watchId: any;
    zoom = 16;
    center: google.maps.LatLngLiteral;
    options: google.maps.MapOptions = {
        mapTypeId: 'satellite',
        mapTypeControl: false,
        streetViewControl: false,
        disableDoubleClickZoom: true,
        fullscreenControl: false,
        clickableIcons: false,
        minZoom: 8,
        tilt: 0,
        rotateControl: false
    };
    currentPosition: any;
    markers = [];
    latitude: number;
    longitude: number;
    loaded = false;

    protected constructor(
        protected eventService: BaseEventService,
        protected userService: UserService,
        protected pickupLocationService: PickupLocationService,
        protected geolocationService: GeoLocationService
    ) {
        super(eventService);
        this.geolocationService.getPosition();
    }

    ngOnInit() {
        this.subscribe(this.userService.current.subscribe(u => this.currentUser = u));
        this.subscribe(this.geolocationService.position.subscribe(position => {
            if (!!position) {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.center = {
                    lat: this.latitude,
                    lng: this.longitude,
                };
            }
        }));
    }

    async watchPosition() {
        try {
            this.watchId = await Geolocation.watchPosition({enableHighAccuracy: true}, (position) => {
                if (!!position) {
                    this.geolocationService.setPosition(position);
                    this.currentPosition = {
                        position: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        },
                        map: this.map,
                        options: {
                            icon: {
                                url: 'assets/maps/map-current.png',
                                scaledSize: {
                                    width: 20,
                                    height: 20,
                                    widthUnit: 'px',
                                    heightUnit: 'px'
                                }
                            }
                        }
                    };

                    const changed = this.latitude !== position.coords.latitude || this.longitude !== position.coords.longitude;
                    this.latitude = position.coords.latitude;
                    this.longitude = position.coords.longitude;

                    if (changed && !!this.currentUser) {
                        this.userService.optInByRange(this.latitude, this.longitude).subscribe(() => {
                            // do nothing
                        });
                    }
                }
            });
        } catch (err) {
            console.log('err', err);
        }
    }

    async clearWatch() {
        if (this.watchId != null) {
            await Geolocation.clearWatch({id: this.watchId});
        }
    }

    checkPickupLocation(pickupLocation: PickupLocation) {
        if (!!pickupLocation) {
            const old = this.markers.find(m => m.pickupLocation.id === pickupLocation.id);

            if (!!old && old.pickupLocation.status !== pickupLocation.status) {
                this.markers.splice(this.markers.indexOf(old), 1);
                this.markers.push(this.pickupLocationService.buildMarker(this.map, pickupLocation));
            }
        }
    }
}
