import {Organization} from './organization';
import {BaseFormModel} from './base-form-model';
import {User} from './user';

export class MarketingPush extends BaseFormModel<MarketingPush> {
    id: number;
    organization: Organization;
    user: User;
    message: string;
    image: string;
    navUrl: string;
    createDate: Date;
    organizationName: string;
}
