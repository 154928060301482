import {BaseFormModel} from './base-form-model';

export class MembershipSummary extends BaseFormModel<MembershipSummary> {
    id: number;
    organizationId: number;
    status: string;
    personId: number;
    firstName: string;
    lastName: string;
    name: string;
    membershipProgramId: number;
    membershipProgramName: string;
    expirationDate: Date;
    tokenCount: number;
    organizationName: string;
    organizationLogoAddress: string;
    memberNumber: number;
    cardSummaryLastFour: string;
}
