<ng-container *ngIf="!!pickupLocationMenu">
    <ion-segment [(ngModel)]="orderType" mode="ios">
        <ion-segment-button *ngIf="pickupLocationMenu.allowInHouse && !hideInHouse" value="IN_HOUSE"
                            class="order-type-selection"
                            (click)="select(availabilities.IN_HOUSE)">
            <ion-label>In House</ion-label>
        </ion-segment-button>
        <ion-segment-button *ngIf="pickupLocationMenu.allowTogo && !hideTogo" value="TOGO"
                            class="order-type-selection"
                            (click)="select(availabilities.TOGO)">
            <ion-label>To Go</ion-label>
        </ion-segment-button>
    </ion-segment>
</ng-container>
