import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {MenuItemSelectionService} from '../_services/menu-item-selection.service';

@Injectable()
export class MenuItemSelectionResolver implements Resolve<any> {
    constructor(private menuItemSelectionService: MenuItemSelectionService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.menuItemSelectionService.get(route.paramMap.get('id'));
    }
}
