<modal-header title="Move Order"></modal-header>
<ion-content>
    <bb-select-buttons *ngIf="!!options"
                       [options]="options"
                       [selected]="selected"
                       [sizeLg]="4"
                       [sizeXl]="3"
                       noSelectionText="New Tab"
                       (optionSelected)="selectTab($event)">
        <ng-template let-option #displayTemplate>
            <div>
                <span *ngIf="!!option.nameOverride">{{option.nameOverride}}</span>
                <span *ngIf="!option.nameOverride">{{!!option.person ? option.person.name : 'Terminal Order'}}</span>
                <p class="tab-date">{{option.startTime | date:'M/d/yy h:mm a'}}</p>
            </div>
        </ng-template>
    </bb-select-buttons>
</ion-content>
<modal-footer [hideSubmit]="true"></modal-footer>
