import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {User} from '../../_models/user';
import {IonInput, ModalController} from '@ionic/angular';
import {CreditCardFormComponent} from '../_forms/credit-card-form/credit-card-form.component';
import {CardSummary} from '../../_models/card-summary';
import {OrganizationService} from '../../_services/organization.service';
import {Organization} from '../../_models/organization';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Address} from '../../_models/address';
import {Person} from '../../_models/person';
import {Order} from '../../_models/order';
import {OrderService} from '../../_services/order.service';

@Component({
    selector: 'bb-edit-credit-card',
    templateUrl: './edit-credit-card.component.html',
    styleUrls: ['./edit-credit-card.component.scss']
})
export class EditCreditCardComponent extends BaseModalComponent implements OnInit, AfterViewInit {
    @Input() user: User;
    @Input() requireName = false;
    @Input() silent = false;
    @ViewChild(CreditCardFormComponent) cardFormComponent: CreditCardFormComponent;
    @ViewChild('firstInput') firstInput: IonInput;
    @Output() cardSummary = new EventEmitter<CardSummary>();
    organization: Organization;
    order: Order;
    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private orderService: OrderService,
        private organizationService: OrganizationService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.subscribe(this.organizationService.current.subscribe(o => this.organization = !!o ? new Organization(o) : o));

        if (this.requireName) {
            this.formGroup = this.formBuilder.group(
                {
                    firstName: ['', Validators.required],
                    lastName: ['', Validators.required],
                    addr1: ['', Validators.required],
                    postalCode: ['', Validators.required]
                });
        }

        this.subscribe(this.orderService.current.subscribe(o => {
            this.order = !!o ? new Order(o) : null;
        }));
    }

    ngAfterViewInit(): void {
        if (this.firstInput != null) {
            setTimeout(async () => {
                await this.firstInput.setFocus();
            }, 50);
        }
    }

    submit(tempToken: string) {
        const contact = new Person(this.formGroup.value);
        contact.address = new Address(this.formGroup.value);

        const cardSummary = new CardSummary({
            tempToken,
            contact
        });
        this.cardSummary.emit(cardSummary);
        this.close(cardSummary);
    }

    valid() {
        return !!this.cardFormComponent && this.cardFormComponent.isValid() && (!this.requireName || this.formGroup.valid);
    }
}
