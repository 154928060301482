<div class="compact-order-container">
    <div *ngIf="expanded" class="display-order">
        <bb-compact-order-item *ngFor="let i of items" [items]="[i]"
                               [item]="i"
                               [details]="details"
                               [selectedItemIds]="selectedItemIds"
                               [orderStatus]="orderStatus"
                               (click)="itemsSelected.emit([i])">
        </bb-compact-order-item>
    </div>
    <ion-grid *ngIf="!expanded"
              [class.selected]="allSelected"
              [class.child]="level > 1" class="display-order"
              [class.expand-header]="!expanded && !!items && items.length > 1"
              (click)="itemsSelected.emit(items)">
        <ion-row *ngIf="level === 1 && !hideHeader">
            <ion-col *ngIf="!!item.menuItem" class="item-info-container">
                {{ item.menuItem.name }} {{ !!item.selectedPrice && !!item.selectedPrice.name && item.selectedPrice.name.toLowerCase() !== 'default'
                ? '(' + item.selectedPrice.name + ') '
                : '' }}
            </ion-col>

            <ion-col *ngIf="!item.menuItem && !item.giftCard" class="item-info-container">
                Misc
                <p class="misc-details">{{item.notes}}</p>
            </ion-col>

            <ion-col *ngIf="!item.menuItem && !!item.giftCard" class="item-info-container">
                Gift Card
                <p class="misc-details">{{ item.giftCard.cardNumber }}</p>
            </ion-col>

            <ion-col *ngIf="!item.menuItem && !!item.membershipRenewal" class="item-info-container">
                Membership Renewal
                <p class="misc-details">{{ item.membershipRenewal.membershipProgram.name }}</p>
            </ion-col>
        </ion-row>

        <ng-container *ngIf="item.removedToppingsFilter().length > 0">
            <ion-row>
                <ion-col [style]="parentRow()">
                    Remove: {{item.compactRemovedToppings()}}
                </ion-col>
            </ion-row>
        </ng-container>

        <ng-container *ngIf="item.addedToppingsFilter().length > 0">
            <ion-row>
                <ion-col [style]="parentRow()">
                    <div class="toppings-container">
                        <div class="toppings-label">Add:</div>
                        <div class="toppings-list">{{item.compactAddedToppings()}}</div>
                    </div>
                </ion-col>
            </ion-row>
        </ng-container>

        <ng-container *ngFor="let menuSelection of item.selections">
            <ion-row>
                <ion-col [style]="parentRow()">
                    <ion-text color="secondary">
                        {{menuSelection.menuItemSelection.menuSelection.name}}
                    </ion-text>
                </ion-col>
            </ion-row>
            <bb-display-order-item-selection-item *ngFor="let selection of menuSelection.selectedItems; trackBy: getId"
                                                  [parent]="item"
                                                  [selection]="selection"
                                                  [level]="level"
                                                  [count]="count"
                                                  [compact]="!details">
            </bb-display-order-item-selection-item>
            <ion-row *ngIf="menuSelection.selectedItems.length === 0">
                <ion-col [style]="childRow()">
                    None
                </ion-col>
            </ion-row>
        </ng-container>


        <ng-container *ngIf="!!item.menuItem && !!item.notes">
            <ion-row>
                <ion-col [style]="parentRow()">
                    <ion-text color="tertiary">
                        Instructions:
                    </ion-text>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col [style]="parentRow()">
                    {{item.notes}}
                </ion-col>
            </ion-row>
        </ng-container>
    </ion-grid>
</div>
<ion-fab *ngIf="!!items && items.length > 1" vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button class="count"
                    [activated]="expanded"
                    (click)="toggleExpanded()">{{items.length}}</ion-fab-button>
</ion-fab>
