<modal-header [title]="title"></modal-header>

<ion-content>
    <ion-grid>
        <form [formGroup]="editPersonForm" (ngSubmit)="onSubmit()">
            <bb-person-form (formReady)="formInitialized($event)" [person]="person" [self]="self"></bb-person-form>
        </form>
    </ion-grid>
</ion-content>

<modal-footer [disableSubmit]="editPersonForm.invalid" (submitClicked)="onSubmit()"></modal-footer>
