import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {MenuTopping} from '../_models/menu-topping';

@Injectable({
    providedIn: 'root'
})
export class MenuToppingService extends BaseService<MenuTopping> {
    destination = 'menu-toppings';
}
