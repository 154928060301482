import {BaseFormModel} from './base-form-model';

export class Tax extends BaseFormModel<Tax> {
    id: number;
    name: string;
    taxRate: number;
    taxedTotal: number;
    tax: number;
    taxIncluded: boolean;
    taxIncludedCash: boolean;
    taxIncludedCharge: boolean;
    taxIncludedGiftCard: boolean;
    paymentType: string;
}
