import {Inject, Injectable} from '@angular/core';
import {Menu} from '../_models/menu';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';
import {BaseObservableService} from './base-obeservable-service';

@Injectable({
    providedIn: 'root'
})
export class MenuService extends BaseObservableService<Menu> {
    destination = 'menus';

    public forceRefresh: Observable<boolean>;
    private forceRefreshSubject: BehaviorSubject<boolean>;

    constructor(
        http: HttpClient,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);

        this.forceRefreshSubject = new BehaviorSubject<boolean>(false);
        this.forceRefresh = this.forceRefreshSubject.asObservable();
    }

    refresh() {
        if (!!this.currentValue && !!this.currentValue.id) {
            this.getSilent(this.currentValue.id).subscribe((o: Menu) => this.setCurrent(new Menu(o)));
        }
    }

    findByOrganizationId(orgId: number) {
        return this.http.get(this.baseUrl() + `/organization/${orgId}`);
    }

    setForceRefresh(next: boolean) {
        this.forceRefreshSubject.next(next);
    }

    sampleData() {
        return {};
    }
}
