import {Feature} from '../_models/feature';
import {FeatureKeys} from './feature-keys';

export const Features = [
    new Feature(FeatureKeys.SUPPORT, 'Support', true),
    new Feature(FeatureKeys.ORG_OWNER, 'Organization Owner', true),
    new Feature(FeatureKeys.ORG_ADMIN, 'Organization Admin', true),
    new Feature(FeatureKeys.CARD_SUMMARY, 'Credit Card Summary'),
    new Feature(FeatureKeys.CARD_SUMMARY_DELETE, 'Credit Card Summary Delete'),
    new Feature(FeatureKeys.CARD_READER_ASSIGN, 'Card Reader Assign'),
    new Feature(FeatureKeys.CASH_DRAWER_ADMIN, 'Cash Drawer Modify'),
    new Feature(FeatureKeys.CASH_DRAWER_ASSIGN, 'Cash Drawer Assign'),
    new Feature(FeatureKeys.CASH_DRAWER_RUNNING_TOTAL, 'Cash Drawer Running Total View'),
    new Feature(FeatureKeys.CASH_SESSION_ADMIN, 'Cash Session Admin'),
    new Feature(FeatureKeys.CASH_SESSION_START, 'Cash Session Start'),
    new Feature(FeatureKeys.CASH_SESSION_COMPLETE, 'Cash Session Complete'),
    new Feature(FeatureKeys.CREDIT_APPLY, 'Comps Apply'),
    new Feature(FeatureKeys.CREDIT_CARD_REFUND, 'Credit Card Refund'),
    new Feature(FeatureKeys.CUSTOMER_ADMIN, 'Customer Details View'),
    new Feature(FeatureKeys.CUSTOMER_AREA_ADMIN, 'Customer Area Modify'),
    new Feature(FeatureKeys.DASHBOARD, 'Dashboard View'),
    new Feature(FeatureKeys.DASHBOARD_ADMIN, 'Dashboard Modify'),
    new Feature(FeatureKeys.DEPOSITS_ADMIN, 'Deposits Administer'),
    new Feature(FeatureKeys.GIFT_CARD, 'Gift Cards Apply'),
    new Feature(FeatureKeys.GIFT_CARD_LOAD, 'Gift Cards Load'),
    new Feature(FeatureKeys.GIFT_CARD_ADMIN, 'Gift Cards Administer'),
    new Feature(FeatureKeys.INVOICE_CREATE, 'Invoice Create'),
    new Feature(FeatureKeys.INVENTORY_ADMIN, 'Inventory Administer'),
    new Feature(FeatureKeys.MENU_ADMIN, 'Menu Modify'),
    new Feature(FeatureKeys.MEMBERSHIP_ADMIN, 'Membership Admin'),
    new Feature(FeatureKeys.ORDER_CANCEL, 'Order Cancel'),
    new Feature(FeatureKeys.ORDER_MOVE, 'Order Move'),
    new Feature(FeatureKeys.ORGANIZATION_EVENT_ADMIN, 'Organization Event Modify'),
    new Feature(FeatureKeys.ORGANIZATION_EVENT_STATUS, 'Organization Event Apply'),
    new Feature(FeatureKeys.PICKUP_LOCATION_ADMIN, 'Pickup Location Modify'),
    new Feature(FeatureKeys.PICKUP_LOCATION_MENU, 'Pickup Location Menu Availability Modify'),
    new Feature(FeatureKeys.PICKUP_LOCATION_STATUS, 'Pickup Location Status Modify'),
    new Feature(FeatureKeys.PRINTER_ASSIGN, 'Printer Assign'),
    new Feature(FeatureKeys.REFUND_PAYMENT, 'Refund Payments'),
    new Feature(FeatureKeys.REPORT, 'Report View'),
    new Feature(FeatureKeys.REPORT_ADMIN, 'Report Modify'),
    new Feature(FeatureKeys.REWARDS_ADMIN, 'Rewards Modify'),
    new Feature(FeatureKeys.SAFE_ADMIN, 'Safe Administrator'),
    new Feature(FeatureKeys.SAFE_TRANSFER, 'Safe Transfer'),
    new Feature(FeatureKeys.TAX_EXEMPT_APPLY, 'Tax Exemption Apply'),
    new Feature(FeatureKeys.TERMINAL, 'Terminal Access'),
    new Feature(FeatureKeys.TIMECARD, 'Timecards View'),
    new Feature(FeatureKeys.TIMECARD_ADMIN, 'Timecards Modify'),
    new Feature(FeatureKeys.TIMECARD_TIPPED, 'Timecard Allow Tipped'),
    new Feature(FeatureKeys.USER_ADMIN, 'User Admin'),
    new Feature(FeatureKeys.USER_CREDIT_ADMIN, 'User Credits Admin')
];
