import {Injectable} from '@angular/core';
import {Person} from '../_models/person';
import {BaseService} from './base-service';

@Injectable({
    providedIn: 'root'
})
export class PersonService extends BaseService<Person> {
    destination = 'persons';
}
