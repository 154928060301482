import {InjectionToken} from '@angular/core';

export interface LibConfig {
    terminal: boolean;
    appVersion: string;
    apiUrl: string;
    mobileUrl: string;
    testMode: boolean;
}

export const LibConfigService = new InjectionToken<LibConfig>('LibConfig');
