import {Component, Input} from '@angular/core';
import {Tab} from '../../_models/tab';
import {OrderService} from '../../_services/order.service';
import {TabService} from '../../_services/tab.service';
import {Order} from '../../_models/order';
import {LoadingService} from '../../_services/loading.service';
import {ModalController} from '@ionic/angular';
import {MenuItemService} from '../../_services/menu-item.service';
import {BaseConfirmationComponent} from '../base-confirmation.component';
import {OrderStatuses} from '../../_constants/order-statuses';
import {OrderItemService} from '../../_services/order-item.service';
import {DeviceService} from '../../_services/device.service';
import {PickupLocationMenuService} from '../../_services/pickup-location-menu.service';
import {MembershipService} from '../../_services/membership.service';

@Component({
    selector: 'bb-confirm-order',
    templateUrl: './confirm-order.component.html',
    styleUrls: ['./confirm-order.component.scss'],
})
export class ConfirmOrderComponent extends BaseConfirmationComponent {
    @Input() tab: Tab;
    @Input() order: Order;

    constructor(
        membershipService: MembershipService,
        pickupLocationMenuService: PickupLocationMenuService,
        deviceService: DeviceService,
        orderService: OrderService,
        menuItemService: MenuItemService,
        orderItemService: OrderItemService,
        loadingService: LoadingService,
        modalController: ModalController,
        private tabService: TabService
    ) {
        super(membershipService,
            pickupLocationMenuService,
            deviceService,
            menuItemService,
            orderService,
            orderItemService,
            loadingService,
            modalController);
    }

    refresh(tab: Tab, orderId?: number) {
        this.tab = new Tab(tab);
        this.tabService.setEvent(this.tab);
        this.order = this.tab.orders.find(o => o.status === OrderStatuses.PENDING && o.deviceId === this.deviceId);
        this.orderService.setCurrent(this.order);
        this.tabService.setCurrent(this.tab);
        this.loadingService.dismiss();
    }

    removeItemSave(order: Order) {
        this.loadingService.present();
        this.orderService.createPending(order).subscribe((t: Tab) => {
            this.loadingService.dismiss();
            if (!t || order.items.length === 0) {
                this.orderService.setCurrent(null);
                this.orderEmpty.emit();
            } else {
                this.refresh(t);
            }
        }, async error => {
            console.log(error);
            await this.displayOrder.reverse();
        });
    }

    addItemSave(order: Order) {
        this.loadingService.present();
        this.orderService.createPending(order).subscribe((t: Tab) => {
            this.refresh(t);
        }, error => {
            this.orderService.handlePendingError(error);
            this.loadingService.dismiss();
        });
    }
}
