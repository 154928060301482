import {Organization} from './organization';
import {Person} from './person';
import {CardSummary} from './card-summary';

export class CustomerAccount {
    id: number;
    organization: Organization;
    person: Person;
    externalCustomerId: string;
    cardSummaries: CardSummary[];
}
