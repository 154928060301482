<modal-header title="Start, Kick, or Replace {{menuItemInventory.menuItem.name}} Keg"></modal-header>
<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col class="ion-text-center">
                <div>Back Of House Inventory</div>
                <span class="count">{{!!backOfHouseInventory ? backOfHouseInventory.currentCount : 'Not Tracked'}}</span>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer *ngIf="menuItemInventory.active" class="ion-no-border">
    <div class="paired-button-container">
        <ion-button data-cy="yes-button" color="dark" class="paired-button" (click)="kickOnly()">
            Kick Only
        </ion-button>
        <ion-button data-cy="kick-and-replace-button" color="success" class="paired-button" (click)="kickAndReplace()">
            Kick And Replace
        </ion-button>
    </div>
</ion-footer>

<modal-footer *ngIf="!menuItemInventory.active" (submitClicked)="kickAndReplace()" submitLabel="Start"></modal-footer>
