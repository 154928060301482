<ion-grid>
    <ion-row>
        <ion-col>
            <ion-segment mode="ios"
                         [class.highlight-primary]="!selectedTip"
                         [class.select-tip]="!selectedTip"
                         [(ngModel)]="selectedTip">
                <ng-container *ngFor="let percent of defaults">
                    <ion-segment-button *ngIf="!max || percent.value <= max" [value]="percent" (click)="changedSelected()">
                        <ion-label>
                            {{percent.percent}}%
                            <span class="dollar-summary">{{percent.value | currency}}</span>
                        </ion-label>
                    </ion-segment-button>
                </ng-container>

                <ion-segment-button value="custom" (click)="clickCustom()">
                    <ion-label>Custom</ion-label>
                </ion-segment-button>
            </ion-segment>
        </ion-col>
    </ion-row>
</ion-grid>
<ion-item *ngIf="!hideTip" lines="none">
    <ion-grid>
        <ion-row>
            <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                Tip:
            </ion-col>
            <ion-col class="ion-text-end child">
                {{tipValue | currency}}
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-item>
