import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserService} from '../../_services/user.service';
import {ToastService} from '../../_services/toast.service';
import {LoadingService} from '../../_services/loading.service';
import {ModalController} from '@ionic/angular';
import {BaseModalComponent} from '../base-modal.component';
import {OrganizationUserService} from '../../_services/organization-user.service';
import {OrganizationUser} from '../../_models/organization-user';
import {Organization} from '../../_models/organization';
import {OrganizationRole} from '../../_models/organization-role';
import {FeatureKeys} from '../../_constants/feature-keys';

@Component({
    selector: 'organization-user',
    templateUrl: './organization-user.component.html',
    styleUrls: ['./organization-user.component.scss'],
})
export class OrganizationUserComponent extends BaseModalComponent implements OnInit {
    @Input() organizationUser: OrganizationUser;
    @Input() organization: Organization;
    formGroup: FormGroup;
    roles: { role: OrganizationRole, selected: boolean }[];

    featureKeys = FeatureKeys;

    constructor(
        private formBuilder: FormBuilder,
        private organizationUserService: OrganizationUserService,
        private userService: UserService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.roles = [];
        if (!!this.organization.roles) {
            this.organization.roles.forEach(role => this.roles.push({
                role,
                selected: this.organizationUser.roles.some(r => r.id === role.id)
            }));
        }

        this.formGroup = this.formBuilder.group({
            id: this.organizationUser.id,
            parentId: this.organizationUser.orgId,
            admin: this.organizationUser.admin,
            owner: this.organizationUser.owner
        });
    }

    async onSubmit() {
        this.loadingService.present();

        const organizationUser = new OrganizationUser(this.formGroup.value);
        organizationUser.roles = this.roles.filter(r => r.selected).map(r => r.role);

        this.organizationUserService.update(organizationUser).subscribe(async (uo: OrganizationUser) => {
            await this.toastService.success(`The role has been changed.`);
            await this.close(uo);
            this.loadingService.dismiss();
        });
    }
}
