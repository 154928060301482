<modal-header title="Credit For Next Visit"></modal-header>

<ion-content [formGroup]="formGroup">
    <ng-container>
        <ion-row>
            <ion-col size-xs="12" size-md="6">
                <ion-item>
                    <ion-label position="floating">Reason</ion-label>
                    <ion-select formControlName="type" interface="popover">
                        <ion-select-option value="UNSATISFACTORY">{{'UNSATISFACTORY' | creditType}}</ion-select-option>
                        <ion-select-option value="INCORRECT_ITEM">{{'INCORRECT_ITEM' | creditType}}</ion-select-option>
                        <ion-select-option value="POOR_SERVICE">{{'POOR_SERVICE' | creditType}}</ion-select-option>
                        <ion-select-option value="LATE">{{'LATE' | creditType}}</ion-select-option>
                        <ion-select-option value="NEVER_RECEIVED">{{'NEVER_RECEIVED' | creditType}}</ion-select-option>
                        <ion-select-option value="DUPLICATE">{{'DUPLICATE' | creditType}}</ion-select-option>
                        <ion-select-option value="FRAUDULENT">{{'FRAUDULENT' | creditType}}</ion-select-option>
                        <ion-select-option value="REWARD">{{'REWARD' | creditType}}</ion-select-option>
                        <ion-select-option value="OTHER">{{'OTHER' | creditType}}</ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
            <ion-col size-xs="12" size-md="6">
                <ion-item lines="none">
                    <ion-label position="floating">Amount</ion-label>
                    <ion-input type="number" formControlName="amount" bbCurrencyMask></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
    </ng-container>
</ion-content>

<modal-footer [disableSubmit]="formGroup.invalid" (submitClicked)="submit()"></modal-footer>
