import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'negativeCurrency'
})
export class NegativeCurrencyPipe implements PipeTransform {
    transform(value: string): string {
        return !!value && value.charAt(0) === '-' ?
            '(' + value.substring(1, value.length) + ')' :
            value;
    }
}
