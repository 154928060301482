import {Directive, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';

@Directive({
    selector: '[bbPhoneMask]'
})
export class PhoneMaskDirective implements OnInit {
    stripChars = new RegExp('[^0-9]', 'g');

    constructor(
        public el: ElementRef,
        public renderer: Renderer2
    ) {
    }

    ngOnInit() {
        this.format(this.el.nativeElement.value); // format any initial values
    }

    @HostListener('input', ['$event.target.value']) onInput(e: string) {
        this.format(e);
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        event.preventDefault();
        this.format(event.clipboardData.getData('text/plain'));
    }

    format(val: string) {
        const numberFormat = String(val).replace(this.stripChars, '');
        let phone = '';

        if (numberFormat.length > 0) {
            phone = '(' + numberFormat.substring(0, 3);

            if (numberFormat.length > 3) {
                phone += ') ' + numberFormat.substring(3, 6);

                if (numberFormat.length > 6) {
                    phone += '-' + numberFormat.substring(6, 10);
                }
            }
        }

        this.renderer.setProperty(this.el.nativeElement, 'value', phone);
    }
}
