import {Injectable} from '@angular/core';
import {PickupLocation} from '../_models/pickup-location';
import {PickupLocationService} from './pickup-location.service';
import {OrganizationService} from './organization.service';
import {Organization} from '../_models/organization';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseOfflineService {
    organization: Organization;
    pickupLocation: PickupLocation;

    protected constructor(
        protected pickupLocationService: PickupLocationService,
        protected organizationService: OrganizationService
    ) {
        this.pickupLocationService.current.subscribe(p => this.pickupLocation = !!p ? new PickupLocation(p) : null);
        this.organizationService.current.subscribe(o => this.organization = !!o ? new Organization(o) : null);
    }
}
