import {Component, Input} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {PickupLocation} from '../../_models/pickup-location';
import {PickupLocationService} from '../../_services/pickup-location.service';
import {LoadingService} from '../../_services/loading.service';
import {ToastService} from '../../_services/toast.service';
import {UploadImageModalComponent} from '../upload-image-modal/upload-image-modal.component';
import {FileUploadService} from '../../_services/file-upload.service';

@Component({
    selector: 'bb-pickup-location',
    templateUrl: './pickup-location.component.html',
    styleUrls: ['./pickup-location.component.scss']
})
export class PickupLocationComponent {
    @Input() pickupLocation: PickupLocation;
    @Input() editable = false;

    constructor(
        private modalController: ModalController,
        private pickupLocationService: PickupLocationService,
        private platform: Platform,
        private fileUploadService: FileUploadService,
        private loadingService: LoadingService,
        private toastService: ToastService
    ) {
    }

    async openUploadDirectionImageModal() {
        if (this.editable) {
            if (this.platform.is('desktop')) {
                const modal = await this.modalController.create({
                    component: UploadImageModalComponent
                });

                modal.onDidDismiss().then((dataReturned) => {
                    if (dataReturned != null && dataReturned.data != null) {
                        this.saveImage(dataReturned.data, null);
                    }
                });

                await modal.present();
            } else {
                const image = await this.fileUploadService.getPhoto();
                const imgBlob = this.fileUploadService.b64toBlob(image.base64String, `image/${image.format}`);
                await this.saveImage(imgBlob, `temp.${image.format}`);
            }
        }
    }

    readFile(file: any) {
        const reader = new FileReader();
        reader.onload = async () => {
            const imgBlob = new Blob([reader.result], {
                type: file.type
            });
            await this.saveImage(imgBlob, file.name);
        };
        reader.readAsArrayBuffer(file);
    }

    async saveImage(image: any, fileName: string) {
        this.loadingService.present();
        this.pickupLocationService.uploadDirectionImage(this.pickupLocation.id, image, fileName).subscribe((p: PickupLocation) => {
            this.toastService.success('The image has been uploaded.');
            this.pickupLocationService.setCurrent(p);
            this.loadingService.dismiss();
        }, (err) => {
            console.log(err);
            this.loadingService.dismiss();
            this.toastService.error('Error saving image.');
        });
    }
}
