import {BaseFormModel} from '../base-form-model';
import {Person} from '../person';
import {CashAudit} from './cash-audit';
import {PickupLocation} from '../pickup-location';

export class CashDrawer extends BaseFormModel<CashDrawer> {
    id: number;
    orgId: number;
    parentId: number;
    name: string;
    startedBy: Person;
    startTime: Date;
    startAmount: number;
    endedBy: Person;
    endTime: Date;
    endAmount: number;
    expected: number;
    tips: number;
    status: string;
    safeAccess: string;
    trackCashTips: boolean;
    pickupLocation: PickupLocation;
    cashAudits: CashAudit[];
    ones: number;
    fives: number;
    tens: number;
    twenties: number;
    fifties: number;
    hundreds: number;
    pennies: number;
    nickels: number;
    dimes: number;
    quarters: number;
}
