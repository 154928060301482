import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {debounce} from 'lodash';
import {BaseModalComponent} from '../base-modal.component';
import {Organization} from '../../_models/organization';
import {GiftCard} from '../../_models/gift-card';
import {CashDrawer} from '../../_models/cash-information/cash-drawer';
import {OrganizationTerminal} from '../../_models/organization-terminal';
import {GiftCardService} from '../../_services/gift-card.service';
import {CashDrawerService} from '../../_services/cash-drawer.service';
import {OrganizationTerminalService} from '../../_services/organization-terminal.service';
import {PrinterService} from '../../_services/printer.service';
import {LoadingService} from '../../_services/loading.service';
import {buildGiftCardReceipt} from '../../_utils/receipt-utils';
import {addCurrency, subtractCurrency} from '../../_utils/currency-math';

@Component({
    selector: 'bb-load-gift-card',
    templateUrl: './load-gift-card.component.html',
    styleUrls: ['./load-gift-card.component.scss'],
})
export class LoadGiftCardComponent extends BaseModalComponent implements OnInit {
    @Input() organization: Organization;
    @Input() giftCard: GiftCard;
    @Input() amount: number;
    @Input() action: string;
    @Input() fromOrder = false;

    last: GiftCard;
    formGroup: FormGroup;
    existing = false;
    prefix: string;
    cashDrawer: CashDrawer;
    terminal: OrganizationTerminal;

    constructor(
        modalController: ModalController,
        private giftCardService: GiftCardService,
        private cashDrawerService: CashDrawerService,
        private organizationTerminalService: OrganizationTerminalService,
        private printerService: PrinterService,
        private loadingService: LoadingService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
        this.search = debounce(this.search, 250);
    }

    ngOnInit() {
        this.existing = !!this.giftCard && !!this.giftCard.id;

        this.subscribe(this.cashDrawerService.current.subscribe(c => this.cashDrawer = !!c ? new CashDrawer(c) : null));

        this.subscribe(this.organizationTerminalService.current.subscribe(r => this.terminal = r));

        this.formGroup = this.formBuilder.group({
            parentId: this.organization.id,
            cardNumber: [!!this.giftCard ? this.giftCard.cardNumber : '', [Validators.required, Validators.minLength(6)]],
            initialAmount: [!!this.amount ? this.amount : '', [Validators.required, Validators.min(1)]]
        });

        this.loadingService.present();
        this.giftCardService.last(this.organization.id).subscribe((g: GiftCard) => {
            if (!!g && !!g.cardNumber && g.cardNumber.length > 6) {
                this.prefix = g.cardNumber.substring(0, g.cardNumber.length - 6);
                this.last = new GiftCard(g);
            }
            this.loadingService.dismiss();
        });
    }

    search() {
        let searchString = this.formGroup.controls.cardNumber.value;
        if (!!searchString && searchString.toString().length >= 6) {
            if (searchString.length === 6 && !!this.prefix) {
                searchString = this.prefix + searchString;
            }
            this.giftCardService.findByCardNumber(this.organization.id, searchString)
                .subscribe(c => this.giftCard = !!c ? new GiftCard(c) : null);
        } else {
            this.giftCard = null;
        }

    }

    loadedBalance() {
        if (!!this.giftCard) {
            if (this.action === 'ADD') {
                return addCurrency(this.giftCard.balance, this.formGroup.controls.initialAmount.value);
            } else {
                return subtractCurrency(this.giftCard.balance, this.formGroup.controls.initialAmount.value);
            }
        } else {
            return this.formGroup.controls.initialAmount.value;
        }
    }

    addToOrder() {
        const giftCard = new GiftCard(this.formGroup.value);

        if (!!this.giftCard) {
            giftCard.id = this.giftCard.id;
            giftCard.parentId = this.organization.id;
        } else if (giftCard.cardNumber.length === 6 && !!this.prefix) {
            giftCard.cardNumber = String(this.prefix) + String(giftCard.cardNumber);
        }
        this.close(giftCard);
    }

    clear() {
        this.formGroup.controls.cardNumber.setValue('');
        this.formGroup.controls.cardNumber.updateValueAndValidity();
    }

    print(giftCard: GiftCard) {
        if (this.printerService.isConnected()) {
            const output = buildGiftCardReceipt(this.organization, giftCard.actions[giftCard.actions.length - 1],
                giftCard.balance, this.printerService.columns);
            this.printerService.print(output);
        }
    }
}
