import {BaseFormModel} from './base-form-model';
import {Person} from './person';
import {GiftCardAction} from './gift-card-action';
import {CashAudit} from './cash-information/cash-audit';
import {CardSummary} from './card-summary';

export class GiftCard extends BaseFormModel<GiftCard> {
    id: number;
    orgId: number;
    createdBy: Person;
    person: Person;
    createDate: Date;
    lastActive: Date;
    cardNumber: string;
    deviceId: string;
    initialAmount: number;
    balance: number;
    imported: boolean;
    status: string;

    actions: GiftCardAction[];
    cashAudit: CashAudit;
    cardSummary: CardSummary;
    credited: boolean;
    terminalId: string;
    referenceId: string;
}
