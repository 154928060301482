import {PickupLocation} from './pickup-location';
import {Person} from './person';
import {Organization} from './organization';
import {BaseFormModel} from './base-form-model';

export class Timecard extends BaseFormModel<Timecard> {
    id: number;
    person: Person;
    organization: Organization;
    pickupLocation: PickupLocation;
    clockInTime: string;
    clockOutTime: string;
    tipped: boolean;
    shiftDurationSecs: number;
    pickupLocationId: number;
    deleted: boolean;
    cashTips: number;
    payrollTips: number;
}
