import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../_models/user';
import {Constants} from '../_constants/constants';
import {LibConfig, LibConfigService} from '../lib.config';
import {BaseObservableService} from './base-obeservable-service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Device} from '@capacitor/device';
import {DeviceInfo} from '../_models/device-info';
import {DeviceService} from './device.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseObservableService<User> {
    destination = 'users';
    token: string;

    public deviceInfo: Observable<DeviceInfo>;
    private deviceInfoSubject: BehaviorSubject<DeviceInfo>;

    constructor(
        http: HttpClient,
        @Inject(LibConfigService) config: LibConfig,
        private deviceService: DeviceService
    ) {
        super(http, config);

        this.deviceInfoSubject = new BehaviorSubject<DeviceInfo>(null);
        this.deviceInfo = this.deviceInfoSubject.asObservable();
    }

    fromToken() {
        return this.http.get(this.baseUrl());
    }

    changePassword(email: string, password: string, newPassword: string) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '401');
        return this.http.put(`${this.baseUrl()}/change-password`, {
            email,
            password,
            changePassword: newPassword
        }, {headers});
    }

    forgotPassword(email: string) {
        return this.http.get(`${this.config.apiUrl}/users/reset-password/${email}`);
    }

    async saveDevice(pushToken: string) {
        if (!this.token && !!pushToken) {
            this.token = pushToken;
        }
        const deviceInfo = await Device.getInfo();
        const device: DeviceInfo = new DeviceInfo();
        device.uuid = await this.deviceService.getDeviceId();
        device.manufacturer = deviceInfo.manufacturer;
        device.model = deviceInfo.model;
        device.platform = deviceInfo.platform;
        device.pushToken = this.token;
        device.osVersion = deviceInfo.osVersion;
        this.http.put(`${this.config.apiUrl}/users/device`, device).subscribe((d: DeviceInfo) => {
            this.deviceInfoSubject.next(d);
        });
    }

    fromPinCode(pinCode: string) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '401');
        return this.http.get(`${this.baseUrl()}/pin-code/${pinCode}`, {headers});
    }

    invite(user: User) {
        return this.http.post(`${this.baseUrl()}/invite`, user);
    }

    getByPersonId(id: number) {
        return this.http.get(`${this.baseUrl()}/person/${id}`);
    }

    getOrgUsers(orgId: any, feature = null) {
        return this.http.get(`${this.baseUrl()}/organization/${orgId}${!!feature ? '?featureList=' + feature.join(',') : ''}`);
    }

    resendConfirmation() {
        return this.http.put(`${this.baseUrl()}/resend-confirmation`, {});
    }

    confirmEmail(code: string) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409,423');
        return this.http.put(`${this.baseUrl()}/confirm-email/${code}`, {}, {headers});
    }

    getPushOptIns() {
        return this.http.get(`${this.baseUrl()}/push-opt-in`);
    }

    optInByRange(latitude: number, longitude: number) {
        return this.http.put(`${this.baseUrl()}/push-opt-in?lat=${latitude}&lon=${longitude}`, {});
    }

    exists(email: string) {
        return this.http.get(`${this.baseUrl()}/exists/${email}`);
    }

    findByEmail(email: string) {
        return this.http.get(`${this.baseUrl()}/email/${email}`);
    }
}
