import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';
import {BaseObservableService} from './base-obeservable-service';
import {MerchantAccount} from '../_models/merchant-account';

@Injectable({
    providedIn: 'root'
})
export class MerchantAccountService extends BaseObservableService<MerchantAccount> {
    destination = 'merchant-accounts';

    public forceRefresh: Observable<boolean>;
    private forceRefreshSubject: BehaviorSubject<boolean>;

    constructor(
        http: HttpClient,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);

        this.forceRefreshSubject = new BehaviorSubject<boolean>(false);
        this.forceRefresh = this.forceRefreshSubject.asObservable();
    }

    findByOrganizationId(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}`);
    }
}
