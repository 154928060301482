import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {OrganizationRewardProgram} from '../_models/organization-reward-program';
import {RewardTypes} from '../_constants/reward-types';
import {RewardProgramTypes} from '../_constants/reward-program-types';
import {OrganizationRewardProgramCardSummary} from '../_models/organization-reward-program-card-summary';

@Injectable({
    providedIn: 'root'
})
export class OrganizationRewardProgramService extends BaseService<OrganizationRewardProgram> {
    destination = 'organization-reward-programs';

    description(program: OrganizationRewardProgram | OrganizationRewardProgramCardSummary) {
        return !program ? '' :
            `Receive ${this.rewardDescription(program)} for every ${this.goalDescription(program)}.`;
    }

    rewardDescription(program: OrganizationRewardProgram | OrganizationRewardProgramCardSummary) {
        return program.rewardType === RewardTypes.ITEM ?
            `${program.reward} ${program.rewardName}` :
            `\$${program.reward}`;
    }

    goalDescription(program: OrganizationRewardProgram | OrganizationRewardProgramCardSummary) {
        return program.type === RewardProgramTypes.PER_ITEM ?
            `${program.goal} qualified items purchased` :
            `\$${program.goal} spent`;
    }
}
