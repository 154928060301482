import {BaseFormModel} from './base-form-model';
import {Sortable} from './sortable';
import {MenuItem} from './menu-item';
import {MenuItemPrice} from './menu-item-price';

export class MenuSelectionItem extends BaseFormModel<MenuSelectionItem> implements Sortable {
    id: number;
    sortOrder: number;
    menuItem: MenuItem;
    additionalPrice: number;
    defaultSelection: boolean;
    menuItemPrice: MenuItemPrice;

    // for user menu management
    selected: boolean;
    selectionCount: number;

    getName() {
        return this.menuItem.name;
    }

    isVariable(): boolean {
        return !!this.menuItem && this.menuItem.isVariable();
    }

    deposit(): number {
        return this.menuItem.deposit();
    }

    init(): void {
        this.menuItem = new MenuItem(this.menuItem);
    }
}
