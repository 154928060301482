import {Injectable} from '@angular/core';
import {TabReport} from '../_models/report/tab-report';
import {BaseService} from './base-service';
import {Sort} from '../_models/report/sort';
import {HttpHeaders} from '@angular/common/http';
import {Constants} from '../_constants/constants';

@Injectable({
    providedIn: 'root'
})
export class TabReportService extends BaseService<TabReport> {
    destination = 'tab-reports';

    findByOrganizationId(orgId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${orgId}`);
    }

    copy(id: number) {
        return this.http.post(`${this.baseUrl()}/${id}/copy`, {});
    }

    quickbooksReports(organizationId: number, useLabels = null) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/quickbooks` + (useLabels != null
            ? `?useLabels=${useLabels}`
            : ''));
    }

    importReports(organizationId: number, reports: TabReport[]) {
        return this.http.post(`${this.baseUrl()}/organization/${organizationId}/import`, reports);
    }

    export(id: number) {
        const httpOptions = {
            responseType: 'blob' as 'json',
        };

        let url = `${this.baseUrl()}/${id}/export`;

        return this.http.get(url, httpOptions);
    }

    exportAsCsv(id: number, startTime: string, endTime: string, selectedCategoryIds: number[], selectedPickupLocationIds: number[]
    ) {
        const httpOptions = {
            responseType: 'blob' as 'json',
        };

        let url = `${this.baseUrl()}/${id}/export-csv?startTime=${startTime.replace('+', '%2b')}&endTime=${endTime.replace('+', '%2b')}`;

        if (!!selectedCategoryIds && selectedCategoryIds.length > 0) {
            url += `&selectedCategoryIds=${selectedCategoryIds.join(',')}`;
        }
        if (!!selectedPickupLocationIds && selectedPickupLocationIds.length > 0) {
            url += `&selectedPickupLocationIds=${selectedPickupLocationIds.join(',')}`;
        }

        return this.http.get(url, httpOptions);
    }

    run(id: number, page: number, size: number, startTime: string, endTime: string,
        sort: Sort, selectedCategoryIds: number[], selectedPickupLocationIds: number[], silent = false
    ) {
        let headers = new HttpHeaders();
        if (silent) {
            headers = headers.set(Constants.SILENT, 'true');
        }
        let url = `${this.baseUrl()}/${id}/run?page=${page}&size=${size}&startTime=${startTime.replace('+', '%2b')}&endTime=${endTime.replace('+', '%2b')}`;
        if (!!sort) {
            url += `&sort=${sort.column.accessKey}&sortOrder=${sort.sortDirection}`;
        }
        if (!!selectedCategoryIds && selectedCategoryIds.length > 0) {
            url += `&selectedCategoryIds=${selectedCategoryIds.join(',')}`;
        }
        if (!!selectedPickupLocationIds && selectedPickupLocationIds.length > 0) {
            url += `&selectedPickupLocationIds=${selectedPickupLocationIds.join(',')}`;
        }
        return this.http.get(url, {headers});
    }

    single(id: number, startTime: string, endTime: string, comparisonStartTime: string, comparisonEndTime: string,
           selectedCategoryIds: number[], selectedPickupLocationIds: number[], silent = false
    ) {
        let headers = new HttpHeaders();
        if (silent) {
            headers = headers.set(Constants.SILENT, 'true');
        }
        let url = `${this.baseUrl()}/${id}/run/single?startTime=${startTime.replace('+', '%2b')}&endTime=${endTime.replace('+', '%2b')}`;

        if (!!comparisonStartTime && !!comparisonEndTime) {
            url += `&comparisonStartTime=${comparisonStartTime.replace('+', '%2b')}&comparisonEndTime=${comparisonEndTime.replace('+', '%2b')}`;
        }
        if (!!selectedCategoryIds && selectedCategoryIds.length > 0) {
            url += `&selectedCategoryIds=${selectedCategoryIds.join(',')}`;
        }
        if (!!selectedPickupLocationIds && selectedPickupLocationIds.length > 0) {
            url += `&selectedPickupLocationIds=${selectedPickupLocationIds.join(',')}`;
        }
        return this.http.get(url, {headers});
    }
}
