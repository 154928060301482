import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {OrganizationRatingService} from '../_services/organization-rating.service';

@Injectable()
export class RatingResponsesResolver implements Resolve<any> {
    constructor(private organizationRatingService: OrganizationRatingService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.organizationRatingService.findResponsesByUser(0, 20);
    }
}
