import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'modal-footer',
    templateUrl: './modal-footer.component.html',
    styleUrls: ['./modal-footer.component.scss'],
})
export class ModalFooterComponent implements OnInit {
    @Input() hideSubmit: boolean;
    @Input() disableSubmit: boolean;
    @Input() submitLabel = 'Save';
    @Output() cancelClicked: EventEmitter<any> = new EventEmitter();
    @Output() submitClicked: EventEmitter<any> = new EventEmitter();

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {
    }

    submitButtonClicked() {
        this.submitClicked.emit(null);
    }

    async close() {
        this.cancelClicked.emit(null);
    }
}
