import {BaseFormModel} from './base-form-model';
import {Sortable} from './sortable';
import {MenuSelection} from './menu-selection';

export class MenuItemSelection extends BaseFormModel<MenuItemSelection> implements Sortable {
    id: number;
    sortOrder: number;
    menuSelection: MenuSelection;

    getName() {
        return this.menuSelection ? this.menuSelection.name : '';
    }

    init(): void {
        this.menuSelection = new MenuSelection(this.menuSelection);
    }
}
