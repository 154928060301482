import {Pipe, PipeTransform} from '@angular/core';
import {OrderStatuses} from '../../_constants/order-statuses';

@Pipe({
    name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {
    transform(value: string, lowercase: boolean = false): string {
        let statusString = '';
        switch (value) {
        case OrderStatuses.RECEIVED:
            statusString = 'Order In';
            break;
        case OrderStatuses.PREPARING:
            statusString = 'Preparing';
            break;
        case OrderStatuses.READY:
            statusString = 'Ready For Pickup!';
            break;
        case OrderStatuses.DELIVERED:
            statusString = 'Delivered';
            break;
        case OrderStatuses.CANCELED:
            statusString = 'Canceled';
            break;
        case OrderStatuses.PENDING:
            statusString = 'Pending';
            break;
        default:
            statusString = 'Your Order';
            break;
        }
        return lowercase ? statusString.toLowerCase() : statusString;
    }
}
