import {BaseFormModel} from './base-form-model';
import {Sortable} from './sortable';
import {MenuCategoryItem} from './menu-category-item';
import {MenuCategoryItemGroup} from './menu-category-item-group';

export class MenuCategory extends BaseFormModel<MenuCategory> implements Sortable {
    id: number;
    orgId: number;
    menuId: number;
    name: string;
    description: string;
    sortOrder: number;
    menuItems: MenuCategoryItem[];
    menuItemGroups: MenuCategoryItemGroup[];
    parentId: number;
    featured: true;
    color: string;
    contrastColor: string;

    options: any[];

    init(): void {
        let temp = [];
        if (!!this.menuItems) {
            this.menuItems.forEach(i => temp.push(new MenuCategoryItem(i)));
            this.menuItems = temp;
        }

        temp = [];
        if (!!this.menuItemGroups) {
            this.menuItemGroups.forEach(i => temp.push(new MenuCategoryItemGroup(i)));
            this.menuItemGroups = temp;
        }
    }
}
