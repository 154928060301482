import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {AlertController, Animation, AnimationController, IonContent, ModalController, NavController} from '@ionic/angular';
import {Order} from '../../_models/order';
import {OrderItemService} from '../../_services/order-item.service';
import {Organization} from '../../_models/organization';
import {Router} from '@angular/router';
import {Tab} from '../../_models/tab';
import {TabService} from '../../_services/tab.service';
import {ToastService} from '../../_services/toast.service';
import {LoadingService} from '../../_services/loading.service';
import {OrderService} from '../../_services/order.service';
import {OrganizationService} from '../../_services/organization.service';
import {CardSummaryService} from '../../_services/card-summary.service';
import {PickupLocation} from '../../_models/pickup-location';
import {PickupLocationService} from '../../_services/pickup-location.service';
import {User} from '../../_models/user';
import {FormGroup} from '@angular/forms';
import {CardSummary} from '../../_models/card-summary';
import {CreditCardFormComponent} from '../_forms/credit-card-form/credit-card-form.component';
import {UserService} from '../../_services/user.service';
import {OrderStatuses} from '../../_constants/order-statuses';
import {TabStatuses} from '../../_constants/tab-statuses';

@Component({
    selector: 'bb-display-order-modal',
    templateUrl: './display-order-modal.component.html',
    styleUrls: ['./display-order-modal.component.scss']
})
export class DisplayOrderModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    @ViewChild(IonContent) content: IonContent;
    @ViewChild(CreditCardFormComponent) cardFormComponent: CreditCardFormComponent;
    @Input() order: Order;
    @Input() organization: Organization;

    @ViewChild('marker', {read: ElementRef}) marker: ElementRef;

    currentUser: User;
    pickupLocation: PickupLocation;
    expandedDirections = false;
    cards: CardSummary[];
    selectedCard: CardSummary;
    tab: Tab;
    proximityAnimation: Animation;

    personFormGroup: FormGroup = new FormGroup({});
    animate = false;
    orderStatuses = OrderStatuses;
    tabStatuses = TabStatuses;

    constructor(
        modalController: ModalController,
        private router: Router,
        private alertController: AlertController,
        private navController: NavController,
        private toastService: ToastService,
        private userService: UserService,
        private loadingService: LoadingService,
        private tabService: TabService,
        private orderService: OrderService,
        private orderItemService: OrderItemService,
        private organizationService: OrganizationService,
        private creditCardService: CardSummaryService,
        private pickupLocationService: PickupLocationService,
        private cardSummaryService: CardSummaryService,
        private changeDetectorRef: ChangeDetectorRef,
        private animationController: AnimationController
    ) {
        super(modalController);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (!!this.proximityAnimation) {
            this.proximityAnimation.destroy();
        }
    }

    ngOnInit() {
        this.order = new Order(this.order);

        if (this.order.status === OrderStatuses.READY) {
            this.pickupLocation = this.order.pickupLocation;
        }

        this.subscribe(this.tabService.current.subscribe(t => this.tab = t));

        this.subscribe(this.orderService.event.subscribe(async o => {
            if (!!o && !!this.order && this.order.id === o.id) {
                if (o.status !== this.order.status) {
                    if (o.status === OrderStatuses.DELIVERED || o.status === OrderStatuses.READY) {
                        await this.close();
                    }
                }
                this.order = o;
                this.changeDetectorRef.detectChanges();
            }
        }));

        this.subscribe(this.orderService.current.subscribe(async o => {
            if (!!o && !!this.order && this.order.id === o.id) {
                this.order = o;
                if (!this.order.items || this.order.items.length === 0) {
                    await this.close();
                } else {
                    this.checkPickupLocation();
                }
            }
        }));

        if (!this.organization) {
            this.subscribe(this.organizationService.current.subscribe(o => {
                this.organization = !!o ? new Organization(o) : null;
                this.checkPickupLocation();
            }));
        }

        if (!this.order.status) {
            this.subscribe(this.userService.current.subscribe(u => {
                this.currentUser = u;
                if (!!this.currentUser) {
                    this.cardSummaryService.findByOrganizationId(this.organization.id)
                        .subscribe((cardSummaries: CardSummary[]) => {
                            if (!!cardSummaries && cardSummaries.length > 0) {
                                this.cards = cardSummaries;
                                const defaultCards = this.cards.filter(cardSummary => {
                                    return cardSummary.defaultSource;
                                });
                                this.selectedCard = defaultCards.length > 0 ? defaultCards[0] : this.cards[0];
                            }
                        });
                }
            }));
        }
    }

    checkPickupLocation() {
        if (!!this.organization && !!this.order && this.organization.pickupLocations && this.organization.pickupLocations.length === 1) {
            this.order.pickupLocation = this.organization.pickupLocations[0];
        }
    }

    async addMore() {
        await this.navController.navigateForward('/menu/' + this.order.menu.orgId + '/' + this.order.menu.id);
        await this.close();
    }

    async remove(index) {
        this.order.items.splice(index, 1);
        this.orderService.setCurrent(this.order.items.length > 0 ? this.order : null);
        if (this.order.items.length === 0) {
            await this.close();
        }
        return false;
    }

    // TODO: Can we do this with geo for now?
    async toneReceived() {
        if (!this.animate && this.order.status === OrderStatuses.READY) {
            if (!this.proximityAnimation) {
                this.proximityAnimation = this.animationController.create()
                    .addElement(this.marker.nativeElement)
                    .duration(2000)
                    .iterations(Infinity)
                    .keyframes([
                        {offset: 0, boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)', transform: 'scale(1)'},
                        {offset: 0.40, boxShadow: '0 20px 32px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)', transform: 'scale(1.1)'},
                        {offset: 0.50, boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)', transform: 'scale(1)'},
                        {offset: 0.51, transform: 'translate(0.5px, 1.5px) rotate(0.5deg)'},
                        {offset: 0.52, transform: 'translate(-0.5px, -0.5px) rotate(-0.5deg)'},
                        {offset: 0.54, transform: 'translate(-1.5px, -0.5px) rotate(-0.5deg)'},
                        {offset: 0.56, transform: 'translate(-0.5px, 0.5px) rotate(1.5deg)'},
                        {offset: 0.58, transform: 'translate(-0.5px, -0.5px) rotate(0.5deg)'},
                        {offset: 0.60, transform: 'translate(1.5px, 0.5px) rotate(-0.5deg)'},
                        {offset: 0.62, transform: 'translate(-1.5px, 1.5px) rotate(0.5deg)'},
                        {offset: 0.64, transform: 'translate(1.5px, -0.5px) rotate(-0.5deg)'},
                        {offset: 0.66, transform: 'translate(2.5px, -1.5px) rotate(1.5deg)'},
                        {offset: 0.68, transform: 'translate(2.5px, 0.5px) rotate(-0.5deg)'},
                        {offset: 0.70, transform: 'translate(0px, 1px) rotate(0.9deg)'},
                        {offset: 0.72, transform: 'translate(0px, 1px) rotate(0.9deg)'},
                        {offset: 0.74, transform: 'translate(0px, 0px) rotate(0.9deg)'},
                        {offset: 0.76, transform: 'translate(1px, 0px) rotate(0.9deg)'},
                        {offset: 0.78, transform: 'translate(1px, 0px) rotate(0.9deg)'},
                        {offset: 0.80, transform: 'translate(1px, 0px) rotate(0.9deg)'},
                        {offset: 0.82, transform: 'translate(0px, 1px) rotate(0.9deg)'},
                        {offset: 0.84, transform: 'translate(1px, 1px) rotate(0.9deg)'},
                        {offset: 0.86, transform: 'translate(0px, 0px) rotate(0.9deg)'},
                        {offset: 0.88, transform: 'translate(1px, 1px) rotate(0.9deg)'},
                        {offset: 0.90, transform: 'translate(0px, 1px) rotate(0.9deg)'},
                        {offset: 0.92, transform: 'translate(0px, 0px) rotate(0.4deg)'},
                        {offset: 0.94, transform: 'translate(0px, 1px) rotate(0.4deg)'},
                        {offset: 0.96, transform: 'translate(0px, 1px) rotate(0.4deg)'},
                        {offset: 0.98, transform: 'translate(1px, 1px) rotate(0.4deg)'},
                        {offset: 1, transform: 'transform: translate(0, 0) rotate(0)'}
                    ]);
            }
            await this.proximityAnimation.play();

            this.animate = true;
        }
    }

    async toggleDirections() {
        this.expandedDirections = !this.expandedDirections;
        if (this.expandedDirections) {
            await this.content.scrollToTop(100);
        }
    }

    async confirmCancel() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Cancel',
            message: 'Are you sure you want to cancel this order?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.cancel();
                    }
                }
            ]
        });

        await alert.present();
    }

    async cancel() {
        // if (!!this.order.id) {
        //     this.loadingService.present();
        //     this.orderService.updateStatus(this.order.id, OrderStatuses.CANCELED).subscribe(async () => {
        //             await this.close();
        //         }, (error) => {
        //             console.log(error);
        //         },
        //         () => {
        //             this.loadingService.dismiss();
        //         });
        // } else {
        //     this.orderService.setCurrent(null);
        //     await this.close();
        // }
    }

    personFormInitialized(form: FormGroup) {
        this.personFormGroup = form;
    }

    async submit() {
        if (!this.currentUser) {
            this.loadingService.present();
            // this.cardFormComponent.createCardToken().then(async (result) => {
            //     if (result.token) {
            //         this.order.cardSummary = new CardSummary();
            //         this.order.cardSummary.token = result.token.id;
            //         this.order.cardSummary.nickname = result.token.card.name;
            //         this.order.person = new Person(this.personFormGroup.value);
            //         if (!this.currentUser) {
            //             this.order.deviceId = await getDeviceId();
            //         }
            //         this.submitCurrent();
            //     } else if (result.error) {
            //         await this.toastService.error(result.error.message);
            //         this.loadingService.dismiss();
            //     }
            // }).catch(async err => {
            //     console.log(err);
            //     await this.toastService.error('An error occurred while confirming the card.');
            //     this.loadingService.dismiss();
            // });
        } else {
            this.loadingService.present();
            this.order.person = this.currentUser.person;
            this.order.cardSummary = this.selectedCard;
            this.submitCurrent();
        }
    }

    submitCurrent() {
        this.orderService.createPending(this.order).subscribe(async o => {
            this.order = new Order(o);
            this.orderService.addActiveOrder(this.order);
            this.orderService.setCurrent(null);
            this.loadingService.dismiss();
        }, async error => {
            if (error.status === 417) {
                await this.toastService.error('This location is not currently taking orders.');
            }
        });
    }

    convertCardToClass(card: CardSummary) {
        return card.cardType.toLowerCase().replace(' ', '-');
    }
}
