import {BaseFormModel} from './base-form-model';

export class TabPaymentInformation extends BaseFormModel<TabPaymentInformation> {
    id: number;

    // tab overall totals
    subtotal: number;
    deposits: number;
    depositsRemaining: number;

    // taxes and fees
    taxes: number;
    mobileFees: number;
    membershipFees: number;
    totalFees: number;
    surcharges: number;
    taxesAndFees: number;

    // subtotal, taxes, and fees - all charged items
    principal: number;

    tips: number;
    credits: number;

    // principal, less credits, does not include tips
    invoiced: number;

    // principal and tips
    total: number;

    // all payments information
    payments: number;
    refunds: number;

    // payments less refunds
    appliedPayments: number;

    // remaining
    remaining: number;
    paymentStatus: string;

    // cash and credit tips
    tipPayments: number;
    tipRefunds: number;

    // all payments less refunds
    paymentsPrincipal: number;

    // membership token payments
    tokensUsed: number;
    tokenPaymentsTotal: number;

    // credit card payments less refunds
    appliedChargesPrincipal: number;
    appliedChargesTips: number;
    appliedChargesTotal: number;

    // gift card payments
    giftCardPaymentsPrincipal: number;
    giftCardPaymentsTips: number;
    giftCardPaymentsTotal: number;
    giftCardPendingBalances: number;
    giftCardPurchasesCount: number;
    giftCardPurchasesTotal: number;

    // membership renewal information
    newMembershipsCount: number;
    membershipRenewalsCount: number;
    membershipRenewalsTotal: number;

    // credit card payments
    chargePaymentsPrincipal: number;
    chargePaymentsTips: number;
    chargePaymentsTotal: number;

    // credit card refunds
    chargeRefundsPrincipal: number;
    chargeRefundsTips: number;
    chargeRefundsTotal: number;

    // cash payments less refunds
    appliedCashPrincipal: number;
    appliedCashTips: number;
    appliedCashTotal: number;

    // cash payments
    cashPaymentsPrincipal: number;
    cashPaymentsTips: number;
    cashPaymentsTotal: number;

    // cash refunds
    cashRefundsPrincipal: number;
    cashRefundsTips: number;
    cashRefundsTotal: number;

    // credit information
    orderItemCredits: number;
    tabCredits: number;

    // tab pre auth
    preAuthTotal: number;

    // canceled order information
    canceledOrdersCount: number;
    canceledOrdersTotal: number;

    paymentType: string;
    invoiceCreated: boolean;
}
