import {BaseFormModel} from './base-form-model';
import {MenuItemPrice} from './menu-item-price';
import {MenuItem} from './menu-item';
import {OrderItemSelection} from './order-item-selection';
import {MenuSelectionItem} from './menu-selection-item';
import {MenuItemSelection} from './menu-item-selection';
import {OrderItemSelectionItem} from './order-item-selection-item';
import {addCurrency, multiplyCurrency, subtractCurrency} from '../_utils/currency-math';
import {OrderItemCredit} from './order-item-credit';
import {OrderItemTopping} from './order-item-topping';
import {Tax} from './tax';
import {Tab} from './tab';
import {calculateTax, includedTaxedAmount, isTaxIncluded} from '../_utils/tax-utils';
import {GiftCard} from './gift-card';
import {MembershipRenewal} from './membership-renewal';

export class OrderItem extends BaseFormModel<OrderItem> {
    id: number;
    menuItem: MenuItem;
    selectedPrice: MenuItemPrice;
    includedToppings: OrderItemTopping[];
    optionalToppings: OrderItemTopping[];
    selections: OrderItemSelection[];
    credits: OrderItemCredit[];
    creditsTotal: number;
    tabCredits: number;
    total: number;
    deposits: number;
    priceOverride: number;
    orderType: string;
    notes: string;
    miscItem = false;
    addIndex: number;
    taxes: Tax[];
    key: string;
    preventAutoCredit: boolean;
    giftCard: GiftCard;
    membershipRenewal: MembershipRenewal;
    membershipOrganizationFee: number;
    membershipHostFee: number;
    membershipFeeIncluded: boolean;

    status: string;

    // ONLY for navigation purposes
    orderItemSelectionId: number;
    menuSelectionItem: MenuSelectionItem;
    menuItemSelection: MenuItemSelection;
    orderIndex: number;
    menuSelectionIndex: number;
    count = 1;
    membershipTokenGroupId: number;
    tokenPayment: number;
    tokenPaymentRefunded: number;

    // for auto applied credit checks
    tempCreditCount: number;
    creditsModified: boolean;

    // ONLY for rest conversion
    toppings: OrderItemTopping[];

    public constructor(jsonInput: any) {
        super(jsonInput);
        Object.assign(this, jsonInput);
        this.menuItem = !!this.menuItem ? new MenuItem(this.menuItem) : null;
        const selections = [];
        if (this.selections) {
            this.selections.forEach(sel => {
                selections.push(new OrderItemSelection(sel));
            });
        }
        this.selections = selections;

        if (!!jsonInput && !!jsonInput.toppings && jsonInput.toppings.length > 0) {
            this.optionalToppings = [];
            this.includedToppings = [];
            let topping: OrderItemTopping;
            jsonInput.toppings.forEach(t => {
                // check if this is a MenuItemTopping or an OrderItemTopping
                topping = new OrderItemTopping(t);
                topping.init();

                if (!topping.included) {
                    this.includedToppings.push(topping);
                } else {
                    this.optionalToppings.push(topping);
                }
            });
        }
    }

    init(): void {
        this.menuItem = !!this.menuItem ? new MenuItem(this.menuItem) : null;
        this.membershipRenewal = !!this.membershipRenewal ? new MembershipRenewal(this.membershipRenewal) : null;

        if (!!this.selections) {
            this.selections.forEach((selection: OrderItemSelection) => {
                selection.selectedItems.forEach((selectedItem: OrderItemSelectionItem) => {
                    selectedItem.orderItem = new OrderItem(selectedItem.orderItem);
                    selectedItem.orderItem.init();
                });
            });
        }
    }

    get membershipFees() {
        return addCurrency(!!this.membershipOrganizationFee ? this.membershipOrganizationFee : 0, !!this.membershipHostFee
            ? this.membershipHostFee
            : 0);
    }

    compactAddedToppings() {
        // TODO: this name situation is broken
        return this.addedToppingsFilter().map((t: any) => t.menuItemTopping.topping.name).join(', ');
    }

    compactRemovedToppings() {
        // TODO: this name situation is broken
        return this.removedToppingsFilter().map((t: any) => t.menuItemTopping.topping.name).join(', ');
    }

    removedToppingsFilter() {
        return !!this.includedToppings ? this.includedToppings.filter(topping => !topping.included) : [];
    }

    addedToppingsFilter() {
        return !!this.optionalToppings ? this.optionalToppings.filter(topping => topping.included) : [];
    }

    includedToppingsDisplay() {
        const display = this.compactRemovedToppings();

        return display ? 'No ' + display : '';
    }

    optionalToppingsDisplay() {
        const display = this.compactAddedToppings();

        return display ? 'Add ' + display : '';
    }

    toppingPrice() {
        return this.optionalToppings.reduce((total, topping) => addCurrency(total, topping.menuItemTopping.price), 0);
    }

    calcTotal() {
        let total = this.total;
        if (!!this.selectedPrice && !this.selectedPrice.deposit) {
            total = this.selectedPrice.variable ? this.priceOverride : this.selectedPrice.price;
        }

        this.addedToppingsFilter().forEach(topping => {
            total = addCurrency(total, topping.menuItemTopping.price);
        });

        const parentAmount = !this.selectedPrice || (!!this.selectedPrice && !!this.selectedPrice.variable)
            ? this.priceOverride : this.selectedPrice.price;

        this.selections.forEach(selection => {
            total = addCurrency(total, selection.calculateTotal(!!parentAmount ? parentAmount : 0));
        });

        this.total = total;
        return total;
    }

    calcDeposits() {
        let total = (!!this.selectedPrice && this.selectedPrice.deposit) ? this.selectedPrice.price : this.deposits;
        this.selections.forEach(selection => {
            total = addCurrency(total, selection.calculateDeposit());
        });
        return total;
    }

    totalWithAdditional() {
        return addCurrency(this.calcTotal(), multiplyCurrency((!!this.menuSelectionItem && !!this.menuSelectionItem.additionalPrice
            ? this.menuSelectionItem.additionalPrice
            : 0), this.count));
    }

    calcCredits() {
        return !!this.credits ? this.credits.reduce((total, c) =>
            addCurrency(total, c.amount), 0) : 0;
    }

    maxCreditIndex(): number {
        return !!this.credits ? this.credits.reduce((max, credit) => max = (max > credit.addIndex) ? max : credit.addIndex + 1, 0) : 0;
    }

    totalLessComps() {
        return subtractCurrency(this.calcTotal(), addCurrency(this.calcCredits(), this.tabCredits));
    }

    groupTaxedAmounts(tab: Tab) {
        const taxes = [];
        if (!!this.menuItem && !!this.menuItem.taxLabels) {
            this.menuItem.taxLabels.forEach(taxLabel => {
                const taxIncluded = !!this.tokenPayment || isTaxIncluded(this.menuItem.taxOverride, taxLabel, !!tab
                    ? tab.paymentInformation.paymentType
                    : null);

                const tax = new Tax({
                    name: taxLabel.name,
                    taxIncluded,
                    tax: calculateTax(taxIncluded
                        ? includedTaxedAmount(this.totalLessComps(), taxLabel.taxRate)
                        : this.totalLessComps(), taxLabel.taxRate),
                    taxedTotal: taxIncluded ? includedTaxedAmount(this.totalLessComps(), taxLabel.taxRate) : this.totalLessComps(),
                    taxRate: taxLabel.taxRate,
                    taxIncludedCash: taxLabel.taxIncludedCash,
                    taxIncludedGiftCard: taxLabel.taxIncludedGiftCard,
                    taxIncludedCharge: taxLabel.taxIncludedGiftCard
                });
                taxes.push(tax);
            });
        } else if (!!this.taxes) {
            this.taxes.forEach(t => {
                const taxIncluded = !!this.tokenPayment || isTaxIncluded(this.menuItem.taxOverride, t, !!tab
                    ? tab.paymentInformation.paymentType
                    : null);
                const tax = new Tax(t);
                tax.taxIncluded = taxIncluded;
                tax.tax = calculateTax(taxIncluded
                    ? includedTaxedAmount(this.totalLessComps(), t.taxRate)
                    : this.totalLessComps(), t.taxRate);
                tax.taxedTotal = taxIncluded ? includedTaxedAmount(this.totalLessComps(), t.taxRate) : this.totalLessComps();
                taxes.push(tax);
            });
        }
        this.taxes = taxes;
    }
}
