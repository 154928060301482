import {Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren} from '@angular/core';
import {Order} from '../../_models/order';
import {OrderItem} from '../../_models/order-item';
import {RemovableItemComponent} from '../removable-item/removable-item.component';
import {TabStatuses} from '../../_constants/tab-statuses';
import {OrderItemSummary} from '../../_models/order-item-summary';
import {compareItems} from '../../_utils/order-utils';
import {MasonryGridComponent} from '../masonry-grid/masonry-grid.component';
import {OrderStatuses} from '../../_constants/order-statuses';
import {Availabilities} from '../../_constants/availabilities';
import {CompDefinition} from '../../_models/comp-definition';

@Component({
    selector: 'bb-display-order',
    templateUrl: './display-order.component.html',
    styleUrls: ['./display-order.component.scss'],
})
export class DisplayOrderComponent implements OnInit, OnChanges {
    @Input() order: Order;
    @Input() compact = false;
    @Input() details = false;
    @Input() allowGrid = false;
    @Input() gridCssClass = 'grid-max-3';
    @Input() selectedItemIds: number[];
    @Input() editable = true;
    @Input() availableOrderComps: CompDefinition[] = [];
    @Input() showImageOverride = false;

    @ViewChildren(RemovableItemComponent) removableItems: QueryList<RemovableItemComponent>;

    @ViewChild(MasonryGridComponent) masonry: MasonryGridComponent;

    @Output() layout: EventEmitter<any> = new EventEmitter();
    @Output() openItemSummary: EventEmitter<OrderItemSummary> = new EventEmitter<OrderItemSummary>();
    @Output() removeItemSummary: EventEmitter<OrderItemSummary> = new EventEmitter<OrderItemSummary>();
    @Output() removeTokens = new EventEmitter<OrderItem>();
    @Output() addCredit = new EventEmitter<OrderItem>();
    @Output() removeCredit = new EventEmitter<any>();
    @Output() updateItem: EventEmitter<OrderItem> = new EventEmitter<OrderItem>();
    @Output() addToSummary: EventEmitter<OrderItemSummary> = new EventEmitter<OrderItemSummary>();
    @Output() removeFromSummary: EventEmitter<OrderItemSummary> = new EventEmitter<OrderItemSummary>();
    @Output() itemsSelected = new EventEmitter<OrderItem[]>();
    @Output() scrollToItemSummary = new EventEmitter<OrderItemSummary>();

    itemSummaries: OrderItemSummary[] = [];
    curIndex: number;
    tabStatuses = TabStatuses;
    orderStatuses = OrderStatuses;
    loaded = false;

    availabilities = Availabilities;

    constructor() {
    }

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes.order && !changes.order.firstChange) {
            this.init(changes.order.previousValue);
            if (!!this.masonry) {
                this.masonry.layout();
            }
        }
    }

    init(previousOrder: Order = null) {
        if (!!this.order) {
            let newItem = null;
            if (!!previousOrder) {
                if (this.order.items.length > previousOrder.items.length) {
                    newItem = this.order.items.reduce((max, item) => max = !max || max.addIndex <= item.addIndex ? item : max);
                }
            }

            // consolidate into list of counts and item on common items unless the order is pending
            const items = [...this.order.items].sort((a, b) => a.addIndex - b.addIndex);

            const itemSummaries = items.reduce((accumulator: OrderItemSummary[], cur) => {
                const casted = new OrderItem(cur);
                casted.init();
                const itemSummary = !this.details ? accumulator.find(e => compareItems(e.items[0], casted)) : null;

                if (itemSummary) {
                    itemSummary.items.push(cur);
                    itemSummary.total += cur.total;
                } else {
                    accumulator.push({key: cur.key, items: [casted], total: cur.total});
                }
                return accumulator;
            }, []);

            this.itemSummaries = itemSummaries.sort((a, b) => {
                let aVal = a.items[0].orderType;
                let bVal = b.items[0].orderType;
                if (aVal < bVal) {
                    return -1;
                }
                if (aVal > bVal) {
                    return 1;
                }

                aVal = `${!!a.items[0].selectedPrice ? a.items[0].selectedPrice.name : ''}`;
                bVal = `${!!b.items[0].selectedPrice ? b.items[0].selectedPrice.name : ''}`;

                if (aVal < bVal) {
                    return -1;
                }
                if (aVal > bVal) {
                    return 1;
                }
                return a.items[0].addIndex - b.items[0].addIndex;
            });

            if (!!newItem) {
                const changedSummary = this.itemSummaries.find(itemSummary => itemSummary.items.some(i => i.addIndex === newItem.addIndex));
                if (!!changedSummary) {
                    this.scrollToItemSummary.emit(changedSummary);
                }
            }
        }
    }

    gridLoaded() {
        setTimeout(() => {
            this.loaded = true;
            this.masonry.layout();
        }, 150);
    }

    selectionCheck(a: OrderItem, b: OrderItem) {
        return (!a.selections && !b.selections)
            || (!!a.selections && !!b.selections && a.selections.length === b.selections.length);
    }

    updateGridLayout() {
        setTimeout(() => this.masonry.layout(), 100);
    }

    async removeSingleItem(index, itemSummary) {
        this.curIndex = index;
        if ((!itemSummary.items[0].membershipRenewal && itemSummary.items.length === 1)
            || (!!itemSummary.items[0].membershipRenewal && itemSummary.items[0].membershipRenewal.quantity === 1)) {
            await this.removableItems.get(index).animate();
        }
        this.removeFromSummary.emit(itemSummary);
    }

    removeItemSummaryClicked(index, itemSummary) {
        this.curIndex = index;
        this.removeItemSummary.emit(itemSummary);
    }

    async reverse() {
        if (!!this.removableItems && this.removableItems.length > 0 && !!this.curIndex || this.curIndex === 0) {
            await this.removableItems.get(this.curIndex).reverse();
        }
    }

    allSelected(itemSummary: OrderItemSummary) {
        return !!this.selectedItemIds
            && this.selectedItemIds.length > 0
            && !!itemSummary.items
            && itemSummary.items.every(i => this.selectedItemIds.includes(i.id));
    }

    getKey(index: number, obj: any) {
        return obj.key;
    }
}
