import {Injectable} from '@angular/core';
import {MembershipRenewal} from '../_models/membership-renewal';
import {BaseService} from './base-service';

@Injectable({
    providedIn: 'root'
})
export class MembershipRenewalService extends BaseService<MembershipRenewal> {
    destination = 'membership-renewals';
}
