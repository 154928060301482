<ion-card>
    <div *ngIf="!imageLoaded" class="image-loading">
        <ion-spinner name="bubbles"></ion-spinner>
    </div>
    <ion-card-header>
        <ion-card-title *ngIf="!hideUser">{{marketingPush.user.person.name}}</ion-card-title>
        <ion-card-subtitle>{{marketingPush.createDate | date:'medium'}}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
        <ion-grid>
            <ion-row *ngIf="!!marketingPush.image || !!marketingPush.navUrl">
                <ion-col class="ion-text-center">
                    <img *ngIf="!!marketingPush.image" [src]="marketingPush.image" class="push-image pointer" alt=""
                         (click)="navigate()"
                         (load)="hideLoader()"
                         (error)="hideLoader()">
                    <a *ngIf="!marketingPush.image" (click)="navigate()">{{marketingPush.navUrl}}</a>
                </ion-col>
            </ion-row>
            <ion-row>
                <p>{{marketingPush.message}}</p>
            </ion-row>
        </ion-grid>
    </ion-card-content>
</ion-card>
