import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'bb-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {
    @Input() rating: number;
    @Input() size = 'large';
    @Output() select = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
    }

    rate(rate) {
        this.select.emit(rate);
    }
}
