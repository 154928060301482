<modal-header title="{{itemGroup.name}}"></modal-header>

<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col *ngFor="let item of itemGroup.menuItems; trackBy: getId;"
                     bbLongPress
                     size-xs="6"
                     (longPress)="openItemModal(item.menuItem)"
                     (click)="checkMode(item.menuItem)"
                     class="menu-item-column pointer">
                <bb-mobile-menu-item [item]="item"
                                     [rewardProgram]="rewardProgram"
                                     [activeCredit]="activeCredit"
                                     [showInventory]="showInventory"
                                     (openItemModal)="openItemModal(item.menuItem)"></bb-mobile-menu-item>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
