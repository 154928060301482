<ion-card>
    <ion-card-header>
        <ion-card-title>
            <bb-secured feature="{{featureKeys.USER_ADMIN}}">
                <ion-toolbar>
                    <ion-label>Users</ion-label>
                    <ion-buttons slot="end">
                        <ion-button color="dark" data-cy="create-user-button" (click)="openInviteUser()">
                            <ion-icon name="add" slot="icon-only"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
                <ion-label notAuthorized>Users</ion-label>
            </bb-secured>
        </ion-card-title>
    </ion-card-header>
    <ion-card-content class="scrollable-content">
        <ion-list>
            <ion-item *ngIf="users.length < 1">
                <ion-label>No users exist</ion-label>
            </ion-item>
            <ion-item data-cy="user-item" *ngFor="let user of users" routerLink="/user/{{user.id}}/{{orgId}}">
                <ion-label>
                    {{ user.person.name }}
                    <p *ngIf="!!user.person.mobile">{{ user.person.mobile }}</p>
                    <p>{{ user.person.email }}</p>
                    <p>{{ user.organizationRolesString(orgId) }}</p>
                </ion-label>

                <ion-buttons *bbSecured="featureKeys.USER_ADMIN" slot="end">
                    <ion-button color="dark" (click)="confirmDelete($event, user)">
                        <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-item>
        </ion-list>
    </ion-card-content>
</ion-card>
