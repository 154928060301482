import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {VenueService} from '../_services/venue.service';

@Injectable()
export class AllVenuesResolver implements Resolve<any> {
    constructor(private venueService: VenueService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.venueService.list();
    }
}
