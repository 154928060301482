import {OrderItem} from '../_models/order-item';
import {Tab} from '../_models/tab';
import {Organization} from '../_models/organization';
import {formattedDate} from './date-utils';
import {OrderItemSummary} from '../_models/order-item-summary';
import {multiplyCurrency} from './currency-math';
import {OrderItemSelectionItem} from '../_models/order-item-selection-item';
import {compareItems} from './order-utils';
import {Address} from '../_models/address';
import {OrderStatuses} from '../_constants/order-statuses';
import {Order} from '../_models/order';
import {Deposit} from '../_models/deposit';
import {GiftCardAction} from '../_models/gift-card-action';
import {PaymentCharge} from '../_models/payment-charge';

const NEW_LINE = '\n';
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

export function centeredText(s: string, length: number) {
    if (!!s) {
        const l = s.length;
        if (l <= length) {
            return s.padStart(((length + l) / 2), ' ');
        } else {
            const spaced = s.split(' ');

            if (spaced.length > 1) {
                let section = '';
                while (spaced.length > 0) {
                    const next = `${section} ${spaced[0]}`;
                    if (next.length > length) {
                        return centeredText(section, length) + NEW_LINE + centeredText(spaced.join(' '), length);
                    } else {
                        section = next;
                    }
                    spaced.shift();
                }
            } else {
                return formatMaxLength(s, length);
            }
        }
    } else {
        return '';
    }
}

export function lineSeparator(length: number) {
    return ''.padStart(length, '-') + NEW_LINE;
}

export function formatMaxLength(s: string, maxLength: number) {
    if (s.length > maxLength) {
        return s.substring(0, maxLength - 3) + '...';
    } else {
        return s;
    }
}

export function buildReceiptHeader(organization: Organization, tab: Tab, length: number) {
    let output = '';
    if (!!organization) {
        output += centeredText(organization.name, length) + NEW_LINE;

        let address: Address;
        if (!!tab.orders) {
            const order = tab.orders.find(o => !!o.pickupLocation && !!o.pickupLocation.address);
            if (!!order) {
                address = order.pickupLocation.address;
            }
        }

        if (!address && !!organization.address) {
            address = organization.address;
        }

        if (!!address) {
            output += printAddress(address, length);
        }
    }

    if (!!tab) {
        output += `ID: ${tab.tabNumber}` + NEW_LINE;
        if (!!tab.server && !!tab.server.firstName) {
            output += `Server: ${tab.server.firstName} ${tab.server.lastName.substring(0, 1)}` + NEW_LINE;
        }
        output += formattedDate(tab.startTime) + NEW_LINE;
    }

    output += lineSeparator(length);
    return output;
}

export function buildGiftReceiptHeader(organization: Organization, giftCardAction: GiftCardAction, length: number) {
    let output = '';
    if (!!organization) {
        output += centeredText(organization.name, length) + NEW_LINE;

        if (!!organization.address) {
            output += printAddress(organization.address, length);
        }
    }

    output += formattedDate(giftCardAction.createDate) + NEW_LINE;

    output += lineSeparator(length);
    return output;
}

export function printAddress(address: Address, length: number) {
    let output = '';
    if (!!address.addr1) {
        output += centeredText(address.addr1, length) + NEW_LINE;
    }
    if (!!address.addr2) {
        output += centeredText(address.addr2, length) + NEW_LINE;
    }
    if (!!address.addr3) {
        output += centeredText(address.addr3, length) + NEW_LINE;
    }
    output += centeredText(`${address.city}, ${address.state} ${address.postalCode}`
            , length)
        + NEW_LINE + NEW_LINE;
    return output;
}

export function buildReceiptOrderItemSummary(itemSummary: OrderItemSummary, length: number) {
    if (!!itemSummary && !!itemSummary.items && itemSummary.items.length > 0) {
        return buildReceiptOrderItem(itemSummary.items[0], 0, length, itemSummary.items.length);
    } else {
        return '';
    }
}

export function buildReceiptOrderItem(input: OrderItem | OrderItemSelectionItem, currentPad: number, length: number, count = 1) {
    const item = isOrderItem(input) ? input : input.orderItem;
    let total = 0;

    if (isOrderItem(input)) {
        if (!!item.priceOverride) {
            total = item.priceOverride;
        } else if (!!item.selectedPrice) {
            total = item.selectedPrice.price;
        } else {
            total = item.total;
        }
    } else {
        total = !!input.menuSelectionItem.additionalPrice ? input.menuSelectionItem.additionalPrice : input.orderItem.total;
    }
    // title: string, amount: number, padCount: number, length: number, prefix = '', prefixMaxLength = 0;
    let output = buildCurrencyLineItem(formatItemName(item), multiplyCurrency(count, total), currentPad, length,
        (isOrderItem(input) ? count.toString(10) : ''), 4);

    const addedToppings = item.addedToppingsFilter();
    const subPad = isOrderItem(input) ? currentPad : currentPad + 2;
    if (!!addedToppings && addedToppings.length > 0) {
        output += buildLineItem('Add:', null, subPad, length, '', 4);
        for (const topping of addedToppings) {
            output += buildCurrencyLineItem(topping.name,
                (topping.menuItemTopping.price > 0) ? multiplyCurrency(count, topping.menuItemTopping.price) : null,
                subPad + 2, length, '', 4);
        }
    }

    const removedToppings = item.removedToppingsFilter();
    if (!!removedToppings && removedToppings.length > 0) {
        output += buildLineItem('Remove:', null, subPad, length, '', 4);
        for (const topping of removedToppings) {
            output += buildLineItem(topping.name, null, subPad + 2, length, '', 4);
        }
    }

    if (!!item.selections && item.selections.length > 0) {
        for (const selection of item.selections) {
            const selectionPrice = selection.countTotal();
            const selectionCount = selectionPrice > 0
                ? ' (x' + selection.selectedItems.length + ')'
                : '';
            output += buildCurrencyLineItem(selection.menuItemSelection.menuSelection.name + selectionCount, selectionPrice,
                subPad, length, '', 4);

            for (const selectedItem of selection.selectedItems) {
                output += buildReceiptOrderItem(selectedItem, subPad + 2, length, count);
            }
        }
    }

    if (item.status === OrderStatuses.CANCELED) {
        output += ''.padStart(length - 8, ' ') + 'CANCELED' + NEW_LINE;
    }
    return output;
}

export function formatItemName(item: OrderItem) {
    if (!!item.menuItem && !!item.selectedPrice && !!item.selectedPrice.name && item.selectedPrice.name !== 'Default') {
        return `${item.menuItem.name} (${item.selectedPrice.name})`;
    } else {
        return !!item.menuItem ? item.menuItem.name : 'Misc';
    }
}

export function buildReceiptDueSummary(tab: Tab, length: number) {
    let output = '';
    const small = length < 25;
    const totalPad = 18;
    const textPad = length - totalPad;
    if (!!tab && !!tab.paymentInformation) {
        if (!!tab.subtotal) {
            if (small) {
                output += buildCurrencyLineItem('Subtotal', tab.subtotal, 0, length);
            } else {
                output += 'Subtotal'.padStart(textPad, ' ') + currencyFormatter.format(tab.subtotal).padStart(totalPad, ' ') + NEW_LINE;
            }
        }
        if (!!tab.taxes && tab.taxes.length > 0) {
            for (const tax of tab.taxes) {
                if (small) {
                    output += buildCurrencyLineItem(`${tax.name} (${tax.taxRate}%)`, tax.tax, 0, length);
                } else {
                    output += formatMaxLength(`${tax.name} (${tax.taxRate}%)`, textPad).padStart(textPad, ' ')
                        + currencyFormatter.format(tax.tax).padStart(totalPad, ' ') + NEW_LINE;
                }
            }
        }
        if (tab.totalFee > 0) {
            if (small) {
                output += buildCurrencyLineItem('Mobile Order Fee', tab.totalFee, 0, length);
            } else {
                output += 'Mobile Order Fee'.padStart(textPad, ' ') + currencyFormatter.format(tab.totalFee).padStart(totalPad, ' ') + NEW_LINE;
            }
        }

        if (tab.paymentInformation.credits > 0) {
            if (small) {
                output += buildLineItem('Credits', ('(' + currencyFormatter.format(tab.paymentInformation.credits) + ')'), 0, length);
            } else {
                output += 'Credits'.padStart(textPad, ' ') + ('(' + currencyFormatter.format(tab.paymentInformation.credits) + ')')
                    .padStart(totalPad, ' ') + NEW_LINE;
            }
        }

        output += lineSeparator(length);
        if (small) {
            output += buildCurrencyLineItem('Total', tab.paymentInformation.invoiced, 0, length);
        } else {
            output += 'Total'.padStart(textPad, ' ') +
                currencyFormatter.format(tab.paymentInformation.invoiced).padStart(totalPad, ' ') + NEW_LINE;
        }

        if (tab.paymentInformation.tips > 0) {
            if (small) {
                output += buildCurrencyLineItem('Tip', tab.paymentInformation.tips, 0, length);
            } else {
                output += 'Tip'.padStart(textPad, ' ')
                    + currencyFormatter.format(tab.paymentInformation.tips).padStart(totalPad, ' ') + NEW_LINE;
            }
        }
    }

    output += lineSeparator(length);

    return output;
}

export function buildReceiptPaymentSummary(tab: Tab, length: number) {
    let output = '';
    const small = length < 25;
    const totalPad = 18;
    const textPad = length - totalPad;
    if (!!tab && !!tab.paymentInformation) {
        if (tab.paymentInformation.cashPaymentsTotal > 0) {
            output += buildCurrencyLineItem('Cash Payments:', tab.paymentInformation.appliedCashTotal, 0, length);
            output += lineSeparator(length);
        }

        if (!!tab.paymentCharges && tab.paymentCharges.length > 0) {
            for (const charge of tab.paymentCharges) {
                if (!!charge.cardSummary) {
                    output += buildPaymentCharge(charge, length);
                }
            }
        }

        if (tab.paymentInformation.appliedPayments > 0) {
            if (small) {
                output += buildCurrencyLineItem('Payments Total', tab.paymentInformation.appliedPayments, 0, length);
            } else {
                output += 'Payments Total'.padStart(textPad, ' ')
                    + currencyFormatter.format(tab.paymentInformation.appliedPayments).padStart(totalPad, ' ') + NEW_LINE;
            }
        }

        if (small) {
            output += buildLineItem('Due', currencyFormatter.format(tab.paymentInformation.remaining), 0, length);
        } else {
            output += 'Due'.padStart(textPad, ' ')
                + currencyFormatter.format(tab.paymentInformation.remaining).padStart(totalPad, ' ') + NEW_LINE;
        }
    }

    output += lineSeparator(length);
    return output;
}

export function buildReceiptDepositSummary(deposits: Deposit[], length: number) {
    let output = '';
    const small = length < 25;
    const totalPad = 18;
    const textPad = length - totalPad;
    if (!!deposits && deposits.length > 0) {
        for (const deposit of deposits) {
            if (!!deposit.paymentCharge) {
                output += buildPaymentCharge(deposit.paymentCharge, length, true);
            }
        }
    }

    return output;
}

export function buildPaymentCharge(charge: PaymentCharge, length: number, deposit = false) {
    let output = formattedDate(charge.createDate) + NEW_LINE;
    output += buildCurrencyLineItem(charge.cardSummary.cardType.toUpperCase().padEnd(11, ' ') + charge.cardSummary.lastFour.padEnd(5, ' '),
        charge.total, 0, length);

    if (deposit) {
        output += ''.padStart(length - 7, ' ') + 'DEPOSIT' + NEW_LINE;
    }

    if (!!charge.applicationLabel) {
        output += `${charge.applicationLabel}` + NEW_LINE;
    }

    if (!!charge.authCode) {
        output += `Auth Code: ${charge.authCode}` + NEW_LINE;
    }

    if (!!charge.issuerApplicationData) {
        output += `AID:       ${charge.issuerApplicationData}` + NEW_LINE;
    }

    if (!!charge.cvmMethod && charge.cvmMethod.toLowerCase() !== 'none') {
        output += `CVM:       ${charge.cvmMethod}` + NEW_LINE;
    }

    if (charge.voided) {
        output += ''.padStart(length - 6, ' ') + 'VOIDED' + NEW_LINE;
    }

    if (!!charge.refunds && charge.refunds.length > 0) {
        for (const refund of charge.refunds) {
            output += `    ${formattedDate(refund.createDate)}` + NEW_LINE;
            output += `    Refund ${charge.cardSummary.lastFour}`.padEnd(length - 16, ' ')
                + ('(' + currencyFormatter.format(refund.total) + ')').padStart(16, ' ') + NEW_LINE;
        }
    }

    output += lineSeparator(length);
    return output;
}

export function buildGiftCardReceipt(organization: Organization, action: GiftCardAction, balance: number, length: number) {
    let output = '';
    if (!!organization && !!action) {
        output += buildGiftReceiptHeader(organization, action, length);

        output += buildCurrencyLineItem('Load Gift Card', action.amount,
            0, length, '', 0);
        output += buildCurrencyLineItem('Balance', balance,
            0, length, '', 0);

        output += lineSeparator(length);

        if (!!action.cashAudit) {
            output += buildCurrencyLineItem('Cash:', action.cashAudit.amount, 0, length);
        } else if (action.credited) {
            output += buildCurrencyLineItem('Comp:', action.amount, 0, length);
        } else if (action.paymentCharge) {
            output += buildPaymentCharge(action.paymentCharge, length);
        }
        output += '\n\n\n';
    }
    return output;
}

export function buildReceipt(organization: Organization, tab: Tab, length: number, deposits: Deposit[] = null) {
    let output = '';
    if (!!organization && !!tab) {
        output += buildReceiptHeader(organization, tab, length);
        tab.orders.forEach(o => o.items.forEach(i => i.status = o.status));
        const items = tab.orders.reduce((accumulator: OrderItem[], cur) => accumulator.concat(cur.items), []);

        const itemSummaries = items.reduce((accumulator: OrderItemSummary[], cur) => {
            const casted = new OrderItem(cur);
            casted.init();
            const item = accumulator.find(e => compareItems(e.items[0], casted));

            if (item) {
                item.items.push(cur);
                item.total += cur.total;
            } else {
                accumulator.push({key: casted.key, items: [casted], total: cur.total});
            }
            return accumulator;
        }, []);

        itemSummaries.forEach(s => output += buildReceiptOrderItemSummary(s, length));

        output += lineSeparator(length);

        output += buildReceiptDueSummary(tab, length);

        output += buildReceiptPaymentSummary(tab, length);

        output += buildReceiptDepositSummary(deposits, length);

        if (!!organization.receiptTag) {
            output += buildTag(organization.receiptTag, length);
        }
    }
    return output;
}

export function buildCurrencyLineItem(title: string, amount: number, padCount: number, length: number, prefix = '', prefixPadding = 0) {
    return buildLineItem(title, !!amount ? currencyFormatter.format(amount) : '', padCount, length, prefix, prefixPadding);
}

export function buildTag(receiptTag: string, length: number) {
    const lines = receiptTag.split('\n');
    let output = NEW_LINE;
    lines.forEach(l => output += centeredText(l, length) + NEW_LINE);
    return output;
}

export function buildLineItem(title: string, suffix: string, padCount: number, length: number, prefix = '', prefixPadding = 0) {
    const formattedSuffix = !!suffix ? '  ' + suffix : '';

    const placeholder = ' ';
    const padding = ''.padStart(padCount, ' ');
    const maxTitleLength = length - prefixPadding - ((length > 24) ? formattedSuffix.length : 0);

    const spaced = title.split(' ');
    const lines = [];
    let output = '';
    if (!!prefix || prefixPadding > 0) {
        output = !!prefix ? prefix.padEnd(prefixPadding) : placeholder.padEnd(prefixPadding);
    }

    if (spaced.length > 1) {
        let line = padding;
        while (spaced.length > 0) {
            const next = (line.length > padding.length ? line + ' ' : line) + spaced[0];
            if (next.length > maxTitleLength) {
                lines.push(line);
                line = padding + spaced[0];
            } else {
                line = next;
            }
            spaced.shift();
        }
        lines.push(line);
    } else {
        lines.push(padding + formatMaxLength(title, maxTitleLength));
    }

    let printedAmount = false;
    if (length > 24) {
        output += lines[0].padEnd(maxTitleLength, ' ') + formattedSuffix + NEW_LINE;
        printedAmount = true;
    } else if (lines[0].length + formattedSuffix.length <= maxTitleLength) {
        output += lines[0].padEnd(maxTitleLength - formattedSuffix.length, ' ') + formattedSuffix + NEW_LINE;
        printedAmount = true;
    } else {
        output += lines[0].padEnd(maxTitleLength, ' ') + NEW_LINE;
    }

    lines.shift();

    for (const line of lines) {
        output += (placeholder.padEnd(prefixPadding, ' ') + line).padEnd(length, ' ') + NEW_LINE;
    }

    if (!printedAmount && !!formattedSuffix) {
        output += placeholder + formattedSuffix.padStart(length - 1, ' ') + NEW_LINE;
    }
    return output;
}

export function buildOrderReceipt(order: Order) {

}

export function isOrderItem(i: OrderItem | OrderItemSelectionItem): i is OrderItem {
    return (i as OrderItem).menuItem !== undefined;
}
