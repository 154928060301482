import {BaseFormModel} from './base-form-model';

export class CardTransactionResponse extends BaseFormModel<CardTransactionResponse> {
    objectType: string;
    objectId: number;
    organizationId: number;
    createDate: Date;
    code: number;
    message: string;
    referenceId: string;
}
