import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {CashAudit} from '../_models/cash-information/cash-audit';
import {BaseService} from './base-service';
import {Constants} from '../_constants/constants';

@Injectable({
    providedIn: 'root'
})
export class CashAuditService extends BaseService<CashAudit> {
    destination = 'cash-audits';

    create(cashAudit: CashAudit, silent = false) {
        let headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406,417,424');
        if (silent) {
            headers = headers.set(Constants.SILENT, 'true');
        }
        return this.http.post(this.baseUrl(), cashAudit, {headers});
    }

    payTab(cashAudit: CashAudit) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406,417,424');
        return this.http.post(`${this.baseUrl()}/tab`, cashAudit, {headers});
    }


    transfer(fromId: number, toId: number, cashAudit: CashAudit) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406');
        return this.http.post(`${this.baseUrl()}/transfer/${fromId}/${toId}`, cashAudit, {headers});
    }

    getByCashDrawer(cashDrawerId: number) {
        return this.http.get(`${this.baseUrl()}/cash-drawer/${cashDrawerId}`);
    }

    getByCashSessionAndPerson(cashSessionId: number, personId: number) {
        return this.http.get(`${this.baseUrl()}/cash-session/${cashSessionId}/tip-out/${personId}`);
    }

    currentCount(pickupLocationId: number) {
        return this.http.get(`${this.baseUrl()}/${pickupLocationId}/current`);
    }
}
