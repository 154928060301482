import {Component, OnDestroy} from '@angular/core';
import {SubscriberComponent} from './subscriber.component';
import {BaseEventService} from '../_services/base-event.service';

@Component({
    template: ''
})
export class ChannelSubscriberComponent extends SubscriberComponent implements OnDestroy {
    public channels = [];

    constructor(protected baseEventService: BaseEventService) {
        super();
    }

    ngOnDestroy(): void {
        this.channels.forEach(c => {
            c.unsubscribe();
            c.detach();
        });
        this.channels = [];
        super.ngOnDestroy();
    }
}
