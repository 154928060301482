import {Component, Input} from '@angular/core';

@Component({
    selector: 'bb-separator',
    templateUrl: './separator.component.html',
    styleUrls: ['./separator.component.scss'],
})
export class SeparatorComponent {
    @Input() vertical = false;
    constructor() {
    }
}
