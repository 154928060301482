import {Injectable} from '@angular/core';
import {BaseObservableService} from './base-obeservable-service';
import {TabNoteDefinition} from '../_models/tab-note-definition';

@Injectable({
    providedIn: 'root'
})
export class TabNoteDefinitionService extends BaseObservableService<TabNoteDefinition> {
    destination = 'tab-note-definitions';
}
