<modal-header title="Connect Card Reader"></modal-header>

<ion-content>
    <ion-grid class="ion-padding-start ion-padding-end">
        <ion-row>
            <ion-col size-xs="12"
                     class="card-reader selected-card-reader">
                <img src="assets/reader-images/e285.png" class="card-reader-image" alt=""/>
                <h3 *ngIf="!!currentTerminal">{{currentTerminal.nickname}}</h3>
            </ion-col>
        </ion-row>
    </ion-grid>

    <ion-list>
        <ion-item *ngIf="loaded">
            <ion-label *ngIf="(!terminals || terminals.length === 0)">
                <h3>No terminals have been configured.</h3>
            </ion-label>
            <ion-buttons slot="end">
                <ion-button (click)="openEditTerminal($event, null)">
                    <ion-icon slot="icon-only" color="dark" name="add-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-item>
        <ion-radio-group [(ngModel)]="currentTerminal">
            <ion-item *ngFor="let terminal of terminals">
                <ion-label>
                    {{ terminal.nickname }}
                </ion-label>
                <ion-radio slot="start" [value]="terminal" color="success"></ion-radio>
                <ion-buttons slot="end">
                    <ion-button color="dark" (click)="openEditTerminal($event, terminal)">
                        <ion-icon name="create-outline" slot="icon-only"></ion-icon>
                    </ion-button>
                    <ion-button color="dark" (click)="deleteTerminal($event, terminal)">
                        <ion-icon name="trash-outline" slot="icon-only"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-item>
            <ion-item>
                <ion-label>
                    None
                </ion-label>
                <ion-radio slot="start" value="" color="danger"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>
</ion-content>

<modal-footer submitLabel="Connect" (submitClicked)="connectTerminal()"></modal-footer>
