export class ColumnField {
    key: string;
    format: string;
    display: string;
    filterType: string;

    constructor(key: string, format: string, display: string, filterType = null) {
        this.key = key;
        this.format = format;
        this.display = display;
        this.filterType = filterType;
    }
}
