import {Injectable} from '@angular/core';
import {BaseObservableService} from './base-obeservable-service';
import {CompDefinition} from '../_models/comp-definition';

@Injectable({
    providedIn: 'root'
})
export class CompDefinitionService extends BaseObservableService<CompDefinition> {
    destination = 'comp-definitions';

    findByMembershipProgramId(membershipProgramId: number | string) {
        return this.http.get(`${this.baseUrl()}/membership-program/${membershipProgramId}`);
    }

    sort(items: CompDefinition[]) {
        return this.http.put(this.baseUrl() + '/sort', items);
    }
}
