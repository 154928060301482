import {Injectable} from '@angular/core';
import {DashboardWidget} from '../_models/dashboard-widget';
import {SortableService} from './sortable-service';

@Injectable({
    providedIn: 'root'
})
export class DashboardWidgetService extends SortableService<DashboardWidget> {
    destination = 'dashboard-widgets';

    findByOrganizationId(orgId: number) {
        return this.http.get(`${this.baseUrl()}?orgId=${orgId}`);
    }
}
