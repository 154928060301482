import {Injectable} from '@angular/core';
import {MenuMarqueeItem} from '../_models/menu-marquee-item';
import {SortableService} from './sortable-service';

@Injectable({
    providedIn: 'root'
})
export class MenuMarqueeItemService extends SortableService<MenuMarqueeItem> {
    destination = 'menu-marquee-items';
}
