<modal-header title="Edit Details"></modal-header>

<ion-content>
    <ion-grid>
        <form [formGroup]="editVenueForm" (ngSubmit)="onSubmit()">
            <bb-venue-form (formReady)="formInitialized($event)" [venue]="venue"></bb-venue-form>
        </form>
    </ion-grid>
</ion-content>

<modal-footer [disableSubmit]="editVenueForm.invalid" (submitClicked)="onSubmit()"></modal-footer>
