<div class="shim" *ngIf="active">&nbsp;</div>
<ion-item class="pointer">
    <ion-note slot="start">{{tab.paymentInformation.invoiced | currency}}</ion-note>
    <ion-label>
        <h2 class="label-content">{{!!tab.person ? tab.person.name : 'Terminal Order'}}</h2>
        <p *ngIf="!!tab.person" class="label-content">{{tab.person.email}}</p>
        <p class="label-content">{{tab.startTime | date:'M/d/yy h:mm a'}}</p>
    </ion-label>

    <span *ngIf="tab.status === tabStatuses.DELETED" class="pill-dark">
    DELETED
  </span>
    <span>
  </span>
    <bb-payment-status [paymentStatus]="tab.paymentInformation.paymentStatus" class="payment-status"></bb-payment-status>
    <span class="time-ago" slot="end">
    {{tab.startTime | dateAgo:true}}
  </span>
</ion-item>
