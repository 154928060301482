<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon slot="icon-only" color="dark" name="close-circle-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>
            {{ title }}
            <p *ngIf="!!subHeader" class="sub-header">{{subHeader}}</p>
        </ion-title>
    </ion-toolbar>
</ion-header>
