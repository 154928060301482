import {Component, Input, OnInit} from '@angular/core';
import {Tab} from '../../_models/tab';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {BaseModalComponent} from '../base-modal.component';
import {TabService} from '../../_services/tab.service';
import {Order} from '../../_models/order';
import {LoadingService} from '../../_services/loading.service';
import {ToastService} from '../../_services/toast.service';
import {OrderService} from '../../_services/order.service';
import {OrderStatuses} from '../../_constants/order-statuses';
import {TabStatuses} from '../../_constants/tab-statuses';

@Component({
    selector: 'bb-move-order',
    templateUrl: './move-order.component.html',
    styleUrls: ['./move-order.component.scss'],
})
export class MoveOrderComponent extends BaseModalComponent implements OnInit {
    @Input() order: Order;
    @Input() orderItemIds: number[];

    selected: Tab;
    options: Tab[];
    loaded = false;

    constructor(
        private tabService: TabService,
        private orderService: OrderService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private navController: NavController,
        private alertController: AlertController,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.subscribe(this.tabService.openTabs.subscribe(tabs => {
            this.options = tabs.sort((a: Tab, b: Tab) => {
                const aName = a.nameOverride ? a.nameOverride : (!!a.person ? a.person.name : 'Terminal Order');
                const bName = b.nameOverride ? b.nameOverride : (!!b.person ? b.person.name : 'Terminal Order');
                return (aName > bName) ? 1 : -1;
            });

            if (!!this.options && this.options.length > 0) {
                this.selected = this.options.find(t => t.id === this.order.parentId);
            }
            this.loaded = true;
        }));

        this.subscribe(this.orderService.event.subscribe(o => {
            if (!!o && this.order.id === o.id) {
                if (this.order.status === OrderStatuses.CANCELED) {
                    this.toastService.message('The order has been canceled.');
                    this.close();
                }

                if (this.order.parentId !== o.parentId) {
                    this.toastService.message('The order has been moved.');
                    this.close();
                }
            }
        }));

        this.subscribe(this.tabService.event.subscribe(t => {
            if (!!t && t.id === this.order.parentId && t.status !== TabStatuses.OPEN) {
                this.toastService.message('The tab is no longer available.');
                this.close();
            }
        }));
    }

    selectTab(tab: Tab) {
        this.selected = tab;
        const previous = tab;
        if (!tab || tab.id !== this.order.parentId) {
            this.loadingService.present();
            this.orderService.move(this.order.id, !!tab ? tab.id : null, this.orderItemIds).subscribe(async (o: Order) => {
                this.order = new Order(o);
                this.loadingService.dismiss();
                const alert = await this.alertController.create({
                    cssClass: 'brewbill-alert',
                    header: 'Navigate to New Tab?',
                    message: 'Would you like to navigate to the new tab or stay on the current tab?',
                    buttons: [
                        {
                            text: 'Stay',
                            role: 'cancel',
                            cssClass: 'secondary'
                        }, {
                            text: 'Navigate to New Tab',
                            handler: async () => {
                                this.navController.navigateForward(`/manager/${this.order.pickupLocation.id}/tab/${o.parentId}`);
                            }
                        }
                    ]
                });

                await alert.present();
                this.close();
            }, error => {
                if (error.status === 409) {
                    this.toastService.error('The selected tab is closed or invalid.');
                    this.selected = previous;
                }
            });
        }
    }
}
