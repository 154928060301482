import {Inject, Injectable} from '@angular/core';
import {
    BaseEventService,
    CustomerAreaPosition,
    CustomerAreaPositionService,
    DeviceService,
    LibConfig,
    LibConfigService,
    Membership,
    MembershipService,
    Menu,
    MenuItemInventoryService,
    MenuService,
    Order,
    OrderItemCredit,
    OrderModalService,
    OrderService,
    OrderStatusColorPipe,
    OrderStatuses,
    OrderStatusPipe,
    Organization,
    OrganizationService,
    PickupLocation,
    PickupLocationMenu,
    PickupLocationMenuService,
    PickupLocationService,
    Tab,
    TabService,
    TabStatuses,
    ToastService,
    User,
    UserNotificationSummary,
    UserNotificationSummaryService,
    UserService
} from 'brewbill-lib';
import {Vibration} from '@ionic-native/vibration/ngx';
import {NavController, Platform} from '@ionic/angular';
import {CurrencyPipe} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class EventService extends BaseEventService {
    pickupLocationChannelPrefix = 'USER_PICKUP_LOCATION_EVENT';
    organizationChannelPrefix = 'USER_ORG_EVENT';

    loadMapMenu = true;
    uuid: string;
    personId: number;

    user: User;
    organization: Organization;
    pickupLocation: PickupLocation;
    pickupLocationMenu: PickupLocationMenu;
    customerAreaPosition: CustomerAreaPosition;
    tab: Tab;
    order: Order;
    activeOrders: Order[];
    openTabs: Tab[];

    userChannel: string;
    organizationChannel: string;

    constructor(
        private toastService: ToastService,
        private orderStatusPipe: OrderStatusPipe,
        private membershipService: MembershipService,
        private orderStatusColorPipe: OrderStatusColorPipe,
        private currencyPipe: CurrencyPipe,
        private orderModalService: OrderModalService,
        private userNotificationSummaryService: UserNotificationSummaryService,
        private vibration: Vibration,
        private userService: UserService,
        private customerAreaPositionService: CustomerAreaPositionService,
        private deviceService: DeviceService,
        private navController: NavController,
        pickupLocationService: PickupLocationService,
        pickupLocationMenuService: PickupLocationMenuService,
        menuService: MenuService,
        menuItemInventoryService: MenuItemInventoryService,
        orderService: OrderService,
        tabService: TabService,
        organizationService: OrganizationService,
        platform: Platform,
        @Inject(LibConfigService) public config: LibConfig
    ) {
        super(pickupLocationService, pickupLocationMenuService, menuService, menuItemInventoryService, orderService,
            tabService, organizationService, platform, config);

        this.customerAreaPositionService.current.subscribe(c => {
            this.customerAreaPosition = !!c ? new CustomerAreaPosition(c) : null;
        });

        this.tabService.current.subscribe(t => {
            this.tab = !!t ? new Tab(t) : null;

            if (!!this.tab && this.tab.preload) {
                this.tabService.getSilent(this.tab.id).subscribe(u => this.tabService.setCurrent(new Tab(u)),
                    () => {
                        this.tabService.setCurrent(null);
                    });
            }
        });

        this.orderService.current.subscribe(o => {
            this.order = !!o ? new Order(o) : null;
            if (!!this.order && this.order.preload) {
                this.orderService.getSilent(this.order.id).subscribe(u => this.orderService.setCurrent(new Order(u)),
                    () => {
                        this.orderService.setCurrent(null);
                    });
            }
        });

        this.tabService.openTabs.subscribe(t => {
            this.openTabs = t;
        });

        this.orderService.activeOrders.subscribe(t => {
            this.activeOrders = t;
        });

        this.userService.current.subscribe(u => {
            this.personId = (!!u && !!u.person) ? u.person.id : null;
            this.user = u;
            this.subscribeToUser();
        });

        const parent = this;
        window.addEventListener('focus', () => {
            parent.refresh();
        });
    }

    async connect(deviceUuid) {
        if (!this.ably) {
            this.uuid = deviceUuid;

            const user = this.userService.currentValue;

            const token = !!user ? user.token : null;
            this.connectAbly(token);

            if (!!deviceUuid) {
                const channel = this.getChannel(deviceUuid, !!user ? user.token : null);
                channel.subscribe((message) => {
                    this.handleMessage(message);
                });
            }

            this.subscribeToUser();
            this.subscribeToOrganization();
            this.subscribeToPickupLocation();
        }
    }

    async refreshApp() {
        if (!!this.userService.currentValue) {
            this.userNotificationSummaryService.list().subscribe((summary: UserNotificationSummary) => {
                this.userNotificationSummaryService.setCurrent(summary);
            });
        }

        const user = this.userService.currentValue;
        let orders = null;
        let tabs = null;

        if (user && user.token) {
            orders = await this.orderService.loadCurrent().toPromise() as Order[];
            tabs = await this.tabService.loadCurrent().toPromise() as Tab[];
        }

        const deviceUuid = await this.deviceService.getDeviceId();

        if (!!deviceUuid) {
            const deviceOrders = await this.orderService.loadDeviceCurrent(deviceUuid).toPromise() as Order[];
            orders = !!orders ? orders.concat(deviceOrders) : deviceOrders;

            if (!!orders && orders.length > 0) {
                const customerAreaPositionOrder = orders.find(o => !!o.customerAreaPosition);
                if (!!customerAreaPositionOrder) {
                    this.customerAreaPositionService.setCurrent(new CustomerAreaPosition(customerAreaPositionOrder.customerAreaPosition));
                }
            }

            const deviceTabs = await this.tabService.loadDeviceOpen(deviceUuid).toPromise() as Tab[];
            tabs = !!tabs ? tabs.concat(deviceTabs) : deviceTabs;
        }

        this.orderService.setActiveOrders(orders);
        this.tabService.setOpenTabs(tabs);
    }

    subscribeToUser() {
        const nextChannel = !!this.user ? `USER_EVENT_${this.user.id}` : null;

        if (!!this.userChannel && this.userChannel !== nextChannel) {
            this.releaseChannel(this.userChannel, null);
        }

        if (!!nextChannel
            && nextChannel !== this.userChannel
            && !!this.ably) {

            this.userChannel = nextChannel;

            const channel = this.ably.channels.get(this.userChannel);

            channel.subscribe(async (message) => {
                await this.handleMessage(message);
            });
        }

        if (!nextChannel) {
            this.userChannel = null;
        }
    }

    subscribeToOrganizationChannels(channel) {
        channel.subscribe(async (message) => {
            switch (message.name) {
            case 'ORG_UPDATE':
                const id = parseInt(message.data, 10);
                const current = this.organizationService.currentValue;
                if (!current || (current.id === id)) {
                    this.organizationService.get(id).subscribe(async (organization: Organization) => {
                        await this.organizationService.setCurrent(organization);
                    });
                }
                break;
            case 'MENU_UPDATE':
                const orgId = parseInt(message.data, 10);
                const currentMenu = this.menuService.currentValue;
                if (!currentMenu || (currentMenu.orgId === orgId)) {
                    this.menuService.findByOrganizationId(orgId).subscribe(async (menu: Menu) => {
                        await this.menuService.setCurrent(menu);
                    });
                }
                break;
            case 'INVENTORY_UPDATE':
                this.inventoryEventHandler(parseInt(message.data, 10));
                break;
            }
        });
    }

    async handleMessage(message) {
        switch (message.name) {
        case 'USER_CREDIT':
            const credit = new OrderItemCredit(JSON.parse(message.data));
            if (!credit.deleted) {
                await this.toastService.success('Your tab has been credited ' + this.currencyPipe.transform(credit.amount) + '!');
            } else {
                await this.toastService.error('A credit for ' + this.currencyPipe.transform(credit.amount) + ' hase been removed.');
            }
            break;
        case 'USER_TAB':
            this.tabEventHandler(message.data);
            break;
        case 'USER_TAB_DETACHED':
            this.detachTab(Number.parseInt(message.data, 10));
            break;
        case 'USER_MEMBERSHIP':
            this.membershipEventHandler(Number.parseInt(message.data, 10));
            break;
        case 'USER_NOTIFICATION_SUMMARY':
            this.userNotificationSummaryService.setCurrent(new UserNotificationSummary(JSON.parse(message.data)));
        }
    }

    tabEventHandler(json: string) {
        const currentTab = this.tabService.currentValue;
        const tabInfo = JSON.parse(json);
        const id = Number.parseInt(tabInfo.first, 10);

        if (!id || isNaN(id)) {
            return;
        }

        const version = Number.parseInt(tabInfo.second, 10);

        if (!!currentTab
            && currentTab.id === id
            && !!version
            && version <= currentTab.version) {
            return;
        }

        this.tabService.get(id).subscribe(t => {
            const tab = new Tab(t);
            this.tabService.setEvent(tab);

            if (!!this.tab && this.tab.id === tab.id) {
                this.tabService.setCurrent(tab);
            }

            if (!!this.order
                && this.order.parentId === tab.id
                && (tab.status === TabStatuses.CLOSED || tab.status === TabStatuses.VACATED || tab.status === TabStatuses.DELETED)
                && !this.tab.orders.some(o => o.id === this.order.id)) {
                this.orderService.setCurrent(null);
                this.toastService.message('The pending order has been canceled.');
            }

            const currentOpenTabs = this.tabService.getCurrentOpenTabs();
            const tabIndex = currentOpenTabs.findIndex(ot => ot.id === id);
            if (tabIndex > -1) {
                if (tab.status === TabStatuses.CLOSED && !tab.singleOrder) {
                    currentOpenTabs.splice(tabIndex, 1);
                    this.tabService.setOpenTabs(currentOpenTabs);
                    this.toastService.success('Your tab has been closed. It can be viewed in your tab history.');
                } else {
                    this.tabService.addOpenTab(tab);
                    this.navController.navigateForward(`/tab/${tab.id}`);
                }
            }

            this.handleCustomerAreaPositionChange(tab.orgId, tab.customerAreaPosition);
        });
    }

    detachTab(id: number) {
        const currentTab = this.tabService.currentValue;
        let showMessage = false;
        if (currentTab.id === id) {
            this.tabService.setCurrent(null);
            showMessage = true;
        }

        const currentOpenTabs = this.tabService.getCurrentOpenTabs();
        const tabIndex = currentOpenTabs.findIndex(t => t.id === id);
        if (tabIndex > -1) {
            currentOpenTabs.splice(tabIndex, 1);
            this.tabService.setOpenTabs(currentOpenTabs);
            showMessage = true;
        }

        const activeOrders = this.orderService.getCurrentActiveOrders();
        if (!!activeOrders && activeOrders.length > 0) {
            this.orderService.setActiveOrders(activeOrders.filter(o => o.parentId !== id));
        }

        if (showMessage) {
            this.toastService.message('A tab has been removed from this session.');
        }
    }

    async orderEventHandler(order: Order) {
        this.orderService.setEvent(order);

        if (order.status !== OrderStatuses.DELIVERED
            && order.status !== OrderStatuses.CANCELED
            && order.status !== OrderStatuses.VACATED
            && order.status !== OrderStatuses.DELETED) {
            this.customerAreaPositionService.setCurrent(order.customerAreaPosition);
        }

        const currentOrder = this.orderService.getCurrentActiveOrders().filter(o => o.id === order.id);
        let useToast = true;
        const activeOrders = [];
        this.orderService.getCurrentActiveOrders().forEach(i => {
            activeOrders.push(i);
        });

        if (currentOrder && currentOrder.length === 1) {
            useToast = currentOrder[0].status !== order.status;
            activeOrders.splice(activeOrders.indexOf(currentOrder[0]), 1);
        }

        if (order.isActive()) {
            activeOrders.push(order);
        }

        const orders = activeOrders.sort((a: Order, b: Order) => {
            let ret = new Order(a).statusPriority() - new Order(b).statusPriority();
            if (ret === 0) {
                ret = new Date(a.date).getTime() - new Date(b.date).getTime();
            }
            return ret;
        });

        this.orderService.setActiveOrders(orders);

        const currentTab = this.tabService.currentValue;
        if (!!currentTab && !!currentTab.orders) {
            const foundIndex = currentTab.orders.findIndex(o => o.id === order.id);
            if (foundIndex > -1) {
                currentTab.orders[foundIndex] = order;
            }
            this.tabService.setCurrent(currentTab);
        }

        if (useToast) {
            if (this.vibration && order.status === OrderStatuses.READY) {
                let count = 0;
                this.vibration.vibrate(1000);

                const vibe = setInterval(() => {
                    this.vibration.vibrate(1000);
                    count++;
                    if (count === 4) {
                        clearInterval(vibe);
                    }
                }, 1500);
            } else if (order.status === OrderStatuses.DELIVERED) {
                this.vibration.vibrate(1000);
                await this.toastService.message('Enjoy!', 'success');
            } else if (order.status === OrderStatuses.RECEIVED) {
                this.vibration.vibrate(1000);
                await this.toastService.message('Your order has been received', 'success');
            } else {
                this.vibration.vibrate(1000);
                await this.toastService.message('Your order ' +
                    ((order.status === OrderStatuses.READY || order.status === OrderStatuses.PREPARING) ? 'is ' : 'has been ') +
                    this.orderStatusPipe.transform(order.status, true),
                    order.status === OrderStatuses.DELIVERED ? 'success' : this.orderStatusColorPipe.transform(order.status));
            }
        }

        if (order.status === OrderStatuses.READY) {
            await this.toastService.hide();
            await this.orderModalService.viewOrder(order);
        }
    }

    firstLoadMapMenu() {
        const val = this.loadMapMenu;
        this.loadMapMenu = false;
        return val;
    }

    handleCustomerAreaPositionChange(organizationId: number, customerAreaPosition: CustomerAreaPosition): number[] {
        const tabIds = [];
        if (!!this.customerAreaPosition
            && this.customerAreaPosition.orgId === organizationId
            && (!!customerAreaPosition || !!this.customerAreaPosition)
            && (
                (!customerAreaPosition && !!this.customerAreaPosition) ||
                (!!customerAreaPosition && !this.customerAreaPosition) ||
                this.customerAreaPosition.id !== customerAreaPosition.id
            )) {
            this.customerAreaPositionService.setCurrent(!!customerAreaPosition ? new CustomerAreaPosition(customerAreaPosition) : null);
        }

        if (!!this.tab && this.tab.orgId === organizationId
            && (!!customerAreaPosition || !!this.tab.customerAreaPosition)
            && (
                (!customerAreaPosition && !!this.tab.customerAreaPosition) ||
                (!!customerAreaPosition && !this.tab.customerAreaPosition) ||
                this.tab.customerAreaPosition.id !== customerAreaPosition.id
            )
        ) {
            this.tab.customerAreaPosition = customerAreaPosition;
            tabIds.push(this.tab.id);
        }

        if (!!this.openTabs && this.openTabs.length > 0) {
            this.openTabs.forEach(t => {
                if (t.orgId === organizationId
                    && (!!customerAreaPosition || !!t.customerAreaPosition)
                    && (
                        (!customerAreaPosition && !!t.customerAreaPosition) ||
                        (!!customerAreaPosition && !t.customerAreaPosition) ||
                        t.customerAreaPosition.id !== customerAreaPosition.id
                    )) {
                    t.customerAreaPosition = customerAreaPosition;
                    tabIds.push(t.id);
                }
            });
            this.tabService.setOpenTabs(this.openTabs);
        }

        if (!!this.activeOrders && this.activeOrders.length > 0) {
            this.activeOrders.forEach(a => {
                if (a.orgId === organizationId
                    && (!!customerAreaPosition || !!a.customerAreaPosition)
                    && (
                        (!customerAreaPosition && !!a.customerAreaPosition) ||
                        (!!customerAreaPosition && !a.customerAreaPosition) ||
                        a.customerAreaPosition.id !== customerAreaPosition.id
                    )) {
                    a.customerAreaPosition = customerAreaPosition;
                    tabIds.push(a.parentId);
                }
            });
            this.orderService.setActiveOrders(this.activeOrders);
        }
        return tabIds;
    }

    membershipEventHandler(id: number) {
        this.membershipService.get(id).subscribe((m: Membership) => {
            this.membershipService.setMembership(m);
        });
    }
}
