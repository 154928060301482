import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {IonInput, ModalController} from '@ionic/angular';
import {CustomDollarAmountComponent} from '../custom-dollar-amount/custom-dollar-amount.component';
import {multiplyCurrency} from '../../_utils/currency-math';
import {Organization} from '../../_models/organization';
import {OrganizationService} from '../../_services/organization.service';

@Component({
    selector: 'bb-tip',
    templateUrl: './tip.component.html',
    styleUrls: ['./tip.component.scss']
})
export class TipComponent implements OnChanges, OnInit {
    @Input() tip: number;
    @Input() max: number;
    @Output() tipChange = new EventEmitter<number>();

    @Input() tabTotal: number;
    @Input() hideTip = false;

    @ViewChild('manualTip') manualTip: IonInput;

    organization: Organization;
    selectedTip = null;
    defaults = [];
    tipValue: number;

    constructor(
        private organizationService: OrganizationService,
        private modalController: ModalController
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((!!changes.tabTotal && !changes.tabTotal.isFirstChange())
            || (!!changes.max && !changes.max.isFirstChange())) {
            this.calculateDefaults();
            this.calculateTip();
        }
    }

    ngOnInit(): void {
        this.organization = this.organizationService.currentValue;
        this.calculateDefaults();
        this.calculateTip();
    }

    calculateTip() {
        if (!this.selectedTip && this.selectedTip !== 0) {
            this.tipValue = null;
        } else {
            let tmpTip = this.tipValue;
            if (this.selectedTip !== 'custom') {
                tmpTip = this.selectedTip.value;
            }

            if (!!this.max && tmpTip > this.max) {
                this.selectedTip = 'custom';
                this.tipValue = this.max;
            } else if (tmpTip !== this.tipValue) {
                this.tipValue = tmpTip;
            }
        }

        this.tipChange.emit(this.tipValue);
    }

    calculateDefaults() {
        this.defaults = [];
        const percents = !!this.organization && !!this.organization.tipDefinitions && this.organization.tipDefinitions.length > 0
            ? this.organization.tipDefinitions.split(',')
            : [0, 20, 25];

        percents.forEach(p => {
            this.defaults.push({percent: p, value: multiplyCurrency(this.tabTotal, p / 100)});
        });
    }

    async clickCustom() {
        const modal = await this.modalController.create({
            component: CustomDollarAmountComponent,
            componentProps: {
                amount: '',
                max: this.max,
                label: 'Tip'
            },
            breakpoints: [0.0, 1],
            initialBreakpoint: 1,
            cssClass: 'quick-input-modal'
        });
        await modal.present();

        modal.onDidDismiss().then(async (returnData) => {
            if (!!returnData && (!!returnData.data || returnData.data === 0)) {
                this.tipValue = Number.parseFloat(returnData.data);
                this.tipChange.emit(this.tipValue);
            }
        });
    }

    changedSelected() {
        if (this.selectedTip !== 'custom') {
            this.calculateTip();
        }
    }

    manualEntry() {
        this.selectedTip = null;
    }
}
