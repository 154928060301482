import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {formatDate} from '@angular/common';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import moment from 'moment';

@Component({
    selector: 'bb-date-input-form',
    templateUrl: './date-input-form.component.html',
    styleUrls: ['./date-input-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateInputFormComponent),
            multi: true,
        },
    ]
})
export class DateInputFormComponent implements OnInit, OnChanges {
    @Input() label: string;
    @Input() id: string;
    @Input() min: string;
    @Input() max: string;
    @Input() format = 'MM/dd/YYYY hh:mm aa';
    @Input() hideIcon = false;
    @Input() presentation = 'date-time';
    @Output() dateChange = new EventEmitter();

    field: string;
    formattedField: string;
    formattedMax: string;
    formattedMin: string;

    initialized = false;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes.max) {
            this.formattedMax = this.initializeDate(this.max);
        }
        if (!!changes.min) {
            this.formattedMin = this.initializeDate(this.min);
        }
    }

    setDefault() {
        this.field = !!this.field ? this.field : moment().format();
    }

    writeValue(obj: any): void {
        if (!this.initialized) {
            this.field = this.initializeDate(obj);
            this.initialized = true;
        } else {
            this.field = this.convert(obj);
        }
        this.formattedField = this.formatDate(this.field);
    }

    onChange = (value: any) => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    initializeDate(obj) {
        return !!obj ? moment(this.convert(obj)).local().format() : undefined;
    }

    convert(obj) {
        let val = obj;
        if (!!val) {
            if (val.includes('.')) {
                const s = val.split('.');
                let index = s[1].indexOf('-');
                if (index === -1) {
                    index = s[1].indexOf('+');
                }
                if (index === -1) {
                    index = s[1].indexOf('Z');
                }
                val = s[0] + s[1].substring(index);
            }
        }
        return val;
    }

    formatDate(val: string) {
        if (!!val) {
            return formatDate(val, this.format, 'en-us', null);
        } else {
            return '';
        }
    }

    fireDateChange(event) {
        const value = event.detail.value;
        if (value !== this.field) {
            this.dateChange.emit(event.detail.value);
        }
    }
}
