import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {MembershipProgramService} from '../_services/membership-program.service';

@Injectable()
export class OrganizationMembershipProgramsResolver implements Resolve<any> {
    constructor(private membershipProgramService: MembershipProgramService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.membershipProgramService.findByOrganizationId(route.paramMap.get('orgId'));
    }
}
