import {BaseFormModel} from './base-form-model';
import {MenuItemSelection} from './menu-item-selection';
import {OrderItemSelectionItem} from './order-item-selection-item';
import {MenuSelectionPrice} from './menu-selection-price';
import {addCurrency} from '../_utils/currency-math';

export class OrderItemSelection extends BaseFormModel<OrderItemSelection> {
    id: number;
    menuItemSelection: MenuItemSelection;
    selectedItems: OrderItemSelectionItem[];

    public constructor(jsonInput: any) {
        super(jsonInput);
        Object.assign(this, jsonInput);
        const selectedItems = [];
        if (this.selectedItems) {
            this.selectedItems.forEach(sel => {
                selectedItems.push(new OrderItemSelectionItem(sel));
            });
        }
        this.selectedItems = selectedItems;
    }

    countTotal(): number {
        let total = 0;
        let currentPrice: MenuSelectionPrice;
        let count = 1;
        this.selectedItems.forEach(item => {
            if (!!item.selectionCountPrice || item.selectionCountPrice === 0) {
                total += item.selectionCountPrice;
            } else if (this.menuItemSelection.menuSelection.prices
                && this.menuItemSelection.menuSelection.prices.length > 0) {
                if (!currentPrice || (currentPrice.countMax <= count)) {
                    currentPrice = this.menuItemSelection.menuSelection.prices.filter(price => {
                        return price.countMin <= count && (!price.countMax || price.countMax >= count);
                    })[0];
                    total = addCurrency(total, currentPrice.price);
                }
            }
            count++;
        });
        return total;
    }

    calculateTotal(parentAmount: number): number {
        let total = this.countTotal();
        this.selectedItems.forEach(item => {
            total = addCurrency(total, item.total(parentAmount));
        });
        return total;
    }

    calculateDeposit() {
        let total = 0;
        this.selectedItems.forEach(item => {
            total = addCurrency(total, item.depositTotal());
        });
        return total;
    }
}
