import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {MenuItemSelection} from '../_models/menu-item-selection';

@Injectable({
    providedIn: 'root'
})
export class MenuItemSelectionService extends BaseService<MenuItemSelection> {
    destination = 'menu-item-selections';
}
