<modal-header title="{{!!organization.achMaskedAccountNumber ? 'Connect New Bank Account' : 'Connect Bank Account'}}"></modal-header>

<ion-content>
    <form [formGroup]="connectBankAccountForm" (ngSubmit)="onSubmit()">
        <ion-grid>
            <ion-row>
                <ion-col size-xs="12">
                    <ion-item>
                        <ion-label position="floating">Account Holder First Name</ion-label>
                        <ion-input type="text" name="firstName" formControlName="firstName"></ion-input>
                    </ion-item>
                </ion-col>
                <ion-col size-xs="12">
                    <ion-item>
                        <ion-label position="floating">Account Holder Last Name</ion-label>
                        <ion-input type="text" name="lastName" formControlName="lastName"></ion-input>
                    </ion-item>
                </ion-col>
                <ion-col size-xs="12">
                    <ion-item>
                        <ion-label position="floating">Account Type</ion-label>
                        <ion-select name="accountType" formControlName="accountType" interface="popover">
                            <ion-select-option value="Checking">Checking Account</ion-select-option>
                            <ion-select-option value="Savings">Savings Account</ion-select-option>
                        </ion-select>
                    </ion-item>
                </ion-col>
                <ion-col size-xs="12">
                    <ion-item>
                        <ion-label position="floating">Account Number</ion-label>
                        <ion-input type="text" name="accountNumber" formControlName="accountNumber"></ion-input>
                    </ion-item>
                </ion-col>
                <ion-col size-xs="12">
                    <ion-item>
                        <ion-label position="floating">Routing Number</ion-label>
                        <ion-input type="text" name="routingNumber" formControlName="routingNumber"></ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>

<modal-footer [disableSubmit]="connectBankAccountForm.invalid" [submitLabel]="'Connect'" (submitClicked)="onSubmit()"></modal-footer>
