export class FeatureKeys {
    public static SUPPORT = 'SUPPORT';
    public static ORG_ADMIN = 'ORG_ADMIN';
    public static ORG_OWNER = 'ORG_OWNER';
    public static CARD_SUMMARY = 'CARD_SUMMARY';
    public static CARD_SUMMARY_DELETE = 'CARD_SUMMARY_DELETE';
    public static CARD_READER_ASSIGN = 'CARD_READER_ASSIGN';
    public static CASH_DRAWER_ADMIN = 'CASH_DRAWER_ADMIN';
    public static CASH_DRAWER_ASSIGN = 'CASH_DRAWER_ASSIGN';
    public static CASH_DRAWER_RUNNING_TOTAL = 'CASH_DRAWER_RUNNING_TOTAL';
    public static CASH_SESSION_ADMIN = 'CASH_SESSION_ADMIN';
    public static CASH_SESSION_COMPLETE = 'CASH_SESSION_COMPLETE';
    public static CASH_SESSION_START = 'CASH_SESSION_START';
    public static COMP_DEFINITION_ADMIN = 'COMP_DEFINITION_ADMIN';
    public static CREDIT_APPLY = 'CREDIT_APPLY';
    public static CREDIT_CARD_REFUND = 'CREDIT_CARD_REFUND';
    public static CUSTOMER_ADMIN = 'CUSTOMER_ADMIN';
    public static CUSTOMER_AREA_ADMIN = 'CUSTOMER_AREA_ADMIN';
    public static DASHBOARD = 'DASHBOARD';
    public static DASHBOARD_ADMIN = 'DASHBOARD_ADMIN';
    public static DEPOSITS_ADMIN = 'DEPOSITS_ADMIN';
    public static GIFT_CARD = 'GIFT_CARD';
    public static GIFT_CARD_ADMIN = 'GIFT_CARD_ADMIN';
    public static GIFT_CARD_LOAD = 'GIFT_CARD_LOAD';
    public static INVOICE_CREATE = 'INVOICE_CREATE';
    public static INVENTORY_ADMIN = 'INVENTORY_ADMIN';
    public static MENU_ADMIN = 'MENU_ADMIN';
    public static MEMBERSHIP_ADMIN = 'MEMBERSHIP_ADMIN';
    public static ORGANIZATION_EVENT_ADMIN = 'ORGANIZATION_EVENT_ADMIN';
    public static ORGANIZATION_EVENT_STATUS = 'ORGANIZATION_EVENT_STATUS';
    public static ORDER_CANCEL = 'ORDER_CANCEL';
    public static ORDER_MOVE = 'ORDER_MOVE';
    public static PICKUP_LOCATION_ADMIN = 'PICKUP_LOCATION_ADMIN';
    public static PICKUP_LOCATION_MENU = 'PICKUP_LOCATION_MENU';
    public static PICKUP_LOCATION_STATUS = 'PICKUP_LOCATION_STATUS';
    public static PRINTER_ASSIGN = 'PRINTER_ASSIGN';
    public static REFUND_PAYMENT = 'REFUND_PAYMENT';
    public static REPORT = 'REPORT';
    public static REPORT_ADMIN = 'REPORT_ADMIN';
    public static REWARDS_ADMIN = 'REWARDS_ADMIN';
    public static SAFE_ADMIN = 'SAFE_ADMIN';
    public static SAFE_TRANSFER = 'SAFE_TRANSFER';
    public static TAB_NOTE_DEFINITION_ADMIN = 'TAB_NOTE_DEFINITION_ADMIN';
    public static TAX_EXEMPT_APPLY = 'TAX_EXEMPT_APPLY';
    public static TERMINAL = 'TERMINAL';
    public static TIMECARD = 'TIMECARD';
    public static TIMECARD_ADMIN = 'TIMECARD_ADMIN';
    public static TIMECARD_TIPPED = 'TIMECARD_TIPPED';
    public static USER_ADMIN = 'USER_ADMIN';
    public static USER_CREDIT_ADMIN = 'USER_CREDIT_ADMIN';

    public static DASHBOARD_FEATURE_KEYS = [
        FeatureKeys.DASHBOARD,
        FeatureKeys.DASHBOARD_ADMIN
    ];

    public static CASH_SESSION_VIEW_KEYS = [
        FeatureKeys.SAFE_ADMIN,
        FeatureKeys.CASH_SESSION_ADMIN,
        FeatureKeys.CASH_SESSION_START,
        FeatureKeys.CASH_SESSION_COMPLETE
    ];

    public static CASH_SESSION_START_KEYS = [
        FeatureKeys.SAFE_ADMIN,
        FeatureKeys.CASH_SESSION_ADMIN,
        FeatureKeys.CASH_SESSION_START
    ];

    public static CASH_SESSION_COMPLETE_KEYS = [
        FeatureKeys.SAFE_ADMIN,
        FeatureKeys.CASH_SESSION_ADMIN,
        FeatureKeys.CASH_SESSION_COMPLETE
    ];

    public static CASH_DRAWER_COMPLETE_KEYS = [
        ...FeatureKeys.CASH_SESSION_COMPLETE_KEYS,
        FeatureKeys.CASH_DRAWER_ADMIN
    ];

    public static CASH_DRAWER_ASSIGN_KEYS = [
        ...FeatureKeys.CASH_DRAWER_COMPLETE_KEYS,
        FeatureKeys.CASH_DRAWER_ASSIGN
    ];

    public static LOCATION_FEATURE_KEYS = [
        FeatureKeys.PICKUP_LOCATION_ADMIN,
        FeatureKeys.PICKUP_LOCATION_MENU,
        FeatureKeys.CUSTOMER_AREA_ADMIN,
        FeatureKeys.ORGANIZATION_EVENT_ADMIN
    ];

    public static CONFIGURATION_FEATURE_KEYS = [
        ...FeatureKeys.LOCATION_FEATURE_KEYS,
        FeatureKeys.MENU_ADMIN,
        FeatureKeys.REWARDS_ADMIN,
        FeatureKeys.USER_ADMIN,
        FeatureKeys.COMP_DEFINITION_ADMIN,
        FeatureKeys.TAB_NOTE_DEFINITION_ADMIN
    ];

    public static PROGRESSIVE_TIP_KEYS = [
        ...FeatureKeys.CASH_SESSION_COMPLETE_KEYS,
        FeatureKeys.TIMECARD_TIPPED
    ];

    public static REPORT_FEATURE_KEYS = [
        FeatureKeys.REPORT_ADMIN,
        FeatureKeys.REPORT
    ];

    public static TIMECARD_FEATURE_KEYS = [
        FeatureKeys.TIMECARD,
        FeatureKeys.TIMECARD_ADMIN
    ];

    public static GIFT_CARD_FEATURE_KEYS = [
        FeatureKeys.GIFT_CARD,
        FeatureKeys.GIFT_CARD_ADMIN,
        FeatureKeys.GIFT_CARD_LOAD
    ];

    public static OPERATIONS_FEATURE_KEYS = [
        ...FeatureKeys.CASH_SESSION_VIEW_KEYS,
        ...FeatureKeys.TIMECARD_FEATURE_KEYS,
        ...FeatureKeys.GIFT_CARD_ADMIN,
        FeatureKeys.INVENTORY_ADMIN,
        FeatureKeys.DEPOSITS_ADMIN
    ];

    public static NON_TERMINAL_FEATURE_KEYS = [
        ...this.OPERATIONS_FEATURE_KEYS,
        ...this.CONFIGURATION_FEATURE_KEYS,
        ...this.REPORT_FEATURE_KEYS,
        ...this.DASHBOARD_FEATURE_KEYS
    ];
}




