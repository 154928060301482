import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastService} from '../../_services/toast.service';
import {LoadingService} from '../../_services/loading.service';
import {CurrencyPipe} from '@angular/common';
import {ModalController} from '@ionic/angular';
import {PaymentCharge} from '../../_models/payment-charge';
import {PaymentChargeRefundService} from '../../_services/payment-charge-refund.service';
import {PaymentChargeRefund} from '../../_models/payment-charge-refund';
import {addCurrency, subtractCurrency} from '../../_utils/currency-math';
import {OrganizationTerminalService} from '../../_services/organization-terminal.service';
import {OrganizationTerminal} from '../../_models/organization-terminal';

@Component({
    selector: 'bb-payment-charge-refund',
    templateUrl: './payment-charge-refund.component.html',
    styleUrls: ['./payment-charge-refund.component.scss']
})
export class PaymentChargeRefundComponent extends BaseModalComponent implements OnInit {
    @Input() paymentCharge: PaymentCharge;
    formGroup: FormGroup;
    available: number;
    availableTip: number;
    terminal: OrganizationTerminal;

    constructor(
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private currencyPipe: CurrencyPipe,
        private paymentChargeRefundService: PaymentChargeRefundService,
        private organizationTerminalService: OrganizationTerminalService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.subscribe(this.organizationTerminalService.current.subscribe(r => this.terminal = r));
        this.available = subtractCurrency(this.paymentCharge.principal, this.paymentCharge.totalPrincipalRefunds());
        this.availableTip = subtractCurrency(this.paymentCharge.tip, this.paymentCharge.totalTipRefunds());

        this.formGroup = this.formBuilder.group({
            principal: [
                this.available,
                [Validators.required, Validators.max(this.available), Validators.min(0)]
            ],
            tip: [
                this.availableTip,
                [Validators.required, Validators.max(this.availableTip), Validators.min(0)]
            ],
            comment: ['', Validators.maxLength(2000)],
            parentId: this.paymentCharge.id,
            creditTab: '',
            creditType: ''
        });
    }

    creditChanged() {
        if (this.formGroup.get('creditTab').value === 'REFUND_AND_COMP') {
            if (!this.formGroup.get('creditType').value) {
                this.formGroup.get('creditType').setValue('REWARD');
            }
            this.formGroup.get('creditType').setValidators([Validators.required]);
        } else {
            this.formGroup.get('creditType').setValidators([]);
        }
        this.formGroup.controls.creditType.updateValueAndValidity();
    }

    async onSubmit() {
        this.loadingService.present(!!this.paymentCharge.registerId ? `Waiting on terminal.` : null);
        const paymentChargeRefund = new PaymentChargeRefund(this.formGroup.value);
        paymentChargeRefund.paymentCharge = this.paymentCharge;
        paymentChargeRefund.creditTab = this.formGroup.controls.creditTab.value === 'REFUND_AND_COMP';
        if (!paymentChargeRefund.creditTab) {
            paymentChargeRefund.creditType = null;
        }

        if (!!this.terminal) {
            paymentChargeRefund.terminalId = this.terminal.terminalId;
        }

        this.paymentChargeRefundService.refund(paymentChargeRefund).subscribe(async (refund: PaymentChargeRefund) => {
            let message;
            if (!!refund) {
                const total = addCurrency(refund.principal, refund.tip);

                if (refund.pending) {
                    message = `A refund of ${this.currencyPipe.transform(total)} is pending. It will be applied in the next 2 business days.`;
                    if (refund.creditTab) {
                        message += ` The tab has been credited ${this.currencyPipe.transform(total)}.`;
                    }
                } else {
                    message = `A refund of ${this.currencyPipe.transform(total)} has been applied${refund.creditTab
                        ? ' and credited'
                        : ''}.`;
                }
            } else {
                message = 'The transaction has been fully refunded and voided.';
            }

            await this.toastService.success(message);

            await this.close(true);
            this.loadingService.dismiss();
        }, (error) => {
            this.paymentChargeRefundService.errorHandler(error);
        });
    }

    maxRefund() {
        this.formGroup.controls.principal.setValue(this.available);
    }

    maxTip() {
        this.formGroup.controls.tip.setValue(this.availableTip);
    }
}
