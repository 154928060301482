<modal-header title="Connect Card Reader"></modal-header>

<ion-content>
    <ion-grid class="ion-padding-start ion-padding-end" [formGroup]="formGroup">
        <ion-row>
            <ion-col size-xs="12">
                <strong>Getting the Device ID</strong>
                <ul>
                    <li>From the home screen of the Genius device, use the keypad to press <strong>000</strong>.</li>
                    <li>Type the password and then tap OK. The default password is <strong>9416557</strong>.</li>
                    <li>Get the device ID from the <strong>Cloud Device ID</strong> field.</li>
                </ul>
            </ion-col>
            <ion-col size-xs="12">
                <ion-item>
                    <div class="label-left" slot="start">cedevice::</div>
                    <ion-label position="floating">Device ID</ion-label>
                    <ion-input data-cy="terminal-id-input" name="terminalId" type="text" formControlName="terminalId"
                               required #firstInput></ion-input>
                </ion-item>
            </ion-col>
            <ion-col>
                <ion-item>
                    <ion-label position="floating">Nickname</ion-label>
                    <ion-input data-cy="nickname-input" name="nickname" type="text" formControlName="nickname"
                               required></ion-input>
                </ion-item>
            </ion-col>
            <ion-col>
                <ion-item>
                    <ion-label position="stacked">Prompt For Tip From Card Reader</ion-label>
                    <ion-toggle class="ion-toggle-small" formControlName="promptTipOnReader"></ion-toggle>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

<modal-footer [disableSubmit]="formGroup.invalid" (submitClicked)="onSubmit()"></modal-footer>
