import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {SubscriberComponent} from '../subscriber.component';
import {TabReport} from '../../_models/report/tab-report';
import {Column} from '../../_models/report/column';
import {formatDayOfWeek, formatHour, formatMonth, formattedMilli} from '../../_utils/date-utils';
import {CurrencyPipe, DatePipe, PercentPipe} from '@angular/common';
import {ColumnFormats} from '../../_constants/column-formats';
import {UnderscoreConversionPipe} from '../_pipes/underscore-conversion.pipe';
import {DashboardWidget} from '../../_models/dashboard-widget';

@Component({
    template: ''
})
export abstract class BaseWidgetComponent extends SubscriberComponent implements OnChanges {
    @Input() widget: DashboardWidget;
    @Input() tabReport: TabReport;
    @Input() startDate: string;
    @Input() endDate: string;
    @Input() selectedCategories: number[];
    @Input() selectedPickupLocations: number[];

    @Output() dataLoading = new EventEmitter();
    @Output() dataReady = new EventEmitter();
    loading = true;
    error = false;

    abstract refresh();

    constructor(
        protected currencyPipe: CurrencyPipe,
        protected percentPipe: PercentPipe,
        protected datePipe: DatePipe,
        protected underscoreConversionPipe: UnderscoreConversionPipe
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.refresh();
    }

    getAggregateSummaryLabel(column: Column) {
        switch (column.summaryAggregation) {
        case 'SUM':
            return 'Total';
        case 'AVG':
            return 'Average';
        case 'MAX':
            return 'Max';
        case 'MIN':
            return 'Min';
        }
        return '';
    }

    getRowValue(row: any, column: Column) {
        return this.formatValue(row.data[column.accessKey], column.format);
    }

    formatValue(value, format) {
        switch (format) {
        case ColumnFormats.CURRENCY:
            return this.currencyPipe.transform(this.getNumeric(value));
        case ColumnFormats.PERCENT:
            return this.percentPipe.transform(value);
        case ColumnFormats.DAY_OF_WEEK:
            return formatDayOfWeek(value);
        case ColumnFormats.HOUR:
            return formatHour(value);
        case ColumnFormats.MONTH:
            return formatMonth(value);
        case ColumnFormats.DURATION:
            return formattedMilli(value);
        case ColumnFormats.DATE:
            return this.datePipe.transform(value, 'shortDate');
        case ColumnFormats.DATE_TIME:
            return this.datePipe.transform(value, 'short');
        case ColumnFormats.NUMBER:
        case ColumnFormats.ID:
            return this.getNumeric(value);
        case ColumnFormats.UNDERSCORE:
            return this.underscoreConversionPipe.transform(value);
        }
        return value;
    }

    getNumeric(value: string) {
        return !!value ? this.round(value) : 0;
    }

    round(num) {
        const m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    }
}
