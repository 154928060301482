import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {BaseModalComponent} from '../base-modal.component';
import {LoadingService} from '../../_services/loading.service';
import {Tab} from '../../_models/tab';
import {ToastService} from '../../_services/toast.service';
import {PickupLocation} from '../../_models/pickup-location';
import moment from 'moment';
import {TabStatuses} from '../../_constants/tab-statuses';
import {addCurrency} from '../../_utils/currency-math';
import {TabSearchResult} from '../../_models/tab-search-result';
import {OrganizationService} from '../../_services/organization.service';
import {Person} from '../../_models/person';
import {UserService} from '../../_services/user.service';
import {User} from '../../_models/user';
import {TabService} from '../../_services/tab.service';

@Component({
    selector: 'bb-tip-summary',
    templateUrl: './tip-summary.component.html',
    styleUrls: ['./tip-summary.component.scss']
})
export class TipSummaryComponent extends BaseModalComponent implements OnInit {
    @Input() pickupLocation: PickupLocation;
    startDate: string;
    endDate: string;
    today: string;
    tabs: TabSearchResult[];
    tabTotal: number;
    tipTotal: number;
    errorMessage: string;
    selectedServer: Person;
    serverOptions: Person[];

    constructor(
        private organizationService: OrganizationService,
        private tabService: TabService,
        private userService: UserService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        modalController: ModalController
    ) {
        super(modalController);
        const now = moment();
        this.today = now.format();
        this.endDate = now.format();

        now.subtract(8, 'hours');
        this.startDate = now.format();
    }

    ngOnInit(): void {
        const none = new Person();
        none.id = -1;
        none.name = 'None';
        this.userService.getOrgUsers(this.pickupLocation.orgId).subscribe((u: User[]) => {
            this.serverOptions = u.reduce((acc, i) => {
                acc.push(i.person);
                return acc;
            }, [none]);
        });

        this.loadTabs();
    }

    changeStartDate() {
        const end = moment(this.startDate);
        end.add(12, 'hours');
        this.endDate = end.format();
        this.loadTabs();
    }

    loadTabs() {
        this.tabs = [];
        const start = new Date(this.startDate);
        const end = new Date(this.endDate);

        if (start > end) {
            this.errorMessage = 'Start date must be before end date.';
        } else if (((end.getTime() - start.getTime()) / 1000) > (24 * 60 * 60)) {
            this.errorMessage = 'Date range exceeds 24 hours.';
        } else {
            this.errorMessage = null;
            this.loadingService.present();
            if (!this.selectedServer) {
                this.organizationService.getDateRangeTabs(this.pickupLocation.orgId, 0, 999999999, this.startDate, this.endDate)
                    .subscribe((page: any) => {
                        const tabList = [];
                        this.tabTotal = 0;
                        this.tipTotal = 0;
                        if (!!page.content) {
                            page.content.forEach(t => {
                                const tab = new Tab(t);
                                if (tab.status === TabStatuses.CLOSED && tab.subtotal > 0) {
                                    this.tabTotal = addCurrency(this.tabTotal, t.subtotal);
                                    this.tipTotal = addCurrency(this.tipTotal, t.tip);
                                    tabList.push(tab);
                                }
                            });
                        }
                        this.tabs = tabList;
                    }, () => {
                        this.toastService.error('Could not retrieve tabs.');
                    }, () => {
                        this.loadingService.dismiss();
                    });
            } else {
                this.tabService.getServerDateRange(this.pickupLocation.orgId,
                    this.selectedServer.id, 0, 999999999, this.startDate, this.endDate)
                    .subscribe((page: any) => {
                        const tabList = [];
                        this.tabTotal = 0;
                        this.tipTotal = 0;
                        if (!!page.content) {
                            page.content.forEach(t => {
                                const tab = new Tab(t);
                                if (tab.status === TabStatuses.CLOSED && tab.subtotal > 0) {
                                    this.tabTotal = addCurrency(this.tabTotal, t.subtotal);
                                    this.tipTotal = addCurrency(this.tipTotal, t.tip);
                                    tabList.push(tab);
                                }
                            });
                        }
                        this.tabs = tabList;
                    }, () => {
                        this.toastService.error('Could not retrieve tabs.');
                    }, () => {
                        this.loadingService.dismiss();
                    });
            }
        }
    }
}
