import {Pipe, PipeTransform} from '@angular/core';
import {TabStatuses} from '../../_constants/tab-statuses';

@Pipe({
    name: 'tabStatus'
})
export class TabStatusPipe implements PipeTransform {

    transform(value: string, lowercase: boolean = false): string {
        let statusString = '';
        switch (value) {
        case TabStatuses.OPEN:
            statusString = 'Open';
            break;
        case TabStatuses.CLOSED:
            statusString = 'Closed';
            break;
        case TabStatuses.VACATED:
            statusString = 'Vacated';
            break;
        case TabStatuses.DELETED:
            statusString = 'Deleted';
            break;
        default:
            statusString = 'Your Tab';
            break;
        }

        return lowercase ? statusString.toLowerCase() : statusString;
    }
}
