<ion-grid *ngIf="user">
    <ion-row>
        <ion-col size="10">
            <ion-card>
                <ion-card-header>
                    <ion-card-title>
                        {{user.person.name}}
                    </ion-card-title>
                    <ion-card-subtitle>
                        <p>{{user.person.email}}</p>
                        <p *ngIf="!user.id">Non-user</p>
                    </ion-card-subtitle>
                </ion-card-header>
            </ion-card>
        </ion-col>

        <ion-col size-xs="2" class="page-header ion-text-right">
            <ion-button color="dark" (click)="close()">
                <ion-icon slot="icon-only" name="close-outline"></ion-icon>
            </ion-button>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col size-xs="12" size-lg="6">
            <ion-card>
                <ion-card-content>
                    <div class="donut-container">
                        <p-chart type="doughnut" [data]="summaryChart"></p-chart>
                        <div class="donut-middle">
                            <div><p>Total:</p>{{getTotal() | currency}}</div>
                        </div>
                    </div>
                </ion-card-content>
            </ion-card>
        </ion-col>

        <ion-col *ngIf="!!credits" size-xs="12" size-lg="6">
            <ion-card>
                <ion-list>
                    <ion-item lines="none">
                        <ion-icon slot="start" name="cash-outline"></ion-icon>
                        <ion-label>
                            <h2>Credits</h2>
                        </ion-label>
                        <ion-icon (click)="openAddUserCreditModal()" slot="end" name="add-outline"></ion-icon>
                    </ion-item>
                    <ion-item *ngIf="credits.length === 0" lines="none">
                        <ion-label>There are no credits for this user.</ion-label>
                    </ion-item>

                    <ion-item *ngFor="let credit of credits" lines="none">
                        <ion-note slot="start" *ngIf="credit.rewardType !== 'ITEM'"
                                  [class]="credit.remaining === 0 ? 'used' : ''">{{credit.amount | currency}}</ion-note>
                        <ion-note slot="start" *ngIf="credit.rewardType === 'ITEM'">
                            {{credit.amount}}<p class="reward-label">{{credit.rewardName}}</p>
                        </ion-note>
                        <ion-label>
                            <h2>{{credit.type | creditType}}</h2>
                            <p *ngIf="!!credit.createdBy">Created By: {{credit.createdBy}}</p>
                            <p>{{credit.creditDate | date}}</p>
                        </ion-label>
                        <span slot="end" *ngIf="credit.rewardType !== 'ITEM'" class="amount"
                              [class]="credit.remaining > 0 ? 'remaining' : ''">{{credit.remaining | currency}}</span>
                        <span slot="end" *ngIf="credit.rewardType === 'ITEM'" class="amount"
                              [class]="credit.remaining > 0 ? 'remaining' : ''">{{credit.remaining}}</span>
                    </ion-item>
                </ion-list>
            </ion-card>
        </ion-col>

        <ion-col *ngFor="let tab of tabs" size-xs="12" size-lg="6">
            <ion-card (click)="tabDetails(tab)">
                <ion-item lines="none" [color]="tab.status == tabStatuses.OPEN ? 'success':''">
                    <ion-label>
                        <h2 class="tab-header">{{tab.startTime | date}}</h2>
                        <span class="pill-success" *ngIf="tab.paymentInformation.paymentStatus === paymentStatuses.PAID">
                Paid
            </span>
                    </ion-label>
                    <bb-rating *ngIf="tab.rating" [rating]="tab.rating.rating" size="medium"></bb-rating>
                </ion-item>
                <ion-grid>
                    <ion-list>
                        <ion-item lines="none">
                            <ion-grid>
                                <ion-row>
                                    <ion-col size="12" class="ion-text-end">
                                        Sub Total: <span class="currency-item">{{tab.subtotal | currency}}</span>
                                    </ion-col>
                                </ion-row>

                                <ion-row *ngFor="let tax of tab.taxes">
                                    <ion-col size="12" class="ion-text-end">
                                        {{tax.name}}({{tax.taxRate}}%): <span class="currency-item">{{tax.tax | currency}}</span>
                                    </ion-col>
                                </ion-row>

                                <ion-row *ngIf="!!tab.creditsTotal && tab.creditsTotal > 0">
                                    <ion-col size="12" class="ion-text-end">
                                        Credits: <span class="currency-item">{{tab.creditsTotal * -1 | currency}}</span>
                                    </ion-col>
                                </ion-row>

                                <ion-row>
                                    <ion-col size="12" class="ion-text-end">
                                        Fee: <span class="currency-item">{{tab.totalFee | currency}}</span>
                                    </ion-col>
                                </ion-row>

                                <ion-row *ngIf="!!tab.paymentInformation.tips && tab.paymentInformation.tips > 0">
                                    <ion-col size="12" class="ion-text-end">
                                        Tip: <span class="currency-item">{{tab.paymentInformation.tips | currency}}</span>
                                    </ion-col>
                                </ion-row>

                                <ion-row>
                                    <ion-col size="12" class="ion-text-end">
                                        Total: <span class="currency-item">{{tab.paymentInformation.total | currency}}</span>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </ion-item>
                    </ion-list>
                </ion-grid>
            </ion-card>
        </ion-col>
    </ion-row>
</ion-grid>
