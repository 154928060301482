import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {IonicModule} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {GoogleMapsModule} from '@angular/google-maps';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrewBillLibModule, DateAgoPipe, MaterialModule, OrderStatusColorPipe, OrderStatusPipe} from 'brewbill-lib';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../../../../environments/environment';
import {Vibration} from '@ionic-native/vibration/ngx';
import {SwiperModule} from 'swiper/angular';
import {ServiceWorkerModule} from '@angular/service-worker';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getMessaging, provideMessaging} from '@angular/fire/messaging';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideMessaging(() => getMessaging()),
        BrowserModule,
        GoogleMapsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        BrowserAnimationsModule,
        MaterialModule,
        BrewBillLibModule.forRoot({
            terminal: false,
            testMode: environment.testMode,
            appVersion: environment.version,
            apiUrl: environment.apiUrl
        }),
        SwiperModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        DateAgoPipe,
        OrderStatusPipe,
        OrderStatusColorPipe,
        Vibration
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
