import {BaseFormModel} from '../base-form-model';
import {Person} from '../person';
import {CashAudit} from './cash-audit';
import {PickupLocation} from '../pickup-location';
import {CashDrawer} from './cash-drawer';
import {CashSessionStatuses} from '../../_constants/cash-session-statuses';
import {CashDrawerStatuses} from '../../_constants/cash-drawer-statuses';
import {CashSessionEmployee} from './cash-session-employee';
import {CashSessionTipAdjustment} from './cash-session-tip-adjustment';
import {CashSessionNote} from './cash-session-note';

export class CashSession extends BaseFormModel<CashSession> {
    id: number;
    orgId: number;
    startedBy: Person;
    startTime: Date;
    pendedBy: Person;
    pendedTime: Date;
    completedBy: Person;
    completedTime: Date;
    status: string;
    cashAudits: CashAudit[];
    pickupLocations: PickupLocation[];
    cashDrawers: CashDrawer[];
    employees: CashSessionEmployee[];
    tipAdjustments: CashSessionTipAdjustment[];
    originId: number;
    notes: CashSessionNote[];

    canAcceptPayments() {
        return this.status === CashSessionStatuses.ACTIVE
            && !!this.cashDrawers
            && this.cashDrawers.some(d => d.status === CashDrawerStatuses.ACTIVE);
    }
}
