import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../_models/user';

@Component({
    selector: 'bb-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
    @Input() user: User;

    constructor() {
    }

    ngOnInit() {
    }

}
