import {Component, Input} from '@angular/core';

@Component({
    selector: 'bb-tokens',
    templateUrl: './tokens.component.html',
    styleUrls: ['./tokens.component.scss'],
})
export class TokensComponent {
    @Input() count: number;
    @Input() canceled = false;
}
