import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Visit} from '../../_models/visit';
import {formatDuration, formattedTimeDifference} from '../../_utils/date-utils';
import moment from 'moment/moment';
import {OrderStatuses} from '../../_constants/order-statuses';

@Component({
    selector: 'bb-visit',
    templateUrl: './visit.component.html',
    styleUrls: ['./visit.component.css'],
})
export class VisitComponent implements OnInit, OnChanges {
    @Input() visit: Visit;
    @Input() orderId: number;
    events = [];
    waits = [];

    constructor() {
    }

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.visit.previousValue) {
            this.init();
        }
    }

    init() {
        const startTime = new Date(this.visit.startTime);
        const startTimeMilli = startTime.getTime();
        this.events = [];
        this.visit.events.forEach(e => {
            if (!this.orderId || this.orderId === e.orderId) {
                const date = new Date(e.date);
                this.events.push({
                    status: e.status,
                    time: formattedTimeDifference(date, startTime),
                    position: ((date.getTime() - startTimeMilli) / this.visit.cachedMilli) * 100
                });
            }
        });

        this.waits = [];
        this.visit.waits.forEach(w => {
            const start = new Date(w.startTime);
            const end = new Date(!!w.endTime ? w.endTime : w.startTime);
            this.waits.push({
                time: formattedTimeDifference(end, start),
                position: ((start.getTime() - startTimeMilli) / this.visit.cachedMilli) * 100,
                width: ((end.getTime() - start.getTime()) / this.visit.cachedMilli) * 100
            });
        });
    }

    getBorder(e) {
        // hardcoding this for PoC
        switch (e.status) {
        case OrderStatuses.PENDING:
            return '2px solid #d9d9d9';
        case OrderStatuses.RECEIVED:
            return '2px solid #52e5ff';
        case OrderStatuses.PREPARING:
            return '2px solid #9e3dff';
        case OrderStatuses.READY:
            return '2px solid #fddb31';
        case OrderStatuses.DELIVERED:
            return '2px solid #2dd36f';
        case OrderStatuses.CANCELED:
            return '2px solid #ff0000';
        }
    }

    getBackgroundColor(e) {
        // hardcoding this for PoC
        switch (e.status) {
        case OrderStatuses.PENDING:
            return '#ffffff';
        case OrderStatuses.RECEIVED:
            return '#52e5ff';
        case OrderStatuses.PREPARING:
            return '#9e3dff';
        case OrderStatuses.READY:
            return '#fddb31';
        case OrderStatuses.DELIVERED:
            return '#2dd36f';
        case OrderStatuses.CANCELED:
            return '#ff0000';
        }
    }

    getColor(e) {
        // hardcoding this for PoC
        switch (e.status) {
        case OrderStatuses.CANCELED:
        case OrderStatuses.PREPARING:
        case OrderStatuses.DELIVERED:
            return '#ffffff';
        case OrderStatuses.PENDING:
        case OrderStatuses.RECEIVED:
        case OrderStatuses.READY:
            return '#000000';
        }
    }

    endTime() {
        return formatDuration(moment.duration(this.visit.cachedMilli));
    }
}
