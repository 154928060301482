import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Availabilities} from '../../_constants/availabilities';
import {MenuItemInventoryService} from '../../_services/menu-item-inventory.service';
import {OrganizationTerminalService} from '../../_services/organization-terminal.service';
import {BaseMenuItemInventoryComponent} from '../base-menu-item-inventory.component';
import {ModalController} from '@ionic/angular';
import {ImageCacheService} from '../../_services/image-cache.service';
import {PickupLocationMenuService} from '../../_services/pickup-location-menu.service';
import {MenuCategory} from '../../_models/menu-category';
import {PickupLocationMenu} from '../../_models/pickup-location-menu';

@Component({
    selector: 'bb-menu-item-image',
    templateUrl: './menu-item-image.component.html',
    styleUrls: ['./menu-item-image.component.scss'],
})
export class MenuItemImageComponent extends BaseMenuItemInventoryComponent implements OnDestroy, OnInit {
    @Input() nameOverride = 'Misc';
    @Input() imageAddressOverride: string;
    @Input() orderType: string;
    @Input() hideImage = false;
    @Input() showInventory = false;
    @Input() orderDisplay = false;
    @Input() showImageOverride = false;
    @Input() menuCategory: MenuCategory;
    @Input() color = '#ECECEC';
    @Input() contrastColor = '#000000';
    @Output() imageLoaded = new EventEmitter<any>();
    fontSize;
    togoFontSize;
    imageContainer: any;
    availabilities = Availabilities;
    pickupLocationMenu: PickupLocationMenu;

    imageSrc;

    resizeObserver: ResizeObserver;

    constructor(
        private pickupLocationMenuService: PickupLocationMenuService,
        private changeDetectorRef: ChangeDetectorRef,
        private imageCacheService: ImageCacheService,
        modalController: ModalController,
        menuItemInventoryService: MenuItemInventoryService,
        organizationTerminalService: OrganizationTerminalService
    ) {
        super(modalController, menuItemInventoryService, organizationTerminalService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.pickupLocationMenu = this.pickupLocationMenuService.currentValue;

        if (!this.showImageOverride) {
            this.hideImage = !this.pickupLocationMenuService.showImagesCurrent;
            this.subscribe(this.pickupLocationMenuService.showImages.subscribe(s => this.hideImage = !s));
        }
        this.imageSrc = this.imageCacheService.getImage(!!this.imageAddressOverride ? this.imageAddressOverride : (!!this.menuItem
            ? this.menuItem.imageAddress
            : ''));

        if (!this.menuCategory && !!this.menuItem && !!this.pickupLocationMenu
            && !!this.pickupLocationMenu.menu && !!this.pickupLocationMenu.menu.menuCategories && !!this.menuItem) {
            this.menuCategory = this.pickupLocationMenu.menu.menuCategories
                .find(c => !!c.menuItems && c.menuItems.some(i => i.menuItem.id === this.menuItem.id));
        }

        if (!!this.menuCategory) {
            this.color = !!this.menuCategory.color ? this.menuCategory.color : this.color;
            this.contrastColor = !!this.menuCategory.contrastColor ? this.menuCategory.contrastColor : this.contrastColor;
        }
    }

    ngOnDestroy(): void {
        if (!!this.resizeObserver) {
            this.resizeObserver.unobserve(this.imageContainer);
            this.resizeObserver.disconnect();
        }
        super.ngOnDestroy();
    }

    @ViewChild('imageContainer') set content(element) {
        this.imageContainer = element.nativeElement;
        if (!this.resizeObserver) {
            this.resizeObserver = new ResizeObserver(entries => {
                entries.forEach(async () => {
                    await this.calculateFontSize();
                });
            });

            this.resizeObserver.observe(this.imageContainer);
        }
    }

    calculateFontSize() {
        if (!!this.imageContainer && !!this.imageContainer && this.imageContainer.offsetWidth > 50) {
            this.fontSize = this.imageContainer.offsetWidth / 8;
            const fontSize = Math.floor(this.imageContainer.offsetWidth / 4);
            if (fontSize > 24) {
                const remainder = fontSize % 8;
                this.togoFontSize = (remainder > 4) ? fontSize + 8 - remainder : fontSize - remainder;
            } else {
                this.togoFontSize = 24;
            }

            if (!!this.menuItem && !!this.menuItem.name && this.menuItem.name.length > 35) {
                this.fontSize = Math.floor(this.fontSize * .85);
            }
            this.changeDetectorRef.detectChanges();
        }
    }
}
