import {BaseFormModel} from './base-form-model';
import {Order} from './order';
import {User} from './user';
import {Organization} from './organization';
import {Tax} from './tax';
import {PaymentCharge} from './payment-charge';
import {OrganizationRating} from './organization-rating';
import {Person} from './person';
import {CardSummary} from './card-summary';
import {OrderStatuses} from '../_constants/order-statuses';
import {TabPaymentInformation} from './tab-payment-information';
import {TabCredit} from './tab-credit';
import {CashAudit} from './cash-information/cash-audit';
import {CustomerAreaPosition} from './customer-area-position';
import {GiftCardAction} from './gift-card-action';
import {TabNote} from './tab-note';
import {calculateTax} from '../_utils/tax-utils';
import {addCurrency, divideCurrency, multiplyCurrency, subtractCurrency} from '../_utils/currency-math';
import {OrderItem} from './order-item';
import {Deposit} from './deposit';
import {MembershipProgram} from './membership-program';
import {Membership} from './membership';

export class Tab extends BaseFormModel<Tab> {
    id: number;
    orgId: number;
    orders: Order[] = [];
    taxes: Tax[] = [];
    openCharges: PaymentCharge[];
    paymentCharges: PaymentCharge[];
    tabDeposits: Deposit[];
    cashAudits: CashAudit[];
    user: User;
    person: Person;
    server: Person;
    organization: Organization;
    startTime: Date;
    subtotal: number;
    creditsTotal: number;
    mobileFee: number;
    membershipFee: number;
    totalFee: number;
    deposits: number;
    invoiced: number;
    hostFee: number;
    organizationFee: number;
    status: string;
    pendingTip: number;
    rating: OrganizationRating;
    paymentInformation: TabPaymentInformation;
    credits: TabCredit[];
    giftCardPayments: GiftCardAction[];
    nameOverride: string;
    claimCode: string;
    deviceId: string;
    singleOrder: boolean;
    tabNumber: string;
    taxExemptionId: string;
    invoiceToken: string;
    customerAreaPosition: CustomerAreaPosition;
    potentialFee: number;
    tempToken: string;
    terminalId: string;
    fromTerminal: boolean;
    cardOnFile: CardSummary;
    notes: TabNote[];
    autoCloseFailed: boolean;
    organizationName: string;
    inFlight = false;
    gratuityEligibleAmount: number;
    membershipProgram: MembershipProgram;
    member: Membership;
    key: string;
    version: number;

    addedTaxes: number;

    // for userless
    cardSummary: CardSummary;
    timeZone: string;

    preload: boolean;
    fromCache = false;

    public constructor(jsonInput: any) {
        super(jsonInput);
        Object.assign(this, jsonInput);
        const orders = [];
        if (this.orders) {
            this.orders.forEach(o => {
                orders.push(new Order(o));
            });
        }
        this.orders = orders;

        const payments = [];
        if (this.paymentCharges) {
            this.paymentCharges.forEach(p => {
                payments.push(new PaymentCharge(p));
            });
        }
        this.paymentCharges = payments;

        const cashAudits = [];
        if (this.cashAudits) {
            this.cashAudits.forEach(c => {
                cashAudits.push(new CashAudit(c));
            });
        }
        this.cashAudits = cashAudits;
        this.customerAreaPosition = !!this.customerAreaPosition ? new CustomerAreaPosition(this.customerAreaPosition) : null;
    }

    public get personName(): string {
        if (!!this.nameOverride) {
            return this.nameOverride;
        } else {
            return !!this.person ? this.person.name : 'Terminal Order';
        }
    }

    sortOrders() {
        this.orders = this.orders.sort((a: Order, b: Order) => a.statusPriority() - b.statusPriority());
        const o = [];
        this.orders.forEach(i => {
            o.push(new Order(i));
        });
        this.orders = o;
    }

    activeOrders() {
        return this.orders.filter(o => o.status !== OrderStatuses.CANCELED
            && o.status !== OrderStatuses.VACATED
            && o.status !== OrderStatuses.DELETED);
    }

    init(): void {
        this.organization = this.organization ? new Organization(this.organization) : null;
        this.user = this.user ? new User(this.user) : null;
        this.membershipProgram = this.membershipProgram ? new MembershipProgram(this.membershipProgram) : null;
        this.member = this.member ? new Membership(this.member) : null;

        const temp = [];
        if (!!this.orders) {
            this.orders.forEach(r => {
                temp.push(new Order(r));
            });
            this.orders = temp;
        }
    }

    statusPriority() {
        let priority = 100;
        switch (this.status) {
        case 'OPEN':
            priority = 0;
            break;
        case 'CLOSED':
            priority = 10;
            break;
        }

        return priority;
    }

    get pickupLocationId() {
        return !!this.orders && this.orders.length > 0 ? this.orders[this.orders.length - 1].pickupLocation.id : null;
    }

    recalculate() {
        this.applyComps();
        this.groupTaxedAmounts();

        const includedMembershipFee = this.orders.reduce((membershipFee, order) => addCurrency(membershipFee,
            order.items.reduce((itemFee, i) => i.membershipFeeIncluded ? addCurrency(itemFee, i.membershipFees) : itemFee, 0)), 0);

        this.subtotal = addCurrency(includedMembershipFee, this.orders.reduce((orderTotal, order) => addCurrency(orderTotal, order.calculateTotal()), 0));
        this.invoiced = subtractCurrency(addCurrency(this.addedTaxes,
            addCurrency(this.totalFee, this.subtotal)), this.paymentInformation.credits);

        this.membershipFee = this.orders.reduce((membershipFee, order) => addCurrency(membershipFee,
            order.items.reduce((itemFee, i) => !i.membershipFeeIncluded ? addCurrency(itemFee, i.membershipFees) : itemFee, 0)), 0);

        this.paymentInformation.remaining = subtractCurrency(addCurrency(this.membershipFee, this.invoiced), this.paymentInformation.appliedPayments);
    }

    applyComps() {
        this.paymentInformation.orderItemCredits = this.orders.reduce((all, o) => all.concat(o.items), [])
            .reduce((credits, i) => credits.concat(i.credits), [])
            .reduce((total: number, c) => addCurrency(total, c.amount), 0);

        const directComps = this.credits.filter(c => c.compDefinition.type !== 'PERCENT')
            .reduce((total, credit) => addCurrency(total, credit.amount), this.paymentInformation.orderItemCredits);

        const tabSubtotalLessComps = subtractCurrency(this.subtotal, directComps);

        this.credits.filter(c => c.compDefinition.type === 'PERCENT')
            .forEach(c => c.amount = multiplyCurrency(tabSubtotalLessComps, divideCurrency(c.compDefinition.amount, 100)));

        this.paymentInformation.tabCredits = this.credits.reduce((total, credit) => addCurrency(total, credit.amount), 0);

        this.paymentInformation.credits = addCurrency(this.paymentInformation.tabCredits, this.paymentInformation.orderItemCredits);

        this.distributeTabCredits();
    }

    distributeTabCredits() {
        const items: OrderItem[] = this.orders.reduce((all, o) => all.concat(o.items), [])
            .sort((a, b) => subtractCurrency(a.total, a.calcCredits()) - subtractCurrency(b.total, b.calcCredits()));

        items.forEach(i => i.tabCredits = 0);

        if (this.paymentInformation.tabCredits > 0) {
            let usedCredits = 0;
            let itemsCount = items.length;
            let adjusted = divideCurrency(this.paymentInformation.tabCredits, itemsCount);
            let itemTotalLessCredits: number;
            let oItem: OrderItem;
            let index = 0;

            while (itemsCount >= 1) {
                oItem = items[index++];
                if (itemsCount === 1) {
                    adjusted = subtractCurrency(this.paymentInformation.tabCredits, usedCredits);
                }
                itemTotalLessCredits = subtractCurrency(oItem.total, oItem.calcCredits());

                if (adjusted < itemTotalLessCredits) {
                    oItem.tabCredits = adjusted;
                    usedCredits = addCurrency(usedCredits, adjusted);
                } else {
                    oItem.tabCredits = itemTotalLessCredits;
                    usedCredits = addCurrency(usedCredits, itemTotalLessCredits);
                }

                if (itemsCount > 1) {
                    adjusted = divideCurrency(subtractCurrency(this.paymentInformation.tabCredits, usedCredits), itemsCount);
                }

                itemsCount--;
            }
        }
    }

    groupTaxedAmounts() {
        if (!this.taxExemptionId) {
            const tabTaxes = this.orders.reduce((taxes: Tax[], o) => {
                o.groupTaxedAmounts(o, this);

                o.items.forEach(item => {
                    if (!!item.taxes) {
                        item.taxes.forEach(itemTax => {
                            let tax = taxes.find(t => t.name === itemTax.name
                                && t.taxRate === itemTax.taxRate
                                && t.taxIncluded === itemTax.taxIncluded);

                            if (!tax) {
                                tax = new Tax({...itemTax});
                                tax.tax = null;
                                taxes.push(tax);
                            } else {
                                tax.taxedTotal = addCurrency(tax.taxedTotal, itemTax.taxedTotal);
                            }
                        });
                    }
                });
                return taxes;
            }, []);

            let addedTaxes = 0;
            tabTaxes.forEach(t => {
                t.tax = calculateTax(t.taxedTotal, t.taxRate);
                if (!t.taxIncluded) {
                    addedTaxes = addCurrency(addedTaxes, t.tax);
                }
            });
            this.taxes = tabTaxes;
            this.addedTaxes = addedTaxes;
            this.paymentInformation.taxes = this.taxes.reduce((total, tax) => addCurrency(total, tax.tax), 0);
            this.distributeTabTaxes();
        } else {
            this.taxes = [];
            this.addedTaxes = 0;
            this.paymentInformation.taxes = 0;
        }
    }

    distributeTabTaxes() {
        if (!!this.taxes && this.taxes.length > 0 && !!this.orders) {
            this.taxes.forEach(t => {
                const items = this.orders.reduce((filteredItems, o) =>
                    filteredItems.concat(o.items.filter(i => !!i.taxes && i.taxes.some(itemTax =>
                        t.name === itemTax.name
                        && t.taxRate === itemTax.taxRate
                        && t.taxIncluded === itemTax.taxIncluded))), []);

                const itemTaxTotal = items.reduce((total: number, i) => {
                    const found = i.taxes.find((itemTax: Tax) => t.name === itemTax.name
                        && t.taxRate === itemTax.taxRate
                        && t.taxIncluded === itemTax.taxIncluded);

                    return addCurrency(total, !!found ? found.tax : 0);
                }, 0);

                let remaining = subtractCurrency(t.tax, itemTaxTotal);
                let itemIndex = 0;
                while (remaining !== 0 && itemIndex < items.length) {
                    const item = items[itemIndex++];
                    const found = item.taxes.find((itemTax: Tax) => t.name === itemTax.name
                        && t.taxRate === itemTax.taxRate
                        && t.taxIncluded === itemTax.taxIncluded);

                    if (!!found) {
                        if (remaining > 0) {
                            found.tax = addCurrency(found.tax, .01);
                            remaining = subtractCurrency(remaining, .01);
                        } else {
                            found.tax = subtractCurrency(found.tax, .01);
                            remaining = addCurrency(remaining, .01);
                        }
                    }
                }
            });
        }
    }
}
