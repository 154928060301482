import {BaseFormModel} from './base-form-model';
import {Sortable} from './sortable';

export class CustomerAreaPosition extends BaseFormModel<CustomerAreaPosition> implements Sortable {
    id: number;
    name: string;
    label: string;
    areaId: number;
    orgId: number;
    sortOrder: number;

    formattedValue() {
        return !!this.label ? `${this.label} ${this.name}` : this.name;
    }
}
