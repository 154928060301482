export class TaxColumns {
    public static TAX_RATE = 'TAX_RATE';
    public static TAXED_TOTAL = 'TAXED_TOTAL';
    public static TAX_INCLUDED = 'TAX_INCLUDED';
    public static TAX = 'TAX';
    public static ORDER_ITEM_TAX_RATE = 'ORDER_ITEM_TAX_RATE';
    public static ORDER_ITEM_TAXED_TOTAL = 'ORDER_ITEM_TAXED_TOTAL';
    public static ORDER_ITEM_TAX_INCLUDED = 'ORDER_ITEM_TAX_INCLUDED';
    public static ORDER_ITEM_TAX = 'ORDER_ITEM_TAX';
}
