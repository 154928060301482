import {Injectable} from '@angular/core';
import {BaseObservableService} from './base-obeservable-service';
import {CardTransactionResponse} from '../_models/card-transaction-response';

@Injectable({
    providedIn: 'root'
})
export class CardTransactionResponseService extends BaseObservableService<CardTransactionResponse> {
    destination = 'card-transaction-responses';

    getByOrganizationIdAndReferenceId(organizationId: number, referenceId: string) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/${referenceId}`);
    }
}
