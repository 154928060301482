import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {MenuItemInventoryAction} from '../_models/menu-item-inventory-action';

@Injectable({
    providedIn: 'root'
})
export class MenuItemInventoryActionService extends BaseService<MenuItemInventoryAction> {
    destination = 'menu-item-inventory-action';

    transfer(fromId: number, action: MenuItemInventoryAction) {
        return this.http.put(`${this.baseUrl()}/${fromId}/transfer`, action);
    }

    kick(menuInventoryItemId: number) {
        return this.http.put(`${this.baseUrl()}/menu-item-inventory/${menuInventoryItemId}/kick`, {});
    }

    kickAndReplace(menuInventoryItemId: number) {
        return this.http.put(`${this.baseUrl()}/menu-item-inventory/${menuInventoryItemId}/kick-replace`, {});
    }
}
