import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BaseModalComponent} from '../base-modal.component';
import {ToastService} from '../../_services/toast.service';
import {MarketingPushService} from '../../_services/marketing-push.service';
import {MarketingPush} from '../../_models/marketing-push';
import {OrganizationService} from '../../_services/organization.service';
import {LoadingService} from '../../_services/loading.service';
import {UploadImageModalComponent} from '../upload-image-modal/upload-image-modal.component';
import {FileUploadService} from '../../_services/file-upload.service';
import {FileUploadType} from '../../_constants/file-upload-type';

@Component({
    selector: 'bb-create-push-modal',
    templateUrl: './create-push-modal.component.html',
    styleUrls: ['./create-push-modal.component.css']
})
export class CreatePushModalComponent extends BaseModalComponent implements OnInit {
    @Input() orgUrl: string;
    createPushForm: FormGroup;
    imageUrl: string;

    constructor(
        modalController: ModalController,
        private marketingPushService: MarketingPushService,
        private organizationService: OrganizationService,
        private fileUploadService: FileUploadService,
        private alertController: AlertController,
        private platform: Platform,
        private loadingService: LoadingService,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    ngOnInit() {
        const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
        this.createPushForm = new FormGroup({});
        this.createPushForm.addControl('message', new FormControl('', [Validators.required, Validators.maxLength(1000)]));
        this.createPushForm.addControl('navUrl', new FormControl(this.orgUrl, [Validators.pattern(reg), Validators.maxLength(500)]));
    }

    async onSubmit() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Push',
            message: 'Are you sure you want to send this notification to all subscribed users? The contents of all marketing pushes are the responsibility of the organization and must abide by the BrewBill terms and conditions.',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.send();
                    }
                }
            ]
        });

        await alert.present();
    }

    async send() {
        const push = new MarketingPush(this.createPushForm.value);
        push.organization = this.organizationService.currentValue;
        push.image = this.imageUrl;
        this.loadingService.present();
        this.marketingPushService.create(push).subscribe(async p => {
            this.loadingService.dismiss();
            await this.toastService.success('Marketing push sent');
            await this.close(p);
        });
    }

    async openUploadImageModal() {
        if (this.platform.is('desktop')) {
            const modal = await this.modalController.create({
                component: UploadImageModalComponent
            });

            modal.onDidDismiss().then((dataReturned) => {
                if (dataReturned != null && dataReturned.data != null) {
                    this.uploadImage(dataReturned.data);
                }
            });

            await modal.present();
        } else {
            const image = await this.fileUploadService.getPhoto();
            const imgBlob = this.fileUploadService.b64toBlob(image.base64String, `image/${image.format}`);
            await this.uploadImage(imgBlob);
        }
    }

    readFile(file: any) {
        const reader = new FileReader();
        reader.onload = async () => {
            const imgBlob = new Blob([reader.result], {
                type: file.type
            });
            await this.uploadImage(imgBlob);
        };
        reader.readAsArrayBuffer(file);
    }

    async uploadImage(image: any) {
        this.loadingService.present();
        this.fileUploadService.uploadImage(this.organizationService.currentValue.id, FileUploadType.MARKETING_PUSH, image)
            .subscribe(async (response: any) => {
                this.loadingService.dismiss();
                this.imageUrl = response.response;
            });
    }
}
