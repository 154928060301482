import {Component, Input} from '@angular/core';
import {OrderItemSelectionItem} from '../../_models/order-item-selection-item';
import {multiplyCurrency} from '../../_utils/currency-math';
import {OrderItem} from '../../_models/order-item';

@Component({
    selector: 'bb-display-order-item-selection-item',
    templateUrl: './display-order-item-selection-item.component.html',
    styleUrls: ['./display-order-item-selection-item.component.scss']
})
export class DisplayOrderItemSelectionItemComponent {
    @Input() parent: OrderItem;
    @Input() selection: OrderItemSelectionItem;
    @Input() level = 1;
    @Input() compact = false;
    @Input() orderStatus: string;
    @Input() count: number;
    @Input() removePadding = false;

    hasDetails() {
        return this.selection.orderItem.removedToppingsFilter().length > 0
            || this.selection.orderItem.addedToppingsFilter().length > 0
            || this.selection.orderItem.selections.length > 0;
    }

    adjustedPrice(price) {
        return !!this.count ? multiplyCurrency(price, this.count) : price;
    }
}
