import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {PersonService} from '../../_services/person.service';
import {Person} from '../../_models/person';
import {ToastService} from '../../_services/toast.service';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-edit-person-modal',
    templateUrl: './edit-person-modal.component.html',
    styleUrls: ['./edit-person-modal.component.scss']
})
export class EditPersonModalComponent extends BaseModalComponent {
    editPersonForm: FormGroup = new FormGroup({});
    @Input() person: Person;
    @Input() self = false;
    @Input() skipSave = false;
    @Input() title = 'Contact Information';

    constructor(
        modalController: ModalController,
        private personService: PersonService,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    async onSubmit() {
        const p = new Person(this.editPersonForm.value);
        if (!this.skipSave) {
            this.personService.update(p).subscribe(
                async (person: Person) => {
                    await this.toastService.success('Personal information updated.');
                    await this.close(person);
                }
            );
        } else {
            await this.close(p);
        }
    }

    formInitialized(form: FormGroup) {
        this.editPersonForm = form;
    }
}
