<ion-row>
    <ion-col>
        <p>Effective date: January 30, 2021</p>

        <p>
            BrewBill ("us", "we", or "our") operates the website and the BrewBill mobile applications (hereinafter referred to as the
            "Services").
        </p>

        <p>
            This page informs you of our policies regarding the collection, use and
            disclosure of personal data when you use our Services and the choices you
            have associated with that data.
        </p>

        <p>
            We use your data to provide and improve the Services. By using the
            Services, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this Privacy
            Policy, the terms used in this Privacy Policy have the same meanings as
            in our Terms and Conditions.
        </p>

        <h2>Definitions</h2>
        <ul>
            <li>
                <p><strong>Services</strong></p>
                <p>
                    Services means the website and the BrewBill mobile applications
                    operated by BrewBill
                </p>
            </li>
            <li>
                <p><strong>Personal Data</strong></p>
                <p>
                    Personal Data means data about a living individual who can be
                    identified from those data (or from those and other information
                    either in our possession or likely to come into our possession).
                </p>
            </li>
            <li>
                <p><strong>Usage Data</strong></p>
                <p>
                    Usage Data is data collected automatically either generated by the
                    use of the Services or from the Services infrastructure itself (for
                    example, the duration of a page visit).
                </p>
            </li>
            <li>
                <p><strong>Cookies</strong></p>
                <p>
                    Cookies are small files stored on your device (computer or mobile
                    device).
                </p>
            </li>
        </ul>

        <h2>Information Collection and Use</h2>
        <p>
            We collect several different types of information for various purposes
            to provide and improve our Services to you.
        </p>

        <h3>Types of Data Collected</h3>

        <h4>Personal Data</h4>
        <p>
            While using our Services, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information may
            include, but is not limited to:
        </p>

        <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Cookies and Usage Data</li>
        </ul>

        <h4>Usage Data</h4>

        <p>
            We may also collect information that your browser sends whenever you
            visit our Services or when you access the Services by or through a mobile
            device ("Usage Data").
        </p>
        <p>
            This Usage Data may include information such as your computer's Internet
            Protocol address (e.g. IP address), browser type, browser version, the
            pages of our Services that you visit, the time and date of your visit,
            the time spent on those pages, unique device identifiers and other
            diagnostic data.
        </p>
        <p>
            When you access the Services with a mobile device, this Usage Data may
            include information such as the type of mobile device you use, your
            mobile device unique ID, the IP address of your mobile device, your
            mobile operating system, the type of mobile Internet browser you use,
            unique device identifiers and other diagnostic data.
        </p>

        <h4>Location Data</h4>
        <p>
            We may use and store information about your location if you give us
            permission to do so ("Location Data"). We use this data to provide
            features of our Services, to improve and customise our Services.
        </p>
        <p>
            You can enable or disable location services when you use our Services at
            any time by way of your device settings.
        </p>

        <h4>Tracking & Cookies Data</h4>
        <p>
            We use cookies and similar tracking technologies to track the activity
            on our Services and we hold certain information.
        </p>
        <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are also
            used such as beacons, tags and scripts to collect and track information
            and to improve and analyse our Services.
        </p>
        <p>
            You can instruct your browser to refuse all cookies or to indicate when
            a cookie is being sent. However, if you do not accept cookies, you may
            not be able to use some portions of our Services.
        </p>
        <p>Examples of Cookies we use:</p>
        <ul>
            <li>
                <strong>Session Cookies.</strong> We use Session Cookies to operate
                our Services.
            </li>
            <li>
                <strong>Preference Cookies.</strong> We use Preference Cookies to
                remember your preferences and various settings.
            </li>
            <li>
                <strong>Security Cookies.</strong> We use Security Cookies for
                security purposes.
            </li>
        </ul>

        <h2>Use of Data</h2>
        <p>BrewBill uses the collected data for various purposes:</p>
        <ul>
            <li>To provide and maintain our Services</li>
            <li>To notify you about changes to our Services</li>
            <li>
                To allow you to participate in interactive features of our Services
                when you choose to do so
            </li>
            <li>To provide customer support</li>
            <li>
                To gather analysis or valuable information so that we can improve our
                Services
            </li>
            <li>To monitor the usage of our Services</li>
            <li>To detect, prevent and address technical issues</li>
        </ul>

        <h2>Transfer of Data</h2>
        <p>
            Your information, including Personal Data, may be transferred to — and
            maintained on — computers located outside of your state, province,
            country or other governmental jurisdiction where the data protection
            laws may differ from those of your jurisdiction.
        </p>
        <p>
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
        </p>
        <p>
            Your consent to this Privacy Policy followed by your submission of such
            information represents your agreement to that transfer.
        </p>
        <p>
            BrewBill will take all the steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this Privacy Policy
            and no transfer of your Personal Data will take place to an organisation
            or a country unless there are adequate controls in place including the
            security of your data and other personal information.
        </p>

        <h2>Disclosure of Data</h2>

        <h3>Legal Requirements</h3>
        <p>
            BrewBill may disclose your Personal Data in the good faith belief
            that such action is necessary to:
        </p>
        <ul>
            <li>To comply with a legal obligation</li>
            <li>To protect and defend the rights or property of BrewBill</li>
            <li>
                To prevent or investigate possible wrongdoing in connection with the
                Services
            </li>
            <li>
                To protect the personal safety of users of the Services or the public
            </li>
            <li>To protect against legal liability</li>
        </ul>

        <h2>Security of Data</h2>
        <p>
            The security of your data is important to us but remember that no method
            of transmission over the Internet or method of electronic storage is
            100% secure. While we strive to use commercially acceptable means to
            protect your Personal Data, we cannot guarantee its absolute security.
        </p>

        <h2>Service Providers</h2>
        <p>
            We may employ third party companies and individuals to facilitate our
            Services ("Service Providers"), provide the Services on our behalf,
            perform Service-related services or assist us in analysing how our
            Services are used.
        </p>
        <p>
            These third parties have access to your Personal Data only to perform
            these tasks on our behalf and are obligated not to disclose or use it
            for any other purpose.
        </p>

        <h3>Analytics</h3>
        <p>
            We may use third-party Service Providers to monitor and analyse the use
            of our Services.
        </p>
        <ul>
            <li>
                <p><strong>Google Analytics</strong></p>
                <p>
                    Google Analytics is a web analytics service offered by Google that
                    tracks and reports website traffic. Google uses the data collected
                    to track and monitor the use of our Services. This data is shared
                    with other Google services. Google may use the collected data to
                    contextualise and personalise the ads of its own advertising
                    network.
                </p>
                <p>
                    For more information on the privacy practices of Google, please
                    visit the Google Privacy & Terms web page:
                    <a href="https://policies.google.com/privacy?hl=en"
                    >https://policies.google.com/privacy?hl=en</a
                    >
                </p>
            </li>
        </ul>

        <h2>Links to Other Sites</h2>
        <p>
            Our Services may contain links to other sites that are not operated by
            us. If you click a third party link, you will be directed to that third
            party's site. We strongly advise you to review the Privacy Policy of
            every site you visit.
        </p>
        <p>
            We have no control over and assume no responsibility for the content,
            privacy policies or practices of any third party sites or services.
        </p>

        <h2>Children's Privacy</h2>
        <p>
            Our Services do not address anyone under the age of 18 ("Children").
        </p>
        <p>
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you are
            aware that your Child has provided us with Personal Data, please contact
            us. If we become aware that we have collected Personal Data from
            children without verification of parental consent, we take steps to
            remove that information from our servers.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
            We may update our Privacy Policy from time to time. We will notify you
            of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
            We will let you know via email and/or a prominent notice on our Services,
            prior to the change becoming effective and update the "effective date"
            at the top of this Privacy Policy.
        </p>
        <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
        </p>

        <h2>Contact Us</h2>
        <p>
            If you have any questions about this Privacy Policy, please contact us:
        </p>
        <ul>
            <li>By email: admin@brewbill.com</li>
        </ul>
    </ion-col>
</ion-row>
