import {Component, Input, OnInit} from '@angular/core';
import {OrganizationRating} from '../../_models/organization-rating';
import {OrganizationRatingService} from '../../_services/organization-rating.service';

@Component({
    selector: 'bb-display-rating',
    templateUrl: './display-rating.component.html',
    styleUrls: ['./display-rating.component.css']
})
export class DisplayRatingComponent implements OnInit {
    @Input() rating: OrganizationRating;
    @Input() organizationView = true;

    constructor(private organizationRatingService: OrganizationRatingService) {
    }

    ngOnInit(): void {
    }

    read() {
        return this.organizationView ? !!this.rating.readDate : !!this.rating.responseReadDate;
    }

    toggleRead(event) {
        event.stopPropagation();
        this.organizationRatingService.toggleOrganizationRead(this.rating.id).subscribe((r: OrganizationRating) => {
            this.rating.readDate = r.readDate;
        });
    }
}
