import {Component, Inject, Input} from '@angular/core';
import {Organization} from '../../_models/organization';
import {OrganizationService} from '../../_services/organization.service';
import {ModalController, Platform} from '@ionic/angular';
import {ToastService} from '../../_services/toast.service';
import {LoadingService} from '../../_services/loading.service';
import {LibConfig, LibConfigService} from '../../lib.config';
import {UploadImageModalComponent} from '../upload-image-modal/upload-image-modal.component';
import {FileUploadService} from '../../_services/file-upload.service';

@Component({
    selector: 'bb-organization',
    templateUrl: './organization.component.html',
    styleUrls: ['./organization.component.css']
})
export class OrganizationComponent {
    @Input() organization: Organization;
    @Input() editable = false;

    constructor(
        @Inject(LibConfigService) public config: LibConfig,
        private organizationService: OrganizationService,
        private toastService: ToastService,
        private modalController: ModalController,
        private loadingService: LoadingService,
        private platform: Platform,
        private fileUploadService: FileUploadService
    ) {
    }

    async openUploadImageModal() {
        if (this.editable) {
            if (this.platform.is('desktop')) {
                const modal = await this.modalController.create({
                    component: UploadImageModalComponent
                });

                modal.onDidDismiss().then((dataReturned) => {
                    if (dataReturned != null && dataReturned.data != null) {
                        this.saveImage(dataReturned.data, null);
                    }
                });

                await modal.present();
            } else {
                const image = await this.fileUploadService.getPhoto();
                const imgBlob = this.fileUploadService.b64toBlob(image.base64String, `image/${image.format}`);
                await this.saveImage(imgBlob, `temp.${image.format}`);
            }
        }
    }

    readFile(file: any) {
        const reader = new FileReader();
        reader.onload = async () => {
            const imgBlob = new Blob([reader.result], {
                type: file.type
            });
            await this.saveImage(imgBlob, file.name);
        };
        reader.readAsArrayBuffer(file);
    }

    async saveImage(image: any, fileName: string) {
        this.loadingService.present();
        this.organizationService.uploadLogo(this.organization.id, image, fileName).subscribe(() => {
            this.toastService.success('The image has been uploaded.');
            this.organizationService.get(this.organization.id).subscribe((org: Organization) => {
                    this.organization = org;
                    this.loadingService.dismiss();
                },
                () => {
                    this.loadingService.dismiss();
                    this.toastService.error('Error reloading page.');
                });
        }, () => {
            this.loadingService.dismiss();
            this.toastService.error('Error saving image.');
        });
    }
}

