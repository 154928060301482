import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {PickupLocationMenuService} from '../_services/pickup-location-menu.service';

@Injectable()
export class PickupLocationMenuResolver implements Resolve<any> {
    constructor(private pickupLocationMenuService: PickupLocationMenuService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        const pickupLocationMenuId = route.paramMap.get('pickupLocationMenuId');
        if (!!pickupLocationMenuId) {
            return this.pickupLocationMenuService.get(pickupLocationMenuId);
        } else {
            return this.pickupLocationMenuService.findByPickupLocationId(Number(route.paramMap.get('pickupLocationId')));
        }
    }
}
