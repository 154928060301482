import {BaseFormModel} from '../base-form-model';
import {Person} from '../person';

export class CashSessionNote extends BaseFormModel<CashSessionNote> {
    id: number;
    orgId: number;
    parentId: number;
    note: string;
    createdBy: Person;
    createDate: Date;
}
