import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserService} from '../_services/user.service';
import {OrganizationService} from '../_services/organization.service';
import {User} from '../_models/user';
import {Organization} from '../_models/organization';

@Directive({
    selector: '[bbSecured]'
})
export class SecuredDirective implements OnInit {
    feature: string | string[];
    user: User;
    organization: Organization;

    @Input() set bbSecured(feature: string | string[]) {
        this.feature = feature;
        this.checkAuth();
    }

    constructor(
        private userService: UserService,
        private organizationService: OrganizationService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {
    }

    ngOnInit(): void {
        this.user = !!this.userService.currentValue ? new User(this.userService.currentValue) : null;
        this.organization = this.organizationService.currentValue;
        this.checkAuth();
    }

    checkAuth() {
        if (!!this.user
            && !!this.organization
            && this.user.authorizedTo(this.feature, this.organization.id)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

}
