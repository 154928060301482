import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {IonInput, ModalController} from '@ionic/angular';
import {divideCurrency} from '../../_utils/currency-math';

@Component({
    selector: 'bb-custom-dollar-amount',
    templateUrl: './custom-dollar-amount.component.html',
    styleUrls: ['./custom-dollar-amount.component.scss'],
})
export class CustomDollarAmountComponent extends BaseModalComponent implements OnInit {
    @ViewChild('manualAmount') set first(input: IonInput) {
        if (!!input) {
            setTimeout(async () => {
                await input.setFocus();
            }, 250);
        }
    }

    @Input() amount: number;
    @Input() max: number;
    @Input() label: string;
    @Input() splitMax: number;

    selectedSplit = null;
    formatted = null;

    constructor(modalController: ModalController) {
        super(modalController);
    }

    ngOnInit() {
        this.formatted = !!this.amount ? this.amount.toString(10) : '';
    }

    setToMax() {
        this.amount = this.max;
        this.formatted = !!this.amount ? this.amount.toString(10) : '';
    }

    calculateSplit() {
        this.amount = divideCurrency(this.splitMax, this.selectedSplit);
        this.formatted = !!this.amount ? this.amount.toString(10) : '';
    }

    formatAndClose() {
        this.close(!!this.formatted && this.formatted.length > 1 && this.formatted.indexOf('$') > -1
            ? parseFloat(this.formatted.substring(1))
            : this.formatted);
    }
}
