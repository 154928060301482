import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {OrderItem} from '../../_models/order-item';
import {OrganizationService} from '../../_services/organization.service';
import {UserService} from '../../_services/user.service';
import {BaseManagerComponent} from '../base-manager.component';
import {AuthenticationService} from '../../_services/authentication.service';
import {PickupLocationService} from '../../_services/pickup-location.service';
import {OrderItemSummary} from '../../_models/order-item-summary';
import {addCurrency, multiplyCurrency} from '../../_utils/currency-math';
import {FeatureKeys} from '../../_constants/feature-keys';
import {CompDefinition} from '../../_models/comp-definition';
import {CompDefinitionTypes} from '../../_constants/comp-definition-types';
import {OrderItemCredit} from '../../_models/order-item-credit';
import {OrderStatuses} from '../../_constants/order-statuses';
import {OrganizationTerminalService} from '../../_services/organization-terminal.service';
import {MembershipService} from '../../_services/membership.service';

@Component({
    selector: 'bb-display-order-item',
    templateUrl: './display-order-item.component.html',
    styleUrls: ['./display-order-item.component.scss']
})
export class DisplayOrderItemComponent extends BaseManagerComponent implements OnInit, OnChanges {
    @Input() item: OrderItem;
    @Input() itemSummary: OrderItemSummary;
    @Input() additionalPrice: number;
    @Input() level = 1;
    @Input() hideHeader = false;
    @Input() removePadding = false;
    @Input() count: number;
    @Input() compact = false;
    @Input() details = false;
    @Input() selectedItemIds: number[];
    @Input() editable = false;
    @Input() orderStatus: string;
    @Input() availableOrderComps: CompDefinition[] = [];
    @Input() showImageOverride = false;

    @Output() itemsSelected = new EventEmitter<OrderItem[]>();
    @Output() updateItem: EventEmitter<OrderItem> = new EventEmitter<OrderItem>();
    @Output() add: EventEmitter<OrderItemSummary> = new EventEmitter<OrderItemSummary>();
    @Output() remove: EventEmitter<OrderItemSummary> = new EventEmitter<OrderItemSummary>();
    @Output() imageLoaded = new EventEmitter<any>();
    @Output() addCredit = new EventEmitter<OrderItem>();
    @Output() removeCredit = new EventEmitter<any>();
    @Output() removeTokens = new EventEmitter<OrderItem>();

    miscItem = false;
    filteredComps: CompDefinition[] = [];
    isTerminal = false;

    compDefinitionTypes = CompDefinitionTypes;
    featureKeys = FeatureKeys;
    orderStatuses = OrderStatuses;
    multiplyCurrency = multiplyCurrency;

    constructor(
        private organizationTerminalService: OrganizationTerminalService,
        authenticationService: AuthenticationService,
        organizationService: OrganizationService,
        pickupLocationService: PickupLocationService,
        userService: UserService,
        membershipService: MembershipService
    ) {
        super(authenticationService,
            organizationService,
            pickupLocationService,
            userService,
            membershipService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.isTerminal = this.organizationTerminalService.isTerminal;
        this.init();
    }

    init() {
        if (!!this.itemSummary) {
            this.item = new OrderItem(this.itemSummary.items[0]);
        } else {
            this.item = new OrderItem(this.item);
        }
        this.miscItem = !this.item.menuItem;
        if (!!this.availableOrderComps) {
            this.filteredComps = this.availableOrderComps
                .filter(c => !c.items
                    || c.items.length === 0
                    || c.items.some(p => !!this.item.selectedPrice && p.priceId === this.item.selectedPrice.id));
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes.itemSummary && !changes.itemSummary.firstChange) {
            this.init();
        }
    }

    childRow() {
        return 'border-left: 1px solid #fff; margin-left: ' + ((this.level + 1) * 15)
            + 'px; max-width: calc((calc(9 / var(--ion-grid-columns, 12)) * 100%) - ' + ((this.level + 1) * 15) + ')';
    }

    parentRow() {
        return 'margin-left: ' + (this.level * 15) + 'px;';
    }

    adjustedTokenPrice(price) {
        return !!this.count ? (price * this.count) : price;
    }

    adjustedPrice(price) {
        return !!this.count ? multiplyCurrency(price, this.count) : price;
    }

    async addCreditClicked(event) {
        event.stopPropagation();
        this.addCredit.emit(this.item);
    }

    async removeCreditClicked(event, credit: OrderItemCredit) {
        event.stopPropagation();
        this.removeCredit.emit({orderItem: this.item, credit});
    }

    removeMisc(event) {
        event.stopPropagation();
        this.remove.emit(this.itemSummary);
    }

    addClicked() {
        if (!!this.item.membershipRenewal) {
            this.item.membershipRenewal.quantity++;
            this.item.total = (multiplyCurrency(this.item.membershipRenewal.quantity, this.item.membershipRenewal.membershipProgram.amount));
            this.updateItem.emit(this.item);
        } else {
            this.add.emit(this.itemSummary);
        }
    }

    removeClicked() {
        if (!!this.item.membershipRenewal && this.item.membershipRenewal.quantity > 1) {
            this.item.membershipRenewal.quantity--;
            this.item.total = (multiplyCurrency(this.item.membershipRenewal.quantity, this.item.membershipRenewal.membershipProgram.amount));
            this.updateItem.emit(this.item);
        } else {
            this.remove.emit(this.itemSummary);
        }
    }

    protected readonly addCurrency = addCurrency;
}
