export class UserOrganizationCreditSummary {
    id: number;
    createdBy: string;
    creditDate: Date;
    amount: number;
    type: string;
    remaining: number;
    rewardType: string;
    rewardName: string;
    organizationName: string;
    organizationId: number;
    programId: number;
}
