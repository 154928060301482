import {BaseFormModel} from '../base-form-model';
import {Person} from '../person';

export class CashSessionEmployee extends BaseFormModel<CashSessionEmployee> {
    id: number;
    orgId: number;
    parentId: number;
    person: Person;
    hours: number;
    payrollTips: number;
    cashTips: number;
    total: number;

    percent: number;
    expected: number;
}
