import {BaseFormModel} from './base-form-model';
import {MenuItem} from './menu-item';
import {OrganizationRole} from './organization-role';
import {PickupLocation} from './pickup-location';
import {MenuItemPrice} from './menu-item-price';
import {MenuItemInventoryPriceAdjustment} from './menu-item-inventory-price-adjustment';

export class MenuItemInventory extends BaseFormModel<MenuItemInventory> {
    id: number;
    orgId: number;
    pickupLocation: PickupLocation;
    menuItem: MenuItem;
    menuItemPrice: MenuItemPrice;
    priceAdjustments: MenuItemInventoryPriceAdjustment[];
    alertRole: OrganizationRole;
    fillFrom: MenuItemInventory;
    fillQuantity: number;
    alertCount: number;
    byPriceCount: number;
    currentCount: number;
    mobileCountdownStart: number;
    label: string;
    containerLabel: string;
    containerQuantity: number;
    expectedPercentageLost: number;
    productionCost: number;
    createDate: Date;
    active: boolean;
    deleted: boolean;

    includeBackOfHouse: boolean;
    currentCountBackOfHouse: number;
    alertCountBackOfHouse: number;
}
