import {Inject, Injectable} from '@angular/core';
import {Venue} from '../_models/venue';
import {HttpClient} from '@angular/common/http';
import {ModalController} from '@ionic/angular';
import {LibConfig, LibConfigService} from '../lib.config';
import {BaseObservableService} from './base-obeservable-service';

@Injectable({
    providedIn: 'root'
})
export class VenueService extends BaseObservableService<Venue> {
    destination = 'venues';

    constructor(
        http: HttpClient,
        private modalController: ModalController,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);
    }

    updateBoundary(id, north, south, east, west) {
        return this.http.put(`${this.baseUrl()}/${id}/boundary/${north}/${south}/${east}/${west}`, {});
    }

    findBySubdomain(subdomain: string) {
        return this.http.get(`${this.baseUrl()}/subdomain/${subdomain}`);
    }
}
