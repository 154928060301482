import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {OrganizationTerminal} from '../../_models/organization-terminal';
import {Organization} from '../../_models/organization';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrganizationTerminalService} from '../../_services/organization-terminal.service';
import {LoadingService} from '../../_services/loading.service';
import {ToastService} from '../../_services/toast.service';

@Component({
    selector: 'bb-edit-terminal',
    templateUrl: './edit-terminal.component.html',
    styleUrls: ['./edit-terminal.component.scss'],
})
export class EditTerminalComponent extends BaseModalComponent implements OnInit {
    @Input() terminal: OrganizationTerminal;
    @Input() organization: Organization;
    formGroup: FormGroup;

    constructor(
        private organizationTerminalService: OrganizationTerminalService,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private toastService: ToastService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group(
            {
                id: !!this.terminal ? this.terminal.id : null,
                terminalId: [!!this.terminal ? this.terminal.terminalId : '', Validators.required],
                nickname: [!!this.terminal ? this.terminal.nickname : '', Validators.required],
                promptTipOnReader: !!this.terminal && this.terminal.promptTipOnReader,
                parentId: this.organization.id
            });
    }

    onSubmit() {
        const organizationTerminal = new OrganizationTerminal(this.formGroup.value);
        this.loadingService.present();
        if (!organizationTerminal.id) {
            this.organizationTerminalService.create(organizationTerminal).subscribe(o => {
                this.loadingService.dismiss();
                this.toastService.success('The terminal has been saved.');
                this.close(o);
            });
        } else {
            this.organizationTerminalService.update(organizationTerminal).subscribe(o => {
                this.loadingService.dismiss();
                this.toastService.success('The terminal has been saved.');
                this.close(o);
            });
        }
    }
}
