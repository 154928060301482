import {Injectable} from '@angular/core';
import {Visit} from '../_models/visit';
import {BaseObservableService} from './base-obeservable-service';

@Injectable({
    providedIn: 'root'
})
export class VisitService extends BaseObservableService<Visit> {
    destination = 'visits';

    save(pickupLocationId: number, uuid: string, personId: number, waiting: boolean) {
        return this.http.put(`${this.baseUrl()}/${pickupLocationId}?uuid=${uuid}&waiting=${waiting}${!!personId
            ? '&personId=' + personId
            : ''}`, {});
    }

    readyWaits(pickupLocationId: number) {
        return this.http.get(`${this.baseUrl()}/${pickupLocationId}/ready-waits`);
    }
}
