import {Component, Input} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';
import {MenuItemGroup} from '../../_models/menu-item-group';
import {OrganizationRewardProgram} from '../../_models/organization-reward-program';
import {UserOrganizationCreditSummary} from '../../_models/user-organization-credit-summary';
import {MenuItem} from '../../_models/menu-item';
import {DisplayMenuItemModalComponent} from '../display-menu-item-modal/display-menu-item-modal.component';
import {Availabilities} from '../../_constants/availabilities';
import {PickupLocationMenu} from '../../_models/pickup-location-menu';
import {OrderItemService} from '../../_services/order-item.service';

@Component({
    selector: 'bb-menu-item-group-select',
    templateUrl: './menu-item-group-select.component.html',
    styleUrls: ['./menu-item-group-select.component.scss'],
})
export class MenuItemGroupSelectComponent extends BaseModalComponent {
    @Input() itemGroup: MenuItemGroup;
    @Input() terminalMode = false;
    @Input() rewardProgram: OrganizationRewardProgram;
    @Input() activeCredit: UserOrganizationCreditSummary;
    @Input() showInventory = false;
    @Input() editMode = false;
    @Input() pickupLocationMenu: PickupLocationMenu;

    constructor(
        private orderItemService: OrderItemService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    async openItemModal(menuItem: MenuItem, initialPrice = null) {
        const modal = await this.modalController.create({
            component: DisplayMenuItemModalComponent,
            componentProps: {
                menuItem,
                initialPrice,
                rewardProgram: this.rewardProgram,
                activeCredit: this.activeCredit,
                terminalMode: this.terminalMode,
                showInventory: this.showInventory
            },
            cssClass: 'menu-item-modal'
        });
        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (!!dataReturned && !!dataReturned.data) {
                this.close(dataReturned.data);
            }
        });
    }

    checkMode(menuItem: MenuItem) {
        const defaultPrice = menuItem.prices.find(p => p.defaultPrice);
        if (this.terminalMode
            && !menuItem.selectionRequired
            && !!defaultPrice
            && (
                (this.pickupLocationMenu.allowInHouse
                    && menuItem.allowInHouse()
                    && defaultPrice.allowInHouse()) ||
                (this.pickupLocationMenu.allowTogo
                    && menuItem.allowToGo()
                    && defaultPrice.allowToGo())
            )) {
            const orderItem = this.orderItemService.initializeOrderItem(menuItem, null);
            if (orderItem.selectedPrice.availability === Availabilities.ALL
                || orderItem.selectedPrice.availability === Availabilities.IN_HOUSE) {
                orderItem.orderType = Availabilities.IN_HOUSE;
            } else {
                orderItem.orderType = Availabilities.TOGO;
            }
            this.close([orderItem]);
        } else {
            this.openItemModal(menuItem);
        }
    }
}
