<modal-header title="Payment Refund"></modal-header>

<ion-content>
    <ion-grid>
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
            <ion-row *ngIf="!!paymentCharge.registerId">
                <ion-col>
                    <div *ngIf="paymentCharge.refundable" class="success-alert">
                        This refund requires the card to be presented at the terminal. A present card may be required.
                    </div>
                    <div *ngIf="paymentCharge.voidable" class="success-alert">
                        This charge has not completed processing and can only be fully voided at this time. A present card may be required.
                    </div>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size-xs="12">
                    <ion-segment *ngIf="!!formGroup"
                                 formControlName="creditTab"
                                 mode="ios"
                                 (ionChange)="creditChanged()">
                        <ion-segment-button value="REFUND" class="action-selection">
                            <ion-label>Refund Only</ion-label>
                        </ion-segment-button>
                        <ion-segment-button value="REFUND_AND_COMP" class="action-selection">
                            <ion-label>Refund and Comp Tab</ion-label>
                        </ion-segment-button>
                    </ion-segment>
                </ion-col>
                <ng-container *ngIf="!!formGroup.controls.creditTab.value">
                    <ion-col size-xs="12" *ngIf="formGroup.controls.creditTab.value === 'REFUND_AND_COMP'">
                        <ion-item>
                            <ion-label position="floating">Reason</ion-label>
                            <ion-select formControlName="creditType" interface="popover">
                                <ion-select-option value="UNSATISFACTORY">{{'UNSATISFACTORY' | creditType}}</ion-select-option>
                                <ion-select-option value="INCORRECT_ITEM">{{'INCORRECT_ITEM' | creditType}}</ion-select-option>
                                <ion-select-option value="POOR_SERVICE">{{'POOR_SERVICE' | creditType}}</ion-select-option>
                                <ion-select-option value="LATE">{{'LATE' | creditType}}</ion-select-option>
                                <ion-select-option value="NEVER_RECEIVED">{{'NEVER_RECEIVED' | creditType}}</ion-select-option>
                                <ion-select-option value="DUPLICATE">{{'DUPLICATE' | creditType}}</ion-select-option>
                                <ion-select-option value="FRAUDULENT">{{'FRAUDULENT' | creditType}}</ion-select-option>
                                <ion-select-option value="REWARD">{{'REWARD' | creditType}}</ion-select-option>
                                <ion-select-option value="OTHER">{{'OTHER' | creditType}}</ion-select-option>
                            </ion-select>
                        </ion-item>
                    </ion-col>

                    <ion-col size-xs="6">
                        <ion-item>
                            <ion-label class="floating-label" position="stacked">
                                Current Paid
                            </ion-label>
                            <h1 class="amount">
                                {{available | currency}}
                            </h1>
                            <ion-icon class="arrow" name="arrow-forward-outline" slot="end" (click)="maxRefund()"></ion-icon>
                        </ion-item>
                    </ion-col>

                    <ion-col size-xs="6">
                        <ion-item>
                            <ion-label class="floating-label" position="floating">
                                Refund
                            </ion-label>
                            <ion-input class="amount" bbCurrencyMask inputmode="decimal" type="number" min="0" name="principal"
                                       [disabled]="!!paymentCharge.registerId && !paymentCharge.refundable"
                                       formControlName="principal"></ion-input>
                        </ion-item>
                    </ion-col>

                    <ng-container *ngIf="availableTip > 0">
                        <ion-col size-xs="6">
                            <ion-item>
                                <ion-label class="floating-label" position="stacked">
                                    Current Tip
                                </ion-label>
                                <h1 class="amount">
                                    {{availableTip | currency}}
                                </h1>
                                <ion-icon class="arrow" name="arrow-forward-outline" slot="end" (click)="maxTip()"></ion-icon>
                            </ion-item>
                        </ion-col>
                        <ion-col size-xs="6">
                            <ion-item>
                                <ion-label class="floating-label" position="floating">
                                    Refund Tip
                                </ion-label>
                                <ion-input class="amount" bbCurrencyMask inputmode="decimal" type="number" min="0" name="tip"
                                           [disabled]="!!paymentCharge.registerId && !paymentCharge.refundable"
                                           formControlName="tip"></ion-input>
                            </ion-item>
                        </ion-col>
                    </ng-container>
                    <ion-col size-xs="12">
                        <ion-item>
                            <ion-label position="floating">Comment</ion-label>
                            <ion-textarea rows="5" formControlName="comment" [maxlength]="2000"></ion-textarea>
                        </ion-item>
                    </ion-col>
                </ng-container>
            </ion-row>
        </form>
    </ion-grid>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-button color="dark" class="footer-button"
                [disabled]="formGroup.invalid || (formGroup.controls.tip.value === 0 && formGroup.controls.principal.value === 0)"
                (click)="onSubmit()">
        {{formGroup.controls.creditTab.value == 'true' ? 'Refund and Credit' : 'Refund'}}
    </ion-button>
</ion-footer>
