import {Component} from '@angular/core';
import {BaseModalComponent} from './base-modal.component';
import {AlertController, ModalController} from '@ionic/angular';
import {FormGroup} from '@angular/forms';
import {divideCurrency, multiplyCurrency} from '../_utils/currency-math';

@Component({
    template: ''
})
export abstract class BaseCompComponent extends BaseModalComponent {
    creditForm: FormGroup;
    selectedCredit: number;
    full: number;
    half: number;
    max: number;
    percent: number;

    protected constructor(
        public alertController: AlertController,
        public modalController: ModalController
    ) {
        super(modalController);
    }

    async setPercent() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Percent',
            inputs: [
                {
                    type: 'number',
                    placeholder: 'Percent'
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                }, {
                    text: 'Confirm',
                    handler: (event) => {
                        const percent = event[0];
                        if (percent > 0 && percent <= 100) {
                            this.applyPercent(percent);
                        }
                    }
                }
            ]
        });
        await alert.present();
    }

    changedSelected() {
        if (!!this.selectedCredit && this.selectedCredit > 0) {
            if (this.selectedCredit == 100) {
                this.creditForm.controls.amount.setValue(this.full);
            } else {
                this.applyPercent(50);
            }
            this.percent = null;
        } else if (this.selectedCredit === -1) {
            this.setPercent();
        }
    }

    applyPercent(percent: number) {
        this.percent = percent;
        this.creditForm.controls.amount.setValue(multiplyCurrency(this.full, divideCurrency(percent, 100)));
    }

    manualEntry() {
        this.selectedCredit = null;
    }
}
