import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {PickupLocationMenuItemPrice} from '../_models/pickup-location-menu-item-price';

@Injectable({
    providedIn: 'root'
})
export class PickupLocationMenuItemPriceService extends BaseService<PickupLocationMenuItemPrice> {
    destination = 'pickup-location-menu-item-prices';
}
