import {Component, OnInit} from '@angular/core';
import {Constants} from '../../../_constants/constants';
import {BaseWidgetComponent} from '../base-widget.component';
import {CurrencyPipe, DatePipe, PercentPipe} from '@angular/common';
import {TabReportResult} from '../../../_models/report/tab-report-result';
import {TabReportService} from '../../../_services/tab-report.service';
import {UnderscoreConversionPipe} from '../../_pipes/underscore-conversion.pipe';
import {Sort} from '../../../_models/report/sort';

@Component({
    selector: 'bb-pie-chart-widget',
    templateUrl: './pie-chart-widget.component.html',
    styleUrls: ['./pie-chart-widget.component.scss'],
})
export class PieChartWidgetComponent extends BaseWidgetComponent implements OnInit {
    data: any;

    constructor(
        private tabReportService: TabReportService,
        currencyPipe: CurrencyPipe,
        percentPipe: PercentPipe,
        datePipe: DatePipe,
        underscoreConversionPipe: UnderscoreConversionPipe
    ) {
        super(currencyPipe, percentPipe, datePipe, underscoreConversionPipe);
    }

    ngOnInit() {
    }

    refresh() {
        this.loading = true;
        this.dataLoading.emit();
        const sort = new Sort({
            column: this.tabReport.columns[1],
            sortDirection: 'DESC'
        });

        this.tabReportService.run(this.tabReport.id, 0, 100, this.startDate, this.endDate,
            sort, this.selectedCategories, this.selectedPickupLocations, true)
            .subscribe((trr: TabReportResult) => {
                if (trr.data.length > 0) {
                    const chart = {
                        labels: [],
                        datasets: [
                            {
                                ...Constants.CHART_CONFIG,
                                data: []
                            }
                        ]
                    };
                    const labelColumn = trr.columns[0];
                    const valueColumn = trr.columns[1];
                    for (const row of trr.data) {
                        const value = row.data[valueColumn.accessKey];
                        if (!!value) {
                            chart.labels.push(this.getRowValue(row, labelColumn));
                            chart.datasets[0].data.push(Number(value));
                        }
                    }
                    this.data = chart;
                } else {
                    this.data = null;
                }

                this.loading = false;
                this.dataReady.emit();
            });
    }
}
