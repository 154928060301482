<bb-card-loading *ngIf="loading || !tabReportResult"></bb-card-loading>
<ng-container *ngIf="!loading && !!tabReportResult">
    <ion-list>
        <ion-item *ngIf="!loading && (!tabReportResult.data || tabReportResult.data.length == 0)">
            No data.
        </ion-item>
        <ion-item *ngFor="let row of tabReportResult.data">
            <ion-label>
                {{getRowValue(row, tabReportResult.columns[0])}}
                <p *ngFor="let column of detailColumns">{{getRowValue(row, column)}}</p>
            </ion-label>
            <h3 slot="end">{{getRowValue(row, valueColumn)}}</h3>
        </ion-item>
    </ion-list>
</ng-container>
