import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {MenuItem} from '../_models/menu-item';
import {HttpHeaders} from '@angular/common/http';
import {Constants} from '../_constants/constants';

@Injectable({
    providedIn: 'root'
})
export class MenuItemService extends BaseService<MenuItem> {
    destination = 'menu-items';

    updateAvailability(id: number, availability: string) {
        return this.http.post(`${this.baseUrl()}/${id}/availability/${availability}`, {});
    }

    uploadImage(id: number, file: any, fileName: string) {
        const formData = new FormData();
        if (fileName) {
            formData.append('file', file, fileName);
        } else {
            formData.append('file', file);
        }

        return this.http.post(this.baseUrl() + '/' + id + '/image', formData);
    }

    rate(menuItemId: number, rating: number) {
        return this.http.put(`${this.baseUrl()}/${menuItemId}/rating/${rating}`, {});
    }

    getRating(menuItemId: any) {
        return this.http.get(`${this.baseUrl()}/${menuItemId}/rating/`);
    }

    create(menuItem: MenuItem) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409,424');
        return this.http.post(this.baseUrl(), menuItem, {headers});
    }

    update(menuItem: MenuItem) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409,424');
        return this.http.put(this.baseUrl(), menuItem, {headers});
    }
}
