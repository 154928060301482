<bb-card-loading *ngIf="loading"></bb-card-loading>
<ion-text color="danger" *ngIf="error">Could not load widget.</ion-text>
<ng-container *ngIf="!loading">
    <div class="value-container">{{value}}</div>

    <div *ngIf="!!change" class="comparison-container" [class.up]="change > 0" [class.down]="change < 0">
        <ion-icon [name]="changeDirection()" class="comparison-icon"></ion-icon>
        <span class="comparison-value">{{change | percent}}</span>
    </div>
</ng-container>
