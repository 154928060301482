import {Inject, Injectable} from '@angular/core';
import {LibConfig, LibConfigService} from '../lib.config';
import {HttpClient} from '@angular/common/http';
import {PickupLocation} from '../_models/pickup-location';
import {ToastService} from './toast.service';
import {Device, DeviceId} from '@capacitor/device';
import {BaseObservableService} from './base-obeservable-service';
import {Address} from '../_models/address';
import {Geolocation} from '@capacitor/geolocation';
import {LoadingService} from './loading.service';
import {ActionSheetController, NavController, Platform} from '@ionic/angular';
import {formatDistance} from '../_utils/geolocation-utils';

@Injectable({
    providedIn: 'root'
})
export class PickupLocationService extends BaseObservableService<PickupLocation> {
    destination = 'pickup-locations';

    constructor(
        @Inject(LibConfigService) config: LibConfig,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private navController: NavController,
        private actionSheetController: ActionSheetController,
        private platform: Platform,
        http: HttpClient
    ) {
        super(http, config);
    }

    refresh() {
        if (!!this.currentValue && !!this.currentValue.id) {
            this.getSilent(this.currentValue.id).subscribe((o: PickupLocation) => this.setCurrent(new PickupLocation(o)));
        }
    }

    findByOrganizationId(orgId: number, latitude = null, longitude = null) {
        return this.http.get(`${this.baseUrl()}?orgId=${orgId}${!!latitude ? '&latitude=' + latitude : ''}${!!longitude
            ? '&longitude=' + longitude
            : ''}`);
    }

    uploadDirectionImage(pickupLocationId: number, file: any, fileName: string) {
        const formData = new FormData();
        if (fileName) {
            formData.append('file', file, fileName);
        } else {
            formData.append('file', file);
        }
        return this.http.post(`${this.baseUrl()}/${pickupLocationId}/image`, formData);
    }

    updateStatus(id: number, status: string) {
        return this.http.put(`${this.baseUrl()}/${id}/status/${status}`, {});
    }

    updateActiveEvent(id: number, organizationEventId: number) {
        let url = `${this.baseUrl()}/${id}/active-event`;
        if (!!organizationEventId) {
            url += `/${organizationEventId}`;
        }
        return this.http.put(url, {});
    }

    updatePrinterOptions(id: number, printAllOrders: boolean) {
        return this.http.put(`${this.baseUrl()}/${id}/print-options/${printAllOrders}`, {});
    }

    updateMobileAddress(address: Address, id: number) {
        return this.http.put(`${this.baseUrl()}/${id}/address`, address);
    }

    async setCurrent(pickupLocation: PickupLocation) {
        const current = this.currentSubject.getValue();
        super.setCurrent(pickupLocation);
        if (!!current && !!pickupLocation && current.id === pickupLocation.id && pickupLocation.status !== current.status) {
            if (pickupLocation.status === 'ACTIVE') {
                await this.toastService.success(`${pickupLocation.name} is open for orders.`);
            } else {
                await this.toastService.message(`${pickupLocation.name} is currently not taking online orders.`, 'secondary');
            }
        }
    }

    searchByBounds(minLatitude: number, maxLatitude: number, minLongitude: number,
                   maxLongitude: number, latitude: number, longitude: number
    ) {
        return this.http.get(`${this.baseUrl()}/bounds/${minLatitude}/${maxLatitude}/${minLongitude}/${maxLongitude}/${latitude}/${longitude}`);
    }

    search(searchString: string, latitude: number, longitude: number) {
        return this.http.get(`${this.baseUrl()}/search/${searchString}` +
            (!!latitude ? '?latitude=' + latitude + '&longitude=' + longitude : ''));
    }

    proximitySearch(latitude: number, longitude: number, maxDistance: number) {
        return this.http.get(`${this.baseUrl()}/proximity?latitude=${latitude}&longitude=${longitude}&maxDistance=${maxDistance}`);
    }

    getActiveOrders(pickupLocationId: number) {
        return this.http.get(this.baseUrl() + `/${pickupLocationId}/orders/active`);
    }

    saveDevice(pickupLocationId: number, pushToken: string) {
        Device.getId().then((deviceId: DeviceId) => {
            this.http.put(`${this.baseUrl()}/${pickupLocationId}/device`, {uuid: deviceId.identifier, pushToken}).subscribe(() => {
                console.log('device saved');
            });
        });
    }

    view(pickupLocationId: number) {
        return this.http.put(`${this.baseUrl()}/${pickupLocationId}/view`, {});
    }

    latestViews() {
        return this.http.get(`${this.baseUrl()}/view`);
    }

    buildMarker(map, pickupLocation: PickupLocation) {
        return {
            position: {
                lat: !!pickupLocation.address ? pickupLocation.address.latitude : pickupLocation.latitude,
                lng: !!pickupLocation.address ? pickupLocation.address.longitude : pickupLocation.longitude
            },
            map,
            label: {
                color: '#ffffff',
                text: `${pickupLocation.organizationName}
                       ${pickupLocation.name}`,
                className: 'bb-marker-label',
                fontWeight: '700'
            },
            title: pickupLocation.name,
            options: {
                animation: google.maps.Animation.DROP,
                icon: {
                    url: 'assets/maps/map-' + (pickupLocation.status === 'ACTIVE' ? 'active' : 'inactive') + '.png',
                    scaledSize: {
                        width: 27,
                        height: 43,
                        widthUnit: 'px',
                        heightUnit: 'px'
                    }
                },
            },
            pickupLocation
        };
    }

    async selectPickupLocation(organizationId: number) {
        this.loadingService.present();
        let pickupLocations = [];

        let currentPosition;
        try {
            if (!this.platform.is('ios')) {
                await Geolocation.checkPermissions();
            }
            currentPosition = await Geolocation.getCurrentPosition();
        } catch (error) {
            console.log(error);
        }

        if (!currentPosition) {
            pickupLocations = await this.findByOrganizationId(organizationId).toPromise() as PickupLocation[];
        } else {
            pickupLocations = await this.findByOrganizationId(organizationId,
                currentPosition.coords.latitude,
                currentPosition.coords.longitude)
                .toPromise() as PickupLocation[];
        }
        this.loadingService.dismiss();
        if (pickupLocations.length === 0) {
            await this.toastService.error('No locations were found.');
        } else if (pickupLocations.length === 1) {
            await this.navController.navigateForward(`/menu/${pickupLocations[0].id}`);
        } else {
            const buttons = [];

            pickupLocations.forEach(pl => buttons.push({
                text: `${pl.name}${formatDistance(pl.distance)}`,
                handler: () => {
                    this.navController.navigateForward(`/menu/${pl.id}`);
                }
            }));

            buttons.push({
                text: 'Cancel',
                role: 'cancel'
            });

            const actionSheet = await this.actionSheetController.create({
                header: 'Locations',
                cssClass: 'bb-action-sheet',
                buttons
            });
            await actionSheet.present();
        }
    }
}
