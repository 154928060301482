import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'modal-header',
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
    @Input() title: string;
    @Input() subHeader: string;
    @Input() closeValue: any;
    @Input() color = 'primary';
    modalState: any;

    constructor(private modalController: ModalController) {
    }

    async close() {
        if (!!this.closeValue) {
            await this.modalController.dismiss(this.closeValue);
        } else {
            await this.modalController.dismiss();
        }
    }
}
