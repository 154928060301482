<ion-grid>
    <ion-row>
        <ion-col size="10">
            <ion-card>
                <ion-card-header>
                    <ion-card-title>
                        Ratings Count: {{ getTotal() }}
                    </ion-card-title>
                </ion-card-header>
            </ion-card>
        </ion-col>

        <ion-col size-xs="2" class="page-header ion-text-right">
            <ion-button color="dark" (click)="close()">
                <ion-icon slot="icon-only" name="close-outline"></ion-icon>
            </ion-button>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col size-xs="12" size-lg="6">
            <ion-card>
                <ion-card-content>
                    <div class="donut-container">
                        <p-chart type="doughnut" [data]="summaryChart" (click)="clicked($event)" #donutChart></p-chart>
                        <div class="donut-middle">
                            <div><p>Total:</p>{{getTotal()}}</div>
                        </div>
                    </div>
                </ion-card-content>
            </ion-card>
        </ion-col>

        <ion-col size-xs="12" size-lg="6">
            <ion-card>
                <ion-card-content>
                    <p-chart type="bar" [data]="monthlyChart" [options]="monthlyChartOptions"></p-chart>
                </ion-card-content>
            </ion-card>
        </ion-col>

        <ion-col size-xs="12" size-lg="6">
            <ion-segment class="read-filter" (ionChange)="loadPageData(true)" [(ngModel)]="readFilter" mode="ios">
                <ion-segment-button value="all">
                    <ion-label>All</ion-label>
                </ion-segment-button>
                <ion-segment-button value="unread">
                    <ion-label>Unread</ion-label>
                </ion-segment-button>
                <ion-segment-button value="read">
                    <ion-label>Read</ion-label>
                </ion-segment-button>
            </ion-segment>
        </ion-col>

        <ion-col size-xs="12" size-lg="6">
            <ion-segment class="response-filter" (ionChange)="loadPageData(true)" [(ngModel)]="responseFilter" mode="ios">
                <ion-segment-button value="all">
                    <ion-label>All</ion-label>
                </ion-segment-button>
                <ion-segment-button value="none">
                    <ion-label>No Response</ion-label>
                </ion-segment-button>
                <ion-segment-button value="respondedTo">
                    <ion-label>Responded To</ion-label>
                </ion-segment-button>
            </ion-segment>
        </ion-col>

        <ion-col size-xs="12">
            <ion-button (click)="markAllRead()" color="success">Mark All Read</ion-button>
        </ion-col>

        <ion-col *ngFor="let rating of ratings" size-xs="12" size-lg="6">
<!--            <bb-display-rating [rating]="rating" (click)="ratingClicked(rating)"></bb-display-rating>-->
        </ion-col>
    </ion-row>
    <ion-row *ngIf="!!ratings && ratings.length === 0">
        <ion-col>
            <ion-item>
                <ion-label>No ratings meet your criteria.</ion-label>
            </ion-item>
        </ion-col>
    </ion-row>
    <ion-row *ngIf="ratingsLoading">
        <ion-col class="ion-justify-content-center">
            <ion-spinner name="bubbles" class="ratings-loading"></ion-spinner>
        </ion-col>
    </ion-row>
</ion-grid>
