import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'bb-card-loading',
  templateUrl: './card-loading.component.html',
  styleUrls: ['./card-loading.component.css'],
})
export class CardLoadingComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
