import {Directive, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';

@Directive({
    selector: '[bbCurrencyMask]'
})
export class CurrencyMaskDirective implements OnInit {
    stripChars = new RegExp('[^0-9|.]', 'g');
    revert: number;

    constructor(
        public el: ElementRef,
        public renderer: Renderer2
    ) {
    }

    ngOnInit() {
        if (!!this.el.nativeElement.value) {
            this.format(this.el.nativeElement.value);
        }
    }

    @HostListener('input', ['$event.target.value']) onInput() {
        this.format(this.el.nativeElement.value);
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        event.preventDefault();
        this.format(event.clipboardData.getData('text/plain'));
    }

    @HostListener('keydown', ['$event']) onKeyDown(e) {
        if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
            // Allow: arrow keys
            (e.keyCode === 37) ||
            (e.keyCode === 38) ||
            (e.keyCode === 39) ||
            (e.keyCode === 40) ||
            // Allow: Ctrl+A
            (e.keyCode === 65 && e.ctrlKey === true) ||
            // Allow: Ctrl+C
            (e.keyCode === 67 && e.ctrlKey === true) ||
            // Allow: Ctrl+V
            (e.keyCode === 86 && e.ctrlKey === true) ||
            // Allow: Ctrl+X
            (e.keyCode === 88 && e.ctrlKey === true)) {
            // let it happen, don't do anything
            return;
        }

        if (e.key === '-') {
            this.revert = this.el.nativeElement.value;
        }

        if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', '-'].indexOf(e.key) === -1) {
            e.preventDefault();
        }
    }

    @HostListener('keyup', ['$event']) onKeyUp() {
        if (!this.el.nativeElement.value && !!this.revert) {
            this.format(this.revert.toString(10));
        }
        this.revert = null;
    }

    format(val: string) {
        const numberFormat = String(val).replace(this.stripChars, '');
        const decIdx = numberFormat.indexOf('.');
        setTimeout(() => {
            this.renderer.setProperty(this.el.nativeElement, 'value', (decIdx > -1) ? numberFormat.substring(0, decIdx + 3) : val);
        });
    }
}
