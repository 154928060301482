import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderService} from '../../_services/order.service';
import {Order} from '../../_models/order';
import {ToastService} from '../../_services/toast.service';
import {LoadingService} from '../../_services/loading.service';
import {SubscriberComponent} from '../subscriber.component';
import {PickupLocationMenuService} from '../../_services/pickup-location-menu.service';
import {PickupLocationMenu} from '../../_models/pickup-location-menu';
import {Availabilities} from '../../_constants/availabilities';

@Component({
    selector: 'bb-order-type-selection',
    templateUrl: './order-type-selection.component.html',
    styleUrls: ['./order-type-selection.component.scss']
})
export class OrderTypeSelectionComponent extends SubscriberComponent implements OnInit {
    @Input() orderType: string;
    @Output() orderTypeChange = new EventEmitter<string>();
    @Input() type = 'ALL';
    @Input() order: Order;
    @Input() hideTogo = false;
    @Input() hideInHouse = false;
    pickupLocationMenu: PickupLocationMenu;
    availabilities = Availabilities;

    constructor(
        private orderService: OrderService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private pickupLocationMenuService: PickupLocationMenuService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.subscribe(this.pickupLocationMenuService.current.subscribe(async p => {
            this.pickupLocationMenu = p;
            if (!!p) {
                if (!p.allowInHouse && p.allowTogo) {
                    this.orderType = Availabilities.TOGO;
                } else if (p.allowInHouse && !p.allowTogo) {
                    this.orderType = Availabilities.IN_HOUSE;
                }
            }
            await this.changeDetectorRef.detectChanges();
        }));
    }

    async select(type) {
        this.orderService.nextType(type);
        this.orderTypeChange.emit(type);
    }
}
