<modal-header [title]="organization.name"></modal-header>
<ion-content>
    <ion-grid>
        <ion-row class="ion-justify-content-center content">
            <ion-col *ngIf="organization.logoAddress != null" size-xs="12" class="ion-justify-content-center content">
                <img [src]="organization.logoAddress" class="org-logo" alt="">
            </ion-col>
        </ion-row>

        <ion-row *ngIf="organization.rating">
            <ion-col class="ion-justify-content-center content">
                Your latest rating:
                <bb-rating size="small" [rating]="organization.rating.rating"></bb-rating>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size-xs="12">
                <ion-card class="ion-justify-content-center">
                    <ion-card-content>
                        <span>{{ organization.description }}</span>
                    </ion-card-content>
                </ion-card>
            </ion-col>

            <ion-col size-xs="12">
                <ion-card class="ion-justify-content-center">
                    <ion-card-content>
                        <bb-address [address]="organization.address"></bb-address>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

<modal-footer submitLabel="Menu" (submitClicked)="openMenu()"></modal-footer>
