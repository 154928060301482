import {BaseFormModel} from './base-form-model';
import {Person} from './person';
import {OrderItem} from './order-item';

export class MembershipTokenAudit extends BaseFormModel<MembershipTokenAudit> {
    id: number;
    orgId: number;
    orderItem: OrderItem;
    count: number;
    createdBy: Person;
    createDate: Date;
    action: string;
    event: string;
    comment: string;

    init(): void {
        this.orderItem = !!this.orderItem ? new OrderItem(this.orderItem) : null;
    }
}
