<modal-header title="Invite User"></modal-header>

<ion-content>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <ion-grid>
            <ion-row [formGroup]="formGroup">
                <ion-col size-xs="12">
                    <ion-item>
                        <ion-label position="floating">Email</ion-label>
                        <ion-input type="email" formControlName="email" (ionChange)="emailChange()" #firstInput></ion-input>
                    </ion-item>
                </ion-col>
                <ion-col size-xs="12" size-md="6">
                    <ion-item>
                        <ion-label position="floating">First Name</ion-label>
                        <ion-input id="firstName" data-cy="first-name-input" name="firstName" type="text" formControlName="firstName"
                                   [disabled]="!!user"
                                   required></ion-input>
                    </ion-item>
                </ion-col>
                <ion-col size-xs="12" size-md="6">
                    <ion-item>
                        <ion-label position="floating">Last Name</ion-label>
                        <ion-input id="lastName" data-cy="last-name-input" name="lastName" type="text" formControlName="lastName"
                                   [disabled]="!!user" required></ion-input>
                    </ion-item>
                </ion-col>
                <ion-col size-xs="12">
                    <ion-item>
                        <ion-label position="floating">Phone</ion-label>
                        <ion-input id="mobile" data-cy="person-mobile-input" name="mobile" type="tel" bbPhoneMask inputmode="numeric" maxlength="14"
                                   minlength="14"
                                   [disabled]="!!user"
                                   formControlName="mobile"></ion-input>
                    </ion-item>
                </ion-col>
                <ion-col *bbSecured="[featureKeys.ORG_OWNER, featureKeys.ORG_ADMIN]" size-xs="6">
                    <ion-item>
                        <ion-label position="stacked">Admin</ion-label>
                        <ion-toggle class="ion-toggle-small" formControlName="admin"></ion-toggle>
                    </ion-item>
                </ion-col>

                <ion-col *bbSecured="featureKeys.ORG_OWNER" size-xs="6">
                    <ion-item>
                        <ion-label position="stacked">Owner</ion-label>
                        <ion-toggle class="ion-toggle-small" formControlName="owner"></ion-toggle>
                    </ion-item>
                </ion-col>

                <ion-col size-xs="12">
                    <ion-item>
                        <ion-label position="floating">Roles</ion-label>
                        <ion-select formControlName="roles" interface="popover" multiple="true">
                            <ion-select-option *ngFor="let r of organization.roles" [value]="r">{{r.name}}</ion-select-option>
                        </ion-select>
                    </ion-item>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>

<modal-footer [disableSubmit]="formGroup.invalid" (submitClicked)="onSubmit()"></modal-footer>
