import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {debounce} from 'lodash';
import Packery from 'packery';

@Component({
    selector: 'bb-masonry-grid',
    templateUrl: './masonry-grid.component.html',
    styleUrls: ['./masonry-grid.component.scss'],
})
export class MasonryGridComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() animate = true;
    @Input() wait = false;
    @Input() gridCssClass = 'grid-max-2';
    @Input() horizontalOrder = false;
    @Input() prependChanges = false;

    @Output() gridLoaded: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('grid') grid: ElementRef;

    changes: MutationObserver;
    masonry: Packery;
    addedNodes = [];
    ready = false;

    constructor() {
        this.handleDomChanges = debounce(this.handleDomChanges, 100);
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.init();

        this.changes = new MutationObserver((mutations: MutationRecord[]) => {
                mutations.forEach((mutation: MutationRecord) => this.domChange(mutation));
            }
        );

        this.changes.observe(this.grid.nativeElement, {subtree: false, childList: true});
    }

    ngOnDestroy() {
        this.masonry.destroy();
        this.changes.disconnect();
    }

    async domChange(mutation) {
        this.addedNodes = [...this.addedNodes, mutation.addedNodes];
        this.handleDomChanges();
    }

    handleDomChanges() {
        if (!!this.masonry) {
            this.addedNodes.forEach(n => {
                if (this.prependChanges) {
                    this.masonry.prepended(n);
                    this.layout();
                } else {
                    this.masonry.appended(n);
                    this.layout();
                }
            });
            this.addedNodes = [];
        }
        this.ready = true;
    }

    init() {
        const options = {
            itemSelector: 'bb-masonry-grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true,
            transitionDuration: this.animate ? '0.4s' : 0,
            resize: false,
            horizontalOrder: this.horizontalOrder
        };

        this.masonry = new Packery(this.grid.nativeElement, options);
        this.gridLoaded.emit();
    }

    @HostListener('window:resize', ['$event'])
    windowResize() {
        this.layout();
    }

    layout() {
        if (!!this.masonry) {
            setTimeout(() => {
                this.masonry.layout();
            });
        } else {
            this.init();
        }
    }
}
