import {Injectable} from '@angular/core';

import {Resolve} from '@angular/router';
import {UserService} from '../_services/user.service';

@Injectable()
export class LoggedInUserResolver implements Resolve<any> {
    constructor(private usersService: UserService) {
    }

    resolve() {
        return this.usersService.fromToken();
    }
}
