import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';
import {debounce} from 'lodash';
import {LoadingService} from '../../_services/loading.service';
import {PickupLocationService} from '../../_services/pickup-location.service';
import {PickupLocation} from '../../_models/pickup-location';

@Component({
    selector: 'bb-search-organizations',
    templateUrl: './search-organizations.component.html',
    styleUrls: ['./search-organizations.component.css']
})
export class SearchOrganizationsComponent extends BaseModalComponent implements OnInit {
    @Input() latitude: number;
    @Input() longitude: number;
    searchString: string;
    pickupLocations: PickupLocation[];

    constructor(
        modalController: ModalController,
        private loadingService: LoadingService,
        private pickupLocationService: PickupLocationService
    ) {
        super(modalController);
        this.search = debounce(this.search, 250);
    }

    ngOnInit(): void {
    }

    search() {
        if (this.searchString.length > 0) {
            this.pickupLocationService.search(this.searchString, this.latitude, this.longitude).subscribe((p: PickupLocation[]) => {
                this.pickupLocations = p;
            });
        } else {
            this.pickupLocations = [];
        }
    }

    async openMenu(pickupLocation) {
        await this.close(pickupLocation);
    }
}
