import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {BaseModalComponent} from '../base-modal.component';
import {Sortable} from '../../_models/sortable';
import {reorder} from '../../_utils/sortable-utils';

@Component({
    selector: 'bb-sort-modal',
    templateUrl: './sort-modal.component.html',
    styleUrls: ['./sort-modal.component.scss']
})
export class SortModalComponent extends BaseModalComponent {
    @Input() items: Sortable[];

    constructor(modalController: ModalController) {
        super(modalController);
    }

    reorder(event) {
        reorder(this.items, event);
    }

    setSortOrders() {
        this.items.forEach((item: Sortable, index) => {
            item.sortOrder = index;
        });
    }

    onSubmit() {
        this.setSortOrders();
        this.close(this.items);
    }
}
