import {PickupLocation} from './pickup-location';
import {Menu} from './menu';
import {PickupLocationMenuItemPrice} from './pickup-location-menu-item-price';
import {BaseFormModel} from './base-form-model';

export class PickupLocationMenu extends BaseFormModel<PickupLocationMenu> {
    id: number;
    menu: Menu;
    pickupLocation: PickupLocation;
    menuItemPrices: PickupLocationMenuItemPrice[];
    allowInHouse: boolean;
    allowTogo: boolean;
    parentId: number;

    init() {
        this.menu = new Menu(this.menu);
        this.pickupLocation = new PickupLocation(this.pickupLocation);

        const temp = [];
        if (!!this.menuItemPrices) {
            this.menuItemPrices.forEach(mi => {
                temp.push(new PickupLocationMenuItemPrice(mi));
            });
            this.menuItemPrices = temp;
        }
    }
}
