<ng-container *ngIf="!!tab">
    <div class="order-container">
        <bb-display-order *ngIf="!!order"
                          [order]="order"
                          [allowGrid]="true"
                          [editable]="editable"
                          [availableOrderComps]="availableOrderComps"
                          [showImageOverride]="true"
                          (addCredit)="addCredit.emit($event)"
                          (removeCredit)="removeCredit.emit($event)"
                          (removeTokens)="removeTokens.emit($event)"
                          (openItemSummary)="openEditOrderItemSummary($event)"
                          (removeItemSummary)="removeItemSummary($event)"
                          (addToSummary)="addItem($event)"
                          (removeFromSummary)="removeItem($event)">
        </bb-display-order>
    </div>

    <ion-item lines="none"
              class="subtotal">
        <ion-grid>
            <ion-row>
                <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                    Subtotal:
                </ion-col>
                <ion-col class="ion-text-end">{{ tab.subtotal | currency }}</ion-col>
            </ion-row>
            <ion-row *ngIf="!!tab.paymentInformation.tokenPaymentsTotal">
                <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                    Tokens Used:
                </ion-col>
                <ion-col class="ion-text-end">
                    <bb-tokens [count]="tab.paymentInformation.tokensUsed"></bb-tokens>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="!!tab.paymentInformation.tokenPaymentsTotal">
                <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                    Tokens Value:
                </ion-col>
                <ion-col class="ion-text-end">{{ tab.paymentInformation.tokenPaymentsTotal | currency }}</ion-col>
            </ion-row>
            <ion-row *ngIf="tab.paymentInformation.payments > 0 && tab.paymentInformation.remaining > 0">
                <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                    Due:
                </ion-col>
                <ion-col class="ion-text-end">{{ tab.paymentInformation.remaining | currency }}</ion-col>
            </ion-row>
            <ion-row
                    *ngIf="displayTipSelection && organization.allowTips && !!tab && maxTip != 0 && managerApp && amount === tab.paymentInformation.remaining && !this.tab.paymentInformation.depositsRemaining">
                <ion-col size-xs="10">
                    <h1>Select Tip to Proceed to Payment</h1>
                </ion-col>
                <ion-col size-xs="2" class="ion-text-end">
                    <a (click)="split()">Split</a>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="amount !== tab.paymentInformation.remaining">
                <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                    Paying:
                </ion-col>
                <ion-col class="ion-text-end"><a (click)="split()">{{ amount | currency }}</a></ion-col>
            </ion-row>
        </ion-grid>
    </ion-item>

    <div class="tip"
         *ngIf="displayTipSelection && organization.allowTips && !!tab && maxTip != 0">
        <bb-tip [max]="maxTip"
                [tabTotal]="tipAgainstAmount"
                (tipChange)="tipChanged($event)">
        </bb-tip>
    </div>

    <ion-item lines="none" *ngIf="!!taxes || surcharge > 0">
        <ion-grid class="confirm-text">
            <ng-container *ngIf="!tab.taxExemptionId && !editTaxExemption && !!taxes">
                <ion-row *ngFor="let tax of taxes">
                    <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                        {{ tax.name }} ({{ tax.taxRate }}%):
                    </ion-col>
                    <ion-col class="ion-text-end tax-container">
                        <span class="tax-name">{{ tax.tax | currency }}</span>
                        <ng-container *bbSecured="featureKeys.TAX_EXEMPT_APPLY">
                            <ion-icon *ngIf="managerApp && !paymentMethod && organization.allowTaxExempt"
                                      name="close-circle-outline"
                                      class="tax-exempt"
                                      (click)="openEditTaxExemption()"></ion-icon>
                        </ng-container>
                    </ion-col>
                </ion-row>
            </ng-container>
            <ion-row *ngIf="editTaxExemption">
                <ion-col offset-sm="8" offset-md="9" class="ion-text-end">
                    <bb-edit-tax-exemption [tab]="tab" (close)="closeEditTaxExemption($event)"></bb-edit-tax-exemption>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="!!tab.taxExemptionId && !editTaxExemption">
                <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                    Tax Exemption ID:
                </ion-col>
                <ion-col class="ion-text-end">
                    <bb-secured feature="{{featureKeys.TAX_EXEMPT_APPLY}}">
                        <a (click)="openEditTaxExemption()">{{ tab.taxExemptionId }}</a>
                        <span notAuthorized>Tax Exempt</span>
                    </bb-secured>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="tab.membershipFee">
                <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                    Membership Renewal Fees:
                </ion-col>
                <ion-col class="ion-text-end">{{ tab.membershipFee | currency }}</ion-col>
            </ion-row>

            <ion-row *ngIf="tab.mobileFee">
                <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                    Mobile Order Fee:
                </ion-col>
                <ion-col class="ion-text-end">{{ tab.mobileFee | currency }}</ion-col>
            </ion-row>

            <ion-row *ngIf="surcharge > 0">
                <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                    Surcharge:
                </ion-col>
                <ion-col class="ion-text-end">{{ surcharge | currency }}</ion-col>
            </ion-row>

            <ng-container *ngIf="tipPoolItems && tipPoolItems.length > 0">
                <ion-row *ngFor="let item of tipPoolItems; trackBy: getId">
                    <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                        {{ item.menuItem.name }}:
                    </ion-col>
                    <ion-col class="ion-text-end">{{ item.calcTotal() | currency }}</ion-col>
                </ion-row>
            </ng-container>

            <ng-container *ngIf="tipPoolSelectionItems && tipPoolSelectionItems.length > 0">
                <ion-row *ngFor="let selectionItem of tipPoolSelectionItems; trackBy: getId">
                    <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                        {{ selectionItem.orderItem.menuItem.name }}:
                    </ion-col>
                    <ion-col class="ion-text-end">{{ selectionItem.orderItem.total | currency }}</ion-col>
                </ion-row>
            </ng-container>

            <ion-row *ngIf="tab.paymentInformation.orderItemCredits > 0">
                <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                    Item Credits Total:
                </ion-col>
                <ion-col class="ion-text-end">
                    <ion-text color="success">({{ tab.paymentInformation.orderItemCredits | currency }})</ion-text>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-item>

    <ion-item *ngIf="!!tab.credits && tab.credits.length > 0" lines="none">
        <ion-grid>
            <ion-row>
                <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                    Tab Comps:
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-item>

    <ion-item *ngFor="let credit of tab.credits" lines="none">
        <ion-grid class="confirm-text">
            <ion-row>
                <ion-col size-xs="12" class="ion-text-end">
                    <div class="comp-container">
                        <div class="comp-label ion-text-end">
                            <span *ngIf="!!credit.compDefinition">{{ credit.compDefinition.name }} <span
                                    *ngIf="credit.compDefinition.type === compDefinitionTypes.PERCENT">
                                ({{ credit.compDefinition.amount }}%)</span></span>
                            <p>({{ credit.amount | currency }})</p>
                        </div>
                        <div *ngIf="editable" class="comp-actions">
                            <ion-toolbar>
                                <ion-buttons>
                                    <ion-button color="dark" (click)="deleteCredit.emit(credit)">
                                        <ion-icon name="trash-outline" slot="icon-only"></ion-icon>
                                    </ion-button>
                                </ion-buttons>
                            </ion-toolbar>
                        </div>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-item>

    <ng-container *ngIf="editable">
        <ion-item *ngIf="!!tab.giftCardPayments && tab.giftCardPayments.length > 0" lines="none">
            <ion-grid>
                <ion-row>
                    <ion-col size-xs="8" size-sm="8" size-md="9" class="ion-text-end">
                        Gift Cards:
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>
        <ion-item *ngFor="let payment of tab.giftCardPayments" lines="none">
            <ion-grid>
                <ion-row>
                    <ion-col size-xs="12" class="ion-text-end">
                        <span class="gift-card-number">************{{ payment.cardLastFour }}</span>
                        <span class="gift-card-balance">{{ payment.amount | currency }}</span>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-buttons slot="end">
                <ion-button color="dark" (click)="deleteGiftCardPayment.emit(payment)">
                    <ion-icon name="trash-outline" slot="icon-only"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-item>
    </ng-container>

    <ion-item class="total" lines="none">
        <ion-grid>
            <ion-row>
                <ion-col size-xs="5" size-sm="8" size-md="9" class="ion-text-end">
                    Total:
                </ion-col>
                <ion-col class="ion-text-end">{{ total() | currency }}</ion-col>
            </ion-row>

            <ng-container *ngIf="tab.paymentInformation.depositsRemaining">
                <ion-row>
                    <ion-col size-xs="5" size-sm="8" size-md="9" class="ion-text-end">
                        <ion-text color="tertiary">
                            Deposits:
                        </ion-text>
                    </ion-col>
                    <ion-col class="ion-text-end">
                        <ion-text color="tertiary">{{ tab.paymentInformation.depositsRemaining | currency }}</ion-text>
                    </ion-col>
                </ion-row>

                <ion-row>
                    <ion-col size-xs="5" size-sm="8" size-md="9" class="ion-text-end">
                        Total Due:
                    </ion-col>
                    <ion-col class="ion-text-end">{{ totalDue() | currency }}</ion-col>
                </ion-row>
            </ng-container>
        </ion-grid>
    </ion-item>

    <ion-item *ngIf="!!applyingGiftCard" class="total" lines="none">
        <ion-grid>
            <ion-row>
                <ion-col size-xs="5" size-sm="8" size-md="9" class="ion-text-end">
                    Gift Card Balance:
                    <p class="sub-header">After Payment</p>
                </ion-col>
                <ion-col class="ion-text-end">{{ subtractCurrency(applyingGiftCard.balance, total()) | currency }}</ion-col>
            </ion-row>
        </ion-grid>
    </ion-item>
</ng-container>
