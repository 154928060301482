import {BaseFormModel} from '../base-form-model';

export class CashSessionSummary extends BaseFormModel<CashSessionSummary> {
    id: number;
    organizationId: number;
    startTime: Date;
    startedByName: Date;
    drawerCount: number;
    cashSalesNet: number;
    cashPrincipal: number;
    cashTipIn: number;
    cashTipOut: number;
    drawerStartAmount: number;
    drawerEndAmount: number;
    drawerDelta: number;
    cashExpectedTotal: number;
    chargePrincipal: number;
    chargeTip: number;
    chargeTotal: number;
    refundPrincipal: number;
    refundTip: number;
    refundTotal: number;
    giftCardSalesCharges: number;
    giftCardSalesRefunds: number;
    totalCharges: number;
    giftCardCount: number;
    giftCardPrincipal: number;
    giftCardTip: number;
    giftCardTotal: number;
    tabCreditCount: number;
    tabCreditAmount: number;
    orderItemCreditCount: number;
    orderItemCreditAmount: number;
    creditCount: number;
    creditAmount: number;
    tipAdjustments: number;
    tipTotal: number;
    cashTotal: number;
    total: number;
    deposits: number;
    payrollTips: number;
    totalPaidTips: number;
    remainingTips: number;
    openTabsTotal: number;
    status: string;
    openTabs: number;
}
