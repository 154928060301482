import {BaseFormModel} from './base-form-model';
import {Person} from './person';
import {MembershipProgram} from './membership-program';
import {CardSummary} from './card-summary';
import {Login} from './login';
import {MembershipTokenPurse} from './membership-token-purse';
import {MembershipRenewal} from './membership-renewal';
import {User} from './user';
import {LimitedAvailability} from './limited-availability';

export class Membership extends BaseFormModel<Membership> {
    id: number;
    orgId: number;
    activeMembershipProgram: MembershipProgram;
    renewals: MembershipRenewal[];
    person: Person;
    user: User;
    cardOnFile: CardSummary;
    status: string;
    denyCardRequest: boolean;
    autoRenewal: boolean;
    createdBy: Person;
    createDate: Date;
    expirationDate: Date;
    purses: MembershipTokenPurse[];
    claimCode: string;
    availableComps: LimitedAvailability[];
    availableTokenGroups: LimitedAvailability[];
    memberNumber: number;

    membershipProgramId: number;
    cardSummary: CardSummary;
    login: Login;
    deviceId: string;

    renewing = false;

    get availableTokens(): number {
        return !!this.purses ? this.purses.filter(p => (!p.expirationDate || new Date(p.expirationDate) > new Date()) && p.count > 0)
            .reduce((total, p) => total + p.count, 0) : 0;
    }

    init(): void {
        let temp = [];
        if (!!this.renewals) {
            this.renewals.forEach(r => {
                temp.push(new MembershipRenewal(r));
            });
            this.renewals = temp;
        }

        temp = [];
        if (!!this.purses) {
            this.purses.forEach(p => {
                temp.push(new MembershipTokenPurse(p));
            });
            this.purses = temp;
        }

        this.activeMembershipProgram = !!this.activeMembershipProgram ? new MembershipProgram(this.activeMembershipProgram) : null;

        if (!!this.availableTokenGroups) {
            this.availableTokenGroups.forEach(t => t.currentUsed = 0);
        }
    }
}
