import {Component, Input} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {FormGroup} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {ToastService} from '../../_services/toast.service';
import {VenueService} from '../../_services/venue.service';
import {Venue} from '../../_models/venue';

@Component({
    selector: 'bb-edit-venue-modal',
    templateUrl: './edit-venue-modal.component.html',
    styleUrls: ['./edit-venue-modal.component.css']
})
export class EditVenueModalComponent extends BaseModalComponent {
    editVenueForm: FormGroup = new FormGroup({});
    @Input() venue: Venue;

    constructor(
        modalController: ModalController,
        private venueService: VenueService,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    onSubmit() {
        this.venueService.update(new Venue(this.editVenueForm.value)).subscribe(
            async (venue: Venue) => {
                await this.toastService.success('Information updated.');
                await this.close(venue);
            }
        );
    }

    formInitialized(form: FormGroup) {
        this.editVenueForm = form;
    }
}
