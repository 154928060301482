import {BaseFormModel} from './base-form-model';
import {DashboardWidgetTab} from './dashboard-widget-tab';
import {Sortable} from './sortable';

export class DashboardWidget extends BaseFormModel<DashboardWidget> implements Sortable {
    id: number;
    title: string;
    size: string;
    tabs: DashboardWidgetTab[];
    sortOrder: number;
}
