import {MenuTaxLabel} from '../_models/menu-tax-label';
import {TaxOverrides} from '../_constants/tax-overrides';
import {addCurrency, divideCurrency, multiplyCurrency} from './currency-math';
import {Tax} from '../_models/tax';

export function isTaxIncluded(taxOverride: string, taxLabel: MenuTaxLabel | Tax, paymentType: string) {
    if (!!taxOverride) {
        return taxOverride === TaxOverrides.INCLUDED;
    } else {
        return (((!paymentType || paymentType === 'NONE' || paymentType === 'MIX')
                && (taxLabel.taxIncludedCash && taxLabel.taxIncludedCharge && taxLabel.taxIncludedGiftCard)) ||
            (paymentType === 'CASH' && taxLabel.taxIncludedCash) ||
            (paymentType === 'CHARGE' && taxLabel.taxIncludedCharge) ||
            (paymentType === 'GIFT_CARD' && taxLabel.taxIncludedGiftCard));
    }
}

export function convertPercentage(percent: number) {
    return Math.round((percent / 100) * 10000) / 10000;
}

export function calculateTax(subtotal: number, taxRate: number) {
    return multiplyCurrency(convertPercentage(taxRate), subtotal);
}

export function includedTaxedAmount(subtotal: number, taxRate: number) {
    return divideCurrency(subtotal, addCurrency(1, convertPercentage(taxRate)));
}


