import {
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {IonPopover} from '@ionic/angular';

@Component({
    selector: 'bb-typeahead-select',
    templateUrl: './typeahead-select.component.html',
    styleUrls: ['./typeahead-select.component.scss'],
})
export class TypeaheadSelectComponent implements OnInit, OnChanges {
    @ViewChild('typeAheadPopover') typeAheadPopover: IonPopover;
    @ContentChild('displayTemplate') displayTemplate: TemplateRef<any>;

    @ViewChild('searchFilter') set searchFilter(element) {
        if (!!element) {
            setTimeout(async () => {
                await element.setFocus();
            }, 250);
        }
    }

    @Input() items = [];
    @Input() filterLabel = 'Filter';
    @Input() filterBy: string;
    @Input() display: string;
    @Input() value: any;
    @Input() event: any;
    @Input() width = 300;
    @Input() filter = '';
    @Input() externalFilter = false;
    @Input() returnFilter = false;

    @Output() closed: EventEmitter<any> = new EventEmitter();
    @Output() filterChange: EventEmitter<string> = new EventEmitter();

    filteredItems = [];
    isOpen = true;
    closing = false;

    constructor() {
    }

    ngOnInit() {
        this.filteredItems = [...this.items];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.items && !changes.items.firstChange) {
            this.filteredItems = [...this.items];
        }
    }

    filterItems() {
        if (!this.externalFilter) {
            if (!!this.filter) {
                const query = this.filter.toLowerCase();
                this.filteredItems = this.items.filter((item: any) => (!!this.filterBy
                    ? item[this.filterBy]
                    : item).toLowerCase().includes(query));
            } else {
                this.filteredItems = [...this.items];
            }
        }
        this.filterChange.emit(this.filter);
    }

    async handleKeyUp(e) {
        if (e.keyCode === 13) {
            if (!this.returnFilter) {
                if (this.filteredItems.length === 1) {
                    this.selectItem(this.filteredItems[0]);
                }
            } else {
                this.selectItem(this.filter);
            }
        }
    }

    selectItem(item) {
        this.value = item;
        this.isOpen = false;
        this.typeAheadPopover.dismiss();
        this.closed.emit(item);
    }
}
