import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';
import {IonicRouteStrategy} from '@ionic/angular';
import {Constants} from '../_constants/constants';

@Injectable()
export class BrewBillRouteReuseStrategy extends IonicRouteStrategy {

    routeStore = new Map<string, DetachedRouteHandle>();

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const path = route.routeConfig.path;
        return path && Constants.REUSE_ROUTES.includes(path);
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const path = !!route.routeConfig ? route.routeConfig.path : null;
        return (
            path && Constants.REUSE_ROUTES.includes(path) && !!this.routeStore.get(path)
        );
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.routeStore.set(route.routeConfig.path, handle);
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return false;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        const path = route.routeConfig.path;
        return this.routeStore.get(path);
    }
}
