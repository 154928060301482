import {BaseFormModel} from './base-form-model';
import {CustomerAreaPosition} from './customer-area-position';
import {PickupLocation} from './pickup-location';

export class CustomerArea extends BaseFormModel<CustomerArea> {
    id: number;
    name: string;
    positionLabel: string;
    positions: CustomerAreaPosition[];
    pickupLocations: PickupLocation[];
    orgId: number;

    public constructor(jsonInput: any) {
        super(jsonInput);
        Object.assign(this, jsonInput);

        this.positions = !!this.positions ? this.positions.map(p => new CustomerAreaPosition(p)) : [];
    }
}
