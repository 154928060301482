import {Injectable} from '@angular/core';
import {Menu} from '../_models/menu';
import {MenuService} from './menu.service';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {IFile} from '@ionic-native/file/ngx';

@Injectable({
    providedIn: 'root'
})
export class ImageCacheService {
    menu: Menu;
    CACHE_FOLDER = 'CACHED_IMG';
    directoryExists = false;

    cachedImages = {};

    constructor(private menuService: MenuService) {
        this.menuService.current.subscribe(m => {
            if (!!m) {
                this.menu = new Menu(m);
                this.loadImages();
            }
        });
    }

    async loadImages() {
        if (!!this.menu) {
            if (!this.directoryExists) {
                try {
                    await Filesystem.mkdir({
                        directory: Directory.Cache,
                        path: this.CACHE_FOLDER
                    });
                } catch (error) {
                    console.log(error.message);
                }
                this.directoryExists = true;
            }

            if (!!this.menu.menuItemGroups && this.menu.menuItemGroups.length > 0) {
                this.menu.menuItemGroups.forEach(item => {
                    this.loadFile(item.imageAddress);
                });
            }

            if (!!this.menu.menuItems && this.menu.menuItems.length > 0) {
                this.menu.menuItems.forEach(item => {
                    this.loadFile(item.imageAddress);
                });
            }
        }
    }

    async loadFile(imageUrl: string) {
        try {
            if (!!imageUrl) {
                const imageName = imageUrl.split('/').pop();
                const fileType = imageName.split('.').pop();

                const found = this.cachedImages[imageName];
                if (!found) {
                    try {
                        const readFile = await Filesystem.readFile({
                            directory: Directory.Cache,
                            path: `${this.CACHE_FOLDER}/${imageName}`
                        });

                        this.cachedImages[imageName] = readFile.data;
                    } catch (e) {
                        try {
                            await this.storeImage(imageUrl, imageName, fileType);
                            const readFile = await Filesystem.readFile({
                                directory: Directory.Cache,
                                path: `${this.CACHE_FOLDER}/${imageName}`
                            });

                            this.cachedImages[imageName] = readFile.data;
                        } catch (storeError) {
                            console.log(storeError);
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error, imageUrl);
        }
    }

    getImage(imageUrl: string) {
        try {
            if (!!imageUrl) {
                const imageName = imageUrl.split('/').pop();
                const fileType = imageName.split('.').pop();

                const found = this.cachedImages[imageName];
                if (!!found) {
                    return `data:image/${fileType};base64,${found}`;
                } else {
                    this.loadFile(imageUrl);
                    return imageUrl;
                }
            } else {
                return null;
            }
        } catch (error) {
            console.log(error, imageUrl);
            return imageUrl;
        }
    }

    async storeImage(url, path, fileType) {
        const response = await fetch(url);
        const blob = await response.blob() as IFile;
        try {
            const base64Data = await this.convertBlobToBase64(blob, fileType) as string;

            return await Filesystem.writeFile({
                path: `${this.CACHE_FOLDER}/${path}`,
                data: base64Data,
                directory: Directory.Cache,
                recursive: true
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    convertBlobToBase64(blob: IFile, fileType: string) {
        return new Promise((resolve, reject) => {
            try {
                let reader = new FileReader();
                if (blob instanceof Blob) {
                    const realFileReader = (reader as any)._realReader;
                    if (realFileReader) {
                        reader = realFileReader;
                    }
                }

                reader.onerror = (e) => {
                    console.log(e);
                    reject(e);
                };

                reader.onabort = (e) => {
                    console.log('aborting', e);
                    reject(e);
                };

                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(blob);

            } catch (convertError) {
                console.log(convertError);
                reject(convertError);
            }
        });
    }
}
