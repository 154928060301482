import {Injectable} from '@angular/core';
import {BaseObservableService} from './base-obeservable-service';
import {MembershipTokenPurse} from '../_models/membership-token-purse';

@Injectable({
    providedIn: 'root'
})
export class MembershipTokenPurseService extends BaseObservableService<MembershipTokenPurse> {
    destination = 'membership-token-purses';
}
