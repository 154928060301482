import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {BeerMenuItem} from '../_models/beer-menu-item';

@Injectable({
    providedIn: 'root'
})
export class BeerMenuItemService extends BaseService<BeerMenuItem> {
    destination = 'beer-menu-items';
}
