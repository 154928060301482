import {Injectable} from '@angular/core';
import {BaseObservableService} from './base-obeservable-service';
import {MembershipProgram} from '../_models/membership-program';

@Injectable({
    providedIn: 'root'
})
export class MembershipProgramService extends BaseObservableService<MembershipProgram> {
    destination = 'membership-programs';

    findByOrganizationId(organizationId: any) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}`);
    }

    sort(items: MembershipProgram[]) {
        return this.http.put(this.baseUrl() + '/sort', items);
    }
}
