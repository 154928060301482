import {BaseFormModel} from './base-form-model';

export class Feature extends BaseFormModel<Feature> {
    id: number;
    key: string;
    description: string;
    global: boolean;

    constructor(key: string, description: string, global = false) {
        super({key, description, global});
    }
}
