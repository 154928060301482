import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {MenuLabel} from '../_models/menu-label';

@Injectable({
    providedIn: 'root'
})
export class MenuLabelService extends BaseService<MenuLabel> {
    destination = 'menu-labels';
}
