<ion-item>
    <ion-label position="floating">{{label}}</ion-label>
    <ion-input [id]="id" [value]="formatDate(date)"></ion-input>
    <ion-button *ngIf="!hideIcon" fill="clear" slot="end" (click)="popover.present()">
        <ion-icon icon="calendar"></ion-icon>
    </ion-button>
    <ion-popover [trigger]="id" show-backdrop="true" #popover>
        <ng-template>
            <ion-datetime
                    [ngModel]="date"
                    [presentation]="presentation"
                    [max]="max"
                    [min]="min"
                    (ionChange)="fireDateChange($event)"
            ></ion-datetime>
        </ng-template>
    </ion-popover>
</ion-item>
