import {Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';

@Component({
    selector: 'bb-select-buttons',
    templateUrl: './select-buttons.component.html',
    styleUrls: ['./select-buttons.component.scss'],
})
export class SelectButtonsComponent implements OnInit {
    @Input() options: any[];
    @Input() sizeXs = 6;
    @Input() sizeSm = 6;
    @Input() sizeMd = 6;
    @Input() sizeLg = 6;
    @Input() sizeXl = 6;
    @Input() selected: any[] | any;
    @Input() noSelectionText = 'None';
    @Input() hideNoSelection = false;
    @Output() optionSelected = new EventEmitter<any>();
    multiple = false;

    @ContentChild('displayTemplate') displayTemplate: TemplateRef<any>;

    constructor() {
    }

    ngOnInit() {
        this.multiple = Array.isArray(this.selected);
    }

    isSelected(option: any) {
        if (this.multiple) {
            return (this.selected as any[]).indexOf(option) > -1;
        } else {
            return this.selected === option;
        }
    }

    noneSelected() {
        if (this.multiple) {
            return (this.selected as any[]).length === 0;
        } else {
            return !this.selected;
        }
    }

    getId(index: number, obj: any) {
        return obj.id;
    }
}
