import {BaseFormModel} from './base-form-model';
import {User} from './user';
import {UserOrganizationCredit} from './user-organization-credit';
import {CompDefinition} from './comp-definition';

export class OrderItemCredit extends BaseFormModel<OrderItemCredit> {
    id: number;
    createdBy: User;
    userOrganizationCredit: UserOrganizationCredit;
    createDate: Date;
    amount: number;
    comment: string;
    type: string;
    compDefinition: CompDefinition;
    autoApplied: boolean;
    addIndex: number;
    deleted: boolean;
}
