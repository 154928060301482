import {Directive, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {IonInput} from '@ionic/angular';

@Directive()
export abstract class BaseForm implements OnInit {
    @Output() formReady = new EventEmitter<FormGroup>();
    @Input() ignoreFirstInput = false;
    firstInput: IonInput;

    @ViewChild('firstInput') set first(input: IonInput) {
        this.firstInput = input;
        if (!!input && !this.ignoreFirstInput) {
            this.setFirstInputFocus();
        }
    }

    public formGroup: FormGroup;

    ngOnInit() {
        this.formGroup = this.setupFormGroup();
        this.formReady.emit(this.formGroup);
    }

    abstract setupFormGroup(): FormGroup;

    setFirstInputFocus() {
        if (!!this.firstInput) {
            setTimeout(async () => {
                await this.firstInput.setFocus();
            }, 250);
        }
    }
}
