<div class="removable-item-container" [class.removing]="removing">
    <ion-item-sliding #removableItem>
        <ng-content></ng-content>

        <ion-item-options (ionSwipe)="removeItemClicked()">
            <ion-item-option color="danger" (click)="removeItemClicked()">
                <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
            </ion-item-option>
        </ion-item-options>
    </ion-item-sliding>
</div>
