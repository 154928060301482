import {Inject, Injectable} from '@angular/core';
import {LibConfig, LibConfigService} from '../lib.config';
import {HttpClient} from '@angular/common/http';
import {BaseObservableService} from './base-obeservable-service';
import {Timecard} from '../_models/timecard';
import {BehaviorSubject, Observable} from 'rxjs';
import moment from 'moment';
import {TimecardTypes} from '../_constants/timecard-types';
import {FeatureKeys} from '../_constants/feature-keys';
import {formatDate} from '@angular/common';
import {PickupLocation} from '../_models/pickup-location';
import {Organization} from '../_models/organization';
import {AlertController} from '@ionic/angular';
import {ToastService} from './toast.service';
import {User} from '../_models/user';
import {UserService} from './user.service';
import {PrinterService} from './printer.service';

@Injectable({
    providedIn: 'root'
})
export class TimecardService extends BaseObservableService<Timecard> {
    destination = 'timecards';

    public timecardSearchCriteria: Observable<any>;
    private timecardSearchCriteriaSubject: BehaviorSubject<any>;

    constructor(
        private alertController: AlertController,
        private toastService: ToastService,
        private userService: UserService,
        private printerService: PrinterService,
        @Inject(LibConfigService) config: LibConfig,
        http: HttpClient
    ) {
        super(http, config);

        const dateSetter = moment();
        dateSetter.set({hour: 0, minute: 0, second: 0, millisecond: 0});
        dateSetter.subtract(14, 'days');
        const startDate = dateSetter.format();
        dateSetter.add(15, 'days');
        const endDate = dateSetter.format();

        this.timecardSearchCriteriaSubject = new BehaviorSubject<any>({
            cacheTime: new Date(),
            startDate,
            endDate,
            searchString: '',
            pickupLocationIds: [],
            tipped: TimecardTypes.ALL.value
        });
        this.timecardSearchCriteria = this.timecardSearchCriteriaSubject.asObservable();
    }

    clockIn(timecard: Timecard) {
        return this.http.post(`${this.baseUrl()}/clock-in`, timecard);
    }

    update(timecard: Timecard) {
        return this.http.put(`${this.baseUrl()}/`, timecard);
    }

    clockOut(timecardId: number) {
        return this.http.put(`${this.baseUrl()}/${timecardId}/clock-out`, {});
    }

    active(organizationId: number, pickupLocations = null) {
        let url = `${this.baseUrl()}/active/${organizationId}`;

        if (!!pickupLocations && pickupLocations.length > 0) {
            url += `?pickupLocationIds=${pickupLocations.join(',')}`;
        }

        return this.http.get(url);
    }

    activeUserTimecard(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/active/${organizationId}/user`);
    }

    getDateRangeTimecardsSearch(
        orgId: number,
        pickupLocations: number[],
        personId: number,
        startDate: string,
        endDate: string,
        tipped: string,
        search: string
    ) {
        let url = `${this.baseUrl()}/date-range/${orgId}?`;
        url += `startDate=${startDate.replace('+', '%2b')}`;
        if (!!endDate) {
            url += `&endDate=${endDate.replace('+', '%2b')}`;
        }
        if (!!personId) {
            url += `&personId=${personId}`;
        }
        if (!!pickupLocations && pickupLocations.length > 0) {
            url += `&pickupLocationIds=${pickupLocations.join(',')}`;
        }
        if (!!search) {
            url += `&search=${search}`;
        }
        if (!!tipped) {
            const tippedVal = this.getTippedVal(tipped);
            url += `${(tippedVal != null) ? '&tipped=' + tippedVal : ''}`;
        }
        return this.http.get(url);
    }

    getUserDateRangeTimecardsSearch(
        orgId: number,
        startDate: string,
        endDate: string
    ) {
        let url = `${this.baseUrl()}/user/date-range/${orgId}?`;
        url += `startDate=${startDate}`;
        if (!!endDate) {
            url += `&endDate=${endDate}`;
        }
        return this.http.get(url);
    }

    getSummaryDateRangeTimecardsSearch(
        orgId: number,
        pickupLocations: number[],
        startDate: string,
        endDate: string,
        tipped: string,
        search: string
    ) {
        let url = `${this.baseUrl()}/summary/${orgId}?`;
        url += `${(!!pickupLocations && pickupLocations.length > 0) ? '&pickupLocationIds=' + pickupLocations.join(',') : ''}`;
        url += `&startDate=${startDate.replace('+', '%2b')}`;
        url += `&endDate=${endDate.replace('+', '%2b')}`;
        url += `&search=${search}`;

        const tippedVal = this.getTippedVal(tipped);
        url += `${(tippedVal != null) ? '&tipped=' + tippedVal : ''}`;

        return this.http.get(url);
    }

    rawData(
        orgId: number,
        pickupLocations: number[],
        startDate: string,
        endDate: string,
        tipped: string,
        search: string
    ) {
        const httpOptions = {
            responseType: 'blob' as 'json',
        };

        let url = `${this.baseUrl()}/export-raw/${orgId}?`;
        url += `${(!!pickupLocations && pickupLocations.length > 0) ? '&pickupLocationIds=' + pickupLocations.join(',') : ''}`;
        url += `&startDate=${startDate.replace('+', '%2b')}`;
        url += `&endDate=${endDate.replace('+', '%2b')}`;
        url += `&search=${search}`;

        const tippedVal = this.getTippedVal(tipped);
        url += `${(tippedVal != null) ? '&tipped=' + tippedVal : ''}`;

        return this.http.get(url, httpOptions);
    }

    getTippedVal(tipped) {
        if (tipped === 'true') {
            return true;
        } else if (tipped === 'false') {
            return false;
        }
        return null;
    }

    setTimecardSearchCriteria(value: any) {
        value.cacheTime = new Date();
        this.timecardSearchCriteriaSubject.next(value);
    }

    getTimecardSearchCriteria(): any {
        const val = this.timecardSearchCriteriaSubject.getValue();
        const current = new Date();
        const difference = current.getTime() - val.cacheTime.getTime();
        const hoursMilli = 1000 * 60 * 60; // 1 hour
        if (Math.abs(difference) < hoursMilli) {
            val.cacheTime = new Date();
            this.timecardSearchCriteriaSubject.next(val);
            return val;
        } else {
            const dateSetter = moment();
            dateSetter.set({hour: 0, minute: 0, second: 0, millisecond: 0});
            dateSetter.add(1, 'days');

            const endDate = dateSetter.format();
            dateSetter.subtract(8, 'days');
            const startDate = dateSetter.format();
            const newVal = {
                cacheTime: new Date(),
                startDate,
                endDate,
                searchString: '',
                pickupLocationIds: [],
                tipped: TimecardTypes.ALL.value
            };
            this.timecardSearchCriteriaSubject.next(newVal);
            return newVal;
        }
    }

    async confirmClockIn(user: User, pickupLocation: PickupLocation, organization: Organization) {
        const timecard = new Timecard();
        timecard.person = user.person;
        timecard.tipped = false;
        timecard.pickupLocationId = !!pickupLocation ? pickupLocation.id : null;
        timecard.parentId = organization.id;

        if (user.authorizedTo(FeatureKeys.TIMECARD_TIPPED, organization.id)) {
            const alert = await this.alertController.create({
                cssClass: 'brewbill-alert',
                header: `Clock In ${user.person.name}`,
                message: `Please select the type of your shift to clock in.`,
                inputs: [
                    {
                        label: 'Tipped',
                        value: 'isTipped',
                        checked: true,
                        type: 'checkbox'
                    }
                ],
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary'
                    }, {
                        text: 'Clock-in',
                        handler: data => {
                            timecard.tipped = !!data[0];

                            this.clockIn(timecard).subscribe((t: Timecard) => {
                                this.setEvent(new Timecard(t));
                                user.timecard = t;
                                const clockedInTime = formatDate(t.clockInTime, 'MM/dd/YYYY hh:mm aa', 'en-us', null);
                                this.toastService.success(`${user.person.name} clocked in at ${clockedInTime}`);
                                this.userService.setCurrent(user);

                                if (this.printerService.isConnected()) {
                                    this.printerService.printTimeCard(new Timecard(t), organization);
                                }
                            });
                        }
                    }
                ]
            });

            await alert.present();
        } else {
            const alert = await this.alertController.create({
                cssClass: 'brewbill-alert',
                header: `Clock In ${user.person.name}?`,
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary'
                    }, {
                        text: 'Yes',
                        handler: data => {
                            this.clockIn(timecard).subscribe((t: Timecard) => {
                                user.timecard = t;
                                const clockedInTime = formatDate(t.clockInTime, 'MM/dd/YYYY hh:mm aa', 'en-us', null);
                                this.toastService.success(`${user.person.name} clocked in at ${clockedInTime}`);
                                this.userService.setCurrent(user);

                                if (this.printerService.isConnected()) {
                                    this.printerService.printTimeCard(new Timecard(t), organization);
                                }
                            });
                        }
                    }
                ]
            });

            await alert.present();
        }
    }
}
