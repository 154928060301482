import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../_models/user';
import {LoadingService} from '../../_services/loading.service';
import {UserService} from '../../_services/user.service';
import {AlertController, ModalController} from '@ionic/angular';
import {ToastService} from '../../_services/toast.service';
import {InviteUserModalComponent} from '../invite-user-modal/invite-user-modal.component';
import {OrganizationUser} from '../../_models/organization-user';
import {OrganizationUserService} from '../../_services/organization-user.service';
import {FeatureKeys} from '../../_constants/feature-keys';
import {Organization} from '../../_models/organization';
import {OrganizationService} from '../../_services/organization.service';
import {SubscriberComponent} from '../subscriber.component';

@Component({
    selector: 'bb-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent extends SubscriberComponent implements OnInit {
    @Input() orgId: number;
    @Input() defaultRole: string;
    @Input() users: User[];

    @Output() refreshUsers = new EventEmitter();
    organization: Organization;

    featureKeys = FeatureKeys;

    constructor(
        private modalController: ModalController,
        private alertController: AlertController,
        private organizationService: OrganizationService,
        private organizationUserService: OrganizationUserService,
        private userService: UserService,
        private loadingService: LoadingService,
        private toastService: ToastService
    ) {
        super();
    }

    ngOnInit() {
        this.subscribe(this.organizationService.current.subscribe(o => this.organization = !!o ? new Organization(o) : null));
    }

    async openInviteUser() {
        const modal = await this.modalController.create({
            component: InviteUserModalComponent,
            componentProps: {
                organization: this.organization
            }
        });

        modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshUsers.emit();
            }
        });

        await modal.present();
    }

    async confirmDelete(event, user: User) {
        event.preventDefault();
        event.stopPropagation();
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Delete',
            message: 'Are you sure you want to remove ' + user.person.name + '?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.deleteOrganizationUser(user.organizations.find(o => o.orgId === this.orgId));
                    }
                }
            ]
        });

        await alert.present();
    }

    async deleteOrganizationUser(organizationUser: OrganizationUser) {
        this.loadingService.present();
        this.organizationUserService.delete(organizationUser.id).subscribe(async (u: User) => {
                await this.toastService.success('The user has been removed.');
                this.loadingService.dismiss();
                this.refreshUsers.emit();
            },
            error => {
                if (error.status === 404) {
                    this.toastService.error('The user does not exist.');
                }
            });
    }
}
