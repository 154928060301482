import {Component, Input} from '@angular/core';
import {UserSearchResult} from '../../_models/user-search-result';

@Component({
    selector: 'bb-user-summary-item',
    templateUrl: './user-summary-item.component.html',
    styleUrls: ['./user-summary-item.component.css']
})
export class UserSummaryItemComponent {
    @Input() user: UserSearchResult;
    @Input() active: boolean;

    constructor() {
    }
}
