import {ColumnField} from '../_models/report/column-field';
import {ColumnFormats} from './column-formats';

export const ReportColumns = [
    new ColumnField('HOUR', ColumnFormats.HOUR, 'Hour'),
    new ColumnField('DAY', ColumnFormats.NUMBER, 'Day'),
    new ColumnField('DAY_OF_WEEK', ColumnFormats.DAY_OF_WEEK, 'Day of Week'),
    new ColumnField('WEEK', ColumnFormats.NUMBER, 'Week'),
    new ColumnField('MONTH', ColumnFormats.MONTH, 'Month'),
    new ColumnField('YEAR', ColumnFormats.YEAR, 'Year'),
    new ColumnField('DATE', ColumnFormats.DATE, 'Date'),
    new ColumnField('FEE_COLLECTION_AMOUNT', ColumnFormats.CURRENCY, 'Fee Collection Total (Sum of All Tabs)'),
    new ColumnField('FEE_COLLECTION_DATE', ColumnFormats.DATE, 'Fee Collection Date'),
    new ColumnField('FEE_COLLECTION_DATE_TIME', ColumnFormats.DATE_TIME, 'Fee Collection Date and Time'),
    new ColumnField('TAB_ID', ColumnFormats.ID, 'Tab ID'),
    new ColumnField('TAB_NUMBER', ColumnFormats.STRING, 'Tab Number'),
    new ColumnField('TAB_START_TIME', ColumnFormats.DATE_TIME, 'Date and Time'),
    new ColumnField('TAB_STATUS', ColumnFormats.STRING, 'Tab Status'),
    new ColumnField('TAB_HOST_FEE', ColumnFormats.CURRENCY, 'Tab BrewBill Fee'),
    new ColumnField('TAB_ORGANIZATION_FEE', ColumnFormats.CURRENCY, 'Tab Organization Fee'),
    new ColumnField('TAB_TOTAL_FEE', ColumnFormats.CURRENCY, 'Tab Total Fee'),
    new ColumnField('TAB_SUBTOTAL', ColumnFormats.CURRENCY, 'Tab Subtotal'),
    new ColumnField('TAB_TAX_EXEMPT', ColumnFormats.BOOLEAN, 'Tab Tax Exempt'),
    new ColumnField('TAB_TAX_EXEMPTION_ID', ColumnFormats.STRING, 'Tab Tax Exemption ID'),
    new ColumnField('TAB_DEVICE_ID', ColumnFormats.STRING, 'Device ID'),
    new ColumnField('TAB_NOTE', ColumnFormats.STRING, 'Tab Note', 'STRING'),
    new ColumnField('PICKUP_LOCATION_ID', ColumnFormats.ID, 'Pickup Location ID', 'LONG'),
    new ColumnField('PICKUP_LOCATION_NAME', ColumnFormats.STRING, 'Pickup Location'),
    new ColumnField('ORGANIZATION_ID', ColumnFormats.ID, 'Organization ID'),
    new ColumnField('ORGANIZATION_NAME', ColumnFormats.STRING, 'Organization'),
    new ColumnField('ORDER_ID', ColumnFormats.ID, 'Order ID'),
    new ColumnField('ORDER_NUMBER', ColumnFormats.STRING, 'Order Number'),
    new ColumnField('ORDER_DATE_TIME', ColumnFormats.DATE, 'Order Date and Time'),
    new ColumnField('ORDER_DATE', ColumnFormats.DATE, 'Order Date'),
    new ColumnField('ORDER_HOUR', ColumnFormats.HOUR, 'Order Hour'),
    new ColumnField('ORDER_DAY', ColumnFormats.NUMBER, 'Order Day'),
    new ColumnField('ORDER_DAY_OF_WEEK', ColumnFormats.DAY_OF_WEEK, 'Order Day of Week'),
    new ColumnField('ORDER_WEEK', ColumnFormats.NUMBER, 'Order Week'),
    new ColumnField('ORDER_MONTH', ColumnFormats.MONTH, 'Order Month'),
    new ColumnField('ORDER_YEAR', ColumnFormats.YEAR, 'Order Year'),
    new ColumnField('ORDER_STATUS', ColumnFormats.STRING, 'Order Status', 'STRING'),
    new ColumnField('ORDER_EVENT', ColumnFormats.STRING, 'Event', 'STRING'),
    new ColumnField('ORDER_TOTAL', ColumnFormats.CURRENCY, 'Order Total'),
    new ColumnField('ORDER_TERMINAL_ORDER', ColumnFormats.BOOLEAN, 'Terminal Order'),
    new ColumnField('ORDER_READY_TIME', ColumnFormats.DURATION, 'Ready Time'),
    new ColumnField('ORDER_DELIVERY_TIME', ColumnFormats.DURATION, 'Delivery Time'),
    new ColumnField('ORDER_SUBMITTED_BY_ID', ColumnFormats.ID, 'Order Submitted By ID'),
    new ColumnField('ORDER_SUBMITTED_BY_NAME', ColumnFormats.STRING, 'Order Submitted By'),
    new ColumnField('ORDER_ITEM_ID', ColumnFormats.ID, 'Order Item ID'),
    new ColumnField('ORDER_ITEM_SKU', ColumnFormats.STRING, 'Order Item SKU', 'STRING'),
    new ColumnField('ORDER_ITEM_ORDER_TYPE', ColumnFormats.STRING, 'Order Item Type'),
    new ColumnField('ORDER_ITEM_SELECTED_PRICE_NAME', ColumnFormats.STRING, 'Order Item Price Name', 'STRING'),
    new ColumnField('ORDER_ITEM_SELECTED_PRICE_PRICE', ColumnFormats.CURRENCY, 'Order Item Price'),
    new ColumnField('ORDER_ITEM_SELECTED_PRICE_VOLUME', ColumnFormats.NUMBER, 'Order Item Volume'),
    new ColumnField('ORDER_ITEM_TOTAL', ColumnFormats.CURRENCY, 'Order Item Total'),
    new ColumnField('ORDER_ITEM_TOTAL_LESS_INCLUDED_TAXES', ColumnFormats.CURRENCY, 'Order Item Total Less Included Taxes'),
    new ColumnField('ORDER_ITEM_CREDITS_TOTAL', ColumnFormats.CURRENCY, 'Order Item Comps'),
    new ColumnField('ORDER_ITEM_TAB_CREDITS_TOTAL', ColumnFormats.CURRENCY, 'Order Item Tab Comps'),
    new ColumnField('ORDER_ITEM_ALL_CREDITS_TOTAL', ColumnFormats.CURRENCY, 'Order Item Combined Comps'),
    new ColumnField('ORDER_ITEM_ADJUSTED_TOTAL', ColumnFormats.CURRENCY, 'Order Item Adjusted Total (Less Comps)'),
    new ColumnField('ORDER_ITEM_ADJUSTED_TOTAL_AND_ADDED_TAXES', ColumnFormats.CURRENCY, 'Order Item Adjusted Total And Added Taxes'),
    new ColumnField('ORDER_ITEM_ADJUSTED_TOTAL_LESS_INCLUDED_TAXES', ColumnFormats.CURRENCY, 'Order Item Adjusted Total Less Included Taxes'),
    new ColumnField('ORDER_ITEM_INCLUDED_TAXED_TOTAL', ColumnFormats.CURRENCY, 'Order Item Included Taxed Amount'),
    new ColumnField('ORDER_ITEM_INCLUDED_TAXES', ColumnFormats.CURRENCY, 'Order Item Included Taxes'),
    new ColumnField('ORDER_ITEM_ADDED_TAXED_TOTAL', ColumnFormats.CURRENCY, 'Order Item Added Taxed Amount'),
    new ColumnField('ORDER_ITEM_ADDED_TAXES', ColumnFormats.CURRENCY, 'Order Item Added Taxes'),
    new ColumnField('ORDER_ITEM_ALL_TAXED_TOTAL', ColumnFormats.CURRENCY, 'Order Item Taxed Amount'),
    new ColumnField('ORDER_ITEM_ALL_TAXES', ColumnFormats.CURRENCY, 'Order Item Taxes'),
    new ColumnField('ORDER_ITEM_ADJUSTED_TOTAL_AND_ADDED_TAXES', ColumnFormats.CURRENCY, 'Order Item Total And Added Taxes'),
    new ColumnField('MENU_ITEM_ID', ColumnFormats.ID, 'Menu Item ID', 'LONG'),
    new ColumnField('MENU_ITEM_NAME', ColumnFormats.STRING, 'Menu Item'),
    new ColumnField('MENU_CATEGORY_ID', ColumnFormats.ID, 'Menu Category ID', 'LONG'),
    new ColumnField('MENU_CATEGORY_NAME', ColumnFormats.STRING, 'Menu Category'),
    new ColumnField('MENU_LABEL_ID', ColumnFormats.ID, 'Menu Label ID', 'LONG'),
    new ColumnField('MENU_LABEL_NAME', ColumnFormats.STRING, 'Menu Label'),
    new ColumnField('PAYMENT_INFORMATION_INVOICE_CREATED', ColumnFormats.BOOLEAN, 'Tab Invoice Created'),
    new ColumnField('PAYMENT_INFORMATION_INCLUDED_TAXED_TOTAL', ColumnFormats.CURRENCY, 'Tab Included Taxed Amount'),
    new ColumnField('PAYMENT_INFORMATION_INCLUDED_TAXES', ColumnFormats.CURRENCY, 'Tab Included Taxes'),
    new ColumnField('PAYMENT_INFORMATION_ADDED_TAXED_TOTAL', ColumnFormats.CURRENCY, 'Tab Added Taxed Amount'),
    new ColumnField('PAYMENT_INFORMATION_ADDED_TAXES', ColumnFormats.CURRENCY, 'Tab Added Taxes'),
    new ColumnField('PAYMENT_INFORMATION_TAXED_TOTAL', ColumnFormats.CURRENCY, 'Tab Taxed Amount'),
    new ColumnField('PAYMENT_INFORMATION_TAXES', ColumnFormats.CURRENCY, 'Tab Taxes'),
    new ColumnField('PAYMENT_INFORMATION_SUBTOTAL_LESS_INCLUDED_TAX', ColumnFormats.CURRENCY, 'Tab Subtotal Less Included Taxes'),
    new ColumnField('PAYMENT_INFORMATION_FEES', ColumnFormats.CURRENCY, 'Tab Fees'),
    new ColumnField('PAYMENT_INFORMATION_TAXES_AND_FEES', ColumnFormats.CURRENCY, 'Tab Taxes and Fees'),
    new ColumnField('PAYMENT_INFORMATION_SURCHARGES', ColumnFormats.CURRENCY, 'Tab Surcharges'),
    new ColumnField('PAYMENT_INFORMATION_PRINCIPAL', ColumnFormats.CURRENCY, 'Tab Subtotal, Taxes, and Fees'),
    new ColumnField('PAYMENT_INFORMATION_TIPS', ColumnFormats.CURRENCY, 'Tab Tips Net'),
    new ColumnField('PAYMENT_INFORMATION_CREDITS', ColumnFormats.CURRENCY, 'Tab Comps Total'),
    new ColumnField('PAYMENT_INFORMATION_INVOICED', ColumnFormats.CURRENCY, 'Tab Subtotal, Taxes, and Fees Less Comps'),
    new ColumnField('PAYMENT_INFORMATION_TOTAL', ColumnFormats.CURRENCY, 'Tab Total Including Tips'),
    new ColumnField('PAYMENT_INFORMATION_PAYMENTS', ColumnFormats.CURRENCY, 'Tab Payments Gross'),
    new ColumnField('PAYMENT_INFORMATION_REFUNDS', ColumnFormats.CURRENCY, 'Tab Refunds'),
    new ColumnField('PAYMENT_INFORMATION_REMAINING', ColumnFormats.CURRENCY, 'Tab Outstanding Balance'),
    new ColumnField('PAYMENT_INFORMATION_APPLIED_PAYMENTS', ColumnFormats.CURRENCY, 'Tab Payments Net'),
    new ColumnField('PAYMENT_INFORMATION_REMAINING', ColumnFormats.CURRENCY, 'Tab Due'),
    new ColumnField('PAYMENT_INFORMATION_PAYMENT_STATUS', ColumnFormats.UNDERSCORE, 'Tab Payment Status', 'STRING'),
    new ColumnField('PAYMENT_INFORMATION_PAYMENT_TYPE', ColumnFormats.UNDERSCORE, 'Tab Payment Type', 'STRING'),
    new ColumnField('PAYMENT_INFORMATION_TIP_PAYMENTS', ColumnFormats.CURRENCY, 'Tab Tips Gross'),
    new ColumnField('PAYMENT_INFORMATION_TIP_REFUNDS', ColumnFormats.CURRENCY, 'Tab Tips Refunded'),
    new ColumnField('PAYMENT_INFORMATION_LAST_CHARGE_DATE', ColumnFormats.DATE, 'Tab Last Payment Charge Date'),
    new ColumnField('PAYMENT_INFORMATION_LAST_CHARGE_DATE_TIME', ColumnFormats.DATE_TIME, 'Tab Last Payment Charge Date and Time'),
    new ColumnField('PAYMENT_INFORMATION_PAYMENTS_PRINCIPAL', ColumnFormats.CURRENCY, 'Tab Payments Principal Gross'),
    new ColumnField('PAYMENT_INFORMATION_APPLIED_CHARGES_PRINCIPAL', ColumnFormats.CURRENCY, 'Tab Charges Principal Net'),
    new ColumnField('PAYMENT_INFORMATION_APPLIED_CHARGES_TIPS', ColumnFormats.CURRENCY, 'Tab Charges Tips Net'),
    new ColumnField('PAYMENT_INFORMATION_APPLIED_CHARGES_TOTAL', ColumnFormats.CURRENCY, 'Tab Charges Total Net'),
    new ColumnField('PAYMENT_INFORMATION_CHARGE_PAYMENTS_PRINCIPAL', ColumnFormats.CURRENCY, 'Tab Charges Principal Gross'),
    new ColumnField('PAYMENT_INFORMATION_CHARGE_PAYMENTS_TIPS', ColumnFormats.CURRENCY, 'Tab Charges Tips Gross'),
    new ColumnField('PAYMENT_INFORMATION_CHARGE_PAYMENTS_TOTAL', ColumnFormats.CURRENCY, 'Tab Charges Total Gross'),
    new ColumnField('PAYMENT_INFORMATION_CHARGE_REFUNDS_PRINCIPAL', ColumnFormats.CURRENCY, 'Tab Charges Refunded Principal'),
    new ColumnField('PAYMENT_INFORMATION_CHARGE_REFUNDS_TIPS', ColumnFormats.CURRENCY, 'Tab Charges Refunded Tips'),
    new ColumnField('PAYMENT_INFORMATION_CHARGE_REFUNDS_TOTAL', ColumnFormats.CURRENCY, 'Tab Charges Refunded Total'),
    new ColumnField('PAYMENT_INFORMATION_APPLIED_CASH_PRINCIPAL', ColumnFormats.CURRENCY, 'Tab Cash Principal Net'),
    new ColumnField('PAYMENT_INFORMATION_APPLIED_CASH_TIPS', ColumnFormats.CURRENCY, 'Tab Cash Principal Net'),
    new ColumnField('PAYMENT_INFORMATION_APPLIED_CASH_TOTAL', ColumnFormats.CURRENCY, 'Tab Cash Total Net'),
    new ColumnField('PAYMENT_INFORMATION_CASH_PAYMENTS_PRINCIPAL', ColumnFormats.CURRENCY, 'Tab Cash Payments Principal Gross'),
    new ColumnField('PAYMENT_INFORMATION_CASH_PAYMENTS_TIPS', ColumnFormats.CURRENCY, 'Tab Cash Payments Tips Gross'),
    new ColumnField('PAYMENT_INFORMATION_CASH_PAYMENTS_TOTAL', ColumnFormats.CURRENCY, 'Tab Cash Payments Total Gross'),
    new ColumnField('PAYMENT_INFORMATION_CASH_REFUNDS_PRINCIPAL', ColumnFormats.CURRENCY, 'Tab Cash Refunds Principal'),
    new ColumnField('PAYMENT_INFORMATION_CASH_REFUNDS_TIPS', ColumnFormats.CURRENCY, 'Tab Cash Refunds Tips'),
    new ColumnField('PAYMENT_INFORMATION_CASH_REFUNDS_TOTAL', ColumnFormats.CURRENCY, 'Tab Cash Refunds Total'),
    new ColumnField('PAYMENT_INFORMATION_ORDER_ITEM_CREDITS', ColumnFormats.CURRENCY, 'Tab Comps (Order Item Level)'),
    new ColumnField('PAYMENT_INFORMATION_TAB_CREDITS', ColumnFormats.CURRENCY, 'Tab Comps (Tab Level)'),
    new ColumnField('PAYMENT_INFORMATION_PRE_AUTH_TOTAL', ColumnFormats.CURRENCY, 'Tab Pre Auth Total'),
    new ColumnField('PAYMENT_INFORMATION_CANCELED_ORDERS_COUNT', ColumnFormats.CURRENCY, 'Tab Canceled Orders Count'),
    new ColumnField('PAYMENT_INFORMATION_CANCELED_ORDERS_TOTAL', ColumnFormats.CURRENCY, 'Tab Canceled Orders Total'),
    new ColumnField('PAYMENT_INFORMATION_GIFT_CARD_PAYMENTS_PRINCIPAL', ColumnFormats.CURRENCY, 'Tab Gift Card Payments Principal'),
    new ColumnField('PAYMENT_INFORMATION_GIFT_CARD_PAYMENTS_TIPS', ColumnFormats.CURRENCY, 'Tab Gift Card Payments Tips'),
    new ColumnField('PAYMENT_INFORMATION_GIFT_CARD_PAYMENTS_TOTAL', ColumnFormats.CURRENCY, 'Tab Gift Card Payments Total'),
    new ColumnField('PAYMENT_INFORMATION_GIFT_CARD_PURCHASES_COUNT', ColumnFormats.NUMBER, 'Gift Card Purchases Count'),
    new ColumnField('PAYMENT_INFORMATION_GIFT_CARD_PURCHASES_TOTAL', ColumnFormats.CURRENCY, 'Gift Card Purchases Total'),
    new ColumnField('PAYMENT_INFORMATION_TOKENS_USED', ColumnFormats.NUMBER, 'Tokens Used'),
    new ColumnField('PAYMENT_INFORMATION_TOKEN_PAYMENTS_TOTAL', ColumnFormats.CURRENCY, 'Token Payments Total'),
    new ColumnField('COMP_ID', ColumnFormats.ID, 'Comp ID'),
    new ColumnField('COMP_AMOUNT', ColumnFormats.CURRENCY, 'Comp Amount'),
    new ColumnField('COMP_TYPE', ColumnFormats.UNDERSCORE, 'Comp Type'),
    new ColumnField('COMP_DEFINITION_ID', ColumnFormats.STRING, 'Comp Definition ID'),
    new ColumnField('COMP_DEFINITION_NAME', ColumnFormats.STRING, 'Comp Definition Name'),
    new ColumnField('COMP_DEFINITION_TYPE', ColumnFormats.UNDERSCORE, 'Comp Definition Type'),
    new ColumnField('COMP_DATE_TIME', ColumnFormats.DATE, 'Comp Date and Time'),
    new ColumnField('COMP_DATE', ColumnFormats.DATE, 'Comp Date'),
    new ColumnField('COMP_HOUR', ColumnFormats.HOUR, 'Comp Hour'),
    new ColumnField('COMP_DAY', ColumnFormats.NUMBER, 'Comp Day'),
    new ColumnField('COMP_DAY_OF_WEEK', ColumnFormats.DAY_OF_WEEK, 'Comp Day of Week'),
    new ColumnField('COMP_WEEK', ColumnFormats.NUMBER, 'Comp Week'),
    new ColumnField('COMP_MONTH', ColumnFormats.MONTH, 'Comp Month'),
    new ColumnField('COMP_YEAR', ColumnFormats.YEAR, 'Comp Year'),
    new ColumnField('COMP_PERSON_ID', ColumnFormats.ID, 'Comp Employee ID'),
    new ColumnField('COMP_PERSON_NAME', ColumnFormats.STRING, 'Comp Employee'),
    new ColumnField('EMPLOYEE_ID', ColumnFormats.ID, 'Delivered By ID'),
    new ColumnField('EMPLOYEE_NAME', ColumnFormats.STRING, 'Delivered By'),
    new ColumnField('SERVER_ID', ColumnFormats.ID, 'Server ID'),
    new ColumnField('SERVER_NAME', ColumnFormats.STRING, 'Server'),
    new ColumnField('CUSTOMER_ID', ColumnFormats.ID, 'Customer ID'),
    new ColumnField('CUSTOMER_NAME', ColumnFormats.STRING, 'Customer'),
    new ColumnField('CUSTOMER_EMAIL', ColumnFormats.STRING, 'Customer Email'),
    new ColumnField('CASH_AUDIT_ID', ColumnFormats.ID, 'Cash Action ID'),
    new ColumnField('CASH_AUDIT_DATE', ColumnFormats.DATE, 'Cash Action Date'),
    new ColumnField('CASH_AUDIT_AMOUNT', ColumnFormats.CURRENCY, 'Cash Action Total'),
    new ColumnField('CASH_AUDIT_TIP', ColumnFormats.CURRENCY, 'Cash Action Tip'),
    new ColumnField('CASH_AUDIT_EMPLOYEE_ID', ColumnFormats.ID, 'Cash Action Server ID'),
    new ColumnField('CASH_AUDIT_EMPLOYEE_NAME', ColumnFormats.STRING, 'Cash Action Server'),
    new ColumnField('PAYMENT_CHARGE_ID', ColumnFormats.ID, 'Charge Action ID'),
    new ColumnField('PAYMENT_CHARGE_PRINCIPAL', ColumnFormats.CURRENCY, 'Charge Action Principal'),
    new ColumnField('PAYMENT_CHARGE_TIP', ColumnFormats.CURRENCY, 'Charge Action Tip'),
    new ColumnField('PAYMENT_CHARGE_TOTAL', ColumnFormats.CURRENCY, 'Charge Action Total'),
    new ColumnField('PAYMENT_CHARGE_SURCHARGE', ColumnFormats.CURRENCY, 'Charge Action Surcharge'),
    new ColumnField('PAYMENT_CHARGE_TERMINAL_PAYMENT', ColumnFormats.STRING, 'Charge Action Terminal Payment'),
    new ColumnField('PAYMENT_CHARGE_DATE_TIME', ColumnFormats.DATE, 'Charge Action Date and Time'),
    new ColumnField('PAYMENT_CHARGE_DATE', ColumnFormats.DATE, 'Charge Action Date'),
    new ColumnField('PAYMENT_CHARGE_HOUR', ColumnFormats.HOUR, 'Charge Action Hour'),
    new ColumnField('PAYMENT_CHARGE_DAY', ColumnFormats.NUMBER, 'Charge Action Day'),
    new ColumnField('PAYMENT_CHARGE_DAY_OF_WEEK', ColumnFormats.DAY_OF_WEEK, 'Charge Action Day of Week'),
    new ColumnField('PAYMENT_CHARGE_WEEK', ColumnFormats.NUMBER, 'Charge Action Week'),
    new ColumnField('PAYMENT_CHARGE_MONTH', ColumnFormats.MONTH, 'Charge Action Month'),
    new ColumnField('PAYMENT_CHARGE_YEAR', ColumnFormats.YEAR, 'Charge Action Year'),
    new ColumnField('PAYMENT_CHARGE_REFUND_ID', ColumnFormats.ID, 'Charge Refund Action ID'),
    new ColumnField('PAYMENT_CHARGE_REFUND_PRINCIPAL', ColumnFormats.CURRENCY, 'Charge Refund Action Principal'),
    new ColumnField('PAYMENT_CHARGE_REFUND_TIP', ColumnFormats.CURRENCY, 'Charge Refund Action Tip'),
    new ColumnField('PAYMENT_CHARGE_REFUND_TOTAL', ColumnFormats.CURRENCY, 'Charge Refund Action Total'),
    new ColumnField('PAYMENT_CHARGE_REFUND_DATE_TIME', ColumnFormats.DATE, 'Charge Refund Action Date and Time'),
    new ColumnField('PAYMENT_CHARGE_REFUND_DATE', ColumnFormats.DATE, 'Charge Refund Action Date'),
    new ColumnField('PAYMENT_CHARGE_REFUND_HOUR', ColumnFormats.HOUR, 'Charge Refund Action Hour'),
    new ColumnField('PAYMENT_CHARGE_REFUND_DAY', ColumnFormats.NUMBER, 'Charge Refund Action Day'),
    new ColumnField('PAYMENT_CHARGE_REFUND_DAY_OF_WEEK', ColumnFormats.DAY_OF_WEEK, 'Charge Refund Action Day of Week'),
    new ColumnField('PAYMENT_CHARGE_REFUND_WEEK', ColumnFormats.NUMBER, 'Charge Refund Action Week'),
    new ColumnField('PAYMENT_CHARGE_REFUND_MONTH', ColumnFormats.MONTH, 'Charge Refund Action Month'),
    new ColumnField('PAYMENT_CHARGE_REFUND_YEAR', ColumnFormats.YEAR, 'Charge Refund Action Year'),
    new ColumnField('CARD_SUMMARY_CARD_TYPE', ColumnFormats.STRING, 'Charge Card Action Brand'),
    new ColumnField('CARD_SUMMARY_LAST_FOUR', ColumnFormats.NUMBER, 'Charge Action Card Last Four Digits'),
    new ColumnField('GIFT_CARD_ACTION_ID', ColumnFormats.ID, 'Gift Card Action ID'),
    new ColumnField('GIFT_CARD_ACTION_AMOUNT', ColumnFormats.CURRENCY, 'Gift Card Action Amount'),
    new ColumnField('GIFT_CARD_ACTION_TIP', ColumnFormats.CURRENCY, 'Gift Card Action Tip'),
    new ColumnField('GIFT_CARD_ACTION_REFUNDED', ColumnFormats.CURRENCY, 'Gift Card Action Refunded'),
    new ColumnField('GIFT_CARD_ACTION_TOTAL', ColumnFormats.CURRENCY, 'Gift Card Action Total'),
    new ColumnField('GIFT_CARD_ACTION_DATE_TIME', ColumnFormats.DATE, 'Gift Card Action Date and Time'),
    new ColumnField('GIFT_CARD_ACTION_DATE', ColumnFormats.DATE, 'Gift Card Action Date'),
    new ColumnField('GIFT_CARD_ACTION_HOUR', ColumnFormats.HOUR, 'Gift Card Action Hour'),
    new ColumnField('GIFT_CARD_ACTION_DAY', ColumnFormats.NUMBER, 'Gift Card Action Day'),
    new ColumnField('GIFT_CARD_ACTION_DAY_OF_WEEK', ColumnFormats.DAY_OF_WEEK, 'Gift Card Action Day of Week'),
    new ColumnField('GIFT_CARD_ACTION_WEEK', ColumnFormats.NUMBER, 'Gift Card Action Week'),
    new ColumnField('GIFT_CARD_ACTION_MONTH', ColumnFormats.MONTH, 'Gift Card Action Month'),
    new ColumnField('GIFT_CARD_ACTION_YEAR', ColumnFormats.YEAR, 'Gift Card Action Year'),
    new ColumnField('GIFT_CARD_ACTION_EMPLOYEE_ID', ColumnFormats.ID, 'Gift Card Action Employee ID'),
    new ColumnField('GIFT_CARD_ACTION_EMPLOYEE_NAME', ColumnFormats.STRING, 'Gift Card Action Employee'),
    new ColumnField('MEMBERSHIP_RENEWAL_NEW', ColumnFormats.NUMBER, 'New Membership'),
    new ColumnField('MEMBERSHIP_RENEWAL_RENEWED', ColumnFormats.NUMBER, 'Renewed Membership'),
    new ColumnField('MEMBERSHIP_RENEWAL_MEMBERSHIP_PROGRAM_ID', ColumnFormats.ID, 'Membership Program ID'),
    new ColumnField('MEMBERSHIP_RENEWAL_MEMBERSHIP_PROGRAM_NAME', ColumnFormats.STRING, 'Membership Program Name'),
    new ColumnField('MEMBERSHIP_RENEWAL_QUANTITY', ColumnFormats.NUMBER, 'Membership Renewal Quantity'),
    new ColumnField('MEMBERSHIP_RENEWAL_TOKENS', ColumnFormats.CURRENCY, 'Membership Token Purchase'),
    new ColumnField('MEMBERSHIP_RENEWAL_START_DATE', ColumnFormats.DATE, 'Membership Renewal Start Date'),
    new ColumnField('MEMBERSHIP_RENEWAL_END_DATE', ColumnFormats.DATE, 'Membership Renewal Expiration Date')
];

export function getReportFieldDisplayName(key: string) {
    const col = ReportColumns.find(c => c.key === key);
    return !!col ? col.display : key;
}
