import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';
import {ModalController} from '@ionic/angular';
import moment from 'moment';
import {Constants} from '../_constants/constants';
import {CashSession} from '../_models/cash-information/cash-session';
import {CashDrawer} from '../_models/cash-information/cash-drawer';
import {BaseObservableService} from './base-obeservable-service';
import {CashDrawerService} from './cash-drawer.service';

@Injectable({
    providedIn: 'root'
})
export class CashSessionService extends BaseObservableService<CashSession> {
    destination = 'cash-sessions';

    public searchCriteria: Observable<any>;
    private searchCriteriaSubject: BehaviorSubject<any>;

    public working: Observable<CashSession>;
    private workingSubject: BehaviorSubject<CashSession>;

    constructor(
        http: HttpClient,
        private modalController: ModalController,
        private cashDrawerService: CashDrawerService,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);

        const dateSetter = moment();
        dateSetter.set({hour: 0, minute: 0, second: 0, millisecond: 0});
        dateSetter.add(1, 'days');

        const endDate = dateSetter.format();
        dateSetter.subtract(8, 'days');
        const startDate = dateSetter.format();

        this.searchCriteriaSubject = new BehaviorSubject<any>({cacheTime: new Date(), startDate, endDate});
        this.searchCriteria = this.searchCriteriaSubject.asObservable();

        this.workingSubject = new BehaviorSubject<CashSession>(null);
        this.working = this.workingSubject.asObservable();
    }

    public get workingValue(): CashSession {
        return this.workingSubject.value;
    }

    refresh() {
        if (!!this.currentValue && !!this.currentValue.id) {
            this.getSilent(this.currentValue.id).subscribe((o: CashSession) => this.setCurrent(new CashSession(o)));
        }
    }

    load(c: CashSession) {
        const next = !!c ? new CashSession(c) : null;

        if (!!next) {
            const cashDrawerId = localStorage.getItem('CASH_DRAWER_ID');
            if (!!cashDrawerId) {
                const found = next.cashDrawers
                    .find(cd => cd.id === Number.parseInt(cashDrawerId, 10) && cd.status === 'ACTIVE');
                this.cashDrawerService.setCurrent(!!found ? new CashDrawer(found) : null);
            } else {
                this.cashDrawerService.setCurrent(null);
            }
        } else {
            this.cashDrawerService.setCurrent(null);
        }

        this.setCurrent(next);
    }

    create(cashSession: CashSession) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409,412');
        return this.http.post(this.baseUrl(), cashSession, {headers});
    }

    modify(cashSession: CashSession) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '412');
        return this.http.put(`${this.baseUrl()}/${cashSession.id}/modify`, {}, {headers});
    }

    pend(cashSession: CashSession) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '412');
        return this.http.put(`${this.baseUrl()}/${cashSession.id}/pend`, {}, {headers});
    }

    complete(cashSession: CashSession) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '406,412,413');
        return this.http.put(`${this.baseUrl()}/${cashSession.id}/complete`, {}, {headers});
    }

    findOpen(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/open`);
    }

    findLast(organizationId: number) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/last`);
    }

    getDateRangeSummaries(organizationId: number, startDate: string, endDate?: string) {
        return this.http.get(`${this.baseUrl()}/organization/${organizationId}/date-range?startDate=${startDate.replace('+', '%2b')}`
            + (!!endDate ? '&endDate=' + endDate.replace('+', '%2b')
            : ''));
    }

    summary(id: any) {
        return this.http.get(`${this.baseUrl()}/${id}/summary`);
    }

    roundedHours(cashSessionId: number, personId: number) {
        const headers = new HttpHeaders().set(Constants.SILENT, 'true');
        return this.http.get(`${this.baseUrl()}/${cashSessionId}/person/${personId}/rounded-hours`, {headers});
    }

    getOpenTabs(cashSessionId: number) {
        return this.http.get(`${this.baseUrl()}/${cashSessionId}/tabs/open`);
    }

    setSearchCriteria(value: any) {
        value.cacheTime = new Date();
        this.searchCriteriaSubject.next(value);
    }

    setWorking(cashSession: CashSession) {
        this.workingSubject.next(cashSession);
    }

    getSearchCriteria(): any {
        const val = this.searchCriteriaSubject.getValue();
        const current = new Date();
        const difference = current.getTime() - val.cacheTime.getTime();
        const hoursMilli = 1000 * 60 * 60; // 1 hour
        if (Math.abs(difference) < hoursMilli) {
            val.cacheTime = new Date();
            this.searchCriteriaSubject.next(val);
            return val;
        } else {
            const dateSetter = moment();
            dateSetter.set({hour: 0, minute: 0, second: 0, millisecond: 0});
            dateSetter.add(1, 'days');

            const endDate = dateSetter.format();
            dateSetter.subtract(8, 'days');
            const startDate = dateSetter.format();
            const newVal = {cacheTime: new Date(), startDate, endDate};
            this.searchCriteriaSubject.next(newVal);
            return newVal;
        }
    }
}
