import {BaseFormModel} from './base-form-model';

export class PickupLocationViewSummary extends BaseFormModel<PickupLocationViewSummary> {

    pickupLocationId: number;

    pickupLocationName: string;

    deviceId: string;

    userId: number;

    name: string;

    startTime: Date;

    visits: number;

    organizationLogo: string;

    organizationName: string;
}
