<ion-row [hidden]="!loaded" [formGroup]="formGroup">
    <ion-col size-xs="12">
        <ion-item>
            <ion-label position="stacked">Credit Card</ion-label>
            <div class="card-input">
                <div class="card-input-first">
                    <div class="card-image-container">
                        <ion-icon name="card-outline" class="card-icon"></ion-icon>
                        <img *ngIf="!!cardType" src="assets/card-logo/{{cardType}}.png" class="card-image" [alt]="cardType"/>
                    </div>
                    <div class="card-number-container">
                        <div id="gp-card-number"></div>
                    </div>
                </div>
                <div class="card-input-second">
                    <div class="card-expiration-container">
                        <div id="gp-card-expiration" #expiration></div>
                    </div>
                    <div class="card-cvv-container">
                        <div id="gp-card-cvv" #cvv></div>
                    </div>
                </div>
            </div>
        </ion-item>
    </ion-col>
</ion-row>
<ion-row [hidden]="!loaded">
    <ion-col *ngIf="!hideCancel">
        <ion-button data-cy="new-card-cancel-button" color="dark" class="full-footer-button" (click)="cancel.emit()">
            Cancel
        </ion-button>
    </ion-col>
    <ion-col>
        <div id="gp-submit"></div>
        <ion-button [disabled]="!isValid()"
                    data-cy="new-card-submit-button" color="success" class="full-footer-button">
            {{buttonText}}
        </ion-button>
    </ion-col>
</ion-row>
<ion-row *ngIf="loadFailed">
    <ion-col>
        <h4>
            <ion-text color="danger">Could not load payment form.</ion-text>
        </h4>
    </ion-col>
</ion-row>
<ion-row *ngIf="!loaded && !loadFailed">
    <ion-col>
        <ion-item lines="none">
            <ion-spinner name="crescent" color="secondary" slot="start"></ion-spinner>
            <ion-label>Loading...</ion-label>
        </ion-item>
    </ion-col>
</ion-row>
