import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'bb-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorPickerComponent),
            multi: true,
        }
    ]
})
export class ColorPickerComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() id: string;
    @Output() colorChange = new EventEmitter<string>();

    field: string;

    initialized = false;

    presetColors = ['#ececec', '#fddb31', '#c04949', '#9e3dff', '#2dd36f', '#52e5ff', '#ff0000', '#000000', '#ffffff'];

    writeValue(obj: any): void {
        this.field = obj;
    }

    onChange = (value: any) => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    fireColorChange(value) {
        this.colorChange.emit(value);
    }
}
