import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {MenuSelectionItem} from '../_models/menu-selection-item';

@Injectable({
    providedIn: 'root'
})
export class MenuSelectionItemService extends BaseService<MenuSelectionItem> {
    destination = 'menu-selection-items';
}
