<modal-header [title]="currentCard.organizationName"></modal-header>

<ion-content>
            <ion-grid>
                <ion-row>
                    <ion-col *ngIf="!!currentCard && currentCard.type === 'PER_ITEM'" class="reward-container ion-text-center">
                        <h2>Earn a reward with the purchase of {{ this.currentCard.goal }} qualified items.</h2>
                        <div class="punch-container ion-text-center">
                            <span *ngFor="let punch of punches; let punchIndex = index" [class.unused]="!punch" [class.add-punch]="punch"
                                  class="punch"></span>
                        </div>
                    </ion-col>
                    <ion-col *ngIf="!!currentCard && currentCard.type === 'PER_DOLLAR'" class="reward-container">
                        <h2>Next reward at {{ this.currentCard.goal | currency }}.</h2>
                        <ion-progress-bar [color]="(this.currentCard.goal * 100 === rewardSlide) ? 'success' : 'secondary'"
                                          [value]="rewardSlide / (this.currentCard.goal * 100)"
                                          [class.progress-completed]="this.currentCard.goal * 100 === rewardSlide">
                        </ion-progress-bar>
                    </ion-col>
                </ion-row>
            </ion-grid>
    <div *ngIf="!!currentCard && ((currentCard.type === 'PER_ITEM' && currentPunch === punches.length) || (currentCard.type === 'PER_DOLLAR' && this.currentCard.goal * 100 === rewardSlide))"
         class="reward-message" (click)="closeMessage()">
        {{rewardMessage()}}
    </div>
</ion-content>

<modal-footer (submitClicked)="close()" submitLabel="Close"></modal-footer>
