import {Component, OnInit} from '@angular/core';
import {SubscriberComponent} from './subscriber.component';
import {Organization} from '../_models/organization';
import {PickupLocation} from '../_models/pickup-location';
import {User} from '../_models/user';
import {UserService} from '../_services/user.service';
import {OrganizationService} from '../_services/organization.service';
import {PickupLocationService} from '../_services/pickup-location.service';
import {AuthenticationService} from '../_services/authentication.service';
import {Membership} from '../_models/membership';
import {MembershipService} from '../_services/membership.service';

@Component({
    template: ''
})
export abstract class BaseManagerComponent extends SubscriberComponent implements OnInit {
    organization: Organization;
    pickupLocation: PickupLocation;
    currentUser: User;
    activeMember: Membership;

    abstract init();

    protected constructor(
        public authenticationService: AuthenticationService,
        public organizationService: OrganizationService,
        public pickupLocationService: PickupLocationService,
        public userService: UserService,
        public membershipService: MembershipService
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribe(this.userService.current.subscribe((user: User) => {
            this.currentUser = !!user ? new User(user) : null;
            this.checkInit();
        }));

        this.subscribe(this.organizationService.current.subscribe((o: Organization) => {
            this.organization = !!o ? new Organization(o) : null;
            this.checkInit();
        }));

        this.subscribe(this.pickupLocationService.current.subscribe(p => {
            this.pickupLocation = p;
            if (!!this.pickupLocation) {
                localStorage.setItem('ACTIVE_PICKUP_LOCATION', this.pickupLocation.id.toString(10));
            }
            this.checkInit();
        }));

        this.subscribe(this.membershipService.current.subscribe(m => this.activeMember = !!m ? new Membership(m) : null));
    }

    checkInit() {
        if (!!this.currentUser
            && !!this.organization
            && !!this.pickupLocation) {
            this.init();
        }
    }
}
