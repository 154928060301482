import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {MenuItemGroup} from '../_models/menu-item-group';

@Injectable({
    providedIn: 'root'
})
export class MenuItemGroupService extends BaseService<MenuItemGroup> {
    destination = 'menu-item-groups';
}
