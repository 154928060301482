import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'underscoreConversion'
})
export class UnderscoreConversionPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return '';
        }
        const words = value.toLowerCase().split('_').map(element => {
            return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
        });
        return words.join(' ');
    }
}
