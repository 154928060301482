import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AudioService {
    public current: Observable<string>;
    private currentSubject: BehaviorSubject<string>;

    constructor() {
        const val = localStorage.getItem('AUDIO');
        this.currentSubject = new BehaviorSubject<string>(val);
        this.current = this.currentSubject.asObservable();
    }

    public get currentValue(): string {
        return this.currentSubject.value;
    }

    setCurrent(obj: string) {
        localStorage.setItem('AUDIO', obj);
        this.currentSubject.next(obj);
    }
}
