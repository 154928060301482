<div id="popover-button"></div>
<ion-popover trigger="popover-button"
             *ngIf="isOpen"
             [style.--width]="width + 'px'"
             [showBackdrop]="false"
             [isOpen]="isOpen"
             (didDismiss)="closed.emit(returnFilter ? filter : value)" #typeAheadPopover>
    <ng-template>
        <ion-content>
            <ion-list>
                <ion-item class="sticky-filter">
                    <ion-label position="floating">{{filterLabel}}</ion-label>
                    <ion-input [(ngModel)]="filter"
                               (keyup)="handleKeyUp($event)"
                               (ionChange)="filterItems()" #searchFilter></ion-input>
                </ion-item>
                <ion-item *ngFor="let item of filteredItems" (click)="selectItem(item)">
                    <ng-container *ngIf="!!display">
                        <ion-label>{{ item[display] }}</ion-label>
                        <ion-icon *ngIf="value === item" slot="end" color="success" name="checkmark-outline"></ion-icon>
                    </ng-container>
                    <ng-template [ngTemplateOutlet]="displayTemplate" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
                </ion-item>
            </ion-list>
        </ion-content>
    </ng-template>
</ion-popover>
