import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'leftPad'
})
export class LeftPadPipe implements PipeTransform {

    transform(value: number, padLen: number, padChar?: string): string {
        const padCharacter = typeof padChar !== 'undefined' ? padChar : '0';
        const pad = new Array(1 + padLen).join(padCharacter);
        return (pad + value).slice(-pad.length);
    }
}
