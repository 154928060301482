<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon slot="icon-only" color="dark" name="close-circle-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>
            Assign Server
        </ion-title>
    </ion-toolbar>
    <ion-grid>
        <ion-row class="header">
            <ion-col>
                <ion-segment [(ngModel)]="source"
                             mode="ios">
                    <ion-segment-button value="timecards"
                                        class="tab-segment"
                                        (click)="optionsFromTimecards()">
                        <ion-label><h2>Clocked In</h2></ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="users"
                                        class="tab-segment"
                                        (click)="optionsFromUsers()">
                        <ion-label>
                            <h2>All Users</h2>
                        </ion-label>
                    </ion-segment-button>
                </ion-segment>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-header>

<ion-content>
    <bb-select-buttons [options]="options"
                       [selected]="selected"
                       (optionSelected)="selectServer($event)">
        <ng-template let-option #displayTemplate>
            {{ option.firstName }} {{option.lastName}}
        </ng-template>
    </bb-select-buttons>
</ion-content>
