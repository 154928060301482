import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {Constants} from '../_constants/constants';
import {OrganizationUser} from '../_models/organization-user';
import {BaseObservableService} from './base-obeservable-service';

@Injectable({
    providedIn: 'root'
})
export class OrganizationUserService extends BaseObservableService<OrganizationUser> {
    destination = 'organization-users';

    create(organizationUser: OrganizationUser) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '404,409');
        return this.http.post(`${this.baseUrl()}`, organizationUser, {headers});
    }

    assignPin(id: number, pinCode: string) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409');
        return this.http.post(`${this.baseUrl()}/${id}/pin-code`
            + (!!pinCode && pinCode.length === 4 ? `?pinCode=${pinCode}` : ''), {}, {headers});
    }
}
