import {BaseFormModel} from './base-form-model';

export class TimecardSummary extends BaseFormModel<TimecardSummary> {
  id: number;
  orgId: number;
  personId: number;
  personName: string;
  totalShiftDurationSecs: number;
  totalTippedShiftDurationSecs: number;
  cardCount: number;
}
