<ion-list>
    <ng-container *ngFor="let option of options; let index = index;">
        <ion-item (click)="itemClick(option)">
            <ion-label>
                <ion-text color="secondary"><strong *ngIf="option.selectionCount > 1">x{{option.selectionCount}}</strong></ion-text>
                {{ option.menuItem.name }}
                <p *ngIf="!!option.menuItemPrice && option.menuItemPrice.name !== 'Default'">{{ option.menuItemPrice.name }}</p>
            </ion-label>
            <ion-checkbox slot="start" [(ngModel)]="option.selected" (click)="checkboxClicked($event, option)"></ion-checkbox>
            <span slot="end" *ngIf="option.additionalPrice">{{ option.additionalPrice | currency}}</span>
            <div slot="end" *ngIf="option.deposit()">
                <span class="deposit-amount">{{ option.deposit() | currency}}</span>
                <span class="deposit-label">Deposit</span>
            </div>

            <ion-button *ngIf="terminalMode && !!option.menuItemPrice && !!option.menuItemPrice.menuItemInventory" slot="end" color="dark"
                        (click)="adjustInventory.emit(option.menuItemPrice.menuItemInventory)">
                {{ option.menuItemPrice.menuItemInventory.currentCount > 0 ? option.menuItemPrice.menuItemInventory.currentCount : 'Sold Out' }}
            </ion-button>

            <ion-badge
                    *ngIf="!terminalMode && !!option.menuItemPrice && !!option.menuItemPrice.menuItemInventory && option.menuItemPrice.menuItemInventory.currentCount < 1"
                    class="inventory-badge"
                    color="dark"
                    slot="end">
                Sold Out
            </ion-badge>

            <ion-icon *ngIf="!!option.menuItem.description || !!option.menuItem.imageAddress"
                      slot="end" name="information-circle-outline" (click)="info($event, option)"></ion-icon>
        </ion-item>

        <ion-item *ngIf="option.selected && option.isVariable()" [formGroup]="formGroup">
            <ion-label position="floating">Amount</ion-label>
            <ion-input inputmode="numeric" formControlName="amount_{{option.id}}" bbCurrencyMask (ionChange)="updateTotal(option)"
                       #amount></ion-input>
        </ion-item>

        <ng-container *ngIf="option.menuItem.selectionRequired">
            <ion-item *ngFor="let selectedOption of itemsForSelection(option)"
                      class="pointer"
                      (click)="editItem(selectedOption.orderItem)">
                <bb-display-order-item class="order-item"
                                       [item]="selectedOption.orderItem"
                                       [additionalPrice]="selectedOption.menuSelectionItem.additionalPrice"
                                       [hideHeader]="true"
                                       [removePadding]="true"
                                       [level]="1">
                </bb-display-order-item>
            </ion-item>
        </ng-container>
    </ng-container>
    <ion-item *ngIf="!menuItemSelection.menuSelection.required" (click)="clear()">
        <ion-label>
            None
        </ion-label>
        <ion-checkbox slot="start" [checked]="parentOrderItem.selections[selectionIndex].selectedItems.length === 0"></ion-checkbox>
    </ion-item>
</ion-list>
