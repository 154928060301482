import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SubscriberComponent} from '../subscriber.component';
import {MembershipProgram} from '../../_models/membership-program';
import {Organization} from '../../_models/organization';
import {OrganizationTerminalService} from '../../_services/organization-terminal.service';

@Component({
    selector: 'bb-select-membership-program',
    templateUrl: './select-membership-program.component.html',
    styleUrls: ['./select-membership-program.component.scss'],
})
export class SelectMembershipProgramComponent extends SubscriberComponent implements OnInit {
    @Input() membershipPrograms: MembershipProgram[];
    @Input() organization: Organization;

    @Output() selectedProgram = new EventEmitter<MembershipProgram>();

    isTerminal = false;

    constructor(private organizationTerminalService: OrganizationTerminalService) {
        super();
    }

    ngOnInit() {
        this.isTerminal = this.organizationTerminalService.isTerminal;
    }
}
