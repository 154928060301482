import {BaseFormModel} from './base-form-model';

export class MerchantAccount extends BaseFormModel<MerchantAccount> {
    id: number;
    publicKey: string;
    externalId: string;
    cardNotPresentAccountCredential: string;
    terminalAccountCredential: string;
    achAccountCredential: string;
    status: string;
}
