import {BaseFormModel} from '../base-form-model';
import {Column} from './column';
import {Filter} from './filter';
import {Sort} from './sort';
import {TabReportEmail} from './tab-report-email';

export class TabReport extends BaseFormModel<TabReport> {
    id: number;
    title: string;
    description: string;
    rowKey: string;
    startTime: Date;
    endTime: Date;
    columns: Column[];
    optionalFilters: Filter[];
    baseFilters: Filter[];
    sort: Sort[];
    orgId: number;
    venueId: number;
    parentId: number;
    durationPart: string;
    durationCount: number;
    tabReportEmail: TabReportEmail;

    selected: boolean;

    public constructor(jsonInput: any) {
        super(jsonInput);
        Object.assign(this, jsonInput);

        this.tabReportEmail = !!this.tabReportEmail ? new TabReportEmail(this.tabReportEmail) : null;
    }
}
