<bb-card-loading *ngIf="!timecards"></bb-card-loading>
<ng-container *ngIf="!!timecards">
    <span class="information-message">The selected date does not affect this information.</span>
    <ion-list>
        <ion-item *ngIf="timecards.length === 0">
            There are no clocked in employees.
        </ion-item>
        <ion-item *ngFor="let timecard of timecards; trackBy: getId" class="pointer" (click)="editTimecard(timecard)">
            <ion-label>
                {{ timecard.person.name }}
                <p>{{timecard.clockInTime | date:'short'}}</p>
                <p *ngIf="!!timecard.pickupLocation">{{timecard.pickupLocation.name}}</p>
            </ion-label>
            <div class="ion-text-center" slot="end">
                {{formatTotalTime(timecard)}}
                <ion-text *ngIf="timecard.tipped" color="success">
                    <p>Tipped</p>
                </ion-text>
                <p *ngIf="!timecard.clockOutTime" class="active-label">Active</p>
            </div>
        </ion-item>
    </ion-list>
</ng-container>
