import {Component, Inject, OnInit, Optional, Renderer2} from '@angular/core';

import {MenuController, NavController, Platform} from '@ionic/angular';
import {
    AuthenticationService,
    BaseAppComponent,
    CustomerAreaPosition,
    CustomerAreaPositionService,
    DeviceService,
    GlobalPayService,
    LibConfig,
    LibConfigService,
    LoadingService,
    Membership,
    MembershipService,
    Order,
    OrderModalService,
    OrderService,
    OrderStatuses,
    Organization,
    OrganizationService,
    ScriptService,
    Tab,
    TabService,
    User,
    UserNotificationSummary,
    UserNotificationSummaryService,
    UserService,
    Venue,
    VenueService
} from 'brewbill-lib';
import {Router} from '@angular/router';
import {EventService} from './_services/event.service';
import {environment} from '../../../../environments/environment';
import {getToken, Messaging, onMessage} from '@angular/fire/messaging';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent extends BaseAppComponent implements OnInit {
    notificationSummary: UserNotificationSummary;
    returnUrl = '/pickup-location-search';

    appVersion: string;
    currentOrganization: Organization;
    memberships: Membership[];

    constructor(
        @Inject(LibConfigService) private config: LibConfig,
        @Optional() messaging: Messaging,
        private eventService: EventService,
        private tabService: TabService,
        private orderService: OrderService,
        private orderModalService: OrderModalService,
        private customerAreaPositionService: CustomerAreaPositionService,
        private userNotificationSummaryService: UserNotificationSummaryService,
        private organizationService: OrganizationService,
        private venueService: VenueService,
        private membershipService: MembershipService,
        @Inject(DOCUMENT) document: Document,
        platform: Platform,
        authenticationService: AuthenticationService,
        userService: UserService,
        router: Router,
        navController: NavController,
        loadingService: LoadingService,
        deviceService: DeviceService,
        menu: MenuController,
        globalPayService: GlobalPayService,
        renderer: Renderer2,
        scriptService: ScriptService
    ) {
        super(
            document,
            platform,
            authenticationService,
            userService,
            router,
            navController,
            loadingService,
            deviceService,
            menu,
            globalPayService,
            renderer,
            scriptService
        );

        if (!!messaging) {
            navigator.serviceWorker.register('firebase-messaging-sw.js', {type: 'module', scope: '__'}).then(serviceWorkerRegistration =>
                getToken(messaging, {
                    serviceWorkerRegistration,
                    vapidKey: environment.firebase.vapidKey,
                }).then(token => {
                    this.userService.saveDevice(token);
                }));

            onMessage(messaging, (payload) => {
                console.log('Message received. ', payload);
            });
        } else {
            this.userService.saveDevice(null);
        }
        this.appVersion = config.appVersion;
    }

    ngOnInit(): void {
        this.subscribe(this.authenticationService.returnUrl.subscribe(url => this.returnUrl = !!url ? url : '/pickup-location-search'));
        this.subscribe(this.organizationService.current.subscribe(o => this.currentOrganization = !!o ? new Organization(o) : null));
        this.subscribe(this.membershipService.memberships.subscribe(m => this.memberships = m));
    }

    async logout() {
        await this.authenticationService.logout();
        await this.init(null);
    }

    async init(user: User) {
        if (!!user && user.initialized) {
            return;
        }

        const hash = location.hash;
        let orders: Order[];
        let tabs: Tab[];

        this.tabService.setOpenTabs(null);
        this.orderService.setActiveOrders(null);

        if (user && user.token) {
            this.initMobile();

            orders = await this.orderService.loadCurrent().toPromise() as Order[];
            if (!!orders && orders.length > 0) {
                const active = this.orderService.getCurrentActiveOrders();
                if (!!active) {
                    orders.concat(active);
                }
                this.orderService.setActiveOrders(orders);
            }

            tabs = await this.tabService.loadCurrent().toPromise() as Tab[];
            if (!!tabs && tabs.length > 0) {
                const openTabs = this.tabService.getCurrentOpenTabs();
                if (!!openTabs) {
                    tabs.concat(openTabs);
                }
                this.tabService.setOpenTabs(tabs);
            }
        }

        const deviceId = await this.deviceService.getDeviceId();
        await this.eventService.connect(deviceId);

        if (!!deviceId) {
            const currentActiveOrders = this.orderService.getCurrentActiveOrders();
            const deviceOrders = await this.orderService.loadDeviceCurrent(deviceId).toPromise() as Order[];
            const concatOrders = !!currentActiveOrders ? currentActiveOrders.concat(deviceOrders) : deviceOrders;
            this.orderService.setActiveOrders(concatOrders);

            if (!!concatOrders && concatOrders.length > 0) {
                const customerAreaPositionOrder = concatOrders.find(o => !!o.customerAreaPosition);
                if (!!customerAreaPositionOrder) {
                    this.customerAreaPositionService.setCurrent(new CustomerAreaPosition(customerAreaPositionOrder.customerAreaPosition));
                }
            }

            const currentOpenTabs = this.tabService.getCurrentOpenTabs();
            const deviceTabs = await this.tabService.loadDeviceOpen(deviceId).toPromise() as Tab[];
            this.tabService.setOpenTabs(!!currentOpenTabs ? currentOpenTabs.concat(deviceTabs) : deviceTabs);
        }

        this.platform.ready().then(async () => {
            // if (!this.platform.is('ios')) {
            //     await Geolocation.checkPermissions();
            // }
            //
            // this.geoLocationService.getPosition();

            // remove until push are supported by safari
            // if (Capacitor.isPluginAvailable('PushNotifications')) {
            //     PushNotifications.requestPermissions().then(result => {
            //         if (result.receive === 'granted') {
            //             PushNotifications.register();
            //         }
            //     });
            //
            //     PushNotifications.addListener('registration', (token: Token) => {
            //         this.userService.saveDevice(token.value);
            //     });
            //
            //     PushNotifications.addListener(
            //         'pushNotificationReceived',
            //         (notification: PushNotificationSchema) => {
            //             console.log('Push received: ' + JSON.stringify(notification));
            //             if (!!notification.data.marketingPushOrgId) {
            //                 this.notificationSummary.unseenNotifications++;
            //                 this.userNotificationSummaryService.setCurrent(this.notificationSummary);
            //             } else if (notification.data.showInForeground) {
            //                 this.toastService.message(notification.body);
            //             }
            //         });
            //
            //     PushNotifications.addListener(
            //         'pushNotificationActionPerformed',
            //         async (action: ActionPerformed) => {
            //             console.log('Push action performed: ' + JSON.stringify(action));
            //             if (!!action.notification.data.marketingPushOrgId) {
            //                 await this.navController.navigateForward('/marketing/' + action.notification.data.marketingPushOrgId);
            //             }
            //         });
            // }

            if (!!this.currentUser && !this.currentUser.login.confirmedEmail) {
                await this.navController.navigateForward('/confirm-email');
            } else if (hash === '#/login' || hash === '#/') {
                const hostNameInfo = location.hostname.split('.');
                if (hostNameInfo.length > 1) {
                    this.venueService.findBySubdomain(hostNameInfo[0]).subscribe(async (v: Venue) => {
                        if (!!v) {
                            this.venueService.setCurrent(v);
                            await this.navController.navigateRoot(`/venue/${v.id}`);
                        } else {
                            await this.navController.navigateRoot(hash === '#/login' && !user ? '/login' : this.returnUrl);
                        }
                    });
                } else {
                    await this.navController.navigateRoot(hash === '#/login' && !user ? '/login' : this.returnUrl);
                }
                this.authenticationService.setReturnUrl(null);
            } else if (!!user && !user.modalLogin) {
                await this.navController.navigateRoot(hash.substring(1));
            }

            if (!!orders && orders.some(o => o.status === OrderStatuses.READY)) {
                await this.orderModalService.viewOrder(orders.find(o => o.status === OrderStatuses.READY));
            }

            if (!!this.currentUser) {
                this.currentUser.initialized = true;
                this.userService.setCurrent(this.currentUser);
            }
            this.ready = true;
            this.dismiss();
        });
    }

    initMobile() {
        this.subscribe(this.userNotificationSummaryService.current.subscribe(s => {
            this.notificationSummary = new UserNotificationSummary(s);
        }));

        this.userNotificationSummaryService.list().subscribe((summary: UserNotificationSummary) => {
            this.userNotificationSummaryService.setCurrent(summary);
        });

        this.membershipService.findByUser().subscribe((m: Membership[]) => {
            this.membershipService.setMemberships(m);
        });
    }
}
