import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AnimationController} from '@ionic/angular';
import {Animation} from '@ionic/core/dist/types/utils/animation/animation-interface';

@Component({
    selector: 'bb-removable-item',
    templateUrl: './removable-item.component.html',
    styleUrls: ['./removable-item.component.scss'],
})
export class RemovableItemComponent {
    @Input() disableSliding;

    @ViewChild('removableItem', {read: ElementRef}) removableItem: ElementRef;

    @Output() removeItem: EventEmitter<any> = new EventEmitter();

    animation: Animation;
    removing = false;

    constructor(private animationController: AnimationController) {
    }

    async removeItemClicked() {
        if (!this.disableSliding) {
            await this.animate();
        }

        this.removeItem.emit();
    }

    async animate() {
        this.removing = true;
        const fade = this.animationController.create()
            .addElement(this.removableItem.nativeElement.children[1])
            .duration(50)
            .fromTo('opacity', '1', '0');

        const slideOut = this.animationController.create()
            .addElement(this.removableItem.nativeElement)
            .duration(300)
            .fromTo('height', `${this.removableItem.nativeElement.offsetHeight}px`, '0px')
            .fromTo('transform', 'translateX(0)', 'translateX(-100%)');

        this.animation = this.animationController.create('shrink-animation')
            .duration(300)
            .easing('ease-out')
            .addAnimation([slideOut, fade]);

        await this.animation.play();

        return Promise.resolve('complete');
    }

    async reverse() {
        this.removing = false;
        if (!!this.animation) {
            await this.animation.direction('reverse').play();
        }
    }
}
