import {Directive, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';
import {GestureController} from '@ionic/angular';

@Directive({
    selector: '[bbLongPress]',
})
export class LongPressDirective implements OnInit {
    @Output() longPress = new EventEmitter();
    longPressActive = false;
    moved: boolean;

    constructor(
        private gestureCtrl: GestureController,
        private el: ElementRef
    ) {
    }

    ngOnInit() {
        const longPress = this.gestureCtrl.create({
            el: this.el.nativeElement,
            threshold: 0,
            gestureName: 'long-press',
            onMove: ev => {
                this.moved = true;
            },
            onStart: ev => {
                this.longPressActive = true;
                this.moved = false;
                this.checkPress();
            },
            onEnd: ev => {
                this.longPressActive = false;
            }
        }, true); // Passing true will run the gesture callback inside of NgZone!

        // Don't forget to enable!
        longPress.enable(true);
    }

    checkPress(timeout = 300) {
        setTimeout(() => {
            if (this.longPressActive && !this.moved) {
                this.longPress.emit();
            }
        }, timeout);
    }
}
