import {Sortable} from '../_models/sortable';

export function reorder(list: Sortable[], event) {
    if (!!list && list.length > 1) {
        const itemMove = list.splice(event.detail.from, 1)[0];
        list.splice(event.detail.to, 0, itemMove);
        let i = 0;
        list.forEach(s => s.sortOrder = i++);
    }
    event.detail.complete();
}
