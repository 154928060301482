<div class="input-container filter-action-button-container">
    <ion-item>
        <ion-label position="floating">Tax ID</ion-label>
        <ion-input type="text"
                   [(ngModel)]="taxExemptionId"
                   (keyup)="handleKeyUp($event)"
                   [clearInput]="true"
                   #taxExemption>
        </ion-input>
    </ion-item>
    <ion-button color="dark" (click)="saveTaxExemptionId()">
        <ion-icon name="save-outline" slot="icon-only"></ion-icon>
    </ion-button>
</div>
