<ion-card [class.active]="active"
          [class.waiting-received]="order.status === orderStatuses.RECEIVED && !order.terminalOrder && !active"
          [class.waiting-preparing]="order.status === orderStatuses.PREPARING && !order.terminalOrder && !active"
          [class.waiting-ready]="order.status === orderStatuses.READY && !order.terminalOrder && !active">
    <ion-card-header
            [color]="(includePaymentStatus && !order.invoiceCreated && (order.tabPaymentStatus === paymentStatuses.UNPAID || order.tabPaymentStatus === paymentStatuses.PARTIAL_PAID || order.tabPaymentStatus === paymentStatuses.OVER_PAID)) ? 'danger' : order.status | orderStatusColor"
            (click)="header($event)">
        <ion-card-title>
            <div class="card-title">
                <ion-icon *ngIf="!order.terminalOrder" name="phone-portrait-outline" class="mobile-order"></ion-icon>
                <span>{{!!order.tabPersonName ? order.tabPersonName : 'Terminal Order'}} ({{order.date | dateAgo:true}})</span>
            </div>
        </ion-card-title>
        <ion-card-subtitle *ngIf="includeCustomerAreaPosition"
                           class="pointer"
                           (click)="changeCustomerPosition($event)">
            {{!!order.customerAreaPosition ? order.customerAreaPosition.formattedValue() : 'Pickup'}}
        </ion-card-subtitle>
        <div *ngIf="!details || !!order.orderMarker || order.orderMarker === 0; else detailsElseBlock" class="header-details ion-text-center"
             (click)="viewDetailsClicked($event)">
            <ng-container *ngIf="order.status === orderStatuses.READY; else elseBlock">
                <div *ngIf="!!order.orderMarker || order.orderMarker === 0" class="order-marker">{{order.orderMarker}}</div>
                <div *ngIf="!order.orderMarker && order.orderMarker !== 0 && !!order.customerAreaPosition" class="order-marker">
                    {{order.customerAreaPosition.formattedValue()}}
                </div>
            </ng-container>
            <ng-template #elseBlock>
                <span *ngIf="!details && (!includePaymentStatus || order.invoiceCreated || order.tabPaymentStatus === paymentStatuses.PAID || order.tabPaymentStatus === paymentStatuses.PRE_AUTH)"
                      class="details-message">Details</span>
                <span *ngIf="includePaymentStatus && !order.invoiceCreated && (order.tabPaymentStatus === paymentStatuses.UNPAID || order.tabPaymentStatus === paymentStatuses.PARTIAL_PAID || order.tabPaymentStatus === paymentStatuses.OVER_PAID)"
                      class="details-message">Payment Issue</span>
            </ng-template>

            <ion-buttons *ngIf="canPrint" class="print-button">
                <ion-button (click)="print($event)">
                    <ion-icon name="print-outline" slot="icon-only"></ion-icon>
                </ion-button>
            </ion-buttons>
        </div>
        <ng-template #detailsElseBlock>
            <div class="header-details ion-text-center">
                <ion-buttons *ngIf="canPrint" class="print-button">
                    <ion-button (click)="print($event)">
                        <ion-icon name="print-outline" slot="icon-only"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </div>
        </ng-template>
    </ion-card-header>
    <ion-card-content>
        <bb-display-order [order]="order"
                          [compact]="!details"
                          [details]="details"
                          [editable]="editable"
                          [selectedItemIds]="selectedItemIds"
                          [availableOrderComps]="availableOrderComps"
                          (addCredit)="addCredit.emit($event)"
                          (removeCredit)="removeCredit.emit($event)"
                          (removeTokens)="removeTokens.emit($event)"
                          (itemsSelected)="toggleSelect($event)"
                          (layout)="layout.emit()">
        </bb-display-order>

        <ion-grid #statusGrid>
            <ion-row>
                <ion-col size-xs="12">
                    <ion-segment [(ngModel)]="currentStatus"
                                 mode="ios"
                                 [scrollable]="true"
                                 (ionChange)="confirmStatusChange($event.detail.value)">
                        <ion-segment-button [disabled]="currentStatus === orderStatuses.CANCELED" value="RECEIVED" class="status-received">
                            <ion-label>
                                <h2 *ngIf="fullText">Received</h2>
                                <h2 *ngIf="!fullText">Rec</h2>
                            </ion-label>
                        </ion-segment-button>
                        <ion-segment-button [disabled]="currentStatus === orderStatuses.CANCELED" value="PREPARING" class="status-preparing">
                            <ion-label>
                                <h2 *ngIf="fullText">Preparing</h2>
                                <h2 *ngIf="!fullText">Prp</h2>
                            </ion-label>
                        </ion-segment-button>
                        <ion-segment-button [disabled]="currentStatus === orderStatuses.CANCELED" value="READY" class="status-ready">
                            <ion-label>
                                <h2 *ngIf="fullText">Ready</h2>
                                <h2 *ngIf="!fullText">Rdy</h2>
                            </ion-label>
                        </ion-segment-button>
                        <ion-segment-button [disabled]="currentStatus === orderStatuses.CANCELED" value="DELIVERED" class="status-delivered">
                            <ion-label>
                                <h2 *ngIf="fullText">Delivered</h2>
                                <h2 *ngIf="!fullText">Dlv</h2>
                            </ion-label>
                        </ion-segment-button>
                        <ion-segment-button [disabled]="currentStatus === orderStatuses.CANCELED || !authorizedToCancel()" value="CANCELED"
                                            class="status-canceled">
                            <ion-label>
                                <h2 *ngIf="fullText">Canceled</h2>
                                <h2 *ngIf="!fullText">Cncl</h2>
                            </ion-label>
                        </ion-segment-button>
                    </ion-segment>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="includeServerOptions">
                <ion-col *ngIf="!!order.server">
                    <ion-button class="full-footer-button" (click)="assignServer()" color="dark">
                        {{order.server.name}}
                    </ion-button>
                </ion-col>
                <ion-col *ngIf="!order.server">
                    <ion-button class="paired-button" (click)="claimServer()" color="dark">
                        Claim
                    </ion-button>
                    <ion-button class="paired-button" (click)="assignServer()" color="dark">
                        Assign
                    </ion-button>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="includeMoveOption && currentUser.authorizedTo(featureKeys.ORDER_MOVE, order.orgId)">
                <ion-col>
                    <ion-button class="full-footer-button"
                                color="dark"
                                (click)="move()">
                        Move
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
</ion-card>
