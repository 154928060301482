<modal-header [title]="organization.name"
              [subHeader]="order.pickupLocation ? order.pickupLocation.name : ''"
              [color]="order.status | orderStatusColor"></modal-header>
<ion-content>
    <ion-item [color]="order.status | orderStatusColor">
        <ion-label>
            {{!!order.customerAreaPosition ? order.customerAreaPosition.formattedValue() : 'Pickup'}}
            <p>{{order.formattedDate()}}</p>
        </ion-label>
        <span [ngClass]="{'pill-danger' : order.status === orderStatuses.CANCELED, 'pill-success': order.status === orderStatuses.DELIVERED}">
            {{order.status | orderStatus}}
        </span>
    </ion-item>
    <ion-card id="directions" *ngIf="!!order && order.status === orderStatuses.READY && !!pickupLocation">
        <ion-card-header (click)="toggleDirections()" color="secondary">
            <ion-card-title>
                {{expandedDirections ? 'Hide Directions' : 'Tap For Directions'}}
                <ion-icon [name]="expandedDirections ? 'caret-up' : 'map-outline'"></ion-icon>
            </ion-card-title>
        </ion-card-header>
        <ion-card-content class="ion-no-padding expand-wrapper" [class.collapsed]="!expandedDirections">
            <img *ngIf="pickupLocation.imageAddress" [src]="pickupLocation.imageAddress" alt="">
            <div class="ion-padding directions">{{pickupLocation.direction}}</div>
        </ion-card-content>
    </ion-card>

    <ion-card *ngIf="!!order && order.status === orderStatuses.READY">
        <ion-card-content>
            <div *ngIf="!!order.orderMarker || order.orderMarker === 0" class="order-marker" #marker>{{order.orderMarker}}</div>
            <p>Head to the BrewBill pickup location to pick up your order. Please make sure to have the following:</p>
            <ul>
                <li>This device with this number displayed.</li>
                <li>Your ID if your order includes alcohol.</li>
            </ul>
        </ion-card-content>
    </ion-card>

    <bb-display-order [order]="order"></bb-display-order>

    <bb-tip *ngIf="!organization.allowTabs && organization.allowTips && !!order && !order.status"
            [(tip)]="order.tip"
            [tabTotal]="order.total"></bb-tip>

    <ion-card *ngIf="!order.status">
        <ion-card-header>{{organization.name}}</ion-card-header>
        <ion-card-content>
            {{order.pickupLocation.name}}
        </ion-card-content>
    </ion-card>
    <ion-card *ngIf="!order.status && !organization.allowTabs && !!currentUser && !!cards">
        <ion-card-header>Select Card</ion-card-header>
        <ion-card-content>
            <ion-item class='payment-method' *ngIf="!!cards && cards.length > 0">
                <ng-container *ngIf="!!cards && cards.length === 1">
                    <img src="assets/card-logo/{{selectedCard.cardType}}.png" class="card-image" slot="start" alt="">
                    <ion-label>
                        {{selectedCard.lastFour}}
                    </ion-label>
                </ng-container>
                <ion-select interface="action-sheet" [(ngModel)]="selectedCard" *ngIf="!!cards && cards.length > 1"
                            [class]="convertCardToClass(selectedCard)">
                    <ion-select-option *ngFor="let card of cards" [value]="card" class="credit-card" [class]="convertCardToClass(card)">
                        {{card.lastFour}}
                    </ion-select-option>
                </ion-select>
            </ion-item>
        </ion-card-content>
    </ion-card>

    <ion-card *ngIf="!order.status && !organization.allowTabs && !currentUser">
        <ion-card-header>Person</ion-card-header>
        <ion-card-content>
            <form [formGroup]="personFormGroup">
                <bb-person-form (formReady)="personFormInitialized($event)"></bb-person-form>
            </form>
        </ion-card-content>
    </ion-card>

    <ion-card *ngIf="!order.status && !organization.allowTabs && (!currentUser || !cards)">
        <ion-card-header>Card</ion-card-header>
        <ion-card-content>
            <bb-credit-card-form [organization]="organization"></bb-credit-card-form>
        </ion-card-content>
    </ion-card>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-button *ngIf="order.status"
                class="footer-button"
                [class]="order.status === orderStatuses.RECEIVED && !!tab && tab.id === order.parentId && tab.status === tabStatuses.OPEN ? 'paired-button':'single-button'"
                color="dark" (click)="close()">
        Close Window
    </ion-button>
</ion-footer>

