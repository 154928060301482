import {Component, Input} from '@angular/core';
import {MenuItemInventory} from '../../_models/menu-item-inventory';
import {BaseModalComponent} from '../base-modal.component';
import {ModalController} from '@ionic/angular';
import {LoadingService} from '../../_services/loading.service';
import {MenuItemInventoryActionService} from '../../_services/menu-item-inventory-action.service';
import {MenuItemInventoryService} from '../../_services/menu-item-inventory.service';
import {ToastService} from '../../_services/toast.service';

@Component({
    selector: 'bb-kick-keg',
    templateUrl: './kick-keg.component.html',
    styleUrls: ['./kick-keg.component.scss'],
})
export class KickKegComponent extends BaseModalComponent {
    @Input() menuItemInventory: MenuItemInventory;
    @Input() backOfHouseInventory: MenuItemInventory;

    constructor(
        modalController: ModalController,
        private menuItemInventoryActionService: MenuItemInventoryActionService,
        private menuItemInventoryService: MenuItemInventoryService,
        private toastService: ToastService,
        private loadingService: LoadingService
    ) {
        super(modalController);
    }

    kickOnly() {
        this.loadingService.present();
        this.menuItemInventoryActionService.kick(this.menuItemInventory.id).subscribe(m => {
            if (!!m) {
                this.menuItemInventoryService.setEvent(new MenuItemInventory(m));
            }
            this.loadingService.dismiss();
            this.toastService.success(`${this.menuItemInventory.menuItem.name} keg kicked.`);
            this.close();
        });
    }

    kickAndReplace() {
        this.loadingService.present();
        this.menuItemInventoryActionService.kickAndReplace(this.menuItemInventory.id).subscribe(m => {
            if (!!m) {
                this.menuItemInventoryService.setEvent(new MenuItemInventory(m));
            }
            this.loadingService.dismiss();
            this.toastService.success(`${this.menuItemInventory.menuItem.name} keg replaced.`);
            this.close();
        });
    }
}
