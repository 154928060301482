import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {LibConfig, LibConfigService} from '../lib.config';
import {BaseService} from './base-service';
import {BehaviorSubject, Observable} from 'rxjs';
import {saveTimeBased} from '../_utils/local-storage-utils';

@Injectable()
export abstract class BaseObservableService<T> extends BaseService<T> {
    public current: Observable<T>;
    public event: Observable<T>;
    public saveToLocalStorage = false;
    public initialValue: T = null;
    public touched: T;
    abstract readonly destination: string;
    protected currentSubject: BehaviorSubject<T>;
    private eventSubject: BehaviorSubject<T>;

    protected constructor(
        http: HttpClient,
        @Inject(LibConfigService) config: LibConfig
    ) {
        super(http, config);

        this.currentSubject = new BehaviorSubject<T>(this.initialValue);
        this.current = this.currentSubject.asObservable();

        this.eventSubject = new BehaviorSubject<T>(null);
        this.event = this.eventSubject.asObservable();
    }

    public get currentValue(): T {
        return this.currentSubject.value;
    }

    setCurrent(obj: T) {
        if (this.saveToLocalStorage) {
            saveTimeBased(this.destination, obj);
        }
        this.currentSubject.next(obj);
    }

    setEvent(obj: T) {
        this.eventSubject.next(obj);
    }
}
