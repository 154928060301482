import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {TabCredit} from '../_models/tab-credit';
import {HttpHeaders} from '@angular/common/http';
import {Constants} from '../_constants/constants';

@Injectable({
    providedIn: 'root'
})
export class TabCreditService extends BaseService<TabCredit> {
    destination = 'tab-credits';

    create(credit: TabCredit) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '409');
        return this.http.post(this.baseUrl(), credit, {headers});
    }
}
