<modal-header title="Pay With Existing Card"></modal-header>
<ion-content>
    <ion-grid *ngIf="!!tab">
        <ion-row>
            <ion-col class="ion-padding-start">
                <ion-label position="stacked">Due:</ion-label>
                <h1>{{principal | currency}}</h1>
            </ion-col>
            <ion-col *ngIf="!!tip">
                <ion-label position="stacked">Tip:</ion-label>
                <h1>{{tip | currency}}</h1>
            </ion-col>
            <ion-col *ngIf="!!surcharge">
                <ion-label position="stacked">Surcharge:</ion-label>
                <h1>{{surcharge | currency}}</h1>
            </ion-col>
            <ion-col>
                <ion-label position="stacked">Total:</ion-label>
                <h1>{{total() | currency}}</h1>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <bb-tip [(tip)]="tip"
                        [tabTotal]="tab.paymentInformation.remaining"
                        [hideTip]="true">
                </bb-tip>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-list>
                    <ion-radio-group [(ngModel)]="selectedCardSummary">
                        <ion-item *ngFor="let cardSummary of cardSummaries; trackBy: getId;">
                            <ion-label>
                                <img class="card-image"
                                     alt="payment.cardSummary.cardType"
                                     src="assets/card-logo/{{cardSummary.cardType}}.png"/>
                                <div class="card-details">
                                    <p>{{cardSummary.personName}}</p>
                                    <p>{{cardSummary.lastFour}}</p>
                                </div>
                            </ion-label>
                            <ion-radio slot="start" [value]="cardSummary" color="success"></ion-radio>
                        </ion-item>
                    </ion-radio-group>
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<modal-footer (submitClicked)="onSubmit()"></modal-footer>
