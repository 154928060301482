import {Component, Input} from '@angular/core';

@Component({
    selector: 'bb-password-requirements',
    templateUrl: './password-requirements.component.html',
    styleUrls: ['./password-requirements.component.css']
})
export class PasswordRequirementsComponent {
    @Input() password: string;
    @Input() confirmPassword: string;

    passwordLength() {
        return this.password.length >= 8;
    }

    passwordSpecial() {
        return (/^(?=.*[0-9]|[$@$!%*?&])/.test(this.password));
    }

    passwordUpper() {
        return (/^(?=.*[a-z])((?=.*[A-Z]))/.test(this.password));
    }

    match() {
        return !!this.password && this.password === this.confirmPassword;
    }
}
