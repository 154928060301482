import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BaseForm} from '../base-form';
import {Organization} from '../../../_models/organization';
import {LibConfig, LibConfigService} from '../../../lib.config';
import {GlobalPayService} from '../../../_services/global-pay.service';

@Component({
    selector: 'bb-credit-card-form',
    templateUrl: './credit-card-form.component.html',
    styleUrls: ['./credit-card-form.component.scss']
})
export class CreditCardFormComponent extends BaseForm implements OnInit {
    @Input() hideNickname = false;
    @Input() organization: Organization;
    @Input() buttonText = 'Submit';
    @Input() externalValid = true;
    @Input() hideCancel = false;
    @Output() cancel = new EventEmitter();
    @Output() token = new EventEmitter<string>();
    @Output() valid = new EventEmitter<boolean>();

    error: any;
    cardType: string;
    validCvv: boolean;
    validNumber: boolean;
    validExpiration: boolean;
    isSafari = false;
    buttonLoaded = false;
    numberLoaded = false;
    expirationLoaded = false;
    cvvLoaded = false;
    loaded = false;
    loadFailed = false;
    cardForm: any;

    constructor(
        @Inject(LibConfigService) private config: LibConfig,
        private formBuilder: FormBuilder,
        private globalPayService: GlobalPayService
    ) {
        super();
    }

    ngOnInit() {
        const userAgentString = navigator.userAgent;
        this.isSafari = !!userAgentString && userAgentString.indexOf('Safari') > -1 && userAgentString.indexOf('Chrome') === -1;

        this.formGroup = this.setupFormGroup();

        this.globalPayService.ready.subscribe(ready => {
            if (ready) {
                this.cardForm = this.globalPayService.initializeForm();

                this.checkLoaded(1);

                this.cardForm.on('token-success', resp => {
                    // add a payment token to form as a hidden input
                    const token = document.createElement('input');
                    token.type = 'hidden';
                    token.name = 'temporary_token';
                    token.value = 'resp.temporary_token';

                    this.token.emit(resp.temporary_token);
                });

                this.cardForm.on('token-error', resp => {
                    console.log(resp);
                });

                this.cardForm.on('card-number-test', resp => {
                    this.validNumber = !!(!!resp && resp.valid);
                    if (this.validNumber) {
                        this.globalPayService.setFocus(this.cardForm.frames['card-expiration'].id);
                    }
                    this.valid.emit(this.isValid());
                });

                this.cardForm.on('card-expiration-test', resp => {
                    this.validExpiration = !!(!!resp && resp.valid);

                    if (this.validExpiration) {
                        this.globalPayService.setFocus(this.cardForm.frames['card-cvv'].id);
                    }
                    this.valid.emit(this.isValid());
                });

                this.cardForm.on('card-cvv-test', resp => {
                    this.validCvv = !!(!!resp && resp.valid);
                    this.valid.emit(this.isValid());
                });

                this.globalPayService.setFocus('gp-expiration');
            }
        });
    }

    checkLoaded(attempt) {
        setTimeout(() => {
            if (!this.buttonLoaded) {
                const el = document.getElementById('gp-submit');
                if (!!el && !!el.firstChild) {
                    // @ts-ignore
                    el.firstChild.width = '100%';
                    this.buttonLoaded = true;
                }
            }

            if (!this.numberLoaded) {
                const el = document.getElementById('gp-card-number');
                if (!!el && !!el.firstChild) {
                    this.numberLoaded = true;
                }
            }

            if (!this.expirationLoaded) {
                const el = document.getElementById('gp-card-expiration');
                if (!!el && !!el.firstChild) {
                    this.expirationLoaded = true;
                }
            }

            if (!this.cvvLoaded) {
                const el = document.getElementById('gp-card-cvv');
                if (!!el && !!el.firstChild) {
                    this.cvvLoaded = true;
                }
            }

            if (this.buttonLoaded && this.numberLoaded && this.expirationLoaded && this.cvvLoaded) {
                this.loaded = true;
            } else if ((!this.buttonLoaded || !this.numberLoaded || !this.expirationLoaded || !this.cvvLoaded) && attempt < 100) {
                this.checkLoaded(attempt + 1);
            } else if (attempt >= 100) {
                this.loadFailed = true;
            }
        }, 100);
    }

    setCardNumberFocus() {
        this.globalPayService.setFocus(this.cardForm.frames['card-number'].id);
    }

    setupFormGroup(): FormGroup {
        return this.formBuilder.group({
            nickname: ''
        });
    }

    isValid() {
        return this.formGroup.valid && this.validNumber && this.validCvv && this.validExpiration && this.externalValid;
    }
}
