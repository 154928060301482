<ion-list *ngIf="!!order && !allowGrid">
    <ng-container *ngFor="let itemSummary of itemSummaries; let itemIndex = index; trackBy: getKey">
        <ion-item *ngIf="compact">
            <ion-icon *ngIf="itemSummary.items[0].orderType === availabilities.TOGO" name="car-outline" class="togo-indicator"
                      slot="start"></ion-icon>
            <bb-compact-order-item [items]="itemSummary.items"
                                   [item]="itemSummary.items[0]"
                                   [level]="1"
                                   [editable]="editable"
                                   [selectedItemIds]="selectedItemIds"
                                   [orderStatus]="order.status"
                                   (itemsSelected)="itemsSelected.emit($event)"
                                   (expandChanged)="layout.emit()">
            </bb-compact-order-item>
        </ion-item>

        <ion-item *ngIf="(!compact && !editable) || (!compact && !!order.status && order.status !== orderStatuses.PENDING)"
                  [class.selected]="allSelected(itemSummary)"
                  class="pointer"
                  (click)="openItemSummary.emit(itemSummary)">
            <ion-icon *ngIf="itemSummary.items[0].orderType === availabilities.TOGO" name="car-outline" color="dark" slot="start"></ion-icon>
            <bb-order-item-summary [itemSummary]="itemSummary"
                                   [compact]="compact"
                                   [editable]="editable"
                                   [availableOrderComps]="availableOrderComps"
                                   [selectedItemIds]="selectedItemIds"
                                   [details]="details"
                                   [orderStatus]="order.status"
                                   (addCredit)="addCredit.emit($event)"
                                   (removeCredit)="removeCredit.emit($event)"
                                   (removeTokens)="removeTokens.emit($event)"
                                   (itemsSelected)="itemsSelected.emit($event)">
            </bb-order-item-summary>
        </ion-item>

        <bb-removable-item *ngIf="editable && (!order.status || order.status === orderStatuses.PENDING)"
                           (removeItem)="removeItemSummaryClicked(itemIndex, itemSummary)">
            <ion-item class="pointer" lines="none" (click)="openItemSummary.emit(itemSummary)">
                <bb-order-item-summary [itemSummary]="itemSummary"
                                       [compact]="compact"
                                       [selectedItemIds]="selectedItemIds"
                                       [details]="details"
                                       [editable]="editable"
                                       [orderStatus]="order.status"
                                       [availableOrderComps]="availableOrderComps"
                                       (addCredit)="addCredit.emit($event)"
                                       (removeCredit)="removeCredit.emit($event)"
                                       (removeTokens)="removeTokens.emit($event)"
                                       (updateItem)="updateItem.emit($event)"
                                       (add)="addToSummary.emit(itemSummary)"
                                       (remove)="removeSingleItem(itemIndex, itemSummary)"
                                       (itemsSelected)="itemsSelected.emit($event)">
                </bb-order-item-summary>
            </ion-item>
        </bb-removable-item>
    </ng-container>

    <ion-item *ngIf="details && order.total > 0">
        <ion-grid>
            <ion-row>
                <ion-col size="12" class="ion-text-end">
                    Total: <span class="tax-item">{{order.total | currency }}</span>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-item>
</ion-list>

<bb-masonry-grid *ngIf="!!order && allowGrid" [gridCssClass]="gridCssClass" (gridLoaded)="gridLoaded()">
    <ng-container *ngIf="loaded">
        <bb-masonry-grid-item *ngFor="let itemSummary of itemSummaries; let itemIndex = index; trackBy: getKey"
                              [id]="'order' + order.id">
            <ion-card (click)="updateGridLayout()">
                <ion-card-content class="item-information">
                    <ion-item *ngIf="compact">
                        <ion-icon *ngIf="itemSummary.items[0].orderType === availabilities.TOGO" name="car-outline" class="togo-indicator"
                                  slot="start"></ion-icon>
                        <bb-compact-order-item [items]="itemSummary.items"
                                               [item]="itemSummary.items[0]"
                                               [level]="1"
                                               [editable]="editable"
                                               [availableOrderComps]="availableOrderComps"
                                               [selectedItemIds]="selectedItemIds"
                                               [orderStatus]="order.status"
                                               (addCredit)="addCredit.emit($event)"
                                               (removeCredit)="removeCredit.emit($event)"
                                               (removeTokens)="removeTokens.emit($event)"
                                               (itemsSelected)="itemsSelected.emit($event)"
                                               (expandChanged)="layout.emit()">
                        </bb-compact-order-item>
                    </ion-item>

                    <ion-item *ngIf="!editable || (!compact && !!order.status && order.status !== orderStatuses.PENDING)"
                              [class.selected]="allSelected(itemSummary)"
                              class="pointer"
                              (click)="openItemSummary.emit(itemSummary)">
                        <bb-order-item-summary [itemSummary]="itemSummary"
                                               [compact]="compact"
                                               [editable]="editable"
                                               [selectedItemIds]="selectedItemIds"
                                               [details]="details"
                                               [orderStatus]="order.status"
                                               [showImageOverride]="showImageOverride"
                                               [availableOrderComps]="availableOrderComps"
                                               (addCredit)="addCredit.emit($event)"
                                               (removeCredit)="removeCredit.emit($event)"
                                               (itemsSelected)="itemsSelected.emit($event)"
                                               (imageLoaded)="updateGridLayout()">
                        </bb-order-item-summary>
                    </ion-item>

                    <bb-removable-item *ngIf="editable && (!order.status || order.status === orderStatuses.PENDING)"
                                       (removeItem)="removeItemSummaryClicked(itemIndex, itemSummary)">
                        <ion-item class="pointer" lines="none" (click)="openItemSummary.emit(itemSummary)">
                            <bb-order-item-summary [itemSummary]="itemSummary"
                                                   [compact]="compact"
                                                   [selectedItemIds]="selectedItemIds"
                                                   [details]="details"
                                                   [editable]="editable"
                                                   [availableOrderComps]="availableOrderComps"
                                                   [orderStatus]="order.status"
                                                   (addCredit)="addCredit.emit($event)"
                                                   (removeCredit)="removeCredit.emit($event)"
                                                   (removeTokens)="removeTokens.emit($event)"
                                                   (updateItem)="updateItem.emit($event)"
                                                   (add)="addToSummary.emit(itemSummary)"
                                                   (remove)="removeSingleItem(itemIndex, itemSummary)"
                                                   (itemsSelected)="itemsSelected.emit($event)"
                                                   (imageLoaded)="updateGridLayout()">
                            </bb-order-item-summary>
                        </ion-item>
                    </bb-removable-item>
                </ion-card-content>
            </ion-card>
        </bb-masonry-grid-item>
    </ng-container>
</bb-masonry-grid>
