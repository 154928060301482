<div class="mobile-menu-container">
    <div class="mobile-menu" [class.mobile-menu-full-screen]="!editMode">
        <div *ngIf="!!menu" class="mobile-menu-header">
            <div *ngIf="terminalMode" class="menu-select-container">
                <div *ngIf="terminalMode" class="menu-select-help quick-menu-select">
                    <ion-item lines="none">
                        <ion-toggle class="ion-toggle-small" [(ngModel)]="showImages" (ionChange)="saveViewImages()"></ion-toggle>
                        <ion-label>Images</ion-label>
                    </ion-item>
                    <ion-item lines="none">
                        <ion-toggle class="ion-toggle-small" [(ngModel)]="showInventory" (ionChange)="saveShowInventory()"></ion-toggle>
                        <ion-label>Inventory</ion-label>
                    </ion-item>

                    <ion-item lines="none">
                        <ion-toggle class="ion-toggle-small" [(ngModel)]="sortAlpha" (ionChange)="toggleSortAlphabetical()"></ion-toggle>
                        <ion-label><span>A</span>
                            <ion-icon name="arrow-forward-outline"></ion-icon>
                            <span>Z</span></ion-label>
                    </ion-item>
                </div>
            </div>
            <!--            <div *ngIf="!!menuMarqueeItems && menuMarqueeItems.length > 0" class="marquee">-->
            <!--                <bb-scrolling-marquee [items]="menuMarqueeItems"-->
            <!--                                      (menuItemClicked)="openItemModal($event)"></bb-scrolling-marquee>-->
            <!--            </div>-->
            <div *ngIf="!terminalMode && !!featuredItems && featuredItems.length > 0" class="row">
                <div class="featured">
                    <div *ngFor="let item of featuredItems" class="pointer" (click)="openItemModal(item.menuItem)">
                        <div class="featured-item-backdrop"
                             [style.background-color]="'var(--ion-color-' + item.menuItem.featuredColor +')'">&nbsp;
                        </div>
                        <div class="featured-item-container"
                             [style.color]="'var(--ion-color-' + item.menuItem.featuredColor +'-contrast)'">
                            <div class="featured-item-information">
                                <div class="featured-item-name">{{ item.menuItem.name }}</div>
                                <div class="featured-item-flavor">{{ item.menuItem.flavorText }}</div>
                                <div *ngIf="!!item.menuItem.defaultPrice"
                                     class="featured-item-price">{{ item.menuItem.defaultPrice.price | currency }}
                                </div>
                            </div>
                            <img [src]="item.menuItem.imageAddress" class="featured-item-image" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!!menuCategories && menuCategories.length > 1" class="row category-table-of-contents-container">
                <ion-segment [(ngModel)]="currentCategory"
                             class="category-table-of-contents"
                             mode="md"
                             [scrollable]="true"
                             (scroll)="scrollCategoryTableOfContents()"
                             (load)="scrollCategoryTableOfContents()"
                             #categoryTableOfContents>
                    <ion-segment-button *ngFor="let cat of menuCategories" [value]="cat" class="menu-category-list" (click)="scrollToCat(cat)"
                                        #categorySegmentButton>
                        <ion-label>{{ cat.name }}</ion-label>
                    </ion-segment-button>
                </ion-segment>
            </div>
        </div>
        <div class="mobile-menu-body-container">
            <div class="mobile-menu-body" (scroll)="parentScroll()" #scrollArea>
                <ion-item *ngIf="!menuCategories || menuCategories.length === 0" lines="none">
                    No items are currently available at this location.
                </ion-item>
                <ng-container *ngFor="let cat of menuCategories; trackBy: getId;">
                    <ion-grid [id]="cat.id"
                              class="category-container" #menuCategoryContainer>
                        <ion-row>
                            <ion-col *ngFor="let item of cat.options; trackBy: getId;"
                                     size-xs="6"
                                     [sizeSm]="!editMode ? 4 : 6"
                                     [sizeLg]="!editMode ? 3 : 6"
                                     [sizeXl]="!editMode ? 2 : 6"
                                     (click)="checkMode(item.menuItem)"
                                     class="menu-item-column pointer">
                                <div *ngIf="!isMenuCategoryItemGroup(item)" bbLongPress (longPress)="openItemModal(item.menuItem)">
                                    <bb-mobile-menu-item [item]="item"
                                                         [rewardProgram]="rewardProgram"
                                                         [activeCredit]="activeCredit"
                                                         [showInventory]="showInventory"
                                                         [color]="cat.color"
                                                         [contrastColor]="cat.contrastColor"
                                                         (openItemModal)="openItemModal(item.menuItem)"></bb-mobile-menu-item>
                                </div>
                                <div *ngIf="isMenuCategoryItemGroup(item)" (click)="openItemGroup($event, item)">
                                    <bb-menu-item-image nameOverride="{{item.menuItemGroup.name}}"
                                                        [menuCategory]="cat"
                                                        [imageAddressOverride]="item.menuItemGroup.imageAddress"
                                                        [showInventory]="false"></bb-menu-item-image>
                                    <div *ngIf="!!item.menuItemGroup.imageAddress && showImages" class="menu-item-info-container">
                                        {{ item.menuItemGroup.name }}
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ng-container>
                <ion-grid *ngIf="terminalMode"
                          class="category-container">
                    <ion-row>
                        <ion-col size-xs="6"
                                 [sizeSm]="!editMode ? 4 : 6"
                                 [sizeLg]="!editMode ? 3 : 6"
                                 [sizeXl]="!editMode ? 2 : 6"
                                 (click)="openItemModal(null)"
                                 class="menu-item-column pointer">
                            <div class="menu-item-container">
                                <bb-menu-item-image nameOverride="Misc"></bb-menu-item-image>
                            </div>
                        </ion-col>
                        <ion-col size-xs="6"
                                 [sizeSm]="!editMode ? 4 : 6"
                                 [sizeLg]="!editMode ? 3 : 6"
                                 [sizeXl]="!editMode ? 2 : 6"
                                 (click)="loadGiftCard(null)"
                                 class="menu-item-column pointer">
                            <div class="menu-item-container">
                                <bb-menu-item-image nameOverride="Gift Card"></bb-menu-item-image>
                            </div>
                        </ion-col>
                        <ion-col size-xs="6"
                                 [sizeSm]="!editMode ? 4 : 6"
                                 [sizeLg]="!editMode ? 3 : 6"
                                 [sizeXl]="!editMode ? 2 : 6"
                                 (click)="renewMembership.emit(null)"
                                 class="menu-item-column pointer">
                            <div class="menu-item-container">
                                <bb-menu-item-image nameOverride="Membership"></bb-menu-item-image>
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>

                <ion-button *ngIf="terminalMode || (!!pickupLocation && pickupLocation.status === 'ACTIVE')"
                            class="tray ion-hide-md-up"
                            [class.tray-display]="!!order && order.items.length > 0"
                            [class.tray-hide]="(!order || order.items.length === 0)&& orderAdded"
                            color="dark"
                            (click)="payNowClicked($event)">
                    <span *ngIf="!!order">({{ order.items.length }}) Confirm Order</span>
                </ion-button>
            </div>
        </div>
    </div>
    <div *ngIf="!editMode" class="order-container ion-hide-md-down">
        <ion-item *ngIf="showTabInfo && !!tab && tab.status !== tabStatuses.PENDING"
                  [class.tab-summary]="!activeMembership"
                  [class.tab-summary-member]="!!activeMembership"
                  class="pointer order-container-tab-header"
                  routerLink="/manager/{{pickupLocation.id}}}/tab/{{tab.id}}">
            <bb-tokens *ngIf="availableTokens > 0" [count]="availableTokens" slot="start"></bb-tokens>
            <ion-label>
                {{ (!!tab.nameOverride && terminalMode) ? tab.nameOverride : (!!tab.person ? tab.person.name : 'Terminal Order') }}
                <p>{{ tab.startTime | dateAgo }}</p>
            </ion-label>

            <ion-note *ngIf="!!tab && !!tab.paymentInformation" slot="end">{{ tab.paymentInformation.invoiced | currency }}</ion-note>
        </ion-item>
        <div class="order-container-header">
            <div *ngIf="terminalMode && (!tab || tab.status === tabStatuses.PENDING) && !!organization.membershipPrograms && organization.membershipPrograms.length > 0"
                 class="active-membership">
                <ion-button [color]="!!activeMembership ? 'primary' : 'dark'"
                            class="membership-button"
                            (click)="selectMember.emit()">
                    <ng-container *ngIf="!activeMembership">No Member</ng-container>
                    <ng-container *ngIf="!!activeMembership">
                        <div class="membership-container">
                            <h3 *ngIf="!!availableTokens" class="member-tokens">
                                <bb-tokens [count]="availableTokens"></bb-tokens>
                            </h3>
                            <div class="membership-info">
                                <span>{{ !!activeMembership.person ? activeMembership.person.name : 'No Person Information' }}</span>
                                <p class="sub-header">{{
                                        !!activeMembership.activeMembershipProgram
                                            ? activeMembership.activeMembershipProgram.name
                                            : 'No active program.'
                                    }}</p>
                            </div>
                        </div>
                    </ng-container>
                </ion-button>
                <ion-button *ngIf="!activeMembership" class="add-membership-button" color="tertiary" (click)="renewMembership.emit()">
                    <ion-icon name="add-outline" slot="icon-only"></ion-icon>
                </ion-button>
                <ion-button *ngIf="!!activeMembership" class="add-membership-button" color="dark" (click)="removeMember.emit()">
                    <ion-icon name="close-outline" slot="icon-only"></ion-icon>
                </ion-button>
            </div>

            <ion-item
                    *ngIf="terminalMode && (!tab || tab.status === tabStatuses.PENDING) && !!organization.tabNoteDefinitions && organization.tabNoteDefinitions.length > 0">
                <ion-label position="floating">Tab Notes</ion-label>
                <ion-select [(ngModel)]="noteDefinitions" interface="popover" multiple="true" (ionChange)="selectNoteDefinitions()">
                    <ion-select-option *ngFor="let n of organization.tabNoteDefinitions" [value]="n">{{ n.name }}</ion-select-option>
                </ion-select>
            </ion-item>

            <a *ngIf="!!order" class="clear-cart" (click)="clearCart.emit()">Clear Order</a>
        </div>
        <div class="order-container-body" #orderContainer>
            <ion-item *ngIf="!order">
                <ion-text>
                    Your order is empty.
                </ion-text>
            </ion-item>
            <bb-display-order *ngIf="!!order"
                              [order]="order"
                              [availableOrderComps]="availableOrderComps"
                              (openItemSummary)="openEditOrderItemSummary($event)"
                              (updateItem)="updateItem.emit($event)"
                              (addToSummary)="addToSummary.emit($event)"
                              (addCredit)="addCredit.emit($event)"
                              (removeCredit)="removeCredit.emit($event)"
                              (removeTokens)="removeTokens.emit($event)"
                              (removeFromSummary)="removeFromSummary.emit($event)"
                              (removeItemSummary)="removeItemSummary.emit($event)"
                              (scrollToItemSummary)="scrollToSummary($event)">
            </bb-display-order>
        </div>
        <div *ngIf="!terminalMode && !!pickupLocation && pickupLocation.status === 'ACTIVE'" class="order-container-footer">
            <ion-button class="pay-now ion-hide-md-down"
                        [class.pay-now-display]="!!order && order.items.length > 0"
                        [class.pay-now-hide]="(!order || order.items.length === 0) && orderAdded"
                        color="dark"
                        (click)="payNowClicked($event)">
                ({{ !!order ? order.items.length : 0 }}) Confirm Order
            </ion-button>
        </div>
        <ion-grid *ngIf="terminalMode" class="ion-hide-md-down footer-button-grid action-buttons">
            <ion-row *ngIf="!!order && order.items.length > 0">
                <ion-col [sizeXs]="!tab || tab.status === tabStatuses.PENDING ? 6 : 12">
                    <ion-button data-cy="confirm-button"
                                class="full-footer-button"
                                color="dark"
                                (click)="payNowClicked($event)">
                        ({{ !!order ? order.items.length : 0 }}) Confirm
                    </ion-button>
                </ion-col>
                <ion-col *ngIf="!tab || tab.status === tabStatuses.PENDING" size-xs="6">
                    <ion-button data-cy="cash-button"
                                class="full-footer-button"
                                color="dark"
                                [disabled]="!availableTabComps || availableTabComps.length < 1"
                                (click)="payNowClicked($event, 'COMP')">
                        Comp
                    </ion-button>
                </ion-col>
                <ion-col *ngIf="!tab || tab.status === tabStatuses.PENDING" size-xs="6">
                    <ion-button data-cy="cash-button"
                                class="full-footer-button"
                                color="dark"
                                (click)="payNowClicked($event, 'GIFT_CARD')">
                        Gift Card
                    </ion-button>
                </ion-col>
                <ion-col *ngIf="!tab || tab.status === tabStatuses.PENDING" size-xs="6">
                    <ion-button data-cy="cash-button"
                                class="full-footer-button"
                                color="dark"
                                [disabled]="!cashSession || !cashSession.canAcceptPayments()"
                                (click)="payNowClicked($event, 'CASH')">
                        Cash
                    </ion-button>
                </ion-col>
                <ion-col *ngIf="!tab || tab.status === tabStatuses.PENDING" size-xs="6">
                    <ion-button data-cy="credit-card-button"
                                class="full-footer-button"
                                color="tertiary"
                                (click)="payNowClicked($event, 'CHARGE')">
                        Card
                    </ion-button>
                </ion-col>
                <ion-col *ngIf="!tab || tab.status === tabStatuses.PENDING" size-xs="6">
                    <ion-button data-cy="start-tab-button"
                                class="full-footer-button"
                                color="success"
                                [disabled]="!!order.deposits"
                                bbLongPress
                                (longPress)="startBlankTab()"
                                (click)="payNowClicked($event, 'START_TAB')">
                        <div class="start-tab-container">
                            <span>Start Tab</span>
                            <p>Hold For No Card</p>
                        </div>

                    </ion-button>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="!order && !tab">
                <ion-col size-xs="12">
                    <ion-button data-cy="start-tab-button"
                                class="full-footer-button"
                                color="dark"
                                (click)="payNowClicked($event, 'START_BLANK_TAB')">
                        Create Empty Tab
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</div>
