export class OrderStatuses {
    public static PENDING = 'PENDING';
    public static RECEIVED = 'RECEIVED';
    public static READY = 'READY';
    public static PREPARING = 'PREPARING';
    public static DELIVERED = 'DELIVERED';
    public static CANCELED = 'CANCELED';
    public static VACATED = 'VACATED';
    public static DELETED = 'DELETED';
}
