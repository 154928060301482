import {BaseFormModel} from './base-form-model';
import {User} from './user';
import {Organization} from './organization';
import {OrganizationRewardProgramCard} from './organization-reward-program-card';

export class UserOrganizationCredit extends BaseFormModel<UserOrganizationCredit> {
    id: number;
    parentId: number;
    user: User;
    createdBy: User;
    organization: Organization;
    creditDate: Date;
    amount: number;
    type: string;
    remaining: number;
    rewardProgramCard: OrganizationRewardProgramCard;
}
