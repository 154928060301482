import {Injectable} from '@angular/core';
import {Order} from '../_models/order';
import {PickupLocationService} from './pickup-location.service';
import {OrganizationService} from './organization.service';
import {v4 as uuidv4} from 'uuid';
import {Tab} from '../_models/tab';
import {TabStatuses} from '../_constants/tab-statuses';
import {TabService} from './tab.service';
import {TabPaymentInformation} from '../_models/tab-payment-information';
import {PaymentStatuses} from '../_constants/payment-statuses';
import {BaseOfflineService} from './base-offline.service';
import {MembershipService} from './membership.service';
import {Membership} from '../_models/membership';
import {TabNoteDefinition} from '../_models/tab-note-definition';
import {TabNote} from '../_models/tab-note';

@Injectable({
    providedIn: 'root'
})
export class OfflineTabService extends BaseOfflineService {
    activeMembership: Membership;

    constructor(
        private tabService: TabService,
        private membershipService: MembershipService,
        pickupLocationService: PickupLocationService,
        organizationService: OrganizationService,
    ) {
        super(pickupLocationService, organizationService);
        this.membershipService.current.subscribe(m => this.activeMembership = !!m ? new Membership(m) : null);
    }

    initialize(order: Order, noteDefinitions: TabNoteDefinition[]): Tab {
        const tab = new Tab({});
        tab.key = uuidv4();
        order.tabKey = tab.key;

        if (!!this.activeMembership) {
            tab.person = this.activeMembership.person;
            tab.member = this.activeMembership;
            tab.membershipProgram = this.activeMembership.activeMembershipProgram;
        }

        if (!!noteDefinitions && noteDefinitions.length > 0) {
            const notes = [];
            noteDefinitions.forEach(definition => notes.push(new TabNote({definition})));
            tab.notes = notes;
        }

        tab.orders = [order];
        tab.status = TabStatuses.PENDING;
        tab.subtotal = order.calculateTotal();

        tab.credits = [];
        tab.parentId = this.organization.id;
        tab.startTime = new Date();
        tab.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        tab.paymentInformation = new TabPaymentInformation({
            remaining: tab.subtotal,
            depositsRemaining: order.calculateDeposits(),
            deposits: order.calculateDeposits(),
            paymentStatus: PaymentStatuses.UNPAID,
            chargePaymentsPrincipal: 0,
            chargePaymentsTips: 0,
            chargePaymentsTotal: 0,
            cashPaymentsPrincipal: 0,
            cashPaymentsTips: 0,
            cashPaymentsTotal: 0,
            giftCardPaymentsPrincipal: 0,
            giftCardPaymentsTips: 0,
            giftCardPaymentsTotal: 0,
            paymentType: 'NONE',
            subtotal: tab.subtotal
        });
        tab.recalculate();
        this.tabService.setEvent(tab);
        return tab;
    }
}
