import {Inject, Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LibConfig, LibConfigService} from '../lib.config';
import {PaymentChargeRefund} from '../_models/payment-charge-refund';
import {Constants} from '../_constants/constants';
import {ToastService} from './toast.service';
import {LoadingService} from './loading.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentChargeRefundService extends BaseService<PaymentChargeRefund> {

    destination = 'payment-charge-refund';

    constructor(
        http: HttpClient,
        @Inject(LibConfigService) config: LibConfig,
        private toastService: ToastService,
        private loadingService: LoadingService
    ) {
        super(http, config);
    }

    refund(paymentChargeRefund: PaymentChargeRefund) {
        const headers = new HttpHeaders().set(Constants.IGNORE_ERROR_CODES, '410,422,423,428');
        return this.http.post(this.baseUrl(), paymentChargeRefund, {headers});
    }

    errorHandler(error: any) {
        this.loadingService.dismiss();
        if (error.status === 428) {
            this.toastService.error('This refund requires a card to be presented to an active card reader.');
        } else if (error.status === 423) {
            this.toastService.error('The card reader is unavailable.');
        } else if (error.status === 410) {
            this.toastService.error('The transaction has been canceled at the card reader.');
        } else if (error.status === 422) {
            this.toastService.error('Invalid payment method.');
        } else if (error.status === 406) {
            this.toastService.error('The card is expired and cannot be refunded during order cancellation. Refund the payment manually, cancel the order, and collect a new payment on the remainder.');
        } else if (error.status === 415) {
            this.toastService.error('The payment is a wallet payment and cannot be refunded during order cancellation. Refund the payment manually, cancel the order, and collect a new payment on the remainder.');
        } else if (error.status === 416) {
            this.toastService.error('Partial refund failed. Refund the payment manually, cancel the order, and collect a new payment on the remainder.');
        }
    }
}
