import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseMenuItemInventoryComponent} from '../base-menu-item-inventory.component';
import {MenuItem} from '../../_models/menu-item';
import {OrganizationRewardProgram} from '../../_models/organization-reward-program';
import {UserOrganizationCreditSummary} from '../../_models/user-organization-credit-summary';
import {MenuCategoryItem} from '../../_models/menu-category-item';
import {MenuItemInventoryService} from '../../_services/menu-item-inventory.service';
import {OrganizationTerminalService} from '../../_services/organization-terminal.service';
import {AlertController, ModalController} from '@ionic/angular';
import {OrganizationRewardProgramService} from '../../_services/organization-reward-program.service';
import {Membership} from '../../_models/membership';
import {MembershipService} from '../../_services/membership.service';
import {Organization} from '../../_models/organization';
import {OrganizationService} from '../../_services/organization.service';
import {OrderService} from '../../_services/order.service';
import {Order} from '../../_models/order';
import {PickupLocationMenuService} from '../../_services/pickup-location-menu.service';

@Component({
    selector: 'bb-mobile-menu-item',
    templateUrl: './mobile-menu-item.component.html',
    styleUrls: ['./mobile-menu-item.component.scss'],
})
export class MobileMenuItemComponent extends BaseMenuItemInventoryComponent implements OnInit {
    @Input() item: MenuCategoryItem;
    @Input() rewardProgram: OrganizationRewardProgram;
    @Input() activeCredit: UserOrganizationCreditSummary;
    @Input() showInventory = false;
    @Input() color = '#ECECEC';
    @Input() contrastColor = '#000000';
    @Output() openItemModal = new EventEmitter();

    showImages = true;

    organization: Organization;
    activeMembership: Membership;
    minToken: number;
    maxToken: number;
    order: Order;
    availableTokens = 0;

    constructor(
        modalController: ModalController,
        menuItemInventoryService: MenuItemInventoryService,
        organizationTerminalService: OrganizationTerminalService,
        private organizationRewardProgramService: OrganizationRewardProgramService,
        private pickupLocationMenuService: PickupLocationMenuService,
        private membershipService: MembershipService,
        private orderService: OrderService,
        private organizationService: OrganizationService,
        private alertController: AlertController
    ) {
        super(modalController, menuItemInventoryService, organizationTerminalService);
    }

    ngOnInit() {
        this.menuItem = this.item.menuItem;
        this.showImages = this.pickupLocationMenuService.showImagesCurrent;

        if (!!this.menuItem && !!this.menuItem.prices && this.menuItem.prices.length === 1) {
            this.menuItemPrice = this.menuItem.prices[0];
        }
        super.ngOnInit();

        this.subscribe(this.pickupLocationMenuService.showImages.subscribe(s => this.showImages = s));

        this.activeMembership = !!this.membershipService.currentValue ? new Membership(this.membershipService.currentValue) : null;
        this.organization = this.organizationService.currentValue;
        this.checkTokenOptions();

        this.subscribe(this.membershipService.current.subscribe(m => {
            this.activeMembership = !!m ? new Membership(m) : null;
            this.checkTokenOptions();
        }));

        this.subscribe(this.orderService.current.subscribe(o => {
            this.order = !!o ? new Order(o) : null;
            this.checkTokenOptions();
        }));
    }

    checkTokenOptions() {
        this.availableTokens = (!!this.activeMembership ? this.activeMembership.availableTokens : 0)
            - (!!this.order ? this.order.tokenPayments() : 0);

        if (!!this.activeMembership
            && !!this.availableTokens
            && !!this.activeMembership.activeMembershipProgram
            && !!this.organization.tokenGroups) {
            const tokenGroups = this.organization.tokenGroups
                .filter(g => g.items.some(i => i.itemId === this.menuItem.id));

            this.minToken = null;
            this.maxToken = null;

            tokenGroups.forEach(t => {
                const tokenAvailability = !!this.activeMembership.availableTokenGroups
                    ? this.activeMembership.availableTokenGroups.find(ta => ta.id === t.id)
                    : null;

                if (!!tokenAvailability &&
                    (!tokenAvailability.remaining
                        || tokenAvailability.remaining > tokenAvailability.currentUsed)) {
                    this.minToken = !this.minToken || t.tokenCount < this.minToken ? t.tokenCount : this.minToken;
                    this.maxToken = this.availableTokens >= t.tokenCount && (!this.maxToken || t.tokenCount > this.maxToken)
                        ? t.tokenCount
                        : this.maxToken;
                } else {
                    this.minToken = null;
                    this.maxToken = null;
                }
            });
        } else {
            this.minToken = null;
            this.maxToken = null;
        }
    }

    showReward() {
        if (!this.terminalMode && !!this.activeCredit && this.activeCredit.rewardType === 'ITEM'
            && !!this.rewardProgram && !!this.rewardProgram.rewardItems && this.rewardProgram.rewardItems
            && !this.insufficientInventory()) {
            return this.rewardProgram.rewardItems.some(q => this.menuItem.prices.some(p => p.id === q.priceId));
        }
        return false;
    }

    showQualified() {
        if (!this.terminalMode && !this.activeCredit && !!this.rewardProgram
            && !!this.rewardProgram.qualifiedItems && this.rewardProgram.qualifiedItems
            && !this.insufficientInventory()) {
            return this.rewardProgram.qualifiedItems.some(q => this.item.menuItem.prices.some(p => p.id === q.priceId));
        }
        return false;
    }

    async showQualifiedMessage(event, item: MenuItem) {
        event.stopPropagation();

        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Qualified Item',
            message: `${this.organizationRewardProgramService.description(this.rewardProgram)} <p>Would you like to add ${item.name}?</p>`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.openItemModal.emit();
                    }
                }
            ]
        });

        await alert.present();
    }

    async showRewardMessage(event, item: MenuItem) {
        event.stopPropagation();

        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Qualified Reward Item',
            message: 'You have an earned reward that this item qualifies for! Would you like to add it?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.openItemModal.emit();
                    }
                }
            ]
        });

        await alert.present();
    }

}
