import {Component, OnInit} from '@angular/core';
import {BaseModalComponent} from '../base-modal.component';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {ToastService} from '../../_services/toast.service';
import {LoadingService} from '../../_services/loading.service';
import {Organization} from '../../_models/organization';
import {OrganizationService} from '../../_services/organization.service';
import {OrganizationTerminalService} from '../../_services/organization-terminal.service';
import {OrganizationTerminal} from '../../_models/organization-terminal';
import {EditTerminalComponent} from '../edit-terminal/edit-terminal.component';
import {v4 as uuidv4} from 'uuid';

@Component({
    selector: 'bb-select-card-reader',
    templateUrl: './select-card-reader.component.html',
    styleUrls: ['./select-card-reader.component.scss'],
})
export class SelectCardReaderComponent extends BaseModalComponent implements OnInit {
    iOS = false;
    organization: Organization;

    terminals: OrganizationTerminal[];
    currentTerminal: OrganizationTerminal = null;
    loaded = false;
    confirmed = false;

    constructor(
        private organizationTerminalService: OrganizationTerminalService,
        private alertController: AlertController,
        private loadingService: LoadingService,
        private organizationService: OrganizationService,
        private toastService: ToastService,
        private platform: Platform,
        modalController: ModalController
    ) {
        super(modalController);
        this.iOS = this.platform.is('ios');
    }

    ngOnInit() {
        this.organization = this.organizationService.currentValue;
        this.subscribe(this.organizationService.current.subscribe(o => this.organization = !!o ? new Organization(o) : null));

        this.currentTerminal = this.organizationTerminalService.currentValue;

        this.loadingService.present();
        this.organizationTerminalService.findByOrganizationId(this.organization.id).subscribe((t: OrganizationTerminal[]) => {
            this.terminals = t;
            this.init();
            this.loaded = true;
            this.loadingService.dismiss();
        });
    }

    init() {
        if (!!this.terminals && this.terminals.length > 0) {
            if (!!this.currentTerminal) {
                this.currentTerminal = this.terminals.find(t => t.id === this.currentTerminal.id);
            }
        }
    }

    async connectTerminal() {
        if (!this.currentTerminal) {
            const alert = await this.alertController.create({
                cssClass: 'brewbill-alert',
                header: 'Confirm No Terminal',
                message: `Are you sure you would like to continue without a credit card terminal?`,
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel'
                    }, {
                        text: 'Yes',
                        handler: async () => {
                            this.organizationTerminalService.setCurrent(null);
                            await this.toastService.success('Disconnected from credit card terminal.');
                            await this.close();
                        }
                    }
                ]
            });

            await alert.present();
        } else {
            const referenceId = 'MER-' + uuidv4();

            this.loadingService.present('Sending test request.');
            const alert = await this.alertController.create({
                cssClass: 'brewbill-alert',
                header: 'Confirm Request',
                message: `Did the terminal request a card tokenization?`,
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel',
                        handler: async () => {
                            this.organizationTerminalService.cancel(this.organization.id, this.currentTerminal.terminalId, referenceId)
                                .subscribe(() => console.log('Transaction canceled.'));
                        }
                    }, {
                        text: 'Yes',
                        handler: async () => {
                            this.confirmed = true;
                            this.organizationTerminalService.cancel(this.organization.id, this.currentTerminal.terminalId, referenceId)
                                .subscribe(() => console.log('Transaction canceled.'));
                            this.organizationTerminalService.setCurrent(!!this.currentTerminal ? this.currentTerminal : null);
                            await this.toastService.success(`Connected to ${this.currentTerminal.nickname}`);
                            await this.close();
                        }
                    }
                ]
            });

            await alert.present();
            this.confirmed = false;
            this.organizationTerminalService.tokenize(this.organization.id, this.currentTerminal.terminalId, true, referenceId)
                .subscribe(() => {
                    console.log('do nothing');
                }, error => {
                    if (!this.confirmed) {
                        if (error.status === 423) {
                            this.toastService.error('The card reader is unavailable. Please make sure the reader is displaying the GlobalPayments Integrated screen.');
                        } else if (error.status === 410) {
                            this.toastService.error('The transaction has been canceled at the card reader. Please leave the transaction on the reader while connecting.');
                        } else if (error.status === 406) {
                            this.toastService.error('Please confirm the terminal ID and make sure the device is powered on and displaying the GlobalPayments Integrated screen.');
                        } else {
                            this.toastService.error('Server error.');
                        }
                        alert.dismiss();
                        this.organizationTerminalService.cancel(this.organization.id, this.currentTerminal.terminalId, referenceId)
                            .subscribe(() => console.log('Transaction canceled.'));
                    }
                });
            this.loadingService.dismiss();
        }
    }

    async openEditTerminal(event, terminal: OrganizationTerminal) {
        event.stopPropagation();
        const modal = await this.modalController.create({
            component: EditTerminalComponent,
            componentProps: {
                terminal,
                organization: this.organization
            }
        });

        await modal.present();

        await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                const t = new OrganizationTerminal(dataReturned.data);
                if (!terminal) {
                    this.terminals.push(t);
                } else {
                    this.terminals[this.terminals.findIndex(c => c.id === t.id)] = t;
                }
                this.currentTerminal = t;
            }
        });
    }

    async deleteTerminal(event, terminal: OrganizationTerminal) {
        event.stopPropagation();

        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm No Terminal',
            message: `Are you sure you would like to delete ${terminal.nickname}?`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: async () => {
                        this.organizationTerminalService.delete(terminal.id).subscribe(() => {
                            this.toastService.success('The credit card reader has been deleted.');
                            if (this.currentTerminal.id === terminal.id) {
                                this.currentTerminal = null;
                                this.organizationTerminalService.setCurrent(null);
                            }
                            this.terminals.splice(this.terminals.indexOf(terminal), 1);
                        });
                    }
                }
            ]
        });

        await alert.present();
    }
}
