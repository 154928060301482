import {Inject, Injectable} from '@angular/core';
import {Organization} from '../_models/organization';
import {LibConfig, LibConfigService} from '../lib.config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {ToastService} from './toast.service';
import {BaseObservableService} from './base-obeservable-service';
import {Constants} from '../_constants/constants';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService extends BaseObservableService<Organization> {
    destination = 'organizations';

    public organizationToken: Observable<string>;
    private organizationTokenSubject: BehaviorSubject<string>;

    constructor(
        @Inject(LibConfigService) config: LibConfig,
        private toastService: ToastService,
        http: HttpClient
    ) {
        super(http, config);

        const organizationToken = localStorage.getItem('ORGANIZATION_TOKEN');
        this.organizationTokenSubject = new BehaviorSubject<string>(!!organizationToken ? organizationToken : null);
        this.organizationToken = this.organizationTokenSubject.asObservable();
    }

    refresh() {
        if (!!this.currentValue && !!this.currentValue.id) {
            this.getSilent(this.currentValue.id).subscribe((o: Organization) => this.setCurrent(new Organization(o)));
        }
    }

    updateStatus(orgId: number, status: string) {
        return this.http.put(`${this.baseUrl()}/${orgId}/status/${status}`, {});
    }

    uploadLogo(orgId: number, file: any, fileName: string) {
        const formData = new FormData();
        if (fileName) {
            formData.append('file', file, fileName);
        } else {
            formData.append('file', file);
        }
        return this.http.post(`${this.baseUrl()}/${orgId}/logo`, formData);
    }

    getToken(orgId: number) {
        return this.http.get(`${this.baseUrl()}/token/${orgId}`);
    }

    findByCsrf(csrf: string) {
        return this.http.get(`${this.baseUrl()}/csrf/${csrf}`);
    }

    getUsers(orgId: number, searchString: string, page: number, size: number) {
        return this.http.get(`${this.baseUrl()}/${orgId}/users?size=${size}&page=${page}&searchString=${searchString}`);
    }

    getRewardProgram(orgId: number) {
        return this.http.get(`${this.baseUrl()}/${orgId}/reward-program`);
    }

    setOrganizationToken(token: string) {
        if (!!token) {
            localStorage.setItem('ORGANIZATION_TOKEN', token);
            this.organizationTokenSubject.next(token);
        }
    }

    getOrganizationToken(): string {
        let token = this.organizationTokenSubject.value;
        if (!token) {
            token = localStorage.getItem('ORGANIZATION_TOKEN');
            if (!!token) {
                this.setOrganizationToken(token);
            }
        }
        return token;
    }

    getOpenTabs(organizationId: number, silent = false) {
        const headers = silent ? new HttpHeaders().set(Constants.SILENT, 'true') : null;
        return this.http.get(`${this.baseUrl()}/${organizationId}/tabs/open`, {headers});
    }

    getOpenTabsSearch(organizationId: number, page: number, size: number, search: string, tabNumber: string) {
        return this.http.get(`${this.baseUrl()}/${organizationId}/tabs/open?page=${page}&size=${size}&search=${search}&tabNumber=${tabNumber}`);
    }

    getDateRangeTabs(organizationId: number, page: number, size: number, startDate: string, endDate?: string) {
        return this.http.get(`${this.baseUrl()}/${organizationId}/tabs/date-range?startDate=${startDate.replace('+', '%2b')}&page=${page}&size=${size}`
            + (!!endDate ? '&endDate=' + endDate.replace('+', '%2b') : ''));
    }

    getDateRangeTabsSearch(organizationId: number, page: number, size: number, startDate: string, endDate: string, search: string, tabNumber: string) {
        return this.http.get(`${this.baseUrl()}/${organizationId}/tabs/date-range?startDate=${startDate.replace('+', '%2b')}&page=${page}&size=${size}&endDate=${endDate.replace('+', '%2b')}&search=${search}&tabNumber=${tabNumber}`);
    }

    search(searchString: string, latitude: number, longitude: number) {
        return this.http.get(`${this.baseUrl()}/search/${searchString}` + (!!latitude
            ? '?latitude=' + latitude + '&longitude=' + longitude
            : ''));
    }

    searchByBounds(minLatitude: number, maxLatitude: number, minLongitude: number,
                   maxLongitude: number, latitude: number, longitude: number
    ) {
        return this.http.get(`${this.baseUrl()}/bounds/${minLatitude}/${maxLatitude}/${minLongitude}/${maxLongitude}/${latitude}/${longitude}`);
    }

    optIn(orgId: number, optIn: boolean) {
        return this.http.put(`${this.baseUrl()}/${orgId}/push-opt-in/${optIn}`, {});
    }

    getEmployeeTipReport(organizationId: number, startDate: string, endDate: string, personId = null) {
        return this.http.get(`${this.baseUrl()}/${organizationId}/employee-tip-report?startTime=${startDate.replace('+', '%2b')}&endTime=${endDate.replace('+', '%2b')}`
            + (!!personId ? `&personId=${personId}` : ''));
    }

    getEmployeeTipReportTotals(organizationId: number, startDate: string, endDate: string) {
        return this.http.get(`${this.baseUrl()}/${organizationId}/employee-tip-report-totals?startTime=${startDate.replace('+', '%2b')}&endTime=${endDate.replace('+', '%2b')}`);
    }

    getEmployeeTipReportCsv(organizationId: number, startDate: string, endDate: string) {
        const httpOptions = {
            responseType: 'blob' as 'json',
        };

        return this.http.get(`${this.baseUrl()}/${organizationId}/employee-tip-report/csv?startTime=${startDate.replace('+', '%2b')}&endTime=${endDate.replace('+', '%2b')}`, httpOptions);
    }

    getFromToken() {
        return this.http.get(`${this.baseUrl()}/token`);
    }

    buildMarker(map, organization: Organization) {
        return {
            position: {
                lat: !!organization.latitude ? organization.latitude : organization.address.latitude,
                lng: !!organization.longitude ? organization.longitude : organization.address.longitude
            },
            map,
            label: {
                color: '#ffffff',
                text: organization.name,
                className: 'bb-marker-label',
                fontWeight: '700'
            },
            title: organization.name,
            options: {
                animation: google.maps.Animation.DROP,
                icon: {
                    url: 'assets/maps/map-' + (organization.status === 'ACTIVE' ? 'active' : 'inactive') + '.png',
                    scaledSize: {
                        width: 27,
                        height: 43,
                        widthUnit: 'px',
                        heightUnit: 'px'
                    }
                },
            },
            organization
        };
    }

    connectBankAccount(organizationId: number, request: any) {
        return this.http.post(`${this.baseUrl()}/${organizationId}/connect-bank-account`, request);
    }
}
