<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end" class="ion-align-items-start">
            <ion-button color="dark" (click)="close()">
                <ion-icon slot="icon-only" color="dark" name="close-circle-outline"></ion-icon>
            </ion-button>
        </ion-buttons>

        <ion-grid class="title-container">
            <ion-row>
                <ion-col class="ion-text-center">
                    <span class="title-header">{{ !miscItem ? menuItem.name : 'Misc' }}</span>
                    <span *ngIf="!miscItem && !!menuItem.flavorText" class="description">{{ menuItem.flavorText }}</span>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen>
    <ion-grid class="information-row">
        <ion-row *ngIf="!parentOrderItem">
            <ion-col>
                <div class="image-container" [class.terminal-image]="terminalMode">
                    <bb-menu-item-image [menuItem]="menuItem"
                                        [orderDisplay]="true"></bb-menu-item-image>
                </div>
            </ion-col>
            <ion-col *ngIf="!miscItem" class="ion-text-center information-container-cell">
                <div class="information-container">
                    <div *ngIf="!!orderItem && (orderTotal() > 0 || orderItem.selectedPrice.tokenPrice > 0) && orderDeposits() === 0"
                         class="price-container">
                        <div *ngIf="orderItem.selectedPrice.tokenPrice > 0; else nonTokenPrice">
                            <span>{{ orderTokensTotal() }}</span>
                            <ion-icon src="assets/icon/token.svg" class="default-token-price-icon"></ion-icon>
                        </div>
                        <ng-template #nonTokenPrice>
                            <span class="default-price">{{ orderTotal() | currency }}</span>
                        </ng-template>
                    </div>
                    <ng-container *ngIf="!!orderItem && orderDeposits() > 0">
                        <div class="itemized-price-container">
                            <div class="itemized-price-container-child">
                                <div class="itemized-price-row">
                                    <span class="itemized-price-label">Item</span>
                                    <span class="itemized-price">{{ orderTotal() | currency }}</span>
                                </div>
                                <div class="itemized-price-row">
                                    <ion-text class="itemized-price-label" color="tertiary">Deposit</ion-text>
                                    <ion-text class="itemized-price" color="tertiary">{{ orderDeposits() | currency }}</ion-text>
                                </div>
                                <div class="itemized-price-row">
                                    <span class="itemized-price-label">Total</span>
                                    <span class="itemized-price">{{ paymentTotal() | currency }}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ion-chip *ngIf="!parentOrderItem" color="dark" [outline]="true">
                        <ion-icon name="remove-outline" (click)="remove()" class="order-count-icon"></ion-icon>
                        <ion-label class="order-count-label">{{ orderItem.count }}</ion-label>
                        <ion-icon name="add-outline" (click)="add()" class="order-count-icon"></ion-icon>
                    </ion-chip>
                </div>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!miscItem">
            <ion-col>
                <bb-order-type-selection *ngIf="!!orderItem"
                                         [orderType]="orderItem.orderType"
                                         [hideInHouse]="hideInHouse"
                                         [hideTogo]="hideTogo"
                                         (orderTypeChange)="orderTypeChange($event)"></bb-order-type-selection>
            </ion-col>
        </ion-row>
    </ion-grid>
    <ng-container *ngIf="miscItem">
        <ion-grid [formGroup]="formGroup">
            <ion-row>
                <ion-col>
                    <ion-item>
                        <ion-label position="floating">Amount</ion-label>
                        <ion-input inputmode="numeric" formControlName="amount" bbCurrencyMask #amount></ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-item>
                        <ion-label position="floating">Reason:</ion-label>
                        <ion-textarea rows="5" formControlName="reason"></ion-textarea>
                    </ion-item>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
    <ion-grid *ngIf="!miscItem">
        <ion-row *ngIf="!!menuItem.description && !parentOrderItem">
            <ion-col class="ion-padding">
                <span class="description item-description">
                {{ menuItem.description }}
                </span>
            </ion-col>
        </ion-row>
        <ion-row
                *ngIf="!parentSelection && !!prices && (prices.length > 1 || (prices.length === 1 && prices[0].name !== 'Default')) && !orderItem.menuSelectionItem">
            <ion-col>
                <ion-list>
                    <ion-radio-group [(ngModel)]="orderItem.selectedPrice">
                        <ion-list-header *ngIf="!!menuItem.priceLabel && menuItem.priceLabel.length > 0">
                            <ion-label>{{ menuItem.priceLabel }}</ion-label>
                        </ion-list-header>
                        <ion-item *ngFor="let price of prices; trackBy: getId;"
                                  [disabled]="!terminalMode && !!price.menuItemInventory && price.menuItemInventory.currentCount < 1">
                            <ion-label>
                                <div class="menu-item-price-container">
                                    <span class="price-name">{{ !!price.name ? price.name : '' }}</span>
                                    <span class="price-price">
                                        <ng-container *ngIf="!!price.tokenPrice">
                                            <bb-tokens [count]="price.tokenPrice"></bb-tokens>
                                        </ng-container>
                                        <span *ngIf="!price.tokenPrice">
                                            {{ price.price | currency }}
                                        </span>
                                    </span>
                                </div>
                                <ion-icon *ngIf="showReward(price)" class="program-indicator" color="primary" name="ribbon-outline"></ion-icon>
                                <ion-icon *ngIf="showQualified(price)" class="program-indicator" color="dark" name="ribbon-outline"></ion-icon>
                            </ion-label>
                            <ion-radio slot="start" [value]="price" color="success"></ion-radio>

                            <ion-button *ngIf="terminalMode && !!price.menuItemInventory" slot="end" color="dark"
                                        (click)="adjustInventory($event, price.menuItemInventory)">
                                {{ price.menuItemInventory.currentCount > 0 ? price.menuItemInventory.currentCount : 'Sold Out' }}
                            </ion-button>

                            <ion-badge *ngIf="!terminalMode && !!price.menuItemInventory && price.menuItemInventory.currentCount < 1"
                                       class="inventory-badge"
                                       color="dark"
                                       slot="end">
                                Sold Out
                            </ion-badge>
                        </ion-item>
                    </ion-radio-group>
                </ion-list>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="orderItem.selectedPrice.variable">
            <ion-col [formGroup]="formGroup">
                <ion-item>
                    <ion-label position="floating">Amount</ion-label>
                    <ion-input inputmode="numeric" formControlName="amount" bbCurrencyMask (ionChange)="updateTotal()" #amount></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="orderItem.includedToppings.length > 0">
            <ion-col>
                <ion-list>
                    <ion-radio-group multiple>
                        <ion-list-header>
                            <ion-label>Included Toppings</ion-label>
                        </ion-list-header>
                        <ion-list>
                            <ion-item *ngFor="let orderItemTopping of orderItem.includedToppings">
                                <ion-label>{{ orderItemTopping.menuItemTopping.topping.name }}</ion-label>
                                <ion-checkbox slot="start" [(ngModel)]="orderItemTopping.included"></ion-checkbox>
                                <span slot="end" *ngIf="orderItemTopping.menuItemTopping.price">{{ orderItemTopping.menuItemTopping.price }}</span>
                            </ion-item>
                        </ion-list>
                    </ion-radio-group>
                </ion-list>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="orderItem.optionalToppings.length > 0">
            <ion-col>
                <ion-list>
                    <ion-radio-group multiple>
                        <ion-list-header>
                            <ion-label>Optional Toppings</ion-label>
                        </ion-list-header>
                        <ion-list>
                            <ion-item *ngFor="let orderItemTopping of orderItem.optionalToppings">
                                <ion-label>{{ orderItemTopping.menuItemTopping.topping.name }}</ion-label>
                                <ion-checkbox slot="start" [(ngModel)]="orderItemTopping.included"></ion-checkbox>
                                <span slot="end"
                                      *ngIf="orderItemTopping.menuItemTopping.price">{{ orderItemTopping.menuItemTopping.price | currency }}</span>
                            </ion-item>
                        </ion-list>
                    </ion-radio-group>
                </ion-list>
            </ion-col>
        </ion-row>
        <ion-row *ngFor="let selection of selections; let index = index;">
            <ion-col>
                <ion-radio-group multiple>
                    <ion-list-header>
                        <ion-label>
                            {{ selection.menuSelection.name }}
                            <ng-container *ngIf="orderItem.selections[index].countTotal() !== 0">
                                ({{ orderItem.selections[index].countTotal() | currency }})
                            </ng-container>
                            <span class="selection-summary">
                                {{ selection.menuSelection.selectionMessage }}
                            </span>
                        </ion-label>
                        <ion-button color="dark" (click)="displaySelectionPricingInfo(selection)">
                            <ion-icon name="help-circle-outline" slot="icon-only"></ion-icon>
                        </ion-button>
                    </ion-list-header>
                    <bb-menu-item-selection [menuItemSelection]="selection"
                                            [selectionIndex]="index"
                                            [parentOrderItem]="orderItem"
                                            [terminalMode]="terminalMode"
                                            (adjustInventory)="adjustInventory(null, $event)"
                                            (itemSelected)="openItemModal($event, selection)"
                                            (editSelected)="editItemModal($event, selection, index)">
                    </bb-menu-item-selection>
                </ion-radio-group>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="!!organization.allowOrderNotes">
            <ion-col size-xs="12">
                <ion-item>
                    <ion-label position="floating">Instructions:</ion-label>
                    <ion-textarea rows="5" [maxlength]="500" [(ngModel)]="orderItem.notes"></ion-textarea>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-button *ngIf="!terminalMode && (!pickupLocation || pickupLocation.status !== 'ACTIVE')" color="dark" class="footer-button" [disabled]="true">
        Not Taking Online Orders
    </ion-button>
    <ion-button *ngIf="terminalMode || (!!pickupLocation && pickupLocation.status === 'ACTIVE')"
                [disabled]="(!miscItem && !orderItem.selectedPrice) || (miscItem && formGroup.invalid)"
                color="dark"
                class="footer-button"
                (click)="confirmSubmit()">
        {{ !editMode ? 'Add to' : 'Update' }} Order
    </ion-button>
</ion-footer>

<ion-popover [isOpen]="!!selectionPrices" (didDismiss)="selectionPrices = null">
    <ng-template>
        <ion-content>
            <ion-item>
                <ion-label>Additional Price Info</ion-label>
            </ion-item>
            <ion-list>
                <ion-item *ngFor="let price of selectionPrices; let index = index;" lines="none">
                    {{ (!price.price || price.price === 0) ? 'Included ' : (price.price | currency) + ' each' }} for
                    {{
                        index === 0 ? 'first ' + price.countMax : (price.countMax
                            ? ' next ' + (price.countMax - price.countMin + 1)
                            : price.countMin + ' or more')
                    }}
                </ion-item>
            </ion-list>
        </ion-content>
    </ng-template>
</ion-popover>
