import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Organization} from '../../_models/organization';
import {BaseModalComponent} from '../base-modal.component';
import {AlertController, ModalController} from '@ionic/angular';
import {ToastService} from '../../_services/toast.service';
import {LoadingService} from '../../_services/loading.service';
import {OrganizationService} from '../../_services/organization.service';

@Component({
    selector: 'bb-connect-bank-account-modal',
    templateUrl: './connect-bank-account-modal.component.html',
    styleUrls: ['./connect-bank-account-modal.component.css']
})
export class ConnectBankAccountModalComponent extends BaseModalComponent implements OnInit {
    connectBankAccountForm: FormGroup = new FormGroup({});
    @Input() organization: Organization;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private organizationService: OrganizationService,
        private alertController: AlertController,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.connectBankAccountForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            accountNumber: ['', Validators.required],
            routingNumber: ['', Validators.required],
            accountType: ['', Validators.required]
        });
    }

    async onSubmit() {
        const updatedOrg = new Organization(this.connectBankAccountForm.value);
        updatedOrg.id = this.organization.id;

        this.loadingService.present();
        this.organizationService.connectBankAccount(this.organization.id, this.connectBankAccountForm.value).subscribe(
            async (o: Organization) => {
                await this.toastService.success('Bank account connected.');
                this.loadingService.dismiss();
                await this.close(o);
            }
        );
    }
}
