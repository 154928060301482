import {Component} from '@angular/core';
import {SubscriberComponent} from './subscriber.component';

@Component({
    template: ''
})
export abstract class BaseSubscriberPageComponent extends SubscriberComponent {
    abstract init();

    protected constructor() {
        super();
    }

    ionViewWillEnter() {
        this.init();
    }

    ionViewWillLeave() {
        this.ngOnDestroy();
    }
}
