import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {MembershipTokenPurseService} from '../_services/membership-token-purse.service';

@Injectable()
export class MembershipTokenPurseResolver implements Resolve<any> {
    constructor(private membershipTokenPurseService: MembershipTokenPurseService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.membershipTokenPurseService.get(route.paramMap.get('membershipTokenPurseId'));
    }
}
