import {BaseFormModel} from './base-form-model';

export class ReportSummary extends BaseFormModel<ReportSummary> {
    name: string;
    count: number;
    countPercentage: number;
    total: number;
    totalPercentage: number;
    creditTotal: number;
}
