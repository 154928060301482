import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'expiration'
})
export class ExpirationPipe implements PipeTransform {

    transform(value: string): string {
        return !!value && value.length === 6 ? value.slice(0, 2) + '/' + value.slice(2) : value;
    }
}
