import {Component, Input, OnInit} from '@angular/core';
import {BaseWidgetComponent} from '../base-widget.component';
import {TabReportResult} from '../../../_models/report/tab-report-result';
import {TabReportService} from '../../../_services/tab-report.service';
import {CurrencyPipe, DatePipe, PercentPipe} from '@angular/common';
import {Column} from '../../../_models/report/column';
import {UnderscoreConversionPipe} from '../../_pipes/underscore-conversion.pipe';

@Component({
    selector: 'bb-list-widget',
    templateUrl: './list-widget.component.html',
    styleUrls: ['./list-widget.component.scss'],
})
export class ListWidgetComponent extends BaseWidgetComponent implements OnInit {
    @Input() pageSize = 5;
    tabReportResult: TabReportResult;
    detailColumns: Column[];
    labelColumn: Column;
    valueColumn: Column;

    constructor(
        private tabReportService: TabReportService,
        currencyPipe: CurrencyPipe,
        percentPipe: PercentPipe,
        datePipe: DatePipe,
        underscoreConversionPipe: UnderscoreConversionPipe
    ) {
        super(currencyPipe, percentPipe, datePipe, underscoreConversionPipe);
    }

    ngOnInit(): void {
    }

    refresh() {
        this.loading = true;
        this.dataLoading.emit();
        this.tabReportService.run(this.tabReport.id, 0, this.pageSize, this.startDate, this.endDate,
            null, this.selectedCategories, this.selectedPickupLocations, true)
            .subscribe((trr: TabReportResult) => {
                this.tabReportResult = trr;
                this.detailColumns = trr.columns.slice(1, trr.columns.length - 1);
                this.labelColumn = trr.columns[0];
                this.valueColumn = trr.columns[trr.columns.length - 1];

                this.loading = false;
                this.dataReady.emit();
            });
    }
}
