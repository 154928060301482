<modal-header title="Register"></modal-header>
<ion-content>
    <mat-horizontal-stepper [linear]="true" labelPosition="bottom" #stepper>
        <mat-step [stepControl]="personFormGroup">
            <form [formGroup]="personFormGroup">
                <ng-template matStepLabel>Personal</ng-template>
                <bb-person-form (formReady)="personFormInitialized($event)" [requireEmail]="true"></bb-person-form>
            </form>
        </mat-step>
        <mat-step [stepControl]="loginFormGroup">
            <form [formGroup]="loginFormGroup">
                <ng-template matStepLabel>Login</ng-template>
                <bb-login-form (formReady)="loginFormInitialized($event)"
                               [email]="email"></bb-login-form>
            </form>
        </mat-step>
    </mat-horizontal-stepper>
</ion-content>

<bb-modal-stepper-footer [linear]="true"
                         submitText="Register"
                         [stepper]="stepper"
                         (submitClicked)="onSubmit()"
                         [disableSubmit]="!valid()"></bb-modal-stepper-footer>
