import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CompDefinitionService} from '../_services/comp-definition.service';

@Injectable()
export class MembershipProgramCompDefinitionResolver implements Resolve<any> {
    constructor(private compDefinitionService: CompDefinitionService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.compDefinitionService.findByMembershipProgramId(route.paramMap.get('id'));
    }
}
