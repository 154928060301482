import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {MarketingPush} from '../_models/marketing-push';

@Injectable({
    providedIn: 'root'
})
export class MarketingPushService extends BaseService<MarketingPush> {
    destination = 'marketing';

    findByOrganization(orgId: number, page: number, size: number) {
        return this.http.get(this.baseUrl() + `/organization/${orgId}?page=${page}&size=${size}`);
    }
}
