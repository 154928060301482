import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {PickupLocationService} from '../_services/pickup-location.service';

@Injectable()
export class OrganizationPickupLocationsResolver implements Resolve<any> {
    constructor(private pickupLocationService: PickupLocationService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.pickupLocationService.findByOrganizationId(Number.parseInt(route.paramMap.get('orgId'), 10));
    }
}
