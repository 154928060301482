import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {PaymentCharge} from '../_models/payment-charge';

@Injectable({
    providedIn: 'root'
})
export class PaymentChargeService extends BaseService<PaymentCharge> {
    destination = 'payment-charge';

    findAllByCardSummaryId(cardSummaryId: number) {
        return this.http.get(`${this.baseUrl()}/card-summary/${cardSummaryId}`);
    }
}
