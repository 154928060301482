import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {FileUpload} from '../_models/file-upload';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService extends BaseService<FileUpload> {
    destination = 'file-upload';

    uploadImage(orgId: number, type: string, file: any) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post(`${this.baseUrl()}/${orgId}/${type}`, formData);
    }

    async getPhoto() {
        return await Camera.getPhoto({
            quality: 100,
            resultType: CameraResultType.Base64,
            source: CameraSource.Photos
        });
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }
}
