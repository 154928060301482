import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {OrganizationPreference} from '../_models/organization-preference';

@Injectable({
    providedIn: 'root'
})
export class OrganizationPreferenceService extends BaseService<OrganizationPreference> {
    destination = 'organization-preferences';

    findByOrganizationId(orgId: number) {
        return this.http.get(`${this.baseUrl()}?orgId=${orgId}`);
    }
}
