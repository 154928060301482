import {Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'bb-masonry-grid-item',
    templateUrl: './masonry-grid-item.component.html',
    styleUrls: ['./masonry-grid-item.component.scss'],
    animations: [
        trigger('fadeInTrigger', [
            transition(':enter', [
                style({opacity: 0}),
                animate('400ms 200ms', style({opacity: 1})),
            ])
        ])
    ]
})
export class MasonryGridItemComponent implements OnInit {
    hide = true;

    constructor() {
    }

    ngOnInit() {
    }

    captureStartEvent() {
        this.hide = false;
    }
}
