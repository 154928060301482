import {BaseFormModel} from './base-form-model';
import {MenuTopping} from './menu-topping';
import {Sortable} from './sortable';

export class MenuItemTopping extends BaseFormModel<MenuItemTopping> implements Sortable {
    id: number;
    included: boolean;
    name: string;
    sortOrder: number;
    price: number;
    topping: MenuTopping;

    init(): void {
        this.topping = !!this.topping && new MenuTopping(this.topping);
    }

    getName() {
        return this.name;
    }
}
