import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function dependentRequired(controlName: string, parentControlName: string, parentValue: any): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
        const control = formGroup.controls[controlName];
        const parentControl = formGroup.controls[parentControlName];

        if (parentControl.errors) {
            return;
        }

        // set error on matchingControl if validation fails
        if (!control.value && parentValue === parentControl.value) {
            control.setErrors({mustMatch: true});
        } else {
            control.setErrors(null);
        }
    };
}

