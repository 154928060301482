import {BaseFormModel} from './base-form-model';
import {Person} from './person';
import {PickupLocation} from './pickup-location';
import {OrderStatusAudit} from './order-status-audit';
import {VisitWait} from './visit-wait';

export class Visit extends BaseFormModel<Visit> {
    id: number;
    pickupLocation: PickupLocation;
    person: Person;
    uuid: string;
    startTime: Date;
    endTime: Date;
    events: OrderStatusAudit[];
    waits: VisitWait[];
    cachedMilli: number;
}
