import {User} from './user';

export class SpecialtyTag {
    id: number;
    type: string;
    tag: string;
    createdBy: User;
    createDate: Date;
    style: boolean;
}
