import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CardSummaryService} from '../_services/card-summary.service';

@Injectable()
export class CardSummaryResolver implements Resolve<any> {
    constructor(private cardSummaryService: CardSummaryService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.cardSummaryService.get(route.paramMap.get('cardSummaryId'));
    }
}
