import {BaseFormModel} from '../base-form-model';
import {QuickbooksFieldMapping} from './quickbooks-field-mapping';

export class QuickbooksDefinition extends BaseFormModel<QuickbooksDefinition> {
    id: number;
    orgId: number;
    useLabels: boolean;
    active: boolean;
    category: string;
    customer: string;
    comps: string;
    tips: string;
    includedTaxes: string;
    addedTaxes: string;
    fees: string;
    surcharges: string;
    cash: string;
    creditCards: string;
    giftCards: string;
    giftCardPurchases: string;
    outstanding: string;
    appliedToPrevious: string;
    unknown: string;
    fieldMappings: QuickbooksFieldMapping[];
}
