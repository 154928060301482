<ion-grid>
    <ion-row>
        <ion-col size-xs="12">
            <ion-item lines="none">
                <div class="ion-float-left">
                    <ion-icon data-cy="image-upload" *ngIf="!organization.logoAddress" name="cloud-upload-outline" class="image-placeholder"
                              (click)="openUploadImageModal()"></ion-icon>
                    <img data-cy="image-upload" *ngIf="!!organization.logoAddress" [src]="organization.logoAddress"
                         style="height: 100px; width: auto;"
                         alt=""
                         [class.upload-image]="editable" (click)="openUploadImageModal()">
                </div>
                <div class="ion-float-left ion-padding">
                <span class="ion-text-wrap">
                    <strong>{{ organization.name }}</strong><br>
                    <span>{{ organization.phone }}</span><br>
                    <a href="{{ organization.website }}">{{ organization.website }}</a>
                </span>
                </div>
            </ion-item>
        </ion-col>
        <ion-col size-xs="12">
            <ion-item lines="none">
                <span class="ion-wrap">{{ organization.description }}</span>
            </ion-item>
        </ion-col>
    </ion-row>
</ion-grid>
